<div class="dialog-content-wrapper" fxFlex style="flex:30%">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Return Order
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <form [formGroup]="returnOrderForm">
            <div class="pt-16 pb-16 w-100-p">
                <div class="row">
                    <mat-form-field class="col-xs-12 col-md-6">
                        <mat-label>
                            Retour Type
                        </mat-label>
                        <mat-select formControlName="retourTypeId" name="mynet-order-shippingTypeId"
                            (selectionChange)="onChange($event)" required>
                            <mat-option *ngFor="let retourType of retourTypes" [value]="retourType.value">
                                {{retourType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="row" *ngIf="cashEnabled">
                    <order-cod (orderCod)="codAdded($event)"></order-cod>
                </div>

                <div class="date row">
                    <mat-form-field class="col-xs-12 col-md-12">
                        <mat-label>Pickup date</mat-label>
                        <input matInput name="mynet-order-pickupdate" placeholder="Date" [min]="minDate"
                            [matDatepicker]="picker" (focus)="picker.open()" readonly required
                            formControlName="collectionDate">
                        <mat-icon matSuffix>date_range</mat-icon>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field class="pl-4 no-padding-sm col-xs-12 col-md-12">
                        <mat-label>Special Instructions</mat-label>
                        <textarea matInput rows="3" formControlName="specialInstructions"
                            name="mynet-order-specialInstructions"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <!-- <mat-error *ngIf="returnOrderForm.invalid" class="mr-8">Please provide a COD.</mat-error> -->
        <button mat-button class="mr-8" (click)="matDialogRef.close(['cancel'])" aria-label="Cancel"
            matTooltip="Cancel Edit Contact">
            CANCEL
        </button>
        <button mat-raised-button class="save-button" color="primary" (click)="confirm()"
             aria-label="Confirm">
            CONFIRM
        </button>
        <!-- [disabled]="returnOrderForm.invalid" -->
    </mat-dialog-actions>
</div>