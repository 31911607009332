import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';

export class WeightValidator {
    static MaxDomesticWeight = 35;
    static MaxExportWeight = 200;
    static validWeight = (direction?: ServiceDirection, mynetDirection?: MyNetServiceType): ValidatorFn => {
        return (weightControl: AbstractControl): { [key: string]: boolean } => {
            const weight = +weightControl.value;
            if (direction !== undefined && direction !== null) {
                switch (direction) {
                    case ServiceDirection.Domestic:
                        if (weight <= WeightValidator.MaxDomesticWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    case ServiceDirection.Export:
                        if (weight <= WeightValidator.MaxExportWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    case ServiceDirection.Import:
                        if (weight <= WeightValidator.MaxExportWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    default:
                        return { validWeight: true };
                }
            }

            if (mynetDirection !== undefined && mynetDirection !== null) {
                switch (mynetDirection) {
                    case MyNetServiceType.Domestic:
                        if (weight <= WeightValidator.MaxDomesticWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    case MyNetServiceType.Export:
                        if (weight <= WeightValidator.MaxExportWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    case MyNetServiceType.Import:
                        if (weight <= WeightValidator.MaxExportWeight) {
                            return undefined;
                        }
                        return { validWeight: true };
                    default:
                        return { validWeight: true };
                }
            }
        };
    }
}
