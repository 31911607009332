import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuard } from 'app/_guards/auth.guard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    MatButtonToggleModule,
    MatCardModule
} from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { MyNetPipesModule } from '../_pipes/mynet-pipes.module';
import { ContactEditorModule } from '../contacts/contact-editor/contact-editor.module';
import { CommodityService } from 'app/_shared/commodity.service';
import { DecimalPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FuseSidebarModule } from '@fuse/components';
import { AutoCompleteCountryModule } from 'app/_shared/auto-complete-country/auto-complete-country.module';
import { AutoCompleteCityModule } from 'app/_shared/auto-complete-city/auto-complete-city.module';
import { NgxBarcodeModule } from 'ngx-barcode';
import { DistributionService } from './distribution.service';
import { DistributionListService } from './list/distribution-list.service';
import { DistributionListComponent } from './list/distribution-list.component';
import { DistributionEventsDialogComponent } from './list/Distribution-events-dialog/distribution-events-dialog.component';
import { NetPointLocationsComponent } from '../orders/new-order/netpoint-locations/netpoint-locations.component';
import { NetPointService } from '../orders/new-order/netpoint-locations/netpoint.services';
import { NewDistributionComponent } from './new-distribution/new-distribution.component';
import { DistributionsBillingDetailsComponent } from './new-distribution/billing-details/distributions-details.component';
import { OrderContactPreviewComponent } from './new-distribution/distribution-details/contact-preview/contact-preview.component';
import { DistributionsOrderDetailsComponent } from './new-distribution/distribution-details/distribution-details.component';
import { OrderContactListPreviewComponent } from './new-distribution/distribution-details/contactList-preview/contactList-preview.component';
import { ConfirmDistributionComponent } from './new-distribution/distribution-details/confirm-distribution/confirm-distribution.component';
import { DistributionViewComponent } from './view/distribution-view.component';
import { DistributionViewService } from './view/distribution-view.service';
import { DistribtuionDetailsViewComponent } from './view/details-view/details-view.component';
import { DistributionContactViewComponent } from './view/contact-view/contact-view.component';
import { AddressViewComponent } from './view/contact-view/address-view/address-view.component';
import { DistributionPrintDialogComponent } from './list/distribution-print-dialog/distribution-print-dialog.component';
import { DistributionManifestComponent } from './manifest/manifest.component';
import { PhoneNumberDialogComponent } from './new-distribution/billing-details/phone-number-dialog/phone-number-dialog.component';
import { DistributionInvoiceComponent } from './distribution-invoice/distribution-invoice.component';
import { CurrentConversionRateModule } from '../profile/current-conversion-rate/current-conversion-rate.module';
const routes = [
    {
        path: 'distributions',
        canActivate: [AuthGuard],
        component: DistributionListComponent,
        resolve: {
            data: DistributionListService
        }

    },
    {
        path: 'distributions/:id',
        canActivate: [AuthGuard],
        component: NewDistributionComponent,
        resolve: {
            data: DistributionService
        }
    },
    // {
    //     path: 'distributions/:id',
    //     canActivate: [AuthGuard],
    //     component: NewDistributionComponent,
    //     resolve: {
    //         data: DistributionService
    //     }
    // },
    {
        path: 'distributions/view/:id',
        canActivate: [AuthGuard],
        component: DistributionViewComponent,
        resolve: {
            data: DistributionViewService
        }
    },
    {
        path: 'distributions/print/:id/:id2',
        canActivate: [AuthGuard],
        component: DistributionInvoiceComponent,
        resolve: {
            data: DistributionViewService
        }
    }
    ,
    {
        path: 'distributions/manifest/:id',
        canActivate: [AuthGuard],
        component: DistributionManifestComponent,
        resolve: {
            data: DistributionViewService
        }
    }
];

@NgModule({
    declarations: [
        DistributionListComponent,
        DistributionsBillingDetailsComponent,
        DistributionsOrderDetailsComponent,
        OrderContactPreviewComponent,
        OrderContactListPreviewComponent,
        ConfirmDistributionComponent,
        DistributionViewComponent,
        DistribtuionDetailsViewComponent,
        AddressViewComponent,
        DistributionInvoiceComponent,
        DistributionPrintDialogComponent,
        DistributionManifestComponent,
        PhoneNumberDialogComponent,
        DistributionEventsDialogComponent,
        NewDistributionComponent,
        DistributionContactViewComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        ContactEditorModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        AutoCompleteCountryModule,
        MatDialogModule,
        MatToolbarModule,
        MyNetPipesModule,
        MatTooltipModule,
        MatSlideToggleModule,
        FuseSidebarModule,
        MatListModule,
        AutoCompleteCityModule,
        MatButtonToggleModule,
        MatCardModule,
        NgxBarcodeModule,
        CurrentConversionRateModule
    ],
    exports: [],
    entryComponents: [
        NetPointLocationsComponent,
        DistributionsBillingDetailsComponent,
        DistributionsOrderDetailsComponent,
        OrderContactPreviewComponent,
        OrderContactListPreviewComponent,
        ConfirmDistributionComponent,
        DistributionViewComponent,
        DistribtuionDetailsViewComponent,
        DistributionContactViewComponent,
        AddressViewComponent,
        DistributionInvoiceComponent,
        DistributionPrintDialogComponent,
        PhoneNumberDialogComponent,
        DistributionEventsDialogComponent,
        NewDistributionComponent
    ],
    providers: [
        DistributionService,
        DistributionListService,
        DistributionViewService,
        NetPointService,
        DistributionService,
        CommodityService,
        DecimalPipe,
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
    ]
})
export class DistributionModule { }
