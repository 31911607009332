import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Entity } from 'app/_models/Entity.model';
import { QuoteService } from 'app/main/quotes/quote.service';
import { CityCache } from 'app/_models/CityCache';
import { Address } from '../contact.model';
import { City } from 'app/_models/city.model';

@Component({
    selector: 'app-contact-form-address',
    templateUrl: './contact-form-address.component.html',
    styleUrls: ['./contact-form-address.component.scss']
})
export class ContactFormAddressComponent implements OnInit, OnDestroy {
    @Input() address: Address;

    @Input() mode = 'new';
    @Input() index: number;

    @Output() addressAdded: EventEmitter<any> = new EventEmitter();

    addressForm: FormGroup;

    countries: Entity<number>[] = [];
    cities: Entity<number>[] = [];

    // countryControl: FormControl;
    cityControl: FormControl;
    countryId: number;

    selectedCountryName = '';
    selectedCityName = '';
    actionButton = '';
    firstTime = false;
    location: any;
    private _unsubscribeAll = new Subject<any>();
    constructor(private _quoteService: QuoteService, private _formBuilder: FormBuilder) {}

    ngOnInit(): void {


        

        if (this.mode === 'new') {
            this.actionButton = 'Add';
        } else if (this.mode === 'edit') {
            this.actionButton = 'Save';
        }
        this.addressForm = this._formBuilder.group(new Address(this.address));

        this.cityControl = new FormControl('', Validators.required);


        if (
            this.address &&
            this.address.countryId &&
            this.address.countryName &&
            this.address.cityId &&
            this.address.cityName
        ) {
            const selectedLocation = new City();
            selectedLocation.cityId = this.address.cityId;
            selectedLocation.countryId = this.address.countryId;
            this.countryId = this.address.countryId;
            this.selectedCountryName = this.address.countryName;
            selectedLocation.name = this.address.cityName + ', ' + this.address.countryName;
            selectedLocation.cityName = this.address.cityName;
            selectedLocation.countryName = this.address.countryName;
            this.cityControl.setValue(selectedLocation);

            this.selectedCountryName = this.address.countryName;
            this.selectedCityName = this.address.cityName;
        }

        this.cityControl.valueChanges.subscribe(value => {
            this.citySelected(value);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    cancel(): void {
        this.addressAdded.emit({ mode: 'cancel', index: this.index });
    }

    countrySelected(option: Entity<number>): void {
        this.addressForm.get('countryId').setValue(option.id);
        this.selectedCountryName = option.name;
        this.cities = [];
        this.addressForm.get('cityId').setValue('');
        this.cityControl.reset();
        this.changeCountryEvent(option.id);
    }
    locationAdded(data: any): void {
        this.location = data;
    }
    countryCleared(): void {
        this.addressForm.get('countryId').setValue('');
        this.selectedCountryName = '';
        this.cities = [];
        this.addressForm.get('cityId').setValue('');
        this.cityControl.reset();
        this.changeCountryEvent(null);
    }

    citySelected(option: City): void {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.addressForm.get('cityId').setValue(option.cityId);
            this.selectedCityName = option.name;
            this.countryId = option.countryId;
        } else {
            this.cityControl.setErrors({ required: true });
        }
    }

    cityCleared(): void {
        this.addressForm.get('cityId').setValue('');
        this.selectedCityName = '';
        this.cityControl.clearValidators();
        this.countryId = undefined;
    }

    addAddress(): void {
        const displayMessage = this.cityControl.value.name + ', ' + this.addressForm.get('street').value;
        this.addressForm.setControl('countryId', new FormControl(this.countryId));
        this.addressForm.setControl('display', new FormControl(displayMessage));
        this.addressForm.setControl('countryName', new FormControl(this.cityControl.value.countryName));
        this.addressForm.setControl('cityName', new FormControl(this.cityControl.value.cityName));
        this.addressForm.setControl('location', new FormControl(this.location));
        this.addressAdded.emit({ value: this.addressForm.value, mode: this.mode, index: this.index });
    }

    private changeCountryEvent(countryId: number): void {
        if (!this.firstTime) {
            this.addressForm.get('cityId').reset();
        }
        if (countryId && this.countries && this.countries.length > 0) {
            this.selectedCountryName = this.countries.find(x => x.id === countryId).name;
            // check if cities with this country is cached
            const cacheCities = this._quoteService.citiesCache.find(c => c.countryId === countryId);
            if (cacheCities && cacheCities.cities.length > 0) {
                this.cities = cacheCities.cities;
                this.addressForm.get('cityId').enable({ onlySelf: true, emitEvent: false });
                if (this.firstTime) {
                    this.addressForm.get('cityId').setValue(this.address.cityId);
                    this.firstTime = false;
                }
            } else {
                this._quoteService
                    .getCitiesByCountryId(countryId)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(cities => {
                        this.addressForm.get('cityId').enable({ onlySelf: true, emitEvent: false });
                        this.cities = cities;
                        this._quoteService.citiesCache.push(new CityCache(countryId, cities));
                        if (this.firstTime) {
                            this.addressForm.get('cityId').setValue(this.address.cityId);
                            this.firstTime = false;
                        }
                    });
            }
        } else {
            this.addressForm.get('cityId').disable({ onlySelf: true, emitEvent: false });
        }
    }
}
