<div class="row" [formGroup]="group">
    <div class="col-md-8 col-xs-12">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <mat-form-field class="w-100-p">
                    <mat-label>First Name</mat-label>
                    <input matInput name="mynet-order-firstName" formControlName="firstName" required>
                    <mat-error *ngIf="group.get('firstName').hasError('required')">First name is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
                <mat-form-field class="w-100-p">
                    <mat-label>Last Name</mat-label>
                    <input matInput name="mynet-order-lastName" formControlName="lastName" required>
                    <mat-error *ngIf="group.get('lastName').hasError('required')">Last name is required</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-16">
            <div class="col-xs-12 col-md-6">
                <mat-form-field class="w-100-p">
                    <mat-label>Phone/Mobile Number</mat-label>
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <input name="mynet-order-mobileNumber" formControlName="mobileNumber" matInput required
                        maxlength="125" type="tel" />
                    <mat-error *ngIf="group.get('mobileNumber').hasError('required')">
                        Phone/Mobile Number is required
                    </mat-error>
                    <mat-error
                        *ngIf="!group.get('mobileNumber').hasError('required') && group.get('mobileNumber').hasError('validCountryPhone')">
                        Phone/Mobile Number is not valid
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6">
                <mat-form-field class="w-100-p">
                    <mat-label>Email</mat-label>
                    <mat-icon matSuffix class="secondary-text">email</mat-icon>
                    <input name="mynet-order-email" formControlName="email" matInput type="email" required
                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" maxlength="125" />
                    <mat-error *ngIf="group.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="group.get('email').hasError('email') || group.get('email').hasError('pattern')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div [formGroup]="group.get('address')">

            <div class="row mt-16">
                <div class="col-xs-12">
                    <ng-container *ngIf="allowedCountries && allowedCountries.length > 0">
                        <span *ngIf="allowedCountries.length === 1">
                            You can only ship {{ direction }} {{ allowedCountriesText[0] }}
                        </span>
                        <span *ngIf="allowedCountries.length > 1">
                            You can only ship {{ direction }} the following countries:
                            {{ allowedCountriesText.join(', ') }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <auto-complete-country class="w-100-p" [required]="true" [label]="'Country'"
                    [allowedCountries]="allowedCountries" [allowedCountriesText]="allowedCountriesText" [control]="countryControl"></auto-complete-country>
                </div>
                <div class="col-xs-12 col-md-6">
                    <auto-complete-city class="w-100-p" [required]="true" [label]="'City'"
                        [disabled]="group.get('address').get('countryId').value === undefined || group.get('address').get('countryId').value === null || group.get('address').get('countryId').value === '' || group.get('address').get('countryId').value === 0"
                        [countryId]="group.get('address').get('countryId').value" [control]="cityControl">
                    </auto-complete-city>
                </div>
            </div>
            <div class="row mt-16">
                <div class="col-xs-12">
                    <mat-form-field class="w-100-p">
                        <mat-label>Street</mat-label>
                        <input matInput name="mynet-order-street" formControlName="street" required>
                        <mat-error *ngIf="group.get('address').get('street').hasError('required')">Street is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-16">
                <div class="col-xs-12 col-md-6">
                    <mat-form-field class="w-100-p">
                        <mat-label>Building</mat-label>
                        <input matInput name="mynet-order-building" formControlName="building">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field class="w-100-p">
                        <mat-label>Floor</mat-label>
                        <input matInput name="mynet-order-floor" formControlName="floor">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-3">
                    <mat-form-field class="w-100-p">
                        <mat-label>Zip Code</mat-label>
                        <input matInput name="mynet-order-zipcode" formControlName="zipCode">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-16">
                <div class="col-xs-12">
                    <mat-form-field class="w-100-p">
                        <mat-label>Landmark</mat-label>
                        <input matInput name="mynet-order-landmark" formControlName="landmark">
                    </mat-form-field>
                </div>
            </div>

        </div>

    </div>
</div>

<div class="action-buttons">
    <button mat-raised-button *ngIf="form.value.activeListItem > 0" (click)="back()">Back</button>
    <button mat-raised-button color="primary" *ngIf="form.value.activeListItem < 6"
        (click)="validateAndContinue()">Continue</button>
</div>

<div class="mt-8" *ngIf="submited">
    <mat-error *ngIf="group.get('firstName').hasError('required')">First name is required</mat-error>
    <mat-error *ngIf="group.get('lastName').hasError('required')">Last name is required</mat-error>
    <mat-error *ngIf="group.get('mobileNumber').hasError('required')">
        Phone/Mobile Number is required
    </mat-error>
    <mat-error
        *ngIf="!group.get('mobileNumber').hasError('required') && group.get('mobileNumber').hasError('validCountryPhone')">
        Phone/Mobile Number is not valid
    </mat-error>
    <mat-error *ngIf="group.get('email').hasError('required')">
        Email address is required
    </mat-error>
    <mat-error *ngIf="group.get('email').hasError('email') || group.get('email').hasError('pattern')">
        Email address is not valid
    </mat-error>
    <mat-error *ngIf="group.get('address').get('countryId').hasError('required')">Country is required
    </mat-error>
    <mat-error *ngIf="group.get('address').get('cityId').hasError('required')">City is required
    </mat-error>
    <mat-error *ngIf="group.get('address').get('street').hasError('required')">Street is required
    </mat-error>
</div>