import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class CurrencyValidator {
    static validCurrency = (currency: number): ValidatorFn => {
        return (currencyControl: AbstractControl): { [key: string]: boolean } => {
            const currencySelected = currencyControl.value;
            if (currency == currencySelected)
                return { validCurrency: true };
            else
            return null;

            
        }
    }
};
