export class RegisterModel {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;

    constructor(user?) {
        user = user || {};
        this.email = user.email || '';
        this.firstName = user.firstName || '';
        this.lastName = user.lastName || '';
        this.password = user.password || '';
        this.confirmPassword = user.confirmPassword || '';
    }
}
