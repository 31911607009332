<div id="orders" class="page-layout carded fullwidth inner-scroll">
    <!--  -->
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div
            class="header accent"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center"
        >
            <!-- APP TITLE -->
            <div class="mb-24 mb-md-0" style="margin-top: 2rem">
                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon
                        class="logo-icon s-32 mr-16"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    >
                        shopping_cart
                    </mat-icon>
                    <span
                        *ngIf="selectedVal == '0'"
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        Normal Orders
                    </span>
                    <span
                        *ngIf="selectedVal == '1'"
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        Return Orders
                    </span>
                </div>

                <div class="logo-text h3" style="margin-top: 2rem">
                    <app-current-conversion-rate></app-current-conversion-rate>
                </div>
            </div>
            <!-- <mat-button-toggle-group appearance="legacy" name="fontStyle" [value]="selectedVal"
                (change)="onTypeChange($event.value)" aria-label="Font Style">
                <mat-button-toggle class="fuse-white" value="0">
                    Normal
                </mat-button-toggle>
                <mat-button-toggle class="fuse-white" value="1">
                    Return
                </mat-button-toggle>
            </mat-button-toggle-group> -->

            <div class="search-wrapper mx-32 mx-md-0">
                <div
                    class="search"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for an order" />
                    <div>
                        <button mat-button (click)="changeDate(null)">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-form-field (click)="fromPicker.open()" class="date-filter">
                <input
                    matInput
                    [(ngModel)]="fromDate"
                    (ngModelChange)="changeDate($event)"
                    [ngxMatDatetimePicker]="fromPicker"
                    placeholder="From Date"
                    [disabled]="false"
                    readonly
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="fromPicker"
                ></mat-datepicker-toggle>
                <!-- <button mat-button matDatepickerToggle matSuffix (click)="clearFromDate($event)">
                    <mat-icon>clear</mat-icon>
                </button> -->

                <ngx-mat-datetime-picker
                    #fromPicker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [enableMeridian]="0"
                    [color]="color"
                >
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field (click)="toPicker.open()" class="date-filter">
                <input
                    matInput
                    [(ngModel)]="toDate"
                    (ngModelChange)="changeDate($event)"
                    [ngxMatDatetimePicker]="toPicker"
                    placeholder="To Date"
                    [disabled]="false"
                    readonly
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="toPicker"
                ></mat-datepicker-toggle>
                <!-- <button mat-button matDatepickerToggle matSuffix (click)="clearToDate($event)">
                    <mat-icon>clear</mat-icon>
                </button> -->
                <ngx-mat-datetime-picker
                    #toPicker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [enableMeridian]="0"
                    [color]="color"
                >
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <div
            class="buttons"
            fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutAlign="end center"
            fxLayoutAlign.gt-sm="end end"
        >
            <button
                mat-raised-button
                color="primary"
                class="fuse-white mt-8 mt-md-0"
                [routerLink]="Routes.newOrder"
            >
                <span>New Order</span>
            </button>
            <button
                mat-raised-button
                (click)="OpenAddFilesDialog($event)"
                [disabled]="!bulkOrderVisibility"
                class="add-contact-button fuse-white mt-8 mt-md-0"
            >
                BULK
            </button>
            <button
                mat-raised-button
                (click)="OpenAddInternationalFilesDialog($event)"
                [disabled]="!internationalBulkOrderVisibility"
                class="add-contact-button fuse-white mt-8 mt-md-0"
            >
                INTERNATIONAL BULK
            </button>
            <button
                mat-raised-button
                color="primary"
                class="fuse-grey mt-8 mt-md-0"
                (click)="printCheckedLabels()"
                [disabled]="checkedOrdersToPrint.length == 0"
            >
                <span
                    >Print
                    {{
                        checkedOrdersToPrint.length === 0
                            ? ""
                            : "(" + checkedOrdersToPrint.length + ")"
                    }}</span
                >
            </button>
            <!-- <button mat-raised-button color="primary" class=" fuse-grey mt-8 mt-md-0" (click)="printCheckedOrders()"
                [disabled]="checkedOrdersToPrint.length ==0 ">
                <span>Print {{checkedOrdersToPrint.length === 0 ? "" : "(" + checkedOrdersToPrint.length + ")"}}</span>
            </button> -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-progress-bar
                *ngIf="importingOrders"
                mode="indeterminate"
            ></mat-progress-bar>
            <mat-progress-bar
                *ngIf="loadingOrders"
                mode="indeterminate"
            ></mat-progress-bar>
            <mat-table
                class="orders-table"
                #table
                [dataSource]="dataSource"
                matSort
                [@animateStagger]="{ value: '30' }"
                multiTemplateDataRows
                fusePerfectScrollbar
            >
                <!-- Direction Column -->
                <ng-container matColumnDef="direction">
                    <mat-header-cell
                        style="width: 50%"
                        *matHeaderCellDef
                        mat-sort-header
                        >Direction</mat-header-cell
                    >
                    <mat-cell *matCellDef="let order">
                        <mat-icon
                            *ngIf="
                                order.direction ===
                                    ServiceDirection[
                                        ServiceDirection.Domestic
                                    ] && order.productTypeId != 4
                            "
                        >
                            local_shipping
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                order.direction ===
                                    ServiceDirection[ServiceDirection.Import] &&
                                order.productTypeId != 4
                            "
                        >
                            flight_land
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                order.direction ===
                                    ServiceDirection[ServiceDirection.Export] &&
                                order.productTypeId != 4
                            "
                        >
                            flight_takeoff
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                order.returnOrders == '1' ||
                                order.returnOrders == '1,4'
                            "
                            >keyboard_return
                        </mat-icon>
                        <mat-icon
                            *ngIf="
                                order.returnOrders == '4' ||
                                order.returnOrders == '4,1'
                            "
                            >attach_money</mat-icon
                        >
                    </mat-cell>
                </ng-container>

                <!-- Label Column -->
                <ng-container matColumnDef="label">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >Tracking Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <a
                            *ngIf="order.label"
                            class="text-truncate badge"
                            (click)="navigateToTracking(order)"
                        >
                            {{ order.label }}
                        </a>
                        <p
                            *ngIf="!order.label"
                            (click)="$event.stopPropagation()"
                        >
                            N/A
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Shipper Column -->
                <ng-container matColumnDef="shipper">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >From Shipper
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{ order.shipper }}</p>
                    </mat-cell>
                </ng-container>

                <!-- From Column -->
                <ng-container matColumnDef="from">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >From Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{ order.from }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Receiver Column -->
                <ng-container matColumnDef="receiver">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >To Receiver
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{ order.receiver }}</p>
                    </mat-cell>
                </ng-container>

                <!-- To Column -->
                <ng-container matColumnDef="to">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >To Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{ order.to }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Commodity -->
                <ng-container matColumnDef="commodity">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >Commodity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{ order.commodity }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        fxHide
                        fxShow.gt-sm
                        >Total
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p *ngIf="order.price == '-1'" class="text-truncate">
                            BULK
                        </p>

                        <p
                            *ngIf="
                                order.price != '-1' && order.currency == 'USD'
                            "
                            class="text-truncate"
                        >
                            ${{ order.price | number : ".2-2" }}
                        </p>
                        <p
                            *ngIf="
                                order.price != '-1' && order.currency !== 'USD'
                            "
                            class="text-truncate"
                        >
                            {{ order.price | number : ".0-2" }}
                            {{ order.currency }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Last Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <ng-container [ngSwitch]="order.status">
                            <p
                                class="text-truncate purple-300 badge"
                                *ngSwitchCase="'Draft'"
                            >
                                {{ order.status }}
                            </p>
                            <p
                                class="text-truncate green-800 badge"
                                *ngSwitchCase="'Payment accepted'"
                            >
                                {{ order.status }}
                            </p>
                            <p
                                class="text-truncate green-800 badge"
                                *ngSwitchCase="'Delivered To Final Destination'"
                            >
                                {{ order.status }}
                            </p>
                            <p
                                class="text-truncate pink-700 badge"
                                *ngSwitchCase="'Order Cancelled'"
                                (click)="viewOrderEvents(order)"
                            >
                                {{ order.status }}
                            </p>
                            <p
                                class="text-truncate pink-400 badge"
                                *ngSwitchCase="'Processing'"
                            >
                                {{ order.status }}
                            </p>
                            <p
                                class="text-truncate blue-900 badge"
                                style="cursor: pointer"
                                (click)="viewOrderEvents(order)"
                                *ngSwitchDefault
                            >
                                {{ order.status }}
                            </p>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- Status Date Column -->
                <ng-container matColumnDef="statusDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Last Status Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="date">
                            {{ order.statusUpdatedDateString }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updatedDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Updated Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="date">
                            {{ order.updatedDateString }}
                        </p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="updatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Updated By
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="text-truncate">
                            {{ order.updatedBy }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- 
                <ng-container matColumnDef="select">
                    
                    <mat-header-cell fxLayoutAlign="end end" *matHeaderCellDef><mat-checkbox fxFlex="row" fxLayoutAlign="end start"></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <mat-checkbox fxFlex="row" fxLayoutAlign="end center"
                            (change)="checkboxLabel($event.checked,order)" (click)="$event.stopPropagation()"
                            [disabled]="!order.label" [checked]="false">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="select">
                    <mat-header-cell
                        *matHeaderCellDef
                        style="margin-right: -50px"
                    >
                        <mat-checkbox
                            fxFlex="row"
                            fxLayoutAlign="end center"
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                                selection.hasValue() && !isAllSelected()
                            "
                        >
                        </mat-checkbox>
                        <label style="margin-left: 15px">Select All</label>
                    </mat-header-cell>
                    <!-- [aria-label]="checkboxLabel()" -->
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox
                            fxFlex="row"
                            fxLayoutAlign="end center"
                            (click)="$event.stopPropagation()"
                            (change)="$event ? orderToggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [disabled]="!row.label"
                        >
                        </mat-checkbox>
                    </mat-cell>
                    <!-- [aria-label]="checkboxLabel(row)" -->
                </ng-container>

                <!-- <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef >
                      <mat-checkbox fxFlex="row" fxLayoutAlign="end center" (change)="$event ? masterToggle($event) : null"
                                    [checked]=""
                                    [indeterminate]="">
                      </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                      <mat-checkbox fxFlex="row" fxLayoutAlign="end center"(click)="$event.stopPropagation()"
                                    (change)="checkboxLabel($event.checked,order)">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container> -->

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <div
                            fxFlex="row"
                            fxLayoutAlign="end center"
                            *ngIf="order.status === 'Draft'"
                        >
                            <button
                                mat-icon-button
                                tabindex="-1"
                                [matMenuTriggerFor]="moreMenu"
                                aria-label="More"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-icon class="secondary-text"
                                    >more_vert</mat-icon
                                >
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button
                                    mat-menu-item
                                    aria-label="edit"
                                    [routerLink]="'/orders/' + order.orderId"
                                >
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button
                                    mat-menu-item
                                    aria-label="remove"
                                    (click)="deleteOrder(order.orderId)"
                                >
                                    <mat-icon>delete</mat-icon>
                                    <span>Remove</span>
                                </button>
                                <!-- <button mat-menu-item aria-label="duplicate" (click)=" duplicateOrder(order.orderId)">
                                    <mat-icon>file_copy</mat-icon>
                                    <span>Duplicate</span>
                                </button> -->
                            </mat-menu>
                        </div>
                        <div
                            fxFlex="row"
                            fxLayoutAlign="end center"
                            *ngIf="order.status !== 'Draft'"
                        >
                            <button
                                mat-icon-button
                                tabindex="-1"
                                [matMenuTriggerFor]="moreMenu"
                                aria-label="More"
                                (click)="$event.stopPropagation()"
                                (menuOpened)="menuOpened(order.orderId)"
                            >
                                <mat-icon class="secondary-text"
                                    >more_vert</mat-icon
                                >
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button
                                    mat-menu-item
                                    aria-label="edit"
                                    [disabled]="disableModifyOrder"
                                    [routerLink]="'/orders/' + order.orderId"
                                >
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <!-- <button mat-menu-item aria-label="print" (click)="printCheckedOrders(order.orderId)"
                                    [disabled]="!order.label">
                                    <mat-icon>print</mat-icon>
                                    <span>Print</span>
                                </button> -->
                                <button
                                    mat-menu-item
                                    aria-label="print new"
                                    (click)="viewOrderLabel(order.orderId)"
                                    [disabled]="!order.label"
                                >
                                    <mat-icon>print</mat-icon>
                                    <span>Print</span>
                                </button>
                                <button
                                    *ngIf="order.productTypeId == 0"
                                    mat-menu-item
                                    aria-label="remove"
                                    (click)="generateReturnOrderDialog(order)"
                                    [disabled]="
                                        order.returnOrders == '1,4' ||
                                        order.returnOrders == '4,1' ||
                                        order.status == 'Order Cancelled' ||
                                        order.status == 'Created'
                                    "
                                >
                                    <mat-icon>assignment_return</mat-icon>
                                    <span>Return</span>
                                </button>
                                <button
                                    mat-menu-item
                                    aria-label="remove"
                                    (click)="duplicateOrder(order.orderId)"
                                >
                                    <mat-icon>file_copy</mat-icon>
                                    <span>Duplicate</span>
                                </button>
                                <button
                                    mat-menu-item
                                    aria-label="cancel"
                                    (click)="cancelOrder(order.label)"
                                    [disabled]="
                                        order.status == 'Order Cancelled'
                                    "
                                >
                                    <mat-icon>cancel</mat-icon>
                                    <span>Cancel</span>
                                </button>
                            </mat-menu>
                        </div>
                    </mat-cell>
                </ng-container>
                <!--    -->
                <mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let order; columns: displayedColumns"
                    class="order"
                    style="cursor: pointer"
                    matRipple
                    [@animate]="{ value: '*', params: { y: '100%' } }"
                    (click)="orderClicked($event, order)"
                >
                </mat-row>
            </mat-table>
            <mat-paginator
                #paginator
                [length]="dataSource.filteredData.length"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 50, 100, 200, 300]"
                (page)="pagination($event)"
            >
            </mat-paginator>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
