<div id="payment-done" fxLayout="column" *ngIf="paymentDoneService.success">

    <div id="payment-done-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="payment-done-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="mat-accent s-96">check</mat-icon>
            </div>

            <div class="title">Thank you for your order</div>

            <div class="message">
                <span>Your order is completed.</span>
                <br />
                <a class="link" [routerLink]="'/orders'">Go back to my orders</a>
            </div>

        </div>

    </div>

</div>

<div id="payment-done" fxLayout="column" *ngIf="!paymentDoneService.success">

    <div id="payment-done-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="payment-done-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="mat-warn s-96">close</mat-icon>
            </div>

            <div class="title">Couldn't save the order, please contact support</div>

            <div class="message">
                <span>Your order is paid successfully, but we had difficulties in our end. Please contact support for
                    assistance</span>
                <br />
                <a *ngIf="authService.isLoggedIn()" class="link" [routerLink]="'/orders'">Go back to my orders</a>
            </div>

        </div>

    </div>

</div>