<div [formGroup]="addressForm">
    <div fxFlex fxLayout="column">

        <auto-complete-location [label]="'Location'" [required]="true" [control]="cityControl">
        </auto-complete-location>

        <mat-form-field fxFlex>
            <mat-label>Street Address</mat-label>
            <input matInput formControlName="street" name="mynet-contact-street" required />
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Building</mat-label>
            <input matInput formControlName="building" name="mynet-contact-building" />
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Floor</mat-label>
            <input matInput formControlName="floor" name="mynet-contact-floor" />
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Postal Code (ZIP)</mat-label>
            <input matInput formControlName="zipCode" name="mynet-contact-zipCode" />
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Landmark</mat-label>
            <textarea matInput formControlName="landmark" name="mynet-contact-landmark" matInput rows="4"
                matTextareaAutosize matAutosizeMinRows=4 matAutosizeMaxRows=6></textarea>
        </mat-form-field>
        <div style="flex: 1 50%">
            <google-location  [latitude]= "" [longitude] = "" (location)="locationAdded($event)" ></google-location>
            <!-- <google-location></google-location> -->
        </div>

        <div style="text-align: right">
            <button mat-raised-button color="primary" class="mb-16" [disabled]="addressForm.invalid"
                (click)="addAddress()">
                {{ actionButton }}
            </button>
            <button mat-raised-button color="primary" class="ml-8" (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</div>