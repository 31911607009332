import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseCountdownModule } from '@fuse/components';

import { NetPointComponent } from './netpoint.component';
import { MatProgressBar, MatProgressBarModule, MatSelectModule, MatTabsModule } from '@angular/material';
import { NetPointService } from './netpoint.service';
import { MatVideoModule } from 'mat-video';
import { NetPointResultComponent } from './netpoint-result/netpoint-result.component';
const routes = [
    {
        path     : 'netpoint',
        component: NetPointComponent
    }
];

@NgModule({
    declarations: [
        NetPointComponent,
        NetPointResultComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatProgressBarModule,
        FuseSharedModule,
        FuseCountdownModule,
        MatSelectModule,
        MatVideoModule
    ],
    providers: [
        NetPointService
    ]
})
export class NetPointModule
{
}
