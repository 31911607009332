import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CashOnDelivery } from '../../order.model';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { MatDividerModule } from '@angular/material/divider';
@Component({
    selector: 'order-cod',
    templateUrl: './order-cod.component.html',
    styleUrls: ['./order-cod.component.scss']
})

export class OrderCodComponent implements OnInit {
    codArray: CashOnDelivery[] = [];
    newDynamic: CashOnDelivery;
    currencyTypes: any = {};
    selectedCurrencies: number[];
    firstLoad: boolean = true;
    @Output() orderCod: EventEmitter<CashOnDelivery[]> = new EventEmitter();
    @Input() orderCodsLoaded: CashOnDelivery[];
    @Input() disabled: boolean = false;

    protected _unsubscribeAll = new Subject<void>();
    ngOnInit(): void {
        // this.currencyTypes = this.enumSelector(CurrencyType).slice(4,6);
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        if (this.orderCodsLoaded) {
            this.orderCodsLoaded.forEach(element => {
                if (element.codCurrencyId != 0)
                    this.currencyTypes.find(i => i.value === element.codCurrencyId).disabled = true;
                this.codArray.push(element);
            });
        }
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key, disabled: false }));
    }
    addRow() {
        if (this.disabled == null)
            this.disabled = false;
        if (this.disabled)
            return;
        if (this.codArray.length == 2)
            return;
        this.newDynamic = new CashOnDelivery();
        this.codArray.push(this.newDynamic);
        this.orderCod.emit(this.codArray);
        return true;
    }
    filterCodCurrency(event) {
        this.currencyTypes.find(i => i.value === event).disabled = true;
        if (this.codArray.length == 1)
            this.currencyTypes.find(i => i.value != event).disabled = false;
        this.emitCod();
    }
    deleteRow(index) {
        if (this.disabled)
            return;
        if (this.codArray[index].codCurrencyId != 0)
            this.currencyTypes.find(i => i.value === this.codArray[index].codCurrencyId).disabled = false;
        this.codArray.splice(index, 1);
        this.emitCod();
        return true;
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    emitCod(): void {
        var canSave = true;
        this.codArray.forEach(element => {
            if (element.codCurrencyId == 0 || isNullOrUndefined(element.codCurrencyId) || element.codAmount == 0 || isNullOrUndefined(element.codAmount)) {
                canSave = false;
            }
            return;
        });
        if (canSave)
            this.orderCod.emit(this.codArray);
        else {
            this.orderCod.emit(null);
        }
    }
}
