import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { LoginResult } from 'app/_models/LoginResult';
import { Entity } from 'app/_models/Entity.model';
import { Distribution, DistributionDetail, DistributionBillingDetails } from './new-distribution/distribution.model';

@Injectable()
export class DistributionService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        // this.onOrdersChanged = new BehaviorSubject([]);
        this.onDistributionAdded = new BehaviorSubject(0);
        this.onDistributionSelected = new BehaviorSubject(null);
    }
    // Orders: OrderListItem[];
    // onOrdersChanged: BehaviorSubject<OrderListItem[]>;
    onDistributionAdded: BehaviorSubject<number>;
    onDistributionSelected: BehaviorSubject<Distribution>;
    selectedOrder: Distribution;
    timeRanges: Entity<number>[];

    timeRangesChanged = new BehaviorSubject<Entity<number>[]>(null);

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            if (route.params.id === 'new') {
                resolve(this);
            } else {
                Promise.all([this.getDistribution(route.params.id)]).then(() => {
                    resolve(this);
                }, reject);
            }
        });
    }

    /**
     * Get Orders
     *
     * @returns {Promise<any>}
     */
    getDistribution(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Distribution>(environment.apiUrl + 'v1/Distributions/' + id)
            .subscribe((response: Distribution) => {
                if (response == null)
                //TODO
                // this.router.navigateByUrl(Routes.orders);
                this.selectedOrder = response;
                this.onDistributionSelected.next(this.selectedOrder);
                resolve(response);
            }, reject);
        });
    }
    getDistributionForView(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Distribution>(environment.apiUrl + 'v1/Distributions/View/' + id).subscribe((response: Distribution) => {
                this.selectedOrder = response;
                this.onDistributionSelected.next(this.selectedOrder);
                resolve(response);
            }, reject);
        });
    }

    addDistribution(order: DistributionDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Distributions/', order)
                .subscribe((billindDetails: DistributionBillingDetails) => {
                    this.onDistributionAdded.next(billindDetails.distributionId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {    
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject);
        });
    }
    //TODO here we need to call  to generate the details
    // [HttpPost("Confirm/{distributionId:int}")]
    // public async Task<IActionResult> ConfirmDistributionAsync(int distributionid)
    // {
    //     int userId = User.Identity.IsAuthenticated ? User.GetUserId() : AppContants.AnonymousUserId;
    //     string jobId = BackgroundJob.Enqueue(() => ConfirmDistributionAsync(distributionid, userId));
    //     await _distributionService.SaveOrderBackgroungJobIdAsync(distributionid, jobId);
    //     return Ok();
    // }
    addDistributionDetails(order: DistributionDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Distributions/', order)
                .subscribe((billindDetails: DistributionBillingDetails) => {
                    this.onDistributionAdded.next(billindDetails.distributionId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {    
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject);
        });
    }

    getMyInfo(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Distributions/MyInfo');
    }


    getTimeRanges(): Observable<any> {

        return this._httpClient.get(environment.apiUrl + 'v1/Orders/TimeRanges');
        // return new Promise((resolve, reject) => {
        //     if (this.timeRanges) {
        //         resolve(this.timeRanges);
        //     } else {
        //         this._httpClient
        //             .get<Entity<number>[]>(environment.apiUrl + 'v1/Distributions/TimeRanges')
        //             .subscribe(result => {
        //                 this.timeRanges = result;
        //                 this.timeRangesChanged.next(this.timeRanges);
        //                 resolve(result);
        //             }, reject);
        //     }
        // });
    }

    updateDistributionDetails(distribution: DistributionDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(environment.apiUrl + 'v1/Distributions/', distribution)
                .subscribe((billindDetails: DistributionBillingDetails) => {
                    this.onDistributionAdded.next(billindDetails.distributionId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject);
        });
    }

    addBillingDetails(billingDetails: DistributionBillingDetails): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Distributions/BillingDetails/', billingDetails)
                .subscribe((loginResult: LoginResult) => {
                    localStorage.setItem('loggedInUser', JSON.stringify(loginResult));
                    resolve(loginResult);
                }, reject);
        });
    }
}
