import { ContactsContactListComponent } from './contact-list/contact-list.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { ContactsContactListService } from './contact-list/contact-list.service';
const ɵ0 = ContactsContactListService;
const routes = [
    {
        path: 'contacts',
        canActivate: [AuthGuard],
        component: ContactsContactListComponent,
        resolve: {
            data: ɵ0
        }
    }
];
export class ContactsModule {
}
export { ɵ0 };
