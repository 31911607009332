import { EmailConfirmedComponent } from 'app/authentication/email-confirmed/email-confirmed.component';
import { EmailConfirmedService } from './email-confirmed.service';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';
const ɵ0 = EmailConfirmedService;
const routes = [
    {
        path: 'auth/email-confirmed',
        component: EmailConfirmedComponent,
        resolve: {
            data: ɵ0
        },
        canActivate: [AnonymousGuard]
    }
];
export class EmailConfirmedModule {
}
export { ɵ0 };
