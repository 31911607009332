import { CashOnDelivery } from "app/main/orders/new-order/order.model";

export class QuickQuote {
    fromCityId: number;
    toCityId: number;
    shippingTypeId: number;
    serviceTypeId: number;
    weight: number;
    width: number;
    height: number;
    length: number;
    cod : CashOnDelivery[];

    constructor(quote?: any) {
        quote = quote || {};
        this.fromCityId = quote.fromCityId || 0;
        this.toCityId = quote.toCityId || 0;
        this.shippingTypeId = quote.shippingTypeId || 0;
        this.serviceTypeId = quote.serviceTypeId || 0;
        this.weight = quote.weight;
        this.width = quote.width;
        this.height = quote.height;
        this.length = quote.length;
        this.cod =  quote.cod || [];
    }
}
