<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
        <!-- <mat-icon class="m-0 mr-16 secondary-text">access_time</mat-icon> -->
        <!-- <div *ngIf="events && events.length > 0" class="h2 secondary-text">
            Current Status: {{ events[0].name }} at {{ events[0].dateFormatted }}
        </div> -->
    </div>

    <div class="customer mb-32">
        <table class="simple">
            <thead>
                <tr>
                    <th>Tracking Number</th>
                    <th>To Receiver</th>
                    <th>Address</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let info of distribution.distributionInfo">
                    <td>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <!-- <span class="name text-truncate">
                                {{info.label}}
                            </span> -->
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <p class="name text-truncate " style="cursor: pointer;color:#FF0000;"
                                    (click)="navigateToTracking(info.label)">
                                    {{info.label}}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span class="name text-truncate">
                            {{info.contact.fullName}}
                        </span>
                    </td>
                    <td>
                        <span class="name text-truncate">
                            {{info.contact.address.display}}
                        </span>
                    </td>
                    <td>
                        <span class="name text-truncate">
                            {{info.lastEvent}}
                        </span>
                        <p class="name text-truncate" *ngIf="!info.lastEvent">Created</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>