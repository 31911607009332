import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Routes } from 'app/Routes';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Countries } from 'app/main/quotes/international/international.model';
import { Location } from '@angular/common';
import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { DistributionsOrderDetailsComponent } from './distribution-details/distribution-details.component';
import { DistributionService } from '../distribution.service';
import { DistributionDetail, DistributionBillingDetails, Distribution } from './distribution.model';
import { OrderService } from 'app/main/orders/order.service';
import { BillingDetails } from 'app/main/orders/new-order/order.model';

@Component({
    selector: 'distributions-new-distribution',
    templateUrl: './new-distribution.component.html',
    styleUrls: ['./new-distribution.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class NewDistributionComponent implements OnInit, OnDestroy {
    protected _unsubscribeAll = new Subject<void>();
    ServiceDirection = ServiceDirection;
    data: QuoteData;
    mode: string;
    step = PanelOption.MyInfo;
    PanelOption = PanelOption;
    distributionId: number;
    hasSubsidiaryId = true;
    distributionForm: FormGroup;
    distributionDetailGroup: FormGroup;
    billingDetailsGroup: FormGroup;
    public imagePath;
    imgURL: any;
    public message: string;
    imageUploadLoading = false;
    requiredUploadIdImage = false;
    firstTime: boolean;

    @ViewChild('orderDetailComponent', { static: false }) orderDetailComponent: DistributionsOrderDetailsComponent;

    newQuote = new FormControl(true);

    constructor(
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _distributionService: DistributionService,
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthenticationService,
        private _orderService: OrderService,
        private _location: Location
    ) {
        const navigation = this._router.getCurrentNavigation();

        const state = navigation.extras.state as QuoteData;

        this.data = state || new QuoteData();

        this.distributionDetailGroup = this._formBuilder.group(new DistributionDetail());
        this.distributionDetailGroup.setControl('quote', this._formBuilder.group(this.data));

        this.distributionDetailGroup.setControl('quoteId', this._formBuilder.control(this.data.quoteId));
        // this.billingDetailsGroup = this._formBuilder.group(new BillingDetails());

        this._activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id === 'new') {
                this.distributionId = 0;
            } else {
                this.distributionId = +id;
                this.mode = 'edit';
            }
        });

        const t = new Promise(resolve => {
            this.loadScript();
            resolve(true);
        });
    }

    get orderDetail(): DistributionDetail {
        const order = new DistributionDetail(this.distributionDetailGroup.getRawValue());
        order.shipperName = this.orderDetailComponent.contacts.find(c => c.contactId === order.shipperId).fullName;
        // order.receiverName = this.orderDetailComponent.contacts.find(c => c.contactId === order.receiverId).fullName;
        order.shipperAddress = this.orderDetailComponent.toLongDisplayString(
            this.orderDetailComponent.shipperAddresses.find(c => c.addressId === order.shipperAddressId)
        );
        // order.receiverAddress = this.orderDetailComponent.toLongDisplayString(
        //     this.orderDetailComponent.receiverAddresses.find(c => c.addressId === order.receiverAddressId)
        // );
        order.distributionId = this.distributionId;

        return order;
    }

    ngOnInit(): void {

        // this._authService.hasSubsidiaryId().subscribe(result => {
        //     this.hasSubsidiaryId = result.status;
        //     this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
        //         this.billingDetailsGroup = this._formBuilder.group(info);
        //         this.firstTime = !this.billingDetailsGroup.get('confirmedPhoneNumber').value;
        //         if (this.firstTime) {
        //             this.step = PanelOption.MyInfo;
        //         }
        //     });
        //     if (this.hasSubsidiaryId) {
        //         this.step = PanelOption.OrderDetails;
        //     }
        // });
        this.step = PanelOption.OrderDetails;
        if (this.mode === 'edit') {
            this.initEditMode(this._distributionService.selectedOrder);
        }
        this._distributionService.onDistributionSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe((order: Distribution) => {
            this.initEditMode(order);
        });
        this.data.type = this.getDirection(this.data.fromCountryId, this.data.toCountryId);

        if (this.data && this.mode === 'new') {

            const orderDetail = new DistributionDetail({ quoteId: this.data.quoteId, quote: this.data });
            this.distributionDetailGroup = this._formBuilder.group(orderDetail);
            this.distributionDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        }

        this._distributionService.onDistributionAdded.subscribe((orderId: number) => {
            if (this.distributionId === 0) {
                // this.billingDetailsGroup.get('orderId').setValue(orderId);
                this.distributionDetailGroup.get('distributionId').setValue(orderId);
                this.distributionId = orderId;
            }
        });
    }

    getDirection(fromCountryId: number, toCountryId: number): ServiceDirection {
        if (!fromCountryId || !toCountryId) {
            return undefined;
        }

        if (fromCountryId === Countries.Lebanon && toCountryId === Countries.Lebanon) {
            // domestic
            return ServiceDirection.Domestic;
        } else if (fromCountryId === Countries.Lebanon) {
            // Export
            return ServiceDirection.Export;
        } else if (toCountryId === Countries.Lebanon) {
            // Import
            return ServiceDirection.Import;
        } else {
            // Not Supported
            return undefined;
        }
    }

    initEditMode(distribution: Distribution): void {
        if (distribution === null) {
            return;
        }
        if (this.distributionId === 0) {
            this._router.navigateByUrl(Routes.orders);
        }
        distribution.detail.quote.type = this.getDirection(distribution.detail.quote.fromCountryId, distribution.detail.quote.toCountryId);
        this.distributionDetailGroup.patchValue(distribution.detail);
        this.distributionDetailGroup.get('quote').patchValue(distribution.detail.quote);
        // this.billingDetailsGroup.patchValue(order.billingDetails);
    }

    ngOnDestroy(): void {
        this._distributionService.onDistributionSelected.complete();
        this._distributionService.onDistributionAdded.complete();
        this._distributionService.selectedOrder = null;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setStep(option: PanelOption): void {
        this.step = option;
    }

    nextStep(option: PanelOption): void {
        this.step = option;
    }

    prevStep(option: PanelOption): void {
        this.step = option;
    }

    goBackFromPayment(): void {
        const quote = this.distributionDetailGroup.value.quote;
        const direction = this.getDirection(quote.fromCountryId, quote.toCountryId);

        if (!this.hasSubsidiaryId) {
            this.prevStep(PanelOption.MyInfo);
        } else if (this.hasSubsidiaryId && direction === ServiceDirection.Export && this.requiredUploadIdImage) {
            this.prevStep(PanelOption.UploadIDPhoto);
        } else {
            this.prevStep(PanelOption.OrderDetails);
        }
    }

    saveOrderDetails(): void {
        const data = this.distributionDetailGroup.getRawValue() as DistributionDetail;
        data.distributionId = this.distributionId;
        if (this.distributionId === 0) {
            this._distributionService.addDistributionDetails(data).then((result: DistributionBillingDetails) => {
                this.afterSaveOrder(result);
            });
        } else if (this.distributionId > 0) {
            this._distributionService.updateDistributionDetails(data).then((result: DistributionBillingDetails) => {
                this.afterSaveOrder(result);
            });
        }
    }

    confirmOrderDetails(): void {
        const data = this.distributionDetailGroup.getRawValue() as DistributionDetail;
        data.distributionId = this.distributionId;
        if (this.distributionId === 0) {
            this._distributionService.addDistributionDetails(data).then((result: DistributionBillingDetails) => {
                this.afterSaveConfirmOrder(result);
            });
        } else if (this.distributionId > 0) {
            this._distributionService.updateDistributionDetails(data).then((result: DistributionBillingDetails) => {
                // localStorage.setItem('distributionId', result.distributionId.toString());
                this.afterSaveConfirmOrder(result);
            });
        }
    }
    private afterSaveConfirmOrder(result: DistributionBillingDetails): void {
        const quoteForm = this.distributionDetailGroup.get('quote');

        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionId = result.distributionId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        } else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/distributions/' + this.distributionId);

    }
    private afterSaveOrder(result: DistributionBillingDetails): void {
        const quoteForm = this.distributionDetailGroup.get('quote');

        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionId = result.distributionId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        } else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/distributions/' + this.distributionId);
    }

    saveBillingDetails(): void {
        this._distributionService.addBillingDetails(this.billingDetailsGroup.value as DistributionBillingDetails).then(user => {
            this._authService.onUserInfoUpdated.next(user);
            this.nextStep(PanelOption.OrderDetails);
            // update list of contacts to display the new Home contact (if created)
            this.orderDetailComponent.getContactsForOrders(false);
        });
    }

    uploadIDPhoto(files): void {
        this.message = '';

        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.imgURL = null;
            return;
        }

        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = _event => {
            this.imgURL = reader.result;
        };
    }

    uploadIdPhotoAndContinue(): void {
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(
            user => {
                this.imageUploadLoading = false;
                this.nextStep(PanelOption.Payment);
            },
            error => {
                this.message = 'Error while uploading your image ID to the server.';
                this.imageUploadLoading = false;
            }
        );
    }

    goBackFromUploadId(): void {
        this.prevStep(PanelOption.OrderDetails);
    }

    loadScript(): void {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            const node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }


}


export enum PanelOption {
    OrderDetails,
    MyInfo,
    UploadIDPhoto,
    Payment
}
