import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProfileService } from 'app/main/profile/profile.service';
import { ProfileComponent } from 'app/main/profile/profile.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { MatFormFieldModule, MatInputModule, MatProgressBarModule, MatSelectModule, MatExpansionModule } from '@angular/material';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { AvatarModule } from 'ngx-avatar';

const routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        resolve: {
            profile: ProfileService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    // declarations: [ProfileComponent, ProfileTimelineComponent, ProfileAboutComponent, ProfilePhotosVideosComponent],
    declarations: [ProfileComponent],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        FuseSharedModule,
        MatProgressBarModule,
        MatSelectModule,
        MatExpansionModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        AvatarModule
        
    ],
    providers: [ProfileService]
})
export class ProfileModule {}
