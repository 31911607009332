export class LoginModel {
    email: string;
    password: string;
    rememberMe: boolean;

    constructor(user?) {
        user = user || {};
        this.email = user.email || '';
        this.password = user.password || '';
        this.rememberMe = user.rememberMe || false;
    }
}
