import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ContactList} from './c-list.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { ContactListDetail } from '../c-list-editor/c-list.model';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';

@Component({
    selector: 'app-contact-form',
    templateUrl: './c-list-form.component.html',
    styleUrls: ['./c-list-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactListFormComponent implements OnInit, OnDestroy {
    selectedTab = 0;
    action: string;
    contactList: ContactList;
    contacts: ContactListItem [];
    contactListDetail: ContactListDetail[];
    contactListForm: FormGroup;
    contactListContacts: FormGroup;
    // activeAddress: FormGroup;
    // activeEditAddress: FormGroup;

    // isNewAddressOpen = false;
    editIndex: boolean[] = [];
    protected _unsubscribeAll = new Subject<void>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];

    constructor(
        public matDialogRef: MatDialogRef<ContactListFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;
        // this.tags =  [];
        if (this.action === 'edit') {
            this.contactList = _data.contactList;
            this.contacts = _data.contacts;
            

            // this.contactListDetail = _data.contactListDetail;
            this.contactListDetail = _data.contactlist.contactListDetail;
        } else {
            this.contactList = new ContactList();
        }
        this.createContactListForm();
        // this.createAddressForm();
    }

    get formValue(): any {
        return this.contactListForm.value;
    }

    get addNewIndex(): number {
        const value = this.contactListForm.value;
        return value ;
    }

    // get contactListContacts(): any {
    //     return (this.contactListForm.get('contactListDetail') as FormArray).controls;
    // }
    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private createContactListForm(): void {
        this.tags = this.contactList.tags.slice();
        this.contactListDetail = this.contactList.contactListDetail;
        this.contactList.tags = [];
        // this.contactList.contactListDetail = [];
        
        this.contactListForm = this._formBuilder.group(this.contactList);

        this.contactListForm.setControl('contactListDetail', this._formBuilder.array([], Validators.required));

        // const addressesFormArray = this.contactListForm.get('addresses') as FormArray;

        // this.contact.addresses.forEach(address => {
        //     addressesFormArray.push(this._formBuilder.group(address));
        //     this.editIndex.push(false);
        // });

        // this.contactListForm.get('email').setValidators(Validators.email);

        // const country = new FormControl({ iso: 'LB' }, Validators.required);

        // this.contactListForm.get('phoneNumber').setValidators(PhoneValidator.validCountryPhone(country));
    }

    // addNewAddress(): void {
    //     this.isNewAddressOpen = true;
    //     this.createAddressForm();
    // }

    // addressAdded(data: any): void {
    //     const addressForm: FormGroup = data.value;
    //     const mode: string = data.mode;
    //     const index: number = data.index;
    //     if (mode === 'cancel') {
    //         if (this.isNewAddressOpen && index === -1) {
    //             this.isNewAddressOpen = false;
    //         } else if (index >= 0) {
    //             this.editIndex[index] = false;
    //         }
    //         return;
    //     }

    //     const addressesFormArray = this.contactListForm.get('addresses') as FormArray;
    //     const address = this._formBuilder.group(addressForm);
    //     if (mode === 'new') {
    //         addressesFormArray.push(address);
    //         this.isNewAddressOpen = false;
    //     } else if (mode === 'edit') {
    //         addressesFormArray.setControl(index, address);
    //         this.editIndex[index] = false;
    //     }
    // }

    // removeAddress(index: number): void {
    //     const addressesFormArray = this.contactListForm.get('addresses') as FormArray;
    //     addressesFormArray.removeAt(index);
    // }

    // editAddress(index: number): void {
    //     // const addressesFormArray = this.contactForm.get('addresses') as FormArray;
    //     // this.activeEditAddress = this._formBuilder.group(addressesFormArray.controls[index].value);
    //     this.editIndex[index] = true;
    // }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    saveNew(): void {
        this.matDialogRef.close(['new', this.contactListForm.value, this.tags]);
    }
    
    saveExisting(): void {
        this.matDialogRef.close(['edit', this.contactListForm.value, this.tags]);
    }
}
