import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { PaymentDoneService } from './payment-done.service';
import { AuthenticationService } from 'app/authentication/authentication.service';

@Component({
    selector: 'orders-payment-done',
    templateUrl: './payment-done.component.html',
    styleUrls: ['./payment-done.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrdersPaymentDoneComponent implements OnInit {
    constructor(private _fuseConfigService: FuseConfigService, public paymentDoneService: PaymentDoneService, public authService: AuthenticationService) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {}
}
