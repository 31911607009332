<div class="page-layout simple fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center">

        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                settings
            </mat-icon>
            <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                Settings
            </span>
        </div>
    </div>

    <div class="content-card">
        <div class="content">
            <div class="w-100-p p-24">
                <div>
                    <!-- <mat-accordion> -->
                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                    <mat-expansion-panel [expanded]="true" (opened)="false" hideToggle class="mb-16 no-border">
                        <mat-expansion-panel-header style="pointer-events: none;">
                            <mat-panel-title> Conversion Rates
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form [formGroup]="group">
                            <div class="mb-6 w-70-p" fxLayout="column" fxLayout.gt-xs="row">
                                <mat-form-field class="col-xs-6 col-md-3">
                                    <mat-label>Source Currency</mat-label>
                                    <mat-select formControlName="sourceCurrency" name="mynet-order-sourceCurrency"
                                        required>
                                        <mat-option *ngFor="let currency of currencyTypes" [value]="currency.title">
                                            {{currency.title}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="group.get('sourceCurrency').hasError('validCurrency')">Duplicate
                                        currency selected</mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-xs-6 col-md-3">
                                    <mat-label>Target Currency</mat-label>
                                    <mat-select formControlName="targetCurrency" name="mynet-order-targetCurrencyId"
                                        required>
                                        <mat-option *ngFor="let currency of currencyTypes" [value]="currency.title">
                                            {{currency.title}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="group.get('targetCurrency').hasError('validCurrency')">Duplicate
                                        currency selected</mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-xs-6 col-md-3">
                                    <mat-label>Value</mat-label>
                                    <input matInput name="mynet-order-targetValue" formControlName="targetValue"
                                        (keypress)="numberOnly($event)" type="number" min="0" max="9999999999999999">
                                    <!-- <mat-error *ngIf="group.get('quote').get('shipmentValue').hasError('validDecimal')">
                                            Shipment Value should be between 0.00 and 9999999999999999
                                        </mat-error> -->
                                    <mat-error *ngIf="group.get('targetValue').hasError('min')">Value must be greater than 0</mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>Effective Date</mat-label>
                                    <input matInput name="mynet-order-effectiveDate" placeholder="Date" [min]="minDate"
                                        [matDatepicker]="picker" (click)="picker.open()" readonly required
                                        formControlName="effectiveDate">
                                    <mat-icon matSuffix>date_range</mat-icon>
                                </mat-form-field>
                                <div>
                                    <button mat-raised-button color="primary" class="mb-16" [disabled]="group.invalid"
                                        (click)="submitForm()">
                                        Add
                                    </button>
                                    <button mat-raised-button color="accent" class="mb-16" (click)="clearForm()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                            <mat-slide-toggle (change)="toggleRates($event)" [(ngModel)]="this._settingsService.marketRateStatusToggle" [ngModelOptions]="{standalone: true}">Enable Conversion Rates</mat-slide-toggle>

                            <mat-datepicker #picker></mat-datepicker>


                            <mat-table class="contacts-table" #table [dataSource]="dataSource" matSort
                                [@animateStagger]="{value:'30'}">
                                <ng-container matColumnDef="targetValue">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Target Value
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let contact">
                                        <p class="text-truncate">{{contact.targetValue}}</p>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="sourceCurrency">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Source Currency
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let contact">
                                        <p class="text-truncate">{{contact.sourceCurrency}}</p>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="targetCurrency">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Target Currency
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let contact">
                                        <p class="text-truncate">{{contact.targetCurrency}}</p>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="effectiveDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let contact">
                                        <p class="text-truncate">{{contact.effectiveDate |date:'yyyy-MM-dd' }}</p>
                                    </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                <mat-row *matRowDef="let contact; columns: displayedColumns;" class="contact" matRipple
                                    [@animate]="{value:'*',params:{y:'100%'}}">
                                </mat-row>
                            </mat-table>

                        </form>

                        <mat-action-row>
                            <button type="button" mat-button color="primary" (click)="cancelForm()">
                                Close</button>
                        </mat-action-row>
                    </mat-expansion-panel>
                    <!-- </mat-accordion> -->

                </div>
            </div>
        </div>
    </div>

</div>