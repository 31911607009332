<form #proformaForm="ngForm" (ngSubmit)="proformaForm.form.valid" validate>
  <mat-card>
 
    <mat-card-content>
      <mat-table #matTable [dataSource]="dataSource">

        <ng-container  matColumnDef="actions" >
          <mat-header-cell *matHeaderCellDef class='actions'>
          </mat-header-cell>
          <mat-cell *matCellDef="let entry" class='actions'>
            <button mat-raised-button   color="warn" (click)="remove(entry)">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef class='actions'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>
            Description
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput #description="ngModel" name="txtdescription-{{rowIdx}}" placeholder="Description"
                [(ngModel)]="row.description" required >
              <mat-error *ngIf="description?.invalid">
                <div *ngIf="description.errors.required">Description is required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef>
            Quantity
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput  type="number" ngModel name="txtQuantity-{{rowIdx}}"
                placeholder="Quantity"  (keypress)="numberOnly($event)" [(ngModel)]="row.quantity"
                (ngModelChange)="calculateQuantity($event,rowIdx)" required>
              <mat-error *ngIf="quantity?.invalid">
                <div *ngIf="quantity.errors.required">Quantity is required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="countryId">
          <mat-header-cell *matHeaderCellDef>
            Country
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index; ">
            <mat-form-field>
              <mat-select #country="ngModel" name="ddlCountry-{{rowIdx}}" placeholder="Country"
                [(ngModel)]="row.countryId" required>
                <mat-option>
                    <ngx-mat-select-search [formControl]="countryFilterCtrl"
                        placeholderLabel="Find contact..."
                        noEntriesFoundLabel="no matching contact found"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country.countryId">
                  {{ country.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="country?.invalid">
                <div *ngIf="country.errors.required">required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="customsCommodityCode">
          <mat-header-cell *matHeaderCellDef>
            Customs Commodity Code
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput #customsCommodityCode="ngModel" name="txtCustomsCommodityCode-{{rowIdx}}"
                placeholder="Customs Commodity Code" [(ngModel)]="row.customsCommodityCode" required>
              <mat-error *ngIf="customsCommodityCode?.invalid">
                <div *ngIf="customsCommodityCode.errors.required">required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="unitValue">
          <mat-header-cell *matHeaderCellDef>
            Unit Value
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput type="number" #unitValue="ngModel" name="txtUnitValue-{{rowIdx}}" placeholder="UnitValue"
                [(ngModel)]="row.unitValue" (ngModelChange)="calculateUnitPrice($event,rowIdx)" (keypress)="numberOnly($event)" required>
              <mat-error *ngIf="unitValue?.invalid">
                <div *ngIf="unitValue.errors.required">required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalValue">
          <mat-header-cell *matHeaderCellDef>
            Total Value
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput #totalValue="ngModel" name="txtTotalValue-{{rowIdx}}" placeholder="TotalValue"
                [(ngModel)]="row.totalValue" disabled>
              <mat-error *ngIf="totalValue?.invalid">
                <div *ngIf="totalValue.errors.required">required</div>
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderId">
           <mat-cell *matCellDef="let row; let rowIdx = index;">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput #orderId="ngModel" name="txtdescription-{{rowIdx}}" placeholder="orderId"
                [(ngModel)]="row.orderId" required>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns ; let i = index; " [hidden]="row.isValid"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" [disabled]="proformaForm.invalid"
        (click)="save();">Save</button>
      <button mat-raised-button  type="submit" color="primary" (click)="add();">Add</button>
     </mat-card-actions>
  </mat-card>
</form>