import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'app/_shared/user.services';
import { FormGroup } from '@angular/forms';
import { BillingDetails } from '../orders/new-order/order.model';
import { environment } from 'environments/environment';
import { UserDetails } from '../profile/profile.model';
import { ConversionRates, MarketRateStatus } from './settings.model';

@Injectable()
export class SettingsService implements Resolve<any> {

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private _userService: UserService,) {
        // Set the defaults
    }
    conversionRates: ConversionRates[];
    marketRateStatusToggle: boolean;
    marketRateStatus: MarketRateStatus;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    //     return new Promise((resolve, reject) => {
    //         // Promise.all([this.getTimeline(), this.getAbout(), this.getPhotosVideos()]).then(() => {
    //             Promise.all([this.getMyInfo()]).then(() => {
    //             resolve();
    //         }, reject);
    //     });
    // }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        // console.log('hererere');
        // return new Promise((resolve, reject) => {
        //      {
        //         Promise.all([this.getConversionRates()]).then(() => {
        //             resolve(this);
        //         }, reject);
        //     }
        // });
    }
    getMyInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<UserDetails>(environment.apiUrl + 'v1/Users/MyInfo/').subscribe((response: UserDetails) => {
                resolve(response);
            }, reject);
        });
    }
    getConversionRates(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Users/Rates/')
                .subscribe((conversionRates: ConversionRates[]) => {
                    this.conversionRates = conversionRates;
                    resolve(conversionRates);
                }, reject);
        });
    }

    getLatestRate(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Users/LatestRate')
                .subscribe((conversionRates: ConversionRates[]) => {
                    this.conversionRates = conversionRates;
                    resolve(conversionRates);
                }, reject);
        });
    }

    saveConversionRates(conversionRates: ConversionRates): Promise<any> {
        console.log(conversionRates);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Users/Rates/', conversionRates)
                .subscribe((conversionRates: ConversionRates[]) => {
                    this.conversionRates = conversionRates;
                    resolve(conversionRates);
                }, reject);
        });
    }

    saveMarketRateSettings(marketRateStatus: MarketRateStatus): Promise<any> {
        console.log(marketRateStatus);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Rates/EnableClientMarketRate', marketRateStatus)
                .subscribe((marketRateStatuses: MarketRateStatus) => {
                    this.marketRateStatus = marketRateStatuses;
                    resolve(marketRateStatus);
                }, reject);
        });
    }

    getMarketRateSettings(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Rates/ClientMarketRateStatus/' + localStorage.getItem('subsidiaryId'))
            .subscribe((enabled: boolean) => {
                this.marketRateStatusToggle = enabled;
                resolve(enabled);
            }, reject);
        });
    }
}
