import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DistributionEvent } from '../list/Distribution-events-dialog/distribution-event.model';
import { DistributionViewService } from './distribution-view.service';
import { Distribution, DistributionView } from '../new-distribution/distribution.model';
import { DistributionListService } from '../list/distribution-list.service';

@Component({
    selector: 'distribution-view',
    templateUrl: './distribution-view.component.html',
    styleUrls: ['./distribution-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DistributionViewComponent implements OnInit, AfterViewChecked {
    // distributions: DistributionView;
    distribution: any;
    // @ViewChild('orderDetailsView', { static: false }) orderDetailsView: OrderDetailsViewComponent;
    // @ViewChild('orderStatusView', { static: false }) orderStatusView: OrderStatusViewComponent;
    lastEvent: DistributionEvent;
    trustedInvoiceUrl: SafeUrl;
    constructor(
        private _distributionService: DistributionListService,
        private _distributionViewService: DistributionViewService,
        private _cdRef: ChangeDetectorRef,
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this._distributionViewService.onDistributionSelected.subscribe(distribution => {
            this.distribution = distribution;
        });
    }

    lastEventTriggered(event: DistributionEvent): void {
        this.lastEvent = event;
    }

    ngAfterViewChecked(): void {
        this._cdRef.detectChanges();
    }
}
