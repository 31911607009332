import { Component, OnInit, Input } from '@angular/core';
import { OrderViewAddress } from '../../order-view.model';

@Component({
    selector: 'order-contact-address-view',
    templateUrl: './address-view.component.html',
    styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {
    @Input() address: OrderViewAddress;
    @Input() contactType: string;
    @Input() netpoint: boolean;

    constructor() {}

    ngOnInit(): void {}
}
