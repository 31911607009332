import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { OrderService } from '../order.service';
import { OrderViewService } from '../view/order-view.service';
import { OrderView } from '../view/order-view.model';
import { BillingDetails, CashOnDelivery } from '../new-order/order.model';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QuoteService } from 'app/main/quotes/quote.service';
import { OrdersOrderListService } from '../list/order-list.service';

@Component({
    selector: 'invoice-print',
    templateUrl: './invoice-print.component.html',
    styleUrls: ['./invoice-print.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoicePrintComponent implements OnInit, OnDestroy {
    orders: any;
    date: Date = new Date();
    billingDetails: BillingDetails;
    orderCods: CashOnDelivery[] = [];
    private _unsubscribeAll: Subject<any>;
    proformaSum: number;

    Arr = Array;

    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _orderViewService: OrderViewService,
        private orderViewService: OrdersOrderListService, private _orderService: OrderService, private _quoteService: QuoteService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._orderViewService.onOrdersSelected.subscribe(order => {
            this.orders = order;
        });
        this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
            this.billingDetails = info;
        });

    }
    getSum(index): number {
        let sum = 0;
        const proformaDetails = this.orders[index].proformaDetails
        for (let i = 0; i < proformaDetails.length; i++) {
            sum += proformaDetails[i]["totalValue"];
        }
        return sum;
    }
    pageNumber(numberOfPieces: number) {
        return Array.from(new Array(numberOfPieces), (x, i) => i + 1)
    }
    ngAfterInitView() {
        window.print();
    }
    onPrint() {
        window.print();
    }
    printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
