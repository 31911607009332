<div class="p-32 quote-loader fuse-card">
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">

        <div *ngIf="loader" class="big-circle app-logo" fxLayout="column" fxLayoutAlign="center center"
            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <mat-spinner color="accent" mode="indeterminate"></mat-spinner>
        </div>

        <span class="app-message" [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
            {{ message }}
        </span>
    </div>
</div>