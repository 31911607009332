import { NgModule } from '@angular/core';
import { ContactsContactListComponent } from './contact-list/contact-list.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'app/_guards/auth.guard';
import { ContactsContactListService } from './contact-list/contact-list.service';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCardModule,
    MatAutocompleteModule, MatRadioButton, MatRadioModule, MatProgressBar, MatProgressBarModule, MatProgressSpinnerModule, MatButtonToggleModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ContactFormModule } from './contact-form/contact-form.module';
import { ContactEditorModule } from './contact-editor/contact-editor.module';
import { SheetJSComponent } from './sheet-jscomponent/sheet-jscomponent.component';
import { UserTableComponent } from './user-table/user-table.component';
import { AutoCompleteCountryModule } from 'app/_shared/auto-complete-country/auto-complete-country.module';
import { AutoCompleteCountryComponent } from 'app/_shared/auto-complete-country/auto-complete-country.component';
import { GoogleLocationModule } from 'app/_shared/google-location/google-location.module';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';


const routes = [
    {
        path: 'contacts',
        canActivate: [AuthGuard],
        component: ContactsContactListComponent,
        resolve: {
            data: ContactsContactListService
        }
    }
];

@NgModule({
    declarations: [ContactsContactListComponent, SheetJSComponent, UserTableComponent],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        FuseConfirmDialogModule,
        ContactFormModule,
        ContactEditorModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDatepickerModule,
        AutoCompleteCountryModule,
        MatAutocompleteModule,
        GoogleLocationModule,
        MatCardModule,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatRadioModule,
        MatProgressBarModule,
        ScrollingModule ,
        MatButtonToggleModule
     
    ],
    providers: [ContactsContactListService]
})
export class ContactsModule { }
