import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { userInfo } from 'os';

@Injectable()
export class PaymentDoneService implements Resolve<any> {
    retryCount = 0;
    success = false;
    
    constructor(private _httpClient: HttpClient, private _router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.payOrder()]).then(() => {
                resolve();
            }, reject);
        });
    }

    payOrder(): Promise<any> {
        return new Promise((resolve, reject) => {
            const orderId = localStorage.getItem('orderId');
            if (orderId === undefined || orderId === null || orderId.length === 0) {
                this._router.navigateByUrl(Routes.orders);
                reject();
            }
            else
            {
                localStorage.removeItem('orderId');
                this.success = true;
                resolve();
            }

            //condition if walkinClient or not
            // this._httpClient.post(environment.apiUrl + 'v1/Orders/Confirm/' + orderId, null).subscribe(
            //     _ => {
            //         localStorage.removeItem('orderId');
            //         this.success = true;
            //         resolve();
            //     },
            //     error => {
            //         if (this.retryCount >= 2) {
            //             resolve();
            //             return;
            //         }
            //         this.payOrder().then(() => resolve(), reject);
            //         this.retryCount++;
            //     }
            // );

            // this._httpClient.post(environment.apiUrl + 'v1/Orders/PayOrder/' + orderId, null).subscribe(
            //     _ => {
            //         localStorage.removeItem('orderId');
            //         this.success = true;
            //         resolve();
            //     },
            //     error => {
            //         if (this.retryCount >= 2) {
            //             resolve();
            //             return;
            //         }
            //         this.payOrder().then(() => resolve(), reject);
            //         this.retryCount++;
            //     }
            // );
        });
    }
}
