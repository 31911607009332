import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Address, Contact } from 'app/main/contacts/contact-form/contact.model';
import { ContactsContactListService } from 'app/main/contacts/contact-list/contact-list.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Countries, InternationalQuote } from 'app/main/quotes/international/international.model';
import { ServiceType, CommodityProductType } from 'app/_enums/ServiceType.enum';
import { Entity } from 'app/_models/Entity.model';
import { QuoteService } from 'app/main/quotes/quote.service';
import { DomesticQuote } from 'app/main/quotes/domestic/domestic.model';
import { ContactEditorComponent } from 'app/main/contacts/contact-editor/contact-editor.component';
import { CommodityService, Commodity } from 'app/_shared/commodity.service';
import { WeightValidator } from 'app/_validators/weight.validator';
import { DecimalPipe } from '@angular/common';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { ConfirmDistributionComponent } from './confirm-distribution/confirm-distribution.component';
import { DistributionService } from '../../distribution.service';
import { DistributionDetail, DistributionBillingDetails } from '../distribution.model';
import { NetPointLocationsComponent } from 'app/main/orders/new-order/netpoint-locations/netpoint-locations.component';
import { NetPointService } from 'app/main/orders/new-order/netpoint-locations/netpoint.services';
import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { ConfirmCheckoutComponent } from 'app/main/orders/new-order/payment/confirm-checkout/confirm-checkout.component';
import { ContactsListService } from 'app/main/c-list/c-list/c-list.service';
import { CListItem } from 'app/main/c-list/c-list/c-list-item.model';
import { ContactList, ContactListDetail } from 'app/main/c-list/c-list-form/c-list.model';
import { ContactListEditorComponent } from 'app/main/c-list/c-list-editor/c-list-editor.component';
import { SelectionModel } from '@angular/cdk/collections';
import { LocationService } from 'app/_shared/location-service.service';
import { Country } from 'app/_models/country.model';
import { isNullOrUndefined } from 'util';
import { BillingDetails } from 'app/main/orders/new-order/order.model';
import { Console } from 'console';

@Component({
    selector: 'distribution-distribution-details',
    templateUrl: './distribution-details.component.html',
    styleUrls: ['./distribution-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DistributionsOrderDetailsComponent implements OnInit, OnDestroy {
    @Input() group: FormGroup;
    @Input() hasSubsidiaryId = false;
    @Input() newQuote: FormControl;

    @Output() submited: EventEmitter<any> = new EventEmitter();
    @Output() canceled: EventEmitter<any> = new EventEmitter();
    @Output() confirmed: EventEmitter<any> = new EventEmitter();
    contacts: ContactListItem[];
    contactListContacts: ContactListItem[];

    allContacts: Contact[] = [];

    contactLists: CListItem[] = [];

    confirmCheckoutDialogRef: MatDialogRef<ConfirmCheckoutComponent>;

    confirmdistributionDialogRef: MatDialogRef<ConfirmDistributionComponent>;

    distributionDetailComponent: DistributionsOrderDetailsComponent;

    shipperAddresses: Address[] = [];
    receiverAddresses: Address[] = [];
    AddressType = AddressType;

    shippingTypes: Entity<number>[];
    timeRanges: Entity<number>[];
    commodities: Commodity[];

    ServiceDirection = ServiceDirection;

    addingShipper = false;
    addingReceiver = false;
    isExport = false;
    generatingQuote = false;

    ServiceType = ServiceType;

    private _onInitShipper = false;
    private _onInitReceiver = false;

    protected _unsubscribeAll = new Subject<void>();
    contactDialogRef: MatDialogRef<ContactEditorComponent>;
    contactListDialogRef: MatDialogRef<ContactListEditorComponent>;
    netpointLocationsDialogRef: MatDialogRef<NetPointLocationsComponent>;

    isNetPointShipper = false;
    isNetPointReceiver = false;
    minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    // toNetPointButtonText = 'To NetPoint?';
    // fromNetPointButtonText = 'From NetPoint?';

    shipperAddressLabel = 'Shipper Address';
    receiverAddressLabel = 'Receiver Address';

    // dataSource = new MatTableDataSource<ContactListItem>(this.contacts);
    // displayedColumns: string[] = ['select','fullName','email','phone','address',];
    // selection = new SelectionModel<ContactListItem>(true, []);

    countries: Country[];

    currentDirection: ServiceDirection;
    maxWeight: number;
    showDimensionsHelp = false;

    domesticCountryId = 158;

    domesticCountryName = "Lebanon";

    constructor(
        private _router: Router,
        private _contactService: ContactsContactListService,
        private _contactsContactListService: ContactsListService,
        public _matDialog: MatDialog,
        private _quoteService: QuoteService,
        private _commodityService: CommodityService,
        private _distributionService: DistributionService,
        private _netpointService: NetPointService,
        private _snackBar: MatSnackBar,
        private _numberPipe: DecimalPipe,
        private _locationService: LocationService
    ) { }

    get groupValue(): DistributionDetail {
        return this.group.getRawValue() as DistributionDetail;
    }

    get selectedShipper(): ContactListItem {
        return this.contacts.find(x => x.contactId === this.groupValue.shipperId);
    }

    // get selectedReceiver(): CListItem {
    //     return this.contactLists.find(x => x.contactListId === this.groupValue.receiverId);
    // }

    get selectedReceiverList(): CListItem {
        return this.contactLists.find(x => x.contactListId === this.groupValue.receiverId);
    }

    get selectedShipperAddress(): Address {
        return this.shipperAddresses.find(x => x.addressId === this.groupValue.shipperAddressId);
    }
    get distributionDetail(): DistributionDetail {
        const distribution = new DistributionDetail(this.group.getRawValue());
        distribution.shipperName = this.contacts.find(c => c.contactId === distribution.shipperId).fullName;
        distribution.receiverName = this.contactLists.find(x => x.contactListId === this.groupValue.receiverId).description;

        distribution.receiverCount = this.contactLists.find(x => x.contactListId === this.groupValue.receiverId).contactListDetail.length;

        distribution.shipperAddress = this.toLongDisplayString(
            this.shipperAddresses.find(c => c.addressId === distribution.shipperAddressId)
        );
        // distribution.receiverAddress = this.toLongDisplayString(
        //     this.receiverAddresses.find(c => c.addressId === distribution.receiverAddressId)
        // );
        return distribution;
    }

    // get selectedReceiverAddress(): Address {
    //     return this.receiverAddresses.find(x => x.addressId === this.groupValue.receiverAddressId);
    // }

    ngOnInit(): void {

        this.hasSubsidiaryId = true;

        this._onInitReceiver = true;
        this._onInitShipper = true;



        // this.isNetPointShipper = this.group.get('isNetPointShipper').value;
        // this.isNetPointReceiver = this.group.get('isNetPointReceiver').value;

        this._distributionService.getMyInfo().subscribe((info: BillingDetails) => {
            this.domesticCountryId = info.countryId;
            this.domesticCountryName = info.countryName;
            console.log(info);
        });



        this.getTimeRanges();

        this.getContactsForOrders();
        this.getAllContactLists();
        this.getAllContacts();
        this.subscribeAllContactsChanged();
        const quote = this.group.value.quote as QuoteData;
        if (quote.type === undefined || quote.type === null || quote.type === ServiceDirection.Domestic) {
            this.prepareShippingTypes();
        } else if (quote.type !== undefined && quote.type !== null && quote.serviceTypeId !== 0) {
            this.prepareShippingTypes(quote.type, quote.serviceTypeId as ServiceType);
        }
        this.subscribeShipperChanged();
        this.subscribeReceiverChanged();
        this.subscribeContactsChanged();
        this.subscribeContactAdded();

        this.subscribeContactListsChanged();
        this.subscribeContactListsAdded();

        this.subscribeShipperAddressChanged();
        this.subscribeReceiverAddressChanged();

        this.subscribeDirectionChanged();
        this.subscribeShippingTypeChanged();

        this.subscribeQuoteChanged();

        this.setDirection();

        this.getCommodities();

        this.setDecimalValidation();
        this.getCountries();
        this._contactsContactListService.getContactsForContactList().then(contacts => {
            this.contactListContacts = contacts;
        });

    }

    private setDecimalValidation(): void {

        const quoteGroup = this.group.get('quote') as FormGroup;
        const length = quoteGroup.get('length');
        const width = quoteGroup.get('width');
        const height = quoteGroup.get('height');


        length.setValidators([DecimalValidator.validDecimal(0, 200)]);
        width.setValidators([DecimalValidator.validDecimal(0, 150)]);
        height.setValidators([DecimalValidator.validDecimal(0, 150)]);


        const shipmentValue = quoteGroup.get('shipmentValue');

        shipmentValue.setValidators([DecimalValidator.validDecimal(0, 9999999999999999)]);

        // const quoteGroup = this.group.get('quote') as FormGroup;
        // const length = quoteGroup.get('length');

        // length.setValidators([DecimalValidator.validDecimal(0, 999)]);

        // const width = quoteGroup.get('width');

        // width.setValidators([DecimalValidator.validDecimal(0, 999)]);

        // const height = quoteGroup.get('height');

        // height.setValidators([DecimalValidator.validDecimal(0, 999)]);

        // const shipmentValue = quoteGroup.get('shipmentValue');

        // shipmentValue.setValidators([DecimalValidator.validDecimal(0, 99999)]);
    }

    weightChanged(event): void {
        const weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.group
                .get('quote')
                .get('weight')
                .setValue('0.5');
        }
    }
    onTimeRangeChange(range): void {
    }

    private getTimeRanges(): void {
        this._distributionService.getTimeRanges().subscribe(result => {
            this.timeRanges = result
            const timeRangeLookupId = this.group.get('timeRangeLookupId').value;
            if (isNullOrUndefined(timeRangeLookupId)) {
                this.group.get('timeRangeLookupId').setValue(this.timeRanges[0].id);
            }
        });
    }
    private getCommodities(): void {
        let serviceTypeId: MyNetServiceType;
        this.setDirection();
        switch (this.currentDirection) {
            case ServiceDirection.Domestic:
                serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                serviceTypeId = 0;
                break;
        }
        if (!serviceTypeId) {
            return;
        }

        let forceGetCommodities = false;

        if (this._commodityService.commodities && this._commodityService.commodities.length > 0) {
            const sameServiceType =
                this._commodityService.commodities.filter(x => x.serviceTypeId === serviceTypeId).length > 0;
            if (sameServiceType) {
                this.commodities = this._commodityService.commodities;
            } else {
                forceGetCommodities = true;
            }
        } else {
            forceGetCommodities = true;
        }

        if (forceGetCommodities) {
            this._commodityService.getCommodities(serviceTypeId).then(result => {
                this.commodities = result;
                this.subscribeCommodityChanged();
            });
        }
    }

    subscribeCommodityChanged(): void {
        this.group
            .get('commodityId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(commodityId => {
                if (commodityId) {
                    const commodity = this.commodities.find(x => x.commodityId === commodityId);

                    const serviceTypeId = this.group.get('quote').get('serviceTypeId');
                    if (serviceTypeId) {
                        serviceTypeId.setValue(commodity.erpProductTypeId);
                    }
                }
            });
    }

    public getContactsForOrders(firstTime = true): void {
        this._contactService.getContactsForOrders().then(contacts => {
            this.contacts = contacts;
            // if (this.group.value.receiverId && firstTime) {
            //     this.receiverChanged(this.group.value.receiverId);
            // }

            if (this.group.value.shipperId && firstTime) {
                this.shipperChanged(this.group.value.shipperId);
            }
            // this.dataSource = new MatTableDataSource<ContactListItem>(this.contacts);
        });
    }

    public getAllContacts(firstTime = true): void {
        this._contactService.getContacts().then(contacts => {
            this.allContacts = contacts;
        });
    }

    public getAllContactLists(firstTime = true): void {
        this._contactsContactListService.getContactLists().then(contacts => {
            this.contactLists = contacts.body;
            // if (this.group.value.receiverId && firstTime) {
            //     this.receiverChanged(this.group.value.receiverId);
            // }

            // if (this.group.value.shipperId && firstTime) {
            //     this.shipperChanged(this.group.value.shipperId);
            // }
        });

    }
    private subscribeContactListsChanged(): void {
        this._contactsContactListService.onContactsChanged.subscribe(contacts => {
            this.contactLists = contacts;
        });
    }
    private subscribeContactListsAdded(): void {
        this._contactsContactListService.onContactAdded.subscribe(contactId => {
            if (this.addingReceiver) {
                this.group.get('receiverId').setValue(contactId);
            }
            this.addingReceiver = false;
        });
    }


    private subscribeQuoteChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
            this.newQuote.setValue(true);
        });
    }

    private subscribeShippingTypeChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shippingTypeId => {
                if (shippingTypeId) {
                    const shippingType = this.shippingTypes.find(s => s.id === shippingTypeId);
                    quoteGroup.get('shippingType').setValue(shippingType.name);
                } else {
                    quoteGroup.get('shippingType').setValue('');
                }
            });
    }

    private prepareShippingTypes(
        direction: ServiceDirection = ServiceDirection.Domestic,
        serviceType: ServiceType = ServiceType.Parcels
    ): void {
        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        this._quoteService.getShippingTypes(direction, serviceType).subscribe(result => {
            this.shippingTypes = result;
            if (this.shippingTypes && (this.shippingTypes.length === 1 || this.shippingTypes.length === 2)) {
                const quoteGroup = this.group.get('quote') as FormGroup;

                quoteGroup.get('shippingTypeId').setValue(this.shippingTypes[0].id);
                quoteGroup.get('shippingType').setValue(this.shippingTypes[0].name);
            }
        });
    }

    private subscribeDirectionChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup
            .get('type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((type: ServiceDirection) => {
                this.group.get('commodityId').setValue('');
                this.getCommodities();

                if (type === undefined) {
                    return;
                }
                this.isExport = type === ServiceDirection.Export;

                // here
                quoteGroup.get('shippingType').setValue('');
                quoteGroup.get('shippingTypeId').setValue('');
                quoteGroup.get('shippingTypeId').markAsUntouched();
                if (this.isExport) {
                    quoteGroup.addControl('serviceTypeId', new FormControl('', Validators.required));
                    this.shippingTypes = [];
                    quoteGroup.get('shippingType').setValue('');
                    const value = quoteGroup.get('serviceTypeId').value;
                    if (value) {
                        this.prepareShippingTypes(type, value as ServiceType);
                    }
                    quoteGroup.get('serviceTypeId').valueChanges.subscribe(serviceTypeId => {
                        this.prepareShippingTypes(type, serviceTypeId as ServiceType);
                    });
                } else {
                    quoteGroup.removeControl('serviceTypeId');
                    this.prepareShippingTypes();
                }
            });
    }

    private subscribeShipperAddressChanged(): void {
        this.group
            .get('shipperAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(addressId => {
                const quoteGroup = this.group.get('quote') as FormGroup;
                let problems = false;
                if (addressId) {
                    const selectedAddress = this.shipperAddresses.find(a => a.addressId === addressId) as Address;
                    if (selectedAddress) {
                        const address = this.toLongDisplayString(selectedAddress);
                        if (this.isNetPointShipper) {
                            quoteGroup.get('fromCity').setValue(selectedAddress.display + ', ' + address);
                        } else {
                            quoteGroup.get('fromCity').setValue(address);
                        }
                        quoteGroup.get('fromCityId').setValue(selectedAddress.cityId);
                        quoteGroup.get('fromCountryId').setValue(selectedAddress.countryId);
                    } else {
                        problems = true;
                    }
                } else {
                    problems = true;
                }
                if (problems) {
                    quoteGroup.get('fromCity').setValue('');
                    quoteGroup.get('fromCityId').setValue('');
                    quoteGroup.get('fromCountryId').setValue('');
                }

                this.updateDirection();
            });
    }

    private subscribeReceiverAddressChanged(): void {
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contactListId => {
                const quoteGroup = this.group.get('quote') as FormGroup;
                let problems = false;
                if (contactListId) {
                    const selectedContactList = this.contactLists.find(a => a.contactListId === contactListId) as ContactList;
                    if (selectedContactList) {
                        if (selectedContactList.contactListDetail) {
                            const contactListDetail = selectedContactList.contactListDetail[0] as ContactListDetail;
                            if (contactListDetail) {
                                // const InitialCityContact = this.allContacts.find(a => a.contactId === selectedContactList.contactListDetail[0].ContactId) as Contact;
                                // if(InitialCityContact){
                                // const selectedAddress = this.shipperAddresses.find(a => a.addressId === InitialCityContact.addresses[0].addressId) as Address;

                                quoteGroup.get('toCityId').setValue(204);
                                quoteGroup.get('toCountryId').setValue(158);
                                //     }
                                //     else{
                                //         problems = true;
                                //     }
                            }
                        }
                    } else {
                        problems = true;
                    }
                } else {
                    problems = true;
                }
                if (problems) {
                    quoteGroup.get('toCity').setValue('');
                    quoteGroup.get('toCityId').setValue('');
                    quoteGroup.get('toCountryId').setValue('');
                }

                this.updateDirection();
            });
    }

    updateDirection(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        this.setDirection();

        quoteGroup.get('type').setValue(this.currentDirection);
    }

    private setDirection(): ServiceDirection {
        const quoteGroup = this.group.get('quote') as FormGroup;
        // const fromCountryId = quoteGroup.get('fromCountryId').value;
        // const toCountryId = quoteGroup.get('toCountryId').value;

        // if (!fromCountryId || !toCountryId) {
        //     return undefined;
        // }
        if (!quoteGroup) {
            return undefined;
        }

        // if (fromCountryId === Countries.Lebanon && toCountryId === Countries.Lebanon) {
        // domestic
        this.maxWeight = WeightValidator.MaxDomesticWeight;
        this.currentDirection = ServiceDirection.Domestic;
        // } else if (fromCountryId === Countries.Lebanon) {
        //     // Export
        //     this.maxWeight = WeightValidator.MaxExportWeight;
        //     this.currentDirection = ServiceDirection.Export;
        // } else if (toCountryId === Countries.Lebanon) {
        //     // Import
        //     this.maxWeight = undefined;
        //     this.currentDirection = ServiceDirection.Import;
        // } else {
        //     // Not Supported
        //     this.maxWeight = undefined;
        //     this.currentDirection = undefined;
        // }

        const weight = quoteGroup.get('weight');

        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    }

    toLongDisplayString(address: Address): string {
        let message = address.cityName + ', ' + address.countryName + ', ' + address.street;
        if (address.building) {
            message += ', ' + address.building;
        }

        if (address.floor) {
            message += ', ' + address.floor;
        }

        if (address.zipCode) {
            message += ', ' + address.zipCode;
        }

        if (address.landmark) {
            message += ', ' + address.landmark;
        }

        return message;
    }

    private subscribeShipperChanged(): void {
        this.group
            .get('shipperId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shipperId => {
                this.shipperChanged(shipperId);
            });
    }

    private shipperChanged(shipperId: number): void {
        if (!this._onInitShipper) {
            this.group.get('shipperAddressId').reset();
            this.shippingTypes = [];
            // this.resetShipperNetPoint();
        } else {
            this._onInitShipper = false;
        }
        this._contactService.getContact(shipperId).then((contact: Contact) => {
            if (contact.addresses) {
                this.shipperAddresses = contact.addresses.filter(item => item.countryId === this.domesticCountryId);
            }
            // if (this.group.get('isNetPointShipper').value) {
            //     if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
            //         this.selectShipperNetPoint(this._netpointService.netpoints.slice());
            //     } else {
            //         this._netpointService.onNetPointsRetreived.subscribe(locations => {
            //             this.selectShipperNetPoint(locations.slice());
            //         });
            //     }
            // } else 
            if (this.shipperAddresses && this.shipperAddresses.length === 1) {
                this.group.get('shipperAddressId').setValue(this.shipperAddresses[0].addressId);
            }
        });
    }

    // private selectShipperNetPoint(locations: Address[]): void {
    //     const location = locations.find(l => l.addressId === this.group.get('shipperAddressId').value);
    //     this.resetShipperNetPoint();
    //     this.netpointSelect(location, AddressType.Shipper);
    // }

    private subscribeReceiverChanged(): void {
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(receiverId => {
                this.receiverChanged(receiverId);
            });
    }

    private receiverChanged(receiverId: number): void {
        if (!this._onInitReceiver) {
            // this.group.get('receiverAddressId').reset();
            this.shippingTypes = [];
            // this.resetReceiverNetPoint();
        } else {
            this._onInitReceiver = false;
        }
        // this._contactsContactListService.getContactList(receiverId).then((contact: ContactList) => {
        //     this.receiverAddresses = contact.addresses;
        //     if (this.group.get('isNetPointReceiver').value) {
        //         if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
        //             this.selectReceiverNetPoint(this._netpointService.netpoints.slice());
        //         } else {
        //             this._netpointService.onNetPointsRetreived.subscribe(locations => {
        //                 this.selectReceiverNetPoint(locations.slice());
        //             });
        //         }
        //     } else 
        //     if (this.receiverAddresses && this.receiverAddresses.length === 1) {
        //         this.group.get('receiverAddressId').setValue(this.receiverAddresses[0].addressId);
        //     }
        // });
    }

    // private selectReceiverNetPoint(locations: Address[]): void {
    //     const location = locations.find(l => l.addressId === this.group.get('receiverAddressId').value);
    //     this.resetReceiverNetPoint();
    //     this.netpointSelect(location, AddressType.Receiver);
    // }

    private subscribeContactAdded(): void {
        this._contactService.onContactAdded.subscribe(contactId => {
            if (this.addingShipper) {
                this.group.get('shipperId').setValue(contactId);
                // } else if (this.addingReceiver) {
                //     this.group.get('receiverId').setValue(contactId);
            }
            this.addingShipper = false;
            // this.addingReceiver = false;
        });
    }

    private subscribeContactsChanged(): void {
        this._contactService.onContactsChanged.subscribe(contacts => {
            this.contacts = contacts;
        });
    }
    private subscribeAllContactsChanged(): void {
        this._contactService.getContacts().then(contacts => {
            this.allContacts = contacts;
        });
    }

    submitForm(): void {
        if (this.group.invalid) {
            return;
        }
        this.submited.emit(5);
    }
    confirmDistribution(): void {
        if (this.group.invalid) {
            return;
        }

        this.generateNewQuote();
        this.ConfirmDialog();
        // this.saveOrderDetails();


    }
    ConfirmDialog(): void {

        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        const data = this.group.getRawValue() as DistributionDetail;

        this.confirmdistributionDialogRef = this._matDialog.open(ConfirmDistributionComponent, {
            panelClass: 'confirm-distribution',
            data: {
                order: this.distributionDetail
            }
        });
        this.confirmdistributionDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }
            const ConfirmedOrderDetail = response[1] as DistributionDetail;
            //TODO
            if (ConfirmedOrderDetail.distributionId === 0) {
                this._distributionService.addDistribution(data).then((result: any) => {
                    // localStorage.setItem('distributionId', result.orderId.toString());
                    this.afterSaveConfirmOrder(result);

                    // this._distributionService.addDistributionDetails
                });
            } else if (ConfirmedOrderDetail.distributionId > 0) {
                this._distributionService.updateDistributionDetails(data).then((result: DistributionBillingDetails) => {
                    // localStorage.setItem('distributionId', result.distributionId.toString());
                    this.afterSaveConfirmOrder(result);
                });
            }
        });
    }
    private afterSaveConfirmOrder(result: DistributionBillingDetails): void {
        const quoteForm = this.group.get('quote');

        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.group.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this._router.navigateByUrl(Routes.distributions);
    }


    cancel(): void {
        this.canceled.emit();
    }

    backtoDistributions(): void {
        this._router.navigateByUrl(Routes.distributions);
    }

    generateNewQuote(): void {
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        this.generatingQuote = true;
        let generateQuote;
        const quoteForm = this.group.get('quote');
        const quote = quoteForm.value as QuoteData;
        quote.direction = quote.type;

        console.log(quote.type);
        if (quote.type === ServiceDirection.Export) {
            generateQuote = this._quoteService.generateInternationalQuote(quote as InternationalQuote);
        } else {
            generateQuote = this._quoteService.generateDomesticQuote(quote as DomesticQuote);
        }

        generateQuote.subscribe(result => {
            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrencyId').setValue(result.currency);
            this.newQuote.setValue(false);
            this.generatingQuote = false;
        });
    }

    addNewShipper(): void {
        this.addNewContact(_ => (this.addingShipper = true));
    }

    addNewReceiver(): void {
        this.addNewContact(_ => (this.addingReceiver = true));
    }
    private getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.countries = success;
            });
        });
    }
    addNewContact(beforeSave): void {
        this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                countries: this.countries
            }
        });

        this.contactDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }

            const contact = response[1] as Contact;
            const tags = response[2] as string[];

            contact.tags = tags;
            beforeSave();
            this._contactService.addNewContact(contact);
        });
    }

    editContact(contactId: number): void {
        this._contactService.getContact(contactId).then((c: Contact) => {
            this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contact: c,
                    countries: this.countries
                }
            });

            this.contactDialogRef.afterClosed().subscribe(response => {
                if (!response) {
                    return;
                }
                const status = response[0] as string;
                if (status === 'cancel') {
                    return;
                }

                const contact = response[1] as Contact;
                const tags = response[2] as string[];

                contact.tags = tags;

                this._contactService.editContact(contact).then(_ => this.getAllContacts(true));
            });
        });
    }
    addNewReceiverList(): void {
        this.addNewContactList(_ => (this.addingReceiver = true));
    }
    addNewContactList(beforeSave): void {
  
        this.contactListDialogRef = this._matDialog.open(ContactListEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                // contactList: c,
                // contactListDetail : c.contactListDetail,
               
                contacts: this.contactListContacts
            }
        });

        this.contactListDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }

            const contactlist = response[1] as ContactList;

            const tags = response[2] as string[];
            if (contactlist.contactListDetail != null) {
                contactlist.contactListDetail.length = 0;
                contactlist.contactListDetail = response[3]
            }
            contactlist.tags = tags;
            beforeSave();
            this._contactsContactListService.addNewContact(contactlist);
            // this.groupValue.receiverId = contactlist.contactListId;
        });
    }
    editContactList(contactListId: number): void {

        this._contactsContactListService.getContactList(contactListId).then((c: ContactList) => {
            this.contactListDialogRef = this._matDialog.open(ContactListEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contactList: c,
                    contactListDetail: c.contactListDetail,
                    contacts: this.contacts
                }
            });

            this.contactListDialogRef.afterClosed().subscribe(response => {
                if (!response) {
                    return;
                }
                const status = response[0] as string;
                if (status === 'cancel') {
                    return;
                }
                const contactlist = response[1] as ContactList;

                const tags = response[2] as string[];
                if (contactlist.contactListDetail != null) {
                    contactlist.contactListDetail.length = 0;
                    contactlist.contactListDetail = response[3]
                }
                contactlist.tags = tags;


                this._contactsContactListService.editContact(contactlist).then(_ => this.getAllContactLists(true));
            });
        });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }

        return true;
    }

    // isAllSelected() {
    //     const numSelected = this.selection.selected.length;
    //     const numRows = this.dataSource.data.length;
    //     return numSelected === numRows;
    //   }

    //   /** Selects all rows if they are not all selected; otherwise clear selection. */
    //   masterToggle() {
    //     this.isAllSelected() ?
    //         this.selection.clear() :
    //         this.dataSource.data.forEach(row => this.selection.select(row));
    //   }

    //   /** The label for the checkbox on the passed row */
    //   checkboxLabel(row?: ContactListItem): string {
    //     if (!row) {
    //       return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    //     }
    //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.contactId + 1}`;
    //   }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export enum AddressType {
    Shipper,
    Receiver
}
