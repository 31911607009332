import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Routes } from 'app/Routes';

@Injectable()
export class ReportService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private router: Router) {
    }
    selectedReport: any;
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            if (route.routeConfig.path == "reports/cod") {
                Promise.all([this.getCodDetailsReport()]).then(() => {
                    resolve(this);
                }, reject);
            }
            else if (route.routeConfig.path == "reports/pod") {
                Promise.all([this.getPodDetailsReport()]).then(() => {
                    resolve(this);
                }, reject);
            }
            else if (route.routeConfig.path == "reports/dashboard") {
                Promise.all([this.getClientDashboard()]).then(() => {
                    resolve(this);
                }, reject);
            }
            else if (route.routeConfig.path == "reports/fulfillment") {
                Promise.all([this.getFulfillmentReport()]).then(() => {
                    resolve(this);
                }, reject);
            }
            else if (route.routeConfig.path == "reports/crossdocking") {
                Promise.all([this.getCrossDockingReport()]).then(() => {
                    resolve(this);
                }, reject);
            }
        });
    }
    getCodDetailsReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Reports/CodDetails').subscribe((response: any) => {
                this.selectedReport = response;
                resolve(response);
            }, reject);
        });
    }
    getPodDetailsReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Reports/PodDetails').subscribe((response: any) => {
                this.selectedReport = response;
                resolve(response);
            }, reject);
        });
    }
    getFulfillmentReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Reports/Fulfillment').subscribe((response: any) => {
                this.selectedReport = response;
                resolve(response);
            }, reject);
        });
    }
    getClientDashboard(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Reports/Dashboard').subscribe((response: any) => {
                this.selectedReport = response;
                resolve(response);
            }, reject);
        });
    }
    getCrossDockingReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Reports/crossdocking').subscribe((response: any) => {
                this.selectedReport = response;
                resolve(response);
            }, reject);
        });
    }
    // getCodDetailsReport() {
    //     return this._httpClient.get(environment.apiUrl + 'v1/Reports/CodDetails');
    // }
    // getPodDetailsReport() {
    //     return this._httpClient.get(environment.apiUrl + 'v1/Reports/PodDetails');
    // }
}
