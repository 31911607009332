<div class="row" [formGroup]="group">
    <div class="col-xs-12 col-md-8">
        <div class="date row">
            <mat-form-field class="col-xs-12 col-md-6">
                <mat-label>Pickup date</mat-label>
                <input matInput name="mynet-order-pickupdate" placeholder="Date" [min]="minDate"
                    [matDatepicker]="picker" (focus)="picker.open()" readonly required formControlName="pickupDate">
                <mat-icon matSuffix>date_range</mat-icon>
                <mat-error *ngIf="group.get('pickupDate').hasError('required')">Pickup date is required</mat-error>
            </mat-form-field>
        </div>

        <ng-container
            *ngIf="form.get('currentDirection') && form.get('currentDirection').value === ServiceDirection.Domestic">
            <div class="row">
                <mat-form-field class="col-xs-12 col-md-6" *ngIf="shippingTypes && shippingTypes.length > 2">
                    <mat-label>
                        How soon do you want it received?
                    </mat-label>
                    <mat-select formControlName="shippingTypeId" name="mynet-order-shippingTypeId" required>
                        <mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">
                            {{shippingType.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="group.get('shippingTypeId').hasError('required')">Service Type is required
                    </mat-error>

                </mat-form-field>
            </div>

            <div *ngIf="shippingTypes && shippingTypes.length == 2" class="col-xs-12 col-md-6">
                <mat-label class="mb-8 row"
                    [ngClass]="group.get('shippingTypeId').hasError('required') ? 'red-fg' : ''">
                    How soon do you want it received? *
                </mat-label>
                <mat-radio-group class="mb-16" name="mynet-order-shipping-type" formControlName="shippingTypeId"
                    [ngClass]="group.get('shippingTypeId').hasError('required') ? 'red-fg' : ''" required>
                    <mat-radio-button class="mr-8" [value]="shippingTypes[0].id">
                        {{ shippingTypes[0].name }}
                    </mat-radio-button>
                    <mat-radio-button class="ml-8" [value]="shippingTypes[1].id">
                        {{ shippingTypes[1].name}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="group.get('shippingTypeId').hasError('required')">Service Type is required</mat-error>

            </div>

        </ng-container>
        <mat-datepicker #picker></mat-datepicker>

        <div class="row">
            <mat-form-field class="pl-4 no-padding-sm col-xs-12 col-md-6">
                <mat-label>Special Instructions</mat-label>
                <textarea matInput rows="3" formControlName="specialInstructions"
                    name="mynet-order-specialInstructions"></textarea>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="action-buttons">
    <button mat-raised-button *ngIf="form.value.activeListItem > 0" (click)="back()">Back</button>
    <button mat-raised-button color="primary" *ngIf="form.value.activeListItem < 6"
        (click)="continue()">Continue</button>
</div>

<div class="mt-8" *ngIf="submited">
    <mat-error *ngIf="group.get('pickupDate').hasError('required')">Pickup date is required</mat-error>
    <ng-container
        *ngIf="form.get('currentDirection') && form.get('currentDirection').value === ServiceDirection.Domestic">
        <mat-error *ngIf="group.get('shippingTypeId').hasError('required')">Service Type is required</mat-error>
    </ng-container>
</div>