import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'email-confirmed',
    templateUrl: './email-confirmed.component.html',
    styleUrls: ['./email-confirmed.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class EmailConfirmedComponent implements OnInit {
    userId: number;
    code: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(private _fuseConfigService: FuseConfigService, private _activatedRoute: ActivatedRoute) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._activatedRoute.queryParams.subscribe(params => {
            this.userId = +params.userId;
            this.code = params.code;
        });
    }

    ngOnInit(): void {
        
    }
}
