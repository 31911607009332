import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.jwtHelper = new JwtHelperService();
        this.onUserInfoUpdated = new BehaviorSubject(null);
        this.onUserLoggedIn = new Subject();
    }
    hasSubsidiaryId() {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasSubsidiaryId');
    }
    getHeardAboutUsLookups() {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HeardAboutUsLookups');
    }
    saveHeardAboutUs(data) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/HeardAboutUs', data);
    }
    hasUserIDImage() {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasUserIDImage');
    }
    register(user) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/register', user);
    }
    login(user) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Login', user);
    }
    // externalLogin(user: SocialUser): Observable<any> {
    //     return this._httpClient.post(environment.apiUrl + 'v1/authentication/SocialLogin', user);
    // }
    resetPassword(resetPassword) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ResetPassword', resetPassword);
    }
    logout() {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Logout', null);
    }
    forgotPassword(email) {
        const params = new HttpParams().append('email', email.toString());
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ForgotPassword', null, { params });
    }
    isLoggedIn() {
        const storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            return false;
        }
        const loggedInUser = JSON.parse(storage);
        const loggedInUserFound = loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0;
        return loggedInUserFound && !this.jwtHelper.isTokenExpired(tokenGetter());
    }
    getLoggedInUser() {
        const storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            this.loggedInUser = undefined;
        }
        const loggedInUser = JSON.parse(storage);
        if (loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0) {
            this.loggedInUser = loggedInUser;
        }
    }
    authenticate(user, token) {
        localStorage.setItem('loggedInUser', JSON.stringify(user));
        localStorage.setItem('subsidiaryId', JSON.stringify(user.subsidiaryId));
        localStorage.setItem('countryId', JSON.stringify(user.countryId));
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role);
        localStorage.setItem('netPointKey', user.netPointKey);
        localStorage.setItem('specialAccount', user.specialAccount);
        localStorage.setItem('bulkSettings', user.bulkSettings);
        localStorage.setItem('isMFERP', JSON.stringify(user.isMFERP));
    }
    uploadUserIDPhoto(image) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserIDImage', image, { headers });
    }
    uploadUserPhoto(image) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserImage', image, { headers });
    }
    downloadUserPhoto() {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserImage', { responseType: 'blob' });
    }
    downloadUserIDPhoto() {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserIDImage', { responseType: 'blob' });
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
