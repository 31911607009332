<div id="coming-soon" fxLayout="column">

    <div id="coming-soon-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="coming-soon-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="top">

                <div class="logo">
                    <img src="assets/images/logos/logo.svg">
                </div>

                <div class="title">Hey! Thank you for checking out our app.</div>
                <div class="subtitle">It’s not quite ready yet, but we are working hard and it will be ready in
                    approximately:
                </div>

                <fuse-countdown eventDate="2019-08-31"></fuse-countdown>

            </div>

        </div>

    </div>

</div>
