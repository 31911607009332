<div class="fuse-card" style="position: relative;" *ngIf="!loading && quoteCalculated"
    [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">

    <div class="overlay-black" *ngIf="!loading && formChanged && quoteCalculated"></div>
    <div class="p-16">
        <div class="h1 text-center" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="font-size-20-xs">{{ data.fromCity }}</span>
            <span class="text-center font-size-20-xs">
                <mat-icon class="font-size-80 w-100-p font-size-20-xs">{{ data.icon }}</mat-icon>
                <div class="h4 secondary-text">
                    <span>{{ data.direction }}</span>
                </div>
            </span>
            <span class="font-size-20-xs">{{ data.toCity }}</span>
        </div>
    </div>

    <div class="p-16 pt-0" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="data.estimatedCost > 0">
            <span *ngIf="data.currency == 'USD'" class="font-weight-300 font-size-48 line-height-48 mt-4">$</span>
            <span *ngIf="data.currency != 'USD'" class="font-weight-300 font-size-48 line-height-48 mt-4">{{ data.currency }} </span>
            <span class="font-weight-300 font-size-72 line-height-72">
                {{ data.estimatedCost | number:'.2-2'}}
            </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="data.estimatedCost === 0">
            <span class="font-weight-300 font-size-48 line-height-48">
                N/A
            </span>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="p-16 pt-4">
        <div class="mb-12" *ngIf="neededServiceType">
            <strong>Service Type: </strong>
            {{ data.serviceType }}
        </div>
        <div class="mb-12">
            <strong>Shipping Type: </strong>
            {{ data.shippingType }}
        </div>

        <div class="mb-12">
            <strong>Weight: </strong>
            {{ data.weight | number:'.2-2' }} KG
        </div>
        <div class="mb-12" *ngIf="data.width && data.height && data.length">
            <strong>Dimension: </strong>
            {{ data.length | number:'.2-2' }} cm x {{ data.width | number:'.2-2' }} cm x
            {{ data.height | number:'.2-2' }} cm
        </div>

        <div class="mb-12" *ngIf="data.direction == 'Domestic'">
            <div class="mb-12" *ngIf="data.weight >= (data.length  * data.width * data.height)/4000">
                <strong>Chargeable :</strong>
                {{ data.weight | number:'.2-2' }} KG
            </div>
            <div class="mb-12" *ngIf="data.weight < (data.length  * data.width * data.height)/4000">
                <strong>Chargeable :</strong>
                {{ (data.length * data.width * data.height)/4000 | number:'.2-2'}} KG
            </div>
        </div>
        <div class="mb-12" *ngIf="data.direction != 'Domestic'">
            <div class="mb-12" *ngIf="data.weight >= (data.length  * data.width * data.height)/5000">
                <strong>Chargeable :</strong>
                {{ data.weight | number:'.2-2' }} KG
            </div>
            <div class="mb-12" *ngIf="data.weight < (data.length  * data.width * data.height)/5000">
                <strong>Chargeable :</strong>
                {{ (data.length * data.width * data.height)/5000 | number:'.2-2' }} KG
            </div>
        </div>
    </div>

    <!-- TODO CANT RELEASE -->
    <!-- <div class="p-8 pt-0" fxLayout="row" fxLayoutAlign="center center" *ngIf="data.estimatedCost > 0">
        <button mat-flat-button color="primary" [disabled]="!allowedToProceed" (click)="placeAnOrder.emit()">
            Place order
        </button>
    </div> -->

    <div class="p-10 pt-0" *ngIf="data.estimatedCost > 0" fxLayoutAlign="center">
        <div>Contact us at <strong style="color: red; margin-left: 1px;"> {{this.hotline}} </strong> or <strong style="color: red;">{{this.contactInformation}} </strong> to place your order</div>
    </div>

</div>