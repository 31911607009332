// import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressBarModule, MatTableModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'app/_guards/auth.guard';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ClientDashboardComponent } from './clientDashboard/clientDashboard.component';
import { CodDetailsComponent } from './codDetailsReport/codDetails.component';
import { CrossDockingComponent } from './crossDocking/crossDocking.component';
import { FulfillmentComponent } from './FulfillmentReport/Fulfillment.component';
import { OrderManifestComponent } from './order-manifest/order-manifest.component';
import { PodDetailsComponent } from './podDetailsReport/podDetails.component';
import { ReportService } from './report.service';

const routes = [
    {
        path: 'reports/cod',
        component: CodDetailsComponent,
        resolve: {
            reports: ReportService
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pod',
        component: PodDetailsComponent,
        resolve: {
            reports: ReportService
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/dashboard',
        component: ClientDashboardComponent,
        resolve: {
            reports: ReportService
        },
        canActivate: [AuthGuard]
    }
    ,
    {
        path: 'reports/manifest',
        component: OrderManifestComponent,
        // resolve: {
        //     reports: ReportService
        // },
        canActivate: [AuthGuard]
    }
    ,
    {
        path: 'reports/fulfillment',
        component: FulfillmentComponent,
        resolve: {
            reports: ReportService
        },
        canActivate: [AuthGuard]
    }
    ,
    {
        path: 'reports/crossdocking',
        component: CrossDockingComponent,
        resolve: {
            reports: ReportService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [CodDetailsComponent,PodDetailsComponent,ClientDashboardComponent,OrderManifestComponent,FulfillmentComponent,CrossDockingComponent],
    imports: [
        FormsModule,
        MatIconModule,
        MatDatepickerModule,
        MatTableModule,
        NgxMatDatetimePickerModule,
        MatFormFieldModule,
        NgxBarcodeModule,
        MatInputModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        CommonModule,
        RouterModule.forChild(routes),
    ],
    providers: [ReportService]
})
export class ReportModule {}
