<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.svg">
            </div>
            <div>
                <span style="color: red;">
                    {{this.myNetVersion}}
                </span>
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate (submit)="submitForm()">

                <mat-form-field>
                    <!-- <mat-label>Email</mat-label>
                    <input matInput formControlName="email" name="mynet-login-email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" type="email"> -->

                    <!-- <mat-label>Email or Username</mat-label> -->
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" name="mynet-login-email"  type="text">


                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>


                    <!-- <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email or Username is required
                    </mat-error> -->
                 
                    <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                            (loginForm.get('email').hasError('email') || loginForm.get('email').hasError('pattern'))">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" name="mynet-login-password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column">
                    <a class="forgot-password" [routerLink]="'/auth/forgot-password'">
                        Forgot Password?
                    </a>
                </div>
                <mat-error class="error-message" *ngIf="errorMessage">
                    {{errorMessage}}
                </mat-error>

                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid">
                    LOGIN
                </button>

            </form>

            <!-- <div class="separator">
                <span class="text">OR</span>
            </div> -->

            <div>
                <!-- <button mat-raised-button class="google" (click)="signInWithGoogle()">
                    Log in with Google
                </button> -->

                <!-- <button mat-raised-button class="facebook" (click)="signInWithFB()">
                    Log in with Facebook
                </button> -->
            </div>

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/auth/register'">Create an account</a>
            </div> -->

        </div>

    </div>

</div>