import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatDialogModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { HeardAboutUsDialogComponent } from './heard-about-us-dialog.component';

@NgModule({
    declarations: [HeardAboutUsDialogComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
        MatSelectModule,
        FuseConfirmDialogModule,
        MatDialogModule
    ],
    entryComponents: [HeardAboutUsDialogComponent]
})
export class HeardAboutUsDialogModule {}
