<div class="row mb-16">
    <div class="col-md-8 col-xs-12">
        <img class="mb-16" style="max-height: 60px;" src="assets/images/payments/powered-by-areeba.png"
            alt="Powered By Areeba" />
    </div>
</div>

<div class="row mb-16">
    <div class="col-md-8 col-xs-12">
        <span class="mt-16">Click on Pay to redirect to Areeba secure payment gateway to complete the payment
            process</span>
    </div>
</div>

<mat-progress-bar *ngIf="payClicked" mode="indeterminate"></mat-progress-bar>

<div class="row mb-16">
    <div class="col-md-8 col-xs-12">
        <div class="action-buttons">
            <button mat-raised-button [disabled]="payClicked" *ngIf="checkoutLoaded" (click)="back()">Back</button>

            <ng-container *ngIf="!retryAllowed">
                <button mat-raised-button color="primary" class="mr-8" (click)="confirm()" aria-label="Confirm"
                    [disabled]="payClicked" *ngIf="checkoutLoaded" matTooltip="Confirm">
                    Pay with credit card
                </button>

                <button mat-raised-button class="w-100-p" color="warn" type="button" *ngIf="!checkoutLoaded">
                    Connecting to Payment Portal...
                </button>
            </ng-container>

            <button mat-raised-button class="w-100-p" color="primary" type="button" *ngIf="retryAllowed"
                (click)="getCheckoutSession()">
                Retry
            </button>

        </div>
    </div>
</div>