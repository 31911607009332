<div class="row">
    <div class="col-xs-12 col-md-8">
        <mat-card class="mb-16">
            <h2 class="h2">I'm shipping from</h2>
            <div>{{ form.value.shipper.firstName }} {{ form.value.shipper.lastName }}</div>
            <div>{{ form.value.shipper.email }}</div>
            <div>{{ form.value.shipper.mobileNumber }}</div>
            <div *ngIf="form.value.shipper.address.countryControl && form.value.shipper.address.cityControl">
                <span>{{ form.value.shipper.address.countryControl.name }}, </span>
                <span>{{ form.value.shipper.address.cityControl.name }}, </span>
                <span>{{ form.value.shipper.address.street }}</span>
                <span *ngIf="form.value.shipper.address.building">, {{ form.value.shipper.address.building }}</span>
                <span *ngIf="form.value.shipper.address.floor">, {{ form.value.shipper.address.floor }}</span>
                <span *ngIf="form.value.shipper.address.zipCode">, {{ form.value.shipper.address.zipCode }}</span>
            </div>
            <div *ngIf="form.value.shipper.address.landmark">{{ form.value.shipper.landmark }}</div>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-8">
        <mat-card class="mb-16">
            <h2 class="h2">My shipment is going to</h2>
            <div class="mb-4">{{ form.value.receiver.firstName }} {{ form.value.receiver.lastName }}</div>
            <div class="mb-4">{{ form.value.receiver.email }}</div>
            <div class="mb-4">{{ form.value.receiver.mobileNumber }}</div>
            <div class="mb-4"
                *ngIf="form.value.receiver.address.countryControl && form.value.receiver.address.cityControl">
                <span>{{ form.value.receiver.address.countryControl.name }}, </span>
                <span>{{ form.value.receiver.address.cityControl.name }}, </span>
                <span>{{ form.value.receiver.address.street }}</span>
                <span *ngIf="form.value.receiver.address.building">, {{ form.value.receiver.address.building }}</span>
                <span *ngIf="form.value.receiver.address.floor">, {{ form.value.receiver.address.floor }}</span>
                <span *ngIf="form.value.receiver.address.zipCode">, {{ form.value.receiver.address.zipCode }}</span>
            </div>
            <div class="mb-4" *ngIf="form.value.receiver.address.landmark">{{ form.value.receiver.landmark }}</div>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-8">
        <mat-card class="mb-16">
            <h2 class="h2">
                <span>I'm using the following packages to ship my </span>
                <span *ngIf="form.value.packageDetails.packageType">
                    {{ CommodityProductType[form.value.packageDetails.packageType] }}
                </span>
            </h2>
            <ng-container *ngFor="let package of form.value.packageDetails.packages; let i = index">
                <div class="mb-16">
                    <div class="mb-8">
                        <strong>
                            Package {{ i + 1 }}: {{ package.dimension.title ? package.dimension.title : 'Custom'  }}
                        </strong>
                    </div>
                    <div class="mb-4">Weight: {{ package.weight | number:'.2-2' }} kg</div>
                    <ng-container *ngIf="package.shipmentValue && package.shipmentValue >= 0">
                        <div class="mb-4">Shipment Value: {{ package.shipmentValue | number:'.2-2' }} $</div>
                    </ng-container>
                    <ng-container
                        *ngIf="package.dimension.width && package.dimension.height && package.dimension.length">
                        <div class="mb-4">
                            <span>Dimension: </span>
                            <span>L: {{ package.dimension.length | number:'.2-2' }} cm </span>
                            <span>x W: {{ package.dimension.width | number:'.2-2' }} cm </span>
                            <span>x H: {{ package.dimension.height | number:'.2-2' }} cm</span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-8">
        <mat-card class="mb-16">
            <h2 class="h2">Pick up my shipment on {{ form.value.pickup.pickupDate | date }}</h2>
            <ng-container
                *ngIf="form.value.currentDirection && form.value.currentDirection === ServiceDirection.Domestic">
                <div>
                    Deliver my shipment in {{ form.value.pickup.shippingTypeName }}
                </div>
            </ng-container>
            <ng-container *ngIf="form.value.pickup.specialInstructions">
                <div>
                    {{ form.value.pickup.specialInstructions }}
                </div>
            </ng-container>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-8">
        <mat-card class="mb-16">
            <h2 class="h2">
                <span>I'm shipping </span>
                <span>{{ form.value.shipmentDetails.commodityId | commodity:form.value.currentDirection | async }}</span>
            </h2>
            <ng-container *ngIf="form.value.shipmentDetails.descriptionOfGoods">
                <div>
                    {{ form.value.shipmentDetails.descriptionOfGoods }}
                </div>
            </ng-container>
            <div>
                {{ form.value.shipmentDetails.numberOfPieces }} piece(s)
            </div>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-md-8 col-xs-12">
        <div class="row" fxLayoutAlign="start center">
            <div class="col-md-6 col-xs-12">
                <mat-form-field class="w-100-p">
                    <mat-label>Do you have a coupon Code?</mat-label>
                    <input matInput name="mynet-order-coupon" [(ngModel)]="coupon" [disabled]="form.get('couponCode').value === coupon">
                </mat-form-field>
            </div>
            <div class="col-md-6 col-xs-12">
                <button class="mr-16" [disabled]="form.get('couponCode').value === coupon" mat-raised-button (click)="applyCoupon()">
                    Apply
                </button>

                <button *ngIf="form.get('couponCode').value === coupon" mat-raised-button color="warn"
                    (click)="removeCoupon()">
                    Remove
                </button>
            </div>
        </div>
        <mat-error *ngIf="invalidCoupon">
            The coupon code entered is not valid. Perhaps you used the wrong coupon code?
        </mat-error>
    </div>
</div>

<div class="action-buttons mt-16">
    <button mat-raised-button *ngIf="form.value.activeListItem > 0" (click)="back()">Back</button>
    <button mat-raised-button color="primary" *ngIf="form.value.activeListItem < 6"
        (click)="saveOrder()">Continue</button>
</div>