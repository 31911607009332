<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" *ngIf="action === 'new'">
                New Contact List
            </span>
            <span class="title dialog-title" *ngIf="action === 'edit'">
                Edit Contact List
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <form [formGroup]="contactListForm" class="h-100-p" fxLayout="column" fxLayout.gt-sm="row">
            <div style="flex: 1">
                <div class="p-24 pb-0 m-0">
                    <h2 class="h2">Contact List Details</h2>
                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <mat-label>Description</mat-label>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <input name="contactList-Description" formControlName="description" matInput required
                                maxlength="125" />
                            <mat-error *ngIf="contactListForm.get('description').hasError('required')">
                                Description is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field class="w-100-p" fxFlex>
                            <mat-chip-list #tagList aria-label="Tags">
                                <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                                    (removed)="remove(tag)">
                                    {{tag}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="add tag(s)" [matChipInputFor]="tagList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-icon matSuffix class="secondary-text">bookmark_border</mat-icon>
                        </mat-form-field>
                    </div>
                    <!-- <div fxLayout="row" class="mb-8" fxLayoutAlign="start start"> -->
                    <!-- <mat-form-field>
                        <mat-label>Contacts</mat-label>
                        <mat-select [formControl]="contactListControl" (selectionChange)="contactsSelectedChanged()" multiple>
                          <mat-select-trigger>
                            {{contactListControl.value ? contactListControl.value[0].fullName : ''}}
                            <span *ngIf="contactListControl.value?.length > 1" class="example-additional-selection">
                              (+{{contactListControl.value.length - 1}} {{contactListControl.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                          </mat-select-trigger>
                          <mat-option *ngFor="let contact of contacts" [value]="contact">{{contact.fullName}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->
                    <!-- </div> -->
                    <!-- <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                        <mat-label>Contacts</mat-label>
                        
                        <mat-select name="mynet-contactList-contacts" formControlName="contactListDetail"  
                        (selectionChange)="contactsSelectedChanged()"   multiple >
                        

                        <mat-select-trigger>
                            {{contactListDetail ? contactListDetail.contactid : 0}}
                            <span *ngIf="contactListDetail?.length > 1" class="example-additional-selection">
                              (+{{contactListDetail.length - 1}} {{contactListDetail?.length === 2 ? 'other' : 'others'}})
                            </span>
                          </mat-select-trigger>

                        <mat-option *ngFor="let contact of contacts" [value]="contact.contactid" style="display:inline-flex; width:33%;">
                            <span class="text-truncate">{{contact.fullName}}</span>
                        </mat-option>
                        </mat-select>
                        </mat-form-field>
                    </div> -->
                    <!-- <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field>
                            <mat-select formControlName="contactListDetail" [multiple]="true" #multiSelect>
                              <mat-option *ngFor="let contact of filteredBanksMulti | async" [value]="bank">
                                {{bank.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>   -->
                    <!-- (change)="filterContacts(filter)" -->
                    <div class="search-wrapper mx-32 mx-md-0">
                        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon>search</mat-icon>
                            <input #filter placeholder="Search for a contact" (keyup)="filterContacts(filter)">
                        </div>
                        <div style="float:right;" class="search-wrapper mx-32 mx-md-0">
                            <p>
                                Contacts selected: {{selection.selected.length}}
                            </p>
                        </div>
                    </div>


                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <div>Select All</div>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <!-- <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ column | titlecase }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element[column] }}</mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="fullName">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef> Company Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef> Phone </th>
                            <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef> Address </th>
                            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                        </tr>
                    </table>

                    <!-- <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                        <mat-label>Contacts</mat-label>
                        <mat-select name="mynet-contactList-contacts" formControlName="contactListDetail"  
                        (selectionChange)="contactsSelectedChanged($event.value)"   (disabled)="checkUserSelection($event.value)" multiple >
                        <mat-select-trigger>
                            {{displayContact ? displayContact : ''}}
                            <span *ngIf="contactListDetail?.length > 1" class="example-additional-selection">
                              (+{{contactListDetail.length - 1}} {{contactListDetail?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <div style="flex: 1" fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                            <mat-form-field>
                                <input matInput (keyup)="applyFilter($event.target.value)" (keydown)="handleInput($event)" placeholder="{{'Search'}}">
                            </mat-form-field>
                         </div>


                         <app-select-check-all [model]="form.get('contactListDetail')" [values]="contacts">
                        </app-select-check-all>

                         <mat-option disabled="disabled" class="filter-option">
                            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
                              Select All 
                            </button>
                            <button  mat-raised-button class="mat-primary fill text-sm eta-margin-all" (click)="deselectAll()">
                              Deselect All
                            </button>
                          </mat-option>

                       <mat-option *ngFor="let contact of contacts" [value]="contact.contactId" [disabled]="contactsDisabled" style="display:inline-flex; width:33%;">
                        <span class="text-truncate">{{contact.fullName}}</span>
                        </mat-option>
                        </mat-select>
                        </mat-form-field>
                    </div> -->


                </div>

            </div>

        </form>
    </mat-dialog-content>


    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <mat-error *ngIf="contactExist" class="mr-8">Contact List already exists!</mat-error>
        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel'])"
            aria-label="Cancel" matTooltip="Cancel Edit Contact List">
            CANCEL
        </button>

        <button *ngIf="action !== 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveNew()"
            [disabled]="contactExist || (contactListForm.invalid && !contactListForm.dirty)"
            aria-label="Save New Contact List">
            SAVE
        </button>

        <button *ngIf="action === 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveExisting()"
            [disabled]="contactExist || (contactListForm.invalid && !contactListForm.dirty)"
            aria-label="Save Contact List">
            SAVE
        </button>
    </mat-dialog-actions>
</div>