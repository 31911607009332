<mat-form-field fxFlex>
    <mat-label>{{ label }}</mat-label>
    <input type="text" placeholder="Search for {{ label }}" matInput [formControl]="control" [matAutocomplete]="auto"
        [required]="required">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
        class="auto-complete-locations">
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <mat-option class="location-option" *ngFor="let option of filteredCities" [value]="option">
            {{option.displayName}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>