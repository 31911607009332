import { InvoicePaymentComponent } from './Invoice-payment.component';
import { InvoicePaymentService } from './Invoice-payment.services';
import { InvoicePaymentDoneComponent } from './payment-done/invoice-done.component';
import { InvoiceDoneService } from './payment-done/invoice-done.service';
const ɵ0 = InvoicePaymentService, ɵ1 = InvoiceDoneService;
const routes = [
    // {
    //     path: 'invoice-payment/:id',
    //     component: InvoiceCheckoutComponent,
    //     resolve: {
    //         data: InvoicePaymentService
    //     }
    // },
    {
        path: 'invoice-payment/:id',
        component: InvoicePaymentComponent,
        resolve: {
            data: ɵ0
        }
    },
    {
        path: 'invoice-payment-redirect',
        component: InvoicePaymentDoneComponent,
        resolve: {
            data: ɵ1
        }
    }
];
export class InvoicePaymentModule {
}
export { ɵ0, ɵ1 };
