import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { NetPointService } from './netpoint.service';
import { DeliverResult, NetPointResult, PreDeliverResult } from './netpoint.model';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CurrencyType } from 'app/_enums/ServiceType.enum';


@Component({
    selector: 'netpoint',
    templateUrl: './netpoint.component.html',
    styleUrls: ['./netpoint.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class NetPointComponent implements OnInit {
    netPointCheckIn: FormGroup;
    netPointPreDeliver: FormGroup;
    netPointDeliver: FormGroup;
    netPointReturnMerch: FormGroup;
    netPointReturnMerchToNet: FormGroup;


    currencyTypes: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    stationKey: string;
    preDeliverResult: PreDeliverResult;
    deliverResult: DeliverResult;
    selectedIndex: number = 0;


    processing: boolean = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _netpointService: NetPointService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.preDeliverResult = new PreDeliverResult();
        this.stationKey = localStorage.getItem('netPointKey');

        this.netPointCheckIn = this._formBuilder.group({
            label: ['', [Validators.required]]
        })
        this.netPointPreDeliver = this._formBuilder.group({
            token: ['', [Validators.required]]
        })
        this.netPointDeliver = this._formBuilder.group({
            label: ['', [Validators.required]],
            amount: [0, [Validators.required]],
            currency: ['LBP', [Validators.required]]
        })
        this.netPointReturnMerch = this._formBuilder.group({
            label: ['', [Validators.required]],
            amount: [0, [Validators.required]]
        })
        this.netPointReturnMerchToNet = this._formBuilder.group({
            label: ['', [Validators.required]]
        })
        this.currencyTypes = this.enumSelector(CurrencyType).slice(3, 5);
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key, disabled: false }));
    }
    checkInLabel() {
        this.processing = true;
        this._netpointService.checkInLabel(this.stationKey, this.netPointCheckIn.get('label').value).then((result: NetPointResult) => {
            this.processing = false;
            this.openResultDialog('Check-In Label', result.message)
        });
    }
    preDeliverLabel() {
        this.processing = true;
        this.preDeliverResult = new PreDeliverResult;
        this._netpointService.preDeliverLabel(this.stationKey, this.netPointPreDeliver.get('token').value).then((result: NetPointResult) => {
            if (result.success) {
                this.preDeliverResult = result.data as PreDeliverResult;
                this.netPointDeliver.get('label').setValue(this.preDeliverResult.label);
                this.selectedIndex = 2;
            }
            this.processing = false;
            this.openResultDialog('Pre-Deliver Label', result.message)
        });
    }
    deliverLabel() {
        this.processing = true;
        this._netpointService.deliverLabel(this.stationKey, this.netPointDeliver.get('label').value, this.netPointDeliver.get('amount').value, this.netPointDeliver.get('currency').value).then((result: NetPointResult) => {
            if (result.success) {
                this.deliverResult = result.data as DeliverResult;
            }
            this.processing = false;
            this.openResultDialog('Deliver Label', result.message)
        });
    }
    returnMerch() {
        this.processing = true;
        this._netpointService.returnMerch(this.stationKey, this.netPointReturnMerch.get('label').value, this.netPointReturnMerch.get('amount').value).then((result: NetPointResult) => {
            this.processing = false;
            this.openResultDialog('Return Merchandise', result.message)
        });
    }
    returnMerchToNet() {
        this.processing = true;
        this._netpointService.returnToNet(this.stationKey, this.netPointReturnMerchToNet.get('label').value).then((result: NetPointResult) => {
            this.processing = false;
            this.openResultDialog('Return To NET', result.message)
        });
    }
    clearData(form) {
        if (form == this.netPointDeliver || form == this.netPointPreDeliver) {
            this.preDeliverResult = new PreDeliverResult();
            this.selectedIndex = 1; // netPointPreDeliver
            this.netPointPreDeliver.reset();
            this.netPointDeliver = this._formBuilder.group({
                label: ['', [Validators.required]],
                amount: [0, [Validators.required]],
                currency: ['LBP', [Validators.required]]
            })
        }
        else
            form.reset();
        // if (form.invalid) {
        // }
    }


    private openResultDialog(operation, message): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
        });
        this.confirmDialogRef.componentInstance.header = operation;
        this.confirmDialogRef.componentInstance.confirm = 'Ok';
        this.confirmDialogRef.componentInstance.cancel = '';
        this.confirmDialogRef.componentInstance.confirmMessage = message;



        // this.netPointCheckIn.get('label').setValue('');
        // this.netPointPreDeliver.get('token').setValue('');
        // this.netPointDeliver.get('amount').setValue(0);
        // this.netPointDeliver.get('label').setValue('');

        // this.netPointReturnMerch.get('label').setValue('');
        // this.netPointReturnMerch.get('amount').setValue(0);
    }
}
