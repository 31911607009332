<div *ngIf="contact" style="color: rgba(0,0,0,.54);line-height: 22px;">
    <p class="my-0"><strong style="font-size:  larger;">{{ contact.companyName }}</strong></p>
    <p class="my-0"><strong>{{ contact.fullName }}</strong></p>
    <p class="my-0">{{ contact.phoneNumber }}</p>
    <p class="my-0">{{ contact.email }}</p>
    <p class="my-0" *ngIf="isNetPoint"><strong>NetPoint:</strong></p>
    <div *ngIf="address">
        <p class="my-0">{{ address.display }}</p>
        <p class="my-0">
            <span>{{ address.street }}</span>
            <span *ngIf="address.building">, {{ address.building }}</span>
            <span *ngIf="address.floor">, {{ address.floor }}</span>
        </p>
        <p class="my-0" *ngIf="address.landmark">{{ address.landmark }}</p>
    </div>
</div>