import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';

import { ConfirmEmailComponent } from 'app/authentication/confirm-email/confirm-email.component';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';

const routes = [
    {
        path: 'auth/confirm-email',
        component: ConfirmEmailComponent,
        canActivate: [AnonymousGuard]
    }
];

@NgModule({
    declarations: [ConfirmEmailComponent],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule]
})
export class ConfirmEmailModule {}
