import { NgModule } from '@angular/core';
import { ContactFormComponent } from './contact-form.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ContactFormAddressComponent } from './contact-form-address/contact-form-address.component';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { SheetJSComponent } from '../sheet-jscomponent/sheet-jscomponent.component';
import { GoogleLocationModule } from 'app/_shared/google-location/google-location.module';

@NgModule({
    declarations: [ContactFormComponent, ContactFormAddressComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        MatToolbarModule,
        FuseConfirmDialogModule,
        MatDialogModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        GoogleLocationModule
    ],
    entryComponents: [ContactFormComponent,SheetJSComponent]
})
export class ContactFormModule {}
