import { CashOnDelivery } from "app/main/orders/new-order/order.model";

export class DomesticQuote {
    fromCityId: number;
    toCityId: number;
    shippingTypeId: number;
    weight: number;
    chargeableWeight: number;
    numberOfPieces: number;
    shipmentValue: number;
    surchargesValue: string;
    height: number;
    width: number;
    length: number;
    shipperReference: string;
    cods: CashOnDelivery[];

    constructor(quote?: any) {
        quote = quote || {};
        this.fromCityId = quote.fromCityId || 0;
        this.toCityId = quote.toCityId || 0;
        this.shippingTypeId = quote.shippingTypeId || 0;
        this.surchargesValue = quote.surchargesValue || '';
        this.weight = quote.weight;
        this.chargeableWeight = quote.chargeableWeight;
        this.height = quote.height;
        this.width = quote.width;
        this.length = quote.length;
        this.numberOfPieces = quote.numberOfPieces;
        this.shipmentValue = quote.shipmentValue;
        this.shipperReference = quote.shipperReference || '';
        this.cods = quote.cods || [];
    }
}
