<div class="invoice-checkout">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Invoices
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close(false)" aria-label="Close dialog"
                type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>


    <mat-dialog-content>
        <div class="p-24 pb-0 m-0" fusePerfectScrollbar>
            <div class="customer mb-32">
                <table class="simple">
                    <thead>
                        <tr>
                            <th>Invoice #</th>
                            <th>Date</th>
                            <th> Amount</th>
                            <th> Amount LBP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoice of invoice.invoices">
                            <td>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <span class="name text-truncate">
                                        {{invoice.invoiceId}}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <span class="date text-truncate">
                                    {{invoice.invoiceDate | date: 'dd-MM-yyyy'}}
                                </span>
                            </td>
                            <td>
                                <span class="text-truncate">
                                    {{invoice.amount | number}}
                                </span>
                            </td>

                            <td>
                                <span class="text-truncate">
                                    {{invoice.amountLBP | number: '2.'}}
                                </span>
                            </td>
                        </tr>

                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th>Total:</th>
                            <th>$ {{invoice.totalAmount | number:'.2-2' }}</th>
                            <th>{{invoice.totalAmountLBP | number:'.0-0'}} L.L.</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
     
        <mat-progress-bar *ngIf="payClicked" mode="indeterminate"></mat-progress-bar>
    </mat-dialog-content>

    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <ng-container *ngIf="!retryAllowed">
            <button mat-raised-button color="primary" class="mr-8" (click)="confirm()" aria-label="Confirm"
                [disabled]="payClicked" *ngIf="checkoutLoaded" matTooltip="Confirm">
                CONFIRM
            </button>

            <button mat-button class="ml-8" color="warn" (click)="matDialogRef.close(false)" aria-label="Cancel"
                [disabled]="payClicked" *ngIf="checkoutLoaded" matTooltip="Cancel">
                CANCEL
            </button>

            <button mat-button class="w-100-p" color="warn" type="button" *ngIf="!checkoutLoaded">
                Connecting to Payment Portal...
            </button>
        </ng-container>

        <button mat-button class="w-100-p" color="primary" type="button" *ngIf="retryAllowed"
            (click)="getCheckoutSession()">
            Retry
        </button>
        <button mat-button class="w-100-p" color="primary" type="button" *ngIf="retryAllowed"
            (click)="navigatePaymentDone()">> Test Payment Done</button>

    </mat-dialog-actions>
</div>