import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
// import { AuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'app-logout',
    template: ''
})
export class LogoutComponent implements OnInit {
    loginProcess = false;
    constructor(
        private _authService: AuthenticationService,
        // private _socialAuthService: AuthService,
        private _router: Router,
        private _fuseConfigService: FuseConfigService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.logout();
    }

    logout(): void {
        this.loginProcess = true;
        this._authService.logout().subscribe(result => {
            localStorage.removeItem('loggedInUser');
            localStorage.removeItem('token');
            localStorage.removeItem('subsidiaryId');
            localStorage.removeItem('distributionId');
            try {
                this._router.navigateByUrl(Routes.login);
                //     this._socialAuthService.authState.subscribe(
                //         user => {
                //             if (this.loginProcess) {
                //                 if (user !== null) {
                //                     this._socialAuthService.signOut().then(
                //                         x => {
                //                             this._router.navigateByUrl(Routes.login);
                //                         },
                //                         reject => {
                //                             this._router.navigateByUrl(Routes.login);
                //                         }
                //                     );
                //                 } else {
                //                     this._router.navigateByUrl(Routes.login);
                //                 }
                //                 this.loginProcess = false;
                //             }
                //         },
                //         error => {
                //             this._router.navigateByUrl(Routes.login);
                //         }
                //     );
            } catch (error) {
                this._router.navigateByUrl(Routes.login);
            }
        });
    }
}
