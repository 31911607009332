<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" *ngIf="action === 'new'">
                New Contact
            </span>
            <span class="title dialog-title" *ngIf="action === 'edit'">
                {{ formValue.firstName }} {{ formValue.lastName }}
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>



    <mat-dialog-content>
        <form [formGroup]="contactForm">
            <mat-tab-group mat-stretch-tabs class="h-100-p" [selectedIndex]="selectedTab">
                <mat-tab label="Info">
                    <div class="p-24 pb-0 m-0 h-70-vh" fusePerfectScrollbar>
                        <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                            <mat-form-field fxFlex>
                                <mat-label>First Name</mat-label>
                                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                                <input name="mynet-contact-firstName" formControlName="firstName" matInput required />
                                <mat-error *ngIf="contactForm.get('firstName').hasError('required')">
                                    First Name is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                            <mat-form-field fxFlex>
                                <mat-label>Last Name</mat-label>
                                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                                <input name="mynet-contact-lastName" formControlName="lastName" matInput required />
                                <mat-error *ngIf="contactForm.get('lastName').hasError('required')">
                                    Last Name is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">

                            <mat-form-field fxFlex>
                                <mat-label>Phone number</mat-label>
                                <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                                <input name="mynet-contact-phoneNumber" formControlName="phoneNumber" matInput required
                                    type="tel" />
                                <mat-error *ngIf="contactForm.get('phoneNumber').hasError('required')">
                                    Phone Number is required
                                </mat-error>

                                <mat-error *ngIf="contactForm.get('phoneNumber').hasError('validCountryPhone')">
                                    Phone Number is not valid
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                            <mat-form-field fxFlex>
                                <mat-label>Email</mat-label>
                                <mat-icon matSuffix class="secondary-text">email</mat-icon>
                                <input name="mynet-contact-email" formControlName="email" matInput type="email" />
                                <!-- <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                    Email is required
                                </mat-error> -->
                                <mat-error *ngIf="contactForm.get('email').hasError('email')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                            <mat-form-field class="w-100-p" fxFlex>
                                <mat-chip-list #tagList aria-label="Tags">
                                    <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                                        (removed)="remove(tag)">
                                        {{tag}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="add tag(s)" [matChipInputFor]="tagList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Addresses">
                    <div class="p-24 pb-0 m-0 h-70-vh" fusePerfectScrollbar>
                        <div style="text-align: right" *ngIf="!isNewAddressOpen">
                            <button mat-raised-button color="primary" class="mb-16" (click)="addNewAddress()">
                                New Address
                            </button>
                        </div>
                        <mat-expansion-panel expanded="true" class="mb-16" *ngIf="isNewAddressOpen">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="text-truncate">
                                    New Address
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <app-contact-form-address (addressAdded)="addressAdded($event)" [index]="-1">
                            </app-contact-form-address>
                        </mat-expansion-panel>

                        <em *ngIf="formValue.addresses.length === 0 && !isNewAddressOpen" color="accent">
                            Click <strong>New Address</strong> to add a new address
                        </em>
                        <ng-container *ngFor="let address of formValue.addresses; let i = index">
                            <mat-expansion-panel class="mb-16">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="text-truncate">
                                        {{ address.display }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <app-contact-form-address style="width: 100%;" [address]="address" [mode]="'edit'"
                                    [index]="i" (addressAdded)="addressAdded($event)" *ngIf="editIndex[i]">
                                </app-contact-form-address>

                                <div fxFlex fxLayout="column" *ngIf="!editIndex[i]">
                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Country: </strong>
                                        <span class="mat-body-2">{{ address.countryName }}</span>
                                    </div>

                                    <div class="mr-sm-12" fxFlex>
                                        <strong>City: </strong>
                                        <span class="mat-body-2">{{ address.cityName }}</span>
                                    </div>

                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Street Address: </strong>
                                        <span class="mat-body-2">{{ address.street }}</span>
                                    </div>

                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Building: </strong>
                                        <span class="mat-body-2">{{ address.building }}</span>
                                    </div>

                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Floor: </strong>
                                        <span class="mat-body-2">{{ address.floor }}</span>
                                    </div>

                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Postal Code (ZIP): </strong>
                                        <span class="mat-body-2">{{ address.zipCode }}</span>
                                    </div>
                                    <div class="mr-sm-12" fxFlex>
                                        <strong>Landmark: </strong>
                                        <span class="mat-body-2">{{ address.landmark }}</span>
                                    </div>
                                   

                                    <div style="text-align: right" class="mb-16">
                                        <button mat-stroked-button color="warn" class="mr-8" (click)="removeAddress(i)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <button mat-stroked-button color="primary" (click)="editAddress(i)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <em *ngIf="formValue.addresses.length === 0" color="accent" class="mb-16 w-100-p">
            At least one address is required in order to save the contact
        </em>
        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['cancel'])"
            aria-label="Delete" matTooltip="Delete Contact">
            CANCEL
        </button>

        <button *ngIf="action !== 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveNew()"
            [disabled]="contactForm.invalid" aria-label="Save New Contact">
            SAVE NEW
        </button>

        <button *ngIf="action === 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveExisting()"
            [disabled]="contactForm.invalid" aria-label="Save Contact">
            SAVE
        </button>
    </mat-dialog-actions>
</div>