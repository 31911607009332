<ng-container [formGroup]="group">
    <div class="row mb-16">
        <div class="col-md-8 col-xs-12">
            <mat-button-toggle-group aria-label="Package Type" formControlName="packageType" required>
                <mat-button-toggle [value]="CommodityProductType.Docs">Documents</mat-button-toggle>
                <mat-button-toggle [value]="CommodityProductType.Parcels">Parcels</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <ng-container formArrayName="packages" *ngIf="group.get('packageType') && group.get('packageType').value">

        <div class="row mb-16" *ngIf="!onlyOnePackage">
            <div class="col-md-8 col-xs-12 text-right">
                <button mat-raised-button color="primary" (click)="addPackage()">
                    <mat-icon>add</mat-icon> Package
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8 col-xs-12">

                <ng-container *ngFor="let orderPackage of orderPackages; let i = index" [formGroupName]="i">

                    <mat-expansion-panel class="mb-16" [expanded]="orderPackage.get('expanded').value"
                        (opened)="packagePanelOpened(i)" (closed)="packagePanelClosed(i)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Package {{ i + 1 }}
                            </mat-panel-title>
                            <mat-panel-description *ngIf="!orderPackage.get('expanded').value">
                                <span [innerHTML]="getPanelDescription(i)"></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-xs-12 col-md-6">
                                <mat-form-field class="w-100-p">
                                    <mat-label>Package Type</mat-label>
                                    <mat-select name="mynet-order-package" formControlName="packageId" required
                                        (valueChange)="packgeChanged($event, i)">
                                        <mat-option [value]="0">
                                            <span class="text-truncate">Custom</span>
                                        </mat-option>
                                        <mat-option *ngFor="let package of packages" [value]="package.packageId">
                                            <span class="text-truncate">{{ package.title }}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <mat-form-field class="w-100-p">
                                    <mat-label>Weight</mat-label>
                                    <input matInput name="mynet-order-weight" formControlName="weight" required
                                        (change)="weightChanged($event, i)" type="number" min="0"
                                        (keypress)="numberOnly($event)">
                                    <span matSuffix>kg</span>
                                    <mat-error
                                        *ngIf="orderPackage.get('weight').hasError('validWeight') && form.get('currentDirection') && form.get('currentDirection').value !== undefined && form.get('maxWeight') && form.get('maxWeight').value">
                                        Max weight for {{ ServiceDirection[form.get('currentDirection').value] }} is
                                        {{ form.get('maxWeight').value }} kg
                                    </mat-error>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" formGroupName="dimension">
                            <div class="col-xs-12 col-md-6">
                                <ng-container *ngIf="orderPackage.value.packageId === 0">
                                    <mat-form-field class="w-100-p">
                                        <mat-label>Length</mat-label>
                                        <input matInput name="mynet-order-length" formControlName="length" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>

                                    </mat-form-field>
                                    <mat-form-field class="w-100-p">
                                        <mat-label>Width</mat-label>
                                        <input matInput name="mynet-order-width" formControlName="width" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>

                                    </mat-form-field>
                                    <mat-form-field class="w-100-p">
                                        <mat-label>Height</mat-label>
                                        <input matInput name="mynet-order-height" formControlName="height" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>

                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="orderPackage.value.packageId > 0">
                                    <h4 class="h4" [innerHTML]="orderPackage.value.dimension.description"></h4>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <div>
                                                Length
                                            </div>
                                            <div>
                                                {{ orderPackage.value.dimension.length }} cm
                                            </div>
                                        </div>

                                        <div class="col-xs-4">
                                            <div>
                                                Width
                                            </div>
                                            <div>
                                                {{ orderPackage.value.dimension.width }} cm
                                            </div>
                                        </div>

                                        <div class="col-xs-4">
                                            <div>
                                                Height
                                            </div>
                                            <div>
                                                {{ orderPackage.value.dimension.height }} cm
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="col-xs-12 col-md-6 center-xs" style="display: flex;align-items: center;">
                                <img width="200" *ngIf="orderPackage.value.packageId === 0"
                                    src="/assets/images/package-dimensions.png" alt="Package Dimensions" />
                                <img width="200" *ngIf="orderPackage.value.packageId > 0"
                                    src="/assets/images/boxes/{{ orderPackage.value.packageId }}.jpg"
                                    alt="Package Dimensions" />
                            </div>

                        </div>

                        <div class="row mt-16">
                            <div class="col-xs-12 col-md-6">
                                <mat-form-field class="w-100-p">
                                    <mat-label>Shipment Value</mat-label>
                                    <input matInput name="mynet-order-shipmentValue" formControlName="shipmentValue"
                                        (keypress)="numberOnly($event)" type="number" min="0" max="9999999999999">
                                    <span matSuffix>$</span>
                                    <mat-error *ngIf="orderPackage.get('shipmentValue').hasError('validDecimal')">
                                        Shipment Value should be between  0.00 and  9999999999999
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="justify-content: flex-end;" *ngIf="i > 0">
                            <button mat-raised-button color="warn" (click)="removePackage(i)">
                                <mat-icon>delete</mat-icon>
                                Remove Package
                            </button>
                        </div>

                    </mat-expansion-panel>

                </ng-container>

            </div>
        </div>

    </ng-container>

    <div class="action-buttons">
        <button mat-raised-button *ngIf="form.value.activeListItem > 0" (click)="back()">Back</button>
        <button mat-raised-button color="primary" *ngIf="form.value.activeListItem < 6"
            (click)="validateAndContinue()">Continue</button>
    </div>

    <div class="mt-8" *ngIf="submited">
        <mat-error *ngIf="group.get('packageType').hasError('required')">Package Type is required</mat-error>
        <ng-container *ngFor="let orderPackage of orderPackages; let i = index;">
            <mat-error *ngIf="orderPackage.get('weight').hasError('required')">Package {{ (i + 1) }}: Weight is
                required
            </mat-error>
            <mat-error
                *ngIf="orderPackage.get('weight').hasError('validWeight') && form.get('currentDirection') && form.get('currentDirection').value !== undefined && form.get('maxWeight') && form.get('maxWeight').value">
                Package {{ (i + 1) }}: Max weight for {{ ServiceDirection[form.get('currentDirection').value] }} is
                {{ form.get('maxWeight').value }} kg
            </mat-error>
            <mat-error *ngIf="orderPackage.get('shipmentValue').hasError('validDecimal')">
                Package {{ (i + 1) }}: Shipment Value should be between $ 0.00 and $ 99,999.00
            </mat-error>
        </ng-container>
    </div>
</ng-container>