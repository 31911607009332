import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';

import { EmailConfirmedComponent } from 'app/authentication/email-confirmed/email-confirmed.component';
import { EmailConfirmedService } from './email-confirmed.service';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';

const routes = [
    {
        path: 'auth/email-confirmed',
        component: EmailConfirmedComponent,
        resolve: {
            data: EmailConfirmedService
        },
        canActivate: [AnonymousGuard]
    }
];

@NgModule({
    declarations: [EmailConfirmedComponent],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule],
    providers: [EmailConfirmedService]
})
export class EmailConfirmedModule {}
