import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { userInfo } from 'os';
import { InvoicePayment } from '../Invoice-payment.model';
// src\MyNet.SPA\ClientApp\src\app\main\Invoice-payment\Invoice-payment.services.ts

@Injectable()
export class InvoiceDoneService implements Resolve<any> {
    retryCount = 0;
    success = false;

    invoices: InvoicePayment;

    constructor(private _httpClient: HttpClient, private _router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {
            Promise.all([this.updateInvoicesStatus()]).then(() => {
                resolve(this);
            }, reject);
        });
    }

    updateInvoicesStatus(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subsidiaryId = localStorage.getItem('invoiceSubsidiaryId');
            this.invoices = JSON.parse(localStorage.getItem('invoices')) as InvoicePayment;

            if (subsidiaryId === undefined || subsidiaryId === null || subsidiaryId.length === 0 || this.invoices.invoices.length == 0) {
                // this._router.navigateByUrl(Routes.invoicePayment);
                resolve(this);
            }
            else {


                //TESTING 
                // this.saveInvoices(this.invoices,"B647441F-2E55-4F08-8DD5-BB","B647441F-2E55-4F08-8DD5-BBE55DA15151");
                 
                this.saveInvoices(this.invoices,localStorage.getItem("paymentSessionId"),localStorage.getItem("uniqueId"));
                localStorage.removeItem('invoiceSubsidiaryId');
                this.success = true;
                resolve(this);
            }

        });
    }
    saveInvoices(invoicePayment: InvoicePayment, paymentSessionId: string, uniqueId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams()
            .append('paymentSessionId', paymentSessionId.toString())
            .append('uniqueId', uniqueId.toString());
            // this._httpClient.post(environment.apiUrl + 'v1/Payments/SaveInvoicePayments', invoicePayment, { params })
            // .subscribe((invoicePayment: InvoicePayment) => {
            //     resolve(invoicePayment);
            // }, reject)
            

              this._httpClient.post(environment.apiUrl + 'v1/Payments/SaveInvoicePayments/',  invoicePayment, { params }).subscribe(
                _ => {
                    this.success = true;
                    resolve(this);
                }
            );

            // this._httpClient
            //     .post(environment.apiUrl + 'v1/Payments/SaveInvoicePayments/', invoicePayment)
            //     .subscribe((invoicePayment: InvoicePayment) => {
            //         resolve(invoicePayment);
            //     }, reject);
        });
    }
}
