import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthenticationService } from 'app/authentication/authentication.service';
// import { AuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { LoginResult } from 'app/_models/LoginResult';
import { ProfileComponent } from 'app/main/profile/profile.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    Routes = Routes;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    loginProcess = false;
    name: string;

    imgURL: any;

    defaultPhotoUrl = 'assets/images/avatars/profile.jpg';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public authService: AuthenticationService,
        // private _socialAuthService: AuthService,
        private _router: Router
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

        this.authService.getLoggedInUser();
        if (this.authService.loggedInUser == undefined)
            return;
        if (this.authService.loggedInUser) {
            this.name = this.authService.loggedInUser.fullName;
        }
        this.authService.onUserInfoUpdated.subscribe(user => {
            this.authService.getLoggedInUser();
            if (this.authService.loggedInUser) {
                this.name = this.authService.loggedInUser.fullName;
                this.loadPhoto();
            }
        });
        // this.loadPhoto();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    ngAfterViewInit() {
        // this.imgURL=  this.profile.imgURL;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    loadPhoto(): void {
        this.authService.downloadUserPhoto().subscribe(
            result => {
                if (result) {
                    var myBlob = result as Blob;
                    if (myBlob.size == 0) {
                        this.imgURL = this.defaultPhotoUrl;
                        return;
                    }
                    var myFile = this.blobToFile(myBlob, "my-image.png");
                    const reader = new FileReader();
                    reader.readAsDataURL(myFile);
                    reader.onload = _event => {
                        this.imgURL = reader.result;
                    };
                }
            },
        );
    }

    public blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }
    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        this._router.navigateByUrl(Routes.logout);
    }

    edit(): void {
        console.log('edit');

        this._router.navigateByUrl(Routes.profile);
    }
    settings(): void {
        console.log('settings');
        this._router.navigateByUrl(Routes.settings);
    }
}
