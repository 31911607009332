import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';

export class DecimalValidator {
    static validDecimal = (min: number = 0, max?: number): ValidatorFn => {
        return (decimalControl: AbstractControl): { [key: string]: boolean } => {
            const value = +decimalControl.value;
            if (value >= min && !max) {
                return undefined;
            } else if (value >= min && max && value <= max) {
                return undefined;
            }
            return { validDecimal: true };
        };
    }
}
