import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, ɵConsole } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Contact, Address } from './contact.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { ContactsContactListService } from '../contact-list/contact-list.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactsListDataSource } from 'app/main/c-list/c-list/c-list.datasource';
import { ContactsListService } from 'app/main/c-list/c-list/c-list.service';
import { LocationService } from 'app/_shared/location-service.service';
import { City } from 'app/_models/city.model';
import { Country } from 'app/_models/country.model';

@Component({
    selector: 'app-contact-editor',
    templateUrl: './contact-editor.component.html',
    styleUrls: ['./contact-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactEditorComponent implements OnInit, OnDestroy {
    selectedTab = 0;
    action: string;
    contact: Contact;
    contactForm: FormGroup;
    activeAddress: FormGroup;
    activeEditAddress: FormGroup;

    isNewAddressOpen = false;
    editIndex: boolean[] = [];
    protected _unsubscribeAll = new Subject<void>();
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];
    contactExist = false;
    contactExistId: number;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    firstload = true;

    countries: Country[];

    selectedCities: City[];
    address;
    cityid = 0;

    countryCode: FormControl;

    saveUsername:boolean;

    constructor(
        public matDialogRef: MatDialogRef<ContactEditorComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _contactService: ContactsContactListService,
        public _matDialog: MatDialog,
        private _locationService: LocationService
    ) {
        // Set the defaults
        this.action = _data.action;
        if (this.action === 'edit') {
            this.contact = _data.contact;
            this.countries = _data.countries;
            this.firstload = true;
        }
        else if (this.action === 'import') {

            this.firstload = true;
            this.contact = _data.contact;
            this.selectedCities = _data.selectedcities;
            this.countries = _data.countries;
            let contactCity = this.selectedCities.find(e => e.cityName == this.contact.addresses[0].cityName)
            if (contactCity) {
                this.contact.addresses[0].cityId = this.selectedCities.find(e => e.cityName == this.contact.addresses[0].cityName).cityId;
                this.contact.addresses[0].countryId = this.selectedCities.find(e => e.cityName == this.contact.addresses[0].cityName).countryId;
            }
        }
        else {
            this.contact = new Contact();
            this.countries = _data.countries;
        }

        this.createContactForm();
        this.createAddressForm();
        this.subscribeContactExist();
    }

    get formValue(): any {
        return this.contactForm.value;
    }

    get addNewIndex(): number {
        const value = this.contactForm.value;
        return value && value.addresses ? value.addresses.length - 1 : -1;
    }

    get contactAddresses(): any {
        return (this.contactForm.get('addresses') as FormArray).controls;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private subscribeContactExist(): void {
        // this.contactForm
        //     .get('firstName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('lastName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('phoneNumber')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('email')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // if (this.action === 'import') {
        //     Object.keys(this.contactForm.controls).forEach(field => {
        //         const control = this.contactForm.get(field);
        //         control.markAsTouched({ onlySelf: true });
        //     });
        // }
    }

    private checkExist(): void {
        const contact = {
            contactId: this.contactForm.get('contactId').value,
            firstName: this.contactForm.get('firstName').value,
            lastName: this.contactForm.get('lastName').value,
            phoneNumber: this.contactForm.get('phoneNumber').value,
            email: this.contactForm.get('email').value
        };
        this._contactService.isContactExist(contact).then(result => {
            this.contactExist = result.status;
            this.contactExistId = result.contactId;

            if (this.contactExist) {
                this.openExistWarningDialog();
            }
        });
    }

    private openExistWarningDialog(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.header = 'Contact Already Exists';
        this.confirmDialogRef.componentInstance.confirm = 'Ok';
        this.confirmDialogRef.componentInstance.cancel = null;
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that this contact already exists in your address book!';
    }

    private createAddressForm(): void {

        this.activeAddress = this._formBuilder.group(new Address());
        this.activeAddress.get('cityId').disable();
    }

    private createContactForm(): void {

        this.tags = this.contact.tags.slice();
        this.contact.tags = [];

        this.contactForm = this._formBuilder.group(this.contact);


        this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));

        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        this.contact.addresses.forEach(address => {
            addressesFormArray.push(this._formBuilder.group(address));
            this.editIndex.push(false);
        });
        this.contactForm.get('email').setValidators(Validators.email);



        const phoneCountryCode = this.contactForm.get('phoneCountryCode');

        if (phoneCountryCode) {
            if (phoneCountryCode.value) {
                var phoneCountry = this.countries.find(country => country.countryPhoneCode == phoneCountryCode.value)

                if (phoneCountry)
                    var country = new FormControl({ iso: phoneCountry }, Validators.required);
            }
            else
                var country = new FormControl({ iso: 'LB' }, Validators.required);
        }

        let phoneNumberValid = this.contactForm.get('validPhoneNumber');
        if (!phoneNumberValid) {
            this.contactForm.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.contactForm.get('validPhoneNumber');
        }


        this.contactForm
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);

        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                if (!value) {
                    this.contactForm.get('phoneNumber').setErrors(Validators.required);
                }
            });
        this.contactForm.valueChanges.subscribe(_ => (this.firstload = false));
    }

    countryChange(value: any) {
        // this.contactForm.get('phoneNumber').setValue('');
        // && country.displayName.toString() == 
        const phoneCountry = this.countries.find(country => country.countryPhoneCode == value.value  );
        const country = new FormControl({ iso: phoneCountry }, Validators.required);

        let phoneNumberValid = this.contactForm.get('validPhoneNumber');
        phoneNumberValid.setErrors(Validators.required);
        this.contactForm.get('phoneNumber').setErrors(Validators.required)
        if (!phoneNumberValid) {
            this.contactForm.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.contactForm.get('validPhoneNumber');
        }
        this.contactForm
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                if (!value) {
                    this.contactForm.get('phoneNumber').setErrors(Validators.required);
                }
            });
        this.contactForm
            .get('phoneNumber').updateValueAndValidity();


    }

    addNewAddress(): void {
        const addresses = this.contactForm.get('addresses') as FormArray;
        addresses.push(this._formBuilder.group(new Address()));
    }

    addressAdded(data: any): void {
        const addressForm: FormGroup = data.value;
        const mode: string = data.mode;
        const index: number = data.index;
        if (mode === 'cancel') {
            if (this.isNewAddressOpen && index === -1) {
                this.isNewAddressOpen = false;
            } else if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;
        }
        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        const address = this._formBuilder.group(addressForm);
        if (mode === 'new') {
            addressesFormArray.push(address);
            this.isNewAddressOpen = false;
        } else if (mode === 'edit') {
            addressesFormArray.setControl(index, address);
            this.editIndex[index] = false;
        }
    }

    removeAddress(index: number): void {
        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        addressesFormArray.removeAt(index);
        this.contactForm.markAsTouched();
        this.contactForm.markAsDirty();
        this.contactForm.updateValueAndValidity();
    }

    editAddress(index: number): void {
        this.editIndex[index] = true;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    saveNew(): void {
        if (!this.contactForm.valid) {
            this.contactForm.markAllAsTouched();
            return;
        }
        this.matDialogRef.close(['new', this.contactForm.value, this.tags]);
    }

    saveExisting(): void {
        if (!this.contactForm.valid) {
            return;
        }
        this.matDialogRef.close(['edit', this.contactForm.value, this.tags]);
    }
    onSaveUsernameChanged(value:boolean){
        this.saveUsername = value;
    }
}
