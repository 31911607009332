import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { OrderService } from '../../order.service';
import { NewOrder } from '../new-order.model';
import { PaymentService } from '../../new-order/payment/payment.services';

@Component({
    selector: 'orders-anonymous-shipment-review',
    templateUrl: './anonymous-shipment-review.component.html',
    styleUrls: ['./anonymous-shipment-review.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousShipmentReviewComponent extends OrderAnonymousBaseComponent implements OnInit {
    coupon: string;
    invalidCoupon = false;

    constructor(private _orderService: OrderService, private _paymentService: PaymentService) {
        super();
    }

    ngOnInit(): void {}

    saveOrder(): void {
        this._orderService.saveOrder(this.form.value as NewOrder).then((order: NewOrder) => {
            const orderId = this.form.get('orderId') as FormControl;
            orderId.setValue(order.orderId, { onlySelf: true, emitEvent: false });
            this.continue();
        });
    }

    applyCoupon(): void {
        const totalPrice = this.form.get('totalPrice') as FormControl;
        const couponCode = this.form.get('couponCode') as FormControl;
        const disableGenerate = this.form.get('disableGenerate') as FormControl;
        this.invalidCoupon = false;
        if (couponCode.value === this.coupon) {
            return;
        }
        this._paymentService.applyCoupon(this.coupon, this.form.value.totalPrice).then((newAmount: number) => {
            const oldAmount = +totalPrice.value;
            if (oldAmount === newAmount) {
                this.invalidCoupon = true;
                totalPrice.setValue(newAmount);
            }
            totalPrice.setValue(newAmount);
            couponCode.setValue(this.coupon);
            disableGenerate.setValue(true);
        });
    }

    removeCoupon(): void {
        const couponCode = this.form.get('couponCode') as FormControl;
        const disableGenerate = this.form.get('disableGenerate') as FormControl;

        couponCode.setValue(undefined);
        this.coupon = '';
        this.form.get('totalPrice').setValue(this.form.get('originPrice').value);
        disableGenerate.setValue(true);
    }
}
