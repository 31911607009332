import { Contact } from 'app/main/contacts/contact-form/contact.model';
import { QuoteData } from './quote-data.model';

export class Order {
    detail: OrderDetail;
    billingDetails: BillingDetails;

    constructor(order?) {
        order = order || {};
        this.detail = order.detail || new OrderDetail();
        this.billingDetails = order.billingAddress || new BillingDetails();
    }
}

export class OrderDetail {
    orderId: number;
    quoteId: number;
    quote: QuoteData;
    shipperId: number;
    shipperName: string;
    shipperAddressId: number;
    shipperAddress: string;
    receiverId: number;
    receiverName: string;
    receiverAddressId: number;
    receiverAddress: string;
    goodsDescription: string;
    pickupDate: Date;
    timeRangeLookupId: number;
    commodityId?: number;
    specialInstructions: string;
    label: string;
    isNetPointShipper: boolean;
    isNetPointReceiver: boolean;

    proforma: ProformaDetails[];
    surcharges: OrderSurcharges[];
    bulkReceiver: Contact;
    // pieces: Piece[];


    constructor(detail?) {
        detail = detail || {};
        this.orderId = detail.orderId || 0;
        this.quoteId = detail.quoteId || 0;
        this.quote = detail.quote || new QuoteData();
        this.shipperId = detail.shipperId;
        this.shipperAddressId = detail.shipperAddressId;
        this.receiverId = detail.receiverId;
        this.receiverAddressId = detail.receiverAddressId;
        this.goodsDescription = detail.goodsDescription || '';
        this.specialInstructions = detail.specialInstructions || '';
        this.pickupDate = detail.pickupDate || new Date(new Date().setDate(new Date().getDate()));
        this.timeRangeLookupId = detail.timeRangeLookupId;
        this.isNetPointShipper = detail.isNetPointShipper || false;
        this.isNetPointReceiver = detail.isNetPointReceiver || false;
        this.shipperName = detail.shipperName || '';
        this.shipperAddress = detail.shipperAddress || '';
        this.receiverName = detail.receiverName || '';
        this.receiverAddress = detail.receiverAddress || '';
        this.label = detail.label || '';
        this.commodityId = detail.commodityId;
        this.bulkReceiver = detail.commodityId;
        this.proforma = detail.proforma;
        this.surcharges = detail.surcharges;
    }
}


export class BillingDetails {
    orderId: number;
    quoteId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    countryName: string;
    cityName: string;
    phoneNumber: string;
    phoneCountryCode: string;
    countryId: number;
    cityId: number;
    street: string;
    building: string;
    floor: string;
    zipCode: string;
    landmark: string;
    validPhoneNumber: boolean;
    confirmedPhoneNumber: boolean;
    clientAgreement: boolean;

    constructor(billing?) {
        billing = billing || {};
        this.orderId = billing.orderId || 0;
        this.quoteId = billing.quoteId || 0;
        this.firstName = billing.firstName || '';
        this.lastName = billing.lastName || '';
        this.countryName = billing.countryName || '';
        this.cityName = billing.cityName || '';
        this.companyName = billing.companyName || '';
        this.phoneNumber = billing.phoneNumber || '';
        this.phoneCountryCode = billing.phoneCountryCode || '';
        this.countryId = billing.countryId;
        this.cityId = billing.cityId;
        this.street = billing.street || '';
        this.building = billing.building || '';
        this.floor = billing.floor || '';
        this.zipCode = billing.zipCode || '';
        this.landmark = billing.landmark || '';
        this.validPhoneNumber = billing.phoneNumberValid || false;
        this.confirmedPhoneNumber = billing.confirmedPhoneNumber || false;
        this.clientAgreement = billing.clientAgreement || false;
    }
}
export class ReturnOrder {
    orderId: number;
    returnOrderId: number;
    pickupDate: Date;
    timeRangeLookupId: number;
    specialInstructions: string;
    // codAmount: number;
    // codCurrencyId: number;
    cods: CashOnDelivery[];
    serviceTypeId: number;
    constructor(billing?) {
        billing = billing || {};
        this.orderId = billing.orderId || 0;
        this.returnOrderId = billing.returnOrderId || 0;
        this.pickupDate = billing.pickupDate;
        this.timeRangeLookupId = billing.timeRangeLookupId;
        this.specialInstructions = billing.specialInstructions || '';
        // this.codAmount = billing.codAmount || 0;
        // this.codCurrencyId = billing.codCurrencyId || 0;
        this.serviceTypeId = billing.serviceTypeId || 2;
        this.cods = billing.cods || [];
    }
}

export class CashOnDelivery {
    quoteId: number;
    codAmount: number;
    codCurrencyId: number;
    constructor(cod?) {
        cod = cod || {};
        this.quoteId = cod.quoteId || 0;
        this.codAmount = cod.codAmount || 0;
        this.codCurrencyId = cod.codCurrencyId || 0;
    }
}
export class ProformaDetails {
    Id: number;
    orderId: number;
    description: string;
    quantity: number;
    countryId: number;
    customsCommodityCode: string;
    unitValue: number;
    totalValue: number;

    constructor(proforma?) {
        proforma = proforma || {};
        this.orderId = proforma.orderId || 0;
        this.Id = proforma.Id || 0;
        this.description = proforma.description || '';
        this.quantity = proforma.quantity || 1;
        this.countryId = proforma.countryId || 0;
        this.customsCommodityCode = proforma.customsCommodityCode || '';
        this.unitValue = proforma.unitValue || 0;
        this.totalValue = proforma.totalValue || 0;
    }
}

export class OrderSurcharges {
    orderId: number;
    surchargeId: number;
    description: string;
    checked: boolean;
    disabled: boolean;
    selected: boolean;
    constructor(surcharges?) {
        surcharges = surcharges || {};
        this.orderId = surcharges.orderId || 0;
        this.surchargeId = surcharges.surchargeId || 0;
        this.description = surcharges.description || 0;
        this.checked = surcharges.checked || false;
        this.disabled = surcharges.disabled || true;
        this.selected = surcharges.selected || false;
    }
}
export class Piece {
    pieceId: number;
    quoteId: number;
    weight: number;
    width: number;
    height: number;
    length: number;
    numberOfPieces: number;
    constructor(piece?) {
        piece = piece || {};
        this.pieceId = piece.pieceId || 0;
        this.quoteId = piece.quoteId || 0;
        this.weight = piece.weight || 0.5;
        this.width = piece.width || 0;
        this.height = piece.height || 0;
        this.length = piece.length || 0;
        this.numberOfPieces = piece.numberOfPieces || 1;
    }
}
export class BulkOrderPayment {
    orderId: number;
    erpOrderId: Guid;
    label: string;
}
export class SkuStock {
    name: string;
    currentStock: number;
    constructor(sku?) {
        sku = sku || {};
        this.name = sku.sku;
        this.currentStock = sku.currentStock || 0;
    }
}

export class BulkSkuStock {
    orderRef: string;
    name: string;
    currentStock: number;
    constructor(sku?) {
        sku = sku || {};
        this.orderRef = sku.orderRef || ''
        this.name = sku.sku;
        this.currentStock = sku.currentStock || 0;
    }
}
export class OrderSku {
    quoteId: number;
    sku: string;
    quantity: number;
    description: string;
    //available: number;
    constructor(sku?) {
        sku = sku || {};
        this.quoteId = sku.quoteId || 0;
        this.sku = sku.sku || "";
        this.quantity = sku.quantity || 0;
        this.description = sku.description || "";
        //this.available = sku.available || 0;
    }
}


