import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { FuseSharedModule } from '@fuse/shared.module';

import { AuthGuard } from 'app/_guards/auth.guard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { QuoteStartComponent } from './quote-start/quote-start.component';
import { QuoteLoaderComponent } from './quote-loader/quote-loader.component';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { QuotesQuickQuoteComponent } from './quick-quote/quick-quote.component';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { QuoteResultComponent } from './quote-result/quote-result.component';
import { QuoteCaveatsComponent } from './caveats/caveats.component';
import { DomesticQuoteComponent } from './domestic/domestic.component';
import { InternationalQuoteComponent } from './international/international.component';
import { AirfreightQuoteComponent } from './airfreight/airfreight.component';
import { OrderCodSharedModule } from 'app/_shared/order-cod-shared/order-cod-shared.module';

const routes = [
    {
        path: 'quotes/quick',
        canActivate: [AuthGuard],
        component: QuotesQuickQuoteComponent
    },
    {
        path: 'quotes/domestic',
        component: QuotesQuickQuoteComponent
    },
    {
        path: 'quotes/international',
        component: QuotesQuickQuoteComponent
    },
    {
        path: 'quotes/airfreight',
        canActivate: [AuthGuard],
        component: QuotesQuickQuoteComponent
    }
];

@NgModule({
    declarations: [
        QuoteStartComponent,
        QuoteLoaderComponent,
        QuotesQuickQuoteComponent,
        QuoteResultComponent,
        QuoteCaveatsComponent,
        InternationalQuoteComponent,
        DomesticQuoteComponent,
        AirfreightQuoteComponent
        
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        OrderCodSharedModule,
        MatButtonModule,
    ],
    // entryComponents: [OrderCodComponent],
    exports: []
})
export class QuotesModule {}
