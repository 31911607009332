<div id="forgot-password-confirmation" fxLayout="column">

    <div id="forgot-password-confirmation-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-confirmation-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.svg">
            </div>

            <div class="title">Please check your email to reset your password.</div>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div>
