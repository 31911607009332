<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.svg">
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" name="mynet-reset-email"
                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" type="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="resetPasswordForm.get('email').hasError('email') || resetPasswordForm.get('email').hasError('pattern')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" name="mynet-reset-password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div class="w-100-p">
                    <mat-label style="display: flex; flex: 1"
                        *ngIf="resetPasswordForm.controls['password'].hasError('minlength')"
                        class="text-danger w-100-p mb-8">
                        <mat-icon class="mr-4">cancel</mat-icon>
                        Must be at least 8 characters!
                    </mat-label>
                    <mat-label style="display: flex; flex: 1"
                        *ngIf="resetPasswordForm.controls['password'].hasError('hasNumber')"
                        class="text-danger w-100-p mb-8">
                        <mat-icon class="mr-4">cancel</mat-icon>
                        Must contain at least 1 number!
                    </mat-label>
                    <mat-label style="display: flex; flex: 1"
                        *ngIf="resetPasswordForm.controls['password'].hasError('hasCapitalCase')"
                        class="text-danger w-100-p mb-8">
                        <mat-icon class="mr-4">cancel</mat-icon>
                        Must contain at least 1 in Capital Case!
                    </mat-label>
                    <mat-label style="display: flex; flex: 1"
                        *ngIf="resetPasswordForm.controls['password'].hasError('hasSmallCase')"
                        class="text-danger w-100-p mb-8">
                        <mat-icon class="mr-4">cancel</mat-icon>
                        Must contain at least 1 Letter in Lower Case!
                    </mat-label>
                    <mat-label style="display: flex; flex: 1"
                        *ngIf="resetPasswordForm.controls['password'].hasError('hasSpecialCharacters')"
                        class="text-danger w-100-p mb-8">
                        <mat-icon class="mr-4">cancel</mat-icon>
                        Must contain at least 1 Special Character!
                    </mat-label>
                </div>

                <mat-form-field>
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="confirmPassword"
                        name="mynet-reset-password-confirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('confirmPassword').hasError('required')">
                        Password confirmation is required
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('confirmPassword').hasError('required') &&
                                       resetPasswordForm.get('confirmPassword').hasError('passwordsNotMatching')">
                        Passwords must match
                    </mat-error>
                </mat-form-field>
                <mat-error class="error-message" *ngIf="errorMessage">
                    {{errorMessage}}
                </mat-error>
                <button mat-raised-button class="submit-button" color="accent" aria-label="RESET MY PASSWORD"
                    (click)="submitForm()" [disabled]="resetPasswordForm.invalid">
                    RESET MY PASSWORD
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div>