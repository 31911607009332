import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModule } from './login/login.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { RegisterModule } from './register/register.module';
import { ConfirmEmailModule } from './confirm-email/confirm-email.module';
import { EmailConfirmedModule } from './email-confirmed/email-confirmed.module';
import { ForgotPasswordConfirmationModule } from './forgot-password-confirmation/forgot-password-confirmation.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { LogoutComponent } from './logout/logout.component';
import { RouterModule } from '@angular/router';
import { Routes } from 'app/Routes';

const routes = [
    {
        path: 'auth/logout',
        component: LogoutComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        LoginModule,
        ForgotPasswordModule,
        RegisterModule,
        ConfirmEmailModule,
        EmailConfirmedModule,
        ForgotPasswordConfirmationModule,
        ResetPasswordModule
    ],
    declarations: [LogoutComponent]
})
export class AuthenticationModule {}
