import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatProgressBarModule } from '@angular/material';
import { GoogleLocationComponent } from './google-location.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
    declarations: [GoogleLocationComponent],
    imports: [FuseSharedModule,MatProgressBarModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDJBObH2C09xCUoPZxXYiZwj5VyuaAUeaU'
        // please get your own API key here:
        // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    })],
    exports: [GoogleLocationComponent]
})
export class GoogleLocationModule {}


