import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DistributionListItem } from './distribution-list-item.model';
import { DistributionEvent } from './Distribution-events-dialog/distribution-event.model';

@Injectable()
export class DistributionListService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.onDistributionsChanged = new BehaviorSubject([]);
        this.onDistributionEventsChanged = new BehaviorSubject([]);
        this.onDistributionAdded = new BehaviorSubject(0);
    }
    distributions: DistributionListItem[];
    distributionEvents: DistributionEvent[];
    onDistributionsChanged: BehaviorSubject<DistributionListItem[]>;
    onDistributionEventsChanged: BehaviorSubject<DistributionEvent[]>;
    onDistributionAdded: BehaviorSubject<number>;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.getDistributions()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get orders
     *
     * @returns {Promise<any>}
     */
    getDistributions(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<DistributionListItem[]>(environment.apiUrl + 'v1/distributions')
                .subscribe((response: DistributionListItem[]) => {
                    this.distributions = response;
                    this.distributions.forEach(order => {
                        const date = new Date(Date.parse(order.statusUpdatedDateString));
                        order.statusUpdatedDateString =
                            date.toLocaleDateString() +
                            ' ' +
                            date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                    });
                    this.onDistributionsChanged.next(this.distributions);
                    resolve(response);
                }, reject);
        });
    }

    deleteDistributions(distributionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/distributions/' + distributionId).subscribe(_ => {
                this.getDistributions().then(() => {
                    resolve();
                }, reject);
            }, reject);
        });
    }
    duplicateDistribution(distributionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/distributions/duplicate/' + distributionId, null).subscribe(_ => {
                this.getDistributions().then(() => {
                    resolve();
                }, reject);
            }, reject);
        });
    }
    getDistributionEvents(distributionId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/distributions/events/' + distributionId)
                .subscribe((events: DistributionEvent[]) => {
                    if (events) {
                        events.forEach(e => {
                            const date = new Date(Date.parse(e.eventDate));
                            e.dateFormatted =
                                date.toLocaleDateString() +
                                ' ' +
                                date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                        });
                    }
                    this.distributionEvents = events;
                    this.onDistributionEventsChanged.next(this.distributionEvents);
                    resolve(events);
                }, reject);
        });
    }
}
