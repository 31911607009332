import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent } from 'rxjs';
import { ContactsListService } from './c-list.service';
import { ContactsListDataSource } from './c-list.datasource';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactListEditorComponent } from '../c-list-editor/c-list-editor.component';
import { ContactList, ContactListDetail } from '../c-list-form/c-list.model';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';

@Component({
    selector: 'contacts-contact-list',
    templateUrl: './c-list.component.html',
    styleUrls: ['./c-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ContactsListComponent implements OnInit, OnDestroy {
    dataSource: ContactsListDataSource | null;
    // displayedColumns = ['fullName', 'email', 'phoneNumber', 'address', 'tags', 'actions'];
    // displayedColumns = ['description' , 'contactCount',  'tags' , 'actions'];
    displayedColumns = ['description', 'contactCount', 'actions'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    contactDialogRef: MatDialogRef<ContactListEditorComponent>;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    selectedContact: ContactList;
    contactListDetail: ContactListDetail[] = [];

    contacts: ContactListItem[];

    contactCount: number = 0;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _contactsContactListService: ContactsListService, public _matDialog: MatDialog) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._contactsContactListService.getContactsForContactList().then(contacts => {
            this.contacts = contacts;
        });
        this.dataSource = new ContactsListDataSource(
            this._contactsContactListService,
            this.paginator,
            this.sort
        );
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    openContactDialog(action: string): void {
        this.contactDialogRef = this._matDialog.open(ContactListEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: action,
                contactList: this.selectedContact,
                contactListDetail: this.contactListDetail,
                contacts: this.contacts
            }
        });

        this.contactDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }
            const contactlist = response[1] as ContactList;
            const tags = response[2] as string[];
            if (contactlist.contactListDetail != null) {
                contactlist.contactListDetail.length = 0;
                contactlist.contactListDetail = response[3]
            }
            contactlist.tags = tags;

            if (contactlist.contactListId === 0 && status === 'new') {
                this._contactsContactListService.addNewContact(contactlist);
            } else if (contactlist.contactListId !== 0 && status === 'edit') {
                this._contactsContactListService.editContact(contactlist);
            }
        });
    }

    editContact(contactId: number): void {
        this._contactsContactListService.getContactList(contactId).then((contactList: ContactList) => {
            this.selectedContact = contactList;
            this.contactListDetail = contactList.contactListDetail;
            this.openContactDialog('edit');
        });
    }

    deleteContact(contactListId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        const contactlist = this._contactsContactListService.contactList.find(c => c.contactListId === contactListId);
        this.confirmDialogRef.componentInstance.header = 'Delete Contact List';
        this.confirmDialogRef.componentInstance.confirm = 'Yes';
        this.confirmDialogRef.componentInstance.cancel = 'No';
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Are you sure you want to delete "' + contactlist.description + '"?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._contactsContactListService.deleteContact(contactListId);
                this.dataSource = new ContactsListDataSource(
                    this._contactsContactListService,
                    this.paginator,
                    this.sort
                );
            }
            this.confirmDialogRef = null;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    pagination(pageEvents): void {
        pageEvents.pageIndex = pageEvents.pageIndex + 1;
        this._contactsContactListService.getContactLists(pageEvents);
    }

}
