import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CListItem } from './c-list-item.model';
import { Pagination } from 'app/_models/pagination';
import { ContactList } from '../c-list-form/c-list.model';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';

@Injectable()
export class ContactsListService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onContactAdded = new BehaviorSubject(0);
    }
    contactList: CListItem[];
    contacts: ContactListItem[];
    paginationInfo: Pagination;
    onContactsChanged: BehaviorSubject<CListItem[]>;
    onContactAdded: BehaviorSubject<number>;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        let promise = new Promise((resolve, reject) => {

            Promise.all([this.getContactLists()]).then(() => {
                resolve();
            }, reject);
            // Promise.all([this.getContactsForUser()]).then(() => {
            //     resolve();
            // }, reject);
        });

        return promise;
    }

    /**
     * Get contacts list
     *
     * @returns {Promise<any>}
     */
    getContactLists(paginationInfo?: any): Promise<any> {
        let params = new HttpParams();

        if (paginationInfo != null) {
            params = params.append('pageNumber', paginationInfo.pageIndex);
            params = params.append('pageSize', paginationInfo.pageSize);
        }
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            this._httpClient
                .get<CListItem[]>(environment.apiUrl + 'v1/contactList', { observe: 'response', params })
                .subscribe(response => {
                    const paginationHeaders = response.headers.get('Pagination');
                    this.contactList = response.body;

                    if (paginationHeaders != null) {
                        this.paginationInfo = JSON.parse(paginationHeaders);
                    }
                    this.onContactsChanged.next(response.body);
                    resolve(response);
                }, reject);
        });
    }

    getContactsForContactList(): Promise<any[]> {
        let params = new HttpParams();
        params = params.append('all', 'true');

        return new Promise((resolve, reject) => {
            this._httpClient
                .get<ContactListItem[]>(environment.apiUrl + 'v1/contacts/contactList', { observe: 'response', params })
                .subscribe(response => {
                    const paginationHeaders = response.headers.get('Pagination');
                    this.contacts = response.body;
                    resolve(response.body);
                    if (paginationHeaders != null) {
                        // this.paginationInfo = JSON.parse(paginationHeaders);
                    }

                }, reject);
        });

    }
    getContactListDetail(contactListId: number): Promise<any[]> {

        return new Promise((resolve, reject) => {
            this._httpClient.get<ContactList>(environment.apiUrl + 'v1/contactList/' + contactListId).subscribe(result => {
            }, reject);
        });

        //     return new Promise((resolve, reject) => {
        //         this._httpClient
        //             .get<ContactListItem[]>(environment.apiUrl + 'v1/contacts', { observe: 'response', params })
        //             .subscribe(response => {
        //                 const paginationHeaders = response.headers.get('Pagination');
        //                 this.contacts = response.body;
        //                 resolve(response.body);
        //                 if (paginationHeaders != null) {
        //                     this.paginationInfo = JSON.parse(paginationHeaders);
        //                 }

        //             }, reject);
        // });

    }

    deleteContact(contactListId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/contactList/' + contactListId).subscribe(_ => {
                this.getContactLists().then(() => {
                    resolve();
                }, reject);
            }, reject);
        });
    }
    addNewContact(contactList: ContactList): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/contactList/', contactList).subscribe((contactId: number) => {
                this.getContactLists().then(() => {
                    this.onContactAdded.next(contactId);
                    resolve();
                }, reject);
            }, reject);
        });
    }
    // addNewDistributionContactList(contactList: ContactList): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(environment.apiUrl + 'v1/contactList/', contactList).subscribe((contactId: number) => {
    //             // this.getContactLists().then(() => {
    //                 // this.onContactAdded.next(contactId);
    //                 resolve();
    //             }, reject);
    //         }, reject);
    //     });
    // }

    editContact(contactList: ContactList): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.apiUrl + 'v1/contactList/', contactList).subscribe(_ => {
                this.getContactLists().then(() => {
                    resolve();
                }, reject);
            }, reject);
        });
    }

    getContactList(contactListId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<ContactList>(environment.apiUrl + 'v1/contactList/' + contactListId).subscribe(result => {
                resolve(result);
            }, reject);
        });
    }

    isContactExist(contactList: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/contactList/exist', contactList).subscribe(result => {
                resolve(result);
            }, reject);
        });
    }
}
