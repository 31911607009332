import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { OrderEvent } from 'app/main/orders/list/order-events-dialog/order-event.model';
import { Routes } from 'app/Routes';
import { Order } from '../../new-order/order.model';
import { OrderService } from '../../order.service';

@Component({
    selector: 'return-order-view',
    templateUrl: './return-order-view.component.html',
    styleUrls: ['./return-order-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ReturnOrdersViewComponent implements OnInit {
    @Input() orderId: number;
    @Input() returnOrders: Order[];



    constructor(private _orderService: OrderService) { }

    ngOnInit(): void {
        // this._orderListService.getOrderEvents(this.orderId);

        // this._orderListService.onOrderEventsChanged.subscribe((events: OrderEvent[]) => {
        //     this.events = events;
        //     if (this.events) {
        //         this.lastEvent.emit(this.events[0]);
        //     }
        // });
        
        // this._distributionService.getDistributionEvents(this.distributionId)
        //     .then((result: DistributionEvent[]) => {
        //         this.distributionEvents = result;
        //     }
        //     )

        // this._distributionListService.getDistributionForView(this.distributionId);
        // this._distributionListService.getDistributionForView(this.distributionId).then((result: Distribution) => {

        //     this.distribution = result;
        // });

        // this._distributionListService.onDistributionAdded.subscribe((distribution: Distribution) => {
        //     this.distribution = distribution;
        // });
        
    }
    navigateToTracking(label: string): void {
        window.location.href = [Routes.trackingURL + label].toString();
    }
}
