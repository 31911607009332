import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { OrderListItem } from './order-list-item.model';
import { ReturnOrder, BillingDetails } from '../new-order/order.model';
import { OrderEvent } from './order-return-dialog/order-return.model';
import { isUndefined, List } from 'lodash';
import { Pagination } from 'app/_models/pagination';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';

@Injectable()
export class OrdersOrderListService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private datePipe: DatePipe) {
        // Set the defaults
        this.onOrdersChanged = new BehaviorSubject([]);
        this.onOrderEventsChanged = new BehaviorSubject([]);
        this.onOrderAdded = new BehaviorSubject(0);
    }
    orders: OrderListItem[];
    orderEvents: OrderEvent[];
    onOrdersChanged: BehaviorSubject<OrderListItem[]>;
    onOrderEventsChanged: BehaviorSubject<OrderEvent[]>;
    onOrderAdded: BehaviorSubject<number>;
    paginationInfo: Pagination;


    fromDate: Date;
    toDate: Date;


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        if (isUndefined(this.fromDate)) {
            this.fromDate = new Date();
            this.fromDate.setHours(0, 0, 0);
            // this.fromDate.setDate(this.fromDate.getDate());
            // this.fromDate.setHours(1);
            // this.fromDate.setMinutes(0);
            // this.fromDate.setSeconds(0);
        }
        if (isUndefined(this.toDate)) {
            this.toDate = new Date();
            this.toDate.setHours(23, 59, 0);

            // this.toDate.setDate(this.fromDate.getDate());
            // this.toDate.setHours(23);
            // this.toDate.setMinutes(59);
            // this.toDate.setSeconds(0);
        }
        return new Promise((resolve, reject) => {
            Promise.all([this.getOrders(null, this.fromDate, this.toDate)]).then(() => {
                resolve(this);
            }, reject);
        });
    }

    /**
     * Get orders
     *
     * @returns {Promise<any>}
     */
    getOrders(paginationInfo?: any, fromDate?: Date, toDate?: Date): Promise<any> {
        let params = new HttpParams();
        if (!isNullOrUndefined(paginationInfo)) {
            params = params.append('pageNumber', paginationInfo.pageIndex);
            params = params.append('pageSize', paginationInfo.pageSize ? paginationInfo.pageSize : '10');
        }
        if (!isNullOrUndefined(fromDate)) {
            params = params.append('fromDate', this.datePipe.transform(fromDate, 'MM/dd/yyyy hh:mm:ss a'));
        }

        if (!isNullOrUndefined(toDate)) {
            params = params.append('toDate', this.datePipe.transform(toDate, 'MM/dd/yyyy hh:mm:ss a'));
        }

        return new Promise((resolve, reject) => {
            this._httpClient
                .get<OrderListItem[]>(environment.apiUrl + 'v1/orders', { params })
                .subscribe((response: OrderListItem[]) => {
                    this.orders = response;
                    // this.orders.forEach(order => {
                    //     const date = new Date(Date.parse(order.statusUpdatedDateString));
                    //     order.statusUpdatedDateString =
                    //         date.toLocaleDateString() + ' ' + date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                    // });

                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);
        });
    }

    getOrdersForLabel(orderIds: string): Promise<any> {
        let params = new HttpParams();
        if (orderIds != null) {
            params = params.append('orderIds', orderIds);
        }
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<OrderListItem[]>(environment.apiUrl + 'v1/orders/Label', { observe: 'response', params })
                .subscribe(response => {
                    this.orders = response.body as OrderListItem[];
                    this.orders.forEach(order => {
                        const date = new Date(Date.parse(order.statusUpdatedDateString));
                        order.statusUpdatedDateString =
                            date.toLocaleDateString() +
                            ' ' +
                            date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                    });
                    this.onOrdersChanged.next(this.orders);
                    resolve(response);
                }, reject);

            // .subscribe((response: OrderListItem[]) => {
            //     this.orders = response;
            //     this.onOrdersChanged.next(response);
            //     //             // response = response.filter(x => x.label == null).sort(x => x.orderId);
            //     // this.orders = response.body as OrderListItem[];
            //     //             this.orders.forEach(order => {
            //     //                 const date = new Date(Date.parse(order.statusUpdatedDateString));
            //     //                 order.statusUpdatedDateString =
            //     //                     date.toLocaleDateString() +
            //     //                     ' ' +
            //     //                     date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
            //     //             });
            //     //             // this.onOrdersChanged.next(this.orders);
            //     resolve(response);
            // }, reject);
        });
    }



    // return new Promise((resolve, reject) => {
    //     this._httpClient
    //         .get<OrderListItem[]>(environment.apiUrl + 'v1/orders')
    //         .subscribe((response: OrderListItem[]) => {
    //             // response = response.filter(x => x.label == null).sort(x => x.orderId);
    //             this.orders = response;
    //             this.orders.forEach(order => {
    //                 const date = new Date(Date.parse(order.statusUpdatedDateString));
    //                 order.statusUpdatedDateString =
    //                     date.toLocaleDateString() +
    //                     ' ' +
    //                     date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
    //             });
    //             // this.onOrdersChanged.next(this.orders);
    //             resolve(response);
    //         }, reject);
    // });
    duplicateOrder(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/orders/duplicate/' + orderId, null).subscribe(_ => {
                this.getOrders(null, this.fromDate, this.toDate).then(() => {
                    resolve(this);
                }, reject);
            }, reject);
        });
    }

    cancelOrder(label): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/orders/cancel/' + label, null).subscribe((response: any[]) => {
                resolve(response);
                if (response[0].result.toString().toLowerCase() == "success")
                    this.getOrders(null, this.fromDate, this.toDate).then(() => {
                        resolve(this);
                    }, reject);
            }, reject);
        });
    }
    addReturnOrder(order: ReturnOrder): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/Return', order)
                .subscribe((billindDetails: BillingDetails) => {
                    this.onOrderAdded.next(billindDetails.orderId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {
                        billindDetails.countryId = 0;
                    }
                    // resolve(billindDetails);
                    this.getOrders(null, this.fromDate, this.toDate).then(() => {
                        resolve(this);
                    }, reject);
                }, reject);
        });
    }

    deleteOrder(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/orders/' + orderId).subscribe(_ => {
                this.getOrders(null, this.fromDate, this.toDate).then(() => {
                    resolve(this);
                }, reject);
            }, reject);
        });
    }

    getOrderEvents(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/orders/events/' + orderId)
                .subscribe((events: OrderEvent[]) => {
                    if (events) {
                        events.forEach(e => {
                            const date = new Date(Date.parse(e.eventDate));
                            e.dateFormatted =
                                date.toLocaleDateString() +
                                ' ' +
                                date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                        });
                    }

                    this.orderEvents = events;
                    this.onOrderEventsChanged.next(this.orderEvents);
                    resolve(events);
                }, reject);
        });
    }
  
}
