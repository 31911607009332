import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ContactListItem } from './contact-list-item.model';
import { Contact } from '../contact-form/contact.model';
import { Pagination } from 'app/_models/pagination';
import { stringify } from '@angular/compiler/src/util';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ContactsContactListService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onContactAdded = new BehaviorSubject(0);
    }
    contacts: ContactListItem[];
    paginationInfo: Pagination;
    onContactsChanged: BehaviorSubject<ContactListItem[]>;
    onContactAdded: BehaviorSubject<number>;
    typeId: number = 5000;


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.getContacts()]).then(() => {
                resolve(this);
            }, reject);
        });
    }
    // Home = 5000,
    // Other = 5001,
    // Import = 5002
    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(paginationInfo?: any, q?: string): Promise<any> {
        let params = new HttpParams();
        if (q == null)
            q = "";


        if (paginationInfo != null) {
            params = params.append('pageNumber', paginationInfo.pageIndex);
            params = params.append('pageSize', paginationInfo.pageSize);
            params = params.append('typeId', this.typeId.toString());
            params = params.append('q', q.toString());
        }

        return new Promise((resolve, reject) => {
            this._httpClient
                .get<ContactListItem[]>(environment.apiUrl + 'v1/contacts', { observe: 'response', params })
                .subscribe(response => {
                    const paginationHeaders = response.headers.get('Pagination');
                    this.contacts = response.body;
                    if (paginationHeaders != null) {
                        this.paginationInfo = JSON.parse(paginationHeaders);
                    }
                    this.onContactsChanged.next(this.contacts);
                    resolve(response);
                }, reject);
        });
    }

    getContactsForOrders(): Promise<ContactListItem[]> {
        let params = new HttpParams();
        params = params.append('all', 'true');

        return new Promise((resolve, reject) => {
            this._httpClient
                .get<ContactListItem[]>(environment.apiUrl + 'v1/contacts/order', { observe: 'response', params })
                .subscribe(response => {
                    const paginationHeaders = response.headers.get('Pagination');
                    this.contacts = response.body;
                    if (paginationHeaders != null) {
                        this.paginationInfo = JSON.parse(paginationHeaders);
                    }
                    this.onContactsChanged.next(response.body);
                    resolve(response.body);
                }, reject);
        });
    }
    getContactsForImport(): Promise<ContactListItem[]> {
        let params = new HttpParams();
        params = params.append('all', 'true');
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<ContactListItem[]>(environment.apiUrl + 'v1/contacts/importOrder', { observe: 'response', params })
                .subscribe(response => {
                    resolve(response.body);
                }, reject);
        });
    }

    getShipperReferences(): Promise<String[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<String[]>(environment.apiUrl + 'v1/contacts/reference')
                .subscribe(response => {
                    resolve(response);
                }, reject);
        });
    }
    IsShipperReferenceExists(reference): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/contacts/reference/' + reference).subscribe(result => {
                resolve(result);
            }, reject);
        });
    }



    deleteContact(contactId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/contacts/' + contactId).subscribe((canDelete: boolean) => {
                resolve(canDelete);
                this.getContacts().then(() => {
                    resolve(this);
                }, reject);
            }, reject);
        });
    }
    addNewContact(contact: Contact): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/contacts/', contact).subscribe((contactId: number) => {
                this.getContacts().then(() => {
                    this.onContactAdded.next(contactId);
                    resolve(this);
                }, reject);
            }, reject);
        });
    }
    addImportContacts(contacts: Contact[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/contacts/import', contacts).subscribe((canImport: boolean) => {
                resolve(canImport);
                // this.getContacts().then(() => {
                //     resolve();
                // }, reject);
            }, reject);
        });
    }

    // addOrderContact(contact: Contact): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(environment.apiUrl + 'v1/contacts/order', contact).subscribe((contact: any) => {
    //             resolve(contact);
    //         }, reject);
    //     });
    // }

    addOrderContact(contact: Contact): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/contacts/order', contact);
    }
    editContact(contact: Contact): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.apiUrl + 'v1/contacts/', contact).subscribe(_ => {
                this.getContacts().then(() => {
                    resolve();
                }, reject);
            }, reject);
        });
    }

    getContact(contactId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Contact>(environment.apiUrl + 'v1/contacts/' + contactId).subscribe(result => {
                resolve(result);
            }, reject);
        });
    }

    isContactExist(contact: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/contacts/exist', contact).subscribe(result => {
                resolve(result);
            }, reject);
        });
    }
}
