import { AuthGuard } from 'app/_guards/auth.guard';
import { ContactsListComponent } from './c-list/c-list.component';
import { ContactsListService } from './c-list/c-list.service';
const ɵ0 = ContactsListService;
const routes = [
    {
        path: 'contactList',
        canActivate: [AuthGuard],
        component: ContactsListComponent,
        resolve: {
            data: ɵ0
        }
    }
];
export class ContactsListModule {
}
export { ɵ0 };
