import { DataSource } from '@angular/cdk/table';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { DistributionListService } from './distribution-list.service';
import { DistributionListItem } from './distribution-list-item.model';

export class DistributionListDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    constructor(
        private _distributionListService: DistributionListService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();
        
        this.filteredData = this._distributionListService.distributions;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._distributionListService.onDistributionsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(
            map(() => {
                let data = this._distributionListService.distributions.slice();

                data = this.filterData(data);
                this.filteredData = [...data];

                data = this.sortData(data);

                // Grab the page's slice of data
                const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                return data.splice(startIndex, this._matPaginator.pageSize);
            })
        );
    }

    /**
     * Disconnect
     */
    disconnect(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data: DistributionListItem[]): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: string | Date;
            let propertyB: string | Date;

            switch (this._matSort.active) {
                case 'direction':
                    [propertyA, propertyB] = [ServiceDirection[a.direction], ServiceDirection[b.direction]];
                    break;
                case 'from':
                    [propertyA, propertyB] = [a.from, b.from];
                    break;
                case 'to':
                    [propertyA, propertyB] = [a.to, b.to];
                    break;
                case 'pickupDate':
                    [propertyA, propertyB] = [a.pickupDate, b.pickupDate];
                    break;
                // case 'label':
                //     [propertyA, propertyB] = [a.label, b.label];
                //     break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
                case 'statusDate':
                    [propertyA, propertyB] = [
                        new Date(Date.parse(a.statusUpdatedDateString)),
                        new Date(Date.parse(b.statusUpdatedDateString))
                    ];
                    break;
            }

            const valueA = propertyA;
            const valueB = propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }
}
