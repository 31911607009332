<div id="distributions" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="mb-24 mb-md-0" style="margin-top: 2rem">
                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        shopping_cart
                    </mat-icon>
                    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        Distributions
                    </span>
                    <!-- Distributions -->
                </div>
                <div class="logo-text h3" style="margin-top: 2rem; margin-bottom: 2rem;">
                    <app-current-conversion-rate></app-current-conversion-rate>
                </div>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for an order">
                </div>
            </div>

            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button class="add-contact-button fuse-white mt-24 mt-md-0"
                [routerLink]="Routes.newDistribution">
                <span>New Distribution</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="distributions-table" #table [dataSource]="dataSource" matSort
                [@animateStagger]="{value:'30'}" fusePerfectScrollbar>

                <!-- Direction Column -->
                <ng-container matColumnDef="direction">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Direction</mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <mat-icon *ngIf="order.direction === ServiceDirection[ServiceDirection.Domestic]">local_shipping
                        </mat-icon>
                        <mat-icon *ngIf="order.direction === ServiceDirection[ServiceDirection.Import]">flight_land
                        </mat-icon>
                        <mat-icon *ngIf="order.direction === ServiceDirection[ServiceDirection.Export]">flight_takeoff
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- Label Column -->
                <ng-container matColumnDef="label">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Tracking Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <a *ngIf="order.label" class="text-truncate" [routerLink]="'/orders/view/' + order.orderId">
                            {{ order.label }}
                        </a>
                        <p *ngIf="!order.label">N/A</p>
                    </mat-cell>
                </ng-container>

                <!-- Shipper Column -->
                <ng-container matColumnDef="shipper">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>From Shipper
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{order.shipper}}</p>
                    </mat-cell>
                </ng-container>

                <!-- From Column -->
                <ng-container matColumnDef="from">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>From Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{order.from}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Receiver Column -->
                <ng-container matColumnDef="receiver">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>To Receiver List
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{order.receiver}}</p>
                    </mat-cell>
                </ng-container>

                <!-- To Column -->
                <ng-container matColumnDef="to">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm># of Receivers
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{order.receiverCount}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Total
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order" fxHide fxShow.gt-sm>
                        <p class="text-truncate">${{order.price | number:'.2-2'}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <ng-container [ngSwitch]="order.status">

                            <p class="text-truncate purple-300 badge" *ngSwitchCase="'Draft'">
                                {{order.status}}
                            </p>

                            <p class="text-truncate green-800 badge" *ngSwitchCase="'Payment accepted'">
                                {{order.status}}
                            </p>
                            <p class="text-truncate blue-900 badge" style="cursor: pointer;"
                                (click)="viewOrderEvents(order)" *ngSwitchDefault>
                                {{order.status}}
                            </p>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- Status Date Column -->
                <ng-container matColumnDef="statusDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <p class="text-truncate">
                            {{ order.statusUpdatedDateString }}
                        </p>
                    </mat-cell>
                </ng-container>



                <!-- Buttons Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let order">
                        <div fxFlex="row" fxLayoutAlign="end center" *ngIf="order.status === 'Draft'">

                            <button mat-icon-button tabindex="-1" [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item aria-label="edit"
                                    [routerLink]="'/distributions/' + order.distributionId">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item aria-label="remove"
                                    (click)="deleteDistribution(order.distributionId)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Remove</span>
                                </button>
                            </mat-menu>
                        </div>
                        <div fxFlex="row" fxLayoutAlign="end center" *ngIf="order.status !== 'Draft'">

                            <button mat-icon-button tabindex="-1" [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <!-- <button mat-menu-item aria-label="print" [routerLink]="'/distributions/print/' + order.distributionId"> -->
                                <button mat-menu-item aria-label="print"
                                    (click)="printDistribution(order.distributionId)">
                                    <mat-icon>print</mat-icon>
                                    <span>Print</span>
                                </button>
                                <button mat-menu-item aria-label="print"
                                    (click)="printDistributionManifest(order.distributionId)">
                                    <mat-icon>print</mat-icon>
                                    <span>Print Manifest</span>
                                </button>
                                <!-- <button mat-menu-item aria-label="remove"
                                    (click)="duplicateDistribution(order.distributionId)">
                                    <mat-icon>file_copy</mat-icon>
                                    <span>Duplicate</span>
                                </button> -->
                                <button mat-menu-item aria-label="remove" [disabled]="true"
                                    (click)="duplicateDistribution(order.distributionId)">
                                    <mat-icon>email</mat-icon>
                                    <span>Send</span>
                                </button>
                            </mat-menu>
                        </div>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let order; columns: displayedColumns;" class="order" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}"
                    [routerLink]="'/distributions/view/' + order.distributionId">
                </mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>