import { CommodityProductType, ServiceType } from 'app/_enums/ServiceType.enum';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';

export class NewOrder {
    shipper: ContactDetails;
    receiver: ContactDetails;
    packageDetails: PackageDetails;
    pickup: PickupDetails;
    shipmentDetails: ShipmentDetails;
    currentDirection: ServiceDirection;
    orderId: number;
    couponCode: string;
    disableGenerate: boolean;

    constructor() {
        this.shipper = new ContactDetails();
        this.receiver = new ContactDetails();
        this.pickup = new PickupDetails();
        this.packageDetails = new PackageDetails();
        this.shipmentDetails = new ShipmentDetails();
        this.currentDirection = ServiceDirection.Domestic;
        this.orderId = undefined;
        this.couponCode = undefined;
        this.disableGenerate = false;
    }
}

export class ContactDetails {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    address: Address;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.mobileNumber = '';
        this.address = new Address();
    }
}

export class Address {
    countryId: number;
    cityId: number;
    street: string;
    building: string;
    floor: string;
    zipCode: string;
    landmark: string;

    constructor() {
        this.street = '';
        this.building = '';
        this.floor = '';
        this.zipCode = '';
        this.landmark = '';
        this.countryId = 0;
        this.cityId = 0;
    }
}

export class PackageDetails {
    packages: Package[];
    packageType: ServiceType;

    constructor() {
        this.packages = [];
        this.packageType = ServiceType.Parcels;
    }
}

export class Package {
    packageId: number;
    shipmentValue: number;
    weight: number;
    dimension: Dimension;

    constructor() {
        this.dimension = new Dimension();
        this.packageId = 0;
        this.weight = undefined;
        this.shipmentValue = undefined;
    }
}

export class Dimension {
    length: number;
    width: number;
    height: number;
    constructor() {
        this.length = undefined;
        this.width = undefined;
        this.height = undefined;
    }
}

export class PickupDetails {
    pickupDate: Date;
    specialInstructions: string;
    shippingTypeId: number;

    constructor() {
        this.pickupDate = new Date(new Date().setDate(new Date().getDate() + 1));
        this.specialInstructions = '';
        this.shippingTypeId = 0;
    }
}

export class ShipmentDetails {
    commodityId: number;
    numberOfPieces: number;
    descriptionOfGoods: string;

    constructor() {
        this.descriptionOfGoods = '';
        this.commodityId = undefined;
        this.numberOfPieces = 1;
    }
}
