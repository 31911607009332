import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { Package } from './Package.model';

@Injectable({
    providedIn: 'root'
})
export class PackageService {
    constructor(private _httpClient: HttpClient) {}

    getPackages(): Observable<Package[]> {
        return this._httpClient.get<Package[]>(environment.apiUrl + 'v1/Packages');
    }
}
