<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon>access_time</mat-icon>
            <span class="title dialog-title text-center">
                Orders
                <br />
                <!-- {{ label }} -->
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close(false)" aria-label="Close dialog" type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <table class="simple">
            <thead>
                <tr>
                    <th class="text-center black" style="font-weight: bold; color: #333;">Event Status</th>
                    <th class="text-center black" style="font-weight: bold; color: #333;">Updated On</th>
                </tr>
            </thead>
            <tbody>
                
                <tr *ngFor="let order of ordersImported">
                    <td class="text-center">
                        <span class="h5">
                            <!-- {{ order.label }} -->
                            {{ order }}

                        </span>
                    </td>
                    <td class="text-center">
                        <span class="h5">
                            <!-- {{ order.receiver }} -->
                            {{ order }}

                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </mat-dialog-content>
</div>