<div id="invoice" class="compact page-layout blank" fxLayout="column">

    <div class="invoice-container">

        <div class="doNotPrint">
            <button mat-flat-button color="primary" aria-label="back" [routerLink]="'/orders'">
                <mat-icon>back</mat-icon>
                <span>Back</span>
            </button>
            <button mat-flat-button color="primary" aria-label="print" (click)="onPrint()">
                <mat-icon>print</mat-icon>
                <span>Print</span>
            </button>
        </div>
        <!-- INVOICE -->
        <div class="card" Id="print-section">
            <ng-container *ngFor="let i of orders ; let index = index , let even = even">
                <div *ngFor="let a of pageNumber(i.orderInfo.numberOfPieces)">
                    <div class="header">
                        <div fxLayout="column" fxLayoutAlign="space-between stretch">
                            <div class="client">
                                <div class="invoice-number" fxLayout="row" fxLayoutAlign="start stretch">
                                    <img width="137" height="137" src="assets/images/logos/logo.svg">
                                    <ngx-barcode [bc-value]="i.label" [bc-margin-left]="100" [bc-margin-top]="50"
                                        [bc-height]="50" [bc-width]="3" [bc-display-value]="true"></ngx-barcode>
                                </div>
                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title">Shipper</span>
                                        </div>
                                        <div *ngIf="i.shipper.companyName.length > 0" class="shipper"><strong
                                                style="font-size:  larger;">{{i.shipper.companyName}}</strong></div>
                                        <div class="shipper">{{i.shipper.fullName}}</div>
                                        <div class="address">{{i.shipper.address.display}}</div>
                                        <div class="address">{{i.shipper.address.street}}</div>
                                        <div class="landmark">{{i.shipper.address.landmark}}</div>

                                    </div>
                                    <div class="shipper2">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="address">Ref: {{i.orderInfo.shipperReference}}</div>
                                        <div class="address">{{i.shipper.phoneCountryCode}} {{i.shipper.phoneNumber}}</div>
                                        <div class="landmark">{{i.shipper.email}}</div>
                                    </div>
                                </div>

                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title">Receiver</span>
                                        </div>
                                        <div *ngIf="i.receiver.companyName.length > 0" class="shipper"><strong
                                                style="font-size:  larger;"> {{i.receiver.companyName}}</strong></div>
                                        <div class="shipper">{{i.receiver.fullName}}</div>
                                        <div class="address">{{i.receiver.address.display}}</div>
                                        <div class="address">{{i.receiver.address.street}}</div>
                                        <div class="landmark">{{i.receiver.address.landmark}}</div>
                                        <div class="landmark">Floor: {{i.receiver.address.floor}}</div>
                                        <div class="landmark">Building: {{i.receiver.address.building}}</div>
                                    </div>
                                    <div class="shipper2">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="address">{{i.receiver.phoneCountryCode}} {{i.receiver.phoneNumber}}</div>
                                        <div class="landmark">{{i.receiver.email}}</div>
                                        <div class="landmark">Zip Code: {{i.receiver.address.zipCode}}</div>
                                    </div>
                                </div>

                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">

                                        <div class="shipper">Weight: {{i.orderInfo.weight}}</div>
                                        <div></div>
                                        <div class="address">Pieces: {{i.orderInfo.numberOfPieces}}</div>

                                        <!-- <div class="landmark">Service: {{i.orderInfo.shippingType}}</div> -->
                                        <div class="landmark">Description: {{i.orderInfo.goodsDescription}}</div>
                                        <div class="landmark">Special Instructions: {{i.orderInfo.specialInstructions}}
                                        </div>
                                    </div>

                                    <div class="shipper2" *ngIf="i.orderCods && i.orderCods.length > 0">
                                        <div class="Cod">
                                            <table class="simple">
                                                <tbody>
                                                    <tr *ngFor="let cod of i.orderCods">
                                                        <div class="shipper">Cod Amount {{cod.codCurrency}}:
                                                            {{cod.codAmount}}</div>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="shipper2" *ngIf=" i.orderCods.length == 0">
                                        <div class="Cod">
                                            <table class="simple">
                                                <tbody>
                                                    <div class="shipper"> </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div style="font-size: 60px;font-weight: bolder; margin-top: 52px;">
                                        {{a}}/{{i.orderInfo.numberOfPieces}}</div>
                                </div>
                                <div class="info">
                                    <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                    <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or
                                        visit
                                        our website www.thenet.group
                                    </div>  
                                </div> -->
                                    <div fxLayout="row" fxLayoutAlign="space-between start">
                                        <div class="small-note">
                                            *1$ and rounding fees will be applied to Total Price*
                                        </div>
                                        <!-- <div style="font-size: large;" *ngIf="i.orderInfo.numberOfPieces > 0"
                                            fxLayout="row" fxLayoutAlign="end end">{{a}} of
                                            {{i.orderInfo.numberOfPieces}}</div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">

                            <div class="contact-note">
                                You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                                our website www.thenet.group
                            </div>

                        </div>

                        <div class="pagebreak"> </div>
                        <div *ngIf="i.proformaDetails.length > 0" fxLayout="column"
                            fxLayoutAlign="space-between stretch">
                            <div class="client">
                                <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                    <span style="font-size: xx-large;" class="title">Proforma Invoice </span>
                                </div>
                                <div class="proforma" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="sentBy" fxLayout="row" fxLayoutAlign="start center">
                                            <span>Sent By</span>
                                        </div>
                                        <div class="detailInfo">
                                            <div><span class="detailHeader">Name : </span> {{i.shipper.fullName}}</div>
                                            <div><span class="detailHeader">Address :
                                                </span>{{i.shipper.address.display}}
                                            </div>
                                            <div><span class="detailHeader">City : </span>{{i.shipper.address.cityName}}
                                            </div>
                                            <div><span class="detailHeader">Country :
                                                </span>{{i.shipper.address.countryName}}
                                            </div>
                                            <div><span class="detailHeader">Phone/Mobile :
                                                </span>{{i.shipper.phoneNumber}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="shipper2">
                                        <div class="sentBy" fxLayout="row" fxLayoutAlign="start center">
                                            <span>Sent To</span>
                                        </div>
                                        <div class="detailInfo">
                                            <div><span class="detailHeader">Name : </span> {{i.receiver.fullName}}</div>
                                            <div><span class="detailHeader">Address :
                                                </span>{{i.receiver.address.display}}
                                            </div>
                                            <div><span class="detailHeader">City :
                                                </span>{{i.receiver.address.cityName}}
                                            </div>
                                            <div><span class="detailHeader">Country :
                                                </span>{{i.receiver.address.countryName}}
                                            </div>
                                            <div><span class="detailHeader">Phone/Mobile :
                                                </span>{{i.receiver.phoneNumber}}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <table class="simples">
                                    <thead>
                                        <tr>
                                            <th>Full Description Of Goods</th>
                                            <th>Quantity</th>
                                            <th>Origin Country</th>
                                            <th>Customs Commodity Code</th>
                                            <th>Unit Value</th>
                                            <th>Total Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let proforma of i.proformaDetails">
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.description}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.quantity}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.countryName}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.customsCommodityCode}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.unitValue}} $
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.totalValue}} $
                                                </span>
                                            </td>
                                        </tr>
                                        <tr style="font-weight: bold; border : none ; color: rgb(0, 0, 0);">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total: </td>
                                            <td>{{ getSum(index) }}
                                        </tr>
                                        <tr style="font-weight: bold; border: none;">
                                            <td>Date : <span>{{proformaDate | date: 'dd-MM-yyyy'}}</span></td>
                                            <!-- <td></td>
                                        <td>ISSUE BY:..............................................</td> -->
                                        </tr>
                                        <tr style="font-weight: bold; border: none;">
                                            <td>ISSUE BY:..............................................</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                    <div class="pagebreak"> </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>