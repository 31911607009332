<agm-map #agmMap  [latitude]="latitude" [longitude]="longitude" [zoom]="zoomLevel"
[mapTypeId]='mapType' (zoomChange)="onZoomChange($event)" (mapClick)="mapClicked($event)">
<agm-marker 
      *ngFor="let m of markers; let i = index"
      (markerClick)="clickedMarker(m.label, i)"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [markerDraggable]="m.draggable"
      (dragEnd)="markerDragEnd(m, $event)">
      
    <agm-info-window>
      <strong>{{longitude}}-{{latitude}}</strong>
    </agm-info-window>
  </agm-marker>
</agm-map>

<!-- <mat-progress-bar  *ngIf="retrievingLocation" mode="indeterminate" color="accent" ></mat-progress-bar> -->

