import { Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Package, Dimension, ContactDetails, Address, PackageDetails } from '../new-order.model';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { Countries } from 'app/main/quotes/international/international.model';
import { WeightValidator } from 'app/_validators/weight.validator';
import { CommodityProductType } from 'app/_enums/ServiceType.enum';

export class OrderAnonymousBaseComponent implements OnDestroy {
    @Input() form: FormGroup;
    @Input() group: FormGroup;
    ServiceDirection = ServiceDirection;
    CommodityProductType = CommodityProductType;

    protected _unsubscribeAll = new Subject<void>();

    constructor() {}
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    back(): void {
        const listItem = this.form.get('activeListItem') as FormControl;
        listItem.setValue(+listItem.value - 1, { emitEvent: false });
    }

    continue(): void {
        const listItem = this.form.get('activeListItem') as FormControl;
        listItem.setValue(+listItem.value + 1, { emitEvent: false });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }

        return true;
    }

    initPackageDetails(formBuilder: FormBuilder): FormGroup {
        const packageDetailsGroup = formBuilder.group(new PackageDetails());
        packageDetailsGroup.setControl('packages', formBuilder.array([this.initPackage(formBuilder)]));
        return packageDetailsGroup;
    }

    initPackage(formBuilder: FormBuilder): FormGroup {
        const packageGroup = formBuilder.group(new Package());
        packageGroup.setControl('dimension', formBuilder.group(new Dimension()));

        const dimension = packageGroup.get('dimension');
        const length = dimension.get('length');

        length.setValidators([DecimalValidator.validDecimal(0, 999)]);

        const width = dimension.get('width');

        width.setValidators([DecimalValidator.validDecimal(0, 999)]);

        const height = dimension.get('height');

        height.setValidators([DecimalValidator.validDecimal(0, 999)]);

        const shipmentValue = packageGroup.get('shipmentValue');

        shipmentValue.setValidators([DecimalValidator.validDecimal(0, 99999)]);

        packageGroup.setControl('expanded', new FormControl(true));

        return packageGroup;
    }

    initContact(formBuilder: FormBuilder): FormGroup {
        const contactGroup = formBuilder.group(new ContactDetails());
        contactGroup.setControl('address', formBuilder.group(new Address()));
        return contactGroup;
    }
}
