import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Entity } from 'app/_models/Entity.model';
import { Subject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material';
import { PhoneNumberDialogComponent } from '../orders/new-order/billing-details/phone-number-dialog/phone-number-dialog.component';
import { UserService } from 'app/_shared/user.services';
import { City } from 'app/_models/city.model';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { PaperSize, UserDetails } from './profile.model';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { userInfo } from 'os';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { result } from 'lodash';
import { LocationService } from 'app/_shared/location-service.service';
import { Country } from 'app/_models/country.model';
import { isNullOrUndefined } from 'util';
const DEFAULT_PROFILE =
    'https://media.wired.com/photos/59268c5dcfe0d93c474309a2/master/w_1300,c_limit/BSP_054.jpg';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileComponent {
    @Output() submited: EventEmitter<any> = new EventEmitter();
    @Output() canceled: EventEmitter<any> = new EventEmitter();
    group: FormGroup;
    protected _unsubscribeAll = new Subject<void>();

    // countries: Entity<number>[] = [];

    // countries: Country[];
    cities: Entity<number>[] = [];

    cityControl: FormControl;
    countryId: number;
    phoneNumberDialogRef: MatDialogRef<PhoneNumberDialogComponent>;
    needPhoneVerification = false;
    phoneNumber: string;
    paperSizeSet: string;
    checkingPhoneNumber = false;
    phoneNumberExists = false;
    billingDetailsGroup: FormGroup;
    public message: string;
    public imagePath;
    idImgURL: any;
    imgURL: any;
    imageUploadLoading = false;
    userDetail: UserDetails;
    submitted: boolean = false;

    imagedownloading = false;

    phoneCountries: Country[];
    paperSizes: PaperSize[];


    customStyle = {
        backgroundColor: "#ffffff",
        border: "1px solid #7e7e7e",
        borderRadius: "50%",
        color: "#7e7e7e",
        cursor: "pointer"
    };
    profilePhotoDisabled = false;
    profilePhoto = DEFAULT_PROFILE;
    defaultPhotoUrl = 'assets/images/avatars/profile.jpg';
    constructor(
        private _location: Location,
        private _router: Router,
        private _userService: UserService,
        private _formBuilder: FormBuilder,
        private _profileService: ProfileService,
        public _matDialog: MatDialog,
        private _authService: AuthenticationService,
        private _locationService: LocationService) { }

    ngOnInit(): void {
        this.getPaperSize();
        this.getCountries();
        this.group = this._formBuilder.group(this._profileService.userDetailOnChanged.value);
        this.cityControl = new FormControl('', Validators.required);
        if (this.group) {
            const countryId = this.group.get('countryId').value as number;
            const countryName = this.group.get('countryName').value as string;
            const cityId = this.group.get('cityId').value as number;
            const cityName = this.group.get('cityName').value as string;

            if (countryId && countryName && cityId && cityName) {
                const selectedCity = new City();
                selectedCity.cityId = cityId;
                selectedCity.countryId = countryId;
                selectedCity.name = cityName + ', ' + countryName;

                this.cityControl.setValue(selectedCity);
            }
            // this.countryChange(countryId);
        }
        this.cityControl.valueChanges.subscribe(value => {
            this.citySelected(value);
        });

        const phoneCountryIso = this.group.get('phoneCountryIso').value;

        var country = new FormControl({ iso: phoneCountryIso }, Validators.required);

        let phoneNumberValid = this.group.get('validPhoneNumber');
        if (!phoneNumberValid) {
            this.group.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.group.get('validPhoneNumber');
        }

        if (this.group.get('phoneNumber').value) {
            this.needPhoneVerification = !this.group.get('validPhoneNumber').value;
        }
        this.phoneNumber = this.group.get('phoneNumber').value;
        this.paperSizeSet = this.group.get('paperSize').value;
        this.group.get('phoneNumber').clearValidators();
        this.group.get('phoneNumber').setValidators(Validators.required);
        this.group.get('phoneNumber').setValidators(PhoneValidator.validCountryMobile(country, phoneNumberValid));

        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                this.phoneNumberExists = false;
                if (this.phoneNumber === this.group.get('phoneNumber').value) {
                    return;
                }
                this.needPhoneVerification = value;
            });

        this.loadPhotos();
    }

    async getPaperSize() {
        this.paperSizes = await this._profileService.getPaperSize();
        console.log(this.paperSizes);
    }

    countryChange(value: any) {
        // this.contactForm.get('phoneNumber').setValue('');
        console.log(this.phoneCountries);
        const phoneCountry = this.phoneCountries.find(country => country.countryId == value)
        
        const country = new FormControl({ iso: phoneCountry }, Validators.required);
        let phoneNumberValid = this.group.get('validPhoneNumber');
        phoneNumberValid.setErrors(Validators.required);
        this.group.get('phoneNumber').setErrors(Validators.required)
        if (!phoneNumberValid) {
            this.group.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.group.get('validPhoneNumber');
        }
        this.group
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                if (!value) {
                    this.group.get('phoneNumber').setErrors(Validators.required);
                }
            });
        this.group
            .get('phoneNumber').updateValueAndValidity();
    }

    paperSizeChange(value: any) {
        this.group.get('paperSize').setValue(value.value);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    cancelForm(): void {
        this._location.back();
    }

    submitForm(): void {
        if (this.group.invalid) {
            return;
        }
        this.submitted = true;
        this.saveBillingDetails()
        this.group.markAsUntouched();

        this._authService.loggedInUser.photoUrl = this.group.get('photo').value;
        localStorage.setItem('loggedInUser', JSON.stringify(this._authService.loggedInUser));
        this._authService.onUserInfoUpdated.next(this._authService.loggedInUser);
        this._location.back();
    }
    saveBillingDetails(): void {
        this._userService.addUserDetails(this.group.value as UserDetails).then(user => {
            this._authService.onUserInfoUpdated.next(user);

        });
    }

    cancel(): void {
        // this.canceled.emit();
    }

    citySelected(option: City): void {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.group.get('cityId').setValue(option.cityId);
            this.group.get('countryId').setValue(option.countryId);
            this.countryId = option.countryId;
            // this.countryChange(option.countryId);
        } else {
            this.cityControl.setErrors({ required: true });
        }
    }

    cityCleared(): void {
        this.group.get('cityId').setValue('');
        this.group.get('countryId').setValue('');
        this.cityControl.clearValidators();
        this.countryId = undefined;
    }

    openPhoneNumberDialog(): void {
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }

        this.checkingPhoneNumber = false;
        // this._userService.isPhoneNumberExist(this.group.get('phoneNumber').value).subscribe(result => {
        //     this.checkingPhoneNumber = false;
        //     this.phoneNumberExists = result;
        //     if (!result) {
        this.phoneNumberDialogRef = this._matDialog.open(PhoneNumberDialogComponent, {
            panelClass: 'phone-number-dialog',
            data: {
                phoneNumber: this.group.get('phoneNumber').value
            }
        });

        this.phoneNumberDialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.phoneNumber = this.group.get('phoneNumber').value;
                this.group.get('confirmedPhoneNumber').setValue(true);
                this.group.get('validPhoneNumber').setValue(true);
                this.needPhoneVerification = false;
            }
        });
        //     } else {
        //         this.group.get('phoneNumber').setErrors({ phoneNumberExists: true });
        //     }
        // });
    }
    uploadPhoto(files): void {
        this.message = '';
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.imgURL = null;
            return;
        }

        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = _event => {
            this.imgURL = reader.result;
        };
        this.uploadPhotoAndContinue();
    }
    uploadPhotoAndContinue(): void {
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserPhoto(this.imagePath[0]).subscribe(
            user => {
                this.imageUploadLoading = false;
            },
            error => {
                this.message = 'Error while uploading your image ID to the server.';
                this.imageUploadLoading = false;
            }
        );
    }
    uploadIDPhoto(files): void {
        this.message = '';
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.idImgURL = null;
            return;
        }
        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = _event => {
            this.idImgURL = reader.result;
        };
        this.uploadIdPhotoAndContinue();
    }
    uploadIdPhotoAndContinue(): void {
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(
            result => {
                this.imageUploadLoading = false;
            },
            error => {
                this.message = 'Error while uploading your image ID to the server.';
                this.imageUploadLoading = false;
            }
        );
    }
    loadPhotos(): void {
        this.loadPhoto();
        this.loadIdPhoto();
    }

    loadIdPhoto(): void {
        this.imagedownloading = true;
        this._authService.downloadUserIDPhoto().subscribe(
            result => {
                if (result) {
                    this.imagedownloading = false;
                    var myBlob = result as Blob;
                    if (myBlob.size == 0) {
                        return;
                    }
                    var myFile = this.blobToFile(myBlob, "my-image.png");
                    const reader = new FileReader();
                    // this.imagePath = myFile;
                    reader.readAsDataURL(myFile);
                    reader.onload = _event => {
                        this.idImgURL = reader.result;

                    };
                }
            },
            error => {
                this.message = 'Error while downloading your image from the server.';
                this.imagedownloading = false;
            }
        );
    }
    private getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.phoneCountries = success;
                const countryId = this.group.get('countryId').value as number;
                console.log(countryId);
                if(!isNullOrUndefined(countryId))
                    this.countryChange(countryId);
            });
        });
    }
    loadPhoto(): void {
        this._authService.downloadUserPhoto().subscribe(
            result => {
                if (result) {
                    this.imagedownloading = false;
                    var myBlob = result as Blob;
                    if (myBlob.size == 0) {
                        this.imgURL = this.defaultPhotoUrl;
                        return;
                    }
                    var myFile = this.blobToFile(myBlob, "my-image.png");
                    const reader = new FileReader();
                    reader.readAsDataURL(myFile);
                    reader.onload = _event => {
                        this.imgURL = reader.result;
                    };
                }
            },
            error => {
                this.message = 'Error while downloading your image from the server.';
                this.imagedownloading = false;
            }
        );
    }
    public blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }


}
