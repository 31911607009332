import { NgModule } from '@angular/core';
import { AutoCompleteCountryComponent } from './auto-complete-country.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule } from '@angular/material';

@NgModule({
    declarations: [AutoCompleteCountryComponent],
    imports: [FuseSharedModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule],
    exports: [AutoCompleteCountryComponent]
})
export class AutoCompleteCountryModule {}
