import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { Caveats } from 'app/_models/Caveats';
import { MyNetServiceType } from 'app/_enums/ServiceDirection.enum';

@Injectable({
    providedIn: 'root'
})
export class CaveatsService {
    constructor(private _httpClient: HttpClient) {}

    getAll(serviceType: MyNetServiceType): Observable<Caveats[]> {
        return this._httpClient.get<Caveats[]>(environment.apiUrl + 'v1/Caveats/' + serviceType);
    }
}
