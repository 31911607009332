
export class ConversionRates {
    id : number;
    clientId: Guid;
    effectiveDate : Date;
    sourceCurrency: string;
    targetCurrency: string;
    targetValue : number;
    createDate : Date;

    constructor(conversionRates?) {
        conversionRates = conversionRates || {};
        this.id = conversionRates.id || 0;
        this.clientId = conversionRates.clientId || '';
        this.effectiveDate = conversionRates.effectiveDate || new Date();
        this.sourceCurrency = conversionRates.sourceCurrency || '';
        this.targetCurrency = conversionRates.targetCurrency || '';
        this.targetValue = conversionRates.targetValue || 0;
        this.createDate = conversionRates.createDate || new Date();

    }
}

export class MarketRateStatus {
    clientId: Guid;
    EnableMarketRate: boolean;

    constructor(marketRateStatus?) {
        marketRateStatus = marketRateStatus || {};
        this.clientId = marketRateStatus.clientId || '';
        this.EnableMarketRate = marketRateStatus.EnableMarketRate;
    }
}
