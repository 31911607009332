<div class="pt-64 p-24 quote-start">
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">

        <div class="big-circle app-logo" fxLayout="column" fxLayoutAlign="center center"
            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <mat-icon class="s-64 s-md-128">{{ icon }}</mat-icon>
        </div>

        <span class="app-title mt-48 mb-8" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            {{ title }}
        </span>

        <span class="app-message" [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
            Fill out the form in order to generate the quote for you
        </span>
    </div>
</div>