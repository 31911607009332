import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserService, ChangePhoneNumberStatus } from 'app/_shared/user.services';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { OrdersOrderListService } from '../order-list.service';
import { Order } from '../../new-order/order.model';

@Component({
    selector: 'bulk-order-result',
    templateUrl: './bulk-order-result.component.html',
    styleUrls: ['./bulk-order-result.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BulkOrderResultComponent implements OnInit, OnDestroy, AfterViewInit {

    protected _unsubscribeAll = new Subject<void>();
    
    // Workaround for angular component issue #13870
    disableAnimation = true;
  


    form: FormGroup;
    ordersImported: [];
    orders;

    constructor(
        public matDialogRef: MatDialogRef<BulkOrderResultComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _orderListService: OrdersOrderListService
    ) {
        this.ordersImported = _data.ordersImported;
    }
    ngOnInit(): void {
        this.ordersImported

        // if (this.ordersImported.length > 0)
        this._orderListService.getOrdersForLabel(JSON.stringify(this.ordersImported)).then((result: any) => {
            this.orders = result.body as Order[];
        });
    }

    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
