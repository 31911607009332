<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="m-0 mr-16 secondary-text">account_circle</mat-icon>
        <div class="h2 secondary-text">{{ contactType }}</div>
    </div>

    <div class="customer mb-32">
        <table class="simple">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <span class="name text-truncate">
                                {{contact.fullName}}
                            </span>
                        </div>
                    </td>
                    <td>
                        <span class="email text-truncate">
                            {{contact.email}}
                        </span>
                    </td>
                    <td>
                        <span class="phone text-truncate">
                            {{contact.phoneNumber}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <distribution-contact-address-view [contactType]="contactType" [address]="contact.netPoint || contact.address">
    </distribution-contact-address-view> -->

</div>