import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { FormBuilder, Validators, FormControl, FormGroup, FormArray } from '@angular/forms';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Address } from '../new-order.model';
import { City } from 'app/_models/city.model';
import { Country } from 'app/_models/country.model';
import { WeightValidator } from 'app/_validators/weight.validator';
import { Countries } from 'app/main/quotes/international/international.model';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { CommodityService } from 'app/_shared/commodity.service';

@Component({
    selector: 'orders-anonymous-contact',
    templateUrl: './anonymous-contact.component.html',
    styleUrls: ['./anonymous-contact.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousContactComponent extends OrderAnonymousBaseComponent implements OnInit {
    @Input() allowedCountries: number[];
    @Input() allowedCountriesText: string[];
    @Input() direction: string;

    submited = false;
    mobileNumberValid: FormControl;
    cityControl = new FormControl('', Validators.required);
    countryControl = new FormControl('', Validators.required);

    countryClear = false;

    constructor(private _formBuilder: FormBuilder, private _commodityService: CommodityService) {
        super();
    }

    ngOnInit(): void {
        const country = new FormControl({ iso: 'LB' }, Validators.required);

        this.mobileNumberValid = this._formBuilder.control(false);

        this.group
            .get('mobileNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, this.mobileNumberValid)]);

        this.mobileNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                if (!value) {
                    this.group.get('mobileNumber').setErrors(Validators.required);
                    this.group.get('mobileNumber').setErrors({ validCountryPhone: true });
                }
            });

        this.group.setControl('address', this._formBuilder.group(new Address()));

        const addressGroup = this.group.get('address') as FormGroup;
        addressGroup.get('countryId').setValidators(Validators.required);
        addressGroup.get('cityId').setValidators(Validators.required);

        addressGroup.setControl('cityControl', this.cityControl);
        addressGroup.setControl('countryControl', this.countryControl);

        this.cityControl.valueChanges.subscribe(value => {
            this.citySelected(value);
        });

        this.countryControl.valueChanges.subscribe(value => {
            this.countrySelected(value);
            this.updateOrderDirection();
        });
    }

    citySelected(option: City): void {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0) {
            const addressGroup = this.group.get('address');
            addressGroup.get('cityId').setValue(option.cityId);
        } else {
            this.cityControl.setErrors({ required: true });
        }
    }

    cityCleared(): void {
        const addressGroup = this.group.get('address');
        addressGroup.get('cityId').setValue('');
        this.cityControl.clearValidators();
    }

    countrySelected(option: Country): void {
        this.countryCleared();
        if (option && option.countryId && option.countryId > 0) {
            const addressGroup = this.group.get('address');
            addressGroup.get('countryId').setValue(option.countryId);
        } else {
            this.countryControl.setErrors({ required: true });
        }
    }

    countryCleared(): void {
        const addressGroup = this.group.get('address');
        addressGroup.get('countryId').setValue('');
        addressGroup.get('cityId').setValue('');
        this.countryControl.clearValidators();
        this.cityControl.clearValidators();
        this.cityControl.setValue('', { onlySelf: true });
    }

    validateAndContinue(): void {
        if (this.group.valid) {
            this.continue();
        } else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    }

    updateOrderDirection(): void {
        const fromCountryId = this.form.get('shipper.address.countryId').value;
        const toCountryId = this.form.get('receiver.address.countryId').value;

        if (fromCountryId === Countries.Lebanon && toCountryId === Countries.Lebanon) {
            // domestic
            this.form.setControl('maxWeight', new FormControl(WeightValidator.MaxDomesticWeight));
            this.form.get('currentDirection').setValue(ServiceDirection.Domestic);
            this.updateCommodities();
            this.updatePackagesValidation();
        } else if (fromCountryId === Countries.Lebanon) {
            // Export
            this.form.setControl('maxWeight', new FormControl(WeightValidator.MaxExportWeight));
            this.form.get('currentDirection').setValue(ServiceDirection.Export);
            this.updateCommodities();
        } else if (toCountryId === Countries.Lebanon) {
            // Import
            this.form.setControl('maxWeight', new FormControl(undefined));
            this.form.get('currentDirection').setValue(ServiceDirection.Import);
            this.updateCommodities();
        } else {
            // Not Supported
            this.form.setControl('maxWeight', new FormControl(undefined));
            this.form.get('currentDirection').setValue(undefined);
        }
    }

    updatePackagesValidation(): void {
        const packagesArray = this.form.get('packages') as FormArray;
        if (packagesArray) {
            for (const packageItem of packagesArray.controls) {
                const packageControl = packageItem as FormControl;
                const weight = packageControl.get('weight');

                const currentDirection = this.form.get('currentDirection').value as ServiceDirection;

                weight.setValidators([WeightValidator.validWeight(currentDirection), Validators.required]);
                weight.updateValueAndValidity();
            }
        }
    }

    updateCommodities(): void {
        const serviceType = this.form.get('currentDirection').value as ServiceDirection;
        let mynetServiceType: MyNetServiceType;
        switch (serviceType) {
            case ServiceDirection.Export:
                mynetServiceType = MyNetServiceType.Export;
                break;
            case ServiceDirection.Import:
                mynetServiceType = MyNetServiceType.Import;
                break;
            case ServiceDirection.Domestic:
                mynetServiceType = MyNetServiceType.Domestic;
                break;
            default:
                break;
        }

        this._commodityService.getCommodities(mynetServiceType);
    }
}
