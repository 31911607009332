import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { NewOrder, Package, ContactDetails, PickupDetails, ShipmentDetails, Dimension } from './new-order.model';
import { Countries } from 'app/main/quotes/international/international.model';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { OrderAnonymousBaseComponent } from './anonymous-base/anonymous-base.component';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { QuoteService } from 'app/main/quotes/quote.service';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';

@Component({
    selector: 'orders-anonymous-new-order',
    templateUrl: './anonymous-new-order.component.html',
    styleUrls: ['./anonymous-new-order.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AnonymousNewOrderComponent extends OrderAnonymousBaseComponent implements OnInit {
    orderForm: FormGroup;
    lebanon = Countries.Lebanon;
    totalPrice: number;
    loadingPrice = false;
    constructor(
        private _authenticationService: AuthenticationService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _formBuilder: FormBuilder,
        private _quoteService: QuoteService
    ) {
        super();
        this.updateFuseTemplate();

        const t = new Promise(resolve => {
            this.loadScript();
            resolve(true);
        });
    }

    loadScript(): void {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');

            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    private updateFuseTemplate(): void {
        if (!this._authenticationService.isLoggedIn()) {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }
    }

    private initForm(): void {
        this.orderForm = this._formBuilder.group({
            shipper: this.initContact(this._formBuilder),
            receiver: this.initContact(this._formBuilder),
            pickup: this._formBuilder.group(new PickupDetails()),
            shipmentDetails: this._formBuilder.group(new ShipmentDetails()),
            packageDetails: this.initPackageDetails(this._formBuilder),
            activeListItem: this._formBuilder.control(0),
            currentDirection: this._formBuilder.control(ServiceDirection.Domestic),
            totalPrice: this._formBuilder.control(0),
            orderId: this._formBuilder.control(undefined),
            couponCode: this._formBuilder.control(undefined),
            originPrice: this._formBuilder.control(undefined),
            disableGenerate: this._formBuilder.control(false)
        });
    }

    toggleSidebar(name: string): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    ngOnInit(): void {
        this.initForm();
        this.subscribeFormChanges();
    }

    subscribeFormChanges(): void {
        this.orderForm.valueChanges.pipe(debounceTime(1000)).subscribe((data: NewOrder) => {
            if (data.disableGenerate) {
                const disableGenerate = this.orderForm.get('disableGenerate') as FormControl;
                disableGenerate.setValue(false, { onlySelf: true, emitEvent: false });
                return;
            }
            this.loadingPrice = true;
            this._quoteService.generateAnonymousQuote(data).subscribe(price => {
                this.totalPrice = price;
                this.loadingPrice = false;
                const totalPrice = this.orderForm.get('totalPrice') as FormControl;
                const originPrice = this.orderForm.get('originPrice') as FormControl;
                totalPrice.setValue(price, { onlySelf: true, emitEvent: false });
                originPrice.setValue(price, { onlySelf: true, emitEvent: false });
            });
        });
    }
}
