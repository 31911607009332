import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderViewService } from './order-view.service';
import { OrderView } from './order-view.model';
import { OrdersOrderListService } from '../list/order-list.service';
import { OrderDetailsViewComponent } from './details-view/details-view.component';
import { OrderStatusViewComponent } from './status-view/status-view.component';
import { OrderEvent } from '../list/order-events-dialog/order-event.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { QuoteService } from 'app/main/quotes/quote.service';
import { CashOnDelivery } from '../new-order/order.model';

@Component({
    selector: 'order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderViewComponent implements OnInit, AfterViewChecked {
    order: OrderView;
    orderCods: CashOnDelivery[] = [];
    @ViewChild('orderDetailsView', { static: false }) orderDetailsView: OrderDetailsViewComponent;
    @ViewChild('orderStatusView', { static: false }) orderStatusView: OrderStatusViewComponent;
    lastEvent: OrderEvent;
    trustedInvoiceUrl: SafeUrl;
    constructor(
        private _orderViewService: OrderViewService,
        private _quoteService: QuoteService,
        private _cdRef: ChangeDetectorRef,
        private _sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this._orderViewService.onOrderSelected.subscribe(order => {
            this.order = order;
            this.trustedInvoiceUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.order.invoiceFilePath);
        });
        // this._quoteService.getCodAmounts(this.order.orderId).then(cods => {
        //     this.orderCods = cods;
        // });
        // this._quoteService.onCodChanged.subscribe(cods => {
        //     this.orderCods = cods;
        // });
    }

    lastEventTriggered(event: OrderEvent): void {
        this.lastEvent = event;
    }

    ngAfterViewChecked(): void {
        this._cdRef.detectChanges();
    }

    printInvoice(): void {

    }
}
