import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { DistributionInfo } from '../distribution-view.model';
import { OrderEvent } from 'app/main/orders/list/order-events-dialog/order-event.model';
import { DistributionService } from '../../distribution.service';
import { Distribution, DistributionView } from '../../new-distribution/distribution.model';
import { DistributionListService } from '../../list/distribution-list.service';
import { DistributionEvent } from '../../list/Distribution-events-dialog/distribution-event.model';
import { Routes } from 'app/Routes';

@Component({
    selector: 'distribtuion-details-view',
    templateUrl: './details-view.component.html',
    styleUrls: ['./details-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DistribtuionDetailsViewComponent implements OnInit {
    @Input() distributionId: number;
    @Input() distribution: DistributionView;
    distributionEvents: DistributionEvent[];


    // events: OrderEvent[];
    // @Output() lastEvent = new EventEmitter<OrderEvent>();

    constructor(private _distributionService: DistributionListService) { }

    ngOnInit(): void {
    }
    navigateToTracking(label: string): void {
        window.location.href = [Routes.trackingURL + label].toString();
    }
}
