import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { Address } from 'app/main/contacts/contact-form/contact.model';

@Injectable()
export class NetPointService {
    netpoints: Address[];
    onNetPointsRetreived: Subject<Address[]>;

    constructor(private _httpClient: HttpClient) {
        this.onNetPointsRetreived = new Subject();
    }

    getNetPoints(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.netpoints || this.netpoints.length === 0) {
                this._httpClient
                    .get<Address[]>(environment.apiUrl + 'v1/NetPoints')
                    .subscribe((response: Address[]) => {
                        this.netpoints = response;
                        this.onNetPointsRetreived.next(this.netpoints);
                        resolve();
                    }, reject);
            } else {
                resolve();
            }
        });
    }
}
