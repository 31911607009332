import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Entity } from 'app/_models/Entity.model';
import { Subject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { DatePipe, formatDate, Location } from '@angular/common';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { SettingsService } from './settings.service';
import { ConversionRates, MarketRateStatus } from './settings.model';
import { CurrencyValidator } from 'app/_validators/currency.validator';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SettingsComponent {
    @Output() submited: EventEmitter<any> = new EventEmitter();
    @Output() canceled: EventEmitter<any> = new EventEmitter();
    dataSource: any | null;
    displayedColumns = ['sourceCurrency', 'targetCurrency', 'targetValue', 'effectiveDate'];
    ratesData: any;
    // displayedColumns = ['sourceCurrencyId','targetValue'];

    minDate = new Date(new Date().setDate(new Date().getDate()));

    loading: boolean = false;
    group: FormGroup;
    currencyTypes: any = {};
    state: string = 'Enabled';


    rateDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    toggleDialogInfoRef: MatDialogRef<FuseConfirmDialogComponent>;

    protected _unsubscribeAll = new Subject<void>();
    constructor(public datepipe: DatePipe,
        private _location: Location,
        public _matDialog: MatDialog,
        private _authService: AuthenticationService, private _formBuilder: FormBuilder, public _settingsService: SettingsService,) { }

    ngOnInit(): void {
        this.loading = true;
        this._settingsService.getConversionRates().then((response: any) => {
            this.dataSource = response;
            this.ratesData = response;
            this.loading = false;
        });
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        this.clearForm();
        this._settingsService.getMarketRateSettings();
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key, disabled: false }));
    }
    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    cancelForm(): void {
        this._location.back();
    }
    clearForm(): void {
        this.group = this._formBuilder.group({
            effectiveDate: [new Date()],
            sourceCurrency: [""],
            targetCurrency: [""],
            targetValue: [0],
            createDate: [new Date()],
        });
        this.group.get('sourceCurrency').valueChanges.subscribe(value => {
            this.group.get('targetCurrency').setValidators(CurrencyValidator.validCurrency(value))
            // this.group.get('sourceCurrency').clearValidators();
        });
        this.group.get('targetCurrency').valueChanges.subscribe(value => {
            this.group.get('sourceCurrency').setValidators(CurrencyValidator.validCurrency(value))
            // this.group.get('targetCurrency').clearValidators();

        });
        this.group.get('targetValue').setValidators(Validators.min(0.0000001));

    }
    toggleRates(event) {
        const marketRateStatus = new MarketRateStatus({clientId: localStorage.getItem('subsidiaryId'), EnableMarketRate: event.checked});
        if (event.checked) {
            this.toggleDialogInfoRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });
            this.toggleDialogInfoRef.componentInstance.header = 'Notice!';
            this.toggleDialogInfoRef.componentInstance.confirm = 'Ok';
            this.toggleDialogInfoRef.componentInstance.cancel = 'Cancel';
            this.toggleDialogInfoRef.componentInstance.confirmMessage = 'Enabling Conversion Rates will affect all orders and not specific orders. Orders that are out for delivery will not be affected.';
            this.toggleDialogInfoRef.afterClosed().subscribe(dialogResult => {
                if (dialogResult) {
                    this._settingsService.saveMarketRateSettings(marketRateStatus);
                }
                else {
                    event.checked = !event.checked;
                    this._settingsService.marketRateStatusToggle = event.checked;
                }
            });
        }
        else {
            this.toggleDialogInfoRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });
            this.toggleDialogInfoRef.componentInstance.header = 'Notice!';
            this.toggleDialogInfoRef.componentInstance.confirm = 'Ok';
            this.toggleDialogInfoRef.componentInstance.cancel = 'Cancel';
            this.toggleDialogInfoRef.componentInstance.confirmMessage = 'Disabling Conversion Rates will affect all orders and not specific orders. Orders that are out for delivery will not be affected.';
            this.toggleDialogInfoRef.afterClosed().subscribe(dialogResult => {
                if (dialogResult) {
                    this._settingsService.saveMarketRateSettings(marketRateStatus);
                }
                else {
                    event.checked = !event.checked;
                    this._settingsService.marketRateStatusToggle = event.checked;
                }
            });
        }
    }
    submitForm(): void {
        if (this.group.invalid) {
            return;
        }
        this.validateNewRate();
        // this.loading = true;

        // var newRate = this.group;

        // var effDate = newRate.get('effectiveDate').value as Date
        // console.log(effDate);
        // console.log(effDate.toUTCString());
        // console.log(effDate.toLocaleString());
        // console.log(effDate.toLocaleDateString());
        // effDate = new Date(effDate.toLocaleDateString());


        // effDate = new Date(effDate.toUTCString())
        // console.log(effDate);



    }

    validateNewRate(): void {
        const newRate = this.group.value as ConversionRates;
        const rates = this.ratesData as ConversionRates[];
        // console.log(newRate);
        // console.log(rates);

        // var olddate = new Date(rates[0].effectiveDate).setHours(0,0,0,0);
        // var newdate = new Date(newRate.effectiveDate).setHours(0,0,0,0);
        // console.log(olddate);
        // console.log(newdate);

        // newRate.effectiveDate.setHours(0, 0, 0, 0);
        // console.log(new Date(rates[0].effectiveDate).toUTCString());
        // console.log(new Date(rates[0].effectiveDate).toLocaleString());
        // console.log(new Date(newRate.effectiveDate).toUTCString());
        // console.log(new Date(newRate.effectiveDate).toLocaleString());

        if (rates.find(x => x.sourceCurrency == newRate.sourceCurrency && x.targetCurrency == newRate.targetCurrency && new Date(x.effectiveDate).setHours(0, 0, 0, 0) == new Date(newRate.effectiveDate).setHours(0, 0, 0, 0)) != undefined)
            this.openRateDialog(newRate);
        else {
            this._settingsService.saveConversionRates(this.group.value as ConversionRates).then((response: any) => {
                this.dataSource = response;
                this.ratesData = response;
                this.clearForm();
            });
        }


        // console.log(rates.find(x =>  new Date(x.effectiveDate).setHours(0,0,0,0) == new Date(newRate.effectiveDate).setHours(0,0,0,0)));
        // console.log(rates.find(x => x.sourceCurrency == newRate.sourceCurrency && x.targetCurrency == newRate.targetCurrency));
        // console.log(rates.find(x => x.sourceCurrency == newRate.sourceCurrency && x.targetCurrency == newRate.targetCurrency && x.effectiveDate == newRate.effectiveDate));


    }

    private openRateDialog(newRate): void {
        this.rateDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.rateDialogRef.componentInstance.header = 'Attention!';
        this.rateDialogRef.componentInstance.confirm = 'Yes';
        this.rateDialogRef.componentInstance.cancel = 'Cancel';

        var newdate = new Date(new Date(newRate.effectiveDate).setHours(0, 0, 0, 0)).toString();
        this.rateDialogRef.componentInstance.confirmMessage =
            'Duplicate rate already found on ' + this.datepipe.transform(newdate, 'yyyy-MM-dd') + ' , are you sure you want to proceed?';
        this.rateDialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                this._settingsService.saveConversionRates(this.group.value as ConversionRates).then((response: any) => {
                    this.dataSource = response;
                    this.ratesData = response;
                    this.clearForm();
                });
            }
            this.loading = false;
        });
    }

}
