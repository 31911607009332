export class HeardAboutUs {
    lookupId: number;
    details: string;

    constructor(data?) {
        data = data || {};
        this.lookupId = data.lookupId;
        this.details = data.details;
    }
}
