import { Pipe, PipeTransform } from '@angular/core';
import { OrderService } from '../orders/order.service';
import { MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { CommodityService } from 'app/_shared/commodity.service';

@Pipe({
    name: 'commodity'
})
export class CommodityPipe implements PipeTransform {
    constructor(private _commodityService: CommodityService) {}
    transform(commodityId: number, serviceTypeId: MyNetServiceType, args?: any): any {
        if (commodityId && serviceTypeId) {
            return new Promise(resolve => {
                if (this._commodityService.commodities && this._commodityService.commodities.length > 0) {
                    const sameServiceType =
                        this._commodityService.commodities.filter(x => x.serviceTypeId === serviceTypeId).length > 0;
                    if (sameServiceType) {
                        return resolve(this.getCommodityFromService(commodityId));
                    }
                }

                this._commodityService.getCommodities(serviceTypeId).then(_ => {
                    return resolve(this.getCommodityFromService(commodityId));
                });
            });
        }
    }

    getCommodityFromService(commodityId: number): string {
        return this._commodityService.commodities.find(t => t.commodityId === commodityId).title;
    }
}
