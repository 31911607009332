import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'quote-loader',
    templateUrl: './quote-loader.component.html',
    styleUrls: ['./quote-loader.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class QuoteLoaderComponent implements OnInit {
    @Input() loader = true;
    @Input() message = '';
    
    constructor() {}

    ngOnInit(): void {}
}
