import { AbstractControl, FormArray, ValidatorFn, Validators } from '@angular/forms';

export class SkuValidator {
    static inValidSku = (status: string): ValidatorFn => {
        return (skuControl: FormArray): { [key: string]: boolean } => {
            var skus = skuControl as FormArray;


            if (skus == null)
                return { inValidSku: false };
            else {
                const skusArray = skus.value;
                var a = getCol(skusArray, "sku");
                let resultToReturn = false;
                resultToReturn = a.some((element, index) => {
                    return a.indexOf(element) !== index
                });
                if (resultToReturn)
                    return { inValidSku: true };
            }


        }
        function getCol(matrix, col) {
            var column = [];
            for (var i = 0; i < matrix.length; i++) {
                column.push(matrix[i][col]);
            }
            return column; // return column data..
        }
    }
};
