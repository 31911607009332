import { AuthGuard } from 'app/_guards/auth.guard';
import { OrdersOrderListComponent } from './list/order-list.component';
import { OrdersNewOrderComponent } from './new-order/new-order.component';
import { OrderService } from './order.service';
import { OrdersOrderListService } from './list/order-list.service';
import { PaymentDoneService } from './payment-done/payment-done.service';
import { OrdersPaymentDoneComponent } from './payment-done/payment-done.component';
import { OrderViewComponent } from './view/order-view.component';
import { OrderViewService } from './view/order-view.service';
import { OrderInvoiceComponent } from './invoice/invoice.component';
import { AnonymousNewOrderComponent } from './anonymous-new-order/anonymous-new-order.component';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';
import { InvoicePrintComponent } from './invoice-print/invoice-print.component';
import { OrderLabelComponent } from './order-label/order-label.component';
const ɵ0 = OrdersOrderListService, ɵ1 = OrderService, ɵ2 = PaymentDoneService, ɵ3 = OrderViewService, ɵ4 = OrderViewService, ɵ5 = OrderViewService, ɵ6 = OrderViewService;
// import {NgxPrinterModule, NgxPrinterService} from 'ngx-printer';
const routes = [
    {
        path: 'orders',
        canActivate: [AuthGuard],
        component: OrdersOrderListComponent,
        resolve: {
            data: ɵ0
        }
    },
    {
        path: 'orders/:id',
        canActivate: [AuthGuard],
        component: OrdersNewOrderComponent,
        resolve: {
            data: ɵ1
        }
    },
    {
        path: 'guest/orders/new',
        canActivate: [AnonymousGuard],
        component: AnonymousNewOrderComponent
    },
    {
        path: 'orders/payments/redirect',
        // canActivate: [AuthGuard],
        component: OrdersPaymentDoneComponent,
        resolve: {
            data: ɵ2
        }
    },
    {
        path: 'orders/view/:id',
        canActivate: [AuthGuard],
        component: OrderViewComponent,
        resolve: {
            data: ɵ3
        }
    },
    {
        path: 'orders/print/:id',
        canActivate: [AuthGuard],
        component: OrderInvoiceComponent,
        resolve: {
            data: ɵ4
        }
    },
    {
        path: 'orders/printall/:id',
        canActivate: [AuthGuard],
        component: InvoicePrintComponent,
        resolve: {
            data: ɵ5
        }
    },
    {
        path: 'orders/label/:id',
        canActivate: [AuthGuard],
        component: OrderLabelComponent,
        resolve: {
            data: ɵ6
        }
    }
    // ,
    // {
    //     path: 'order/cash',
    //     canActivate: [AuthGuard],
    //     component: OrdersNewOrderComponent,
    //     resolve: {
    //         data: OrderService
    //     }
    // }
];
const ɵ7 = { duration: 3000 };
export class OrdersModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
