<div class="page-layout simple fullwidth">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->
    <!-- CENTER -->

    <!-- <div class="center"> -->
    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center">

        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                person
            </mat-icon>
            <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                My Info
            </span>
        </div>
    </div>

    <!-- CONTENT CARD -->
    <div class="content-card">
        <div class="content">
            <div class="w-100-p p-24">
                <div>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="true" (opened)="false" hideToggle class="mb-16 no-border">
                            <mat-expansion-panel-header>
                                <mat-panel-title>

                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <form [formGroup]="group">
                                <div fxLayout="column" fxLayoutAlign="center center">
                                    <div fxFlex>
                                        <!-- <div class="mb-12">
                                            <strong>You are required to upload your ID</strong>
                                        </div> -->

                                        <!-- <div class="mb-12">
                                            <span style="color:red;" *ngIf="message">{{message}}</span>
                                        </div> -->
                                        <div *ngIf="imgURL" #photo class="mb-12">
                                            <ngx-avatar [style]="customStyle" [src]="imgURL" [round]="true" size="200"
                                                (clickOnAvatar)="file.click()"></ngx-avatar>
                                        </div>
                                        <!-- <div #photo class="mb-12" *ngIf="!imgURL" >
                                            <ngx-avatar [style]="customStyle" [src]="defaultPhotoUrl" [round]="true" size="250"
                                                (clickOnAvatar)="file.click()"></ngx-avatar>
                                        </div> -->
                                        <!-- <div *ngIf="!imgURL" class="mb-12">
                                            <button mat-raised-button (click)="file.click()">Upload</button>
                                        </div>
                                        <div *ngIf="!idImgURL" class="mb-12">
                                            <img [src]="defaultPhotoUrl" width="250" height="250">
                                        </div> -->
                                        <!-- <img src='https://localhost:5001/wwwroot/images/userID/9022197351b9489ebd5feff84baa815c.jpg'/> -->
                                        <!-- <img src='/assets/images/users/8b9f8cfeea0d4b4097c691433197a795.jpg'/> -->

                                        <div class="container">
                                            <input #file hidden type="file" accept="image/x-png,image/gif,image/jpeg"
                                                (change)="uploadPhoto(file.files)" />
                                        </div>

                                    </div>
                                </div>
                                <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-xs="row">
                                    <mat-form-field class="pr-4 no-padding-xs" fxFlex>
                                        <mat-label>First Name</mat-label>
                                        <input matInput required name="mynet-myinfo-firstName"
                                            formControlName="firstName">
                                    </mat-form-field>

                                    <mat-form-field class="pl-4 no-padding-xs" fxFlex>
                                        <mat-label>Last Name</mat-label>
                                        <input matInput required name="mynet-myinfo-lastName"
                                            formControlName="lastName">
                                    </mat-form-field>
                                </div>
                                
                                <mat-form-field class="w-5-p mb-6">
                                    <mat-select name="contact-PaperSize"
                                        placeholder="Paper Size" (selectionChange)="paperSizeChange($event)" [(value)]="paperSizeSet" required>
                                        <mat-option *ngFor="let ps of paperSizes" [value]="ps.paperSize">{{ps.paperSize}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-100-p mb-6">
                                    <mat-label>Company Name</mat-label>
                                    <input matInput name="mynet-myinfo-companyName" formControlName="companyName">
                                </mat-form-field>
                                <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                                    <mat-form-field fxFlex style="margin-top:-1px;">
                                        <mat-select name="contact-PhoneCountryCode" formControlName="phoneCountryCode"
                                            placeholder="Code" (selectionChange)="countryChange($event)" required>
                                            <mat-option *ngFor="let country of phoneCountries"
                                                [value]="country.countryPhoneCode"
                                                >
                                                {{ country.displayNameWithCode }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="w-100-p mb-6">
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput name="mynet-myinfo-phoneNumber" formControlName="phoneNumber"
                                            required type="tel">
                                        <mat-error *ngIf="group.get('phoneNumber').hasError('required')">
                                            Phone Number is required
                                        </mat-error>

                                        <mat-error *ngIf="group.get('phoneNumber').hasError('validCountryPhone')">
                                            Phone Number is not valid
                                        </mat-error>

                                        <mat-error *ngIf="group.get('phoneNumber').hasError('phoneNumberExists')">
                                            Phone Number already register for another user
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-progress-bar *ngIf="checkingPhoneNumber" mode="indeterminate">
                                    </mat-progress-bar>
                                </div>
                                <ng-container
                                    *ngIf="!checkingPhoneNumber && group.get('phoneNumber').value && this.group.get('validPhoneNumber').value">
                                    <div class="mb-12 w-100-p text-left"
                                        *ngIf="!group.get('confirmedPhoneNumber').value && needPhoneVerification">
                                        <button mat-raised-button color="primary" aria-label="validate" type="button"
                                            (click)="openPhoneNumberDialog()">
                                            Validate
                                        </button>
                                    </div>
                                </ng-container>
                                <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-xs="row">

                                    <auto-complete-location fxFlex class="pr-4 no-padding-sm" [label]="'Location'"
                                        [required]="true" [control]="cityControl">
                                    </auto-complete-location>

                                    <mat-form-field class="pl-4 no-padding-sm" fxFlex>
                                        <mat-label>Street Address</mat-label>
                                        <input matInput name="mynet-myinfo-street" formControlName="street" required>
                                    </mat-form-field>

                                </div>

                                <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-sm="row">
                                    <mat-form-field class="pr-4no-padding-sm" fxFlex>
                                        <mat-label>Building</mat-label>
                                        <input matInput name="mynet-myinfo-building" formControlName="building">
                                    </mat-form-field>

                                    <mat-form-field class="pr-4 pl-4 no-padding-sm" fxFlex>
                                        <mat-label>Floor</mat-label>
                                        <input matInput name="mynet-myinfo-floor" formControlName="floor">
                                    </mat-form-field>

                                    <mat-form-field class="pl-4 no-padding-sm" fxFlex>
                                        <mat-label>Zip Code</mat-label>
                                        <input matInput name="mynet-myinfo-zipCode" formControlName="zipCode">
                                    </mat-form-field>
                                </div>

                                <mat-form-field class="w-100-p mb-6">
                                    <mat-label>Landmark</mat-label>
                                    <textarea matInput name="mynet-myinfo-landmark" formControlName="landmark" matInput
                                        rows="4" matTextareaAutosize matAutosizeMinRows=4
                                        matAutosizeMaxRows=6></textarea>
                                </mat-form-field>
                                <div fxLayout="column" fxLayout.gt-xs="row">
                                    <div fxFlex>
                                        <!-- <div class="mb-12" *ngIf="!idImgURL">
                                            <strong style="color: red;">You are required to upload your ID</strong>
                                        </div> -->
                                        <!-- <div class="mb-12">    
                                            <span style="color:red;" *ngIf="message">{{message}}</span>
                                        </div> -->
                                        <div *ngIf="idImgURL" class="mb-12">
                                            <img [src]="idImgURL" width="250" height="250">
                                        </div>





                                        <div class="container">
                                            <input #Idfile hidden type="file" accept="image/x-png,image/gif,image/jpeg"
                                                (change)="uploadIDPhoto(Idfile.files)" />
                                        </div>
                                        <div *ngIf="idImgURL" class="mb-12">
                                            <button mat-raised-button (click)="Idfile.click()">Edit</button>
                                        </div>
                                        <div *ngIf="!idImgURL" class="mb-12">
                                            <button mat-raised-button (click)="Idfile.click()">Upload ID</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <mat-action-row>
                                <button type="button" mat-button color="primary" (click)="cancelForm()">
                                    Cancel</button>
                                <button type="button"
                                    [disabled]="(!group.get('confirmedPhoneNumber').value  || needPhoneVerification) && group.invalid "
                                    mat-button color="primary" (click)="submitForm()">
                                    Save</button>
                                <mat-progress-bar *ngIf="imageUploadLoading || imagedownloading" mode="indeterminate">
                                </mat-progress-bar>
                            </mat-action-row>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </div>
        </div>
    </div>
    <!-- / CONTENT CARD -->

    <!-- </div> -->
    <!-- / CENTER -->

</div>