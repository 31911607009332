<!-- <mat-radio-group aria-label="Select an option" (change)="radioChange($event)">
    <mat-radio-button value="1" [checked]="true">Fill out Address</mat-radio-button>
    <mat-radio-button value="2">Pin Location</mat-radio-button>
</mat-radio-group> -->
<!-- <div *ngIf="locationEnabled" style="flex: 1 50%"> -->


<!-- <div style="flex: 1 50%">
    <h3 class="h3">
        <strong>Address #{{ index }}</strong>
        <a *ngIf="contactForm.get('addresses').length > 1" class="remove-link" (click)="removeAddress()">Remove</a>
    </h3>
    <google-location [latitude]="latitude" [longitude]="longitude" (location)="locationAdded($event)"></google-location>
</div> -->
<div [formGroup]="addressForm">
    <div fxFlex fxLayout="column">
        <h3 class="h3" *ngIf="!locationEnabled">
            <!-- <h3 class="h3"> -->
            <strong>Address #{{ index }}</strong>
            <a *ngIf="contactForm.get('addresses').value.length !== 1" class="remove-link"
                (click)="removeAddress()">Remove</a>
        </h3>
        <div fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field fxFlex class="pl-4 no-padding-sm">
                <mat-label>Longitude</mat-label>
                <input matInput formControlName="longitude" name="contact-latitude" readonly />
            </mat-form-field>
            <mat-form-field fxFlex class="pl-4 no-padding-sm" readonly>
                <mat-label>Latitude</mat-label>
                <input matInput formControlName="latitude" name="contact-latitude" readonly />
            </mat-form-field>
            <!-- <button  mat-button class="mr-8" color="accent"
                (click)="clearPin()" aria-label="Cancel" matTooltip="Clear Location Pin">
                Clear Pin
            </button> -->
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row">
            <auto-complete-location class="pr-4 no-padding-sm" [locationValues]="location" fxFlex [label]="'Location'"
                [required]="true" [control]="cityControl">
            </auto-complete-location>
            <mat-form-field fxFlex class="pl-4 no-padding-sm">
                <mat-label>Street Address</mat-label>
                <input matInput #iLongitude formControlName="street" name="contact-street" required />
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-md="row">
            <mat-form-field class="pr-4 no-padding-sm" fxFlex>
                <mat-label>Building</mat-label>
                <input matInput formControlName="building" name="contact-building" />
            </mat-form-field>

            <mat-form-field fxFlex class="pr-4 pl-4 no-padding-sm">
                <mat-label>Floor</mat-label>
                <input matInput formControlName="floor" name="contact-floor" />
            </mat-form-field>

            <mat-form-field fxFlex class="pl-4 no-padding-sm">
                <mat-label>Postal Code (ZIP)</mat-label>
                <input matInput formControlName="zipCode" name="contact-zipCode" />
            </mat-form-field>

        </div>
        <mat-form-field fxFlex>
            <mat-label>Landmark</mat-label>
            <textarea matInput formControlName="landmark" name="contact-landmark" matInput rows="3" matTextareaAutosize
                matAutosizeMinRows=3 matAutosizeMaxRows=4></textarea>
        </mat-form-field>
        <div style="flex: 1 50%">
            <!-- <h3 class="h3">
                <strong>Address #{{ index }}</strong>
                <a *ngIf="contactForm.get('addresses').length > 1" class="remove-link" (click)="removeAddress()">Remove</a>
            </h3> -->
            <!-- <google-location [latitude]="latitude" [longitude]="longitude" (location)="locationAdded($event)"></google-location> -->
        </div>
    </div>
</div>