import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Routes } from 'app/Routes';
import { OrderView } from './order-view.model';
import { isNullOrUndefined } from 'util';

@Injectable()
export class OrderViewService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private router: Router) {
        // Set the defaults
        // this.onOrdersChanged = new BehaviorSubject([]);
        this.onOrderAdded = new BehaviorSubject(0);
        this.onOrderSelected = new BehaviorSubject(null);
        this.onOrdersAdded = new BehaviorSubject(0);
        this.onOrdersSelected = new BehaviorSubject(null);
        this.onOrderPrint = new BehaviorSubject(null);
    }
    // Orders: OrderListItem[];
    // onOrdersChanged: BehaviorSubject<OrderListItem[]>;
    onOrderAdded: BehaviorSubject<number>;
    onOrderSelected: BehaviorSubject<OrderView>;
    selectedOrder: OrderView;

    onOrdersAdded: BehaviorSubject<number>;
    onOrdersSelected: BehaviorSubject<OrderView>;
    selectedOrders: OrderView;

    onOrderPrint: BehaviorSubject<string>;
    orderPrint: string;


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            var params = route.params.id;

            if (isNullOrUndefined(params)) {
                this.router.navigateByUrl(Routes.orders);
            } else if (route.routeConfig.path.includes('view')) {
                Promise.all([this.getOrder(params)]).then(() => {
                    resolve(this);
                }, reject);
            }
            else if (route.routeConfig.path.includes('label')) {
                Promise.all([this.generateLabel(route.params.id)]).then(() => {
                    resolve(this);
                }, reject);
            }
            else {
                Promise.all([this.getOrdersForPrint(params)]).then(() => {
                    resolve(this);
                }, reject);
            }
            // } else {
            //     Promise.all([this.getOrder(route.params.id)]).then(() => {
            //         resolve();
            //     }, reject);
            // }

        });
    }

    /**
     * Get Orders
     *
     * @returns {Promise<any>}
     */
    getOrder(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Orders/View/' + id)
                .subscribe((response: any) => {
                    if (response == null)
                        this.router.navigateByUrl(Routes.orders);
                    if (response && response.payments) {

                        response.payments.forEach(payment => {
                            const date = new Date(Date.parse(payment.transactionTime));
                            payment.transactionTime =
                                date.toLocaleDateString() +
                                ' ' +
                                date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                        });
                    }
                    this.selectedOrder = response;
                    this.onOrderSelected.next(this.selectedOrder);
                    resolve(response);
                }, reject);
        });
    }
    getOrdersForPrint(ids: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<OrderView>(environment.apiUrl + 'v1/Orders/ViewForPrint/' + ids)
                .subscribe((response: OrderView) => {
                    this.selectedOrders = response;
                    this.onOrdersSelected.next(this.selectedOrders);
                    resolve(response);
                }, reject);
        });
    }
    generateLabel(orderIds: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Orders/label/' + orderIds)
                .subscribe((response: any) => {
                    this.orderPrint = response.data;
                    this.onOrderPrint.next(this.orderPrint);
                    resolve(response);
                }, reject);
        });
    }
}
