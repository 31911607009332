import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { OrderDetail, BillingDetails, Order, ReturnOrder, ProformaDetails, BulkOrderPayment } from './new-order/order.model';
import { LoginResult } from 'app/_models/LoginResult';
import { Entity } from 'app/_models/Entity.model';
import { MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { CommodityProductType } from 'app/_enums/ServiceType.enum';
import { NewOrder } from './anonymous-new-order/new-order.model';
import { OrderPayment } from './view/order-view.model';
import { isNullOrUndefined } from 'util';
import { catchError, concatMap, retry, retryWhen } from 'rxjs/operators';
import { delay } from 'lodash';

@Injectable()
export class OrderService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */

    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        // this.onOrdersChanged = new BehaviorSubject([]);
        this.onOrderAdded = new BehaviorSubject(0);
        this.onOrderSelected = new BehaviorSubject(null);
    }
    // Orders: OrderListItem[];
    // onOrdersChanged: BehaviorSubject<OrderListItem[]>;
    onOrderAdded: BehaviorSubject<number>;
    onOrderSelected: BehaviorSubject<Order>;
    selectedOrder: Order;
    timeRanges: Entity<number>[];

    timeRangesChanged = new BehaviorSubject<Entity<number>[]>(null);

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            if (route.params.id === 'new') {
                resolve(this);
            }
            else {
                Promise.all([this.getOrder(route.params.id)]).then(() => {
                    resolve(this);
                }, reject);
            }
        });
    }

    /**
     * Get Orders
     *
     * @returns {Promise<any>}
     */
    getOrder(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<Order>(environment.apiUrl + 'v1/Orders/' + id).subscribe((response: Order) => {
                this.selectedOrder = response;
                this.onOrderSelected.next(this.selectedOrder);
                resolve(response);
            }, reject);
        });
    }

    addOrderDetails(order: OrderDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/', order)
                .subscribe((billindDetails: BillingDetails) => {
                    this.onOrderAdded.next(billindDetails.orderId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject)
                ;

        });
    }

    // addBulkOrderDetails(bulkOrders: OrderDetail[]): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient
    //             .post(environment.apiUrl + 'v1/Orders/Bulk', bulkOrders)
    //             .subscribe((billindDetails: any) => {
    //                 resolve(billindDetails);
    //             }, reject)
    //             ;

    //     });
    // }

    uploadBulkExcelAttachment(data: any): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Orders/UploadBulkExcel/ ', data)
    }
    sendBulkExcelAttachment(string: any): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Orders/BulkEmail', string)
    }
    addBulkOrderDetails(bulkOrders: OrderDetail[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Orders/Bulk', bulkOrders);
        // .pipe(
        //     retryWhen(errors => errors
        //         .pipe(
        //             concatMap((error, count) => {
        //                 if (count === 3 && error.status === 0) {
        //                     return throwError(error);
        //                 }
        //                 return of(count);
        //             }),
        //             // (1000,1000))
        //         )
        //     )
        // );
    }


    getSurchargesForOrder(orderId: number): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Orders/Surcharges/' + orderId)
    }
    getMyInfo(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Orders/MyInfo');
    }
    getOrderManifest(fromDate?: Date, toDate?: Date): Observable<any> {

        let params = new HttpParams();
        if (!isNullOrUndefined(fromDate))
            params = params.append('fromDate', fromDate.toLocaleString());
        if (!isNullOrUndefined(toDate))
            params = params.append('toDate', toDate.toLocaleString());
        return this._httpClient.get(environment.apiUrl + 'v1/Orders/Manifest', { params });
    }

    GetReturnOrders(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Orders/MyInfo');
    }
    confirmOrder(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/Orders/Confirm/' + orderId, null).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
    ConfirmBulkOrder(orderIds): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiUrl + 'v1/Orders/ConfirmBulk/', orderIds).subscribe((response: any) => {
                // setTimeout(resolve, 5000);
                resolve(response);
            }, reject)

        });
    }
    PayBulkOrder(bulkOrderPayment: BulkOrderPayment): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/PayBulkOrder', bulkOrderPayment)
                .subscribe((a) => {
                    resolve(a);
                }, reject);
        });
    }

    downloadPdf(src) {
        return this._httpClient.get(src, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders().append('Content-Type', 'application/pdf').append('Access-Control-Allow-Origin','http://localhost:5001/'),
        });
    }





    getTimeRanges(): Observable<any> {

        return this._httpClient.get(environment.apiUrl + 'v1/Orders/TimeRanges');

        // return new Promise((resolve, reject) => {
        //     if (this.timeRanges) {
        //         resolve(this.timeRanges);
        //     } else {
        //         this._httpClient
        //             .get<Entity<number>[]>(environment.apiUrl + 'v1/Orders/TimeRanges')
        //             .subscribe(result => {
        //                 this.timeRanges = result;
        //                 this.timeRangesChanged.next(this.timeRanges);
        //                 resolve(result);
        //             }, reject);
        //     }
        // });
    }

    updateOrderDetails(order: OrderDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(environment.apiUrl + 'v1/Orders/', order)
                .subscribe((billindDetails: BillingDetails) => {
                    this.onOrderAdded.next(billindDetails.orderId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject);
        });
    }

    addBillingDetails(billingDetails: BillingDetails): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/BillingDetails/', billingDetails)
                .subscribe((loginResult: LoginResult) => {
                    localStorage.setItem('loggedInUser', JSON.stringify(loginResult));
                    resolve(loginResult);
                }, reject);
        });
    }
    addProformaDetails(proformaDetails: ProformaDetails[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/ProformaDetails/', proformaDetails)
                .subscribe((proforma: ProformaDetails[]) => {
                    resolve(proforma);
                }, reject);
        });
    }
    deleteProformaDetails(proformaDetailsId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/Orders/ProformaDetails/' + proformaDetailsId).subscribe(_ => {
                // this.getDistributions().then(() => {
                resolve(this);
                // }, reject);
            }, reject);
        });
    }
    addReturnOrder(order: ReturnOrder): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/Return', order)
                .subscribe((billindDetails: BillingDetails) => {
                    this.onOrderAdded.next(billindDetails.orderId);
                    if (billindDetails.cityId === null) {
                        billindDetails.cityId = 0;
                    }
                    if (billindDetails.countryId === null) {
                        billindDetails.countryId = 0;
                    }
                    resolve(billindDetails);
                }, reject);
        });
    }

    saveOrder(order: NewOrder): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/AnonymousOrder', order)
                .subscribe((orderId: number) => {
                    order.orderId = orderId;
                    resolve(order);
                }, reject);
        });
    }
    updateConfirmedOrder(order: OrderDetail): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Orders/Update', order)
                .subscribe((x) => {
                    resolve(x);
                }, reject);
        });
    }
    canUpdateOrder(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/orders/CanUpdate/' + orderId)
                .subscribe((canUpdate) => {
                    resolve(canUpdate);
                }, reject);
        });
    }

   
}
