import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { Entity } from 'app/_models/Entity.model';
import { QuoteService } from 'app/main/quotes/quote.service';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { ServiceType } from 'app/_enums/ServiceType.enum';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'orders-anonymous-shipment-pickup',
    templateUrl: './anonymous-shipment-pickup.component.html',
    styleUrls: ['./anonymous-shipment-pickup.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousShipmentPickupComponent extends OrderAnonymousBaseComponent implements OnInit {
    minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    shippingTypes: Entity<number>[];
    submited = false;

    constructor(private _quoteService: QuoteService) {
        super();
    }

    ngOnInit(): void {
        this._quoteService
            .getShippingTypesPromise(ServiceDirection.Domestic, ServiceType.Parcels)
            .then((result: Entity<number>[]) => {
                this.shippingTypeRetreived(result);
            });

        this._quoteService.shippingTypesChanged.subscribe((result: Entity<number>[]) => {
            this.shippingTypeRetreived(result);
        });

        this.group.get('shippingTypeId').valueChanges.subscribe(value => {
            let name = '';
            if (value) {
                const shippingType = this.shippingTypes.find(x => x.id === value);
                if (shippingType) {
                    name = shippingType.name;
                }
            }
            this.group.setControl('shippingTypeName', new FormControl(name));
        });
    }

    private shippingTypeRetreived(result: Entity<number>[]): void {
        this.shippingTypes = result;
        if (this.shippingTypes && (this.shippingTypes.length === 1 || this.shippingTypes.length === 2)) {
            this.group.get('shippingTypeId').setValue(this.shippingTypes[0].id);
            this.group.setControl('shippingTypeName', new FormControl(this.shippingTypes[0].name));
        } else {
            this.group.get('shippingTypeId').setValue(undefined);
            this.group.setControl('shippingTypeName', new FormControl(undefined));
        }
    }

    validateAndContinue(): void {
        if (this.group.valid) {
            this.continue();
        } else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    }
}
