import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subject } from '@microsoft/signalr/dist/esm/Subject';
import { CashOnDelivery } from 'app/main/orders/new-order/order.model';
import { CurrencyType } from 'app/_enums/ServiceType.enum';

@Component({
    selector: 'order-cod-shared',
    templateUrl: './order-cod-shared.component.html',
    styleUrls: ['./order-cod-shared.component.scss']
})
export class OrderCodSharedComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() index: number;
    @Input() cod: FormGroup;
    currencyTypes: any = {};
    selectedCurr: any;
    @Output() codAdded: EventEmitter<any> = new EventEmitter();


    private _unsubscribeAll: Subject<any>;
    constructor(public _matDialog: MatDialog,
        private _formBuilder: FormBuilder) {
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        console.log(this.enumSelector(CurrencyType));
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        // this.currencyTypes = this.enumSelector(CurrencyType).slice(4, 7);
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key, disabled: false }));
    }
    numberOnly(event) {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }
    removeCod(event): void {
        const pieces = this.group.get('cods') as FormArray;
        this.codAdded.emit({ mode: 'delete', index: this.index, value: event });
        pieces.removeAt(this.index - 1);
        this.cod.markAsDirty();
    }
    addCod() {
        this.codAdded.emit({ mode: 'new', index: this.index, value: this.cod.value });
        this.cod.value['codAmount'] =this.cod.value['codAmount'];
        this.cod.disable();
    }
    editCod() {
        this.cod.enable();
    }
    changeCurr(value) {
        console.log(value);
        this.selectedCurr = value;
    }
}
