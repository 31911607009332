import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subject } from '@microsoft/signalr/dist/esm/Subject';
import { SkuStock } from 'app/main/orders/new-order/order.model';
import { QuoteService } from 'app/main/quotes/quote.service';
import { stringify } from 'querystring';

@Component({
    selector: 'order-sku-shared',
    templateUrl: './order-sku-shared.component.html',
    styleUrls: ['./order-sku-shared.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderSkuSharedComponent implements OnInit {
    @Input() group: FormGroup;
    @Input() index: number;
    @Input() sku: FormGroup;
    @Input() wmsSKUs: SkuStock[];

    currencyTypes: any = {};
    @Output() skuAdded: EventEmitter<any> = new EventEmitter();

    availableQuantity: number = 0;


    private _unsubscribeAll: Subject<any>;
    constructor(public _matDialog: MatDialog,
        private _formBuilder: FormBuilder, private _quoteService: QuoteService,) {
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        console.log(this.sku);
        if (this.sku.get('sku').value != "") {
            this.availableQuantity = this.wmsSKUs.find(sku => sku.name === this.sku.get('sku').value).currentStock;
            console.log(this.availableQuantity)
            this.sku.get('quantity').setValidators([Validators.max(this.availableQuantity), Validators.min(1)]);
            this.sku.disable();
        }
        // this.currencyTypes = this.enumSelector(CurrencyType).slice(4, 7);
        // this.currencyTypes = this.enumSelector(CurrencyType).slice(4, 7);
    }

    skuSelected(event) {

        const skuSelected = this.wmsSKUs.filter(x => x.name == event.value)[0];

        this.availableQuantity = skuSelected.currentStock;
        this.sku.get('quantity').setValidators([Validators.max(this.availableQuantity), Validators.min(1)]);
        this.availableQuantity = this.wmsSKUs.find(sku => sku.name === this.sku.get('sku').value).currentStock;

    }
    numberOnly(event) {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }
    removeSku(event): void {
        const skus = this.group.get('skus') as FormArray;
        this.skuAdded.emit({ mode: 'delete', index: this.index, value: event });

        skus.removeAt(this.index - 1);
        this.sku.markAsDirty();
    }
    addSku() {
        this.skuAdded.emit({ mode: 'new', index: this.index, value: this.sku.value });
        // this.sku.disable();
    }
    editSku() {
        // this.sku.enable();
    }
}
