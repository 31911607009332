import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class PaymentService {
    constructor(private _httpClient: HttpClient) {}

    createCheckoutSession(orderId: number): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Payments/Checkout/?orderId=' + orderId, null);
    }
    applyCoupon(couponCode: string, amount: number): Promise<any> {
        return new Promise((resolve, reject) => {
            const orderId = localStorage.getItem('orderId');
            if (couponCode === undefined || couponCode === null || couponCode.length === 0) {
                reject();
            }
            if (amount === undefined || amount === null || amount === 0) {
                reject();
            }
            const params = new HttpParams()
                .append('couponCode', couponCode.toString())
                .append('amount', amount.toString());

            this._httpClient.post(environment.apiUrl + 'v1/Payments/Coupon', null, { params }).subscribe(
                (newAmount: number) => {
                    resolve(newAmount);
                },
                error => {
                    reject();
                }
            );
        });
    }
}
