import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { NetPointResult } from './netpoint.model';

@Injectable()
export class NetPointService {
    constructor(private _httpClient: HttpClient) {
    }

    checkInLabel(key, label): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams().append('key', key).append('label', label);

            this._httpClient
                .post<any>(environment.apiUrl + 'v1/NetPoints/CheckIn', null, { params })
                .subscribe(result => {
                    resolve(result);
                }, reject);
        });
    }
    preDeliverLabel(key, token): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams().append('key', key).append('token', token);

            this._httpClient
                .post<any>(environment.apiUrl + 'v1/NetPoints/PreDeliver', null, { params })
                .subscribe(result => {
                    resolve(result);
                }, reject);
        });
    }
    deliverLabel(key, label, amount, currency): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams().append('key', key).append('label', label).append('amount', amount).append('currency', currency);
            this._httpClient
                .post<any>(environment.apiUrl + 'v1/NetPoints/Deliver', null, { params })
                .subscribe(result => {
                    resolve(result);
                }, reject);
        });
    }
    returnMerch(key, label, amount): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams().append('key', key).append('label', label).append('amount', amount);
            this._httpClient
                .post<any>(environment.apiUrl + 'v1/NetPoints/ReturnMerch', null, { params })
                .subscribe(result => {
                    resolve(result);
                }, reject);
        });
    }
    returnToNet(key, label): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams().append('key', key).append('label', label);
            this._httpClient
                .post<any>(environment.apiUrl + 'v1/NetPoints/ReturnMerchToNet', null, { params })
                .subscribe(result => {
                    resolve(result);
                }, reject);
        });
    }

}
