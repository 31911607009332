import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Entity } from 'app/_models/Entity.model';
import { QuoteService } from '../quote.service';
import { QuickQuote } from './quick-quote.model';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { CurrencyType, ServiceType } from 'app/_enums/ServiceType.enum';
import { Countries, InternationalQuote } from '../international/international.model';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Routes } from 'app/Routes';
import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { City } from 'app/_models/city.model';
import { DomesticQuote } from '../domestic/domestic.model';
import { QuoteResult } from '../quote-result/quote-result.model';
import { WeightValidator } from 'app/_validators/weight.validator';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDimesionsComponent } from 'app/main/profile/confirm-dimensions/confirm-dimensions';
import { CashOnDelivery } from 'app/main/orders/new-order/order.model';
import { CurrencyValidator } from 'app/_validators/currency.validator';
import { StatusValidator } from 'app/_validators/status.validator';

@Component({
    selector: 'quotes-quick-quote',
    templateUrl: './quick-quote.component.html',
    styleUrls: ['./quick-quote.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class QuotesQuickQuoteComponent implements OnInit, OnDestroy {
    //#region properties
    quoteForm: FormGroup;
    quoteResult: QuoteResult;

    selectedFromCityName: string;
    selectedFromCityNameClone: string;
    selectedToCityName: string;
    selectedToCityNameClone: string;
    shippingTypeName: string;

    quoteCalculated = false;
    formChanged = false;
    loading = false;
    allowedToProceed = true;
    neededServiceType = false;
    ServiceType = ServiceType;
    progressValue: number;
    estimatedCost: number;

    quoteCurrency: string;

    loadingShippingTypes = false;



    shippingTypes: Entity<number>[];

    fromCityControl = new FormControl('', Validators.required);
    toCityControl = new FormControl('', Validators.required);

    fromCountryId: number;
    toCountryId: number;

    selectedDirectionName = '';
    selectedDirectionNameClone = '';
    quoteIcon = '';
    quoteIconClone = '';

    // lebanonId = Countries.Lebanon;
    domesticCountryId = 158; //Lebanon

    protected _unsubscribeAll = new Subject<void>();
    getShippingTypesAction: Subscription;

    ServiceDirection = ServiceDirection;

    currentDirection: ServiceDirection;
    readyDirection: ServiceDirection;
    maxWeight: number;

    currentServiceType: MyNetServiceType;

    pageName: string;

    confirmDimension: MatDialogRef<ConfirmDimesionsComponent>;
    //#endregion

    constructor(
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _quoteService: QuoteService,
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _fuseConfigService: FuseConfigService
    ) {
        const pathname = window.location.pathname;
        if (pathname.includes(Routes.domesticQuotes)) {
            this.readyDirection = ServiceDirection.Domestic;
            this.updateFuseTemplate();
        } else if (pathname.includes(Routes.exportQuote)) {
            this.readyDirection = ServiceDirection.Export;
            this.updateFuseTemplate();
        }
    }

    private updateFuseTemplate(): void {
        if (!this._authenticationService.isLoggedIn()) {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }
    }
    addNewCod(): void {
        const cods = this.quoteForm.get('cods') as FormArray;


        var cod = this._formBuilder.group(new CashOnDelivery());
        // cod.setValidators();
        cod.get('codAmount').setValidators(Validators.min(1));
        cod.get('codCurrencyId').setValidators(Validators.min(1));
        if (cods.length > 0) {
            cod.get('codCurrencyId').setValidators([Validators.min(1), CurrencyValidator.validCurrency((cods.at(cods.length - 1) as FormGroup).get('codCurrencyId').value)]);
            cod.get('codCurrencyId').setValue(2);
        }
        else
            cod.get('codCurrencyId').setValue(1);



        cods.push(cod);

    }
    codAdded(data: any): void {
        const pieceForm = data.value;
        const mode: string = data.mode;
        const index: number = data.index;
        this.quoteForm.setValidators(StatusValidator.validStatus("disabled"));

        if (mode === 'cancel') {
            // if (index >= 0) {
            //     this.editIndex[index] = false;
            // }
            return;

        }
        else if (mode === 'new') {

           
            // const pieces = this.group.get('quote').get('pieces') as FormArray;
            // pieces.at(index - 1).setValue(pieceForm);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // if (TotalNOP == 0)
            //     TotalNOP = 1
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            // const pieces = this.quoteForm.get('pieces') as FormArray;
            // pieces.removeAt(index - 1);
            // var TotalWeight = 0, TotalNOP = 0;

            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // if (TotalNOP == 0)
            //     TotalNOP = 1;
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            // if (pieceForm != undefined && pieceForm.pieceId != 0) {
            //     this._quoteService.deletePieces(pieceForm.pieceId)
            // }
            // if (pieces.controls.length == 0)
            //     pieces.clearValidators()
        }
        // this.calculateChargeableWeight();
    }
    ngOnInit(): void {
        this.quoteForm = this.createQuoteForm();
        this.quoteForm.setControl('cods', this._formBuilder.array([]));

        this.prepareShippingTypes();
        this.subscribeShippingTypeChanged();
        this.progressValue = 0;
        this.quoteForm.valueChanges.subscribe(_ => (this.formChanged = true));

        this.fromCityControl.valueChanges.subscribe(value => {
            this.fromCitySelected(value);
            if (!this.readyDirection) {
                this.checkServiceType();
                this.updateQuoteIcon();
            }
        });

        this.toCityControl.valueChanges.subscribe(value => {
            this.toCitySelected(value);
            if (!this.readyDirection) {
                this.checkServiceType();
                this.updateQuoteIcon();
            }
        });

        if (this.readyDirection) {
            this.checkServiceType();
            this.updateQuoteIcon();
        }
        this.setDecimalValidation();
        this.domesticCountryId = parseInt(localStorage.getItem('countryId').toString());
        // this.addNewCod();
    }
    private setDecimalValidation(): void {
        const length = this.quoteForm.get('length');
        const width = this.quoteForm.get('width');
        const height = this.quoteForm.get('height');
        if (this.currentDirection == ServiceDirection.Domestic) {
            length.setValidators([DecimalValidator.validDecimal(0, 200)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        else {
            length.setValidators([DecimalValidator.validDecimal(0, 150)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        const weight = this.quoteForm.get('weight');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    }

    weightChanged(event): void {
        const weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.quoteForm.get('weight').setValue('0.5');
        }
        // if (this.quoteForm.get('shippingTypeId').value == undefined || this.quoteForm.get('shippingTypeId').value == '') // tarif product not found
        // {
        //     this.quoteForm.get('shippingTypeId').setValue(0);
        // }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    updateQuoteIcon(): void {
        this.currentDirection = this.getDirection();
        if (this.currentDirection === ServiceDirection.Domestic) {
            // domestic
            this.quoteIcon = 'local_shipping';
            this.currentServiceType = MyNetServiceType.Domestic;
        } else if (this.currentDirection === ServiceDirection.Export) {
            // Export
            this.quoteIcon = 'flight_takeoff';
            this.currentServiceType = MyNetServiceType.Export;
        } else if (this.currentDirection === ServiceDirection.Import) {
            // Import
            this.quoteIcon = 'flight_land';
            this.currentServiceType = MyNetServiceType.Import;
        } else {
            // Not Supported
            this.quoteIcon = '';
            this.currentServiceType = undefined;
        }
    }

    private subscribeShippingTypeChanged(): void {
        this.quoteForm
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shippingTypeId => {
                if (shippingTypeId && this.shippingTypes) {
                    this.shippingTypeName = this.shippingTypes.find(x => x.id === shippingTypeId).name;
                }
            });
    }
    private prepareShippingTypes(
        direction: ServiceDirection = ServiceDirection.Domestic,
        serviceType: ServiceType = ServiceType.Parcels): void {



        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        if (this.getShippingTypesAction && !this.getShippingTypesAction.closed) {
            this.getShippingTypesAction.unsubscribe();
        }
        this.loadingShippingTypes = true;
        this.shippingTypes = [];
        this.getShippingTypesAction = this._quoteService.getShippingTypes(direction, serviceType).subscribe(result => {

            this.shippingTypes = result;
            this.loadingShippingTypes = false;
            if (this.shippingTypes && this.shippingTypes.length === 1) {
                this.quoteForm.get('shippingTypeId').setValue(this.shippingTypes[0].id);
                this.shippingTypeName = this.shippingTypes[0].name;
            }
            this.setDecimalValidation();
            if (this.shippingTypes.length == 0) {
                const shippingTypeId = this.quoteForm.get('shippingTypeId');
                shippingTypeId.setValue(0);
                this.quoteForm.updateValueAndValidity();
            }
        });
    }

    // private prepareShippingTypes(
    //     direction: ServiceDirection = ServiceDirection.Domestic,
    //     serviceType: ServiceType = ServiceType.Parcels ): void {
    //     // Direction is domestic since we are in domestic component
    //     // Service Type is always Parcels, no docs in Lebanon domestic
    //     if (this.getShippingTypesAction && !this.getShippingTypesAction.closed) {
    //         this.getShippingTypesAction.unsubscribe();
    //     }

    //     this.shippingTypes = [];
    //     this.getShippingTypesAction = this._quoteService.getShippingTypes(direction, serviceType).subscribe(result => {
    //         this.shippingTypes = result;
    //         if (this.shippingTypes && this.shippingTypes.length === 1) {
    //             this.quoteForm.get('shippingTypeId').setValue(this.shippingTypes[0].id);
    //             this.shippingTypeName = this.shippingTypes[0].name;
    //         }
    //     });
    // }

    private createQuoteForm(): FormGroup {
        const formGroup = this._formBuilder.group(new QuickQuote());
        formGroup.get('fromCityId').setValidators(Validators.required);
        formGroup.get('toCityId').setValidators(Validators.required);
        return formGroup;
    }
    get quoteCods(): any {
        return (this.quoteForm.get('cods') as FormArray).controls;
    }


    fromCitySelected(option: City): void {
        this.fromCityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.quoteForm.get('fromCityId').setValue(option.cityId);
            this.selectedFromCityName = option.displayName;
            this.fromCountryId = option.countryId;
        } else {
            this.fromCityControl.setErrors({ required: true });
        }
    }

    fromCityCleared(): void {
        this.quoteForm.get('fromCityId').setValue('');
        this.selectedFromCityName = '';
        this.fromCityControl.clearValidators();
        this.fromCountryId = undefined;
    }

    toCitySelected(option: City): void {
        this.toCityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.quoteForm.get('toCityId').setValue(option.cityId);
            this.selectedToCityName = option.name;
            this.toCountryId = option.countryId;
        } else {
            this.toCityControl.setErrors({ required: true });
        }
    }

    toCityCleared(): void {
        this.quoteForm.get('toCityId').setValue('');
        this.selectedToCityName = '';
        this.toCityControl.clearValidators();
        this.toCountryId = undefined;
    }

    checkServiceType(): void {
        this.quoteForm.removeControl('serviceTypeId');

        this.currentDirection = this.getDirection();



        if (this.currentDirection === ServiceDirection.Export) {
            // Export
            this.neededServiceType = true;
            this.maxWeight = WeightValidator.MaxExportWeight;
        } else if (this.currentDirection === ServiceDirection.Import) {
            // Import
            this.neededServiceType = true;
            this.maxWeight = undefined;
        } else if (this.currentDirection === ServiceDirection.Domestic) {
            // Domestic
            this.neededServiceType = false;
            this.maxWeight = WeightValidator.MaxDomesticWeight;
        } else {
            // Not Supported
            this.maxWeight = undefined;
        }
        this.quoteForm.get('shippingTypeId').setValue('');
        if (this.neededServiceType) {
            this.quoteForm.addControl('serviceTypeId', new FormControl('', Validators.required));
            this.shippingTypes = [];
            this.quoteForm.get('serviceTypeId').valueChanges.subscribe(serviceTypeId => {
                this.prepareShippingTypes(this.currentDirection, serviceTypeId as ServiceType);
            });
        } else {
            this.prepareShippingTypes();
        }

        const weight = this.quoteForm.get('weight');
        weight.setValue('');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    }
    dimensionsChanged(content) {
        const validHeight = this.quoteForm.get('height').hasError('validDecimal');
        const validWidth = this.quoteForm.get('width').hasError('validDecimal');
        const validLength = this.quoteForm.get('length').hasError('validDecimal');
        if (validHeight || validWidth || validLength)
            this.confirmDimension = this._matDialog.open(ConfirmDimesionsComponent, {
                panelClass: 'confirm-Dimension'
            });
    }
    getDirection(): ServiceDirection {
        if (this.readyDirection) {
            return this.readyDirection;
        }

        if (!this.fromCountryId || !this.toCountryId) {
            return undefined;
        }

        if (this.fromCountryId === this.domesticCountryId && this.toCountryId === this.domesticCountryId) {
            // domestic
            return ServiceDirection.Domestic;
        } else if (this.fromCountryId === this.domesticCountryId) {
            // Export
            return ServiceDirection.Export;
        } else if (this.toCountryId === this.domesticCountryId) {
            // Import
            return ServiceDirection.Import;
        } else {
            // Not Supported
            return undefined;
        }
    }

    submitForm(): void {
        const currentDirection = this.getDirection();
        this.loading = true;

        if (currentDirection === ServiceDirection.Export || currentDirection === ServiceDirection.Import) {
            const serviceType = this.quoteForm.get('serviceTypeId').value;

            if (
                !serviceType ||
                serviceType === 0 ||
                (serviceType !== ServiceType.Docs && serviceType !== ServiceType.Parcels)
            ) {
                this.quoteForm.get('serviceTypeId').setErrors({ required: true });
                return;
            }
        }
        if (this.quoteForm.invalid) {
            return;
        }
        let international = false;
        if (currentDirection === ServiceDirection.Domestic) {
            // domestic
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Domestic];
            this.quoteIcon = 'local_shipping';
            this.allowedToProceed = true;
        } else if (currentDirection === ServiceDirection.Export) {
            // Export
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Export];
            this.quoteIcon = 'flight_takeoff';
            this.allowedToProceed = true;
            international = true;
        } else if (currentDirection === ServiceDirection.Import) {
            // Import
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Import];
            this.quoteIcon = 'flight_land';
            this.allowedToProceed = true;
            international = true;
        } else {
            // Not Supported
            this.selectedDirectionName = 'Not Supported';
            this.quoteIcon = 'not_interested';
            this.allowedToProceed = false;
        }

        let generateQuote;
        const formValue = this.quoteForm.value;
        formValue.cods = this.quoteForm.get('cods').value;

        if (international) {
            formValue.fromCountryId = this.fromCountryId;
            if (this.quoteForm.get('shippingTypeId').value == undefined || this.quoteForm.get('shippingTypeId').value == '') // tarif product not found
            {
                this.quoteForm.get('shippingTypeId').setValue(0);
                formValue.shippingTypeId = 0;
            }
            formValue.toCountryId = this.toCountryId;
            generateQuote = this._quoteService.generateInternationalQuote(formValue as InternationalQuote);
        } else {
            formValue.serviceTypeId = ServiceType.Parcels;
            generateQuote = this._quoteService.generateDomesticQuote(formValue as DomesticQuote);
        }


        generateQuote.subscribe(result => {
            this.estimatedCost = result.price;
            this.quoteCurrency = result.currency;
            console.log(result);

            this.quoteResult = new QuoteResult({
                fromCity: this.selectedFromCityName,
                icon: this.quoteIcon,
                direction: this.selectedDirectionName,
                toCity: this.selectedToCityName,
                estimatedCost: this.estimatedCost,
                currency: this.quoteCurrency,
                shippingType: this.shippingTypeName,
                weight: this.quoteForm.get('weight').value,
                width: this.quoteForm.get('width').value,
                height: this.quoteForm.get('height').value,
                length: this.quoteForm.get('length').value
            });


            if (this.neededServiceType) {
                this.quoteResult.serviceType = ServiceType[this.quoteForm.get('serviceTypeId').value];
            }
            this.quoteCalculated = true;
            this.formChanged = false;
            this.loading = false;
        });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }

        return true;
    }

    goToPlaceAnOrder(): void {
        this.loading = true;
        const quoteData = new QuoteData(this.quoteForm.value);
        quoteData.fromCity = this.selectedFromCityName;
        quoteData.toCity = this.selectedToCityName;
        quoteData.shippingType = this.shippingTypeName;
        quoteData.quotePrice = this.estimatedCost;
        quoteData.quoteCurrency = this.quoteCurrency;
        var currencySelected = this.quoteCurrency as String;
        var currencyType: CurrencyType = <CurrencyType>CurrencyType[currencySelected.toString()];
        quoteData.quoteCurrencyId = currencyType;
        quoteData.type = this.getDirection();
        quoteData.productTypeId = ServiceType.Parcels;
        if (quoteData.type === ServiceDirection.Domestic) {
            quoteData.fromCountryId = this.domesticCountryId;
            quoteData.toCountryId = this.domesticCountryId;
        }
        this._quoteService.addQuote(quoteData).subscribe(
            (result: QuoteData) => {
                quoteData.quoteId = result.quoteId;
                const navigationExtras: NavigationExtras = {
                    state: quoteData
                };
                this.loading = false;
                if (!this._authenticationService.isLoggedIn()) {
                    this._router.navigate([Routes.newAnonymousOrder], navigationExtras);
                } else {
                    this._router.navigate([Routes.newOrder], navigationExtras);
                }
            },
            error => {
                this.loading = false;
                alert('Error while adding quote');
            }
        );
    }
}
