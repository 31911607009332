import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'quote-start',
    templateUrl: './quote-start.component.html',
    styleUrls: ['./quote-start.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class QuoteStartComponent implements OnInit {
    @Input() icon: string;
    @Input() title: string;

    constructor() {}

    ngOnInit(): void {}
}
