export enum ServiceType {
    Docs = 1,
    Parcels = 2,
    Cash = 3
}
export enum CurrencyType {
    LBP = 1,
    USD = 2,
    JOD = 9,
    IQD = 106,
    EGP = 98,
    AED = 18,
    EUR = 3
}

export enum CommodityProductType {
    Docs = 1,
    Parcels = 2
}
