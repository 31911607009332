import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { Commodity, CommodityService } from 'app/_shared/commodity.service';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';

@Component({
    selector: 'orders-anonymous-shipment-details',
    templateUrl: './anonymous-shipment-details.component.html',
    styleUrls: ['./anonymous-shipment-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousShipmentDetailsComponent extends OrderAnonymousBaseComponent implements OnInit {
    commodities: Commodity[];
    submited = false;

    constructor(private _commodityService: CommodityService) {
        super();
    }

    ngOnInit(): void {
        if (this.form.get('currentDirection')) {
            const serviceType: ServiceDirection = this.form.get('currentDirection').value;
            let mynetServiceType: MyNetServiceType;
            switch (serviceType) {
                case ServiceDirection.Export:
                    mynetServiceType = MyNetServiceType.Export;
                    break;
                case ServiceDirection.Import:
                    mynetServiceType = MyNetServiceType.Import;
                    break;
                case ServiceDirection.Domestic:
                    mynetServiceType = MyNetServiceType.Domestic;
                    break;
                default:
                    break;
            }
            this._commodityService.getCommodities(mynetServiceType).then(result => {
                this.commodities = result;
            });
        }

        // this._commodityService.commoditiesChanged.subscribe(result => {
        //     this.commodities = result;
        //     this.group.get('commodityId').setValue('');
        // });
    }

    validateAndContinue(): void {
        if (this.group.valid) {
            this.continue();
        } else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    }
}
