import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
// import { OrderDetail } from '../../order.model';
import { environment } from 'environments/environment';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { FormGroup } from '@angular/forms';
import { Countries } from 'app/main/quotes/international/international.model';
import { OrderDetail } from 'app/main/orders/new-order/order.model';
import { InvoicePaymentService } from '../Invoice-payment.services';
import { InvoicePayment } from '../Invoice-payment.model';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';

declare var Checkout: any;

@Component({
    selector: 'invoice-checkout',
    templateUrl: './invoice-checkout.component.html',
    styleUrls: ['./invoice-checkout.component.scss']//,
    // encapsulation: ViewEncapsulation.None
})
export class InvoiceCheckoutComponent implements OnInit, OnDestroy {
    invoice: InvoicePayment;
    paymentSessionId: string;
    uniqueId: string;
    loadCheckout: Promise<any>;
    payClicked = false;
    checkoutLoaded = false;
    retryAllowed = false;
    serviceTypeId: MyNetServiceType;

    protected _unsubscribeAll = new Subject<void>();

    constructor(
        public matDialogRef: MatDialogRef<InvoiceCheckoutComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _paymentService: InvoicePaymentService,
        private _snackBar: MatSnackBar,
        private _router: Router
    ) {
        this.invoice = _data.invoice as InvoicePayment;
        this.loadCheckout = new Promise(resolve => {
            this.loadScript();
            resolve(true);
        });
    }

    loadScript(): void {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;

            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');

            node.setAttribute('data-complete', window.location.origin + '/invoice-payment-redirect');



            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    ngOnInit(): void {
        
        // this.setServiceTypeId();
        this.loadCheckout.then(_ => {
            this.getCheckoutSession();
        });
    }
    navigatePaymentDone() : void{
        this.matDialogRef.close();
        this._router.navigate([Routes.invoicePaymentRedirect]);
    }

    getCheckoutSession(): void {
        this.retryAllowed = false;
        this._paymentService.createCheckoutSessionBulk(this.invoice).subscribe(
            result => {
                this.paymentSessionId = result.sessionId;
                this.uniqueId = result.uniqueId;

                localStorage.setItem("paymentSessionId",result.sessionId);
                localStorage.setItem("uniqueId",result.uniqueId);


                if (this.paymentSessionId == null || this.uniqueId === '00000000-0000-0000-0000-000000000000') {
                    this._snackBar.open('Error occured while generating payment session!');
                    this.retryAllowed = true;
                }

                const mainLoopId = setInterval(() => {
                    if (!this.checkoutLoaded) {
                        this.configureCheckoutJs();
                        this.checkoutLoaded = true;
                        clearInterval(mainLoopId);
                    }
                }, 1000);
            },
            error => {
                this._snackBar.open('Error occured while generating payment session!');
                this.retryAllowed = true;
            }
        );
    }

    configureCheckoutJs(): void {
        Checkout.configure({
            merchant: environment.merchantId,
            order: {
                amount: this.invoice.totalAmount,
                currency: 'USD',
                description: 'Invoice Payment',
                id: this.uniqueId
            },
            session: {
                id: this.paymentSessionId
            },
            interaction: {
                merchant: {
                    name: 'The Net Holding',
                    address: {
                        line1: 'Karantina Seaside Road, Beirut, Lebanon',
                        line2: 'Beirut, Lebanon'
                    },
                    email: 'info@thenet.group',
                    phone: '+961 (1) 593-400',
                    logo: 'https://www.thenet.group/images/logo.svg'
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    confirm(): void {
        this.payClicked = true;
        Checkout.showPaymentPage();
    }
  
    @HostListener('document:payment-error', ['$event'])
    paymentError(event): void {
    }

    @HostListener('document:payment-canceled', ['$event'])
    paymentCanceled(event): void {
    }
}
