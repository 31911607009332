import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { City } from '../_models/city.model';
import { Country } from '../_models/country.model';
import { environment } from 'environments/environment';
import { map } from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    constructor(private _httpClient: HttpClient) { }

    getCities(q: string, countryId?: number, excludeCountryId?: number): Observable<City[]> {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            if (countryId) {
                params = params.append('countryId', countryId.toString());
            }
            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }

            return this._httpClient.get<City[]>(environment.apiUrl + 'v1/Cities', { params });
        } else {
            return EMPTY;
        }
    }
    getCitiesForLocation(q?: string, CityName1?: string, CityName2?: string, countryName?: string): Observable<City[]> {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            params = params.append('CityName1', CityName1);
            params = params.append('CityName2', CityName2);
            params = params.append('countryName', countryName);

            return this._httpClient.get<City[]>(environment.apiUrl + 'v1/Cities/Location', { params });
        } else {
            return EMPTY
        }
    }

    getCity(q: string, countryId?: number, excludeCountryId?: number): number {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            if (countryId) {
                params = params.append('countryId', countryId.toString());
            }
            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }
            this._httpClient.get<City>(environment.apiUrl + 'v1/Cities', { params }).subscribe(result => {

                return result[0].cityId;
            });;
        } else {
            return 0;
        }
    }


    getStrictCities(q: string, countryId: number, excludeCountryId?: number): Observable<City[]> {
        if (q && typeof q === 'string' && countryId) {
            let params = new HttpParams().append('q', q);
            params = params.append('countryId', countryId.toString());

            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }
            return this._httpClient.get<City[]>(environment.apiUrl + 'v1/Cities/Strict', { params });
        } else {
            return EMPTY;
        }
    }

    getCountries(q: string, allowedCountries?: number[]): Observable<Country[]> {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);

            if (allowedCountries) {
                params = params.append('allowedCountries', allowedCountries.join(','));
            }

            return this._httpClient.get<Country[]>(environment.apiUrl + 'v1/Countries', { params });
        } else {
            return EMPTY;
        }
    }
    getCountryCitiesFromDB(countryId: string): Observable<City[]> {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);
        let params = new HttpParams().append('countryId', countryId);
        return this._httpClient.get<City[]>(environment.apiUrl + 'v1/Cities/Country', { params });

        // } else {
        //     return EMPTY;
    }
    getCountriesFromDB(): Observable<Country[]> {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);

        return this._httpClient.get<Country[]>(environment.apiUrl + 'v1/Countries/DB');

        // } else {
        //     return EMPTY;
    }
    getCitiesFromDB(): Observable<City[]> {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);

        return this._httpClient.get<City[]>(environment.apiUrl + 'v1/Cities/DB');

        // } else {
        //     return EMPTY;
    }
}
