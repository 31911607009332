import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Address } from 'app/main/contacts/contact-form/contact.model';

@Component({
    selector: 'order-contact-preview',
    templateUrl: './contact-preview.component.html',
    styleUrls: ['./contact-preview.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrderContactPreviewComponent implements OnInit {
    @Input() contact: ContactListItem;
    @Input() address: Address;
    @Input() isNetPoint: boolean;

    ngOnInit(): void {
    }
}
