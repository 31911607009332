<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" *ngIf="action === 'new'">
                New Contact
            </span>
            <span class="title dialog-title" *ngIf="action === 'edit'">
                Edit Contact
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <form [formGroup]="contactForm" class="h-100-p" fxLayout="column" fxLayout.gt-sm="row">
            <div style="flex: 1">
                <div class="p-24 pb-0 m-0">
                    <h2 class="h2">Contact Details</h2>
                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <mat-label>First Name</mat-label>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <input name="contact-firstName" formControlName="firstName" matInput required
                                maxlength="125" />
                            <mat-error *ngIf="contactForm.get('firstName').hasError('required')">
                                First Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <mat-label>Last Name</mat-label>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <input name="contact-lastName" formControlName="lastName" matInput required
                                maxlength="125" />
                            <mat-error *ngIf="contactForm.get('lastName').hasError('required')">
                                Last Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <mat-label>Company Name</mat-label>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <input name="contact-companyName" formControlName="companyName" matInput maxlength="125" />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex style="padding-top: -1px;">
                            <mat-select name="contact-PhoneCountryCode" formControlName="phoneCountryCode"
                                placeholder="Code" (selectionChange)="countryChange($event)" required>
                                <mat-option *ngFor="let country of countries" [value]="country.countryPhoneCode">
                                    {{ country.displayNameWithCode }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                            <mat-label>Phone/Mobile Number</mat-label>
                            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                            <input name="contact-phoneNumber" formControlName="phoneNumber" matInput required
                                maxlength="125" type="tel" />
                            <mat-error *ngIf="contactForm.get('phoneNumber').hasError('required')">
                                Phone/Mobile Number is required
                            </mat-error>

                            <mat-error *ngIf="contactForm.get('phoneNumber').hasError('validCountryPhone')">
                                Phone/Mobile Number is not valid
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <mat-label>Email</mat-label>
                            <mat-icon matSuffix class="secondary-text">email</mat-icon>
                            <input name="contact-email" formControlName="email" matInput type="email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                maxlength="125" />
                            <!-- <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                Email is required
                            </mat-error> -->
                            <mat-error
                                *ngIf="contactForm.get('email').hasError('email') || contactForm.get('email').hasError('pattern')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <mat-form-field class="w-50-p" fxFlex>
                            <mat-chip-list #tagList aria-label="Tags">
                                <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                                    (removed)="remove(tag)">
                                    {{tag}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="add tag(s)" [matChipInputFor]="tagList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-icon matSuffix class="secondary-text">bookmark_border</mat-icon>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" class="mb-8" fxLayoutAlign="start start">
                        <div class="mat-form-field">
                            <p><mat-checkbox formControlName="isDefault">Is Default Shipper</mat-checkbox></p>
                        </div>    
                        <!-- <mat-form-field class="w-50-p" fxFlex> -->
                        <!-- <mat-label>Is Default</mat-label>
                        <mat-checkbox class="example-margin">Is Default Shipper</mat-checkbox> -->
                        <!-- <input #saveUserNameCheckBox id="saveUserNameCheckBox" type="checkbox" [checked]="saveUsername"
                            (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked)" /> -->
                        <!-- </mat-form-field> -->
                    </div>
                </div>
            </div>
            <div style="flex: 1 50%">
                <div class="pl-0 p-24 pb-0 m-0 pr-0 min-h-60-vh">
                    <div class="address-container-right" fusePerfectScrollbar formArrayName="addresses">
                        <h2 class="h2">Addresses</h2>
                        <ng-container *ngFor="let address of contactAddresses; let i = index" [formGroupName]="i">
                            <app-contact-form-address [addressForm]="address" [contactForm]="contactForm"
                                [index]="(i + 1)">
                            </app-contact-form-address>
                        </ng-container>

                        <a (click)="addNewAddress()" style="cursor: pointer;">+ Add new address</a>
                    </div>
                </div>
            </div>

        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <mat-error *ngIf="contactExist" class="mr-8">Contact already exists!</mat-error>
        <button *ngIf="action === 'edit' || action === 'import'" mat-button class="mr-8"
            (click)="matDialogRef.close(['cancel'])" aria-label="Cancel" matTooltip="Cancel Edit Contact">
            CANCEL
        </button>
        <button *ngIf="action !== 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveNew()"
            [disabled]="contactExist || (contactForm.invalid && !contactForm.dirty)" aria-label="Save New Contact">
            SAVE
        </button>
        <button *ngIf="action === 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveExisting()"
            [disabled]="contactExist || (contactForm.invalid && !contactForm.dirty)" aria-label="Save Contact">
            SAVE
        </button>
    </mat-dialog-actions>
</div>