import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { forkJoin, Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderEvent } from './bulk-order.model';
import { OrdersOrderListService } from '../order-list.service';
import { takeUntil } from 'rxjs/operators';
import { CurrencyType, ServiceType } from 'app/_enums/ServiceType.enum';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { QuoteService } from 'app/main/quotes/quote.service';
import { CommodityService } from 'app/_shared/commodity.service';
import { OrderService } from '../../order.service';
import { Entity } from 'app/_models/Entity.model';
type AOA = any[][];
import * as XLSX from 'xlsx';
import { ImportOrderComponent } from '../import-order/import-order.component';
import { OrderExcelListItem } from '../import-order/import-order.model';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { isNullOrUndefined } from 'util';
import { ContactsContactListService } from 'app/main/contacts/contact-list/contact-list.service';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Country } from 'app/_models/country.model';
import { City } from 'app/_models/city.model';
import { BillingDetails, BulkOrderPayment, CashOnDelivery, Order, OrderDetail, OrderSku, SkuStock, BulkSkuStock } from '../../new-order/order.model';
import { DomesticQuote } from 'app/main/quotes/domestic/domestic.model';
import { Contact } from 'app/main/contacts/contact-form/contact.model';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { QuoteData } from '../../new-order/quote-data.model';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { Stream } from 'stream';
import { OrderViewService } from '../../view/order-view.service';
import { OrderLabelComponent } from '../../order-label/order-label.component';

@Component({
    selector: 'bulk-order-dialog',
    templateUrl: './bulk-order-dialog.component.html',
    styleUrls: ['./bulk-order-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BulkOrderDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    shippingTypes: any[];
    commodities: any;
    specialInstructions: string;
    timeRanges: Entity<number>[];
    listSkus: SkuStock[] = [];
    hasDuplicatedSKUs: boolean = false;

    @ViewChild('FileSelectInputDialog', { static: true }) FileSelectInputDialog: ElementRef;

    importingOrders = false;
    importOrderDialogRef: MatDialogRef<ImportOrderComponent>;
    data: AOA = [];
    exceldataSource = new MatTableDataSource<any>(this.data);
    excelTemplateColumns: string[] = ['Shipper Reference', 'Shipper', 'First Name', 'Last Name', 'MobileCountryCode', 'Mobile',
        'City', 'Street', 'Building', 'Floor', 'Landmark', 'Special Instructions', 'Weight', 'NOP', 'COD Amount', 'COD Currency', 'COD Amount 2', 'COD Currency 2', 'SKUs',
        'Multiframes Label'];
    excelTemplateColumnsNew: string[] = ['Shipper Reference', 'Shipper', 'First Name', 'Last Name', 'MobileCountryCode', 'Mobile',
        'City', 'Street', 'Building', 'Floor', 'Landmark', 'Special Instructions', 'Weight', 'NOP', 'COD Amount', 'COD Currency', 'COD Amount 2', 'COD Currency 2', 'SKUs', 'SKU Quantity',
        'Multiframes Label'];

    shipperReferences: String[];

    ordersToImport: OrderExcelListItem[];
    ordersImported = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    currencyTypes: any;
    contacts: ContactListItem[];
    countries: Country[];
    cities: City[];
    bulkOrderParams: any;
    // orderImportCount: number = 0;
    totalOrders: number = 0;
    bulkOrdersImported: number[] = [];

    ShipperReferenceIndex: number; ShipperIndex: number; FirstNameIndex: number; LastNameIndex: number; EmailIndex: number; 
    CountryCodeIndex: number; MobileIndex: number; CountryIndex: number; CityIndex: number;
    StreetIndex: number; BuildingIndex: number; FloorIndex: number; LandMarkIndex: number; 
    CompanyNameIndex: number; SpecialIndex: number; WeightIndex: number; NOPIndex: number; CodIndex: number; CodCurrencyIndex: number;
    Cod2Index: number; CodCurrency2Index: number; SkuIndex: number; SkuQtyIndex: number; multiframesLabelIndex: number;


    // quoteType: number;
    goodsDescription: string;
    // orders: any;
    protected _unsubscribeAll = new Subject<void>();

    minDate = new Date(new Date().setDate(new Date().getDate()));


    orderIdsToImport = [];
    importingBulk = false;
    BulkOrderCod: CashOnDelivery[] = [];

    BulkOrderDetails: OrderDetail[] = [];


    BulkQuoteDetails: DomesticQuote[] = [];
    BulkBatchLength: number = 20;

    selectedDirection: string;
    selectedDirectionId: number;

    lebanonId = 158;
    jordanId = 137;
    egyptId = 90;
    uaeId = 280;
    domesticCountryId: 158; // Default is Lebanon
    // checkedOrdersToPrint: number[] = [];
    
    loadingOrders: boolean = false;
    orderLabelDialogRef: MatDialogRef<OrderLabelComponent>;
    storerKey;
    bulkSettings;
    isMFERP;

    constructor(private _formBuilder: FormBuilder, private _quoteService: QuoteService, private _commodityService: CommodityService,
        private _orderService: OrderService, public _matDialog: MatDialog, private _router: Router,
        public matDialogRef: MatDialogRef<BulkOrderDialogComponent>, private _contactService: ContactsContactListService, private _orderViewService: OrderViewService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.countries = _data.countries;
        this.cities = _data.cities;
        this.domesticCountryId = _data.domesticCountryId;
    }
    BulkOrderForm: FormGroup;
    ngOnInit(): void {
        this.storerKey = localStorage.getItem('netPointKey');
        this.bulkSettings = localStorage.getItem('bulkSettings');
        this.isMFERP = localStorage.getItem("isMFERP");
        console.log(this.isMFERP);
        this.totalOrders = 0;
        this.BulkOrderForm = this._formBuilder.group({
            shippingTypeId: ['', [Validators.required]],
            // commodityId: ['', [Validators.required]],
            // goodsDescription: ['', [Validators.required]],
            collectionDate: [this.minDate, [Validators.required]],
            timeRangeLookupId: ['', [Validators.required]],
            specialInstructions: ['', null]
        })
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        this.prepareShippingTypes();
        this.getAllShipperReferences();
        this.getTimeRanges();
        this.getAllContacts();
        this.subscribeShippingTypeChanged();
        this._quoteService.FetchStockAsync(this.storerKey, "all").subscribe(sku => {
            this.listSkus = sku;
        });
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key }));
    }
    public getAllContacts(): void {
        this._contactService.getContactsForImport().then(contacts => {
            this.contacts = contacts;
        });
    }
    public getAllShipperReferences(): void {
        this._contactService.getShipperReferences().then(references => {
            this.shipperReferences = references;

        });
    }

    private prepareShippingTypes(direction: ServiceDirection = ServiceDirection.Domestic, serviceType: ServiceType = ServiceType.Parcels): void {
        this._quoteService.getShippingTypes(direction, serviceType).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
            this.shippingTypes = result;
            if (this.shippingTypes && (this.shippingTypes.length === 1 || this.shippingTypes.length === 2)) {
                this.BulkOrderForm.get('shippingTypeId').setValue(this.shippingTypes[0].id);
                // if (this.orderSurcharges.length == 0) {
                //     this.getSurchargesFromTarif(this.shippingTypes[0].id);
                // }
            }
        });
    }
    private getTimeRanges(): void {
        this._orderService.getTimeRanges().subscribe(result => {
            this.timeRanges = result
            if (this.timeRanges.length > 0) {
                this.BulkOrderForm.get('timeRangeLookupId').setValue(this.timeRanges[0].id);
            }
        });
    }
    get formValue(): any {
        return this.BulkOrderForm.value;
    }
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    private subscribeShippingTypeChanged(): void {
        this.BulkOrderForm
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.selectedDirection = ServiceDirection[this.shippingTypes.find(x => x.id == value).directionId];
                this.selectedDirectionId = this.shippingTypes.find(x => x.id == value).directionId;
                // this.registerForm.get('confirmPassword').updateValueAndValidity();
            });
    }
    import(): void {
        if (this.BulkOrderForm.invalid) {
            this.BulkOrderForm.markAllAsTouched();
            return;
        }
        else {
            const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
            e.click();
        }

    }
    onFileChange(evt: any, files: any) {
        this.importingOrders = true;
        this.ordersToImport = [];
        this.exceldataSource = new MatTableDataSource<any>(this.ordersToImport);
        const target: DataTransfer = <DataTransfer> (evt.target);
        if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            // var a = XLSX.utils.sheet_to_html(ws);
            this.data = <AOA> (XLSX.utils.sheet_to_json(ws, { header: 1 }));
            this.data = this.data.filter((entry) => { return entry.length != 0 })
            if (this.verifyExcelTemplate(this.data)) {
                if (this.bulkSettings === 'Old') {
                    this.generateExcelDatatable();
                }
                else if (this.bulkSettings === 'New') {
                    this.generateExcelDatatableNEW();
                }
            }
            else {
                this.openInvalidExcelDialog();
            }
        };
        reader.readAsBinaryString(target.files[0]);
        const formData = new FormData();
        if (files[0]) {
            formData.append(files[0].name, files[0]);
        }
        this._orderService.uploadBulkExcelAttachment(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(x => {
        })
        evt.target.value = '';
    }
    private openInvalidExcelDialog(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.header = 'Invalid Excel Format imported';
        this.confirmDialogRef.componentInstance.confirm = 'Template';
        this.confirmDialogRef.componentInstance.cancel = 'Cancel';

        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that the excel imported is invalid. Kindly use the template!';
        this.confirmDialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                this.exportExcel();
            }
        });
    }
    exportExcel(): void {
        console.log(this.domesticCountryId);
        var filename;
        if (this.bulkSettings === 'New') {
            filename = "MyNet Order Import Template - Lebanon NEW.xlsx"
            if (this.domesticCountryId == this.jordanId) {
                filename = "MyNet Order Import Template - Jordan NEW.xlsx"
            }
            else if (this.domesticCountryId == this.egyptId) {
                filename = "MyNet Order Import Template - Egypt NEW.xlsx"
            }
            else if (this.domesticCountryId == this.uaeId) {
                filename = "MyNet Order Import Template - UAE NEW.xlsx"
            }
        }
        else {
            filename = "MyNet Order Import Template - Lebanon.xlsx"
            if (this.domesticCountryId == this.jordanId) {
                filename = "MyNet Order Import Template - Jordan.xlsx"
            }
            else if (this.domesticCountryId == this.egyptId) {
                filename = "MyNet Order Import Template - Egypt.xlsx"
            }
            else if (this.domesticCountryId == this.uaeId) {
                filename = "MyNet Order Import Template - UAE.xlsx"
            }
        }
        let link = document.createElement("a");
        link.download = filename;
        // link.href = "assets/MyNet Order Import Template.xlsx";
        link.href = "assets/" + filename;
        link.click();
    }
    assignExcelIndexes(data): void {
        this.ShipperReferenceIndex = data[0].findIndex(item => item.toLowerCase() == "shipper reference");
        this.ShipperIndex = data[0].findIndex(item => item.toLowerCase() == "shipper");
        this.FirstNameIndex = data[0].findIndex(item => item.toLowerCase() == "first name");
        this.LastNameIndex = data[0].findIndex(item => item.toLowerCase() == "last name");
        this.CompanyNameIndex = data[0].findIndex(item => item.toLowerCase() == "company name");
        this.EmailIndex = data[0].findIndex(item => item.toLowerCase() == "email");
        this.CountryCodeIndex = data[0].findIndex(item => item.toLowerCase() == "mobilecountrycode");
        this.MobileIndex = data[0].findIndex(item => item.toLowerCase() == "mobile");
        this.CountryIndex = data[0].findIndex(item => item.toLowerCase() == "country");
        this.CityIndex = data[0].findIndex(item => item.toLowerCase() == "city");
        this.StreetIndex = data[0].findIndex(item => item.toLowerCase() == "street");
        this.BuildingIndex = data[0].findIndex(item => item.toLowerCase() == "building");
        this.FloorIndex = data[0].findIndex(item => item.toLowerCase() == "floor");
        this.LandMarkIndex = data[0].findIndex(item => item.toLowerCase() == "landmark");
        this.SpecialIndex = data[0].findIndex(item => item.toLowerCase() == "special instructions");
        this.WeightIndex = data[0].findIndex(item => item.toLowerCase() == "weight");
        this.NOPIndex = data[0].findIndex(item => item.toLowerCase() == "nop");
        this.CodIndex = data[0].findIndex(item => item.toLowerCase() == "cod amount");
        this.CodCurrencyIndex = data[0].findIndex(item => item.toLowerCase() == "cod currency");
        this.Cod2Index = data[0].findIndex(item => item.toLowerCase() == "cod amount 2");
        this.CodCurrency2Index = data[0].findIndex(item => item.toLowerCase() == "cod currency 2");
        this.SkuIndex = data[0].findIndex(item => item.toLowerCase() == "skus");
        if (this.bulkSettings === 'New') {
            this.SkuQtyIndex = data[0].findIndex(item => item.toLowerCase() == "sku quantity");
        }
        this.multiframesLabelIndex = data[0].findIndex(item => item.toLowerCase() == "multiframes label")
        console.log(this.CompanyNameIndex);
    }
    verifyExcelTemplate(data): boolean {
        this.assignExcelIndexes(data);
        if (this.bulkSettings === 'New') {
            if (data.length > 0) {
                if (this.ShipperIndex == -1 || this.FirstNameIndex == -1 || this.LastNameIndex == -1 || this.CountryCodeIndex == -1 || this.MobileIndex == -1
                    || this.CityIndex == -1 || this.StreetIndex == -1 || this.WeightIndex == -1 || this.CodIndex == -1 || this.CodCurrencyIndex == -1 || this.SkuQtyIndex == -1
                    || this.multiframesLabelIndex == - 1) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }
        else if (this.bulkSettings === 'Old') {
            if (data.length > 0) {
                if (this.ShipperIndex == -1 || this.FirstNameIndex == -1 || this.LastNameIndex == -1 || this.CountryCodeIndex == -1 || this.MobileIndex == -1
                    || this.CityIndex == -1 || this.StreetIndex == -1 || this.WeightIndex == -1 || this.CodIndex == -1 || this.CodCurrencyIndex == -1 || this.SkuQtyIndex == -1
                    || this.multiframesLabelIndex == - 1) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }
    }
    generateExcelDatatableNEW() {
        this.ordersToImport = [];
        let id = 0;

        this.data.slice(1, this.data.length).forEach(order => {
            let o = new OrderExcelListItem();

            o.isValid = true;
            o.importId = id;

            o.shipperReference = order[this.ShipperReferenceIndex] || '';
            o.multiframesLabel = '';
            if (this.isMFERP) {
                o.multiframesLabel = order[this.multiframesLabelIndex] || '';
            }

            var checkReferenceIfFound = this.shipperReferences.findIndex(x => x.toLowerCase() == o.shipperReference.toString().toLowerCase())
            if (checkReferenceIfFound >= 0) {
                o.isValid = false;
            }
            // o.shipperReference = '';
            o.shipper = order[this.ShipperIndex] || '';

            if (this.contacts.find(x => x.fullName.trim().toLowerCase() == o.shipper.trim().toLowerCase())) {
                const shipperContact = this.contacts.find(x => x.fullName.trim().toLowerCase() == o.shipper.trim().toString().toLowerCase());

                o.shipperId = shipperContact.contactId;
                o.shipperCityId = shipperContact.cityId;
                o.shipperAddressId = shipperContact.addressId;
            }
            else {
                o.shipperId = null;
            }


            o.firstName = order[this.FirstNameIndex] || '';
            o.lastName = order[this.LastNameIndex];
            o.companyName = order[this.CompanyNameIndex];
            o.phoneCountryCode = order[this.CountryCodeIndex];

            if (isNullOrUndefined(o.lastName)) {
                o.lastName = "";
            }



            if (o.phoneCountryCode == undefined) {
                o.phoneCountryCode = this.countries.find(x => x.countryId == this.domesticCountryId).countryPhoneCode;
            }
            if (o.phoneCountryCode.toString().indexOf("+") == -1) {
                o.phoneCountryCode = "+" + o.phoneCountryCode;
            }



            o.phoneNumber = order[this.MobileIndex];
            // o.addresses[0].countryName = "Lebanon";
            o.addresses[0].countryName = this.countries.find(x => x.countryId == this.domesticCountryId).name;
            o.addresses[0].cityName = order[this.CityIndex];
            o.addresses[0].cityName = isNullOrUndefined(o.addresses[0].cityName) ? undefined : o.addresses[0].cityName.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
            o.addresses[0].street = order[this.StreetIndex];
            o.addresses[0].building = order[this.BuildingIndex];
            o.addresses[0].floor = order[this.FloorIndex];
            o.addresses[0].landmark = order[this.LandMarkIndex];
            o.specialInstructions = order[this.SpecialIndex];

            o.weight = order[this.WeightIndex];
            o.nop = order[this.NOPIndex];
            o.SKUs[0] = order[this.SkuIndex];
            o.SKUs[0] = isNullOrUndefined(o.SKUs[0]) ? undefined : o.SKUs[0].replace(/^\s+|\s+$|\s+(?=\s)/g, '');
            o.SKUQty = order[this.SkuQtyIndex];
            if (o.nop == undefined || o.nop.toString() === '' || isNullOrUndefined(o.nop) || /\s/.test(o.nop.toString())) {
                o.nop = 1;
            }

            if (typeof (o.weight) !== 'number') {
                o.weight = 0.5;
            }

            if (o.weight == undefined) {
                o.weight = 0.5;
            }
            // o.shipmentValue = order[15];
            // o.shipmentCurrency = order[16];
            // if (isNullOrUndefined(this.currencyTypes.find(element => element.title == o.shipmentCurrency)))
            //     o.shipmentCurrency = 'LBP';
            // o.shipmentCurrencyId = this.currencyTypes.find(element => element.title == o.shipmentCurrency).value;

            o.codAmount = order[this.CodIndex];
            o.codCurrency = order[this.CodCurrencyIndex];
            o.codCurrency = isNullOrUndefined(o.codCurrency) ? o.codCurrency : o.codCurrency.replace(/[ \t]/g, '');

            o.codAmount2 = order[this.Cod2Index];
            o.codCurrency2 = order[this.CodCurrency2Index];
            o.codCurrency2 = isNullOrUndefined(o.codCurrency2) ? o.codCurrency2 : o.codCurrency2.replace(/[ \t]/g, '');

            if (!isNullOrUndefined(o.codCurrency)) {
                o.codCurrency = o.codCurrency.toLowerCase();
            }
            if (!isNullOrUndefined(o.codCurrency2)) {
                o.codCurrency2 = o.codCurrency2.toLowerCase();
            }
            if (isNullOrUndefined(this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency))) {
                o.codCurrency = 'lbp';
            }
            o.codCurrencyId = this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency).value;

            if (!isNullOrUndefined(this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency2))) {
                o.codCurrencyId2 = this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency2).value;
            }
            
            o = this.validateExcelOrder(o);
            this.ordersToImport.push(o);
            id += 1;
        });
        var shipperReferenceList: any[] = [];
        for (let i = 0; i < this.ordersToImport.length; i++) {
            if (shipperReferenceList.find(x => x.toString() === this.ordersToImport[i].shipperReference)) {
                continue;
            }
            else {
                shipperReferenceList.push(this.ordersToImport[i].shipperReference);
            }
        }
        for (let i = 0; i < shipperReferenceList.length; i++) {
            const givenSku: BulkSkuStock[] = [];
            const duplicatedCheck = {};
            const filteredOrder = this.ordersToImport.filter(x => x.shipperReference === shipperReferenceList[i]);
            filteredOrder.forEach(order => {
                if (!isNullOrUndefined(this.storerKey) && (!isNullOrUndefined(order.SKUs[0]))) {
                    givenSku.push({orderRef: order.shipperReference, name: order.SKUs[0], currentStock: order.SKUQty});
                }
            });
            if (givenSku.length > 0) { // SKU not empty
                givenSku.forEach(sku => {
                    if (duplicatedCheck.hasOwnProperty(sku.name)) {
                        duplicatedCheck[sku.name] = true;
                        this.hasDuplicatedSKUs = duplicatedCheck[sku.name];
                    }
                    else {
                        duplicatedCheck[sku.name] = false;
                    }
                });
                filteredOrder.forEach(order => {
                    order.invalidSku = [];
                    if (!this.listSkus.some(
                        existingSku => order.SKUs[0] === existingSku.name && 
                        order.SKUQty <= existingSku.currentStock)) {
                        order.isValid = false;
                        order.invalidSku.push({name: 'Invalid SKU', currentStock: NaN});
                    }
                });
                if (this.hasDuplicatedSKUs) {
                    filteredOrder.forEach(order => {
                        if (duplicatedCheck[order.SKUs[0]]) {
                            order.isValid = false;
                            order.invalidSku.push({name : 'ERROR DUPLICATE', currentStock : NaN});
                        }
                    });
                }
            }
            else { // SKU empty here
                filteredOrder.forEach(order => {
                    order.isValid = false;
                    order.invalidSku = [];
                    order.invalidSku.push({name: 'Empty SKU', currentStock: NaN});
                });
            }
            filteredOrder.forEach(order => {
                if (!isNullOrUndefined(order.shipperReference) && order.shipperReference !== '') {
                    order.firstName = filteredOrder[0].firstName;
                    order.lastName = filteredOrder[0].lastName;
                    order.shipperId = filteredOrder[0].shipperId;
                    order.shipperCityId = filteredOrder[0].shipperCityId;
                    order.shipperAddressId = filteredOrder[0].shipperAddressId;
                    order.shipper = filteredOrder[0].shipper;
                    order.shipperId = filteredOrder[0].shipperId;
                    order.addresses = filteredOrder[0].addresses;
                    order.phoneCountryCode = filteredOrder[0].phoneCountryCode;
                    order.phoneNumber = filteredOrder[0].phoneNumber;
                    order.weight = filteredOrder[0].weight;
                    order.nop = filteredOrder[0].nop;
                    order.codAmount = filteredOrder[0].codAmount;
                    order.codCurrency = filteredOrder[0].codCurrency;
                    order.codCurrencyId = filteredOrder[0].codCurrencyId;
                    order.codAmount2 = filteredOrder[0].codAmount2;
                    order.codCurrency2 = filteredOrder[0].codCurrency2;
                    order.codCurrencyId2 = filteredOrder[0].codCurrencyId2;
                    if (!isNullOrUndefined(order.invalidSku) && order.invalidSku.length === 0) {
                        order.isValid = true;
                    }
                    if (isNullOrUndefined(filteredOrder[0].addresses[0].cityId)) {
                        order.isValid = false;
                    }
                }
            });
            filteredOrder.forEach(order => {
                var checkReferenceIfFound = this.shipperReferences.findIndex(x => x.toLowerCase() == order.shipperReference.toString().toLowerCase())
                if (checkReferenceIfFound >= 0) {
                    order.isValid = false;
                }
                if (order.shipper === '') {
                    order.isValid = false;
                }
            });
        }
        this.openImportDialog();
    }

    generateExcelDatatable() {
        this.ordersToImport = [];
        let id = 0;
        console.log(this.data.slice(1, this.data.length));

        this.data.slice(1, this.data.length).forEach(order => {
            let o = new OrderExcelListItem();

            o.isValid = true;
            o.importId = id;

            o.shipperReference = order[this.ShipperReferenceIndex] || '';
            o.multiframesLabel = '';
            if (this.isMFERP) {
                o.multiframesLabel = order[this.multiframesLabelIndex] || '';
            }

            var checkReferenceIfFound = this.shipperReferences.findIndex(x => x.toLowerCase() == o.shipperReference.toString().toLowerCase())
            if (checkReferenceIfFound >= 0) {
                o.isValid = false;
            }
            // o.shipperReference = '';
            o.shipper = order[this.ShipperIndex] || '';

            if (this.contacts.find(x => x.fullName.trim().toLowerCase() == o.shipper.trim().toLowerCase())) {
                const shipperContact = this.contacts.find(x => x.fullName.trim().toLowerCase() == o.shipper.trim().toString().toLowerCase());

                o.shipperId = shipperContact.contactId;
                o.shipperCityId = shipperContact.cityId;
                o.shipperAddressId = shipperContact.addressId;
            }
            else {
                o.shipperId = null;
            }


            o.firstName = order[this.FirstNameIndex] || '';
            o.lastName = order[this.LastNameIndex];
            o.companyName = order[this.CompanyNameIndex];
            o.phoneCountryCode = order[this.CountryCodeIndex];

            if (isNullOrUndefined(o.lastName)) {
                o.lastName = "";
            }



            if (o.phoneCountryCode == undefined) {
                o.phoneCountryCode = this.countries.find(x => x.countryId == this.domesticCountryId).countryPhoneCode;
            }
            if (o.phoneCountryCode.toString().indexOf("+") == -1) {
                o.phoneCountryCode = "+" + o.phoneCountryCode;
            }



            o.phoneNumber = order[this.MobileIndex];
            // o.addresses[0].countryName = "Lebanon";
            o.addresses[0].countryName = this.countries.find(x => x.countryId == this.domesticCountryId).name;
            o.addresses[0].cityName = order[this.CityIndex];
            o.addresses[0].cityName = isNullOrUndefined(o.addresses[0].cityName) ? undefined : o.addresses[0].cityName.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
            o.addresses[0].street = order[this.StreetIndex];
            o.addresses[0].building = order[this.BuildingIndex];
            o.addresses[0].floor = order[this.FloorIndex];
            o.addresses[0].landmark = order[this.LandMarkIndex];
            o.specialInstructions = order[this.SpecialIndex];

            o.weight = order[this.WeightIndex];
            o.nop = order[this.NOPIndex];
            if (order[this.SkuIndex] === undefined) {
                o.SKUs = [];
                o.invalidSku = [];
                o.skus = [];
            }
            else {
                let matched;
                o.SKUs = order[this.SkuIndex].split(',');
                const givenSkus: SkuStock[] = [];
                const duplicatedCheck = {};
                const regex = /^(.*?)(?:\((\d+)\))?$/i;
                for (let i = 0; i < o.SKUs.length; i++) {
                    matched = o.SKUs[i].match(regex);
                    givenSkus.push({name : matched[1], currentStock : parseInt(matched[2]) || 0});
                    o.SKUs[i] = matched[1] + "(" + matched[2] + ")";
                    o.invalidSku = givenSkus.filter(
                        givenSku => !this.listSkus.some(
                            existingSku => givenSku.name === existingSku.name && 
                            givenSku.currentStock <= existingSku.currentStock));
                    if (isNullOrUndefined(matched[2])) {
                        o.invalidSku.push({name: matched[1], currentStock: matched[2]});
                        console.log(o.invalidSku);
                    }
                }
                this.hasDuplicatedSKUs = givenSkus.some(duplicateSku => {
                    if (duplicatedCheck.hasOwnProperty(duplicateSku.name)) {
                        return true;
                    }
                    return (duplicatedCheck[duplicateSku.name] = false);
                });
                if (this.hasDuplicatedSKUs) {
                    o.isValid = false;
                    o.invalidSku.push({name : "ERROR DUPLICATE", currentStock : NaN});
                }
                if (o.invalidSku.length > 0) {
                    o.isValid = false;
                }
            }

            console.log(this.NOPIndex);
            console.log(order);
            console.log(o.nop);
            if (o.nop == undefined || o.nop.toString() === '' || isNullOrUndefined(o.nop) || /\s/.test(o.nop.toString())) {
                o.nop = 1;
            }

            if (typeof (o.weight) !== 'number') {
                o.weight = 0.5;
            }

            if (o.weight == undefined) {
                o.weight = 0.5;
            }

            if (o.addresses[0].building != undefined) {
                if (o.addresses[0].building.length > 300) {
                    o.isValid = false;
            }
                }
            if (o.addresses[0].street != undefined) {
                if (o.addresses[0].street.length > 500) {
                    o.isValid = false;
            }
                }
            if (o.addresses[0].landmark != undefined) {
                if (o.addresses[0].landmark.length > 1000) {
                    o.isValid = false;
            }
                }
            // o.shipmentValue = order[15];
            // o.shipmentCurrency = order[16];
            // if (isNullOrUndefined(this.currencyTypes.find(element => element.title == o.shipmentCurrency)))
            //     o.shipmentCurrency = 'LBP';
            // o.shipmentCurrencyId = this.currencyTypes.find(element => element.title == o.shipmentCurrency).value;

            o.codAmount = order[this.CodIndex];
            o.codCurrency = order[this.CodCurrencyIndex];
            o.codCurrency = isNullOrUndefined(o.codCurrency) ? o.codCurrency : o.codCurrency.replace(/[ \t]/g, '');

            o.codAmount2 = order[this.Cod2Index];
            o.codCurrency2 = order[this.CodCurrency2Index];
            o.codCurrency2 = isNullOrUndefined(o.codCurrency2) ? o.codCurrency2 : o.codCurrency2.replace(/[ \t]/g, '');

            if (!isNullOrUndefined(o.codCurrency)) {
                o.codCurrency = o.codCurrency.toLowerCase();
            }
            if (!isNullOrUndefined(o.codCurrency2)) {
                o.codCurrency2 = o.codCurrency2.toLowerCase();
            }
            if (isNullOrUndefined(this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency))) {
                o.codCurrency = 'lbp';
            }
            o.codCurrencyId = this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency).value;

            if (!isNullOrUndefined(this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency2))) {
                o.codCurrencyId2 = this.currencyTypes.find(element => element.title.toLowerCase() == o.codCurrency2).value;
            }
            
            o = this.validateExcelOrder(o);
            this.ordersToImport.push(o);
            id += 1;
        });
        this.openImportDialog();
    }
    public validateExcelOrder(order: OrderExcelListItem): OrderExcelListItem {
        if (order.shipper == '' || order.shipperId == null) {
            order.isValid = false;
        }
        if (order.weight <= 0) {
            order.weight = 0.5;
        }

        const contactAddress = order.addresses[0];
        if (contactAddress.cityName == '' || contactAddress.countryName == '' || contactAddress.street == undefined) {
            order.isValid = false;
        }

        if (order.firstName == '' || order.lastName == '') {
            order.isValid = false;
        }

        var countryIdRetreived = this.countries.find(x => x.name.toString().toLowerCase() == order.addresses[0].countryName.toString().toLowerCase());
        if (!isNullOrUndefined(countryIdRetreived)) {
            order.addresses[0].countryId = countryIdRetreived.countryId;
            // order.addresses[0].cities = this.cities.filter(x => x.countryId == countryIdRetreived.countryId);
        }
        else {
            countryIdRetreived = this.countries.find(x => x.countryId == 158);
            order.addresses[0].countryId = 158;
            // order.addresses[0].cities = this.cities.filter(x => x.countryId == 158);
        }
        if (order.addresses[0].cityName == undefined) {
            order.addresses[0].cityName = "";
        }
        var cityIdRetreived = this.cities.find(x => x.cityName.toString().toLowerCase() == order.addresses[0].cityName.toString().toLowerCase());

        if (!isNullOrUndefined(cityIdRetreived)) {
            order.addresses[0].cityId = cityIdRetreived.cityId;
        }
        else {
            order.isValid = false;
        }
        if (order.phoneNumber == undefined) {
            order.phoneNumber = "";
        }
        const mobile = order.phoneNumber;


        var phoneCountryId = this.countries.find(x => x.countryPhoneCode == order.phoneCountryCode);
        if (phoneCountryId) {
            const mobile = order.phoneNumber;
            const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), phoneCountryId.countryReference);

            if (validatedPhone == '') {
                //     order.phoneNumber = validatedPhone
                // else {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }
        if (!isNullOrUndefined(order.codAmount)) {
            if (!this.isNumber(order.codAmount)) {
                order.isValid = false;
            }
        }
        else {
            order.codAmount = 0;
        }
        if (isNullOrUndefined(this.countries.find(x => x.countryPhoneCode == order.phoneCountryCode))) {
            order.phoneCountryCode = '';
            order.isValid = false;
        }
        // const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
        // if (validatedPhone != '')
        //     order.phoneNumber = validatedPhone
        // else {
        //     order.phoneNumber = '';
        //     order.isValid = false;
        // }

        return order;
    }
    isNumber(value) {
        var regex = /^\d*\.?\d+$/;
        var match = regex.exec(value);
        return match != null;
    }
    openImportDialog(): void {
        
        this.importOrderDialogRef = this._matDialog.open(ImportOrderComponent
            , {
                disableClose: true,
                panelClass: 'import-order',
                data: {
                    orders: this.ordersToImport.sort(function (x, y) {
                        return (x.isValid === y.isValid) ? 0 : x ? -1 : 1;
                    }),
                    countries: this.countries,
                    cities: this.cities,
                    contacts: this.contacts,
                    shipperReferences: this.shipperReferences,
                    existingSkusList: this.listSkus,
                }
            }
        );
        this.importOrderDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }

            const status = response[0] as string;

            if (status === 'cancel') {
                this.importingOrders = false;
                return;
            }


            const contactOrders = response[1];
            this.bulkOrderParams = this.BulkOrderForm.value;
            this.importingOrders = true;


            this.orderIdsToImport = [];
            this.importingBulk = true;
            this.BulkOrderCod = [];
            this.BulkQuoteDetails = [];
            if (this.bulkSettings === 'Old') {
                this.totalOrders += contactOrders.length;
                this.prepareBulkOrders(contactOrders).then(a => {
                }
                );
            }
            else if (this.bulkSettings === 'New') {
                var shipperReferenceList: any[] = [];
                for (let i = 0; i < contactOrders.length; i++) {
                    if (shipperReferenceList.find(x => x.toString() === contactOrders[i].shipperReference.toString())) {
                        continue;
                    }
                    else {
                        shipperReferenceList.push(contactOrders[i].shipperReference);
                    }
                }
                this.totalOrders += shipperReferenceList.length;
                this.prepareBulkOrdersNew(contactOrders).then(a => {
                }
                );
            }
            // for (let i = 0; i < contactOrders.length; i + this.BulkBatchLength) {

            //     // setTimeout(async function () {
            //     var items = contactOrders.slice(i, i + this.BulkBatchLength);
            //     console.log(items.length);
            //     // var b = this.createSplitBulkOrders(items, items.length);
            //     // console.log(b);

            //     // }.bind(this), interval * i);
            // }

            // var a = this.createSplitBulkOrders(contactOrders, contactOrders.length);




            // contactOrders.forEach(function (contactOrder, index) {

            //     setTimeout(function () {
            //         this.createBulkOrders(contactOrder, contactOrders.length);

            //     }.bind(this), (index) * interval);
            // }.bind(this));
            // const getData = async (data) => {
            //     return await data;
            // }


            this.importingOrders = false;
        }

        );
        this.importOrderDialogRef = null;

    }

    async prepareBulkOrders(contactOrders): Promise<boolean> {
        var interval = 500;
        for (let i = 0; i < contactOrders.length; i = i + this.BulkBatchLength) {
            setTimeout(function () {
                if (navigator.onLine) {
                    console.log('online');
                } else {
                    console.log('offline');
                }
                var items = contactOrders.slice(i, i + this.BulkBatchLength);
                var b = this.createSplitBulkOrders(items, items.length);
                if (b.length > 0) {
                    this._orderService.addBulkOrderDetails(b).pipe(takeUntil(this._unsubscribeAll)).subscribe(orderResult => {
                        this.BulkOrderDetails = [];
                        this.bulkOrdersImported = this.bulkOrdersImported.concat(orderResult.value);
                        console.log('Batch done ' + orderResult.value.length);
                    });
                }
            }.bind(this), interval * i);
        }
        return true;
    }

    async prepareBulkOrdersNew(contactOrders): Promise<boolean> {
        var shipperReferenceList: any[] = [];
        for (let i = 0; i < contactOrders.length; i++) {
            if (shipperReferenceList.find(x => x.toString() === contactOrders[i].shipperReference.toString())) {
                continue;
            }
            else {
                shipperReferenceList.push(contactOrders[i].shipperReference);
            }
        }
        for (let i = 0; i < shipperReferenceList.length; i++) {
            var filteredOrders = contactOrders.filter(x => x.shipperReference === shipperReferenceList[i]);
            var b = this.createSplitBulkOrdersNew(filteredOrders, filteredOrders.length);
            if (b.length > 0) {
                this._orderService.addBulkOrderDetails(b).pipe(takeUntil(this._unsubscribeAll)).subscribe(orderResult => {
                    this.BulkOrderDetails = [];
                    this.bulkOrdersImported = this.bulkOrdersImported.concat(orderResult.value);
                    console.log('Order done:' + orderResult.value);
                });
            }
        }
        return true;
        // var interval = 500;
        // let index = 0;
        // for (let i = 0; i < contactOrders.length; i = i + this.BulkBatchLength) {
        //     console.log(i);
        //     var filteredOrder = contactOrders.filter(x => x.shipperReference === shipperReferenceList[index]);
        //     console.log(filteredOrder);
        //     index++;
        //     setTimeout(function () {
        //         if (navigator.onLine) {
        //             console.log('online');
        //         } else {
        //             console.log('offline');
        //         }
        //         var items = contactOrders.slice(i, i + this.BulkBatchLength);
        //         var b = this.createSplitBulkOrdersNew(items, filteredOrder.length);
        //         console.log(b);
        //         return null;
        //         if (b.length > 0) {
        //             this._orderService.addBulkOrderDetails(b).pipe(takeUntil(this._unsubscribeAll)).subscribe(orderResult => {
        //                 this.BulkOrderDetails = [];
        //                 this.bulkOrdersImported = this.bulkOrdersImported.concat(orderResult.value);
        //                 console.log('Batch done ' + orderResult.value.length);
        //             });
        //         }
        //     }.bind(this), interval * i);
        // }
        // return true;
    }

    printAll() {
        this.matDialogRef.close(['print']);
        // if (this.bulkOrdersImported.length == 1)
        //     this._router.navigateByUrl(Routes.PrintInvoice + "/" + this.bulkOrdersImported.toString());
        // else
        this._router.navigateByUrl(Routes.PrintOrders + "/" + this.bulkOrdersImported.toString());

    }

    printCheckedLabels() {
        // if (orderid != undefined)
        //     this.checkedOrdersToPrint.push(orderid);
        this.viewOrderLabel(this.bulkOrdersImported);
    }

    viewOrderLabel(order: any): void {
        console.log(order);
        // if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length == 1) {
        //     return;
        // }
        this.loadingOrders = true;
        this._orderViewService.generateLabel(order.toString()).then(x => {
            console.log(x.data);
            this.loadingOrders = false;
            this.orderLabelDialogRef = this._matDialog.open(OrderLabelComponent, {
                panelClass: 'order-label',
                data: {
                    orderIds: order.toString(),
                    url: x.data
                }
            });
        })

    }

    createBulkOrders(contactOrder, totalCount) {
        {
            const shipper = contactOrder as OrderExcelListItem;
            const contact = contactOrder as Contact;

            var quote = new DomesticQuote();
            const getData = async (data) => {
                return await data;
            }
            // var pShipper = this._contactService.getContact(shipper.shipperId);
            var pReceiver = this._contactService.addOrderContact(contact);
            // getData(pShipper).then(Shipperr => {
            //     var shipperContact = Shipperr;
            getData(pReceiver).then(Receiver => {
                var receiverContact = Receiver;
                quote.fromCityId = shipper.shipperCityId;
                quote.toCityId = receiverContact.addresses[0].cityId;
                quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
                quote.weight = contactOrder.weight;
                quote.chargeableWeight = contactOrder.weight;
                quote.numberOfPieces = contactOrder.nop;
                quote.shipmentValue = contactOrder.shipmentValue;
                quote.shipperReference = contactOrder.shipperReference;
                var pPrice = this._quoteService.generateDomesticQuote(quote as DomesticQuote).toPromise();
                getData(pPrice).then(quotePrice => {
                    var data = new OrderDetail();
                    var qdata = quote as QuoteData;
                    qdata.fromCountryId = 158; // Lebanon
                    qdata.toCountryId = 158; // Lebanon

                    var currencySelected = quotePrice.currency as String;
                    var currencyType: CurrencyType = <CurrencyType> CurrencyType[currencySelected.toString()];
                    qdata.quotePrice = quotePrice.price;
                    qdata.quoteCurrency = quotePrice.currency;
                    qdata.quoteCurrencyId = currencyType;



                    // qdata.quotePrice = 0;
                    // qdata.quoteCurrency = "LBP";
                    // qdata.quoteCurrencyId = 1;
                    qdata.cods = [];
                    const cod: CashOnDelivery = {
                        quoteId: 0,
                        codAmount: contactOrder.codAmount,
                        codCurrencyId: contactOrder.codCurrencyId
                    };
                    qdata.cods.push(cod);



                    data.quote = qdata;
                    data.shipperId = shipper.shipperId;
                    data.shipperAddressId = shipper.shipperAddressId;
                    data.receiverId = receiverContact.contactId;
                    data.receiverAddressId = receiverContact.addresses[0].addressId;
                    // TODO
                    data.quote.type = 2; // Domestic;
                    data.timeRangeLookupId = this.bulkOrderParams.timeRangeLookupId;
                    data.quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
                    data.commodityId = 16234;  // Other
                    data.pickupDate = this.bulkOrderParams.collectionDate;
                    data.specialInstructions = this.bulkOrderParams.specialInstructions;
                    data.goodsDescription = this.bulkOrderParams.goodsDescription;
                    data.specialInstructions = contactOrder.specialInstructions;
                    data.quote.shippingType = this.shippingTypes.find(x => x.id == this.bulkOrderParams.shippingTypeId).name;

                    // var pOrder = this._orderService.addOrderDetails(data);

                    this.BulkOrderDetails.push(data);
                    // this.BulkQuoteDetails.push(data.quote)
                    this.orderIdsToImport.push(data.orderId);

                    if (this.BulkOrderDetails.length == totalCount) {

                        for (let i = 0; i < this.BulkOrderDetails.length; i = i + this.BulkBatchLength) {
                            var items = this.BulkOrderDetails.slice(i, i + this.BulkBatchLength);
                            var pOrder = this._orderService.addBulkOrderDetails(items);
                            getData(pOrder).then(orderResult => {
                                // console.log(orderResult);
                                this.importingBulk = false;
                                this.bulkOrdersImported = this.bulkOrdersImported.concat(orderResult.value);
                                // this.bulkOrdersImported.push(orderResult.value);

                            });
                        }
                        // var pPrice = this._quoteService.generateDomesticBulkQuote(this.BulkQuoteDetails).toPromise();
                        // getData(pPrice).then(quotePrice => {
                        //     console.log(quotePrice)
                        // });

                        // var pOrder = this._orderService.addBulkOrderDetails(this.BulkOrderDetails);
                        // getData(pOrder).then(orderResult => {
                        //     this.importingBulk = false;
                        //     this.bulkOrdersImported = orderResult.value;
                        //     console.log('Pricing now');
                        //     console.log(this.bulkOrdersImported);
                        //     // var pPrice = this._quoteService.generateDomesticBulkQuote(this.BulkQuoteDetails).toPromise();
                        //     // getData(pPrice).then(quotePrice => {
                        //     //     console.log(quotePrice)
                        //     // });
                        // });
                    }


                    // var pOrder = this._orderService.addOrderDetails(data);
                    // getData(pOrder).then(orderResult => {
                    //     var order = orderResult as BillingDetails;
                    //     const cod: CashOnDelivery = {
                    //         quoteId: order.quoteId,
                    //         codAmount: contactOrder.codAmount,
                    //         codCurrencyId: contactOrder.codCurrencyId
                    //     }
                    //     this.BulkOrderCod.push(cod);
                    //     // getData(pCod).then(codResult => {
                    //     this.orderIdsToImport.push(order.orderId);
                    //     this.bulkOrdersImported.push(order.orderId);
                    //     if (this.orderIdsToImport.length == totalCount) {
                    //         var pConfirm = this._orderService.ConfirmBulkOrder(this.orderIdsToImport);
                    //         getData(pConfirm).then((confirmResult) => {
                    //             this.importingBulk = false;
                    //             console.log(this.BulkOrderCod)
                    //             var pCod = this._quoteService.saveCodAmount(this.BulkOrderCod).toPromise();
                    //             getData(pCod).then(codResult => {
                    //             });
                    //             // var bulkOrderPayment = new BulkOrderPayment();
                    //             // bulkOrderPayment.orderId = confirmResult.orderId;
                    //             // bulkOrderPayment.erpOrderId = confirmResult.erpOrderId;
                    //             // bulkOrderPayment.label = confirmResult.label;
                    //         });
                    //     }
                    //     // });
                    // });
                });
            });
            // })









        }
    }
    async saveImportReceiver(contact) {
        return this._contactService.addOrderContact(contact);
    }
    async saveBulkOrderDetails(bulkOrderDetails) {
        return this._orderService.addBulkOrderDetails(bulkOrderDetails);
    }

    public createSplitBulkOrdersNew(contactOrders, totalCount): OrderDetail[] {
        // Create one OrderDetail object and return it in the end.
        // Example: if bulk has 3 orders, order 1 and 2 ref is 123 and order 3 ref is 456
        // MyNet should show only 2 labels and not 3 
        var a: OrderDetail[] = [];
        var skus: OrderSku[] = [];
        var data = new OrderDetail();
        var quote = new DomesticQuote();
        skus = [];
        var contactOrder;
        var cod = new CashOnDelivery();
        var cod2 = new CashOnDelivery();
        var shipper;
        var contact;
        for (let j = 0; j < contactOrders.length; j++) {
            contactOrder = contactOrders[j];
            shipper = contactOrder as OrderExcelListItem;
            contact = contactOrder as Contact;
            quote.fromCityId = shipper.shipperCityId;
            quote.toCityId = contact.addresses[0].cityId;
            quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
            quote.weight = contactOrder.weight;
            quote.chargeableWeight = contactOrder.weight;
            quote.numberOfPieces = contactOrder.nop;
            quote.shipmentValue = contactOrder.shipmentValue;
            quote.shipperReference = contactOrder.shipperReference;
            var qdata = quote as QuoteData;
            qdata.quotePrice = -1;
            qdata.quoteCurrency = 'LBP';
            qdata.quoteCurrencyId = 1;
            qdata.cods = [];
            qdata.multiframesLabel = contactOrder.multiframesLabel;
            if (!isNullOrUndefined(contactOrder.SKUs[0]) && contactOrder.SKUs[0] !== '') {
                const sku: OrderSku = {
                    quoteId: 0,
                    sku: contactOrder.SKUs[0],
                    quantity: contactOrder.SKUQty,
                    description: ''
                };
                skus.push(sku);
            }
            cod = {
                quoteId: 0,
                codAmount: contactOrder.codAmount,
                codCurrencyId: contactOrder.codCurrencyId
            };
            cod2 = {
                quoteId: 0,
                codAmount: contactOrder.codAmount2,
                codCurrencyId: contactOrder.codCurrencyId2
            };
        }
        if (cod.codAmount > 0) {
            qdata.cods.push(cod);
        }
        if (cod2.codAmount > 0) {
            qdata.cods.push(cod2);
        }
        if (skus.length > 0) {
            qdata.skus = skus;
        }
        qdata.fromCountryId = 158; // Lebanon
        qdata.toCountryId = 158; // Lebanon
        data.quote = qdata;
        data.shipperId = shipper.shipperId;
        data.shipperAddressId = shipper.shipperAddressId;
        data.quote.type = this.BulkOrderForm.get('shippingTypeId').value; // Domestic;
        data.quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
        data.quote.shippingType = this.shippingTypes.find(x => x.id == this.bulkOrderParams.shippingTypeId).name;
        data.receiverId = 0;
        data.receiverAddressId = 0;
        data.bulkReceiver = contact;
        data.timeRangeLookupId = this.bulkOrderParams.timeRangeLookupId;
        data.commodityId = 16234;  // Other
        data.pickupDate = this.bulkOrderParams.collectionDate;
        data.specialInstructions = this.bulkOrderParams.specialInstructions;
        data.goodsDescription = this.bulkOrderParams.goodsDescription;
        data.specialInstructions = contactOrder.specialInstructions;
        this.BulkOrderDetails.push(data);
        a.push(data);
        return a;
    }


    public createSplitBulkOrders(contactOrders, totalCount): OrderDetail[] {
        {
            var a: OrderDetail[] = [];
            for (let index = 0; index < contactOrders.length; index++) {
                // setTimeout(() => {
                const contactOrder = contactOrders[index];
                const shipper = contactOrder as OrderExcelListItem;
                const contact = contactOrder as Contact;
                var quote = new DomesticQuote();
                // var pReceiver = this._contactService.addOrderContact(contact);
                // getData(pReceiver).then(Receiver => {
                // this._contactService.addOrderContact(contact).subscribe(Receiver => {
                //     var receiverContact = Receiver;
                quote.fromCityId = shipper.shipperCityId;
                // TODO
                quote.toCityId = contact.addresses[0].cityId;

                quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
                quote.weight = contactOrder.weight;
                quote.chargeableWeight = contactOrder.weight;
                quote.numberOfPieces = contactOrder.nop;
                quote.shipmentValue = contactOrder.shipmentValue;
                quote.shipperReference = contactOrder.shipperReference;
                var data = new OrderDetail();
                var qdata = quote as QuoteData;


                qdata.quotePrice = -1;
                qdata.quoteCurrency = "LBP";
                qdata.quoteCurrencyId = 1;
                qdata.cods = [];
                qdata.multiframesLabel = contactOrder.multiframesLabel;

                const cod: CashOnDelivery = {
                    quoteId: 0,
                    codAmount: contactOrder.codAmount,
                    codCurrencyId: contactOrder.codCurrencyId
                };

                const cod2: CashOnDelivery = {
                    quoteId: 0,
                    codAmount: contactOrder.codAmount2,
                    codCurrencyId: contactOrder.codCurrencyId2
                };
                // TODO
                qdata.fromCountryId = 158; // Lebanon
                qdata.toCountryId = 158; // Lebanon
                if (contactOrder.codAmount > 0) {
                    qdata.cods.push(cod);
                }
                
                if (contactOrder.codAmount2 > 0) {
                    qdata.cods.push(cod2);
                }
                
                if (contactOrder.SKUs.length > 0) {
                    qdata.skus = [];
                    const regex = /^(.*?)(?:\((\d+)\))?$/i;
                    const stringSKU = contactOrder.SKUs.toLocaleString();
                    const reformattedArrayOfSKUs = stringSKU.split(',');
                    for (let i = 0; i < reformattedArrayOfSKUs.length; i++) {
                        const matched = reformattedArrayOfSKUs[i].match(regex);
                        const skus: OrderSku = {
                            quoteId: 0,
                            sku: matched[1],
                            quantity: parseInt(matched[2]),
                            description: ''
                        };
                        qdata.skus.push(skus);
                    }
                }
                data.quote = qdata;
                data.shipperId = shipper.shipperId;
                data.shipperAddressId = shipper.shipperAddressId;

                // TODO
                data.quote.type = this.BulkOrderForm.get('shippingTypeId').value; // Domestic;
                data.quote.shippingTypeId = this.bulkOrderParams.shippingTypeId;
                data.quote.shippingType = this.shippingTypes.find(x => x.id == this.bulkOrderParams.shippingTypeId).name;

                data.receiverId = 0;
                data.receiverAddressId = 0;
                data.bulkReceiver = contact;


                data.timeRangeLookupId = this.bulkOrderParams.timeRangeLookupId;
                data.commodityId = 16234;  // Other
                data.pickupDate = this.bulkOrderParams.collectionDate;
                data.specialInstructions = this.bulkOrderParams.specialInstructions;
                data.goodsDescription = this.bulkOrderParams.goodsDescription;
                data.specialInstructions = contactOrder.specialInstructions;
                this.BulkOrderDetails.push(data);
                // this.orderIdsToImport.push(data.orderId);
                a.push(data);
                // if (this.BulkOrderDetails.length == totalCount) {
                // var pOrder = this._orderService.addBulkOrderDetails(this.BulkOrderDetails);
                // return this.saveBulkOrderDetails(this.BulkOrderDetails);

                // this._orderService.addBulkOrderDetails(this.BulkOrderDetails).subscribe(orderResult => {
                //     this.BulkOrderDetails = [];
                //     this.bulkOrdersImported = this.bulkOrdersImported.concat(orderResult.value);
                //     console.log('Batch done ' + orderResult.value.length);
                // });
                // }
                // });
                // }, 1000 * index);
            }
            console.log(a);
            return a;
        }
    }


    confirm(): void {
        if (this.BulkOrderForm.invalid) {
            return;
        }
        else {
            this.matDialogRef.close(['new', this.BulkOrderForm.value, ]);
        }

    }
    validate(): void {

    }
    onChange(event) {

    }
    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }
}