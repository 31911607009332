import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Entity } from 'app/_models/Entity.model';
import { City } from 'app/_models/city.model';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { UserService } from 'app/_shared/user.services';
import { PhoneNumberDialogComponent } from './phone-number-dialog/phone-number-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { LocationService } from 'app/_shared/location-service.service';
import { Country } from 'app/_models/country.model';

@Component({
    selector: 'orders-billing-details',
    templateUrl: './billing-details.component.html',
    styleUrls: ['./billing-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersBillingDetailsComponent implements OnInit, OnDestroy {
    @Input() group: FormGroup;

    @Output() submited: EventEmitter<any> = new EventEmitter();
    @Output() canceled: EventEmitter<any> = new EventEmitter();

    protected _unsubscribeAll = new Subject<void>();

    countries: Entity<number>[] = [];
    cities: Entity<number>[] = [];

    cityControl: FormControl;
    countryId: number;
    phoneNumberDialogRef: MatDialogRef<PhoneNumberDialogComponent>;
    needPhoneVerification = false;
    phoneNumber: string;
    checkingPhoneNumber = false;
    phoneNumberExists = false;

    phoneCountries: Country[];

    constructor(private _userService: UserService, public _matDialog: MatDialog,
        private _locationService: LocationService) { }

    ngOnInit(): void {
        // this.countryControl = new FormControl('', Validators.required);
        this.cityControl = new FormControl('', Validators.required);
        if (this.group) {
            const countryId = this.group.get('countryId').value as number;
            const countryName = this.group.get('countryName').value as string;
            const cityId = this.group.get('cityId').value as number;
            const cityName = this.group.get('cityName').value as string;

            if (countryId && countryName && cityId && cityName) {
                const selectedCity = new City();
                selectedCity.cityId = cityId;
                selectedCity.countryId = countryId;
                selectedCity.name = cityName + ', ' + countryName;

                this.cityControl.setValue(selectedCity);
            }
        }

        this.cityControl.valueChanges.subscribe(value => {
            this.citySelected(value);
        });

        const country = new FormControl({ iso: 'LB' }, Validators.required);
        const phoneNumberValid = this.group.get('validPhoneNumber');
        if (this.group.get('phoneNumber').value) {
            this.needPhoneVerification = !this.group.get('validPhoneNumber').value;
        }
        this.phoneNumber = this.group.get('phoneNumber').value;

        this.group.get('phoneNumber').clearValidators();

        this.group.get('phoneNumber').setValidators(Validators.required);
        this.group.get('phoneNumber').setValidators(PhoneValidator.validCountryMobile(country, phoneNumberValid));

        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                this.phoneNumberExists = false;
                if (this.phoneNumber === this.group.get('phoneNumber').value) {
                    return;
                }
                this.needPhoneVerification = value;
            });
        this.getCountries();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    submitForm(): void {
        if (this.group.invalid) {
            return;
        }

        this.submited.emit(this.group.value);
    }

    cancel(): void {
        this.canceled.emit();
    }
    countryChange(value: any) {
        // this.contactForm.get('phoneNumber').setValue('');
        const phoneCountry = this.phoneCountries.find(country => country.countryPhoneCode == value.value)
        const country = new FormControl({ iso: phoneCountry }, Validators.required);
        let phoneNumberValid = this.group.get('validPhoneNumber');
        phoneNumberValid.setErrors(Validators.required);
        this.group.get('phoneNumber').setErrors(Validators.required)
        // if (!phoneNumberValid) {
        //     this.group.setControl('validPhoneNumber', this._formBuilder.control(false));
        //     phoneNumberValid = this.group.get('validPhoneNumber');
        // }
        this.group
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(0)
            )
            .subscribe((value: boolean) => {
                if (!value) {
                    this.group.get('phoneNumber').setErrors(Validators.required);
                }
            });
        this.group
            .get('phoneNumber').updateValueAndValidity();
    }
    citySelected(option: City): void {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.group.get('cityId').setValue(option.cityId);
            this.group.get('countryId').setValue(option.countryId);
            this.countryId = option.countryId;
        } else {
            this.cityControl.setErrors({ required: true });
        }
    }

    cityCleared(): void {
        this.group.get('cityId').setValue('');
        this.group.get('countryId').setValue('');
        this.cityControl.clearValidators();
        this.countryId = undefined;
    }
    getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.phoneCountries = success;
            });
        });
    }
    openPhoneNumberDialog(): void {
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }

        this.checkingPhoneNumber = true;
        this._userService.isPhoneNumberExist(this.group.get('phoneNumber').value).subscribe(result => {
            this.checkingPhoneNumber = false;
            this.phoneNumberExists = result;
            if (!result) {
                this.phoneNumberDialogRef = this._matDialog.open(PhoneNumberDialogComponent, {
                    panelClass: 'phone-number-dialog',
                    data: {
                        phoneNumber: this.group.get('phoneNumber').value
                    }
                });

                this.phoneNumberDialogRef.afterClosed().subscribe(response => {
                    if (response) {
                        this.phoneNumber = this.group.get('phoneNumber').value;
                        this.group.get('confirmedPhoneNumber').setValue(true);
                        this.group.get('validPhoneNumber').setValue(true);
                        this.needPhoneVerification = false;
                    }
                });
            } else {
                this.group.get('phoneNumber').setErrors({ phoneNumberExists: true });
            }
        });
    }
}
