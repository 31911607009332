import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import * as pbi from 'powerbi-client';
import { IEmbedConfigurationBase } from 'embed';
import { ReportService } from '../report.service';
import * as models from 'powerbi-models';

@Component({
    selector: 'reports-Fulfillment',
    templateUrl: './Fulfillment.component.html',
    styleUrls: ['./Fulfillment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FulfillmentComponent implements OnInit, OnDestroy {
    EmbedReport: any;
    powerbi: pbi.service.Service = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);;
    config: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */

    constructor(private _reportService: ReportService) {
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const storerKey = localStorage.getItem('netPointKey');
        this.EmbedReport = this._reportService.selectedReport;
        if (this.EmbedReport)
            this.getReport(storerKey);
    }
    getReport(storerKey) {
        // subsidiaryid = "7BA7783A-21EA-4129-AD59-DE8C9942F13B";
        const display = {
            isLockedInViewMode: true,
            isHiddenInViewMode: true
        };
        const filter1 = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            displaySettings: display,
            target: {
                table: "vwWH_INVENTORY",
                column: "STORERKEY",
            },
            operator: "In",
            values: [storerKey]
        };
        const filter2 = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            displaySettings: display,
            target: {
                table: "vwSTOCK_MOVEMENT",
                column: "STORERKEY",
            },
            operator: "In",
            values: [storerKey]
        };
        const filter3 = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            displaySettings: display,
            target: {
                table: "bi_OrderSummary",
                column: "STORERKEY",
            },
            operator: "In",
            values: [storerKey]
        };
        filter1.values[0] = filter1.values[0].replace(/['"]+/g, '');
        filter2.values[0] = filter2.values[0].replace(/['"]+/g, '');
        filter3.values[0] = filter3.values[0].replace(/['"]+/g, '');
        const config: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.EmbedReport.embedToken.token,
            id: this.EmbedReport.id,
            permissions: pbi.models.Permissions.Read,
            pageName: "Inventory",
            embedUrl: this.EmbedReport.embedUrl,
            filters: [filter1, filter2, filter3],
            settings: {
                filterPaneEnabled: true, // this will show/hide filter options
                navContentPaneEnabled: true
            }
        };


        let reportContainer = <HTMLElement>document.getElementById('powerBIReportContainer');
        this.powerbi.embed(reportContainer, config);
        // const config = <IEmbedConfigurationBase><any>{
        //     type: 'report',
        //     accessToken: this.EmbedReport.embedToken.token,
        //     embedUrl: this.EmbedReport.embedUrl,
        //     id: this.EmbedReport.Id,
        //     settings: {
        //         filterPaneEnabled: true,
        //         navContentPaneEnabled: true
        //     }
        // };

        // Grab the reference to the div HTML element that will host the report.
        // let pbicontainer = <HTMLElement>document.getElementById('pbicontainer');
        // let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

        // let reportContainer = <HTMLElement>document.getElementById('powerBIReportContainer');
        // this.powerbi.embed(reportContainer, config);

        // let report = powerbi.embed(pbicontainer, config);

        // Report.off removes a given event handler if it exists.
        // report.off("loaded");

        // // Report.on will add an event handler which prints to Log window.
        // report.on("loaded", function () {
        // });
    }
    ngAfterViewInit(): void {
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }




}




