<div class="page-layout carded fullwidth quick-quote">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="mt-16 mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        format_quote
                    </mat-icon>
                    <span class="h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <ng-container>COD Details Report</ng-container>
                    </span>
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>From date</mat-label>
                        <input matInput [matDatepicker]="fromPicker" [max]="toDate" formControlName="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>To date</mat-label>
                        <input matInput [matDatepicker]="toPicker" [min]="fromDate" formControlName="toDate">
                        <mat-datepicker-toggle matSuffix [for]="toPicker">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field> -->
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 100%;" id="powerBIReportContainer" class="d-block container-fluid m-0 p-0">
        </div>
    </div>
</div>