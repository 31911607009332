<mat-progress-bar class="app-progress-bar" *ngIf="showProgressBar" mode="indeterminate" color="accent">
</mat-progress-bar>
<mat-card-header style="position: sticky; position: -webkit-sticky;">
    <mat-card-title>Order Import Validation</mat-card-title>

    <mat-card-subtitle>Total Orders : {{orders.length}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="invalidOrders">Invalid Orders : {{invalidOrders.length}}
    </mat-card-subtitle>

</mat-card-header>
<mat-error *ngIf="invalidOrders.length > 0" class="mr-8">Please fix invalid rows in red to proceed!
</mat-error>
<mat-error *ngIf="hasSKUErrors" class="mr-8">Check your SKUs please before confirming your orders!</mat-error>
<mat-error *ngIf="hasDuplicatedSKUs && this.bulkSettings === 'New'" class="mr-8">Duplicated SKUs found.</mat-error>
<mat-card-actions align="end" style="align-items:flex-end; margin-top: -40px;">
    <button mat-raised-button color="primary" [disabled]="invalidOrders.length > 0 || ConfirmButtonDisabled"
        (click)="saveImported();">Confirm</button>
    <button mat-raised-button color="primary" (click)="closeDialog();">Cancel</button>
</mat-card-actions>

<form *ngIf="this.bulkSettings === 'Old'" class="form" #importForm="ngForm" (ngSubmit)="importForm.form.valid" validate>
    <button id="auto_trigger" hidden="true" color="primary" (click)="save(importForm);">Validate</button>
    <mat-card>
        <mat-card-content>
            <mat-table class="table" [dataSource]="dataSource" matSort matSortActive="isValid" matSortDirection="desc">
                <input type="submit" style="display:none;" />

                <ng-container matColumnDef="edit" class="editContainer">
                    <mat-header-cell *matHeaderCellDef>
                        <!-- Edit -->
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">

                        <mat-label></mat-label>
                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && !order.isValid">
                            <mat-icon class="edtIcon">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewData(order)"
                            *ngIf=" isEditOn && editIndex==rowIdx && !order.isValid">
                            <mat-icon class="edtIcon">check</mat-icon>
                        </button>
                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && order.isValid">
                            <mat-icon class="edtIconGreen">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewData(order)"
                            *ngIf="order.isValid && isEditOn && editIndex==rowIdx">
                            <mat-icon class="edtIconGreen">check</mat-icon>
                        </button>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="shipperId">
                    <mat-header-cell *matHeaderCellDef>
                        Shipper
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx" style="margin-top: -5px;">
                            <mat-select matInput #shipper="ngModel" name="ddlShipper-{{rowIdx}}" placeholder=" "
                                [(ngModel)]="order.shipperId" required>
                                <mat-option *ngFor="let contact of contacts;" [value]="contact.contactId">
                                    {{ contact.fullName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipper?.invalid">
                                <div *ngIf="shipper.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:100px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipper}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="shipperReference">
                    <mat-header-cell *matHeaderCellDef>
                        Reference
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:100px" #shipperReference="ngModel"
                                name="txtshipperReference-{{rowIdx}}" placeholder="Shipper Reference"
                                [(ngModel)]="order.shipperReference" (keyup)="onKeyUpEvent($event,rowIdx)"
                                style="font-size: smaller;">
                            <mat-error *ngIf="shipperReference?.errors">
                                <div *ngIf="shipperReference.errors.duplicate">duplicated</div>
                            </mat-error>
                            <!-- <mat-error >
                                <div >duplicated</div>
                            </mat-error> -->
                        </mat-form-field>
                        <span style="width:100px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipperReference}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="firstname">
                    <mat-header-cell *matHeaderCellDef>
                        Firstname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #firstName="ngModel" name="txtFirstname-{{rowIdx}}"
                                placeholder="FirstName" [(ngModel)]="order.firstName" required
                                style="font-size: smaller;">
                            <mat-error *ngIf="firstName?.invalid">
                                <div *ngIf="firstName.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.firstName}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>
                        Lastname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #lastname="ngModel" name="txtLastname-{{rowIdx}}"
                                placeholder="lastname" [(ngModel)]="order.lastName" required>
                            <mat-error *ngIf="lastname?.invalid">
                                <div *ngIf="lastname.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.lastName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef>
                        Company Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #companyName="ngModel" name="txtCompanyName-{{rowIdx}}"
                                placeholder="companyName" [(ngModel)]="order.companyName">
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.companyName}}</span>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>
                        Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="email" #email="ngModel" name="txtEmail-{{rowIdx}}" placeholder="Email"
                                [(ngModel)]="order.email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$">
                            <mat-error *ngIf="email.hasError('pattern')">
                                invalid
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.email}}</span>

                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="mobile">
                    <mat-header-cell *matHeaderCellDef>
                        Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #mobile="ngModel" name="txtMobile-{{rowIdx}}" placeholder="mobile"
                                [(ngModel)]="order.phoneNumber" required>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.required">required</div>
                            </mat-error>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.validCountryMobile">invalid</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.phoneNumber}}</span>

                    </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="country">
                    <mat-header-cell *matHeaderCellDef>
                        Country
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index; ">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select style="width:100px" #country="ngModel" name="ddlCountry-{{rowIdx}}"
                                (selectionChange)="changeCountry(rowIdx,$event)" placeholder="Country"
                                [(ngModel)]="order.addresses[0].countryId" required>

                                <mat-option *ngFor="let country of countries" [value]="country.countryId">
                                    {{ country.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="country?.invalid">
                                <div *ngIf="country.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.addresses[0].countryName}}</span>

                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="phoneCountryCode">
                    <mat-header-cell *matHeaderCellDef>
                        Code
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field style="min-width:fit-content;" *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select #countryCode="ngModel" name="ddlCountryCode-{{rowIdx}}"
                                (selectionChange)="changeCountryCode(rowIdx,$event)" placeholder="Code"
                                [(ngModel)]="order.phoneCountryCode" required>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countryCodeFilterCtrl"
                                        placeholderLabel="Find code..." noEntriesFoundLabel="no matching code found">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredCountryCodes  | async"
                                    [value]="country.countryPhoneCode">
                                    {{ country.countryPhoneCode }}
                                </mat-option>

                            </mat-select>
                            <mat-error *ngIf="countryCode?.invalid">
                                <div >required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="min-width:fit-content"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.phoneCountryCode}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>
                        City
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #city="ngModel" name="ddlCity-{{rowIdx}}" placeholder="City"
                                [(ngModel)]="order.addresses[0].cityId" required>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="cityFilterCtrl"
                                        placeholderLabel="Find city..." noEntriesFoundLabel="no matching city found">
                                    </ngx-mat-select-search>
                                </mat-option>

                                <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityId">
                                    {{ city.cityName }}
                                </mat-option>


                            </mat-select>
                            <mat-error *ngIf="city?.invalid">
                                <div *ngIf="city.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.addresses[0].cityName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="street">
                    <mat-header-cell *matHeaderCellDef>
                        Street
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #street="ngModel" name="txtStreet-{{rowIdx}}" placeholder="street"
                                maxlength="300" [(ngModel)]="order.addresses[0].street" required>
                            <mat-error *ngIf="street?.invalid">
                                <div *ngIf="street.errors.required">required</div>
                                <div *ngIf="street.errors">max 500</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].street}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="building">
                    <mat-header-cell *matHeaderCellDef>
                        Building
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #building="ngModel" name="txtBuilding-{{rowIdx}}" placeholder="building"
                                [(ngModel)]="order.addresses[0].building" maxlength="300">
                            <mat-error *ngIf="building?.errors">
                                <div *ngIf="building.errors">max 300</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].building}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="floor">
                    <mat-header-cell *matHeaderCellDef>
                        Floor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #floor="ngModel" name="txtFloor-{{rowIdx}}" placeholder="floor"
                                [(ngModel)]="order.addresses[0].floor">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].floor}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="landmark">
                    <mat-header-cell *matHeaderCellDef>
                        Landmark
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #landmark="ngModel" name="txtLandmark-{{rowIdx}}" placeholder="landmark"
                                [(ngModel)]="order.addresses[0].landmark" maxlength="1000">
                            <mat-error *ngIf="landmark?.errors">
                                <div *ngIf="landmark.errors">max length 1000</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].landmark}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="specialInstructions">
                    <mat-header-cell *matHeaderCellDef>
                        Special Instructions
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #specialInstructions="ngModel" name="txtSpecialInstructions-{{rowIdx}}"
                                placeholder="specialInstructions" [(ngModel)]="order.specialInstructions">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.specialInstructions}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef>
                        Weight
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="number" matInput #weight="ngModel" name="txtWeight-{{rowIdx}}"
                                placeholder="weight" [(ngModel)]="order.weight" required>
                            <mat-error *ngIf="weight?.invalid">
                                <div *ngIf="weight.errors.required">required</div>
                            </mat-error>
                            <mat-error *ngIf="weight >30">
                                <div>Max weight 30 kg</div>
                            </mat-error>

                        </mat-form-field>
                        <span style="widows: 50px;" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.weight}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nop">
                    <mat-header-cell *matHeaderCellDef>
                        NOP
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="number" matInput #nop="ngModel" name="txtNOP-{{rowIdx}}"
                                placeholder="nop" [(ngModel)]="order.nop" pattern="^\d+$" required>
                        </mat-form-field>
                        <span style="widows: 50px;" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.nop}}</span>
                    </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="shipmentValue">
                    <mat-header-cell *matHeaderCellDef>
                        Shipment Value
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input type="number" matInput #shipmentValue="ngModel" name="txtShipmentValue-{{rowIdx}}"
                                placeholder="shipmentValue" [(ngModel)]="order.shipmentValue">
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.shipmentValue}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="shipmentCurrencyId">
                    <mat-header-cell *matHeaderCellDef>
                        Shipment Currency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #shipmentCur="ngModel" name="ddlShipmentCurrencyId-{{rowIdx}}"
                                placeholder="" [(ngModel)]="order.shipmentCurrencyId" required>
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipmentCur?.invalid">
                                <div *ngIf="shipmentCur.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipmentCurrency}}</span>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="codAmount">
                    <mat-header-cell *matHeaderCellDef>
                        COD Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #codAmount="ngModel" name="txtCodAmount-{{rowIdx}}" placeholder="codAmount"
                                [(ngModel)]="order.codAmount" min="0" pattern="^\d*\.?\d+$">
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.codAmount}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codCurrencyId">
                    <mat-header-cell *matHeaderCellDef>
                        COD Currency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #codCurrency="ngModel" name="ddlCodCurrencyId-{{rowIdx}}"
                                placeholder="" [(ngModel)]="order.codCurrencyId" required>
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="codCurrency?.invalid">
                                <div *ngIf="codCurrency.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.codCurrency}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codAmount2">
                    <mat-header-cell *matHeaderCellDef>
                        COD Amount 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #codAmount2="ngModel" name="txtCodAmount2-{{rowIdx}}" placeholder="codAmount2"
                                [(ngModel)]="order.codAmount2" min="0" pattern="^\d*\.?\d+$"  >
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.codAmount2}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codCurrencyId2">
                    <mat-header-cell *matHeaderCellDef>
                        COD Currency 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #codCurrency2="ngModel" name="ddlCodCurrencyId2-{{rowIdx}}" [required]="order.codAmount2 > 0"
                                placeholder="" [(ngModel)]="order.codCurrencyId2">
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="codCurrency2?.invalid">
                                <div *ngIf="codCurrency2.errors.required">required</div>
                            </mat-error> -->
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.codCurrency2}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="skus">
                    <mat-header-cell *matHeaderCellDef>
                        SKUs
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #skus="ngModel" name="txtSkus-{{rowIdx}}" placeholder="skus"
                                [(ngModel)]="order.SKUs" (change)="skuChanged(order.SKUs)">
                        </mat-form-field>
                        <span style="height: auto; white-space: nowrap; flex-wrap: wrap; overflow: auto; display: flex;"
                            *ngIf="!isEditOn || editIndex!=rowIdx">
                            <span >{{order.SKUs}}</span>
                        </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns ; let i = index; ">
                </mat-row>
            </mat-table>

        </mat-card-content>

    </mat-card>


</form>

<form *ngIf="this.bulkSettings === 'New'" class="form" #importForm="ngForm" (ngSubmit)="importForm.form.valid" validate>
    <button id="auto_trigger" hidden="true" color="primary" (click)="save(importForm);">Validate</button>
    <mat-card>
        <mat-card-content>
            <mat-table class="table" [dataSource]="dataSource" matSort matSortActive="isValid" matSortDirection="desc">
                <input type="submit" style="display:none;" />

                <ng-container matColumnDef="edit" class="editContainer">
                    <mat-header-cell *matHeaderCellDef>
                        <!-- Edit -->
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">

                        <mat-label></mat-label>
                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && !order.isValid">
                            <mat-icon class="edtIcon">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewDataNew(order)"
                            *ngIf=" isEditOn && editIndex==rowIdx && !order.isValid">
                            <mat-icon class="edtIcon">check</mat-icon>
                        </button>
                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && order.isValid">
                            <mat-icon class="edtIconGreen">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewDataNew(order)"
                            *ngIf="order.isValid && isEditOn && editIndex==rowIdx">
                            <mat-icon class="edtIconGreen">check</mat-icon>
                        </button>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="shipperId">
                    <mat-header-cell *matHeaderCellDef>
                        Shipper
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx" style="margin-top: -5px;">
                            <mat-select matInput #shipper="ngModel" name="ddlShipper-{{rowIdx}}" placeholder=" "
                                [(ngModel)]="order.shipperId" required>
                                <mat-option *ngFor="let contact of contacts;" [value]="contact.contactId">
                                    {{ contact.fullName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipper?.invalid">
                                <div *ngIf="shipper.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:100px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipper}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="shipperReference">
                    <mat-header-cell *matHeaderCellDef>
                        Reference
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:100px" #shipperReference="ngModel"
                                name="txtshipperReference-{{rowIdx}}" placeholder="Shipper Reference"
                                [(ngModel)]="order.shipperReference" (keyup)="onKeyUpEvent($event,rowIdx)"
                                style="font-size: smaller;" required>
                            <mat-error *ngIf="shipperReference?.errors">
                                <div *ngIf="shipperReference.errors.duplicate">duplicated</div>
                            </mat-error>
                            <!-- <mat-error >
                                <div >duplicated</div>
                            </mat-error> -->
                        </mat-form-field>
                        <span style="width:100px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipperReference}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="firstname">
                    <mat-header-cell *matHeaderCellDef>
                        Firstname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #firstName="ngModel" name="txtFirstname-{{rowIdx}}"
                                placeholder="FirstName" [(ngModel)]="order.firstName" required
                                style="font-size: smaller;">
                            <mat-error *ngIf="firstName?.invalid">
                                <div *ngIf="firstName.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.firstName}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>
                        Lastname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #lastname="ngModel" name="txtLastname-{{rowIdx}}"
                                placeholder="lastname" [(ngModel)]="order.lastName" required>
                            <mat-error *ngIf="lastname?.invalid">
                                <div *ngIf="lastname.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.lastName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef>
                        Company Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #companyName="ngModel" name="txtCompanyName-{{rowIdx}}"
                                placeholder="companyName" [(ngModel)]="order.companyName">
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.companyName}}</span>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>
                        Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="email" #email="ngModel" name="txtEmail-{{rowIdx}}" placeholder="Email"
                                [(ngModel)]="order.email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$">
                            <mat-error *ngIf="email.hasError('pattern')">
                                invalid
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.email}}</span>

                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="mobile">
                    <mat-header-cell *matHeaderCellDef>
                        Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #mobile="ngModel" name="txtMobile-{{rowIdx}}" placeholder="mobile"
                                [(ngModel)]="order.phoneNumber" required>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.required">required</div>
                            </mat-error>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.validCountryMobile">invalid</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.phoneNumber}}</span>

                    </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="country">
                    <mat-header-cell *matHeaderCellDef>
                        Country
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index; ">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select style="width:100px" #country="ngModel" name="ddlCountry-{{rowIdx}}"
                                (selectionChange)="changeCountry(rowIdx,$event)" placeholder="Country"
                                [(ngModel)]="order.addresses[0].countryId" required>

                                <mat-option *ngFor="let country of countries" [value]="country.countryId">
                                    {{ country.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="country?.invalid">
                                <div *ngIf="country.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.addresses[0].countryName}}</span>

                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="phoneCountryCode">
                    <mat-header-cell *matHeaderCellDef>
                        Code
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field style="min-width:fit-content;" *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select #countryCode="ngModel" name="ddlCountryCode-{{rowIdx}}"
                                (selectionChange)="changeCountryCode(rowIdx,$event)" placeholder="Code"
                                [(ngModel)]="order.phoneCountryCode" required>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countryCodeFilterCtrl"
                                        placeholderLabel="Find code..." noEntriesFoundLabel="no matching code found">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredCountryCodes  | async"
                                    [value]="country.countryPhoneCode">
                                    {{ country.countryPhoneCode }}
                                </mat-option>

                            </mat-select>
                            <mat-error *ngIf="countryCode?.invalid">
                                <div >required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="min-width:fit-content"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.phoneCountryCode}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>
                        City
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #city="ngModel" name="ddlCity-{{rowIdx}}" placeholder="City"
                                [(ngModel)]="order.addresses[0].cityId" required>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="cityFilterCtrl"
                                        placeholderLabel="Find city..." noEntriesFoundLabel="no matching city found">
                                    </ngx-mat-select-search>
                                </mat-option>

                                <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityId">
                                    {{ city.cityName }}
                                </mat-option>


                            </mat-select>
                            <mat-error *ngIf="city?.invalid">
                                <div *ngIf="city.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.addresses[0].cityName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="street">
                    <mat-header-cell *matHeaderCellDef>
                        Street
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #street="ngModel" name="txtStreet-{{rowIdx}}" placeholder="street"
                                maxlength="300" [(ngModel)]="order.addresses[0].street" required>
                            <mat-error *ngIf="street?.invalid">
                                <div *ngIf="street.errors.required">required</div>
                                <div *ngIf="street.errors">max 500</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].street}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="building">
                    <mat-header-cell *matHeaderCellDef>
                        Building
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #building="ngModel" name="txtBuilding-{{rowIdx}}" placeholder="building"
                                [(ngModel)]="order.addresses[0].building" maxlength="300">
                            <mat-error *ngIf="building?.errors">
                                <div *ngIf="building.errors">max 300</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].building}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="floor">
                    <mat-header-cell *matHeaderCellDef>
                        Floor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #floor="ngModel" name="txtFloor-{{rowIdx}}" placeholder="floor"
                                [(ngModel)]="order.addresses[0].floor">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].floor}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="landmark">
                    <mat-header-cell *matHeaderCellDef>
                        Landmark
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #landmark="ngModel" name="txtLandmark-{{rowIdx}}" placeholder="landmark"
                                [(ngModel)]="order.addresses[0].landmark" maxlength="1000">
                            <mat-error *ngIf="landmark?.errors">
                                <div *ngIf="landmark.errors">max length 1000</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.addresses[0].landmark}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="specialInstructions">
                    <mat-header-cell *matHeaderCellDef>
                        Special Instructions
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #specialInstructions="ngModel" name="txtSpecialInstructions-{{rowIdx}}"
                                placeholder="specialInstructions" [(ngModel)]="order.specialInstructions">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.specialInstructions}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef>
                        Weight
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="number" matInput #weight="ngModel" name="txtWeight-{{rowIdx}}"
                                placeholder="weight" [(ngModel)]="order.weight" required>
                            <mat-error *ngIf="weight?.invalid">
                                <div *ngIf="weight.errors.required">required</div>
                            </mat-error>
                            <mat-error *ngIf="weight >30">
                                <div>Max weight 30 kg</div>
                            </mat-error>

                        </mat-form-field>
                        <span style="widows: 50px;" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.weight}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nop">
                    <mat-header-cell *matHeaderCellDef>
                        NOP
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="number" matInput #nop="ngModel" name="txtNOP-{{rowIdx}}"
                                placeholder="nop" [(ngModel)]="order.nop" pattern="^\d+$" required>
                        </mat-form-field>
                        <span style="widows: 50px;" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.nop}}</span>
                    </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="shipmentValue">
                    <mat-header-cell *matHeaderCellDef>
                        Shipment Value
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input type="number" matInput #shipmentValue="ngModel" name="txtShipmentValue-{{rowIdx}}"
                                placeholder="shipmentValue" [(ngModel)]="order.shipmentValue">
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.shipmentValue}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="shipmentCurrencyId">
                    <mat-header-cell *matHeaderCellDef>
                        Shipment Currency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #shipmentCur="ngModel" name="ddlShipmentCurrencyId-{{rowIdx}}"
                                placeholder="" [(ngModel)]="order.shipmentCurrencyId" required>
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="shipmentCur?.invalid">
                                <div *ngIf="shipmentCur.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{order.shipmentCurrency}}</span>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="codAmount">
                    <mat-header-cell *matHeaderCellDef>
                        COD Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #codAmount="ngModel" name="txtCodAmount-{{rowIdx}}" placeholder="codAmount"
                                [(ngModel)]="order.codAmount" min="0" pattern="^\d*\.?\d+$">
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.codAmount}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codCurrencyId">
                    <mat-header-cell *matHeaderCellDef>
                        COD Currency
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #codCurrency="ngModel" name="ddlCodCurrencyId-{{rowIdx}}"
                                placeholder="" [(ngModel)]="order.codCurrencyId" required>
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="codCurrency?.invalid">
                                <div *ngIf="codCurrency.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.codCurrency}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codAmount2">
                    <mat-header-cell *matHeaderCellDef>
                        COD Amount 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #codAmount2="ngModel" name="txtCodAmount2-{{rowIdx}}" placeholder="codAmount2"
                                [(ngModel)]="order.codAmount2" min="0" pattern="^\d*\.?\d+$"  >
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.codAmount2}}</span>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="codCurrencyId2">
                    <mat-header-cell *matHeaderCellDef>
                        COD Currency 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #codCurrency2="ngModel" name="ddlCodCurrencyId2-{{rowIdx}}" [required]="order.codAmount2 > 0"
                                placeholder="" [(ngModel)]="order.codCurrencyId2">
                                <mat-option *ngFor="let currency of currencyTypes;" [value]="currency.value">
                                    {{ currency.title }}
                                </mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="codCurrency2?.invalid">
                                <div *ngIf="codCurrency2.errors.required">required</div>
                            </mat-error> -->
                        </mat-form-field>
                        <span style="width:50px" *ngIf="!isEditOn || editIndex!=rowIdx">{{order.codCurrency2}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="skus">
                    <mat-header-cell *matHeaderCellDef>
                        SKU
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #skus="ngModel" name="txtSkus-{{rowIdx}}" placeholder="skus"
                                [(ngModel)]="order.SKUs[0]" (change)="skuChangedName(order.SKUs[0])">
                        </mat-form-field>
                        <span style="height: auto; white-space: nowrap; flex-wrap: wrap; overflow: auto; display: flex;"
                            *ngIf="!isEditOn || editIndex!=rowIdx">
                            <span >{{order.SKUs[0]}}</span>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="skuqty">
                    <mat-header-cell *matHeaderCellDef>
                        SKU Quantity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let order; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="number" matInput #skuqty="ngModel" name="txtskuqty-{{rowIdx}}"
                                placeholder="skuqty" [(ngModel)]="order.SKUQty" pattern="^\d+$" (change)="skuChangedQty(order.SKUQty)">
                        </mat-form-field>
                        <span style="widows: 50px;" *ngIf="!isEditOn || editIndex!=rowIdx ">{{order.SKUQty}}</span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsNew"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsNew ; let i = index; ">
                </mat-row>
            </mat-table>

        </mat-card-content>

    </mat-card>


</form>