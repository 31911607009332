import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { NgxBarcodeModule } from 'ngx-barcode';
import { DistributionView } from '../view/distribution-view.model';
import { DistributionBillingDetails, Distribution, DistributionDetail } from '../new-distribution/distribution.model';
import { DistributionViewService } from '../view/distribution-view.service';
import { DistributionService } from '../distribution.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { OrderLabelComponent } from 'app/main/orders/order-label/order-label.component';

@Component({
    selector: 'distribution-invoice',
    templateUrl: './distribution-invoice.component.html',
    styleUrls: ['./distribution-invoice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DistributionInvoiceComponent implements OnInit, OnDestroy {
    // distribution: DistributionView;
    distribution: Distribution;
    distributionv: DistributionView;
    distributiond: DistributionDetail;
    date: Date = new Date();
    billingDetails: DistributionBillingDetails;
    distributionLabelDialogRef: MatDialogRef<OrderLabelComponent>;
    mescoDist: boolean;




    printType: number = 1; // : Number of pages per A4 Print

    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _distributionViewService: DistributionViewService, private activatedRoute: ActivatedRoute, private _orderService: DistributionService, private _matDialog: MatDialog) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._distributionViewService.onDistributionSelected.subscribe(order => {
            this.distribution = order;
        });
        this._orderService.getMyInfo().subscribe((info: DistributionBillingDetails) => {
            this.billingDetails = info;
        });
        this.printType = Number(this.activatedRoute.snapshot.paramMap.get('id2').toString()); // : Number of pages per A4 Print
        const dist = this.distribution as any;
        if(dist.shipper.address.countryName === "Egypt") {
            this.mescoDist = true;
        }
    }
    ngAfterViewInit(): void {
        // window.print();
    }
    pagesPerSheet(index: number) {
        if (index == 0)
            return true;
        else if (index % 4 == 0)
            return true
        else
            return false;
    }
    onPrint() {
        //TODO Create Multiple HTML Pages

        // var contentToPrint = document.getElementById('invoice').innerHTML;
        // var windowPopup = window.open('', '_blank', 'width=500,height=500');
        // windowPopup.document.open();
        // windowPopup.document.write('<html><head><link rel="stylesheet" type="text/css" href="" /></head><body onload="window.print()">' + contentToPrint + '</body></html>');
        // windowPopup.document.close();
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this._distributionViewService.printDistribution(this.distribution).then(x => {
            this.distributionLabelDialogRef = this._matDialog.open(OrderLabelComponent, {
                panelClass: 'order-label',
                data: {
                    url: x.data
                }
            });
        });
    }
    printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
