<div style="position: relative;height: 100%;display: flex;flex-flow: column-reverse;">
    <div>
        <h1 class="h1 pb-4">Rules and Regulations - {{ MyNetServiceType[serviceType] }} Shipping</h1>
        <ul class="main-list">
            <!-- <li class="main-list-item" *ngFor="let c of caveats">
                <h2 class="h2">{{ c.title }}</h2>
                <p [innerHTML]="c.description"></p>
            </li> -->
            <mat-accordion>
                <mat-expansion-panel *ngFor="let c of caveats">
                  <mat-expansion-panel-header>
                    <h2 class="h2">{{ c.title }}</h2> 
                  </mat-expansion-panel-header>
                  <p [innerHTML]="c.description"></p>
                </mat-expansion-panel>
              
              </mat-accordion>
        </ul>
    </div>
</div>