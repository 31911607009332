import { NgModule } from '@angular/core';
import { ContactEditorComponent } from './contact-editor.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule, MatRadioButton, MatRadioModule, MatProgressBarModule, MatCheckboxModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ContactFormAddressComponent } from './contact-form-address/contact-form-address.component';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
// import { GoogleLocationComponent } from '../google-location/google-location.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleLocationModule } from 'app/_shared/google-location/google-location.module';

@NgModule({
    declarations: [ContactEditorComponent, ContactFormAddressComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        MatToolbarModule,
        FuseConfirmDialogModule,
        MatDialogModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        AgmCoreModule,
        GoogleLocationModule,
        MatRadioModule,
        MatProgressBarModule,
        MatCheckboxModule
    ],
    entryComponents: [ContactEditorComponent]
})
export class ContactEditorModule {}
