<div id="email-confirmed" fxLayout="column">

    <div id="email-confirmed-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="email-confirmed-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="mat-accent s-96">check</mat-icon>
            </div>

            <div class="title">Email has been confirmed</div>

            <div class="message">
                <a class="link" [routerLink]="'/auth/login'">Go back to login page</a>
            </div>

        </div>

    </div>

</div>