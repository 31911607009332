<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Confirm Checkout
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close(false)" aria-label="Close dialog"
                type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <div class="p-24 pb-0 m-0" fusePerfectScrollbar>
            <table class="checkout-table w-100-p">

                <tr>
                    <td class="font-weight-900">Price</td>
                    <td *ngIf="order.quote.quoteCurrency == 'USD'" class="text-right">{{ order.quote.quotePrice | number:'.2-2' }} $</td>
                    <td *ngIf="order.quote.quoteCurrency !== 'USD'" class="text-right">{{ order.quote.quotePrice | number:'.0-2' }} {{ order.quote.quoteCurrency}}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Shipper</td>
                    <td class="text-right">{{ order.shipperName }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">
                        {{ order.isNetPointShipper ? 'From NetPoint Location' : 'Shipper Address'}}</td>
                    <td class="text-right">{{ order.shipperAddress }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Receiver</td>
                    <td class="text-right">{{ order.receiverName }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">
                        {{ order.isNetPointReceiver ? 'To NetPoint Location' : 'Receiver Address'}}</td>
                    <td class="text-right">{{ order.receiverAddress }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Pickup Date</td>
                    <td class="text-right">{{ order.pickupDate | date }}</td>
                </tr>

                <!-- <tr>
                    <td class="font-weight-900">Pickup Time</td>
                    <td class="text-right">{{ order.pickupTimeId | timeRange | async }}</td>
                </tr> -->

                <tr>
                    <td class="font-weight-900">Commodity</td>
                    <td class="text-right">{{ order.commodityId | commodity:serviceTypeId | async }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Number Of Pieces</td>
                    <td class="text-right">{{ order.quote.numberOfPieces }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Shipment Value</td>
                    <td class="text-right">{{ order.quote.shipmentValue | number:'.2-2' }}
                        <span *ngIf="order.quote.shipmentValue">$</span></td>
                </tr>

                <tr>
                    <td class="font-weight-900">Shipping Type</td>
                    <td class="text-right">{{ order.quote.shippingType }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Weight</td>
                    <td class="text-right">{{ order.quote.weight | number:'.2-2' }} Kg</td>
                </tr>

                <tr *ngIf="order.quote.width && order.quote.height && order.quote.length">
                    <td class="font-weight-900">Dimension</td>
                    <td class="text-right">
                        {{ order.quote.length | number:'.2-2' }} cm x
                        {{ order.quote.width | number:'.2-2' }} cm x
                        {{ order.quote.height | number:'.2-2' }} cm
                    </td>
                </tr>

                <tr>
                    <td class="font-weight-900">Goods Description</td>
                    <td class="text-right"> {{ order.goodsDescription }}</td>
                </tr>

                <tr>
                    <td class="font-weight-900">Special Instructions</td>
                    <td class="text-right"> {{ order.specialInstructions }}</td>
                </tr>

            </table>
        </div>
        <mat-progress-bar *ngIf="payClicked" mode="indeterminate"></mat-progress-bar>
    </mat-dialog-content>
    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <ng-container *ngIf="!retryAllowed">
            <button mat-raised-button color="primary" class="mr-8" (click)="confirm()" aria-label="Confirm"
                [disabled]="payClicked" *ngIf="checkoutLoaded" matTooltip="Confirm">
                CONFIRM
            </button>

            <button mat-button class="ml-8" color="warn" (click)="matDialogRef.close(false)" aria-label="Cancel"
                [disabled]="payClicked" *ngIf="checkoutLoaded" matTooltip="Cancel">
                CANCEL
            </button>

            <button mat-button class="w-100-p" color="warn" type="button" *ngIf="!checkoutLoaded">
                Connecting to Payment Portal...
            </button>
        </ng-container>

        <button mat-button class="w-100-p" color="primary" type="button" *ngIf="retryAllowed"
            (click)="getCheckoutSession()">
            Retry
        </button>
    </mat-dialog-actions>
</div>