export class QuoteResult {
    fromCity: string;
    icon: string;
    direction: string;
    toCity: string;
    estimatedCost: number;
    currency: string;
    serviceType: string;
    shippingType: string;
    weight: number;
    width: number;
    height: number;
    length: number;

    constructor(result?) {
        result = result || {};
        this.fromCity = result.fromCity || '';
        this.icon = result.icon || '';
        this.direction = result.direction || '';
        this.toCity = result.toCity || '';
        this.serviceType = result.serviceType || '';
        this.shippingType = result.shippingType || '';
        this.estimatedCost = result.estimatedCost || 0;
        this.currency = result.currency;
        this.weight = result.weight || 0;
        this.width = result.width || 0;
        this.height = result.height || 0;
        this.length = result.length || 0;
    }
}
