import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup } from '@angular/forms';
import { Country } from 'app/_models/country.model';
import { takeUntil, scan } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { City } from 'app/_models/city.model';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { isNullOrUndefined } from 'util';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderExcelListItem } from './import-order.model';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Order, SkuStock } from '../../new-order/order.model';
const { read, write, utils } = XLSX;
type AOA = any[][];

@Component({
    selector: 'import-order',
    templateUrl: './import-order.component.html',
    styleUrls: ['./import-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ImportOrderComponent implements OnInit {

    @ViewChild('importForm', { static: false }) importForm: NgForm;
    @ViewChild('btnValidate', { static: false }) btnValidate: MatButton
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    
    orders: OrderExcelListItem[];
    invalidOrders: OrderExcelListItem[];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    protected _unsubscribeAll = new Subject<void>();
    hasSKUErrors: boolean = false;
    stringSKU: string;
    editedSKUsArray: string[];
    existingSkus: SkuStock[];
    invalidSku: SkuStock[] = [];
    hasDuplicatedSKUs: boolean;
    duplicatedSKU: string;
    orderEdit: OrderExcelListItem;

    storeKey = localStorage.getItem('netPointKey');
    bulkSettings = localStorage.getItem('bulkSettings');

    countries: Country[];
    countryCodes: Country[];
    allCities: City[];
    countryCities: City[] = [];
    dataSource;

    selection = new SelectionModel<any>(true, []);
    currencyTypes: any;
    contacts: ContactListItem[];

    shipperReferences: string[];

    // contactDialogRef: MatDialogRef<ContactEditorComponent>;
    // displayedColumns: string[] = ['edit', 'shipper', 'firstname', 'lastname', 'email', 'phoneCountryCode', 'mobile',
    //     'country', 'city', 'street', 'building', 'floor', 'landmark', 'postCode', 'weight', 'shipmentValue', 'codAmount', 'codCurrency'];

    displayedColumns: string[] = ['edit', 'shipperReference', 'shipperId', 'firstname', 'lastname', 'companyName', 'phoneCountryCode', 'mobile',
        'city', 'street', 'building', 'floor', 'landmark', 'specialInstructions', 'weight', 'nop', 'codAmount', 'codCurrencyId', 'codAmount2', 'codCurrencyId2', 'skus'];
    displayedColumnsNew: string[] = ['edit', 'shipperReference', 'shipperId', 'firstname', 'lastname', 'companyName', 'phoneCountryCode', 'mobile',
        'city', 'street', 'building', 'floor', 'landmark', 'specialInstructions', 'weight', 'nop', 'codAmount', 'codCurrencyId', 'codAmount2', 'codCurrencyId2', 'skus', 'skuqty'];
    contactsValidated: boolean = false;
    // options = new BehaviorSubject<City[]>([]);
    // options$: Observable<City[]>;
    showProgressBar: boolean = true;
    isEditOn: boolean = false;
    editIndex: number;
    ConfirmButtonDisabled: boolean = true;
    countinValid: number = 0;
    public localData: any[];

    public countryFilterCtrl: FormControl = new FormControl();
    public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    public cityFilterCtrl: FormControl = new FormControl();
    public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
    public countryCodeFilterCtrl: FormControl = new FormControl();
    public filteredCountryCodes: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    constructor(@Inject(MAT_DIALOG_DATA) private _data: any, 
                public _matDialog: MatDialog, 
                private cdr: ChangeDetectorRef, 
                public matDialogRef: MatDialogRef<any>) {
        
                    this.orders = _data.orders;
                    this.allCities = _data.cities;
                    this.countries = _data.countries;
                    this.countryCodes = _data.countries;
                    this.contacts = _data.contacts;
                    this.shipperReferences = _data.shipperReferences;
                    this.existingSkus = _data.existingSkusList;
                }

    ngOnInit(): void {
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key }));
    }
    ngAfterViewInit(): void {
        this.countryCodeFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.filterCountryCodes();
            });
        this.cityFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.filterCities();
            });


    }
    ngAfterContentInit(): void {
        this.orders.sort((b, a) => {
            if (a.isValid === b.isValid) {
                return 0;
            }
            if (a.isValid) {
                return -1;
            }
            if (b.isValid) {
                return 1;
            }
        });


        this.invalidOrders = this.orders.filter(c => c.isValid == false);
        setTimeout(() => {
            this.dataSource = new MatTableDataSource<OrderExcelListItem>(this.orders);
            this.dataSource.data.sort
            this.filteredCountryCodes.next(this.countries.slice());
            this.filteredCities.next(this.allCities.slice());
            let element: HTMLElement = document.getElementById('auto_trigger') as HTMLElement;
            element.click();
            this.importForm.control.valueChanges.subscribe(x => {
                if (x) {
                    const countryCodeControl = this.importForm.form.controls['txtshipperReference-' + this.editIndex];
                    // const codControl = this.importForm.form.controls['txtCodAmount2-' + this.editIndex];
                    // console.log(codControl.value);
                    if (countryCodeControl) {
                        if (this.shipperReferences.findIndex(x => x.toString().toLowerCase() == countryCodeControl.value.toString().toLowerCase()) >= 0) {
                            countryCodeControl.setErrors({ 'duplicate': true });
                        }
                        else {
                            countryCodeControl.setErrors(null);
                        }
                    }
                }
            });
            if (this.invalidOrders.length > 0)
                this.ConfirmButtonDisabled = true;
            else
                this.ConfirmButtonDisabled = false;
            if (this.orders.some(o => !isNullOrUndefined(o.invalidSku)) && this.orders.some(o => o.invalidSku.length > 0)) {
                this.hasSKUErrors = true;
            }
            if (this.orders.some(o => !isNullOrUndefined(o.invalidSku)) && 
            this.orders.some(o => !isNullOrUndefined(o.invalidSku[0]) && o.invalidSku[0].name.toLowerCase().includes('duplicate'))) {
                this.hasDuplicatedSKUs = true;
            }
        });
        console.log(this.orders);
        // if (this.orders.some(o => o.invalidSku.length > 0)) {
        //     if (this.orders.some(o => o.invalidSku.length > 0)) {
        //         this.hasSKUErrors = true;
        //     }
        //     for (const skuname of this.orders.find(sku => sku.invalidSku.length > 0).invalidSku) {
        //         if (skuname.name.toLowerCase().includes("duplicate")) {
        //             this.hasSKUErrors = true;
        //         }
        //     }
        // }
    }
    trackById(index, city) {
        return city.cityId;
    }
    AllContactsValidation() {
        var notvalidnb = 0;
        this.ConfirmButtonDisabled = false;
        this.invalidOrders.forEach(x => {
            if (!x.isValid) {
                this.ConfirmButtonDisabled = true;
                notvalidnb = notvalidnb + 1;
            }
        }
        );
        this.countinValid = this.invalidOrders.length - notvalidnb;
    }
    onEdit(index: number) {
        this.editIndex = index;
        this.isEditOn = !this.isEditOn;
        this.importForm.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.importForm.form.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        this.ConfirmButtonDisabled = true;
        //this.editedSKUsArray = this.orders[index].SKUs;
        this.invalidSku = this.orders[index].invalidSku;
        this.duplicatedSKU = this.orders[index].SKUs[0];
        this.orderEdit = this.orders[index];
    }
    saveNewData(order: OrderExcelListItem) {
        var orderExcelItem = this.orders.find(x => x == order);
        orderExcelItem.shipmentCurrencyId = order.shipmentCurrencyId;
        orderExcelItem.codCurrencyId = order.codCurrencyId;
        orderExcelItem.shipperId = order.shipperId;


        var checkReferenceIfFound = this.shipperReferences.findIndex(x => x.toString().toLowerCase() == orderExcelItem.shipperReference.toString().toLowerCase())
        if (checkReferenceIfFound >= 0)
            order.isValid = false;
        orderExcelItem.shipperReference = order.shipperReference;
        orderExcelItem.specialInstructions = order.specialInstructions;

        if (order.shipperId) {
            const shipperContact = this.contacts.find(x => x.contactId == order.shipperId);
            orderExcelItem.shipper = shipperContact.fullName;
            orderExcelItem.shipperCityId = shipperContact.cityId;
            orderExcelItem.shipperAddressId = shipperContact.addressId;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        var countryIdRetreived = this.countries.find(x => x.countryPhoneCode == order.phoneCountryCode);

        if (countryIdRetreived) {
            const mobile = order.phoneNumber;
            const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
            if (validatedPhone == '') {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }
        if (!isNullOrUndefined(order.addresses[0].cityId)) {
            order.addresses[0].cityName = this.allCities.find(x => x.cityId == order.addresses[0].cityId).cityName;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (!isNullOrUndefined(order.addresses[0].countryId)) {
            order.addresses[0].countryName = this.countries.find(x => x.countryId == order.addresses[0].countryId).name;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (this.editedSKUsArray !== undefined) {
            // orderExcelItem.SKUs = this.editedSKUsArray;
            console.log(orderExcelItem.SKUs);
            if (this.invalidSku.length > 0) {
                order.isValid = false;
                orderExcelItem.isValid = this.importForm.form.invalid;
                this.hasSKUErrors = true;
                this.invalidOrders.length++;
            }
            else {
                order.isValid = true;
                orderExcelItem.isValid = this.importForm.form.valid;
                this.hasSKUErrors = false;
                this.invalidSku = [];
                orderExcelItem.invalidSku = this.invalidSku;
                this.ConfirmButtonDisabled = false;
                if (this.invalidOrders.length > 0) {
                    this.invalidOrders.length--;
                }
            }
        }
        this.isEditOn = !this.isEditOn;
        this.invalidOrders = this.orders.filter(c => c.isValid === false);
        if (this.invalidOrders.length === 0) {
            this.ConfirmButtonDisabled = false;
        }
    }
    saveNewDataNew(order: OrderExcelListItem) {
        var shipperReferenceList: any[] = [];
        for (let i = 0; i < this.orders.length; i++) {
            if (shipperReferenceList.find(x => x.toString() === this.orders[i].shipperReference)) {
                continue;
            }
            else {
                shipperReferenceList.push(this.orders[i].shipperReference);
            }
        }
        var orderExcelItem = this.orders.find(x => x == order);
        orderExcelItem.shipmentCurrencyId = order.shipmentCurrencyId;
        orderExcelItem.codCurrencyId = order.codCurrencyId;
        orderExcelItem.shipperId = order.shipperId;


        var checkReferenceIfFound = this.shipperReferences.findIndex(x => x.toString().toLowerCase() == orderExcelItem.shipperReference.toString().toLowerCase())
        if (checkReferenceIfFound >= 0) {
            order.isValid = false;
        }
        orderExcelItem.shipperReference = order.shipperReference;
        orderExcelItem.specialInstructions = order.specialInstructions;

        if (order.shipperId) {
            const shipperContact = this.contacts.find(x => x.contactId == order.shipperId);
            orderExcelItem.shipper = shipperContact.fullName;
            orderExcelItem.shipperCityId = shipperContact.cityId;
            orderExcelItem.shipperAddressId = shipperContact.addressId;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        var countryIdRetreived = this.countries.find(x => x.countryPhoneCode == order.phoneCountryCode);

        if (countryIdRetreived) {
            const mobile = order.phoneNumber;
            const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
            if (validatedPhone == '') {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }
        if (!isNullOrUndefined(order.addresses[0].cityId)) {
            order.addresses[0].cityName = this.allCities.find(x => x.cityId == order.addresses[0].cityId).cityName;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (!isNullOrUndefined(order.addresses[0].countryId)) {
            order.addresses[0].countryName = this.countries.find(x => x.countryId == order.addresses[0].countryId).name;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        for (let i = 0; i < shipperReferenceList.length; i++) {
            var filteredOrders = this.orders.filter(x => x.shipperReference === order.shipperReference);
            var filteredOrdersSKU = filteredOrders.filter(x => x.shipperReference === order.shipperReference && x.SKUs[0] === order.SKUs[0]);
            filteredOrders.forEach(forder => {
                if (!isNullOrUndefined(forder.addresses[0].cityId)) {
                    forder.addresses[0].cityName = this.allCities.find(x => x.cityId == filteredOrders[0].addresses[0].cityId).cityName;
                    orderExcelItem.isValid = this.importForm.form.valid;
                    forder.isValid = true;
                }
                else {
                    orderExcelItem.isValid = this.importForm.form.invalid;
                    forder.isValid = false;
                }
                if (!isNullOrUndefined(forder.addresses[0].countryId)) {
                    forder.addresses[0].countryName = this.countries.find(x => x.countryId == filteredOrders[0].addresses[0].countryId).name;
                    orderExcelItem.isValid = this.importForm.form.valid;
                    forder.isValid = true;
                }
                else {
                    orderExcelItem.isValid = this.importForm.form.invalid;
                    forder.isValid = false;
                }
                if (filteredOrdersSKU.length > 1) {
                    order.isValid = false;
                    if (!isNullOrUndefined(order.invalidSku)) {
                        order.invalidSku.push({name: 'ERROR DUPLICATE', currentStock: NaN});
                        this.hasDuplicatedSKUs = true;
                    }
                }
                else {
                    order.isValid = true;
                    order.invalidSku = [];
                }
                // THIS NEEDS A REWORK
                if (!isNullOrUndefined(forder.invalidSku) && forder.invalidSku.length > 0) {
                    forder.isValid = false;
                    this.hasSKUErrors = true;
                    this.invalidOrders.length++;
                }
                else {
                    orderExcelItem.isValid = true;
                    orderExcelItem.isValid = this.importForm.form.valid;
                    this.hasSKUErrors = false;
                    orderExcelItem.invalidSku = this.invalidSku;
                    this.ConfirmButtonDisabled = false;
                    if (this.invalidOrders.length > 0) {
                        this.invalidOrders.length--;
                    }
                    if (!isNullOrUndefined(orderExcelItem.invalidSku) && orderExcelItem.invalidSku.length > 0) {
                        orderExcelItem.isValid = false;
                        orderExcelItem.isValid = this.importForm.form.invalid;
                        this.hasSKUErrors = true;
                        this.invalidOrders.length++;
                    }
                    if (filteredOrders.length > 1) {
                        if (isNullOrUndefined(orderExcelItem.SKUs[0])) {
                            orderExcelItem.isValid = false;
                            orderExcelItem.invalidSku = [];
                            orderExcelItem.invalidSku.push({name: 'Empty SKU', currentStock: NaN});
                            this.hasSKUErrors = true;
                        }
                    }
                    
                }
            });
            if (!isNullOrUndefined(orderExcelItem.SKUs[0]) && orderExcelItem.SKUs[0].length >= 0 && isNullOrUndefined(orderExcelItem.SKUQty)) {
                orderExcelItem.isValid = false;
                this.hasSKUErrors = true;
            }
            else {
                this.hasSKUErrors = false;
            }
        }
        this.isEditOn = !this.isEditOn;
        this.invalidOrders = this.orders.filter(c => c.isValid === false);
        if (this.invalidOrders.length === 0) {
            this.ConfirmButtonDisabled = false;
        }
        if (!this.orders.some(o => !isNullOrUndefined(o.invalidSku) && o.invalidSku.some(sku => sku.name === 'ERROR DUPLICATE'))) {
            this.hasDuplicatedSKUs = false;
        }
    }
    skuChanged($event): void { // Function checks evrytime there's a change within the input text field.
        const editedSkus: SkuStock[] = [];
        const duplicatedCheck = {};
        const regex = /^(.*?)(?:\((\d+)\))?$/i;
        this.editedSKUsArray = $event.split(',');
        for (let i = 0; i < this.editedSKUsArray.length; i++) {
            const matched = this.editedSKUsArray[i].match(regex);
            editedSkus.push({name: matched[1], currentStock: parseInt(matched[2]) || 0});
        }
        this.invalidSku = this.orders.find(o => o.SKUs.length > 0).invalidSku;
        this.invalidSku = editedSkus.filter(
            editedSku => !this.existingSkus.some(
                existingSku => editedSku.name === existingSku.name && 
                editedSku.currentStock <= existingSku.currentStock));
        if (editedSkus.some(sku => sku.name === '')) {
            this.invalidSku = [];
        }
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        else {
            this.hasSKUErrors = false;
        }
        this.hasSKUErrors = editedSkus.some(duplicateSku => {
            if(duplicatedCheck.hasOwnProperty(duplicateSku.name)) {
                this.invalidSku.push(duplicateSku);
                return true;
            }
            return (duplicatedCheck[duplicateSku.name] = false);
        });
        console.log(this.invalidSku, editedSkus, this.editedSKUsArray);
    }
    skuChangedName($event) {
        const duplicatedSku = this.duplicatedSKU;
        const filteredOrders = this.orders.filter(o => o.shipperReference === this.orders[this.editIndex].shipperReference);
        const editedSku: SkuStock[] = [];
        editedSku.push({name: $event, currentStock: this.orders[this.editIndex].SKUQty});
        this.invalidSku = editedSku.filter(editSku => !this.existingSkus.some(existing => editSku.name === existing.name && $event !== ''));
        this.orders[this.editIndex].invalidSku = this.invalidSku;
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        if (isNullOrUndefined($event) || $event === '') {
            filteredOrders.forEach(order => {
                order.isValid = false;
                order.invalidSku = [];
                order.invalidSku.push({name: 'Empty SKU', currentStock: NaN});
            });
            this.hasSKUErrors = true;
        }
        if (this.hasDuplicatedSKUs && this.orders[this.editIndex].invalidSku.length === 0) {
            this.orders[this.editIndex].isValid = true;
            const filterRef = this.orders.filter(order => order.shipperReference === this.orders[this.editIndex].shipperReference);
            const filterInvalidSKU = filterRef.filter(order => order.SKUs[0] === duplicatedSku);
            if(filterInvalidSKU.length > 1) {
                for (let i = 0; i < 1; i++) {
                    filterInvalidSKU[i].invalidSku = [];
                    filterInvalidSKU[i].isValid = true;
                }
            }
            else {
                filterInvalidSKU.forEach(order => {
                    order.invalidSku = [];
                    order.isValid = true;
                });
            }
        }
        else {
            this.hasSKUErrors = false;
        }
    }
    skuChangedQty($event) {
        const editedSku: SkuStock[] = [];
        editedSku.push({name: this.orders[this.editIndex].SKUs[0], currentStock: $event});
        this.invalidSku = editedSku.filter(editSku => !this.existingSkus.some(existing => editSku.name === existing.name && 
            editSku.currentStock <= existing.currentStock));
        if (editedSku.some(sku => sku.name === '')) {
            this.invalidSku = [];
        }
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        else {
            this.hasSKUErrors = false;
        }
    }
    editIn(index: number) {
    }
    onKeyUpEvent(event, rowIdx): boolean {
        if (this.shipperReferences.findIndex(x => x.toString().toLowerCase() == event.target.value.toString().toLowerCase()) >= 0) {
            const countryCodeControl = this.importForm.controls['txtshipperReference-' + rowIdx];
            countryCodeControl.setErrors({ 'duplicate': true })
            return false;
        }
        else {
            const countryCodeControl = this.importForm.controls['txtshipperReference-' + rowIdx];
            countryCodeControl.setErrors(null);
            return true;
        }
    }


    protected filterCities() {
        if (!this.allCities) {
            return;
        }
        let search = this.cityFilterCtrl.value;
        if (!search) {
            // this.getNextBatch();
            this.filteredCities.next(this.allCities.slice());
            return;
        } else {
            search = search.toString().toLowerCase();
        }
        // this.getNextBatch(search);
        this.filteredCities.next(
            this.allCities.filter(city => city.displayName.toString().toLowerCase().indexOf(search) > -1)
        );
    }
    protected filterCountries() {
        if (!this.countries) {
            return;
        }
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toString().toLowerCase();
        }
        this.filteredCountries.next(
            this.countries.filter(country => country.displayName.toLowerCase().indexOf(search) > -1)
        );
    }
    protected filterCountryCodes() {
        if (!this.countryCodes) {
            return;
        }
        let search = this.countryCodeFilterCtrl.value;
        if (!search) {
            this.filteredCountryCodes.next(this.countryCodes.slice());
            return;
        } else {
            search = search.toString().toLowerCase();
        }
        this.filteredCountryCodes.next(
            this.countryCodes.filter(country => country.countryPhoneCode.toString().toLowerCase().indexOf(search) > -1)
        );
    }
    numberOnly(event): boolean {
        var key = window.event ? event.keyCode : event.which;
        if (event.keyCode === 8 || event.keyCode === 46) {
            return true;
        } else if (key < 48 || key > 57) {
            return false;
        } else {
            return true;
        }
        // const charCode = event.which ? event.which : event.keyCode;
        // const invalidChars = ['-', '+', 'e'];
        // const valid = ['.'];
        // if (valid.includes(event.key)) {
        //     console.log(true);
        //     return true;
        // }
        // if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
        //     console.log(false);
        //     return false;
        // }
        // console.log(true);
        // return true;
    }

    ValidateImportedOrders(): OrderExcelListItem[] {

        if (!this.importForm) 
            return;
        for (var _i = 0; _i < this.invalidOrders.length; _i++) {
            Object.keys(this.importForm.controls).filter(key => key.endsWith('-' + _i)).forEach(key => {
                const abstractControl = this.importForm.controls[key];
                if (abstractControl.invalid)
                    this.invalidOrders[_i].isValid = false;
                if (key.startsWith('txtMobile')) {
                    var phoneCountry = this.countries.find(country => country.countryPhoneCode == this.invalidOrders[_i].phoneCountryCode)
                    const countryCodeControl = this.importForm.controls['ddlCountryCode-' + _i];
                    const mobileControl = this.importForm.controls['txtMobile-' + _i];
                    if (phoneCountry) {
                        var country = new FormControl({ iso: phoneCountry }, Validators.required);
                        var phoneNumberValid = new FormControl(false);

                        countryCodeControl.setValue(phoneCountry.countryPhoneCode);
                        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
                    }
                    else {
                        countryCodeControl.setErrors({ 'invalid': true })
                        mobileControl.setErrors({ 'invalid': true })
                    }
                }
                // else if (key.startsWith('txtCodAmount2')) {

                //     console.log('txtCodAmount2');
                //     const codCurrencyControl = this.importForm.controls['ddlCodCurrencyId2-' + _i];
                //     const codControl = this.importForm.controls['txtCodAmount2-' + _i];
                //     console.log('txtCodAmount2');
                //     if (codControl.value > 0) {
                //         codCurrencyControl.setValidators([Validators.required]);
                //     }
                // }
            });
        }

        return this.invalidOrders;
    }
    changeCountry(index, event) {
        if (event.value != 0) {
            this.invalidOrders[index].addresses[0].cityId = null;
            //this.invalidOrders[index].addresses[0].cities = this.allCities.filter(x => x.countryId == event.value);
        }
    }
    changeCountryCode(index, event) {
        var key = Object.keys(this.importForm.controls).filter(key => key.startsWith('txtMobile-' + index));
        const abstractControl = this.importForm.controls[key[0]];
        var phoneCountry = this.countries.find(country => country.countryPhoneCode == event.value)
        var country = new FormControl({ iso: phoneCountry }, Validators.required);
        var phoneNumberValid = new FormControl(false);
        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
    }
    save(form) {
        setTimeout(() => {
            this.ValidateImportedOrders();
        }, 1000);
        this.importForm.form.updateValueAndValidity();
        this.showProgressBar = false;
    }
    closeDialog() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.matDialogRef.close(['cancel', null]);
    }
    validate(): void {
        this.ValidateImportedOrders();
        this.importForm.form.updateValueAndValidity();
    }
    saveImported(): void {
        this.matDialogRef.close(['new', this.orders.filter(x => x.isValid == true)]);
    }

    // getNextBatch(citySearch?: string) {
    //     if (this.countryCities.length == 0)
    //         this.countryCities = this.allCities;
    //     if (!isNullOrUndefined(citySearch))
    //         var result = this.countryCities.filter(city => city.displayName.toLowerCase().indexOf(citySearch) > -1).slice(0, 0 + this.limit);
    //     else {

    //         var result = this.countryCities.slice(0, 0 + this.limit);
    //     }
    //     this.filteredCities.next(result.slice());
    //     this.options.next(result);
    //     this.offset += this.limit;
    // }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}