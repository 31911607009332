import { Component, OnInit, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { InvoicePayment } from './Invoice-payment.model';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceCheckoutComponent } from './Invoice-checkout/Invoice-checkout.component';
import { fuseAnimations } from '@fuse/animations';
import { InvoicePaymentService } from './Invoice-payment.services';
import { ContactsModule } from '../contacts/contacts.module';

@Component({
    selector: 'invoice-payment',
    templateUrl: './invoice-payment.component.html',
    styleUrls: ['./invoice-payment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InvoicePaymentComponent implements OnInit {
    confirmCheckoutDialogRef: MatDialogRef<InvoiceCheckoutComponent>;
    invoice: InvoicePayment;

    /**
 * Constructor
 *
 * @param {FuseConfigService} _fuseConfigService
 * @param {FormBuilder} _formBuilder
 */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder, public _matDialog: MatDialog,
        private _invoicePaymentService: InvoicePaymentService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }


    ngOnInit(): void {
        this.invoice = this._invoicePaymentService.invoices;
        if (this._invoicePaymentService.invoices.invoices.length > 0)
            this.pay();
    }

    pay(): void {
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this.confirmCheckoutDialogRef = this._matDialog.open(InvoiceCheckoutComponent, {
            panelClass: 'invoice-checkout',
            data: {
                invoice: this._invoicePaymentService.invoices
            }
        });
    }
}
