import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LocationService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
    }
    getCities(q, countryId, excludeCountryId) {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            if (countryId) {
                params = params.append('countryId', countryId.toString());
            }
            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }
            return this._httpClient.get(environment.apiUrl + 'v1/Cities', { params });
        }
        else {
            return EMPTY;
        }
    }
    getCitiesForLocation(q, CityName1, CityName2, countryName) {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            params = params.append('CityName1', CityName1);
            params = params.append('CityName2', CityName2);
            params = params.append('countryName', countryName);
            return this._httpClient.get(environment.apiUrl + 'v1/Cities/Location', { params });
        }
        else {
            return EMPTY;
        }
    }
    getCity(q, countryId, excludeCountryId) {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            if (countryId) {
                params = params.append('countryId', countryId.toString());
            }
            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }
            this._httpClient.get(environment.apiUrl + 'v1/Cities', { params }).subscribe(result => {
                return result[0].cityId;
            });
            ;
        }
        else {
            return 0;
        }
    }
    getStrictCities(q, countryId, excludeCountryId) {
        if (q && typeof q === 'string' && countryId) {
            let params = new HttpParams().append('q', q);
            params = params.append('countryId', countryId.toString());
            if (excludeCountryId) {
                params = params.append('excludeCountryId', excludeCountryId.toString());
            }
            return this._httpClient.get(environment.apiUrl + 'v1/Cities/Strict', { params });
        }
        else {
            return EMPTY;
        }
    }
    getCountries(q, allowedCountries) {
        if (q && typeof q === 'string') {
            let params = new HttpParams().append('q', q);
            if (allowedCountries) {
                params = params.append('allowedCountries', allowedCountries.join(','));
            }
            return this._httpClient.get(environment.apiUrl + 'v1/Countries', { params });
        }
        else {
            return EMPTY;
        }
    }
    getCountryCitiesFromDB(countryId) {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);
        let params = new HttpParams().append('countryId', countryId);
        return this._httpClient.get(environment.apiUrl + 'v1/Cities/Country', { params });
        // } else {
        //     return EMPTY;
    }
    getCountriesFromDB() {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);
        return this._httpClient.get(environment.apiUrl + 'v1/Countries/DB');
        // } else {
        //     return EMPTY;
    }
    getCitiesFromDB() {
        // if (q && typeof q === 'string') {
        //     let params = new HttpParams().append('q', q);
        return this._httpClient.get(environment.apiUrl + 'v1/Cities/DB');
        // } else {
        //     return EMPTY;
    }
}
LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.HttpClient)); }, token: LocationService, providedIn: "root" });
