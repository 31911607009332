import { NgModule } from '@angular/core';
import { ContactListFormComponent } from './c-list-form.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
// import { ContactFormAddressComponent } from './c-list-form-address/contact-form-address.component';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';

@NgModule({
    declarations: [ContactListFormComponent],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        MatToolbarModule,
        FuseConfirmDialogModule,
        MatDialogModule,
        AutoCompleteModule,
        AutoCompleteLocationModule
    ],
    entryComponents: [ContactListFormComponent]
})
export class ContactListFormModule {}
