<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="m-0 mr-16 secondary-text">{{ shipmentIcon}}</mat-icon>
        <div class="h2 secondary-text">{{ ServiceDirection[orderView.orderInfo.serviceDirection] }} Shippment</div>
    </div>


    <table class="simple">
        <tbody>
            <tr>
                <td class="font-weight-900">Shipper Reference</td>
                <td>{{ orderView.orderInfo.shipperReference }}</td>
            </tr>
            <tr>
                <td class="font-weight-900">Pickup Date</td>
                <td>{{ orderView.orderInfo.pickupDate | date }}</td>
            </tr>

            <tr>
                <td class="font-weight-900">Number Of Pieces</td>
                <td>{{ orderView.orderInfo.numberOfPieces || 'N/A'  }}</td>
            </tr>

            <tr>
                <td class="font-weight-900">Shipment Values</td>
                <td>{{ orderView.orderInfo.shipmentValue || 'N/A' }}</td>
            </tr>

            <tr>
                <td class="font-weight-900">Shipping Type</td>
                <td>{{ orderView.orderInfo.shippingType }}</td>
            </tr>
    

           

            <tr>
                <td class="font-weight-900">Commodity</td>
                <td *ngIf="orderView.orderInfo.commodityId">{{ orderView.orderInfo.commodityId | commodity:serviceTypeId | async }}</td>
                <td *ngIf="!orderView.orderInfo.commodityId">N/A</td>
            </tr>

            <tr>
                <td class="font-weight-900">Weight</td>
                <td>{{ orderView.orderInfo.weight | number:'.2-2' }} Kg</td>
            </tr>

            <tr *ngIf="orderView.orderInfo.width && orderView.orderInfo.height && orderView.orderInfo.length">
                <td class="font-weight-900">Dimension</td>
                <td>
                    {{ orderView.orderInfo.width | number:'.2-2' }} cm x
                    {{ orderView.orderInfo.height | number:'.2-2' }} cm x
                    {{ orderView.orderInfo.length | number:'.2-2' }} cm
                </td>
            </tr>

            <tr>
                <td class="font-weight-900">Goods Description</td>
                <td> {{ orderView.orderInfo.goodsDescription || 'N/A' }}</td>
            </tr>

            <tr>
                <td class="font-weight-900">Special Instructions</td>
                <td> {{ orderView.orderInfo.specialInstructions || 'N/A' }}</td>
            </tr>
        </tbody>
    </table>


</div>



<div class="section pb-48" *ngIf="orderView.orderCods && orderView.orderCods.length > 0" >
    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
    </div>
    <div class="Cod mb-32">
        <table class="simple">
            <thead>
                <tr>
                    <th>Cod Amount</th>
                    <th>Currency</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cod of orderView.orderCods">
                    <td>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <p class="name text-truncate ">
                                    {{cod.codAmount}}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <p class="text-truncate">
                            <span class="name text-truncate">
                                {{cod.codCurrency}}
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>