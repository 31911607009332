<ng-container [formGroup]="group">
    <div class="row">
        <div class="col-xs-12 col-md-8">
            <mat-form-field class="w-100-p">
                <mat-label>Select Commodity</mat-label>
                <mat-select name="mynet-order-commodity" formControlName="commodityId" required>
                    <mat-option *ngFor="let commodity of commodities" [value]="commodity.commodityId">
                        <span class="text-truncate">{{ commodity.title }}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="group.get('commodityId').hasError('required')">Commodity is required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-md-8">
            <mat-form-field class="w-100-p">
                <mat-label>Description of goods</mat-label>
                <textarea matInput rows="3" formControlName="descriptionOfGoods"
                    name="mynet-order-descriptionOfGoods"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-md-8">
            <mat-form-field class="w-100-p">
                <mat-label>Number Of Pieces</mat-label>
                <input matInput name="mynet-order-numberOfPieces" formControlName="numberOfPieces"
                    (keypress)="numberOnly($event)" required type="number" min="1">
                <mat-error *ngIf="group.get('numberOfPieces').hasError('required')">Number Of Pieces is required
                </mat-error>

            </mat-form-field>
        </div>
    </div>
</ng-container>

<div class="action-buttons">
    <button mat-raised-button *ngIf="form.value.activeListItem > 0" (click)="back()">Back</button>
    <button mat-raised-button color="primary" *ngIf="form.value.activeListItem < 6"
        (click)="validateAndContinue()">Continue</button>
</div>

<div class="mt-8" *ngIf="submited">
    <mat-error *ngIf="group.get('commodityId').hasError('required')">Commodity is required</mat-error>
    <mat-error *ngIf="group.get('numberOfPieces').hasError('required')">Number Of Pieces is required</mat-error>
</div>