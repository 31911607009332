import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Address } from 'app/main/contacts/contact-form/contact.model';
import { NetPointService } from './netpoint.services';

@Component({
    selector: 'netpoint-locations',
    templateUrl: './netpoint-locations.component.html',
    styleUrls: ['./netpoint-locations.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NetPointLocationsComponent implements OnInit, OnDestroy, AfterViewInit {
    loading = true;
    constructor(
        public matDialogRef: MatDialogRef<NetPointLocationsComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _netpointService: NetPointService
    ) {
        this.netpoints = _netpointService.netpoints;
        if (this.netpoints && this.netpoints.length > 0) {
            this.loading = false;
        }
    }
    protected _unsubscribeAll = new Subject<void>();

    netpoints: Address[];

    // Workaround for angular component issue #13870
    disableAnimation = true;

    ngOnInit(): void {
        this._netpointService.onNetPointsRetreived.subscribe(response => {
            this.netpoints = response;
            this.loading = false;
        });
    }

    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
