<div class="dialog-content-wrapper" fxFlex style="flex:1">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Print Distribution
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <form [formGroup]="printDistributionForm">
            <div class="pt-16 pb-16 w-100-p">
                <div class="row">
                    <mat-form-field class="col-xs-12 col-md-12">
                        <mat-label>
                            Pages per Sheet
                        </mat-label>
                        <mat-select formControlName="pagesPerSheet" name="mynet-distribution-pagesPerSheet"  required>
                            <mat-option *ngFor="let page of pagesTypes" [value]="page.value" >
                                {{page.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <button mat-button class="mr-8" (click)="matDialogRef.close(['cancel'])" aria-label="Cancel"
            matTooltip="Cancel Print Distribution">
            CANCEL
        </button>
        <button mat-raised-button class="save-button" color="primary" (click)="confirm()"
            [disabled]="printDistributionForm.invalid" aria-label="Confirm">
            CONFIRM
        </button>

    </mat-dialog-actions>
</div>

<!-- <button *ngIf="action === 'edit'" mat-raised-button class="save-button" color="primary" (click)="saveExisting()"
            [disabled]="contactExist || (contactForm.invalid && !contactForm.dirty)" aria-label="Save Contact">
            SAVE
        </button> -->