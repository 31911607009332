<!-- <style>
    @page {
        size: A5
    }
</style> -->


<div id="invoice" class="compact page-layout blank" fxLayout="column">
    <div class="invoice-container">
        <div class="doNotPrint">
            <button mat-flat-button color="primary" aria-label="back" [routerLink]="'/orders'">
                <mat-icon>back</mat-icon>
                <span>Back</span>
            </button>
            <button mat-flat-button color="primary" aria-label="print" (click)="onPrint()">
                <mat-icon>print</mat-icon>
                <span>Print</span>
            </button>
        </div>
        <div class="A5">
            <div class="card" Id="print-section">
                <div *ngFor="let a of pageNumber(order.orderInfo.numberOfPieces) ">
                    <!-- <div class="shipper"> {{order}}
                    </div> -->
                    <div class="header">
                        <div fxLayout="column" fxLayoutAlign="space-between stretch">
                            <div class="client">
                                <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                    <img width="137" height="137" src="assets/images/logos/logo.svg">
                                    <ngx-barcode [bc-value]="order.label" [bc-margin-left]="100" [bc-margin-top]="50"
                                        [bc-height]="50" [bc-width]="3" [bc-display-value]="true"></ngx-barcode>
                                </div>
                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title">Shipper</span>
                                        </div>
                                        <div *ngIf="order.shipper.companyName.length > 0" class="shipper"><strong
                                                style="font-size:  larger;"> {{order.shipper.companyName}}</strong>
                                        </div>

                                        <div class="shipper">{{order.shipper.fullName}}</div>
                                        <div class="address">{{order.shipper.address.display}}</div>
                                        <div class="address">{{order.shipper.address.street}}</div>
                                        <div class="landmark">{{order.shipper.address.landmark}}</div>
                                    </div>
                                    <div class="shipper2">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="address">Ref: {{order.orderInfo.shipperReference}}</div>
                                        <div class="address">{{order.shipper.phoneNumber}}</div>
                                        <div class="landmark">{{order.shipper.email}}</div>
                                    </div>
                                </div>

                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title">Receiver</span>
                                        </div>
                                        <div *ngIf="order.receiver.companyName.length > 0" class="shipper"><strong
                                                style="font-size:  larger;"> {{order.receiver.companyName}}</strong>
                                        </div>
                                        <div class="shipper">{{order.receiver.fullName}}</div>
                                        <div class="address">{{order.receiver.address.display}}</div>
                                        <div class="address">{{order.receiver.address.street}}</div>
                                        <div class="landmark">{{order.receiver.address.landmark}}</div>
                                        <div class="landmark">Floor: {{order.receiver.address.floor}}</div>
                                        <div class="landmark">Building: {{order.receiver.address.building}}</div>
                                    </div>
                                    <div class="shipper2">
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                            <span class="title"></span>
                                        </div>
                                        <div class="address">{{order.receiver.phoneNumber}}</div>
                                        <div class="landmark">{{order.receiver.email}}</div>
                                        <div class="landmark">{{order.receiver.address.zipCode}}</div>
                                    </div>
                                </div>

                                <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">

                                        <div class="shipper">Weight: {{order.orderInfo.weight}}</div>
                                        <div class="address">Pieces: {{order.orderInfo.numberOfPieces}}</div>
                                        <div class="landmark">Service: {{order.orderInfo.shippingType}}</div>
                                        <div class="landmark">Description: {{order.orderInfo.goodsDescription}}</div>
                                        <!-- <div class="landmark" >Special Instructions: {{order.orderInfo.specialInstructions}}</div> -->
                                    </div>
                                    <div class="shipper2">
                                        <div class="Cod" *ngIf="order.orderCods && order.orderCods.length > 0">
                                            <table class="simple">
                                                <tbody>
                                                    <tr *ngFor="let cod of order.orderCods">

                                                        <div class="shipper">Cod Amount {{cod.codCurrency}}:
                                                            {{cod.codAmount}}
                                                        </div>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="Cod">Special Instructions: {{order.orderInfo.specialInstructions}}
                                        </div>
                                    </div>
                                </div>
                                <div class="info">
                                    <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->

                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <div class="small-note">
                                            *1$ and rounding fees will be applied to Total Price*
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <div class="contact-note">
                                You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit our
                                website www.thenet.group
                            </div>
                        </div>
                        <div style="font-size: large;" fxLayout="row" fxLayoutAlign="end end" >{{a}} of {{order.orderInfo.numberOfPieces}}</div>

                        <div class="pagebreak"> </div>

                        <div *ngIf="order.proformaDetails.length > 0" fxLayout="column"
                            fxLayoutAlign="space-between stretch">
                            <div class="client">
                                <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                    <span style="font-size: xx-large;" class="title">Proforma Invoice </span>
                                </div>
                                <div class="proforma" fxLayout="row" fxLayoutAlign="start start">
                                    <div class="shipper1">
                                        <div class="sentBy" fxLayout="row" fxLayoutAlign="start center">
                                            <span>Sent By</span>
                                        </div>
                                        <div class="detailInfo">
                                            <div><span class="detailHeader">Name: </span> {{order.shipper.fullName}}
                                            </div>
                                            <div><span class="detailHeader">Address:
                                                </span>{{order.shipper.address.display}}
                                            </div>
                                            <div><span class="detailHeader">City:
                                                </span>{{order.shipper.address.cityName}}
                                            </div>
                                            <div><span class="detailHeader">Country:
                                                </span>{{order.shipper.address.countryName}}
                                            </div>
                                            <div><span class="detailHeader">Mobile: </span>{{order.shipper.phoneNumber}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="shipper2">
                                        <div class="sentBy" fxLayout="row" fxLayoutAlign="start center">
                                            <span>Sent To</span>
                                        </div>
                                        <div class="detailInfo">
                                            <div><span class="detailHeader">Name: </span> {{order.receiver.fullName}}
                                            </div>
                                            <div><span class="detailHeader">Address:
                                                </span>{{order.receiver.address.display}}
                                            </div>
                                            <div><span class="detailHeader">City:
                                                </span>{{order.receiver.address.cityName}}
                                            </div>
                                            <div><span class="detailHeader">Country:
                                                </span>{{order.receiver.address.countryName}}
                                            </div>
                                            <div><span class="detailHeader">Mobile:
                                                </span>{{order.receiver.phoneNumber}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table class="simples">
                                    <thead>
                                        <tr>
                                            <th>Full Description Of Goods</th>
                                            <th>Quantity</th>
                                            <th>Origin Country</th>
                                            <th>Customs Commodity Code</th>
                                            <th>Unit Value</th>
                                            <th>Total Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let proforma of order.proformaDetails">
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.description}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.quantity}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.countryName}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.customsCommodityCode}}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.unitValue}} $
                                                </span>
                                            </td>
                                            <td>
                                                <span class="name text-truncate">
                                                    {{proforma.totalValue}} $
                                                </span>
                                            </td>
                                        </tr>
                                        <tr style="font-weight: bold; border : none ; color: rgb(0, 0, 0);">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total: </td>
                                            <td>{{ proformaSum }}
                                        </tr>
                                        <tr style="font-weight: bold; border: none;">
                                            <td>Date : <span>{{proformaDate | date: 'dd-MM-yyyy'}}</span></td>
                                            <!-- <td></td>
                                    <td>ISSUE BY:..............................................</td> -->
                                        </tr>
                                        <tr style="font-weight: bold; border: none;">
                                            <td>ISSUE BY:..............................................</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>