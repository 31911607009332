import { City } from 'app/_models/city.model';

export class ContactExcelListItem {
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    phoneCountryCode: string;
    companyName: string;
    validPhoneNumber: boolean;
    tags: string[];
    addresses: Address[];
    importId: number;
    isValid: boolean;

    constructor(contact?) {
        contact = contact || {};
        this.contactId = contact.contactId || 0;
        this.firstName = contact.firstName || '';
        this.lastName = contact.lastName || '';
        this.email = contact.email || '';
        this.phoneNumber = contact.phoneNumber || '';
        this.phoneCountryCode = contact.phoneCountryCode || '';
        this.companyName = contact.companyName || '';
        this.tags = contact.tags || [];
        this.addresses = contact.addresses || [new Address()];
        this.validPhoneNumber = contact.validPhoneNumber || false;
        this.importId = contact.importId || 0;
        this.isValid = contact.isValid || false;
    }
}

export class Address {
    addressId: number;
    countryId: number;
    countryName: string;
    cityId: number;
    cityName: string;
    streetAddress1: string;
    streetAddress2: string;
    region: string;
    zipCode: string;
    display: string;
    street: string;
    building: string;
    floor: string;
    landmark: string;
   // cities:City[]

    constructor(address?) {
        address = address || {};
        this.addressId = address.addressId || 0;
        this.countryId = address.countryId;
        this.cityId = address.cityId;

        this.countryName = address.countryName || '';
        this.cityName = address.cityName || '';
        this.streetAddress1 = address.streetAddress1 || '';
        this.streetAddress2 = address.streetAddress2 || '';
        this.region = address.region || '';
        this.zipCode = address.zipCode || '';
        this.display = address.display || '';

        this.street = address.street || '';
        this.building = address.building || '';
        this.floor = address.floor || '';
        this.landmark = address.landmark || '';
        //this.cities = address.cities || [];
    }
}

