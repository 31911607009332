import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Address, Contact } from 'app/main/contacts/contact-form/contact.model';
import { ContactsContactListService } from 'app/main/contacts/contact-list/contact-list.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, NgForm } from '@angular/forms';
import { OrderDetail, BillingDetails, CashOnDelivery, OrderSurcharges, Piece, OrderSku, SkuStock } from '../order.model';
import { scan, take, takeUntil } from 'rxjs/operators';
import { Countries, InternationalQuote } from 'app/main/quotes/international/international.model';
import { ServiceType, CommodityProductType, CurrencyType } from 'app/_enums/ServiceType.enum';
import { Entity } from 'app/_models/Entity.model';
import { QuoteService } from 'app/main/quotes/quote.service';
import { DomesticQuote } from 'app/main/quotes/domestic/domestic.model';
import { QuoteData } from '../quote-data.model';
import { NetPointLocationsComponent } from '../netpoint-locations/netpoint-locations.component';
import { NetPointService } from '../netpoint-locations/netpoint.services';
import { OrderService } from '../../order.service';
import { ContactEditorComponent } from 'app/main/contacts/contact-editor/contact-editor.component';
import { CommodityService, Commodity } from 'app/_shared/commodity.service';
import { WeightValidator } from 'app/_validators/weight.validator';
import { DecimalPipe } from '@angular/common';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Router } from '@angular/router';
import { ConfirmOrderComponent } from './confirm-order/confirm-order.component';
import { ConfirmCheckoutComponent } from '../payment/confirm-checkout/confirm-checkout.component';
import { Routes } from 'app/Routes';
import { LocationService } from 'app/_shared/location-service.service';
import { Country } from 'app/_models/country.model';
import { UserDetails } from 'app/main/profile/profile.model';
import { Quote } from '@angular/compiler';
import { HeardAboutUsDialogComponent } from 'app/main/profile/heard-about-us-dialog/heard-about-us-dialog.component';
import { ConfirmDimesionsComponent } from 'app/main/profile/confirm-dimensions/confirm-dimensions';
import { isNullOrUndefined } from 'util';
import { getType } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { OrderProformaComponent } from './order-proforma/order-proforma.component';
import { Console } from 'console';
import { constant, first, isNull } from 'lodash';
import { StatusValidator } from 'app/_validators/status.validator';
import { SkuValidator } from 'app/_validators/sku.validator';
import { X } from '@angular/cdk/keycodes';
import { CurrencyValidator } from 'app/_validators/currency.validator';
interface MyType {
    id: string;
    label: string;
}
@Component({
    selector: 'orders-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersOrderDetailsComponent implements OnInit, OnDestroy {
    @Input() group: FormGroup;
    @Input() hasSubsidiaryId = true;

    @Input() newQuote: FormControl;

    @Output() submited: EventEmitter<any> = new EventEmitter(true);
    @Output() canceled: EventEmitter<any> = new EventEmitter(true);
    @Output() confirmed: EventEmitter<any> = new EventEmitter(true);

    @ViewChild('proformaForm', { static: false }) proformaForm: NgForm

    myInfo: any;
    contacts: ContactListItem[] = [];

    // confirmCheckoutDialogRef: MatDialogRef<ConfirmCheckoutComponent>;

    confirmOrderDialogRef: MatDialogRef<ConfirmOrderComponent>;

    confirmDimension: MatDialogRef<ConfirmDimesionsComponent>;

    orderDetailComponent: OrdersOrderDetailsComponent;


    shipperAddresses: Address[] = [];
    receiverAddresses: Address[] = [];
    AddressType = AddressType;

    shippingTypes: Entity<number>[];
    timeRanges: Entity<number>[];
    commodities: Commodity[];

    ServiceDirection = ServiceDirection;

    addingShipper = false;
    addingReceiver = false;
    isExport = false;
    generatingQuote = false;
    confirmingOrder = false;
    loadingShippingTypes = false;
    ServiceType = ServiceType;

    private _onInitShipper = false;
    private _onInitReceiver = false;

    protected _unsubscribeAll = new Subject<void>();
    contactDialogRef: MatDialogRef<ContactEditorComponent>;
    netpointLocationsDialogRef: MatDialogRef<NetPointLocationsComponent>;

    isNetPointShipper = false;
    isNetPointReceiver = false;
    minDate = new Date(new Date().setDate(new Date().getDate()));
    toNetPointButtonText = 'To NetPoint?';
    fromNetPointButtonText = 'From NetPoint?';

    shipperAddressLabel = 'Shipper Address';
    receiverAddressLabel = 'Receiver Address';

    currentDirection: ServiceDirection;
    maxWeight: number;
    showDimensionsHelp = false;
    countries: Country[];
    // orderCod: CashOnDelivery[] = [];
    orderSurcharges: OrderSurcharges[] = [];
    // surchargesFromOrder: OrderSurcharges[];

    proformaPanelState: boolean = true;
    editIndex: boolean[] = [];
    chargeableWeight: number;
    currencyTypes: any = {};


    formLoaded: boolean = false;

    domesticCountryId = 158;

    domesticCountryName = "Lebanon";

    wmsSKUs: SkuStock[];

    orderSKUs: FormGroup;

    loadingSurcharges: boolean;







    contactDisplayCount = 5;
    public shipperFilterCtrl: FormControl = new FormControl();
    public receiverFilterCtrl: FormControl = new FormControl();

    // public filteredContacts: ReplaySubject<ContactListItem[]> = new ReplaySubject<ContactListItem[]>();

    shipperOptions: BehaviorSubject<ContactListItem[]> = new BehaviorSubject<ContactListItem[]>([]);
    shipperOptions$: Observable<ContactListItem[]>;

    receiverOptions: BehaviorSubject<ContactListItem[]> = new BehaviorSubject<ContactListItem[]>([]);
    receiverOptions$: Observable<ContactListItem[]>;

    limit = 10;
    offset = 0;
    filteredList;

    disableEdit = false;
    disableCOD = false;
    isEdit: boolean = false;

    showShipmentValue = true;

    userRole: any;
    loggedInUser: any;
    specialAccount: boolean = false;
    isMFERP: boolean = false;


    constructor(
        private _router: Router,
        private _contactService: ContactsContactListService,
        public _matDialog: MatDialog,
        private _quoteService: QuoteService,
        private _commodityService: CommodityService,
        private _orderService: OrderService,
        private _netpointService: NetPointService,
        private _snackBar: MatSnackBar,
        private _numberPipe: DecimalPipe,
        private _locationService: LocationService,
        private _formBuilder: FormBuilder,
        private dateAdapter: DateAdapter<Date>
    ) {
        this.shipperOptions$ = this.shipperOptions.asObservable().pipe(
            scan((acc, curr) => {
                return [...acc, ...curr];
            }, [])
        );
        this.receiverOptions$ = this.receiverOptions.asObservable().pipe(
            scan((acc, curr) => {
                return [...acc, ...curr];
            }, [])
        );
    }


    get groupValue(): OrderDetail {
        return this.group.getRawValue() as OrderDetail;
    }
    get selectedShipper(): ContactListItem {
        return this.contacts.find(x => x.contactId === this.groupValue.shipperId);
    }

    get selectedReceiver(): ContactListItem {
        return this.contacts.find(x => x.contactId === this.groupValue.receiverId);
    }

    get selectedShipperAddress(): Address {
        return this.shipperAddresses.find(x => x.addressId === this.groupValue.shipperAddressId);
    }
    get orderDetail(): OrderDetail {
        const order = new OrderDetail(this.group.getRawValue());
        order.quote.productTypeId = ServiceType.Parcels;
        order.shipperName = this.contacts.find(c => c.contactId === order.shipperId).fullName;
        order.receiverName = this.contacts.find(c => c.contactId === order.receiverId).fullName;
        order.shipperAddress = this.toLongDisplayString(
            this.shipperAddresses.find(c => c.addressId === order.shipperAddressId)
        );
        order.receiverAddress = this.toLongDisplayString(
            this.receiverAddresses.find(c => c.addressId === order.receiverAddressId)
        );
        return order;
    }

    get quoteCods(): any {
        return (this.group.get('quote').get('cods') as FormArray).controls;
    }
    get orderPieces(): any {
        return (this.group.get('quote').get('pieces') as FormArray).controls;
    }
    get selectedReceiverAddress(): Address {
        return this.receiverAddresses.find(x => x.addressId === this.groupValue.receiverAddressId);
    }
    get quoteSkus(): any {
        return (this.group.get('quote').get('skus') as FormArray).controls;
    }

    private creatCodForm(element): FormGroup {
        return this._formBuilder.group({
            quoteId: this.group.get('quoteId'),
            codAmount: [element.codAmount, Validators.required],
            codCurrencyId: element.codCurrencyId
        });
    }

    private createCodArray() {
        const arr = [];
        this.group.get('quote').get('cods').value.forEach(element => {
            arr.push(this.creatCodForm(element));
        });
        const quoteForm = this.group.get('quote') as FormGroup;
        quoteForm.setControl('cods', this._formBuilder.array(arr));
    }

    private createSkuForm(element): FormGroup {
        const skuSelected = this.wmsSKUs.filter(x => x.name == element.sku)[0];
        return this._formBuilder.group({
            sku: [element.sku, Validators.required],
            quantity: [element.quantity, [Validators.max(skuSelected.currentStock), Validators.min(1)]],
            available: skuSelected.currentStock
        });
    }

    private createSkuArray() {
        const arr = [];
        const count = this.group.get('quote').get('skus').value.length
        this.group.get('quote').get('skus').value.forEach(element => {
            arr.push(this.createSkuForm(element));
        });
        const quoteForm = this.group.get('quote') as FormGroup
        quoteForm.setControl('skus', this._formBuilder.array(arr));
        // for (let i = 0; i < count; i++) {
        //     arr.push(this.createItem());
        // }
        // const skuSelected = this.wmsSKUs.filter(x=> x.name == event.value)[0];

        // this.availableQuantity = skuSelected.currentStock;
        // this.sku.get('quantity').setValidators([Validators.max(this.availableQuantity), Validators.min(1)]);
    }
    getSkuInventory
    ngOnInit(): void {

        console.log(this.group);
        this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        // console.log(this.group.get('quote').get('cods').value.length);

        this.userRole = localStorage.getItem('role');
        // console.log(this.group.get('quote').get('skus').value);
        // console.log(this.quoteSkus);

        if (this.loggedInUser.countryId === 90) {
            this.domesticCountryId = 90;
        }


        // const skus = this.group.get('quote').get('skus')
        // if (skus.value.length > 0) {
        //     skus.value.forEach(element => {
        //         this.orderSKUs = new FormGroup({
        //             sku: new FormControl(element.sku),
        //             quantity: new FormControl(element.quantity),
        //         });
        //         // console.log(form);
        //         // this.orderSKUs.patchValue(form);
        //     });
        // }
        // console.log(this.orderSKUs);
        // this.group.get('quote').get('skus').setValue(this.orderSKUs);
        // console.log(this.group.get('quote').get('skus') as FormArray);

        // const form = new FormGroup({
        //     sku: new FormControl('sku'),
        //     last: new FormControl('Drew'),
        //   });





        this._onInitReceiver = true;
        this._onInitShipper = true;


        this.isNetPointShipper = this.group.get('isNetPointShipper').value;
        this.isNetPointReceiver = this.group.get('isNetPointReceiver').value;


        this.getAllContacts();
        this.loadSurcharges();
        console.log('getting time ranges');

        this.getTimeRanges();


        this.subscribeShipperChanged();
        this.subscribeReceiverChanged();
        this.subscribeContactsChanged();
        this.subscribeContactAdded();

        this.subscribeShipperAddressChanged();
        this.subscribeReceiverAddressChanged();

        this.subscribeDirectionChanged();
        this.subscribeShippingTypeChanged();

        this.subscribeQuoteChanged();

        this.subscribeShipperReferenceChanged();

        this.setDirection();

        this.getCommodities();

        this.setDecimalValidation();

        this.getCountries();


        this.canUpdateOrder(this.group.get('orderId').value).then(canUpdate => {
            if (canUpdate == null) {
                this.disableEdit = null;
                return;
            }
            this.disableEdit = !canUpdate;
            var pickupDate: Date = this.group.get('pickupDate').value;
            this.minDate = pickupDate;
        })

        this.getCodAmounts();

        // this.setValidations();

        // this.shipperFilterCtrl.valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         this.getNextShipperBatch("");
        //     });
        // this.receiverFilterCtrl.valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         this.getNextReceiverBatch();
        //     });

        this.calculateChargeableWeight();
        this.group.get('quote').get('chargeableWeight').disable();
        // this.group.get('quote').get('numberofpieces').setValidators([Validators.min(0),Validators.max(20) , Validators.max(30)])
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        this.formLoaded = true;

        this.dateAdapter.setLocale('en-GB');


        this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
            this.domesticCountryId = info.countryId;
            this.domesticCountryName = info.countryName;
            this.myInfo = info;
            console.log('getMyInfo');
            console.log(this.myInfo);
        });

        let quoteCountry = this.group.get('quote').get('toCountryId').value;
        if (!isNullOrUndefined(quoteCountry))
            if (quoteCountry != this.domesticCountryId && quoteCountry != 0)
                this.isExport = true;

        const storerKey = localStorage.getItem('netPointKey');

        const quoteForm = this.group.get('quote') as FormGroup;

        this.fetchStock(storerKey, "all")
            .then(() => {
                if (this.group.get('quote').get('skus').value != null)
                    this.createSkuArray();
                else
                    quoteForm.setControl('skus', this._formBuilder.array([]));

            });
        if (this.group.get('quote').get('cods').value !== null && this.group.get('quote').get('cods').value.length > 0) {
            this.createCodArray();
            this.group.get('quote').get('cods').disable();
        }
        if (this.group.get('orderId').value !== 0) {
            this.isEdit = true;
        }
        if (this.isEdit && this.group.get('quote').get('cods').value.length === 0) {
            this.disableCOD = true;
        }

        this.specialAccount = this.loggedInUser.specialAccount === true ? true : false;
        this.isMFERP = this.loggedInUser.isMFERP
        console.log(this.specialAccount);
        console.log(this.domesticCountryId);





        // if(this.canUpdateOrder(this.group.get('orderId').value)){
        //     this.disableEdit = true;
        //     // this.group.get('shipperId').disable();
        //     // this.group.get('shipperAddressId').disable();
        // }
        // else
        //     this.disableEdit = false;

    }


    setValidations() {
        const quoteGroup = this.group.get('quote');
        quoteGroup.get('numberofpieces').setValidators([Validators.min(0),Validators.max(20) , Validators.max(30)])

    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key, disabled: false }));
    }
    calculateChargeableWeight() {
        const pieces = this.group.get('quote').get('pieces') as FormArray;
        var pieceTotalWeight = 0;
        var pieceTotalVolume = 0;
        for (let c of pieces.controls) {
            const pieceValue = c.value as Piece;
            if (this.currentDirection == ServiceDirection.Domestic && this.domesticCountryId !== 90 && this.domesticCountryId !=280) {
                pieceTotalVolume += ((pieceValue.length * pieceValue.width * pieceValue.height) / 4000) * pieceValue.numberOfPieces;
            }
            else
                pieceTotalVolume += ((pieceValue.length * pieceValue.width * pieceValue.height) / 5000) * pieceValue.numberOfPieces;
            pieceTotalWeight += pieceValue.weight;
        }
        if (pieceTotalWeight == 0)
            pieceTotalWeight = 0.5;
        if (pieceTotalVolume == 0)
            pieceTotalVolume = 0.5;
        if (pieceTotalWeight >= pieceTotalVolume)
            this.group.get('quote').get('chargeableWeight').setValue(pieceTotalWeight);
        else
            this.group.get('quote').get('chargeableWeight').setValue(pieceTotalVolume);
    }

    onCheckBoxChanges(e: HTMLInputElement, id: number) {
        this.newQuote.setValue(true);
        const index = this.orderSurcharges.findIndex(_ => _.surchargeId == id);
        if (!(index > -1)) return;

        this.orderSurcharges[index].checked = e.checked;
        if (this.orderSurcharges[index].surchargeId == 3) //insurance
        {
            if (e.checked)
                this.group.get('quote').get('shipmentValue').setValidators(Validators.min(1));
            else
                this.group.get('quote').get('shipmentValue').setValidators(null);
            this.group.get('quote').get('shipmentValue').updateValueAndValidity();
        }
    }

    private setDecimalValidation(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        const length = quoteGroup.get('length');
        const width = quoteGroup.get('width');
        const height = quoteGroup.get('height');

        if (this.currentDirection == ServiceDirection.Domestic) {
            length.setValidators([DecimalValidator.validDecimal(0, 200)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        else {
            length.setValidators([DecimalValidator.validDecimal(0, 150)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }

        const shipmentValue = quoteGroup.get('shipmentValue');

        shipmentValue.setValidators([DecimalValidator.validDecimal(0, 9999999999999999)]);
    }
    addNewCod(): void {
        const cods = this.group.get('quote').get('cods') as FormArray;
        console.log(cods);
        var cod = this._formBuilder.group(new CashOnDelivery());
        // cod.setValidators();
        cod.get('codAmount').setValidators(Validators.min(1));
        cod.get('codCurrencyId').setValidators(Validators.min(1));
        if (cods.length > 0) {
            console.log((cods.at(cods.length - 1) as FormGroup).get('codCurrencyId'));
            cod.get('codCurrencyId').setValidators([Validators.min(1), CurrencyValidator.validCurrency((cods.at(cods.length - 1) as FormGroup).get('codCurrencyId').value)]);
            cod.get('codCurrencyId').setValue(2);
        }
        else {
            cod.get('codCurrencyId').setValue(1);
        }
        cods.push(cod);
        console.log(cods);

    }
    codAdded(data: any): void {
        const codForm = data.value;
        const mode: string = data.mode;
        const index: number = data.index;
        this.group.get('quote').setValidators(StatusValidator.validStatus("disabled"));
        if (mode === 'cancel') {
            // if (index >= 0) {
            //     this.editIndex[index] = false;
            // }
            return;
        }
        else if (mode === 'new') {
            // const pieces = this.group.get('quote').get('pieces') as FormArray;
            // pieces.at(index - 1).setValue(pieceForm);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // if (TotalNOP == 0)
            //     TotalNOP = 1
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            console.log(this.group.get('quote'));
            // const pieces = this.quoteForm.get('pieces') as FormArray;
            // pieces.removeAt(index - 1);
            // var TotalWeight = 0, TotalNOP = 0;

            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // if (TotalNOP == 0)
            //     TotalNOP = 1;
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            // if (pieceForm != undefined && pieceForm.pieceId != 0) {
            //     this._quoteService.deletePieces(pieceForm.pieceId)
            // }
            // if (pieces.controls.length == 0)
            //     pieces.clearValidators()
        }
        // this.calculateChargeableWeight();
    }

    weightChanged(event): void {
        const weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.group
                .get('quote')
                .get('weight')
                .setValue('0.5');
        }
    }

    private getTimeRanges(): void {
        this._orderService.getTimeRanges().subscribe(result => {
            this.timeRanges = result
            const timeRangeLookupId = this.group.get('timeRangeLookupId').value;
            if (isNullOrUndefined(timeRangeLookupId)) {
                this.group.get('timeRangeLookupId').setValue(this.timeRanges[0].id);
            }
        });
    }
    private getCommodities(): void {

        let serviceTypeId: MyNetServiceType;
        this.setDirection();
        switch (this.currentDirection) {
            case ServiceDirection.Domestic:
                serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                serviceTypeId = 0;
                break;
        }
        if (!serviceTypeId) {
            return;
        }
        let forceGetCommodities = false;
        if (this._commodityService.commodities && this._commodityService.commodities.length > 0) {
            const sameServiceType =
                this._commodityService.commodities.filter(x => x.serviceTypeId === serviceTypeId).length > 0;
            if (sameServiceType) {
                this.commodities = this._commodityService.commodities;
            } else {
                forceGetCommodities = true;
            }
        } else {
            forceGetCommodities = true;
        }

        if (forceGetCommodities) {
            this._commodityService.getCommodities(serviceTypeId).then(result => {
                this.commodities = result;
            });
        }
    }


    public getAllContacts(firstTime = true): void {
        this._contactService.getContactsForOrders().then(contacts => {
            this.contacts = contacts;

            if (this.group.value.shipperId == null && firstTime) {
                const defaultShipper = this.contacts.filter(x => x.isDefault == true);
                if (defaultShipper.length > 0) {
                    this.group.get('shipperId').setValue(defaultShipper[0].contactId)
                }
            }

            if (this.group.value.receiverId && firstTime) {
                var result = this.contacts.filter(x => x.contactId == this.group.value.receiverId).slice(0, 0 + this.limit);
                this.receiverOptions.next(result);
                this.getNextReceiverBatch();
                this.receiverChanged(this.group.value.receiverId);
            }
            else {
                this.offset = 0;
                var result = this.contacts.slice(this.offset, this.offset + this.limit);
                this.receiverOptions.next(result);
                this.offset = this.offset + this.limit;
            }
            if (this.group.value.shipperId && firstTime) {

                var result = this.contacts.filter(x => x.contactId == this.group.value.shipperId).slice(0, 0 + this.limit);
                this.shipperOptions.next(result);
                this.getNextShipperBatch();
                this.shipperChanged(this.group.value.shipperId);

            }
            else {
                this.offset = 0;
                var result = this.contacts.slice(this.offset, this.offset + this.limit);
                this.shipperOptions.next(result);
                this.offset = this.offset + this.limit;

            }

        });
    }



    private subscribeQuoteChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(_ => {
            this.newQuote.setValue(true);
        });
    }

    private subscribeShippingTypeChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shippingTypeId => {
                if (shippingTypeId) {
                    const shippingType = this.shippingTypes.find(s => s.id === shippingTypeId);
                    quoteGroup.get('shippingType').setValue(shippingType.name);
                } else {
                    quoteGroup.get('shippingType').setValue('');
                }
            });
    }

    private prepareShippingTypes(
        direction: ServiceDirection = ServiceDirection.Domestic,
        serviceType: ServiceType = ServiceType.Parcels
    ): void {
        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        this.loadingShippingTypes = true;
        this._quoteService.getShippingTypes(direction, serviceType).subscribe(result => {


            this.shippingTypes = result;
            this.loadingShippingTypes = false;
            if (this.shippingTypes && (this.shippingTypes.length === 1 || this.shippingTypes.length === 2)) {

                const quoteGroup = this.group.get('quote') as FormGroup;
                quoteGroup.get('shippingTypeId').setValue(this.shippingTypes[0].id);
                quoteGroup.get('shippingType').setValue(this.shippingTypes[0].name);
                this.getSurchargesFromTarif(this.shippingTypes[0].id);
                if (this.shippingTypes[0].id == 9)
                    this.showShipmentValue = false;
                else
                    this.showShipmentValue = true;
            }
            else {
                const quoteGroup = this.group.get('quote') as FormGroup;
                quoteGroup.get('shippingTypeId').setValue('');
                quoteGroup.get('shippingType').setValue('');
                this.orderSurcharges = [];
            }
            console.log('this.shippingTypes');

            console.log(this.shippingTypes);
        });
    }

    private subscribeDirectionChanged(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        quoteGroup
            .get('type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((type: ServiceDirection) => {
                this.getCommodities();

                if (type === undefined) {
                    return;
                }
                // if (this.currentDirection == type)
                //     return;
                this.isExport = type === ServiceDirection.Export;

                // here
                quoteGroup.get('shippingType').setValue('');
                quoteGroup.get('shippingTypeId').setValue('');
                quoteGroup.get('shippingTypeId').markAsUntouched();

                //if export
                if (this.isExport) {
                    quoteGroup.addControl('serviceTypeId', new FormControl('', Validators.required));
                    this.shippingTypes = [];
                    quoteGroup.get('shippingType').setValue('');
                    const value = quoteGroup.get('serviceTypeId').value;

                    // value is = to 0 <---
                    if (value) {
                        this.prepareShippingTypes(type, value as ServiceType);
                    }
                    quoteGroup.get('serviceTypeId').valueChanges.subscribe(serviceTypeId => {
                        this.prepareShippingTypes(type, serviceTypeId as ServiceType);
                    });
                }
                //if domestic
                else {
                    quoteGroup.removeControl('serviceTypeId');
                    this.prepareShippingTypes();
                }
            });
    }

    private subscribeShipperAddressChanged(): void {
        this.group
            .get('shipperAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(addressId => {
                const quoteGroup = this.group.get('quote') as FormGroup;
                let problems = false;
                if (addressId) {
                    const selectedAddress = this.shipperAddresses.find(a => a.addressId === addressId) as Address;
                    if (selectedAddress) {
                        const address = this.toLongDisplayString(selectedAddress);
                        if (this.isNetPointShipper) {
                            quoteGroup.get('fromCity').setValue(selectedAddress.display + ', ' + address);
                        } else {
                            quoteGroup.get('fromCity').setValue(address);
                        }
                        quoteGroup.get('fromCityId').setValue(selectedAddress.cityId);
                        quoteGroup.get('fromCountryId').setValue(selectedAddress.countryId);
                    } else {
                        problems = true;
                    }
                } else {
                    problems = true;
                }
                if (problems) {
                    quoteGroup.get('fromCity').setValue('');
                    quoteGroup.get('fromCityId').setValue('');
                    quoteGroup.get('fromCountryId').setValue('');
                }

                this.updateDirection();
            });
    }

    private subscribeReceiverAddressChanged(): void {
        this.group
            .get('receiverAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(addressId => {
                const quoteGroup = this.group.get('quote') as FormGroup;
                let problems = false;
                if (addressId) {
                    const selectedAddress = this.receiverAddresses.find(a => a.addressId === addressId) as Address;
                    if (selectedAddress) {
                        const address = this.toLongDisplayString(selectedAddress);
                        if (this.isNetPointReceiver) {
                            quoteGroup.get('toCity').setValue(selectedAddress.display + ', ' + address);
                        } else {
                            quoteGroup.get('toCity').setValue(address);
                        }
                        quoteGroup.get('toCityId').setValue(selectedAddress.cityId);
                        quoteGroup.get('toCountryId').setValue(selectedAddress.countryId);
                    } else {
                        problems = true;
                    }
                } else {
                    problems = true;
                }
                if (problems) {
                    quoteGroup.get('toCity').setValue('');
                    quoteGroup.get('toCityId').setValue('');
                    quoteGroup.get('toCountryId').setValue('');
                }

                this.updateDirection();
            });
    }

    updateDirection(): void {
        const quoteGroup = this.group.get('quote') as FormGroup;
        const direction = quoteGroup.get('type').value;
        this.setDirection();
        if (!isNullOrUndefined(direction) && direction == this.currentDirection)
            return;
        quoteGroup.get('type').setValue(this.currentDirection);


    }

    private setDirection(): ServiceDirection {
        const quoteGroup = this.group.get('quote') as FormGroup;
        const fromCountryId = quoteGroup.get('fromCountryId').value;
        const toCountryId = quoteGroup.get('toCountryId').value;

        if (!fromCountryId || !toCountryId) {
            return undefined;
        }

        if (fromCountryId === this.domesticCountryId && toCountryId === this.domesticCountryId) {
            // domestic
            this.maxWeight = WeightValidator.MaxDomesticWeight;
            this.currentDirection = ServiceDirection.Domestic;
        } else if (fromCountryId === this.domesticCountryId) {
            // Export
            this.maxWeight = WeightValidator.MaxExportWeight;
            this.currentDirection = ServiceDirection.Export;
        } else if (toCountryId === this.domesticCountryId) {
            // Import
            this.maxWeight = undefined;
            this.currentDirection = ServiceDirection.Import;
        } else {
            // Not Supported
            this.maxWeight = undefined;
            this.currentDirection = undefined;
        }

        const weight = quoteGroup.get('weight');

        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    }

    toLongDisplayString(address: Address): string {
        let message = address.cityName + ', ' + address.countryName + ', ' + address.street;
        if (address.building) {
            message += ', ' + address.building;
        }

        if (address.floor) {
            message += ', ' + address.floor;
        }

        if (address.zipCode) {
            message += ', ' + address.zipCode;
        }

        if (address.landmark) {
            message += ', ' + address.landmark;
        }

        return message;
    }

    private subscribeShipperReferenceChanged(): void {

        this.group
            .get('quote')
            .get('shipperReference')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shipperId => {
                this.shipperReferenceChanged(shipperId);
            });
    }
    private shipperReferenceChanged(shipperReference: string): void {
        shipperReference = shipperReference.replace("#", "@");
        shipperReference = shipperReference.replace("/", "|");

        if (shipperReference != "")
            this._contactService.IsShipperReferenceExists(shipperReference).then(exists => {
                if (exists.status)
                    if (exists.status || exists.find(element => element == shipperReference)) {
                        this.group
                            .get('quote')
                            .get('shipperReference').setErrors(({ 'exists': true }));


                    }
            });
    }
    private subscribeShipperChanged(): void {
        this.group
            .get('shipperId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(shipperId => {
                this.shipperChanged(shipperId);
            });
    }

    private shipperChanged(shipperId: number): void {

        if (!this._onInitShipper) {
            this.group.get('shipperAddressId').reset();
            this.shippingTypes = [];
            this.resetShipperNetPoint();
        } else {
            this._onInitShipper = false;
        }

        this._contactService.getContact(shipperId).then((contact: Contact) => {
            if (contact.addresses) {
                this.shipperAddresses = contact.addresses.filter(item => item.countryId === this.domesticCountryId);
            }
            if (this.group.get('isNetPointShipper').value) {
                if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
                    this.selectShipperNetPoint(this._netpointService.netpoints.slice());
                } else {
                    this._netpointService.onNetPointsRetreived.subscribe(locations => {
                        this.selectShipperNetPoint(locations.slice());
                    });
                }
            }
            else if (this.shipperAddresses && this.shipperAddresses.length === 1) {
                this.group.get('shipperAddressId').setValue(this.shipperAddresses[0].addressId);
            }
            // else
            //     this.group.get('shipperAddressId').setValue(undefined);
        });
    }

    private selectShipperNetPoint(locations: Address[]): void {
        const location = locations.find(l => l.addressId === this.group.get('shipperAddressId').value);
        this.resetShipperNetPoint();
        this.netpointSelect(location, AddressType.Shipper);
    }

    private subscribeReceiverChanged(): void {
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(receiverId => {
                this.receiverChanged(receiverId);
            });
    }

    private receiverChanged(receiverId: number): void {
        if (!this._onInitReceiver) {
            this.group.get('receiverAddressId').reset();
            this.shippingTypes = [];
            this.resetReceiverNetPoint();
        } else {
            this._onInitReceiver = false;
        }
        this._contactService.getContact(receiverId).then((contact: Contact) => {
            this.receiverAddresses = contact.addresses;
            if (this.group.get('isNetPointReceiver').value) {
                if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
                    this.selectReceiverNetPoint(this._netpointService.netpoints.slice());
                } else {
                    this._netpointService.onNetPointsRetreived.subscribe(locations => {
                        this.selectReceiverNetPoint(locations.slice());
                    });
                }
            } else if (this.receiverAddresses && this.receiverAddresses.length === 1) {
                this.group.get('receiverAddressId').setValue(this.receiverAddresses[0].addressId);
            }
        });
    }

    private selectReceiverNetPoint(locations: Address[]): void {
        const location = locations.find(l => l.addressId === this.group.get('receiverAddressId').value);
        this.resetReceiverNetPoint();
        this.netpointSelect(location, AddressType.Receiver);
    }

    private subscribeContactAdded(): void {
        this._contactService.onContactAdded.subscribe(contactId => {
            if (this.addingShipper) {
                this.group.get('shipperId').setValue(contactId);
            } else if (this.addingReceiver) {
                this.group.get('receiverId').setValue(contactId);
            }

            this.addingShipper = false;
            this.addingReceiver = false;
        });
    }

    private subscribeContactsChanged(): void {
    }
    submitForm(): void {
        if (this.group.invalid) {
            return;
        }
        this.group.get('isNetPointShipper').setValue(this.isNetPointShipper);
        this.group.get('isNetPointReceiver').setValue(this.isNetPointReceiver);
        this.saveCodAmounts(this.group.get('quoteId').value);
        this.submited.emit(5);
    }
    confirmOrder(): void {
        if (this.group.invalid) {
            return;
        }
        this.orderDetail.orderId = this.group.get('orderId').value;

        this.group.get('isNetPointShipper').setValue(this.isNetPointShipper);
        this.group.get('isNetPointReceiver').setValue(this.isNetPointReceiver);


        this.ConfirmDialog();



    }
    ConfirmDialog(): void {

        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        const data = this.group.getRawValue() as OrderDetail;
        this.confirmOrderDialogRef = this._matDialog.open(ConfirmOrderComponent, {
            panelClass: 'confirm-order',
            data: {
                order: this.orderDetail,
                myInfo: this.myInfo
            }
        });
        this.confirmOrderDialogRef.afterClosed().subscribe(response => {
            const getData = async (data) => {
                return await data;
            }
            if (!response) {
                this.confirmingOrder = false;
                return;
            }
            const status = response[0] as string;
            if (response === 'cancel') {
                this.confirmingOrder = false;
                return;
            }
            this.confirmingOrder = true;
            const ConfirmedOrderDetail = response[1] as OrderDetail;
            if (ConfirmedOrderDetail.orderId === 0) {
                this._orderService.addOrderDetails(data).then((result: BillingDetails) => {
                    localStorage.setItem('orderId', result.orderId.toString());
                    this.confirmingOrder = true;

                    var pConfirm = this._orderService.confirmOrder(result.orderId);

                    getData(pConfirm).then((result: any) => {
                        if (result != null)
                            setTimeout(() => (this._router.navigateByUrl(Routes.paymentRedirect)), 10);
                    });
                    this.confirmingOrder = false;

                });
            } else if (ConfirmedOrderDetail.orderId > 0) {
                this._orderService.updateOrderDetails(data).then((result: BillingDetails) => {
                    localStorage.setItem('orderId', result.orderId.toString());
                    this.confirmingOrder = true;
                    var pConfirm = this._orderService.confirmOrder(result.orderId);
                    getData(pConfirm).then((result: any) => {
                        if (result != null)
                            setTimeout(() => (this._router.navigateByUrl(Routes.paymentRedirect)), 10);
                    });
                    this.confirmingOrder = false;


                });
            }
        });
    }
    afterSaveConfirmOrder(result: BillingDetails): void {
        const quoteForm = this.group.get('quote');

        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.group.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.saveCodAmounts(result.quoteId);
        this.saveOrderPieces(result.quoteId);
        this.saveOrderSurcharges(result.orderId);
    }
    saveOrderPieces(quoteId: number) {
        var pieces = this.group.get('quote').get('pieces') as FormArray;
        var piecesValue = pieces.value;
        if (piecesValue.length == 0)
            return;
        for (var c of pieces.value) {
            c.quoteId = quoteId;
        }
        this._quoteService.savePieces(pieces.value).subscribe(result => {
            this.group.get('quote').get('pieces').updateValueAndValidity();
            this.newQuote.setValue(false);
        });;
        this.newQuote.setValue(false);
    }

    saveOrderSurcharges(orderId: number) {
        if (this.orderSurcharges.length != 0) {
            this.orderSurcharges.forEach(element => {
                element.orderId = orderId;
            });

            if (this.orderSurcharges)
                this._quoteService.saveSurcharges(this.orderSurcharges).subscribe(result => {
                });
        }
    }

    saveCodAmounts(quoteId) {
        // if (this.orderCod.length > 0) {
        //     this.orderCod = this.orderCod.map(item => {
        //         item.quoteId = quoteId
        //         return item;
        //     });
        //     this._quoteService.saveCodAmount(this.orderCod).subscribe(result => {
        //     });
        // }
        // else if (this.group.get('quoteId').value != 0)
        //     this._quoteService.deleteCodAmount(this.group.get('quoteId').value).subscribe(result => {
        //     });
    }

    getCodAmounts() {
        // var orderId = this.group.get('orderId').value;
        // if (orderId > 0)
        //     this._quoteService.getCodAmounts(orderId).then(result => {
        //         this.orderCod = result;

        //         if (this.orderCod.length > 0) {
        //             var CODsurcharge = this.orderSurcharges.filter(x => x.description.toString().toLowerCase() == 'cash on delivery')
        //             if (CODsurcharge.length > 0) {
        //                 const index = this.orderSurcharges.findIndex(_ => _.surchargeId == CODsurcharge[0].surchargeId);
        //                 if (!(index > -1)) return;
        //                 this.orderSurcharges[index].disabled = true;
        //                 this.orderSurcharges[index].checked = true;
        //             }
        //         }
        //         else {
        //             if (this.disableEdit != null && this.disableEdit == false)
        //                 this.disableCOD = true;
        //         }
        //     });
    }
    cancel(): void {
        this.canceled.emit();
    }
    backToOrders(): void {
        this._router.navigateByUrl(Routes.orders);
    }
    generateNewQuote(showPrice: boolean = true): void {
        var codValid = true;
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        if (this.isExport && this.group.get('quote').get('serviceTypeId').value === 0) {
            this._snackBar.open('Select either Parcels or Docs', 'Dismiss', {duration: 10000});
            return;
        }
        // this.orderCod.forEach(element => {
        //     if (element.codCurrencyId == 0 || isNullOrUndefined(element.codCurrencyId) || element.codAmount == 0 || isNullOrUndefined(element.codAmount)) {
        //         codValid = false;
        //     }
        // });
        // if (!codValid)
        //     return;
        const timeRangeLookupId = this.group.get('timeRangeLookupId').value;
        var pickupDateTime: Date;
        if (!isNullOrUndefined(timeRangeLookupId)) {
            var tRange = this.timeRanges.find(x => x.id == timeRangeLookupId).name;
            var pickupDate: Date = this.group.get('pickupDate').value;
            var splitted = tRange.split(" - ");
            var hour = splitted[0].split(":");
            pickupDateTime = new Date(pickupDate);
            // if (Number(hour[0]) == 12)
            //     pickupDateTime.setHours(Number(hour[0]));
            // else
            //     pickupDateTime.setHours(12.01);
        }
        this.group.get('pickupDate').setValue(pickupDateTime);

        this.generatingQuote = true;
        let generateQuote;
        const quoteForm = this.group.get('quote');
        const quote = quoteForm.value as QuoteData;
        quote.direction = quote.type;
        var surchargeValues = '6,1 | '; //Default Values
        this.orderSurcharges.filter(x => x.checked == true).forEach(
            x => surchargeValues += x.surchargeId.toString() + ',1|'
        );

        quote.surchargesValue = surchargeValues;
        quote.chargeableWeight = this.group.get('quote').get('chargeableWeight').value;
        quote.cods = this.group.get('quote').get('cods').value;

        const skusSelected = this.group.get('quote').get('skus').value;
        quote.skus = skusSelected;



        if (quote.type === ServiceDirection.Export) {
            if (quote.shippingTypeId.toString().trim() == "")
                quote.shippingTypeId = 0;
            generateQuote = this._quoteService.generateInternationalQuote(quote as InternationalQuote);
        } else {
            generateQuote = this._quoteService.generateDomesticQuote(quote as DomesticQuote);
        }

        // const proformaDetails = this.group.get('proforma').value;
        // const orderId = this.group.get('orderId').value;

        // if (proformaDetails != null) {
        //     proformaDetails.forEach(x =>
        //         x.orderId = orderId)
        //     this._orderService.addProformaDetails(proformaDetails).then(result => {
        //     });
        // }



        generateQuote.subscribe(result => {

            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrency').setValue(result.currency.toString());
            var enumValue = CurrencyType[result.currency.toString()];
            quoteForm.get('quoteCurrencyId').setValue(enumValue);
            this.generatingQuote = false;

            if (showPrice) {
                this._snackBar.open('Total charges: ' + result.currency + ' ' + this._numberPipe.transform(result.price, '.2-2'), 'Dismiss', {
                    duration: 10000
                });
                if (this.hasSubsidiaryId)
                    this.confirmed.emit(result);
                else
                    this.updateQuotePrice(result.price, result.currency);

            }
            else
                if (this.hasSubsidiaryId)
                    this.confirmed.emit(result);
                else
                    this.updateQuotePrice(result.price, result.currency);
        });
    }

    updateQuote(showPrice: boolean = true): void {

        const pickupTime = this.group.get('pickupDate').value;

        var codValid = true;
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        // this.orderCod.forEach(element => {
        //     if (element.codCurrencyId == 0 || isNullOrUndefined(element.codCurrencyId) || element.codAmount == 0 || isNullOrUndefined(element.codAmount)) {
        //         codValid = false;
        //     }
        // });
        // if (!codValid)
        //     return;
        const timeRangeLookupId = this.group.get('timeRangeLookupId').value;
        var pickupDateTime: Date;
        if (!isNullOrUndefined(timeRangeLookupId)) {
            var tRange = this.timeRanges.find(x => x.id == timeRangeLookupId).name;
            var pickupDate: Date = this.group.get('pickupDate').value;
            var splitted = tRange.split(" - ");
            var hour = splitted[0].split(":");
            pickupDateTime = new Date(pickupDate);
            // if (Number(hour[0]) == 12)
            //     pickupDateTime.setHours(Number(hour[0]));
            // else
            //     pickupDateTime.setHours(12.01);
        }
        this.group.get('pickupDate').setValue(pickupDateTime);

        let generateQuote;
        const quoteForm = this.group.get('quote');
        const quote = quoteForm.value as QuoteData;
        quote.direction = quote.type;
        var surchargeValues = '6,1 |'; //Default Values
        this.orderSurcharges.filter(x => x.checked == true).forEach(
            x => surchargeValues += x.surchargeId.toString() + ',1|'
        );

        quote.surchargesValue = surchargeValues;
        quote.chargeableWeight = this.group.get('quote').get('chargeableWeight').value;
        quote.cods = this.group.get('quote').get('cods').value;
        if (quote.cods.find(cod => cod.codCurrencyId === 2) !== undefined)
        {
            quote.cods.find(cod => cod.codCurrencyId === 2).codAmount = quote.cods.find(cod => cod.codCurrencyId === 2).codAmount;
        }


        if (quote.type === ServiceDirection.Export) {
            if (quote.shippingTypeId.toString().trim() == "")
                quote.shippingTypeId = 0;
            generateQuote = this._quoteService.generateInternationalQuote(quote as InternationalQuote);
        } else {
            generateQuote = this._quoteService.generateDomesticQuote(quote as DomesticQuote);
        }




        generateQuote.subscribe(result => {
            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrency').setValue(result.currency.toString());
            var enumValue = CurrencyType[result.currency.toString()];
            quoteForm.get('quoteCurrencyId').setValue(enumValue);
            // this.generatingQuote = false;

            if (showPrice) {
                this._snackBar.open('Total charges: ' + result.currency + ' ' + this._numberPipe.transform(result.price, '.2-2'), 'Dismiss', {
                    duration: 10000
                });
                if (this.hasSubsidiaryId) {
                    this.updateQuotePrice(result.price, result.currency);
                    const data = this.group.getRawValue() as OrderDetail;
                    data.quote.cods = this.group.get('quote').get('cods').value;
                    this._orderService.updateConfirmedOrder(data).then((result) => {
                        this._orderService.updateOrderDetails(data).then((result: BillingDetails) => {
                            localStorage.setItem('orderId', result.orderId.toString());
                            const quoteForm = this.group.get('quote');
                            this.updateQuotePrice(quoteForm.get('quotePrice').value, quoteForm.get('quoteCurrency').value);
                            this.saveOrderSurcharges(result.orderId);
                            this.saveCodAmounts(result.quoteId);
                            this.saveOrderPieces(result.quoteId);
                            data.quote.cods = this.group.get('quote').get('cods').value;
                        });
                    });
                    this._router.navigateByUrl(Routes.orders);
                }
            }
        });
    }
    updateQuotePrice(price, currency) {
        const quoteForm = this.group.get('quote');
        quoteForm.get('quotePrice').setValue(price);
        quoteForm.get('quoteCurrency').setValue(currency.toString());
        var enumValue = CurrencyType[currency.toString()];
        quoteForm.get('quoteCurrencyId').setValue(enumValue);
        this.newQuote.setValue(false);
        this.generatingQuote = false;
    }

    updateQuoteAndOrderId(orderid, quoteid) {
        this.group.get('quoteId').setValue(quoteid);
        this.group.get('orderId').setValue(orderid);
        const quoteForm = this.group.get('quote');
        quoteForm.get('quoteId').setValue(quoteid);
        this.newQuote.setValue(false);
        this.generatingQuote = false;
    }

    addNewShipper(): void {
        this.addNewContact(_ => (this.addingShipper = true));
    }

    addNewReceiver(): void {
        this.addNewContact(_ => (this.addingReceiver = true));
    }

    addNewContact(beforeSave): void {
        this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                countries: this.countries
            }
        });

        this.contactDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }
            const contact = response[1] as Contact;

            const tags = response[2] as string[];

            contact.tags = tags;

            beforeSave();
            this._contactService.addNewContact(contact).then(_ => this.getAllContacts(true));
        });
    }
    private getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.countries = success;
            });
        });
    }

    private getSurchargesFromTarif(productId: number): Promise<any> {
        this.loadingSurcharges = true;
        return new Promise((resolve, reject) => {
            this._quoteService.getSurchargesFromTarif(productId).subscribe(success => {
                resolve(success);
                this.orderSurcharges = success;
                this.orderSurcharges = this.orderSurcharges.map(item => {
                    item.orderId = 0;
                    item.checked = item.selected;
                    return item;
                });
                this.loadingSurcharges = false;
            });
        });
    }
    dimensionsChanged(content) {
        const validHeight = this.group.get('quote').get('height').hasError('validDecimal');
        const validWidth = this.group.get('quote').get('width').hasError('validDecimal');
        const validLength = this.group.get('quote').get('length').hasError('validDecimal');
        if (validHeight || validWidth || validLength)
            this.confirmDimension = this._matDialog.open(ConfirmDimesionsComponent, {
                panelClass: 'confirm-Dimension'
            });
    }
    private getSurcharges(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._orderService.getSurchargesForOrder(orderId).subscribe(success => {
                resolve(success);
                this.orderSurcharges = success;
                this.orderSurcharges = this.orderSurcharges.map(item => {
                    item.disabled = true;
                    return item;
                });
            });
        });
    }
    loadSurcharges(): void {
        const data = this.group.getRawValue() as OrderDetail;
        const orderId = data.orderId;
        if (orderId && orderId > 0)
            this.getSurcharges(orderId);
        else {
            const quote = this.group.value.quote as QuoteData;
            if (quote.type === undefined || quote.type === null || quote.type === ServiceDirection.Domestic) {
                this.prepareShippingTypes();
            } else if (quote.type !== undefined && quote.type !== null && quote.serviceTypeId !== 0) {
                this.prepareShippingTypes(quote.type, quote.serviceTypeId as ServiceType);
            }
        }

    }
    editContact(contactId: number): void {
        this._contactService.getContact(contactId).then((c: Contact) => {
            this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contact: c,
                    countries: this.countries
                }
            });

            this.contactDialogRef.afterClosed().subscribe(response => {
                if (!response) {
                    return;
                }
                const status = response[0] as string;
                if (status === 'cancel') {
                    return;
                }

                const contact = response[1] as Contact;
                const tags = response[2] as string[];

                contact.tags = tags;

                this._contactService.editContact(contact).then(_ => this.getAllContacts(true));
            });
        });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }

    openNetPointDialog(type: AddressType, action: string = 'new'): void {
        if (action === 'new') {
            switch (type) {
                case AddressType.Receiver:
                    if (this.isNetPointReceiver) {
                        this.resetReceiverNetPoint();
                        return;
                    }
                    break;
                case AddressType.Shipper:
                    if (this.isNetPointShipper) {
                        this.resetShipperNetPoint();
                        return;
                    }
                    break;
                default:
                    break;
            }
        }

        this.netpointLocationsDialogRef = this._matDialog.open(NetPointLocationsComponent, {
            panelClass: 'netpoint-locations-dialog'
        });

        this.netpointLocationsDialogRef.afterClosed().subscribe((response: Address) => {
            this.netpointSelect(response, type);
        });
    }

    private resetReceiverNetPoint(): void {
        this.isNetPointReceiver = false;
        this.toNetPointButtonText = 'To NetPoint?';
        this.receiverAddressLabel = 'Receiver Address';
        this.receiverAddresses = this.receiverAddresses.filter(
            a => a.addressId !== this.group.get('receiverAddressId').value
        );
        this.group.get('receiverAddressId').enable();
        if (this.receiverAddresses && this.receiverAddresses.length === 1) {
            this.group.get('receiverAddressId').setValue(this.receiverAddresses[0].addressId);
        } else {
            this.group.get('receiverAddressId').setValue(undefined);
        }
    }

    private resetShipperNetPoint(): void {
        this.isNetPointShipper = false;
        this.fromNetPointButtonText = 'From NetPoint?';
        this.shipperAddressLabel = 'Shipper Address';
        this.shipperAddresses = this.shipperAddresses.filter(
            a => a.addressId !== this.group.get('shipperAddressId').value
        );
        this.group.get('shipperAddressId').enable();
        if (this.shipperAddresses && this.shipperAddresses.length === 1) {
            this.group.get('shipperAddressId').setValue(this.shipperAddresses[0].addressId);
        }
        // else {
        //     this.group.get('shipperAddressId').setValue(undefined);
        // }
    }

    private netpointSelect(netpointAddress: Address, type: AddressType): void {
        if (!netpointAddress) {
            return;
        }

        const netpointInstance = new Address(netpointAddress);
        netpointInstance.isNetPoint = true;

        switch (type) {
            case AddressType.Receiver:
                this.receiverAddresses = this.receiverAddresses.filter(a => !a.isNetPoint);
                this.isNetPointReceiver = true;
                this.toNetPointButtonText = 'To Receiver?';
                this.receiverAddressLabel = 'To NetPoint Location';

                this.receiverAddresses.push(netpointInstance);
                this.group.get('receiverAddressId').setValue(netpointInstance.addressId);
                this.group.get('receiverAddressId').disable({ onlySelf: true });
                break;
            case AddressType.Shipper:
                this.shipperAddresses = this.shipperAddresses.filter(a => !a.isNetPoint);
                this.isNetPointShipper = true;
                this.fromNetPointButtonText = 'From Shipper?';
                this.shipperAddressLabel = 'From NetPoint Location';
                this.shipperAddresses.push(netpointInstance);
                this.group.get('shipperAddressId').setValue(netpointInstance.addressId);
                this.group.get('shipperAddressId').disable({ onlySelf: true });
                break;
            default:
                break;
        }
    }
    updateProformaPanelState() {
        this.proformaPanelState = true;
    }
    saveProforma(input): void {
        this.group.get('proforma').setValue(input)
        this.proformaPanelState = false;
        // var orderId = this.group.get('orderId').value;
        // input.forEach(x =>
        //     x.orderId = orderId)
        // this._orderService.addProformaDetails(input).then(result => {
        // });
    }
    addNewPiece(): void {
        const quoteGroup = this.group.get('quote');
        const pieces = quoteGroup.get('pieces') as FormArray;
        pieces.push(this._formBuilder.group(new Piece()));
    }

    pieceAdded(data: any): void {
        const pieceForm = data.value;
        const mode: string = data.mode;
        const index: number = data.index;

        if (mode === 'cancel') {
            if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;

        }
        else if (mode === 'new') {
            const pieces = this.group.get('quote').get('pieces') as FormArray;
            pieces.at(index - 1).setValue(pieceForm);
            var TotalWeight = 0, TotalNOP = 0;
            for (let c of pieces.controls) {
                const pieceValue = c.value as Piece;
                TotalWeight += pieceValue.weight;
                TotalNOP += pieceValue.numberOfPieces;
            }
            if (TotalWeight == 0)
                TotalWeight = 0.5
            if (TotalNOP == 0)
                TotalNOP = 1
            this.group.get('quote').get('weight').setValue(TotalWeight);
            this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            const pieces = this.group.get('quote').get('pieces') as FormArray;
            pieces.removeAt(index - 1);
            var TotalWeight = 0, TotalNOP = 0;

            for (let c of pieces.controls) {
                const pieceValue = c.value as Piece;
                TotalWeight += pieceValue.weight;
                TotalNOP += pieceValue.numberOfPieces;
            }
            if (TotalWeight == 0)
                TotalWeight = 0.5
            this.group.get('quote').get('weight').setValue(TotalWeight);
            if (TotalNOP == 0)
                TotalNOP = 1;
            this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            if (pieceForm != undefined && pieceForm.pieceId != 0) {
                this._quoteService.deletePieces(pieceForm.pieceId)
            }
            if (pieces.controls.length == 0)
                pieces.clearValidators()
        }
        this.calculateChargeableWeight();
    }

    protected filterContacts() {
        if (!this.contacts) {
            return;
        }
        // this.getNextShipperBatch();
        // get the search keyword
        // let search = this.contactFilterCtrl.value;

        // if (!search) {
        //     this.filteredContacts.next(this.contacts.slice(0, 10));
        //     return;
        // } else {
        //     search = search.toLowerCase();
        // }
        // // filter the banks
        // this.filteredContacts.next(
        //     this.contacts.filter(contact => contact.fullName.toLowerCase().indexOf(search) > -1)
        // );
    }

    canUpdateOrder(orderId) {
        var canUpdate = this._orderService.canUpdateOrder(orderId);
        return canUpdate;
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // search(query: string) {
    //     this.getNextShipperBatch(query);
    //     // let result = this.select(query)
    //     // this.selectedAreas = result;
    // }
    handleInput(event: KeyboardEvent): void {
        event.stopPropagation();
    }
    getNextShipperBatch(searchShipper?) {

        if (isNullOrUndefined(searchShipper) || searchShipper == "") {
            if (searchShipper == "")
                this.offset = 0;
            var result = this.contacts.slice(this.offset, this.offset + this.limit);
            this.shipperOptions.next(result);
        }
        else {
            this.offset = 0;
            result = this.contacts.filter(x => x.fullName.toLowerCase().includes(searchShipper.toString().toLowerCase())).slice(this.offset, this.offset + this.limit);
            this.shipperOptions$ = this.shipperOptions.asObservable().pipe(
                scan((acc, curr) => {
                    return [...acc, ...curr];
                }, [])
            );
            this.shipperOptions.next(result);
        }
        this.offset += this.limit;
    }
    getNextReceiverBatch(searchReceiver?) {
        if (isNullOrUndefined(searchReceiver) || searchReceiver == "") {
            if (searchReceiver == "")
                this.offset = 0;
            var result = this.contacts.slice(this.offset, this.offset + this.limit);
            this.receiverOptions.next(result);
        }
        else {
            this.offset = 0;
            result = this.contacts.filter(x => x.fullName.toLowerCase().includes(searchReceiver.toString().toLowerCase())).slice(this.offset, this.offset + this.limit);
            this.receiverOptions$ = this.receiverOptions.asObservable().pipe(
                scan((acc, curr) => {
                    return [...acc, ...curr];
                }, [])
            );
            this.receiverOptions.next(result);
        }
        this.offset += this.limit;
    }

    addNewSku(): void {
        const skus = this.group.get('quote').get('skus') as FormArray;
        console.log(skus);
        this.group.get('quote').get('skus').setValidators(SkuValidator.inValidSku("disabled"));


        var sku = this._formBuilder.group(new OrderSku());
        sku.get('sku').setValidators(Validators.required);
        sku.get('quantity').setValidators(Validators.min(1));
        skus.push(sku);

    }
    skuAdded(data: any): void {

    }

    private fetchStock(storerKey, sku): Promise<any> {
        return new Promise((resolve, reject) => {
            this._quoteService.FetchStockAsync(storerKey, sku).subscribe(result => {
                resolve(result);
                this.wmsSKUs = result;
            });
        });
    }
}

export enum AddressType {
    Shipper,
    Receiver
}
