import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { PaymentService } from '../../new-order/payment/payment.services';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';
import { FormControl } from '@angular/forms';
declare var Checkout: any;

@Component({
    selector: 'orders-anonymous-shipment-payment',
    templateUrl: './anonymous-shipment-payment.component.html',
    styleUrls: ['./anonymous-shipment-payment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousShipmentPaymentComponent extends OrderAnonymousBaseComponent implements OnInit {
    loadCheckout: Promise<any>;
    retryAllowed = false;
    checkoutLoaded = false;
    payClicked = false;
    paymentSessionId: string;
    uniqueId: string;

    constructor(private _paymentService: PaymentService, private _snackBar: MatSnackBar) {
        super();
        this.loadCheckout = new Promise(resolve => {
            this.loadScript();
            resolve(true);
        });
    }

    ngOnInit(): void {
        this.loadCheckout.then(_ => {
            this.getCheckoutSession();
        });
    }

    getCheckoutSession(): void {
        this.retryAllowed = false;
        this._paymentService.createCheckoutSession(this.form.value.orderId).subscribe(
            result => {
                this.paymentSessionId = result.sessionId;
                this.uniqueId = result.uniqueId;

                if (this.paymentSessionId == null || this.uniqueId === '00000000-0000-0000-0000-000000000000') {
                    this._snackBar.open('Error occured while generating payment session!');
                    this.retryAllowed = true;
                }

                const mainLoopId = setInterval(() => {
                    if (!this.checkoutLoaded) {
                        this.configureCheckoutJs();
                        this.checkoutLoaded = true;
                        clearInterval(mainLoopId);
                    }
                }, 1000);
            },
            error => {
                this._snackBar.open('Error occured while generating payment session!');
                this.retryAllowed = true;
            }
        );
    }

    configureCheckoutJs(): void {
        Checkout.configure({
            merchant: environment.merchantId,
            order: {
                amount: this.form.get('totalPrice').value,
                currency: 'USD',
                description: 'New Shipment Order',
                id: this.uniqueId
            },
            session: {
                id: this.paymentSessionId
            },
            interaction: {
                merchant: {
                    name: 'The Net Holding',
                    address: {
                        line1: 'Sin El Fil - Horsh Tabet Center 288 - Ground Floor',
                        line2: 'Beirut, Lebanon'
                    },
                    email: 'info@thenet.group',
                    phone: '+961 (1) 500-047',
                    logo: 'https://www.thenet.group/images/logo.svg'
                }
            }
        });
    }

    loadScript(): void {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');

            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    confirm(): void {
        this.payClicked = true;
        localStorage.setItem('orderId', this.form.value.orderId.toString());
        Checkout.showPaymentPage();
    }

    @HostListener('document:payment-error', ['$event'])
    paymentError(event): void {
    }

    @HostListener('document:payment-canceled', ['$event'])
    paymentCanceled(event): void {
    }
}
