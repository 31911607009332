import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class QuoteService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.citiesCache = [];
        this.countriesCache = [];
        this.shippingTypesChanged = new BehaviorSubject(null);
        this.onCodChanged = new BehaviorSubject(null);
    }
    getDomesticCities() {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Lebanon/Cities');
    }
    getCountries() {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Countries');
    }
    getCitiesByCountryId(countryId) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Countries/' + countryId + '/Cities');
    }
    getShippingTypes(direction, type) {
        const params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params });
    }
    getShippingTypesPromise(direction, type) {
        return new Promise((resolve, reject) => {
            if (direction === undefined || type === undefined) {
                resolve(null);
            }
            const params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
            this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params })
                .subscribe(result => {
                this.shippingTypes = result;
                this.shippingTypesChanged.next(this.shippingTypes);
                resolve(result);
            }, reject);
        });
    }
    saveCodAmount(cod) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Cod', cod);
    }
    saveBulkCodAmount(cod) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/BulkCod', cod);
    }
    deleteCodAmount(quoteId) {
        return this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Cod/' + quoteId);
    }
    getCodAmounts(orderId) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Cod/' + orderId)
                .subscribe((cods) => {
                this.cods = cods;
                this.onCodChanged.next(this.cods);
                resolve(cods);
            }, reject);
        });
    }
    getPieces(quoteId) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Dimensions/' + quoteId)
                .subscribe((pieces) => {
                this.pieces = pieces;
                this.onPieceChanged.next(this.pieces);
                resolve(pieces);
            }, reject);
        });
    }
    getSurchargesFromTarif(productId) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Surcharges/' + productId);
    }
    saveSurcharges(surcharges) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Surcharges', surcharges);
    }
    savePieces(pieces) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Dimensions', pieces);
    }
    deletePieces(pieceId) {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Dimensions/' + pieceId).subscribe(_ => {
                resolve(this);
            }, reject);
        });
    }
    generateDomesticQuote(quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote', quote);
    }
    generateDomesticBulkQuote(quotes) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote/Bulk', quotes);
    }
    generateInternationalQuote(quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/InternationalQuote', quote);
    }
    addQuote(quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes', quote);
    }
    generateAnonymousQuote(order) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/AnonymousQuote', order);
    }
    FetchStockAsync(storerKey, sku) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/FetchStock/' + storerKey + "/" + sku);
    }
}
QuoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuoteService_Factory() { return new QuoteService(i0.ɵɵinject(i1.HttpClient)); }, token: QuoteService, providedIn: "root" });
