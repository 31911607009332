import { Contact } from 'app/main/contacts/contact-form/contact.model';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { City } from 'app/_models/city.model';
import { OrderSku, SkuStock } from '../../new-order/order.model';

export class OrderExcelListItem {
    shipper: string;
    shipperId: number;
    shipperCityId : number;
    shipperAddressId : number;
    shipperReference: string;
    weight: number;
    nop: number;
    shipmentValue: number;
    shipmentCurrency: string;
    shipmentCurrencyId: number;
    codAmount: number;
    codCurrency: string;
    codCurrencyId: number;
    codAmount2: number;
    codCurrency2: string;
    codCurrencyId2: number;
    contactId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    phoneCountryCode: string;
    validPhoneNumber: boolean;
    specialInstructions:string;
    tags: string[];
    addresses: Address[];
    importId: number;
    direction : ServiceDirection;
    isValid: boolean;
    SKUs: string[];
    SKUQty: number;
    skus: OrderSku[];
    invalidSku: SkuStock[];
    multiframesLabel: string;

    constructor(order?) {
        order = order || {};
        this.shipperId = order.shipperId || 0;
        this.shipperReference = order.shipperReference ||'';
        this.shipperCityId = order.shipperCityId || 0;
        this.shipperAddressId = order.shipperAddressId || 0;

        this.weight = order.weight || 0;
        this.shipmentValue = order.shipmentValue || 0;
        this.shipmentCurrency = order.shipmentValue || '';
        this.codAmount = order.codAmount || 0;
        this.codCurrency = order.codCurrency || '';

        this.codAmount2 = order.codAmount2 || 0;
        this.codCurrency2 = order.codCurrency2 || '';




        this.contactId = order.contactId || 0;
        this.firstName = order.firstName || '';
        this.lastName = order.lastName || '';
        this.companyName = order.companyName || '';
        this.email = order.email || '';
        this.phoneNumber = order.phoneNumber || '';
        this.phoneCountryCode = order.phoneCountryCode || '';
        this.specialInstructions = order.specialInstructions || '';
        this.tags = order.tags || [];
        this.addresses = order.addresses || [new Address()];
        this.validPhoneNumber = order.validPhoneNumber || false;
        this.importId = order.importId || 0;
        this.isValid = order.isValid || false;
        this.SKUs = order.SKUs || [];
        // this.skuqty = order.skuqty || {};
        this.skus = order.skus || [new OrderSku()];
    }
}

export class Address {
    addressId: number;
    countryId: number;
    countryName: string;
    cityId: number;
    cityName: string;
    streetAddress1: string;
    streetAddress2: string;
    region: string;
    zipCode: string;
    display: string;
    street: string;
    building: string;
    floor: string;
    landmark: string;
    //cities: City[]

    constructor(address?) {
        address = address || {};
        this.addressId = address.addressId || 0;
        this.countryId = address.countryId;
        this.cityId = address.cityId;

        this.countryName = address.countryName || '';
        this.cityName = address.cityName || '';
        this.streetAddress1 = address.streetAddress1 || '';
        this.streetAddress2 = address.streetAddress2 || '';
        this.region = address.region || '';
        this.zipCode = address.zipCode || '';
        this.display = address.display || '';

        this.street = address.street || '';
        this.building = address.building || '';
        this.floor = address.floor || '';
        this.landmark = address.landmark || '';
        //this.cities = address.cities || [];
    }
}

