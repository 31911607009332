import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderPayment } from '../order-view.model';

@Component({
    selector: 'order-payment-view',
    templateUrl: './payment-view.component.html',
    styleUrls: ['./payment-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderPaymentViewComponent implements OnInit {
    @Input() payments: OrderPayment[];

    constructor() {}

    ngOnInit(): void {}
}
