import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { OrderDetail } from '../../order.model';
import { environment } from 'environments/environment';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { FormGroup } from '@angular/forms';
import { Countries } from 'app/main/quotes/international/international.model';
import { PaymentService } from '../../payment/payment.services';
import { CurrencyType } from 'app/_enums/ServiceType.enum';

declare var Checkout: any;

@Component({
    selector: 'confirm-order',
    templateUrl: './confirm-order.component.html',
    styleUrls: ['./confirm-order.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmOrderComponent implements OnInit, OnDestroy {
    order: OrderDetail;
    myInfo: any;
    paymentSessionId: string;
    uniqueId: string;
    loadCheckout: Promise<any>;
    checkoutLoaded = false;
    serviceTypeId: MyNetServiceType;
    payClicked = false;
    domesticCountryId = 158; //Lebanon Default

    currencyRef: string;

    protected _unsubscribeAll = new Subject<void>();

    constructor(
        public matDialogRef: MatDialogRef<ConfirmOrderComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.order = _data.order;
        this.myInfo = _data.myInfo;
        console.log(this.myInfo);
        this.domesticCountryId = this.myInfo.countryId;
    }


    ngOnInit(): void {
        this.setServiceTypeId();
        var a =  this.enumSelector(CurrencyType).slice(7, 13);
        this.currencyRef = a.find(x => x.value == this.order.quote.shipmentValueCurrencyId).title;
 
    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key }));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    confirm(): void {
        this.matDialogRef.close(['new', this.order]);
     }

    setServiceTypeId(): void {
        switch (this.getDirection()) {
            case ServiceDirection.Domestic:
                this.serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                this.serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                this.serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                break;
        }
    }

    private getDirection(): ServiceDirection {
        const fromCountryId = this.order.quote.fromCountryId;
        const toCountryId = this.order.quote.toCountryId;

        if (!fromCountryId || !toCountryId) {
            return undefined;
        }

        if (fromCountryId === this.domesticCountryId && toCountryId === this.domesticCountryId) {
            // domestic
            return ServiceDirection.Domestic;
        } else if (fromCountryId === this.domesticCountryId) {
            // Export
            return ServiceDirection.Export;
        } else if (toCountryId === this.domesticCountryId) {
            // Import
            return ServiceDirection.Import;
        } else {
            // Not Supported
            return undefined;
        }
    }
}
