<form [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxFlex>

    <div class="w-100-p" fxFlex>
        <div fxFlex class="h2 mb-24">How did you hear about us?</div>
        <mat-icon (click)="matDialogRef.close()" style="cursor: pointer;" class="text-right">close</mat-icon>
    </div>

    <mat-form-field fxFlex="100" class="w-100-p">
        <mat-select placeholder="Select how did you hear about us" name="mynet-hear-about-us-lookup"
            formControlName="lookupId" required>
            <mat-option *ngFor="let lookup of lookups" [value]="lookup.id">{{ lookup.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="100" class="w-100-p" *ngIf="form.get('lookupId').value === otherLookupId">
        <mat-label>Please Specify</mat-label>
        <textarea matInput formControlName="details" name="mynet-hear-about-us-details"
            [required]="form.get('lookupId').value === otherLookupId"></textarea>
    </mat-form-field>

    <div class="w-100-p text-right">
        <button (click)="saveHeardAboutUs()" color="primary" mat-raised-button>Submit</button>
        <button (click)="matDialogRef.close()" class="ml-16" mat-raised-button>Skip</button>
    </div>

</form>