import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { UserService, ChangePhoneNumberStatus } from 'app/_shared/user.services';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DistributionEvent } from './distribution-event.model';
import { DistributionListService } from '../distribution-list.service';

@Component({
    selector: 'distribution-events-dialog',
    templateUrl: './distribution-events-dialog.component.html',
    styleUrls: ['./distribution-events-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DistributionEventsDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    distributionEvents: DistributionEvent[];
    // label: string;

    protected _unsubscribeAll = new Subject<void>();

    // Workaround for angular component issue #13870
    disableAnimation = true;

    form: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<DistributionEventsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _distributionListService: DistributionListService
    ) {
        // this.label = _data.label;
    }
    ngOnInit(): void {
        this._distributionListService.onDistributionEventsChanged.subscribe(events => {
            this.distributionEvents = events;
        });
    }

    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
