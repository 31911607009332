<form [formGroup]="group">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="mb-16 w-100-p" fxLayout="column" fxLayout.gt-xs="row">
            <strong class="font-size-18">
                It looks like this is the first time you place an order with us, please fill out this contact form
            </strong>
        </div>

        <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-xs="row">
            <mat-form-field class="pr-4 no-padding-xs" fxFlex>
                <mat-label>First Name</mat-label>
                <input matInput required name="mynet-myinfo-firstName" formControlName="firstName">
            </mat-form-field>

            <mat-form-field class="pl-4 no-padding-xs" fxFlex>
                <mat-label>Last Name</mat-label>
                <input matInput required name="mynet-myinfo-lastName" formControlName="lastName">
            </mat-form-field>
        </div>

        <mat-form-field class="w-100-p mb-6">
            <mat-label>Company Name</mat-label>
            <input matInput name="mynet-myinfo-companyName" formControlName="companyName">
        </mat-form-field>
        <div fxLayout="row" class="w-100-p mb-6" fxLayoutAlign="start start">
            <mat-form-field fxFlex style="margin-top:-1px;">
                <mat-select name="contact-PhoneCountryCode" formControlName="phoneCountryCode" placeholder="Code"
                    (selectionChange)="countryChange($event)" required>
                    <mat-option *ngFor="let country of phoneCountries" [value]="country.countryPhoneCode">
                        {{ country.DisplayNameWithCode }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100-p mb-6">
                <mat-label>Phone Number</mat-label>
                <input matInput name="mynet-myinfo-phoneNumber" formControlName="phoneNumber" required type="tel">
                <mat-error *ngIf="group.get('phoneNumber').hasError('required')">
                    Phone Number is required
                </mat-error>

                <mat-error *ngIf="group.get('phoneNumber').hasError('validCountryPhone')">
                    Phone Number is not valid
                </mat-error>

                <mat-error *ngIf="group.get('phoneNumber').hasError('phoneNumberExists')">
                    Phone Number already register for another user
                </mat-error>
            </mat-form-field>
        </div>
        <mat-progress-bar *ngIf="checkingPhoneNumber" mode="indeterminate"></mat-progress-bar>
        <!-- <ng-container
            *ngIf="!phoneNumberExists && !checkingPhoneNumber && group.get('phoneNumber').value && this.group.get('validPhoneNumber').value">
            <div class="mb-12 w-100-p text-left"
                *ngIf="!group.get('confirmedPhoneNumber').value || needPhoneVerification">
                <button mat-raised-button color="primary" aria-label="validate" type="button"
                    (click)="openPhoneNumberDialog()">
                    Validate
                </button>
            </div>
        </ng-container> -->
        <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-xs="row">

            <auto-complete-location fxFlex class="pr-4 no-padding-sm" [label]="'Location'" [required]="true"
                [control]="cityControl">
            </auto-complete-location>

            <mat-form-field class="pl-4 no-padding-sm" fxFlex>
                <mat-label>Street Address</mat-label>
                <input matInput name="mynet-myinfo-street" formControlName="street" required>
            </mat-form-field>

        </div>

        <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field class="pr-4no-padding-sm" fxFlex>
                <mat-label>Building</mat-label>
                <input matInput name="mynet-myinfo-building" formControlName="building">
            </mat-form-field>

            <mat-form-field class="pr-4 pl-4 no-padding-sm" fxFlex>
                <mat-label>Floor</mat-label>
                <input matInput name="mynet-myinfo-floor" formControlName="floor">
            </mat-form-field>

            <mat-form-field class="pl-4 no-padding-sm" fxFlex>
                <mat-label>Zip Code</mat-label>
                <input matInput name="mynet-myinfo-zipCode" formControlName="zipCode">
            </mat-form-field>
        </div>

        <mat-form-field class="w-100-p mb-6">
            <mat-label>Landmark</mat-label>
            <textarea matInput name="mynet-myinfo-landmark" formControlName="landmark" matInput rows="4"
                matTextareaAutosize matAutosizeMinRows=4 matAutosizeMaxRows=6></textarea>
        </mat-form-field>

        <div class="mb-6 w-100-p" fxLayout="column" fxLayout.gt-sm="row">
            <mat-checkbox class="mt-24" formControlName="clientAgreement" flex required >
                I hereby agree to share my personal details to be used by My Net for the purpose of my Shipments Pickup
                or Delivery
            </mat-checkbox>
        </div>

    </div>
</form>

<mat-action-row>
    <div
        *ngIf="!phoneNumberExists && !checkingPhoneNumber && group.get('phoneNumber').value && this.group.get('validPhoneNumber').value">
        <div *ngIf="!group.get('confirmedPhoneNumber').value || needPhoneVerification">
            <button mat-raised-button color="primary" aria-label="validate" type="button"
                (click)="openPhoneNumberDialog()">
                Validate
            </button>
        </div>
    </div>
    <button type="button"
        [disabled]="!group.get('confirmedPhoneNumber').value || needPhoneVerification || group.invalid"
        mat-raised-button color="primary" (click)="submitForm()">
        <strong>Proceed to Order</strong> </button>

</mat-action-row>