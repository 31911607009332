/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./current-conversion-rate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./current-conversion-rate.component";
import * as i4 from "../../settings/settings.service";
var styles_CurrentConversionRateComponent = [i0.styles];
var RenderType_CurrentConversionRateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CurrentConversionRateComponent, data: {} });
export { RenderType_CurrentConversionRateComponent as RenderType_CurrentConversionRateComponent };
function View_CurrentConversionRateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" 1 ", " = ", " ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.sourceCurrency; var currVal_1 = _v.context.$implicit.targetValue; var currVal_2 = _v.context.$implicit.targetCurrency; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CurrentConversionRateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Conversion rates:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentConversionRateComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.latestRates; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CurrentConversionRateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentConversionRateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.noRates && _co.marketRateStatus); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CurrentConversionRateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-current-conversion-rate", [], null, null, null, View_CurrentConversionRateComponent_0, RenderType_CurrentConversionRateComponent)), i1.ɵdid(1, 114688, null, 0, i3.CurrentConversionRateComponent, [i4.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CurrentConversionRateComponentNgFactory = i1.ɵccf("app-current-conversion-rate", i3.CurrentConversionRateComponent, View_CurrentConversionRateComponent_Host_0, {}, {}, []);
export { CurrentConversionRateComponentNgFactory as CurrentConversionRateComponentNgFactory };
