export class ContactList {
    contactListId: number;
    description: string;
    tags: string[];
    contactListDetail: ContactListDetail[];
    canDelete: boolean;
    constructor(contactList?) {
        contactList = contactList || {};
        this.contactListId = contactList.contactListId || 0;
        this.description = contactList.description || '';
        this.tags = contactList.tags || [];
        this.contactListDetail = contactList.contactListDetail || [];
        this.canDelete = contactList.canDelete;
    }
}
export class ContactListDetail {
    Id: number;
    ContactListId: number;
    ContactId: number;

    constructor(contactListDetail?) {
        contactListDetail = contactListDetail || {};
        this.Id = contactListDetail.Id || 0;
        this.ContactListId = contactListDetail.ContactListId || 0;
        this.ContactId = contactListDetail.ContactId || 0;
    }
}