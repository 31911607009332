import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { Entity } from 'app/_models/Entity.model';
import { City } from 'app/_models/city.model';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';
import { GoogleLocationComponent } from 'app/_shared/google-location/google-location.component';
import { stringify } from '@angular/compiler/src/util';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-contact-form-address',
    templateUrl: './contact-form-address.component.html',
    styleUrls: ['./contact-form-address.component.scss']
})
export class ContactFormAddressComponent implements OnInit, OnDestroy {
    @Input() contactForm: FormGroup;
    @Input() addressForm: FormGroup;
    @Input() index: number;
    countries: Entity<number>[] = [];
    cities: Entity<number>[] = [];

    // countryControl: FormControl;
    cityControl: FormControl;
    countryId: number;
    

    selectedCountryName = '';
    selectedCityName = '';
    actionButton = '';
    firstTime = false;
    location: any;
    latitude: number
    longitude: number
    locationEnabled: boolean = false;
    addressCount: number;
    @ViewChild('iLongitude', { static: false }) iLongitude: ElementRef;
    private _unsubscribeAll = new Subject<any>();
    constructor() { }

    ngOnInit(): void {
        this.cityControl = new FormControl('', Validators.required);
        this.addressForm.addControl('cityControl', this.cityControl);
        if (
            this.addressForm.value &&
            this.addressForm.value.countryId &&
            this.addressForm.value.countryName &&
            this.addressForm.value.cityId &&
            this.addressForm.value.cityName
        ) {
            const selectedLocation = new City();
            selectedLocation.cityId = this.addressForm.value.cityId;
            selectedLocation.countryId = this.addressForm.value.countryId;
            this.countryId = this.addressForm.value.countryId;
            this.selectedCountryName = this.addressForm.value.countryName;
            selectedLocation.name = this.addressForm.value.cityName + ', ' + this.addressForm.value.countryName;
            selectedLocation.cityName = this.addressForm.value.cityName;
            selectedLocation.countryName = this.addressForm.value.countryName;
            this.cityControl.setValue(selectedLocation);
            this.selectedCountryName = this.addressForm.value.countryName;
            this.selectedCityName = this.addressForm.value.cityName;
        }
        this.cityControl.valueChanges.subscribe(value => {
            this.citySelected(value);
        });

        this.longitude = this.addressForm.value.longitude as number;
        this.latitude = this.addressForm.value.latitude as number;

        const addresses = this.contactForm.get('addresses') as FormArray;
        this.addressCount = addresses.length;
        if (this.longitude != 0 || this.latitude != 0) {
            this.locationEnabled = true;
        }

    }
    clearPin() {
        this.location['lat'] = 0;
        this.location['lng'] = 0;
        this.location = null;
        this.updateFormValues();
    }
    radioChange(event: any) {
        const addresses = this.contactForm.get('addresses') as FormArray;
        this.addressCount = addresses.length;;
        if (event.value == AddressType.Location) {
            this.locationEnabled = true;
            // this.addressForm.disable();
        }
        else {
            this.locationEnabled = false;
            // this.addressForm.enable();
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    locationAdded(data: any): void {
        if (data === undefined)
            return;
        if (data.cityId == 0) {
            this.cityControl.setValue('');
            this.addressForm.get('street').setValue('');
            this.cityControl.setErrors({ required: true });
            alert("Could not retrieve address , kindly fill below!");
            return;
        }
        this.location = data;
        this.updateFormValuesFromLocation(data);
    }
    citySelected(option: City): void {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.addressForm.get('cityId').setValue(option.cityId);
            this.selectedCityName = option.name;
            this.countryId = option.countryId;
            this.updateFormValues();
        } else {
            this.cityControl.setErrors({ required: true });
        }
    }
    cityCleared(): void {

        this.addressForm.get('cityId').setValue('');
        this.selectedCityName = '';
        this.cityControl.clearValidators();
        this.countryId = undefined;
    }
    updateFormValues(): void {
        const displayMessage = this.cityControl.value.name + ', ' + this.addressForm.get('street').value;
        this.addressForm.setControl('countryId', new FormControl(this.countryId));
        this.addressForm.setControl('display', new FormControl(displayMessage));
        // if (!isNullOrUndefined(cityname) || !isNullOrUndefined(countryName)) {
        //     this.addressForm.setControl('countryName', new FormControl(countryName));
        //     this.addressForm.setControl('cityName', new FormControl(cityname));
        // }
        this.addressForm.setControl('countryName', new FormControl(this.cityControl.value.countryName));
        this.addressForm.setControl('cityName', new FormControl(this.cityControl.value.cityName));
        if (this.location != undefined) {
            this.addressForm.setControl('latitude', new FormControl(this.location['lat']));
            this.addressForm.setControl('longitude', new FormControl(this.location['lng']));
        }
        // this.addressForm.updateValueAndValidity();
    }
    updateFormValuesFromLocation(data: any): void {
        // this.cityControl.setValue(data.cityId);

        if (!data)
            return;
        if (data.cityId == 0)
            return;
        const selectedLocation = new City();
        selectedLocation.cityId = data.cityId;
        selectedLocation.countryId = data.countryId;
        this.countryId = data.countryId;
        this.selectedCountryName = data.countryName;
        selectedLocation.name = data.cityName + ', ' + data.countryName;
        selectedLocation.cityName = data.cityName;
        selectedLocation.countryName = data.countryName;


        this.cityControl.setValue(selectedLocation);
        this.cityControl.markAllAsTouched();

        this.selectedCountryName = data.countryName;
        this.selectedCityName = data.cityName;
        if (data.streetName.includes("Unnamed Road")) {
            data.streetName = data.streetName.replace("Unnamed Road", data.cityName);
        }
        this.addressForm.get('street').setValue(data.streetName);
        this.addressForm.get('cityId').setValue(data.cityId);

        

        // setTimeout(()=>{ // this will make the execution after the above boolean has changed
        //     this.iLongitude.nativeElement.focus();
        //   },0); 
        // this.addressForm.updateValueAndValidity();
        // this.cityControl.updateValueAndValidity();

        // this.contactForm.updateValueAndValidity();
        // this.updateFormValues();

        // this.addressForm.setControl('countryId', new FormControl(this.countryId));
        // this.addressForm.setControl('display', new FormControl(displayMessage));
        // this.addressForm.setControl('countryName', new FormControl(data.countryName));
        // this.addressForm.setControl('cityName', new FormControl(data.cityName));
        // this.addressForm.setControl('latitude', new FormControl(data.lat));
        // this.addressForm.setControl('longitude', new FormControl(data.lng));
    }

    removeAddress(): void {
        const addresses = this.contactForm.get('addresses') as FormArray;
        if (addresses.length === 1) {
            return;
        }
        addresses.removeAt(this.index - 1);
        this.contactForm.markAsDirty();
    }
   

}
export enum AddressType {
    Normal = 1,
    Location = 2
}
