
export class NetPointResult {
    success: boolean;
    message: string;
    errorMessage: string;
    errorCode: number;
    dataCount: number;
    data: any;
}
export class PreDeliverResult {
    label: string;
    shortCode: string;
    codAmountUSD: number;
    codAmountLBP: number;
    consigneeName: string;
    status: string;
    constructor(result?) {
        result = result || {};
        this.label = result.label || 'N/A';
        this.consigneeName = result.consigneeName || 'N/A';
        this.codAmountUSD = result.codAmountUSD || 0;
        this.codAmountLBP = result.codAmountLBP || 0;
        this.shortCode = result.shortCode || '';
        this.status = result.status || '';
    }
}

export class DeliverResult {
    result: boolean;
    message: string;
}

