import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseCountdownModule } from '@fuse/components';

import { InvoicePaymentComponent } from './Invoice-payment.component';
import { MatAutocompleteModule, MatChipsModule, MatDialogActions, MatDialogModule, MatDialogTitle, MatExpansionModule, MatMenuModule, MatPaginatorModule, MatProgressBar, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule } from '@angular/material';
import { InvoiceCheckoutComponent } from './Invoice-checkout/Invoice-checkout.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PaymentComponent } from '../orders/new-order/payment/payment.component';
import { InvoicePaymentService } from './Invoice-payment.services';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InvoicePaymentDoneComponent } from './payment-done/invoice-done.component';
import { InvoiceDoneService } from './payment-done/invoice-done.service';

const routes = [
    // {
    //     path: 'invoice-payment/:id',
    //     component: InvoiceCheckoutComponent,
    //     resolve: {
    //         data: InvoicePaymentService
    //     }
    // },
    {
        path: 'invoice-payment/:id',
        component: InvoicePaymentComponent,
        resolve: {
            data: InvoicePaymentService
        }
    },
    {
        path: 'invoice-payment-redirect',
        component: InvoicePaymentDoneComponent,
        resolve: {
            data: InvoiceDoneService
        }
    }
];

@NgModule({
    declarations: [
        InvoicePaymentComponent,
        InvoiceCheckoutComponent,
        InvoicePaymentDoneComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule.forChild(routes),
        MatButtonModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatIconModule,
        MatProgressBarModule,
        FuseCountdownModule,
        FlexLayoutModule
    ],
    providers: [
        InvoicePaymentService,
        InvoiceDoneService,
        InvoiceCheckoutComponent
    ],
    entryComponents: [
        InvoiceCheckoutComponent,
    ],
})
export class InvoicePaymentModule {
}
