<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Select NetPoint Location
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog"
                type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <div class="p-24 pb-0 m-0 h-70-vh" fusePerfectScrollbar
            *ngIf="!loading && netpoints !== undefined && netpoints !== null && netpoints.length !== 0">
            <mat-accordion [@.disabled]="disableAnimation">
                <ng-container *ngFor="let netpoint of netpoints; let i = index">
                    <mat-expansion-panel [expanded]="false" class="mb-16">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="text-truncate">
                                {{ netpoint.display }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div fxFlex fxLayout="column">
                            <div class="mr-sm-12" fxFlex>
                                <strong>Country: </strong>
                                <span class="mat-body-2">{{ netpoint.countryName }}</span>
                            </div>

                            <div class="mr-sm-12" fxFlex>
                                <strong>City: </strong>
                                <span class="mat-body-2">{{ netpoint.cityName }}</span>
                            </div>

                            <div class="mr-sm-12" fxFlex>
                                <strong>Street Address: </strong>
                                <span class="mat-body-2">{{ netpoint.street }}</span>
                            </div>

                            <div class="mr-sm-12" fxFlex>
                                <strong>Landmark: </strong>
                                <span class="mat-body-2">{{ netpoint.landmark }}</span>
                            </div>

                            <div style="text-align: right" class="mb-16">
                                <button mat-stroked-button color="primary" (click)="matDialogRef.close(netpoint)">
                                    <mat-icon>check</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
        <div fxLayout="row" class="h-70-vh" fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <div fxLayout="row" class="h-70-vh" fxLayoutAlign="center center"
            *ngIf="!loading && netpoints !== undefined && netpoints !== null && netpoints.length === 0">
            No available NetPoint locations
        </div>
        <div fxLayout="row" class="h-70-vh" fxLayoutAlign="center center"
            *ngIf="!loading && (netpoints === undefined || netpoints == null)">
            <span class="warn-fg">Could not retrieve list of NetPoint</span>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <button mat-button class="mr-8" (click)="matDialogRef.close()" aria-label="Cancel" matTooltip="Cancel">
            CANCEL
        </button>
    </mat-dialog-actions>
</div>