<mat-progress-bar class="app-progress-bar" *ngIf="showProgressBar" mode="indeterminate" color="accent">
</mat-progress-bar>
<mat-card-header>
    <mat-card-title>Contact Import Correction</mat-card-title>
</mat-card-header>
<mat-card-header>
    <mat-card-subtitle>Total Contacts : {{contacts.length}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="invalidContacts">Invalid Contacts : {{invalidContacts.length - countinValid}}
    </mat-card-subtitle>
</mat-card-header>
<mat-error *ngIf="invalidContacts.length > 0" class="mr-8">Kindly fill out required fields to proceed!</mat-error>

<mat-card-actions align="end" style="align-items:flex-end; margin-top: -40px;">
    <input type="submit" style="display:none;" />
    <button mat-raised-button color="primary" [disabled]="invalidContacts.length > 0" class="buttons"
        (click)="saveImported();">Confirm</button>
    <button mat-raised-button color="primary" (click)="closeDialog();" class="buttons">Cancel</button>
    <button id="auto_trigger" [hidden]="true" color="primary" (click)="save(importForm);">Validate</button>
</mat-card-actions>

<form class="form" #importForm="ngForm" (ngSubmit)="importForm.form.valid" validate>
    <mat-card>
        <mat-card-content>
            <mat-table class="table" [dataSource]="dataSource">
                <ng-container matColumnDef="edit" class="editContainer">
                    <mat-header-cell *matHeaderCellDef>
                        Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact; let rowIdx = index;">


                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && !contact.isValid">
                            <mat-icon class="edtIcon">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewData(contact)"
                            *ngIf=" isEditOn && editIndex==rowIdx && !contact.isValid">
                            <mat-icon class="edtIcon">check</mat-icon>
                        </button>
                        <button class="editBtn" (click)="onEdit(rowIdx)" *ngIf="!isEditOn && contact.isValid">
                            <mat-icon class="edtIconGreen">edit</mat-icon>
                        </button>
                        <button class="editBtn" (click)="saveNewData(contact)"
                            *ngIf="contact.isValid && isEditOn && editIndex==rowIdx">
                            <mat-icon class="edtIconGreen">check</mat-icon>
                        </button>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="firstname">
                    <mat-header-cell *matHeaderCellDef>
                        Firstname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #firstName="ngModel" name="txtFirstname-{{rowIdx}}"
                                placeholder="FirstName" [(ngModel)]="employee.firstName" required
                                style="font-size: smaller;">
                            <mat-error *ngIf="firstName?.invalid">
                                <div *ngIf="firstName.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.firstName}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>
                        Lastname
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #lastname="ngModel" name="txtLastname-{{rowIdx}}"
                                placeholder="lastname" [(ngModel)]="employee.lastName" required>
                            <mat-error *ngIf="lastname?.invalid">
                                <div *ngIf="lastname.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.lastName}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef>
                        Company Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput style="width:200px" #companyName="ngModel" name="txtCompanyName-{{rowIdx}}"
                                placeholder="companyName" [(ngModel)]="employee.companyName">
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.companyName}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>
                        Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput type="email" #email="ngModel" name="txtEmail-{{rowIdx}}" placeholder="Email"
                                [(ngModel)]="employee.email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$">
                            <mat-error *ngIf="email.hasError('pattern')">
                                invalid
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.email}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mobile">
                    <mat-header-cell *matHeaderCellDef>
                        Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #mobile="ngModel" name="txtMobile-{{rowIdx}}" placeholder="mobile"
                                [(ngModel)]="employee.phoneNumber" required>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.required">required</div>
                            </mat-error>
                            <mat-error *ngIf="mobile?.invalid">
                                <div *ngIf="mobile.errors.validCountryMobile">invalid</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px" *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.phoneNumber}}</span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="country">
                    <mat-header-cell *matHeaderCellDef>
                        Country
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index; ">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select style="width:100px" #country="ngModel" name="ddlCountry-{{rowIdx}}"
                                (selectionChange)="changeCountry(rowIdx,$event)" placeholder="Country"
                                [(ngModel)]="employee.addresses[0].countryId" required>

                                <mat-option *ngFor="let country of countries" [value]="country.countryId">
                                    {{ country.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="country?.invalid">
                                <div *ngIf="country.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.addresses[0].countryName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="phoneCountryCode">
                    <mat-header-cell *matHeaderCellDef>
                        Code
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field style="min-width:fit-content;" *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select #countryCode="ngModel" name="ddlCountryCode-{{rowIdx}}"
                                (selectionChange)="changeCountryCode(rowIdx,$event)" placeholder="Code"
                                [(ngModel)]="employee.phoneCountryCode" required>

                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countryCodeFilterCtrl"
                                        placeholderLabel="Find code..." noEntriesFoundLabel="no matching code found">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredCountryCodes  | async"
                                    [value]="country.countryPhoneCode">
                                    {{ country.countryPhoneCode }}
                                </mat-option>

                            </mat-select>
                            <mat-error *ngIf="countryCode?.invalid">
                                <div *ngIf="countryCode.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="min-width:fit-content"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.phoneCountryCode}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>
                        City
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-select matInput #city="ngModel" name="ddlCity-{{rowIdx}}" placeholder="City"
                                [(ngModel)]="employee.addresses[0].cityId" required>


                                <mat-option *ngFor="let city of countryCities;trackBy: trackById"
                                    [value]="city.cityId">
                                    {{ city.cityName }}
                                </mat-option>


                            </mat-select>
                            <mat-error *ngIf="city?.invalid">
                                <div *ngIf="city.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx">{{employee.addresses[0].cityName}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="street">
                    <mat-header-cell *matHeaderCellDef>
                        Street
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #street="ngModel" name="txtStreet-{{rowIdx}}" placeholder="street"
                                [(ngModel)]="employee.addresses[0].street" required>
                            <mat-error *ngIf="street?.invalid">
                                <div *ngIf="street.errors.required">required</div>
                            </mat-error>
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{employee.addresses[0].street}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="building">
                    <mat-header-cell *matHeaderCellDef>
                        Building
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #building="ngModel" name="txtBuilding-{{rowIdx}}" placeholder="building"
                                [(ngModel)]="employee.addresses[0].building">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{employee.addresses[0].building}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="floor">
                    <mat-header-cell *matHeaderCellDef>
                        Floor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #floor="ngModel" name="txtFloor-{{rowIdx}}" placeholder="floor"
                                [(ngModel)]="employee.addresses[0].floor">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{employee.addresses[0].floor}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="landmark">
                    <mat-header-cell *matHeaderCellDef>
                        Landmark
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #landmark="ngModel" name="txtLandmark-{{rowIdx}}" placeholder="landmark"
                                [(ngModel)]="employee.addresses[0].landmark">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{employee.addresses[0].landmark}}</span>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="postCode">
                    <mat-header-cell *matHeaderCellDef>
                        Postal Code
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employee; let rowIdx = index;">
                        <mat-form-field *ngIf="isEditOn && editIndex==rowIdx">
                            <mat-label></mat-label>
                            <input matInput #postCode="ngModel" name="txtPostCode-{{rowIdx}}" placeholder="postCode"
                                [(ngModel)]="employee.addresses[0].zipCode">
                        </mat-form-field>
                        <span style="width:200px"
                            *ngIf="!isEditOn || editIndex!=rowIdx ">{{employee.addresses[0].zipCode}}</span>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns ; let i = index; ">
                </mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</form>