import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { DistributionBillingDetails, Distribution, DistributionDetail, DistributionView } from '../new-distribution/distribution.model';
import { DistributionViewService } from '../view/distribution-view.service';
import { DistributionService } from '../distribution.service';
import { ActivatedRoute } from '@angular/router';
import * as pbi from 'powerbi-client';
import { IEmbedConfigurationBase } from 'embed';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'distribution-manifest',
    templateUrl: './manifest.component.html',
    styleUrls: ['./manifest.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DistributionManifestComponent implements OnInit, OnDestroy {
    PBmodel: pbi.IEmbedConfiguration;
    title: string;
    report: pbi.Report;
    pages: pbi.Page[];
    currentPage: pbi.Page;

    EmbedReport: any;
    powerbi: pbi.service.Service = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);;
    // powerbi: pbi.service.Service;


    distribution: DistributionView;
    distributiond: DistributionDetail;
    date: Date = new Date();
    billingDetails: DistributionBillingDetails;

    displayedColumns = ['label', 'receiver', 'destination', 'weight', 'nop', 'shipmentValue'];
    // displayedColumns = ['label', 'receiver'];
    dataSource;
    ELEMENT_DATA: DistributionManifest[] = [];

    today = new Date().toJSON().slice(0,10).replace(/-/g,'/');
    public screenHeight: number;
    @ViewChild('embeddedReport', { static: false }) embeddedReport: ElementRef;
    @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;
    config: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _distributionViewService: DistributionViewService,
        private httpClient: HttpClient, private _orderService: DistributionService) {
        this.title = "Sample Check";
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const subsidiaryid = localStorage.getItem('subsidiaryId');

        this._distributionViewService.onDistributionViewSelected.subscribe(order => {
            this.distribution = order;
        });
        this._orderService.getMyInfo().subscribe((info: DistributionBillingDetails) => {
            this.billingDetails = info;
        });

        this.distribution.distributionInfo.forEach(x => {
            var dist = new DistributionManifest();
            dist.label = x.label;
            dist.receiver = x.contact.fullName,
                dist.destination = x.contact.address.display,
                dist.weight = 1,
                dist.nop = 1,
                dist.shipmentValue = 0;
            this.ELEMENT_DATA.push(dist);
        });
        this.dataSource = this.ELEMENT_DATA;
    }
    onEmbedded() { }
    getReport(subsidiaryid) {
        // subsidiaryid = "9076F344-700B-4407-9E43-A7472F578F4D";
        const display = {
            isLockedInViewMode: true,
            isHiddenInViewMode: true
        };
        const filter1 = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            displaySettings: display,
            target: {
                table: "vw_CodDetailsReport",
                column: "ClientId",
            },

            operator: "In",
            values: [subsidiaryid]
        };
        filter1.values[0] = filter1.values[0].replace(/['"]+/g, '');
        const config: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.EmbedReport.embedToken.token,
            id: this.EmbedReport.id,
            permissions: pbi.models.Permissions.Read,
            pageName: "Page 1",

            embedUrl: this.EmbedReport.embedUrl,
            filters: [filter1],
            settings: {
                filterPaneEnabled: true, // this will show/hide filter options
                navContentPaneEnabled: true
            }
        };


        let reportContainer = <HTMLElement>document.getElementById('powerBIReportContainer');
        this.powerbi.embed(reportContainer, config);
        // const config = <IEmbedConfigurationBase><any>{
        //     type: 'report',
        //     accessToken: this.EmbedReport.embedToken.token,
        //     embedUrl: this.EmbedReport.embedUrl,
        //     id: this.EmbedReport.Id,
        //     settings: {
        //         filterPaneEnabled: true,
        //         navContentPaneEnabled: true
        //     }
        // };

        // Grab the reference to the div HTML element that will host the report.
        // let pbicontainer = <HTMLElement>document.getElementById('pbicontainer');
        // let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

        // let reportContainer = <HTMLElement>document.getElementById('powerBIReportContainer');
        // this.powerbi.embed(reportContainer, config);

        // let report = powerbi.embed(pbicontainer, config);

        // Report.off removes a given event handler if it exists.
        // report.off("loaded");

        // // Report.on will add an event handler which prints to Log window.
        // report.on("loaded", function () {
        // });
    }
    ngAfterViewInit(): void {
    }
    pagesPerSheet(index: number) {
        if (index == 0)
            return true;
        else if (index % 4 == 0)
            return true
        else
            return false;
    }
    onPrint() {
        window.print();
    }
    printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    // showReport(accessToken) {  
    //     // Embed URL    
    //     let embedUrl = "https://app.powerbi.com/reportEmbed?groupId=[put-group-id-here]";  
    //     let embedReportId = "c848e239-2c38-4b8b-b6ad-baa752aca56";  
    //     let config = {  
    //         type: 'report',  
    //         accessToken: accessToken,  
    //         embedUrl: embedUrl,  
    //         id: embedReportId,  
    //         settings: {}  
    //     };  
    //     let reportContainer = this.reportContainer.nativeElement;  
    //     let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);  
    //     let report = powerbi.embed(reportContainer, config);  
    //     report.off("loaded");  
    //     report.on("loaded", () => {  
    //     });  
    //     report.on("error", () => {  
    //         this.getToken();  
    //     });  
    // }  
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }




}
export class DistributionManifest {
    label: string;
    receiver: string;
    destination: string;
    weight: number;
    nop: number;
    shipmentValue: number
    constructor(distribution?) {
        distribution = distribution || {};
        this.label = distribution.label;
        this.receiver = distribution.receiver || '';
        this.destination = distribution.destination;
        this.weight = distribution.weight;
        this.nop = distribution.nop;
        this.shipmentValue = distribution.shipmentValue;
    }
}






