<form [formGroup]="group">
    <div class="mb-12 w-100-p row">
        <div class="ship-from mb-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Ship From
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <div class="col-xs-12 col-md-9 my-4">
                            <div class="row my-4">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>Shipper</mat-label>
                                    <mat-select name="mynet-order-shipper" formControlName="shipperId" required>
                                        <mat-option *ngFor="let contact of contacts" [value]="contact.contactId">
                                            <span class="text-truncate">{{contact.fullName}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md"
                                    ngClass.xs="my-8">
                                    <button tabindex="-1" type="button" mat-button *ngIf="groupValue.shipperId"
                                        (click)="editContact(groupValue.shipperId)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button type="button" tabindex="-1" mat-button (click)="addNewShipper()">
                                        <mat-icon class="pr-8">person_add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row my-4" [ngClass]="isNetPointShipper ? 'address-container' : ''">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>{{ shipperAddressLabel }}</mat-label>
                                    <mat-select name="mynet-order-shipper-address" formControlName="shipperAddressId"
                                        required>
                                        <mat-option *ngFor="let contact of shipperAddresses"
                                            [value]="contact.addressId">
                                            <span class="text-truncate">{{contact.display}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md">
                                    <button type="button" tabindex="-1" mat-button *ngIf="isNetPointShipper"
                                        (click)="openNetPointDialog(AddressType.Shipper, 'change')">
                                        <mat-icon>room</mat-icon>
                                        Change
                                    </button>
                                    <button type="button" tabindex="-1" mat-button
                                        (click)="openNetPointDialog(AddressType.Shipper)"
                                        [disabled]="!groupValue.shipperId">
                                        <mat-icon>{{ isNetPointShipper ? 'person' : 'move_to_inbox' }}</mat-icon>
                                        {{ fromNetPointButtonText }}
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3 my-4">
                            <distribution-contact-preview
                                *ngIf="shipperAddresses.length > 0 && groupValue.shipperId && groupValue.shipperAddressId "
                                [isNetPoint]="isNetPointShipper" [contact]="selectedShipper"
                                [address]="selectedShipperAddress"></distribution-contact-preview>
                        </div>
                    </div>
                    <mat-error class="mb-8 mt-8 text-italic row center-xs start-md"
                        *ngIf="groupValue.shipperId && shipperAddresses && shipperAddresses.length === 0">
                        This contact has not specified an address in {{domesticCountryName}}</mat-error>
                    <div class="row center-xs start-md mt-8">

                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="ship-to mb-4 mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Ship To
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <div class="col-xs-12 col-md-9 my-4">
                            <div class="row my-4">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>Contact List</mat-label>
                                    <mat-select name="mynet-order-receiver" formControlName="receiverId" required>
                                        <mat-option *ngFor="let a of contactLists" [value]="a.contactListId">
                                            <span class="text-truncate">{{a.description}}</span>
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error
                                    *ngIf="group.get('receiverListId') && currentDirection !== undefined && maxWeight">
                                             Max weight for {{ ServiceDirection[currentDirection] }} is {{ maxWeight }} kg
                                    </mat-error> -->
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md">
                                    <button type="button" mat-button tabindex="-1" *ngIf="groupValue.receiverId"
                                        (click)="editContactList(groupValue.receiverId)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button type="button" tabindex="-1" mat-button (click)="addNewReceiverList()">
                                        <mat-icon class="pr-8">person_add</mat-icon>
                                    </button>
                                </div>

                            </div>
                            <!-- <div class="row my-4"> -->
                            <!-- <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Contacts</mat-label>
                                <mat-select name="mynet-order-receiver" formControlName="receiverId" > 
                                    <mat-option *ngFor="let a of contacts" [value]="a.contactListId">
                                        <span class="text-truncate">{{a.fullName}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <!-- <div class="search-wrapper mx-32 mx-md-0">
                                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon>search</mat-icon>
                                    <input #filter placeholder="Search for a contact">
                                </div>
                                <div  style="float:right;" class="search-wrapper mx-32 mx-md-0">
                                  <p>
                                    Contacts selected: {{selection.selected.length}}
                                  </p>
                                </div>
                              </div> -->

                            <!-- <table mat-table   [dataSource]="dataSource" class="mat-elevation-z8">
                                  <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue() && isAllSelected()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                    [aria-label]="checkboxLabel()">
                                      </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                      <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(row) : null"
                                                    [checked]="selection.isSelected(row)"
                                                    [aria-label]="checkboxLabel(row)">
                                      </mat-checkbox>
                                    </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="fullName">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef> Email </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                                    <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
                                  </ng-container>
                                
                                  <ng-container matColumnDef="address">
                                    <th mat-header-cell *matHeaderCellDef> Address </th>
                                    <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                                  </ng-container>
                                
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                      (click)="selection.toggle(row)">
                                  </tr>
                                </table> -->
                            <!-- </div> -->
                            <!-- <div class="row my-4" [ngClass]="isNetPointReceiver ? 'address-container' : ''">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>{{ receiverAddressLabel }}</mat-label>
                                    <mat-select name="mynet-order-receiver-address" formControlName="receiverAddressId"
                                        required>
                                        <mat-option *ngFor="let contact of receiverAddresses"
                                            [value]="contact.addressId">
                                            <span class="text-truncate">{{contact.display}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md"
                                    *ngIf="!isExport">
                                    <button type="button" mat-button tabindex="-1" *ngIf="isNetPointReceiver"
                                        (click)="openNetPointDialog(AddressType.Receiver, 'change')">
                                        <mat-icon>room</mat-icon>
                                        Change
                                    </button>
                                    <button type="button" mat-button tabindex="-1"
                                        (click)="openNetPointDialog(AddressType.Receiver)"
                                        [disabled]="!groupValue.receiverId">
                                        <mat-icon>{{ isNetPointReceiver ? 'person' : 'move_to_inbox' }}</mat-icon>
                                        {{ toNetPointButtonText }}
                                    </button>
                                </div>
                            </div> -->
                        </div>
                        <!-- <distribution-contact-preview *ngIf="shipperAddresses.length > 0 && groupValue.shipperId && groupValue.shipperAddressId "
                            [isNetPoint]="isNetPointShipper" [contact]="selectedShipper" 
                            [address]="selectedShipperAddress"></distribution-contact-preview> -->
                        <div class="col-xs-12 col-md-3 my-4">
                            <distribution-contactList-preview *ngIf="groupValue.receiverId > 0"
                                [contact]="selectedReceiver" [contactList]="selectedReceiverList">
                            </distribution-contactList-preview>
                        </div>

                    </div>

                    <div class="row center-xs start-md mt-8">

                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="package-information mb-4 mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Package Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>What are you shipping?</mat-label>
                            <mat-select name="mynet-order-commodity" formControlName="commodityId" required>
                                <mat-option *ngFor="let commodity of commodities" [value]="commodity.commodityId">
                                    <span class="text-truncate">{{ commodity.title }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <ng-container formGroupName="quote">
                        <div class="suffix row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Weight</mat-label>
                                <input matInput name="mynet-order-weight" formControlName="weight" required
                                    (change)="weightChanged($event)" type="number" min="0"
                                    (keypress)="numberOnly($event)">
                                <span matSuffix>kg</span>
                                <mat-error
                                    *ngIf="group.get('quote').get('weight').hasError('validWeight') && currentDirection !== undefined && maxWeight">
                                    Max weight for {{ ServiceDirection[currentDirection] }} is {{ maxWeight }} kg
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Number Of Pieces</mat-label>
                                <input matInput name="mynet-order-numberOfPieces" formControlName="numberOfPieces"
                                    (keypress)="numberOnly($event)" required type="number" min="1">
                            </mat-form-field>
                        </div>

                        <div class="suffix-prime row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Shipment Value</mat-label>
                                <input matInput name="mynet-order-shipmentValue" formControlName="shipmentValue"
                                    (keypress)="numberOnly($event)" type="number" min="0" max="99999">
                                <span matSuffix>$</span>
                                <mat-error *ngIf="group.get('quote').get('shipmentValue').hasError('validDecimal')">
                                    Shipment Value should be between  0.00 and  9999999999999999
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <h3 class="h3">Dimensions
                            <button mat-icon-button color="primary" type="button"
                                (click)="showDimensionsHelp = !showDimensionsHelp">
                                <mat-icon>info</mat-icon>
                            </button>
                            <span class="h6 text-italic" *ngIf="showDimensionsHelp">
                                Shipments are priced either by weight or dimensional weight — a calculation based on
                                package
                                size.
                            </span>
                        </h3>
                        <div class="row suffix">
                            <div class="col-xs-12 col-md-6">
                                <div class="row">
                                    <mat-form-field class="col-xs-12">
                                        <mat-label>Length</mat-label>
                                        <input matInput name="mynet-order-length" formControlName="length" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>
                                        <mat-error *ngIf="group.get('quote').get('length').hasError('validDecimal')">
                                            Length should be between 0 and 200
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class=" row">
                                    <mat-form-field class="col-xs-12">
                                        <mat-label>Width</mat-label>
                                        <input matInput name="mynet-order-width" formControlName="width" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>
                                        <mat-error *ngIf="group.get('quote').get('width').hasError('validDecimal')">
                                            Width should be between 0 and 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field class="col-xs-12">
                                        <mat-label>Height</mat-label>
                                        <input matInput name="mynet-order-height" formControlName="height" type="number"
                                            (keypress)="numberOnly($event)" min="0" max="999">
                                        <span matSuffix>cm</span>
                                        <mat-error *ngIf="group.get('quote').get('height').hasError('validDecimal')">
                                            Height should be between 0 and 100
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6 center-xs start-md"
                                style="display: flex;align-items: center;">
                                <img width="200" src="/assets/images/package-dimensions.png" alt="Package Dimensions" />
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>Description of goods</mat-label>
                            <textarea matInput rows="3" formControlName="goodsDescription"
                                name="mynet-order-goodsDescription"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="shipping-service mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Shipping Service
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="date row">
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>Pickup date</mat-label>
                            <input matInput name="mynet-order-pickupdate" placeholder="Date" [min]="minDate"
                                [matDatepicker]="picker" (focus)="picker.open()" readonly required
                                formControlName="pickupDate">
                            <mat-icon matSuffix>date_range</mat-icon>
                        </mat-form-field>

                        <mat-radio-group class="mb-16" name="mynet-order-shipping-type"
                            formControlName="timeRangeLookupId"
                            [ngClass]="group.get('timeRangeLookupId').hasError('required') ? 'red-fg' : ''" required>
                            <div *ngFor="let range of timeRanges; let idx = index">
                                <mat-radio-button (change)="onTimeRangeChange(range)" [value]="range.id"
                                    >{{range.name}}</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>

                    <ng-container formGroupName="quote">
                        <div class="row">
                            <mat-form-field class="col-xs-12 col-md-6"
                                *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length > 2 && !isExport">
                                <mat-label>
                                    How soon do you want it received?
                                </mat-label>
                                <mat-select formControlName="shippingTypeId" name="mynet-order-shippingTypeId" required>
                                    <mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">
                                        {{shippingType.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length == 2 && !isExport"
                            class="col-xs-12 col-md-6">
                            <!-- <mat-label class="mb-8 row"
                                [ngClass]="group.get('quote').get('shippingTypeId').hasError('required') ? 'red-fg' : ''">
                                How soon do you want it received? *
                            </mat-label>
                            <mat-radio-group class="mb-16" name="mynet-order-shipping-type"
                                formControlName="shippingTypeId"
                                [ngClass]="group.get('quote').get('shippingTypeId').hasError('required') ? 'red-fg' : ''"
                                required>
                                <mat-radio-button class="mr-8" [value]="shippingTypes[0].id">
                                    {{ shippingTypes[0].name }}
                                </mat-radio-button>
                                <mat-radio-button class="ml-8" [value]="shippingTypes[1].id">
                                    {{ shippingTypes[1].name}}
                                </mat-radio-button>
                            </mat-radio-group> -->
                        </div>
                    </ng-container>

                    <div class="row">
                        <mat-form-field class="pl-4 no-padding-sm col-xs-12 col-md-6">
                            <mat-label>Special Instructions</mat-label>
                            <textarea matInput rows="3" formControlName="specialInstructions"
                                name="mynet-order-specialInstructions"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>

    <mat-datepicker #picker></mat-datepicker>
</form>
<mat-action-row>

    <button type="button" mat-button color="warn" *ngIf="!hasSubsidiaryId" (click)="cancel()">Previous</button>
    <button type="button" mat-button color="warn" *ngIf="hasSubsidiaryId" (click)="backtoDistributions()">Cancel</button>
    <button type="button" [disabled]="group.invalid " mat-button color="primary"
        (click)="confirmDistribution()">Confirm</button>
    <!-- <button type="button" *ngIf="isWalkIn"
        [disabled]="groupValue.quote.quotePrice === 0 || group.invalid || newQuote.value || generatingQuote " mat-button
        color="primary" (click)="submitForm()">Next</button> -->
    <!-- <button *ngIf="newQuote.value" type="button" mat-button color="primary" (click)="generateNewQuote()">Update
        Price</button> -->
</mat-action-row>
<mat-progress-bar *ngIf="generatingQuote" mode="indeterminate"></mat-progress-bar>