<div id="order" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent no-print"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center no-padding-print">

        <!-- HEADER -->
        <div class="header accent no-print" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button tabindex="-1" class="mr-16" [routerLink]="'/distributions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        Distribuion 
                    </div>
                    <!-- <div class="subtitle secondary-text" fxLayoutAlign="start center">
                        <mat-icon>{{ orderDetailsView.shipmentIcon }}</mat-icon>
                        <span *ngIf="lastEvent" class="ml-8">
                            {{ lastEvent.name }} at {{ lastEvent.dateFormatted }}
                        </span>
                    </div> -->
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card no-border-print">

            <!-- CONTENT -->
            <div class="content">

                <mat-tab-group fxLayout="column" fxFlex class="order-tabs">

                    <mat-tab label="Shipper Details">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                             <distribution-contact-view [contact]="distribution.shipper" [contactType]="'Shipper'">
                            </distribution-contact-view>

                        </div>

                    </mat-tab>

                    <mat-tab label="Receiver Details">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                            <distribtuion-details-view #orderDetailsView  [distribution] = "distribution" 
                            [distributionId] = "distribution.distributionId"> </distribtuion-details-view>

                           
                            <!-- <order-contact-view [contact]="order.shipper" [contactType]="'Shipper'">
                            </order-contact-view>

                            <order-contact-view [contact]="order.receiver" [contactType]="'Receiver'">
                            </order-contact-view>

                            <order-payment-view [payments]="order.payments"></order-payment-view> -->

                        </div>

                    </mat-tab>

 

                    <!-- <mat-tab label="Order Status">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                            <order-status-view #orderStatusView [orderId]="order.orderId"
                                (lastEvent)="lastEventTriggered($event)"></order-status-view>

                        </div>

                    </mat-tab> -->

                    <!-- <mat-tab label="Invoice" *ngIf="order.invoiceFilePath && trustedInvoiceUrl">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                            <order-invoice></order-invoice>
                            <div fxLayout="row" style="height: 100%;">
                                <object [data]="trustedInvoiceUrl" type="application/pdf" width="50%" height="80%">
                                    Click here to view the <a [href]="trustedInvoiceUrl">Invoice</a>
                                </object>
                                <div class="ml-8">
                                    <a mat-raised-button color="primary" [href]="trustedInvoiceUrl" target="_blank">
                                        <mat-icon>print</mat-icon>
                                        Print Invoice
                                    </a>
                                </div>

                            </div>


                        </div>

                    </mat-tab> -->

                </mat-tab-group>
            </div>
        </div>
    </div>
</div>