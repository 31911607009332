export class FuseUtils {
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns {any}
     */
    public static filterArrayByStringAndDate(mainArr, searchText, fromDate, toDate): any {
        searchText = searchText.toLowerCase();
        return mainArr.filter(itemObj => {
            var d = Date.parse(itemObj['updatedDate']);
            var dfrom = Date.parse(fromDate);
            var dto = Date.parse(toDate);

            if (searchText)
                if (dfrom.toString() != 'NaN' && dto.toString() != 'NaN') {
                    return (this.searchInObj(itemObj, searchText) && (d >= fromDate && d <= toDate));
                }
                else if (dfrom.toString() != 'NaN' && dto.toString() == 'NaN') {
                    return (this.searchInObj(itemObj, searchText) && (d >= fromDate));
                }
                else if (dfrom.toString() == 'NaN' && dto.toString() != 'NaN') {
                    return (this.searchInObj(itemObj, searchText) && (d <= toDate));
                }
                else
                    return (this.searchInObj(itemObj, searchText));
            else {
                if (dfrom.toString() != 'NaN' && dto.toString() != 'NaN') {
                    return (d >= fromDate && d <= toDate);
                }
                else if (dfrom.toString() != 'NaN' && dto.toString() == 'NaN') {
                    return (d >= fromDate);
                }
                else if (dfrom.toString() == 'NaN' && dto.toString() != 'NaN') {
                    return (d <= toDate);
                }
                else
                    return mainArr;
            }
        });
    }
    public static filterArrayByString(mainArr, searchText): any {
        if (searchText === '') {
            return mainArr;
        }
        searchText = searchText.toLowerCase();
        if (searchText.toString().includes("producttypeid = 0", "0")) {
            searchText = searchText.toString().replace("producttypeid = 0", "");
            mainArr = mainArr.filter(order => order.productTypeId === 0)
        }
        if (searchText.toString().includes("producttypeid = 1", "1")) {
            searchText = searchText.toString().replace("producttypeid = 1", "");
            mainArr = mainArr.filter(order => order.productTypeId === 1)
        }
        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    public static searchInObj(itemObj, searchText): boolean {

        for (const prop in itemObj) {

            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }
            const value = itemObj[prop];


            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }
            else if (Array.isArray(value)) {
                if (this.searchInArray(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    public static searchInArray(arr, searchText): boolean {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any {
        return value.toLowerCase().includes(searchText);
    }

    public static filterProductType(value, searchText): any {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void {
        if (array.indexOf(item) === -1) {
            array.push(item);
        }
        else {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }
}
