import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent } from 'rxjs';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { Routes } from 'app/Routes';
import { DistributionListItem } from './distribution-list-item.model';
import { DistributionListService } from './distribution-list.service';
import { DistributionListDataSource } from './distribution-list.datasource';
import { DistributionEventsDialogComponent } from './Distribution-events-dialog/distribution-events-dialog.component';
import { Router } from '@angular/router';
import { DistributionPrintDialogComponent } from './distribution-print-dialog/distribution-print-dialog.component';
import { DistributionInvoiceComponent } from '../distribution-invoice/distribution-invoice.component';

@Component({
    selector: 'distribution-order-list',
    templateUrl: './distribution-list.component.html',
    styleUrls: ['./distribution-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DistributionListComponent implements OnInit, OnDestroy {
    dataSource: DistributionListDataSource | null;
    // displayedColumns = ['direction', 'label', 'shipper', 'from', 'receiver', 'to', 'price', 'status', 'statusDate', 'actions'];
    displayedColumns = ['direction', 'shipper', 'from', 'receiver', 'to', 'status', 'statusDate', 'actions'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    printDialogRef: MatDialogRef<DistributionPrintDialogComponent>;
    distributionEventsDialogRef: MatDialogRef<DistributionEventsDialogComponent>;
    invoiceDialogRef: MatDialogRef<DistributionInvoiceComponent>;


    ServiceDirection = ServiceDirection;
    Routes = Routes;
    receiverCount: number = 0;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(private _router: Router, private _distributionsListService: DistributionListService, public _matDialog: MatDialog) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this.dataSource = new DistributionListDataSource(this._distributionsListService, this.paginator, this.sort);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

    deleteDistribution(distributionId: number): void {

        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._distributionsListService.deleteDistributions(distributionId);
            }
            this.confirmDialogRef = null;
        });
    }
    duplicateDistribution(distributionId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to duplicate?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._distributionsListService.duplicateDistribution(distributionId);
            }
            this.confirmDialogRef = null;
        });
    }
    printDistribution(distributionId: number): void {
        this.printDialogRef = this._matDialog.open(DistributionPrintDialogComponent, {
            panelClass: "distribution-print-dialog",
            disableClose: false,
            // data: {
            //     pageTypeId: order.returnOrders
            // }
        });
        this.printDialogRef.afterClosed().subscribe(result => {
            if (result[0] != 'cancel') {
                const pagesPerSheet = result[1].pagesPerSheet;
                this._router.navigateByUrl(Routes.PrintDistribution + '/' + distributionId.toString() + '/' + pagesPerSheet);
            }
        });
    }
    printDistributionManifest(distributionId: number): void {
        // this.printDialogRef = this._matDialog.open(DistributionPrintDialogComponent, {
        //     panelClass: "distribution-print-dialog",
        //     disableClose: false,
        //     // data: {
        //     //     pageTypeId: order.returnOrders
        //     // }
        // });
        // this.printDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //         const pagesPerSheet = result[1].pagesPerSheet;
        //         this._router.navigateByUrl(Routes.PrintDistribution + '/' + distributionId.toString() + '/' + pagesPerSheet );
        //     }
        // });
        this._router.navigateByUrl(Routes.PrintDistributionManifest + '/' + distributionId.toString());
    }
    displayDistribution(distributionid) {

    }
    viewOrderEvents(distribution: DistributionListItem): void {


        this._distributionsListService.getDistributionEvents(distribution.distributionId);

        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }

        this.distributionEventsDialogRef = this._matDialog.open(DistributionEventsDialogComponent, {
            panelClass: 'distribution-events-dialog',
            data: {
                // label: order.label,
                maxHeight: '80vh',
                minHeight: '20vh'
            }
        });
    }
    emailDistribution(distributionId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false

        });

        // this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to duplicate?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._distributionsListService.duplicateDistribution(distributionId);
            }
            this.confirmDialogRef = null;
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
