import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { Address, Contact } from 'app/main/contacts/contact-form/contact.model';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { ContactList } from 'app/main/c-list/c-list-form/c-list.model';

@Component({
    selector: 'distribution-contactList-preview',
    templateUrl: './contactList-preview.component.html',
    styleUrls: ['./contactList-preview.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrderContactListPreviewComponent implements OnInit {
    @Input() contactList: ContactList;
    @Input() contact: ContactListItem;
    ngOnInit(): void {
    }
}
