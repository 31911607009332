import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import * as pbi from 'powerbi-client';
import { IEmbedConfigurationBase } from 'embed';
import { ReportService } from '../report.service';
@Component({
    selector: 'clientDashboard',
    templateUrl: './clientDashboard.component.html',
    styleUrls: ['./clientDashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ClientDashboardComponent implements OnInit, OnDestroy {

    EmbedReport: any;
    powerbi: pbi.service.Service = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);;


    config: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _reportService: ReportService) {
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const subsidiaryid = localStorage.getItem('subsidiaryId');
        this.EmbedReport = this._reportService.selectedReport;

        if (this.EmbedReport)
            this.getReport(subsidiaryid);
    }
    getReport(subsidiaryid) {

        const display = {
            isLockedInViewMode: true,
            isHiddenInViewMode: true
        };
        const filter1 = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: pbi.models.FilterType.Basic,
            displaySettings: display,
            target: {
                table: "bi_ClientDashBoard",
                column: "ClientId",
            },

            operator: "In",
            values: [subsidiaryid]
        };
        filter1.values[0] = filter1.values[0].replace(/['"]+/g, '');
        const config: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: this.EmbedReport.embedToken.token,
            id: this.EmbedReport.id,
            permissions: pbi.models.Permissions.Read,
            pageName: "Page 1",

            embedUrl: this.EmbedReport.embedUrl,
            filters: [filter1 ],
            settings: {
                filterPaneEnabled: true, // this will show/hide filter options
                navContentPaneEnabled: false
            }
        };
        let reportContainer = <HTMLElement>document.getElementById('powerBIReportContainer');
        this.powerbi.embed(reportContainer, config);
     
    }
    ngAfterViewInit(): void {
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }




}
export class DistributionManifest {
    label: string;
    receiver: string;
    destination: string;
    weight: number;
    nop: number;
    shipmentValue: number
    constructor(distribution?) {
        distribution = distribution || {};
        this.label = distribution.label;
        this.receiver = distribution.receiver || '';
        this.destination = distribution.destination;
        this.weight = distribution.weight;
        this.nop = distribution.nop;
        this.shipmentValue = distribution.shipmentValue;
    }
}






