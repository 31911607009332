/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common";
import * as i8 from "./confirm-dialog.component";
var styles_FuseConfirmDialogComponent = [i0.styles];
var RenderType_FuseConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseConfirmDialogComponent, data: {} });
export { RenderType_FuseConfirmDialogComponent as RenderType_FuseConfirmDialogComponent };
function View_FuseConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.cancel; _ck(_v, 2, 0, currVal_2); }); }
export function View_FuseConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "pt-24 pb-24 mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "text-center"], ["fxFlex", ""], ["style", "flex:1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "mat-accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(12, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseConfirmDialogComponent_1)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_5 = "mat-accent"; var currVal_6 = (_co.cancel ? "mr-16" : ""); _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_8 = _co.cancel; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.header; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.confirmMessage; _ck(_v, 3, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_7 = _co.confirm; _ck(_v, 12, 0, currVal_7); }); }
export function View_FuseConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-confirm-dialog", [], null, null, null, View_FuseConfirmDialogComponent_0, RenderType_FuseConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.FuseConfirmDialogComponent, [i6.MatDialogRef], null, null)], null, null); }
var FuseConfirmDialogComponentNgFactory = i1.ɵccf("fuse-confirm-dialog", i8.FuseConfirmDialogComponent, View_FuseConfirmDialogComponent_Host_0, {}, {}, []);
export { FuseConfirmDialogComponentNgFactory as FuseConfirmDialogComponentNgFactory };
