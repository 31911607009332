import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentConversionRateComponent } from './current-conversion-rate.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CurrentConversionRateComponent],
  exports: [CurrentConversionRateComponent, CommonModule]
})
export class CurrentConversionRateModule { }
