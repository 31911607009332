import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { OrderViewContact } from '../order-view.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'order-contact-view',
    templateUrl: './contact-view.component.html',
    styleUrls: ['./contact-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderContactViewComponent implements OnInit {
    @Input() contactType: string;
    @Input() contact: OrderViewContact;

    constructor() {}

    ngOnInit(): void {}
}
