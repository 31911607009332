import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Entity } from 'app/_models/Entity.model';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, debounceTime, tap, switchMap, finalize, count } from 'rxjs/operators';
import { Country } from '../../_models/country.model';
import { LocationService } from '../location-service.service';

@Component({
    selector: 'auto-complete-country',
    templateUrl: './auto-complete-country.component.html',
    styleUrls: ['./auto-complete-country.component.scss']
})
export class AutoCompleteCountryComponent implements OnInit {
    @Input() appearance = 'outline';
    @Input() options: Entity<number>[];
    @Input() label: string;
    @Input() required = false;
    @Input() defaultValue = '';
    @Input() disabled = false;
    @Input() allowedCountries: number[];
    @Input() allowedCountriesText: string[];

    @Output() selected: EventEmitter<Entity<number>> = new EventEmitter();
    @Output() cleared: EventEmitter<any> = new EventEmitter();

    filteredOptions: Observable<Entity<number>[]>;
    @Input() control: FormControl;

    filteredCities: any;
    isLoading = false;
    errorMsg: string;

    interval;

    constructor(private _locationService: LocationService) {}
    ngOnInit(): void {
        let inputControl = false;
        if (this.control) {
            inputControl = true;
        }

        if (!inputControl) {
            if (this.required) {
                this.control = new FormControl(Validators.required);
            } else {
                this.control = new FormControl();
            }

            if (this.disabled) {
                this.control.disable();
            }
        }

        this.control.valueChanges
            .pipe(
                debounceTime(200),
                tap(() => {
                    this.errorMsg = '';
                    this.filteredCities = [];
                    this.isLoading = true;
                }),
                switchMap(value =>
                    this._locationService.getCountries(value, this.allowedCountries).pipe(
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                )
            )
            .subscribe((data: Country[]) => {
                this.errorMsg = '';
                this.filteredCities = data;
            });

        if (!inputControl) {
            this.control.setValue(this.defaultValue);
        }

        if (this.allowedCountries && this.allowedCountries.length === 1) {
            const country = new Country();
            country.name = this.allowedCountriesText[0];
            country.countryId = this.allowedCountries[0];
            country.displayName = this.allowedCountriesText[0];
            this.control.setValue(country);
            this.control.disable();
        }
    }

    displayFn(country?: Country): string | undefined {
        return country ? country.name : undefined;
    }
}
