import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { OrderInfo, OrderView } from '../order-view.model';
import { fuseAnimations } from '@fuse/animations';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { CashOnDelivery } from '../../new-order/order.model';

@Component({
    selector: 'order-details-view',
    templateUrl: './details-view.component.html',
    styleUrls: ['./details-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderDetailsViewComponent implements OnInit {
    @Input() orderView: any;
    ServiceDirection = ServiceDirection;
    shipmentIcon = '';
    serviceTypeId: MyNetServiceType;
    constructor() {}

    ngOnInit(): void {
        switch (this.orderView.orderInfo.serviceDirection) {
            case ServiceDirection.Domestic:
                this.shipmentIcon = 'local_shipping';
                this.serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                this.shipmentIcon = 'flight_land';
                this.serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                this.shipmentIcon = 'flight_takeoff';
                this.serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                break;
        }
    }
}
