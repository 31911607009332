import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { UserService, ChangePhoneNumberStatus } from 'app/_shared/user.services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderEvent } from './order-return.model';
import { OrdersOrderListService } from '../order-list.service';
import { debounceTime, takeUntil } from 'rxjs/internal/operators';
import { ServiceType, CurrencyType } from 'app/_enums/ServiceType.enum';
import { Routes } from 'app/Routes';
import { Router } from '@angular/router';
import { CashOnDelivery } from '../../new-order/order.model';

@Component({
    selector: 'order-return-dialog',
    templateUrl: './order-return-dialog.component.html',
    styleUrls: ['./order-return-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderReturnDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    // returnOrderForm: FormGroup;
    @Input('ReturnOrdersType')

    order: any;
    returnOrdersType: string;
    protected _unsubscribeAll = new Subject<void>();

    a: any
    // Workaround for angular component issue #13870
    disableAnimation = true;
    minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    form: FormGroup;
    cashEnabled: boolean = false;
    retourTypes: any;
    currencyTypes: any;

    orderCod: CashOnDelivery[] = [];

    constructor(private _router: Router, private _formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<OrderReturnDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.returnOrdersType = _data.ReturnOrdersType;
    }
    returnOrderForm = this._formBuilder.group({
        retourTypeId: ['', [Validators.required]],
        collectionDate: [this.minDate, [Validators.required]],
        specialInstructions: ['', null]//,
        // codAmount: ['', null],
        // currencyId: [CurrencyType.LBP, null]
    })
    ngOnInit(): void {
        if (this.returnOrdersType == "1") {
            this.retourTypes = [
                { name: "Cash", value: 4 }];
        }
        else if (this.returnOrdersType == "4")
            this.retourTypes = [
                { name: "Docs/Parcels", value: 1 }]
        else {
            this.retourTypes = [
                { name: "Docs/Parcels", value: 1 },
                { name: "Cash", value: 4 }];
        }

        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);

        // this.currencyTypes = this.currencyTypes.slice(2, 4);
        this.subscribeRetourTypeChanged();

    }
    enumSelector(definition) {
        return Object.keys(definition)
            .map(key => ({ value: definition[key], title: key }));
    }
    get formValue(): any {
        return this.returnOrderForm.value;
    }
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    private subscribeRetourTypeChanged(): void {
        this.returnOrderForm
            .get('retourTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }
    confirm(): void {
        if (this.returnOrderForm.invalid)
            return;
        else
            this.matDialogRef.close(['new', this.returnOrderForm.value,]);

        // if (this.returnOrderForm.get('orderCod').value.length == 0)
        //     this.returnOrderForm.get('orderCod').setValue(null);
        // this.returnOrderForm.updateValueAndValidity();
        // if (this.returnOrderForm.get('orderCod').value.length == 0)
        //     return;
        // this.matDialogRef.close(['new', this.returnOrderForm.value,]);

    }
    validate(): void {

    }
    onChange(event) {
        if (event.value == 4) // Cash
        {
            this.cashEnabled = true;

            this.returnOrderForm.addControl('orderCod', new FormControl('', [Validators.required]));

            // this.returnOrderForm.addControl('currencyId', new FormControl(CurrencyType.LBP, [Validators.required]));
            // this.returnOrderForm.addControl('codAmount', new FormControl('', [Validators.required]));

        }
        else {
            this.returnOrderForm.removeControl('orderCod');

            // this.returnOrderForm.removeControl('currencyId');
            // this.returnOrderForm.removeControl('codAmount');
            this.cashEnabled = false;
        }
    }
    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }
    codAdded(data: any): void {
        if (data == null) {
            this.returnOrderForm.get('orderCod').setErrors({ required: true });;
            return;
        }
        this.orderCod = [];
        data.forEach(element => {
            // if (element.CodAmount > 0)
            this.orderCod.push(element);
        });
        this.returnOrderForm.get('orderCod').setValue(this.orderCod);
        if (data.length > 0)
            this.returnOrderForm.get('orderCod').setErrors(null);
    }
}
