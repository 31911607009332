<div [formGroup]="cod">
    <div >
        <div class="col-xs-12 col-md-3">
            <div fxFlex fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>COD Amount</mat-label>
                    <input matInput formControlName="codAmount" name="mynet-contact-codAmount"
                        (keypress)="numberOnly($event)" type="number" min="1" required />
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Currency</mat-label>
                    <mat-select formControlName="codCurrencyId"  name="codCurrencyId" (selectionChange)="changeCurr($event.value)">
                        <mat-option *ngFor="let currency of currencyTypes" [disabled]="currency.disabled"
                            [value]="currency.value" required>
                            {{currency.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
        <div>
            <button *ngIf="!this.cod.disabled" mat-raised-button color="primary" class="mb-16" [disabled]="cod.invalid"
                (click)="addCod()">
                Save
            </button>
            <button *ngIf="this.cod.disabled" mat-raised-button color="primary" class="mb-16" (click)="editCod()">
                Edit
            </button>
            <button style="margin:5px;" mat-raised-button color="warn" class="mb-16" (click)="removeCod(cod)">
                Remove
            </button>
        </div>
        <div *ngIf="this.selectedCurr == 2" style="color:red; font-style: italic;">
            Kindly inform your customers of the following:<br>
            When paying in USD, the customer needs to make sure to have the exact amount ready to be paid to our driver, as the latter doesn’t have change at hand.
            If the amount to be paid has cents in it, we will round it up to a full figure.
        </div>
    </div>
</div>