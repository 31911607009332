import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { LocationPinned } from '../user.services';
import { LocationService } from '../location-service.service';
import { City } from 'app/_models/city.model';
// import { google } from '@agm/core/services/google-maps-types';
declare var google: any;
@Component({
    selector: 'google-location',
    templateUrl: './google-location.component.html',
    styleUrls: ['./google-location.component.scss'],
})

export class GoogleLocationComponent implements OnInit {
    constructor(private _locationService: LocationService) { }
    @Input() latitude: number;
    @Input() longitude: number;
    @Output() location: EventEmitter<any> = new EventEmitter();
    zoomLevel = 0;
    retrievingLocation: boolean = false;
    // mapType = 'satellite'; 'roadmap' | 'hybrid' | 'satellite' | 'terrain'
    mapType = 'hybrid';
    markers: Marker[];
    locationsPinned: LocationPinned[];
    @ViewChild('agmMap', { static: false }) agmMap: any;
    ngOnInit(): void {
        this.markers = [];
        if (this.latitude != 0 && this.longitude != 0) {
            this.markers.push({
                lat: this.latitude,
                lng: this.longitude,
                draggable: true,
                visible: true,
                opacity: 0.4
            });
            this.zoomLevel = 17;
        }
        else {
            this.latitude = 33.8547;
            this.longitude = 35.8623;
            this.zoomLevel = 7;
        }
    }
    getGeoLocation(lat: number, lng: number) {
        this.locationsPinned = [];
        if (navigator.geolocation) {
            let geocoder = new google.maps.Geocoder();
            let latlng = new google.maps.LatLng(lat, lng);
            let request = {
                latLng: latlng
            };
            geocoder.geocode(request, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    this.retrievingLocation = false;
                    var i: number = 0
                    for (i = 0; i <= results.length - 1; i++) {
                        var address = results[i];
                        var cityName = address.address_components.find(x => x.types[0] == 'administrative_area_level_4');
                        if (cityName !== undefined)
                            break;
                        cityName = address.address_components.find(x => x.types[0] == 'administrative_area_level_3');
                        if (cityName !== undefined)
                            break;
                        var cityName = address.address_components.find(x => x.types[0] == 'neighborhood');
                        if (cityName !== undefined)
                            break;
                        if (cityName2 === undefined)
                            var cityName2 = address.address_components.find(x => x.types[0] == 'administrative_area_level_2');
                        if (cityName3 === undefined)
                            var cityName3 = address.address_components.find(x => x.types[0] == 'administrative_area_level_1');
                    }
                    if (cityName !== undefined)
                        cityName = cityName.long_name;
                    else
                        cityName = ' ';
                    if (cityName2 !== undefined)
                        cityName2 = cityName2.long_name;
                    else
                        cityName2 = ' ';
                    if (cityName3 !== undefined)
                        cityName3 = cityName3.long_name;
                    else
                        cityName3 = ' ';
                    let result = results[0];
                    if (result != null) {
                        var street = result.formatted_address;
                        var countryName = result.address_components.find(x => x.types[0] == 'country').long_name;
                        if (street === undefined)
                            street = '';
                        var cityName3 = address.address_components.find(x => x.types[0] == 'administrative_area_level_1');
                        if (cityName3 !== undefined)
                            cityName3 = cityName3.long_name;
                        else
                            cityName3 = ' ';
                        this._locationService.getCitiesForLocation(cityName, cityName2, cityName3, countryName)
                            .subscribe((data: City[]) => {
                                this.retrievingLocation = false;
                                // if (data) {
                                var matchedCity = data[0];
                                if (matchedCity == undefined) {
                                    matchedCity = new City();
                                    matchedCity.cityId = 0;
                                    matchedCity.countryId = 0;
                                    matchedCity.countryName = '';
                                    matchedCity.cityName = '';
                                }
                                this.locationsPinned.push({
                                    lat: lat,
                                    lng: lng,
                                    countryName: matchedCity.countryName,
                                    countryId: matchedCity.countryId,
                                    cityId: matchedCity.cityId,
                                    cityName: matchedCity.cityName,
                                    streetName: street
                                });
                                this.location.emit(this.locationsPinned[0]);
                                // }
                            });
                    } else {
                        alert("No address available!");
                    }
                }
            });
        }
    }
    markerDragEnd(m, $event) {
        m.latitude = $event.coords.lat;
        m.longitude = $event.coords.lng;
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;

        this.getGeoLocation($event.coords.lat, $event.coords.lng);

    }
    mapClicked($event) {

        this.retrievingLocation = true;
        this.markers = [];
        this.markers.push({
            lat: $event.coords.lat,
            lng: $event.coords.lng,
            draggable: true,
            visible: true,
            opacity: 0.4
        });

        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.zoomLevel = this.zoomLevel + 1;
        this.zoomLevel = this.zoomLevel - 1;
        this.getGeoLocation($event.coords.lat, $event.coords.lng);
    }
    clickedMarker(label: string, index: number) {
        this.markers.splice(index);
        this.zoomLevel = this.zoomLevel - 1;
    }
    onZoomChange($event) {
        this.zoomLevel = $event;
    }
}
interface Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
    visible: boolean;
    opacity: number;
}
