export class Contact {
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    PhoneCountryCode: string;
    companyName: string;
    countryCode:string;
    tags: string[];
    addresses: Address[];

    constructor(contact?) {
        contact = contact || {};
        this.contactId = contact.contactId || 0;
        this.firstName = contact.firstName || '';
        this.lastName = contact.lastName || '';
        this.email = contact.email || '';
        this.phoneNumber = contact.phoneNumber || '';
        this.PhoneCountryCode = contact.PhoneCountryCode || '';
        this.companyName = contact.companyName || '';
        this.countryCode = contact.countryCode || 'LB';
        this.tags = contact.tags || [];
        this.addresses = contact.addresses || [];
    }
}

export class Address {
    addressId: number;
    countryId: number;
    countryName: string;
    cityId: number;
    cityName: string;
    streetAddress1: string;
    streetAddress2: string;
    region: string;
    zipCode: string;
    display: string;

    street: string;
    building: string;
    floor: string;
    landmark: string;

    isNetPoint = false;

    constructor(address?) {
        address = address || {};
        this.addressId = address.addressId || 0;
        this.countryId = address.countryId || 0;
        this.cityId = address.cityId || 0;

        this.countryName = address.countryName || '';
        this.cityName = address.cityName || '';
        this.streetAddress1 = address.streetAddress1 || '';
        this.streetAddress2 = address.streetAddress2 || '';
        this.region = address.region || '';
        this.zipCode = address.zipCode || '';
        this.display = address.display || '';

        this.street = address.street || '';
        this.building = address.building || '';
        this.floor = address.floor || '';
        this.landmark = address.landmark || '';
    }
}
