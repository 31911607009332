import { Router } from '@angular/router';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Routes } from 'app/Routes';
import * as i0 from "@angular/core";
import * as i1 from "../authentication/authentication.service";
import * as i2 from "@angular/router";
export class AnonymousGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(next) {
        if (!this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate([Routes.homepage]);
        return false;
    }
}
AnonymousGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnonymousGuard_Factory() { return new AnonymousGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AnonymousGuard, providedIn: "root" });
