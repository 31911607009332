import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { QuoteData } from './quote-data.model';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, Form } from '@angular/forms';
import { OrderDetail, BillingDetails, Order, ProformaDetails, Piece, CashOnDelivery } from './order.model';
import { OrderService } from '../order.service';
import { Routes } from 'app/Routes';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Countries } from 'app/main/quotes/international/international.model';
import { Location } from '@angular/common';
import { OrdersOrderDetailsComponent } from './order-details/order-details.component';
import { QuoteService } from 'app/main/quotes/quote.service';
import { Console } from 'console';
import { A } from '@angular/cdk/keycodes';

@Component({
    selector: 'orders-new-order',
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersNewOrderComponent implements OnInit, OnDestroy {
    protected _unsubscribeAll = new Subject<void>();
    ServiceDirection = ServiceDirection;
    data: QuoteData;
    proforma: ProformaDetails[];
    pieces: Piece[];
    mode: string;
    step = PanelOption.MyInfo;
    PanelOption = PanelOption;
    orderId: number;
    hasSubsidiaryId = true;
    orderForm: FormGroup;
    orderDetailGroup: FormGroup;
    billingDetailsGroup: FormGroup;
    public imagePath;
    idImgURL: any;
    public message: string;
    imageUploadLoading = false;
    requiredUploadIdImage = false;
    hasUserIDImage: boolean;
    firstTime: boolean = true;
    domesticCountryId: number = 158;
    myInfo: any;
    cods: CashOnDelivery[];


    @ViewChild('orderDetailComponent', { static: false }) orderDetailComponent: OrdersOrderDetailsComponent;
    // @ViewChild('cashOrderDetailComponent', { static: false }) cashOrderDetailComponent: OrdersCashOrderComponent;

    newQuote = new FormControl(true);

    constructor(
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _orderService: OrderService,
        private _quoteService: QuoteService,
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthenticationService,
        private _location: Location
    ) {
        const navigation = this._router.getCurrentNavigation();

        const state = navigation.extras.state as QuoteData;

        this.data = state || new QuoteData();
        this.proforma = [];
        // this.pieces = [];

        this.orderDetailGroup = this._formBuilder.group(new OrderDetail());
        this.orderDetailGroup.setControl('quote', this._formBuilder.group(this.data));

        this.orderDetailGroup.setControl('quoteId', this._formBuilder.control(this.data.quoteId));
        var quoteForm = this.orderDetailGroup.get('quote') as FormGroup;
        quoteForm.setControl('pieces', this._formBuilder.array([]));
        




       


        this._activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id === 'new') {
                this.orderId = 0;
                quoteForm.setControl('cods', this._formBuilder.array([]));
                // this.mode = 'new'
            }
            // else if (id === 'cash') {
            //     this.orderId = 0;
            //     this.isCashOrder = true;
            //     this.newQuote.setValue(false);
            // }
            else {
                this.orderId = +id;
                this.mode = 'edit';
            }
        });


        // const t = new Promise(resolve => {
        //     this.loadScript();
        //     resolve(true);
        // });


    }

    get orderDetail(): OrderDetail {

        const order = new OrderDetail(this.orderDetailGroup.getRawValue());
        order.shipperName = this.orderDetailComponent.contacts.find(c => c.contactId === order.shipperId).fullName;
        order.receiverName = this.orderDetailComponent.contacts.find(c => c.contactId === order.receiverId).fullName;
        order.shipperAddress = this.orderDetailComponent.toLongDisplayString(
            this.orderDetailComponent.shipperAddresses.find(c => c.addressId === order.shipperAddressId)
        );
        order.receiverAddress = this.orderDetailComponent.toLongDisplayString(
            this.orderDetailComponent.receiverAddresses.find(c => c.addressId === order.receiverAddressId)
        );
        order.orderId = this.orderId;

        // order.proforma = this.orderDetailComponent.contacts.find(c => c.contactId === order.receiverId).fullName;


        return order;
    }

    ngOnInit(): void {

        // this._authService.hasSubsidiaryId().subscribe(result => {
        //     this.hasSubsidiaryId = result.status;
        //     this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
        //         this.billingDetailsGroup = this._formBuilder.group(info);
        //         this.firstTime = !this.billingDetailsGroup.get('confirmedPhoneNumber').value;
        //         if (this.firstTime) {
        //             this.step = PanelOption.MyInfo;
        //         }
        //     });
        //     if (this.hasSubsidiaryId) {
        //         this.step = PanelOption.OrderDetails;
        //     }
        // });
        this.step = PanelOption.OrderDetails;

        // this._authService.IsWalkIn().subscribe(result => {
        //     this.isWalkIn = result.status;
        // });
        const quote = this.orderDetailGroup.get('quote');
        quote.get('type').valueChanges.subscribe((type: ServiceDirection) => {
            if (type === ServiceDirection.Export) {
                this.requiredUploadIdImage = true;
            }
        });
        // this._authService.hasUserIDImage().subscribe(result => {
        //     const hasUserIDImage = result.status;
        //     if (!hasUserIDImage) {
        //         const quote = this.orderDetailGroup.get('quote');
        //         quote.get('type').valueChanges.subscribe((type: ServiceDirection) => {
        //             if (type === ServiceDirection.Export) {
        //                 this.requiredUploadIdImage = true;
        //             }
        //         });
        //     }
        // });

        if (this.mode === 'edit') {
            this.initEditMode(this._orderService.selectedOrder);
        }
        this._orderService.onOrderSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe((order: Order) => {
            this.initEditMode(order);
        });

        this.data.type = this.getDirection(this.data.fromCountryId, this.data.toCountryId);
        if (this.data && this.mode === 'new') {
            const orderDetail = new OrderDetail({ quoteId: this.data.quoteId, quote: this.data });
            this.orderDetailGroup = this._formBuilder.group(orderDetail);
            this.orderDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        }

        this._orderService.onOrderAdded.subscribe((orderId: number) => {
            if (this.orderId === 0) {
                this.orderDetailGroup.get('orderId').setValue(orderId);
                this.orderId = orderId;
            }
        });
        this.domesticCountryId = parseInt(localStorage.getItem('countryId').toString());
        // this.loadIdPhoto();
    }

    getDirection(fromCountryId: number, toCountryId: number): ServiceDirection {
        if (!fromCountryId || !toCountryId) {
            return undefined;
        }
        if (fromCountryId === this.domesticCountryId && toCountryId === this.domesticCountryId) {
            // domestic
            return ServiceDirection.Domestic;
        } else if (fromCountryId === this.domesticCountryId) {
            // Export
            return ServiceDirection.Export;
        } else if (toCountryId === this.domesticCountryId) {
            // Import
            return ServiceDirection.Import;
        } else {
            // Not Supported
            return undefined;
        }
    }

    initEditMode(order: Order): void {
        if (order === null) {
            return;
        }
        if (this.orderId === 0) {
            this._router.navigateByUrl(Routes.orders);
        }
        order.detail.quote.type = this.getDirection(order.detail.quote.fromCountryId, order.detail.quote.toCountryId);

        // fill up the quote data with the order details that is being sent when we edit an existing order.
        this.data = order.detail.quote;
        
        this.orderDetailGroup.patchValue(order.detail);
        var prof = order.detail.proforma as ProformaDetails[];
        this.orderDetailGroup.get('proforma').patchValue(prof);
        var pieces = order.detail.quote.pieces as Piece[];
        this.orderDetailGroup.get('quote').get('pieces').patchValue(pieces);
        var quoteForm = this.orderDetailGroup.get('quote') as FormGroup;
        quoteForm.setControl('pieces', this._formBuilder.array([], Validators.required));
        // this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));
        const piecesFormArray = quoteForm.get('pieces') as FormArray;
        // this.group.get('quote').get('pieces').value.forEach(piece => {
        //     piecesFormArray.push(this._formBuilder.group(piece));
        // });
        if (this.data.cods.length === 0) {
            quoteForm.setControl('cods', this._formBuilder.array([]));
        }
        var pieces = order.detail.quote.pieces as Piece[];
        if (pieces.length > 0) {
            pieces.forEach(piece => {
                piecesFormArray.push(this._formBuilder.group(piece));
            });
        }
        else {
            piecesFormArray.push(this._formBuilder.group(new Piece()));
        }
    }

    ngOnDestroy(): void {
        this._orderService.onOrderSelected.complete();
        this._orderService.onOrderAdded.complete();
        this._orderService.selectedOrder = null;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setStep(option: PanelOption): void {
        this.step = option;
    }

    nextStep(option: PanelOption): void {
        this.step = option;
    }

    prevStep(option: PanelOption): void {
        this.step = option;
    }

    goBackFromPayment(): void {
        const quote = this.orderDetailGroup.value.quote;
        const direction = this.getDirection(quote.fromCountryId, quote.toCountryId);

        if (!this.hasSubsidiaryId || this.firstTime) {
            this.prevStep(PanelOption.MyInfo);
        } else if (this.hasSubsidiaryId && direction === ServiceDirection.Export && this.requiredUploadIdImage) {
            this.prevStep(PanelOption.UploadIDPhoto);
        } else {
            this.prevStep(PanelOption.OrderDetails);
        }
    }

    saveOrderDetails(): void {
        const data = this.orderDetailGroup.getRawValue() as OrderDetail;
        data.orderId = this.orderId;
        if (this.orderId === 0) {
            this._orderService.addOrderDetails(data).then((result: BillingDetails) => {
                this.afterSaveOrder(result);
            });
        } else if (this.orderId > 0) {
            this._orderService.updateOrderDetails(data).then((result: BillingDetails) => {
                this.afterSaveOrder(result);
            });
        }
    }
    // saveOrderSurcharges(orderId: number) {
    //     var orderSurchargesToSave = this.orderDetailComponent.orderSurcharges;
    //     if (orderSurchargesToSave.length != 0) {
    //         orderSurchargesToSave.forEach(element => {
    //             element.orderId = orderId;
    //         });
    //         if (orderSurchargesToSave)
    //             this._quoteService.saveSurcharges(orderSurchargesToSave).subscribe(result => {
    //             });
    //     }

    // }

    // saveCodAmounts(quoteId) {
    //     var orderCod = this.orderDetailComponent.orderCod;
    //     if (orderCod.length > 0) {
    //         orderCod = orderCod.map(item => {
    //             item.QuoteId = quoteId
    //             return item;
    //         });
    //         this._quoteService.saveCodAmount(orderCod).subscribe(result => {
    //         });
    //     }
    // }
    confirmOrderDetails(event?): void {

        const data = this.orderDetailGroup.getRawValue() as OrderDetail;
        if (data.quote.shippingTypeId.toString().trim() == "")
            data.quote.shippingTypeId = 0;

        data.orderId = this.orderId;

        

        if (this.orderId === 0) {
            this._orderService.addOrderDetails(data).then((result: BillingDetails) => {
                this.orderId = result.orderId;
                this.orderDetailComponent.updateQuoteAndOrderId(result.orderId, result.quoteId);

                this.orderDetailComponent.saveOrderSurcharges(result.orderId);
                this.orderDetailComponent.saveCodAmounts(result.quoteId);
                this.orderDetailComponent.saveOrderPieces(result.quoteId);
                this.newQuote.setValue(false);
            });
        } else if (this.orderId > 0) {
            this._orderService.updateOrderDetails(data).then((result: BillingDetails) => {
                localStorage.setItem('orderId', result.orderId.toString());
                this.orderId = result.orderId;
                const quoteForm = this.orderDetailComponent.group.get('quote');
                // quoteForm.get('quotePrice').setValue(quoteForm.value.quotePrice,{ emitEvent: false });
                // this.orderDetailComponent.newQuote.setValue(false ,{ emitEvent: false });

                this.orderDetailComponent.updateQuotePrice(quoteForm.get('quotePrice').value, quoteForm.get('quoteCurrency').value);
                this.orderDetailComponent.saveOrderSurcharges(result.orderId);
                this.orderDetailComponent.saveCodAmounts(result.quoteId);
                this.orderDetailComponent.saveOrderPieces(result.quoteId);
            });
        }
    }
    private afterSaveConfirmOrder(result: BillingDetails): void {
        const quoteForm = this.orderDetailGroup.get('quote');

        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });

        this.orderDetailComponent.orderDetail.orderId = result.orderId;
        this.orderId = result.orderId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        } else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/orders/' + this.orderId);

    }
    private afterSaveOrder(result: BillingDetails): void {
        const quoteForm = this.orderDetailGroup.get('quote');
        this.orderDetail.orderId = result.orderId;
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderId = result.orderId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        } else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/orders/' + this.orderId);
    }

    saveBillingDetails(): void {
        this._orderService.addBillingDetails(this.billingDetailsGroup.value as BillingDetails).then(user => {
            this._authService.onUserInfoUpdated.next(user);
            this.nextStep(PanelOption.OrderDetails);
            // update list of contacts to display the new Home contact (if created)
            this.orderDetailComponent.getAllContacts(false);
        });
    }

    uploadIDPhoto(files): void {
        this.message = '';
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.idImgURL = null;
            return;
        }
        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = _event => {
            this.idImgURL = reader.result;
        };
        this.uploadIdPhotoAndContinue();
    }
    uploadIdPhotoAndContinue(): void {
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(
            result => {
                this.imageUploadLoading = false;
                this.nextStep(PanelOption.Payment);
            },
            error => {
                this.message = 'Error while uploading your image ID to the server.';
                this.imageUploadLoading = false;
            }
        );
    }
    ContinueAfterPhoto(): void {
        this.nextStep(PanelOption.Payment);
    }
    loadIdPhoto(): void {
        this._authService.downloadUserIDPhoto().subscribe(
            result => {
                if (result) {
                    var myBlob = result as Blob;
                    if (myBlob.size == 0)
                        return;
                    var myFile = this.blobToFile(myBlob, "my-image.png");
                    const reader = new FileReader();
                    reader.readAsDataURL(myFile);
                    reader.onload = _event => {
                        this.idImgURL = reader.result;
                    };
                }
            },
            error => {
                this.message = 'Error while downloading your image from the server.';
            }
        );
    }
    public blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }
    goBackFromUploadId(): void {
        this.prevStep(PanelOption.OrderDetails);
    }

    loadScript(): void {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            const node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}

export enum PanelOption {
    OrderDetails,
    MyInfo,
    UploadIDPhoto,
    Payment
}
