import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PackageService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
    }
    getPackages() {
        return this._httpClient.get(environment.apiUrl + 'v1/Packages');
    }
}
PackageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PackageService_Factory() { return new PackageService(i0.ɵɵinject(i1.HttpClient)); }, token: PackageService, providedIn: "root" });
