import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
export class PhoneValidator {
    // Inspired on: https://github.com/yuyang041060120/ng2-validation/blob/master/src/equal-to/validator.ts
    static validCountryMobile = (countryControl: AbstractControl, validControl?: AbstractControl): ValidatorFn => {
        return (phoneControl: AbstractControl): { [key: string]: boolean } => {
            if (phoneControl.value !== '') {
                try {
                    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                    var phoneNumber = '' + phoneControl.value + '';
                    const PNF = libphonenumber.PhoneNumberFormat;
                    const initialPrefix = phoneNumber[0] + phoneNumber[1]

                    var byPass = false;
                    phoneNumber = phoneNumber.toString().replace(/ /g, "");


                   
                    if (phoneNumber.indexOf((countryControl.value.iso.countryPhoneCode)) == -1)
                        phoneNumber = countryControl.value.iso.countryPhoneCode.concat(phoneNumber.toString());
                    const region = countryControl.value.iso.countryReference;





                    if (region == 'LB') {
                        if (phoneNumber.toString().startsWith('78') || phoneNumber.toString().startsWith('79')
                            || phoneNumber.toString().startsWith('80') || phoneNumber.toString().startsWith('81')) {
                                byPass = true;
                                // phoneNumber = phoneNumber.toString().replace('79', '03');
                                phoneNumber = "03" + phoneNumber.slice(2);
                        }



                    }
                    var pNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region);


                    // var isValidNumber = phoneUtil.isValidNumberForRegion(pNumber, region.iso);
                    var isPossibleNumber = phoneUtil.isPossibleNumber(pNumber, region);


                    const isMobile = phoneUtil.getNumberType(pNumber);




                    if (!isPossibleNumber && region == 'CA')
                        isPossibleNumber = phoneUtil.isValidNumberForRegion(pNumber, 'US');



                    if (isPossibleNumber) {

                        phoneControl.clearValidators();
                        var formattedNumber = phoneUtil.format(pNumber, PNF.NATIONAL)
                        if (byPass)
                            formattedNumber = initialPrefix + formattedNumber.slice(2);
                        phoneControl.setValue(formattedNumber);
                        if (validControl) {
                            validControl.setValue(true);
                        }
                        phoneControl.setValidators(Validators.required);
                        phoneControl.setValidators(PhoneValidator.validCountryMobile(countryControl, validControl));

                        return undefined;
                    }
                    if (validControl) {
                        validControl.setValue(false);
                    }
                } catch (e) {
                    if (validControl) {
                        validControl.setValue(false);
                    }
                    return {
                        validCountryMobile: true
                    };
                }
                if (validControl) {
                    validControl.setValue(false);
                }
                return {
                    validCountryMobile: true
                };
            } else {
                if (validControl) {
                    validControl.setValue(false);
                }
                return undefined;
            }
        };
    }
    static validCountryMobile2 = (countryControl: AbstractControl, validControl?: AbstractControl): ValidatorFn => {
        return (phoneControl: AbstractControl): { [key: string]: boolean } => {
            if (phoneControl.value !== '') {
                try {
                    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                    const phoneNumber = '' + phoneControl.value + '';
                    const region = countryControl.value;

                    const pNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region.iso);

                    const isValidNumber = phoneUtil.isValidNumber(pNumber);
                    const isMobile = phoneUtil.getNumberType(pNumber);
                    // && isMobile !== 0
                    if (isValidNumber && isMobile !== -1) {
                        const PNF = libphonenumber.PhoneNumberFormat;

                        phoneControl.clearValidators();
                        phoneControl.setValue(phoneUtil.format(pNumber, PNF.E164));
                        if (validControl) {
                            validControl.setValue(true);
                        }
                        phoneControl.setValidators(Validators.required);
                        phoneControl.setValidators(PhoneValidator.validCountryMobile(countryControl, validControl));

                        return undefined;
                    }
                    if (validControl) {
                        validControl.setValue(false);
                    }
                } catch (e) {
                    if (validControl) {
                        validControl.setValue(false);
                    }
                    return {
                        validCountryMobile: true
                    };
                }
                if (validControl) {
                    validControl.setValue(false);
                }
                return {
                    validCountryMobile: true
                };
            } else {
                if (validControl) {
                    validControl.setValue(false);
                }
                return undefined;
            }
        };
    }
    static ValidPhone(phonenumber, countryCode): any {
        try {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            const PNF = libphonenumber.PhoneNumberFormat;
            // const phoneNumber = '' + phonenumber + '';
            const pNumber = phoneUtil.parseAndKeepRawInput(phonenumber, countryCode);
            var isValidNumber = phoneUtil.isValidNumber(pNumber);
            if (pNumber.values_[5].startsWith('78') && countryCode === 'LB') {
                isValidNumber = true;
            }
            if (isValidNumber)
                return phoneUtil.format(pNumber, PNF.E164)
            else
                return '';
        } catch (error) {
            return '';
        }
    }

    static validCountryPhone = (countryControl: AbstractControl, validControl?: AbstractControl): ValidatorFn => {
        return (phoneControl: AbstractControl): { [key: string]: boolean } => {
            if (phoneControl.value !== '') {
                try {
                    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                    const phoneNumber = '' + phoneControl.value + '';
                    const region = countryControl.value;

                    const pNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region.iso);
                    const isValidNumber = phoneUtil.isValidNumber(pNumber);

                    if (isValidNumber) {
                        const PNF = libphonenumber.PhoneNumberFormat;
                        phoneControl.clearValidators();
                        phoneControl.setValue(phoneUtil.format(pNumber, PNF.E164));
                        if (validControl) {
                            phoneControl.setValue(true);
                        }
                        phoneControl.setValidators(Validators.required);
                        phoneControl.setValidators(PhoneValidator.validCountryPhone(countryControl, validControl));

                        return undefined;
                    }
                    if (validControl) {
                        validControl.setValue(false);
                    }
                } catch (e) {
                    if (validControl) {
                        validControl.setValue(false);
                    }
                    return {
                        validCountryPhone: true
                    };
                }
                if (validControl) {
                    validControl.setValue(false);
                }
                return {
                    validCountryPhone: true
                };
            } else {
                if (validControl) {
                    validControl.setValue(false);
                }
                return undefined;
            }
        };
    }
}
