<!-- 4 sections: Shipper, Receiver, Order Details, Payment -->
<div class="page-layout simple left-sidebar inner-sidebar">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start center">

        <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md (click)="toggleSidebar('left-sidebar')">
            <mat-icon>menu</mat-icon>
        </button>

        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                store_mall_directory
            </mat-icon>
            <span class="h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                New Shipping Order
            </span>
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content h-100-p">

        <!-- SIDEBAR -->
        <fuse-sidebar class="sidebar" name="left-sidebar" position="left" lockedOpen="gt-md">

            <!-- SIDEBAR CONTENT -->
            <div class="content p-24" fusePerfectScrollbar>
                <mat-list>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 0 ? 'text-bold' : ''">
                        <span>Shipping From</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 1 ? 'text-bold' : ''">
                        <span>Shipping To</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 2 ? 'text-bold' : ''">
                        <span>Packages</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 3 ? 'text-bold' : ''">
                        <span>Pickup</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 4 ? 'text-bold' : ''">
                        <span>Details</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 5 ? 'text-bold' : ''">
                        <span>Review</span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item [ngClass]="orderForm.value.activeListItem === 6 ? 'text-bold' : ''">
                        <span>Payment</span>
                    </mat-list-item>

                </mat-list>
            </div>
            <!-- / SIDEBAR CONTENT -->

        </fuse-sidebar>
        <!-- / SIDEBAR -->

        <!-- CENTER -->
        <div class="center p-24 h-100-p" fusePerfectScrollbar>

            <!-- CONTENT -->
            <div class="content p-24 h-100-p mb-24">
                <form [formGroup]="orderForm">
                    <div [ngStyle]="{display: orderForm.value.activeListItem === 0 ? 'block' : 'none'}">
                        <h1 class="h1">Where are you shipping from?</h1>
                        <orders-anonymous-contact [form]="orderForm" [group]="orderForm.get('shipper')"
                            [allowedCountries]="[lebanon]" [allowedCountriesText]="['Lebanon']" [direction]="'from'">
                        </orders-anonymous-contact>
                    </div>

                    <div [ngStyle]="{display: orderForm.value.activeListItem === 1 ? 'block' : 'none'}">
                        <h1 class="h1">Where is your shipment going?</h1>
                        <orders-anonymous-contact [form]="orderForm" [group]="orderForm.get('receiver')">
                        </orders-anonymous-contact>
                    </div>

                    <div [ngStyle]="{display: orderForm.value.activeListItem === 2 ? 'block' : 'none'}">
                        <h1 class="h1">What kind of packages are you using?</h1>
                        <orders-anonymous-shipment-packages [form]="orderForm"
                            [group]="orderForm.get('packageDetails')">
                        </orders-anonymous-shipment-packages>
                    </div>

                    <div [ngStyle]="{display: orderForm.value.activeListItem === 3 ? 'block' : 'none'}">
                        <h1 class="h1">Schedule a Pickup</h1>
                        <orders-anonymous-shipment-pickup [form]="orderForm" [group]="orderForm.get('pickup')">
                        </orders-anonymous-shipment-pickup>
                    </div>

                    <div [ngStyle]="{display: orderForm.value.activeListItem === 4 ? 'block' : 'none'}">
                        <h1 class="h1">What are you shipping?</h1>
                        <orders-anonymous-shipment-details [form]="orderForm"
                            [group]="orderForm.get('shipmentDetails')"></orders-anonymous-shipment-details>
                    </div>

                    <div [ngStyle]="{display: orderForm.value.activeListItem === 5 ? 'block' : 'none'}">
                        <div class="row">
                            <div class="col-md-8 col-xs-12">
                                <h1 class="h1">
                                    <span>Review your shipment order</span>
                                    <span *ngIf="totalPrice" class="header-price">
                                        Total: $ {{ totalPrice | number:'.2-2' }}</span>
                                </h1>
                            </div>
                        </div>
                        <orders-anonymous-shipment-review [form]="orderForm"></orders-anonymous-shipment-review>
                    </div>

                    <div *ngIf="orderForm.value.activeListItem === 6">
                        <h1 class="h1">Payment using Credit Card</h1>
                        <orders-anonymous-shipment-payment [form]="orderForm"></orders-anonymous-shipment-payment>
                    </div>

                </form>
                <br />
                <div class="fixed-bottom"
                    *ngIf="totalPrice !== undefined && totalPrice !== null">
                    <ng-container *ngIf="loadingPrice">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>
                    <ng-container *ngIf="!loadingPrice">
                        <ng-container *ngIf="orderForm.get('totalPrice').value === 0">
                            Unfortunately we couldn't get you a price! please try again with different input
                        </ng-container>
                        <ng-container *ngIf="orderForm.get('totalPrice').value > 0">
                            Total: $ {{ orderForm.get('totalPrice').value | number:'.2-2' }}
                        </ng-container>
                    </ng-container>
                </div>
                <!-- <pre class="fuse-black-bg fuse-white-fg">{{orderForm.value | json}}</pre> -->

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT -->

</div>