<!-- <div *ngIf="isWalkIn"> -->
<img class="mb-16" style="max-height: 60px;" src="assets/images/payments/powered-by-areeba.png"
    alt="Powered By Areeba" />
<br />
<span class="mt-16">Click on Pay to redirect to Areeba secure payment gateway to complete the payment process</span>
<br />
<button mat-raised-button color="blue" class="mt-16 mb-16" type="button" (click)="pay()">
    Proceed to payment
</button>
<!-- </div> -->



<!-- <div *ngIf="!isWalkIn">
    <button mat-raised-button color="blue" class="mt-16 mb-16" type="button" (click)="pay()">
        Confirm 
    </button>
</div> -->