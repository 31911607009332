import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { FormGroup } from '@angular/forms';
import { Countries } from 'app/main/quotes/international/international.model';
import { DistributionDetail } from '../../distribution.model';

declare var Checkout: any;

@Component({
    selector: 'confirm-distribution',
    templateUrl: './confirm-distribution.component.html',
    styleUrls: ['./confirm-distribution.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDistributionComponent implements OnInit, OnDestroy {
    order: DistributionDetail;
    paymentSessionId: string;
    uniqueId: string;
    loadCheckout: Promise<any>;
    payClicked = false;
    checkoutLoaded = false;
    serviceTypeId: MyNetServiceType;

    receiverCount : number;

    protected _unsubscribeAll = new Subject<void>();

    constructor(
        public matDialogRef: MatDialogRef<ConfirmDistributionComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.order = _data.order;
    }


    ngOnInit(): void {
        this.setServiceTypeId();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    confirm(): void {
        this.matDialogRef.close(['new', this.order]);
        // this.payClicked = true;
        // localStorage.setItem('orderId', this.order.orderId.toString());
        // Checkout.showPaymentPage();
    }

    setServiceTypeId(): void {
        switch (this.getDirection()) {
            case ServiceDirection.Domestic:
                this.serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                this.serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                this.serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                break;
        }
    }

    private getDirection(): ServiceDirection {
        // const fromCountryId = this.order.quote.fromCountryId;
        // const toCountryId = this.order.quote.toCountryId;

        // if (!fromCountryId || !toCountryId) {
        //     return undefined;
        // }

        // if (fromCountryId === Countries.Lebanon && toCountryId === Countries.Lebanon) {
            // domestic
            return ServiceDirection.Domestic;
        // } else if (fromCountryId === Countries.Lebanon) {
        //     // Export
        //     return ServiceDirection.Export;
        // } else if (toCountryId === Countries.Lebanon) {
        //     // Import
        //     return ServiceDirection.Import;
        // } else {
        //     // Not Supported
        //     return undefined;
        // }
    }

    @HostListener('document:payment-error', ['$event'])
    paymentError(event): void {
    }

    @HostListener('document:payment-canceled', ['$event'])
    paymentCanceled(event): void {
    }
}
