import { DataSource } from '@angular/cdk/table';
import { OrdersOrderListService } from './order-list.service';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { OrderListItem } from './order-list-item.model';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { isNullOrUndefined } from 'util';

export class OrdersOrderListDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');
    public pagination: any;

    // public  _fromDate = new Date;
    // public  _toDate = new Date;


    constructor(
        private _ordersOrderListService: OrdersOrderListService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort
    ) {
        super();
        // if (!isNullOrUndefined(this._dateFrom)) {
        //     this._dateFrom.setDate(this._dateFrom.getDate());
        //     this._dateFrom.setHours(5);
        //     this._dateFrom.setMinutes(0);
        //     this._dateFrom.setSeconds(0);
        // }
        // if (!isNullOrUndefined(this._dateTo)) {
        //     this._dateTo.setDate(this._dateTo.getDate());
        // }
        // this._ordersOrderListService.fromDate = this._dateFrom;
        // this._ordersOrderListService.fromDate = this._dateFrom;
        this.filteredData = this._ordersOrderListService.orders;
        this.pagination = this._ordersOrderListService.paginationInfo;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._ordersOrderListService.onOrdersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(
            map(() => {
                let data = this._ordersOrderListService.orders.slice();

               
                data = this.filterData(data);

                this.filteredData = [...data];
                data = this.sortData(data);

                // Grab the page's slice of data
                const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                return data.splice(startIndex, this._matPaginator.pageSize);
            })
        );
    }

    /**
     * Disconnect
     */
    disconnect(): void { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // set fromDate(date: any) {
    //     this._fromDate = date;
    // }
    // get fromDate(): any {
    //     return this._fromDate;
    // }
    // set toDate(date: any) {
    //     this._toDate = date;
    // }
    // get toDate(): any {
    //     return this._toDate;
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        // if (!this.filter) {
        //     return data;
        // }
        // return FuseUtils.filterArrayByStringAndDate(data, this.filter, this._fromDate, this._toDate);
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    // setDateFrilter(fromDate , toDate): any {
    //    this._fromDate = fromDate;
    //    this._toDate = toDate;
    // }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data: OrderListItem[]): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: string | Date;
            let propertyB: string | Date;

            switch (this._matSort.active) {
                case 'direction':
                    [propertyA, propertyB] = [ServiceDirection[a.direction], ServiceDirection[b.direction]];
                    break;
                case 'from':
                    [propertyA, propertyB] = [a.from, b.from];
                    break;
                case 'to':
                    [propertyA, propertyB] = [a.to, b.to];
                    break;
                case 'pickupDate':
                    [propertyA, propertyB] = [a.pickupDate, b.pickupDate];
                    break;
                case 'label':
                    [propertyA, propertyB] = [a.label, b.label];
                    break;
                case 'status':
                    [propertyA, propertyB] = [a.status, b.status];
                    break;
                case 'statusDate':
                    [propertyA, propertyB] = [
                        new Date(Date.parse(a.statusUpdatedDateString)),
                        new Date(Date.parse(b.statusUpdatedDateString))
                    ];
                    break;
            }

            const valueA = propertyA;
            const valueB = propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }
}
