import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DistributionViewContact } from '../distribution-view.model';
// import { DistributionViewContact } from '../distribution-view.model';

@Component({
    selector: 'distribution-contact-view',
    templateUrl: './contact-view.component.html',
    styleUrls: ['./contact-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DistributionContactViewComponent implements OnInit {
    @Input() contactType: string;
    @Input() contact: DistributionViewContact;

    constructor() {}

    ngOnInit(): void {
    }
}
