<div id="contactList" class="page-layout carded fullwidth  inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    contact_phone
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Address Book
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for a contact list">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button (click)="openContactDialog('new')"
                class="add-contact-button fuse-white mt-24 mt-md-0">
                <span>Add New Contact List</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="contactsList-table" #table [dataSource]="dataSource" matSort
                [@animateStagger]="{value:'30'}" fusePerfectScrollbar>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
                    <mat-cell *matCellDef="let contactList">
                        <p class="text-truncate">{{contactList.description}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="contactCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs># of Contacts
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contactList" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{contactList.contactListDetail.length}}</p>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="tags">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Tags</mat-header-cell>
                    <mat-cell *matCellDef="let contactList" fxHide fxShow.gt-xs>
                        <p class="text-truncate">
                            <mat-chip-list class="h-40" style="display: block;">
                                <mat-chip *ngFor="let tag of contactList.tags" style="font-size:12px;">
                                    {{ tag }}
                                </mat-chip>
                            </mat-chip-list>
                        </p>
                    </mat-cell>
                </ng-container> -->

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let contactList">
                        <div fxFlex="row" fxLayoutAlign="end center">

                            <button mat-icon-button tabindex="-1" [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item aria-label="edit"
                                    (click)="editContact(contactList.contactListId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button [disabled]="!contactList.canDelete" mat-menu-item aria-label="remove"
                                    (click)="deleteContact(contactList.contactListId)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Remove</span>
                                </button>
                            </mat-menu>
                        </div>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let contactList; columns: displayedColumns;" class="contactList" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                </mat-row> -->
                <mat-row *matRowDef="let contactList; columns: displayedColumns;" class="contactList" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}" (click)="editContact(contactList.contactListId)">
                </mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="dataSource.pagination.totalItems" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 50]" (page)="pagination($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>