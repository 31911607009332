import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Entity } from 'app/_models/Entity.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { ServiceType } from 'app/_enums/ServiceType.enum';
import { DomesticQuote } from './domestic/domestic.model';
import { CityCache } from 'app/_models/CityCache';
import { InternationalQuote } from './international/international.model';
import { QuoteData } from '../orders/new-order/quote-data.model';
import { NewOrder } from '../orders/anonymous-new-order/new-order.model';
import { CashOnDelivery, OrderSurcharges, Piece } from '../orders/new-order/order.model';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {
    citiesCache: CityCache[] = [];
    countriesCache: Entity<number>[] = [];
    shippingTypes: Entity<number>[];
    shippingTypesChanged = new BehaviorSubject<Entity<number>[]>(null);

    cods: CashOnDelivery[];
    surcharges: OrderSurcharges[];
    pieces: Piece[];
    onCodChanged: BehaviorSubject<CashOnDelivery[]>;
    onPieceChanged: BehaviorSubject<Piece[]>;
    onSurchargeChanged: BehaviorSubject<OrderSurcharges[]>;


    constructor(private _httpClient: HttpClient,
    ) { this.onCodChanged = new BehaviorSubject(null); }

    getDomesticCities(): Observable<Entity<number>[]> {
        return this._httpClient.get<Entity<number>[]>(environment.apiUrl + 'v1/Quotes/Lebanon/Cities');
    }

    getCountries(): Observable<Entity<number>[]> {
        return this._httpClient.get<Entity<number>[]>(environment.apiUrl + 'v1/Quotes/Countries');
    }

    getCitiesByCountryId(countryId: number): Observable<Entity<number>[]> {
        return this._httpClient.get<Entity<number>[]>(
            environment.apiUrl + 'v1/Quotes/Countries/' + countryId + '/Cities'
        );
    }

    getShippingTypes(direction: ServiceDirection, type: ServiceType): Observable<Entity<number>[]> {
        const params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
        return this._httpClient.get<Entity<number>[]>(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params });
    }

    getShippingTypesPromise(direction: ServiceDirection, type: ServiceType): Promise<Entity<number>[]> {
        return new Promise((resolve, reject) => {
            if (direction === undefined || type === undefined) {
                resolve(null);
            }
            const params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
            this._httpClient
                .get<Entity<number>[]>(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params })
                .subscribe(result => {
                    this.shippingTypes = result;
                    this.shippingTypesChanged.next(this.shippingTypes);
                    resolve(result);
                }, reject);
        });
    }
    saveCodAmount(cod: CashOnDelivery[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Cod', cod);
    }
    saveBulkCodAmount(cod: CashOnDelivery[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/BulkCod', cod);
    }
    deleteCodAmount(quoteId: number): Observable<any> {
        return this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Cod/' + quoteId);
    }
    getCodAmounts(orderId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Cod/' + orderId)
                .subscribe((cods: CashOnDelivery[]) => {
                    this.cods = cods;
                    this.onCodChanged.next(this.cods);
                    resolve(cods);
                }, reject);
        });
    }
    getPieces(quoteId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Dimensions/' + quoteId)
                .subscribe((pieces: Piece[]) => {
                    this.pieces = pieces;
                    this.onPieceChanged.next(this.pieces);
                    resolve(pieces);
                }, reject);
        });
    }
    getSurchargesFromTarif(productId: number): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Surcharges/' + productId)
    }

    saveSurcharges(surcharges: OrderSurcharges[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Surcharges', surcharges);
    }
    savePieces(pieces: Piece[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Dimensions', pieces);
    }
    deletePieces(pieceId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Dimensions/' + pieceId).subscribe(_ => {
                resolve(this);
            }, reject);
        });
    }
    generateDomesticQuote(quote: DomesticQuote): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote', quote)
            ;
    }


    generateDomesticBulkQuote(quotes: DomesticQuote[]): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote/Bulk', quotes);
    }

    generateInternationalQuote(quote: InternationalQuote): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/InternationalQuote', quote);
    }

    addQuote(quote: QuoteData): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes', quote);
    }

    generateAnonymousQuote(order: NewOrder): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/AnonymousQuote', order);
    }

    FetchStockAsync(storerKey: string, sku: string): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/FetchStock/' + storerKey + "/" + sku)
    }
}

