import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CommodityService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.commoditiesChanged = new BehaviorSubject(null);
    }
    getCommodities(serviceTypeId) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiUrl + 'v1/Commodities/ServiceType/' + serviceTypeId)
                .subscribe(result => {
                this.commodities = result;
                this.commoditiesChanged.next(this.commodities);
                resolve(result);
            }, reject);
        });
    }
}
CommodityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommodityService_Factory() { return new CommodityService(i0.ɵɵinject(i1.HttpClient)); }, token: CommodityService, providedIn: "root" });
