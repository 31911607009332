import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent, MatDialog, MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ContactList, ContactListDetail } from './c-list.model';
import { Subject, fromEvent } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ContactsListService } from '../c-list/c-list.service';
import { debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-contact-editor',
    templateUrl: './c-list-editor.component.html',
    styleUrls: ['./c-list-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactListEditorComponent implements OnInit, OnDestroy {
    contactsListSelected;
    selectedTab = 0;
    action: string;
    contactList: ContactList;
    contactListDetail: ContactListDetail[];
    contacts: ContactListItem[];
    allContacts: ContactListItem[] = [];
    selectedContacts: ContactListItem[];
    contactListForm: FormGroup;
    activeAddress: FormGroup;
    activeEditAddress: FormGroup;
    filterApplied: boolean = false;
    contactsDisabled: boolean = false;

    displayContact: string;

    allSelected = false;

    displayedColumns: string[] = ['select', 'fullName', 'companyName', 'email', 'phone', 'address',];
    dataSource = new MatTableDataSource<ContactListItem>(this.allContacts);
    selection = new SelectionModel<ContactListItem>(true, []);

    @ViewChild('filter', { static: false })
    filter: ElementRef;



    editIndex: boolean[] = [];
    protected _unsubscribeAll = new Subject<void>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];
    contactExist = false;
    contactExistId: number;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    firstload = true;
    constructor(
        public matDialogRef: MatDialogRef<ContactListEditorComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _contactService: ContactsListService,
        public _matDialog: MatDialog
    ) {
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {

            this.contactList = _data.contactList;
            this.contactListDetail = _data.contactListDetail;
            this.contacts = _data.contacts;
            this.allContacts = _data.contacts;

            this.firstload = true;
        } else {
            this.contactList = new ContactList();
            this.contacts = _data.contacts;
            this.allContacts = _data.contacts;
        }

        this.createContactForm();

        // this.createAddressForm();
        this.subscribeContactExist();
    }


    get formValue(): any {
        return this.contactListForm.value;
    }

    get addNewIndex(): number {
        const value = this.contactListForm.value;
        return value && value.contactListDetail ? value.contactListDetail.length - 1 : -1;
    }


    ngOnInit(): void {
        this.selectedContacts = [];
        if (this.contactListDetail != undefined) {
            this.contactListDetail.forEach(contact => {
                this.selectedContacts.push(this.allContacts.find(b => b.contactId == contact[Object.keys(contact)[2]]));
            });
        }
        this.dataSource = new MatTableDataSource<ContactListItem>(this.allContacts);
        this.selection = new SelectionModel<ContactListItem>(true, this.selectedContacts);
        // this.dataSource.filterPredicate = function(data: ContactListItem, filterValue: string) {
        //     return data.fullName
        //       .trim()
        //       .toLocaleLowerCase().indexOf(filterValue.trim().toLocaleLowerCase()) >= 0;
        //   };
        // fromEvent(this.filter.nativeElement, 'keyup')
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         debounceTime(150),
        //         distinctUntilChanged()
        //     )
        //     .subscribe(() => {
        //         if (!this.dataSource) {
        //             return;
        //         }
        //         this.dataSource.filter = this.filter.nativeElement.value;
        //     });
    }
    filterContacts(a) {
        this.dataSource.filter = a.value;
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private subscribeContactExist(): void {
        this.contactListForm
            .get('description')
            .valueChanges.pipe(debounceTime(500))
            .subscribe(_ => this.checkExist());

        // this.contactsListSelected.value = a;
        // this.contactListForm
        //     .get('contactListDetail')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe();

        // this.contactListForm
        //     .get('contactListContacts')
        //     .valueChanges.pipe(takeUntil(this._unsubscribeAll))
        //     // .valueChanges.pipe(debounceTime(500))
        //     .subscribe();
        // .valueChanges.pipe(takeUntil(this._unsubscribeAll))
        // this.contactForm
        //     .get('lastName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('phoneNumber')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('email')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ContactListItem): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.contactId + 1}`;
    }






    private checkExist(): void {
        const contactList = {
            contactListId: this.contactListForm.get('contactListId').value,
            description: this.contactListForm.get('description').value,
            // firstName: this.contactForm.get('firstName').value,
            // lastName: this.contactForm.get('lastName').value,
            // phoneNumber: this.contactForm.get('phoneNumber').value,
            // email: this.contactForm.get('email').value
        };
        this._contactService.isContactExist(contactList).then(result => {
            this.contactExist = result.status;
            this.contactExistId = result.contactListId;

            if (this.contactExist) {
                this.openExistWarningDialog();
            }
        });
    }

    private openExistWarningDialog(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.header = 'Contact List Already Exists';
        this.confirmDialogRef.componentInstance.confirm = 'Ok';
        this.confirmDialogRef.componentInstance.cancel = null;

        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that this contact List already exists in your address book!';
    }

    private createContactForm(): void {
        this.tags = this.contactList.tags.slice();
        this.contactList.tags = [];
        this.contactList.contactListDetail = [];
        this.contactListForm = this._formBuilder.group(this.contactList);
        this.contactList.tags = this.tags;
        if (this.contactListDetail)
            this.contactList.contactListDetail = this.contactListDetail;


        // this.contactListForm.setControl('contactListContacts', this._formBuilder.array([], Validators.required));

        // const contactFormArray = this.contactListForm.get('contactListDetail') as FormArray;

        // this.contactList.contactListDetail.forEach(detail => {
        //     contactFormArray.push(this._formBuilder.group(detail));
        //     this.editIndex.push(false);
        // });



        this.contactListForm.valueChanges.subscribe(_ => (this.firstload = false));
    }

    populateContactListDetails(action: string) {
        this.contactListDetail = [];
        this.selection.selected.forEach(element => {
            let selectedlist = new ContactListDetail();
            selectedlist.ContactId = element.contactId;
            let a = this.contactListDetail.find(b => b.ContactId == element.contactId);
            if (a) {
                selectedlist.ContactListId = a.ContactListId;
                selectedlist.Id = a.Id;
            }
            else {
                selectedlist.ContactListId = 0;
                selectedlist.Id = 0;
            }
            this.contactListDetail.push(selectedlist);
            this.contactList.contactListDetail.push(selectedlist);
            (<FormControl>this.contactListForm.controls['contactListDetail']).setValue(selectedlist);
        });
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    saveNew(): void {
        if (!this.contactListForm.valid) {
            return;
        }
        this.populateContactListDetails('new');
        this.matDialogRef.close(['new', this.contactListForm.value, this.tags, this.contactListDetail]);
    }

    saveExisting(): void {
        if (!this.contactListForm.valid) {
            return;
        }
        this.populateContactListDetails('edit');
        this.matDialogRef.close(['edit', this.contactListForm.value, this.tags, this.contactListDetail]);
    }
}
