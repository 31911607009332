import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AuthenticationService } from './authentication/authentication.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { LoginResult } from './_models/LoginResult';
import { UserService } from './_shared/user.services';
import { BillingDetails } from './main/orders/new-order/order.model';
import { ProfileService } from './main/profile/profile.service';
import { UserDetails } from './main/profile/profile.model';
import { HeardAboutUsDialogComponent } from './main/profile/heard-about-us-dialog/heard-about-us-dialog.component';
//import { AlertPopupDialogComponent } from './main/profile/alert-popup-dialog/alert-popup-dialog.component';
import { environment } from 'environments/environment';
import { VersionService } from './_shared/version-service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    imageCollection: string[] = [];
    // Private
    private _unsubscribeAll: Subject<any>;

    heardAboutUsDialogRef: MatDialogRef<HeardAboutUsDialogComponent>;
    //alertPopUpDialog: MatDialogRef<AlertPopupDialogComponent>;

    heardAboutUsStarted = false;
    alertPopupStarted = false;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _authService: AuthenticationService,
        private _profileService: ProfileService,
        public _matDialog: MatDialog,
        private versionService: VersionService
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.updateVersion();
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility
            for (const className of this.document.body.classList) {
                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className);
                }
            }
            
            this.document.body.classList.add(this.fuseConfig.colorTheme);

            const folded = localStorage.getItem('folded');

            if (folded !== undefined && folded !== null && folded.length > 0) {
                this.fuseConfig.layout.navbar.folded = JSON.parse(folded);
            }

            this._authService.onUserLoggedIn.subscribe((user: LoginResult) => {
                this.openHeardAboutUsDialog(user);
                // if (user.countryId==158)
                //     this.openAlertPopUpDialog(user);
            });


            // this.fuseConfig.this._fuseConfigService.config({
            //     layout: {
            //         navbar: {
            //             folded: JSON.parse(folded)
            //         }
            //     }
            // });

            this.setImages();
            // if (typeof Worker !== 'undefined') {
            //     // Create a new
            //     const worker = new Worker('./client-app.worker', { type: 'module' });
            //     worker.onmessage = ({ data }) => {
            //       console.log(`page got message: ${data}`);
            //     };
            //     worker.postMessage('hello');
            //   } else {
            //     // Web workers are not supported in this environment.
            //     // You should add a fallback so that your program still executes correctly.
            //   }
        });
        // const hasSubsidiary = localStorage.getItem('hasSubsidiary');
        // if (hasSubsidiary) {
        //     this._fuseNavigationService.removeNavigationItem('distributions');
        // }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    openHeardAboutUsDialog(user: LoginResult): void {
        if (this.heardAboutUsStarted) {
            return;
        }
        this.heardAboutUsStarted = true;
        if (user && user.heardAboutUs === false) {
            this._authService.getHeardAboutUsLookups().subscribe(lookups => {
                this.heardAboutUsDialogRef = this._matDialog.open(HeardAboutUsDialogComponent, {
                    panelClass: 'contact-form-dialog',
                    width: '60vh',
                    data: {
                        lookups: lookups
                    }
                });
                this.heardAboutUsStarted = false;
            });
        }
    }

    // openAlertPopUpDialog(user: LoginResult): void {
    //     if (this.alertPopupStarted) {
    //         return;
    //     }
    //     this.alertPopupStarted = true;
    //     this.alertPopUpDialog = this._matDialog.open(AlertPopupDialogComponent, {
    //         panelClass: 'contact-form-dialog',
    //         width: '60vh'
    //     });
    //     this.alertPopUpDialog.afterClosed().subscribe(() => {
    //         this.alertPopupStarted = false;
    //     });
    // }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    private setImages(): void {
        this.imageCollection.push('/assets/images/boxes/1.jpg');
        this.imageCollection.push('/assets/images/boxes/2.jpg');
        this.imageCollection.push('/assets/images/boxes/3.jpg');
    }

    private updateVersion() {
        this.versionService.versionNumber = "v20241106";
    }
}
