/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@agm/core";
import * as i3 from "../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./google-location.component";
import * as i6 from "../location-service.service";
var styles_GoogleLocationComponent = [i0.styles];
var RenderType_GoogleLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleLocationComponent, data: {} });
export { RenderType_GoogleLocationComponent as RenderType_GoogleLocationComponent };
function View_GoogleLocationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "agm-marker", [], null, [[null, "markerClick"], [null, "dragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerClick" === en)) {
        var pd_0 = (_co.clickedMarker(_v.context.$implicit.label, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("dragEnd" === en)) {
        var pd_1 = (_co.markerDragEnd(_v.context.$implicit, $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.FitBoundsAccessor, null, [i2.AgmMarker]), i1.ɵdid(2, 1720320, null, 1, i2.AgmMarker, [i2.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], label: [2, "label"], draggable: [3, "draggable"] }, { markerClick: "markerClick", dragEnd: "dragEnd" }), i1.ɵqud(603979776, 2, { infoWindow: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "agm-info-window", [], null, null, null, i3.View_AgmInfoWindow_0, i3.RenderType_AgmInfoWindow)), i1.ɵdid(5, 770048, [[2, 4]], 0, i2.AgmInfoWindow, [i2.InfoWindowManager, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", "-", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.lat; var currVal_1 = _v.context.$implicit.lng; var currVal_2 = _v.context.$implicit.label; var currVal_3 = _v.context.$implicit.draggable; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.longitude; var currVal_5 = _co.latitude; _ck(_v, 7, 0, currVal_4, currVal_5); }); }
export function View_GoogleLocationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { agmMap: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "zoomChange"], [null, "mapClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("zoomChange" === en)) {
        var pd_0 = (_co.onZoomChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("mapClick" === en)) {
        var pd_1 = (_co.mapClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AgmMap_0, i3.RenderType_AgmMap)), i1.ɵprd(4608, null, i2.MarkerManager, i2.MarkerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.InfoWindowManager, i2.InfoWindowManager, [i2.GoogleMapsAPIWrapper, i1.NgZone, i2.MarkerManager]), i1.ɵprd(4608, null, i2.CircleManager, i2.CircleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.DataLayerManager, i2.DataLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.KmlLayerManager, i2.KmlLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.LayerManager, i2.LayerManager, [i2.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i2.PolygonManager, i2.PolygonManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.PolylineManager, i2.PolylineManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.RectangleManager, i2.RectangleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i2.GoogleMapsAPIWrapper, i2.GoogleMapsAPIWrapper, [i2.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i2.ɵa, i2.ɵa, [i2.MapsAPILoader]), i1.ɵdid(13, 770048, [[1, 4], ["agmMap", 4]], 0, i2.AgmMap, [i1.ElementRef, i2.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i2.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"], mapTypeId: [3, "mapTypeId"] }, { mapClick: "mapClick", zoomChange: "zoomChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GoogleLocationComponent_1)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.longitude; var currVal_2 = _co.latitude; var currVal_3 = _co.zoomLevel; var currVal_4 = _co.mapType; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.markers; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }); }
export function View_GoogleLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "google-location", [], null, null, null, View_GoogleLocationComponent_0, RenderType_GoogleLocationComponent)), i1.ɵdid(1, 114688, null, 0, i5.GoogleLocationComponent, [i6.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleLocationComponentNgFactory = i1.ɵccf("google-location", i5.GoogleLocationComponent, View_GoogleLocationComponent_Host_0, { latitude: "latitude", longitude: "longitude" }, { location: "location" }, []);
export { GoogleLocationComponentNgFactory as GoogleLocationComponentNgFactory };
