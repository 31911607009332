
<div class="container">
    <table>
        <thead>
            <tr>
                <th style="padding-left:6px;"><mat-icon>money</mat-icon></th>
                <th>COD Amount</th>
                <th>Currency</th>
            </tr>
        </thead>

        <tbody>
            <tr class ="tableRow" tabindex="0" *ngFor="let cod of codArray; let i = index;" >
                <td class="ignore" style="padding-right: 20px;">
                    <button style="margin: 5px 0 !important; color: red;background: none !important;
                    background-color: none !important;
                    box-shadow: none !important;" mat-mini-fab class="mb-16" (click)="deleteRow(i)">  
                        <mat-icon>delete</mat-icon>         
                    </button>
                </td>
                <td [class.color]='cod.codAmount === 0 || cod.codAmount === null'>
                    <input #amount matInput id="codAmount" [disabled] = "disabled" [(ngModel)]="codArray[i].codAmount" (keydown)="emitCod()" 
                        type="number" pattern="[0-9]+([,\.][0-9]+)?" required min="1" placeholder="Enter Amount"/>
                </td>
                <td [class.color]='cod.codCurrencyId === 0 || cod.codCurrencyId === null'>
                    <mat-select [(ngModel)]="codArray[i].codCurrencyId" (ngModelChange)="filterCodCurrency($event)" [disabled] = "disabled"
                        #currency name="currencyId" #matSelect >
                        <mat-option *ngFor="let currency of currencyTypes" [disabled]="currency.disabled"
                            [value]="currency.value">
                            {{currency.title}}
                        </mat-option>
                    </mat-select>
                </td>
            
            </tr>
            <tr>
                <button class="addbtn" mat-mini-fab [disabled]="codArray.length == 2" (click)="addRow()" [disabled] = "disabled">
                    <mat-icon class="addbtn">add_circle_outline</mat-icon>
                </button>
            </tr>
        </tbody>
    </table>
</div>