import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { OrdersOrderListService } from '../../list/order-list.service';
import { OrderEvent } from '../../list/order-events-dialog/order-event.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'order-status-view',
    templateUrl: './status-view.component.html',
    styleUrls: ['./status-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderStatusViewComponent implements OnInit {
    @Input() orderId: number;
    events: OrderEvent[];
    @Output() lastEvent = new EventEmitter<OrderEvent>();

    constructor(private _orderListService: OrdersOrderListService) {}

    ngOnInit(): void {
        this._orderListService.getOrderEvents(this.orderId);

        this._orderListService.onOrderEventsChanged.subscribe((events: OrderEvent[]) => {
            this.events = events;
            if (this.events) {
                this.lastEvent.emit(this.events[0]);
            }
        });
    }
}
