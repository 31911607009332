import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class EmailConfirmedService implements Resolve<any> {
    queryParams: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {}

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.queryParams = route.queryParams;

        return new Promise((resolve, reject) => {
            Promise.all([this.confirmEmail()]).then(() => {
                resolve();
            }, reject);
        });
    }

    confirmEmail(): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = new HttpParams()
                .append('userId', this.queryParams.userId)
                .append('code', this.queryParams.code.toString());
            this._httpClient.get(environment.apiUrl + 'v1/authentication/ConfirmEmail', { params }).subscribe(
                (response: any) => {
                    resolve(response);
                },
                error => {
                    reject();
                }
            );
        });
    }
}
