import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Entity } from 'app/_models/Entity.model';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { City } from '../../_models/city.model';
import { LocationService } from '../location-service.service';
import { LocationPinned } from '../user.services';

@Component({
    selector: 'auto-complete-location',
    templateUrl: './auto-complete-location.component.html',
    styleUrls: ['./auto-complete-location.component.scss']
})
export class AutoCompleteLocationComponent implements OnInit {
    @Input() appearance = 'outline';
    @Input() options: Entity<number>[];
    @Input() label: string;
    @Input() required = false;
    @Input() defaultValue = '';
    @Input() disabled = false;
    @Input() countryId: number;
    @Input() excludeCountryId: number;

    @Input() locationValues: LocationPinned[] = [];



    @Output() selected: EventEmitter<Entity<number>> = new EventEmitter();
    @Output() cleared: EventEmitter<any> = new EventEmitter();

    filteredOptions: Observable<Entity<number>[]>;
    @Input() control: FormControl;

    filteredCities: any;
    isLoading = false;
    errorMsg: string;

    interval;

    constructor(private _locationService: LocationService) { }
    ngOnInit(): void {

        let inputControl = false;
        if (this.control) {
            inputControl = true;
        }

        if (!inputControl) {
            if (this.required) {
                this.control = new FormControl(Validators.required);
            } else {
                this.control = new FormControl();
            }

            if (this.disabled) {
                this.control.disable();
            }
        }

        this.control.valueChanges
            .pipe(
                debounceTime(200),
                tap(() => {
                    this.errorMsg = '';
                    this.filteredCities = [];
                    this.isLoading = true;
                }),
                
                switchMap(value =>
                    this._locationService.getCities(value, this.countryId, this.excludeCountryId).pipe(
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                )
            )
            .subscribe((data: City[]) => {
                this.errorMsg = '';
                this.filteredCities = data;
                // this.control.setValue(data[0]);
     
            });

        if (!inputControl) {
            this.control.setValue(this.defaultValue);
        }
       
    }

    displayFn(city?: City): string | undefined {
        return city ? city.name : undefined;
    }
}
