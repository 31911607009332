import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Caveats } from 'app/_models/Caveats';
import { CaveatsService } from 'app/_shared/caveats.services';
import { fuseAnimations } from '@fuse/animations';
import { MyNetServiceType } from 'app/_enums/ServiceDirection.enum';

@Component({
    selector: 'quote-caveats',
    templateUrl: './caveats.component.html',
    styleUrls: ['./caveats.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class QuoteCaveatsComponent implements OnInit {
    @Input() serviceType: MyNetServiceType;
    caveats: Caveats[];

    MyNetServiceType = MyNetServiceType;
    constructor(private _caveatsService: CaveatsService) {}

    ngOnInit(): void {
        this.getCaveats();
    }

    getCaveats(): void {
        this._caveatsService.getAll(this.serviceType).subscribe(
            caveats => (
                this.caveats = caveats
                ));
    }
}
