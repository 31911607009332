import { Entity } from 'app/_models/Entity.model';

export class CityCache {
    countryId: number;
    cities: Entity<number>[];

    constructor(countryId: number, citites: Entity<number>[]) {
        this.countryId = countryId;
        this.cities = citites;
    }
}
