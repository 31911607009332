import { Component, OnInit, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderDetail } from '../order.model';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfirmCheckoutComponent } from './confirm-checkout/confirm-checkout.component';

@Component({
    selector: 'orders-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
    @Input() order: OrderDetail;

    confirmCheckoutDialogRef: MatDialogRef<ConfirmCheckoutComponent>;

    constructor(public _matDialog: MatDialog) {}

    ngOnInit(): void {
    }

    pay(): void {
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this.confirmCheckoutDialogRef = this._matDialog.open(ConfirmCheckoutComponent, {
            panelClass: 'confirm-checkout',
            data: {
                order: this.order
            }
        });
    }
}
