import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuard } from 'app/_guards/auth.guard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { OrdersOrderListComponent } from './list/order-list.component';
import { OrdersNewOrderComponent } from './new-order/new-order.component';
import { OrdersOrderDetailsComponent } from './new-order/order-details/order-details.component';
import { OrdersBillingDetailsComponent } from './new-order/billing-details/billing-details.component';
import { OrderService } from './order.service';
import { OrdersOrderListService } from './list/order-list.service';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { PaymentComponent } from './new-order/payment/payment.component';
import { PaymentService } from './new-order/payment/payment.services';
import { PaymentDoneService } from './payment-done/payment-done.service';
import { OrdersPaymentDoneComponent } from './payment-done/payment-done.component';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { NetPointLocationsComponent } from './new-order/netpoint-locations/netpoint-locations.component';
import { NetPointService } from './new-order/netpoint-locations/netpoint.services';
import { PhoneNumberDialogComponent } from './new-order/billing-details/phone-number-dialog/phone-number-dialog.component';
import { ConfirmCheckoutComponent } from './new-order/payment/confirm-checkout/confirm-checkout.component';
import { OrderViewComponent } from './view/order-view.component';
import { OrderViewService } from './view/order-view.service';
import { OrderContactViewComponent } from './view/contact-view/contact-view.component';
import { OrderPaymentViewComponent } from './view/payment-view/payment-view.component';
import { OrderStatusViewComponent } from './view/status-view/status-view.component';
import { OrderDetailsViewComponent } from './view/details-view/details-view.component';
import { AddressViewComponent } from './view/contact-view/address-view/address-view.component';
import { MyNetPipesModule } from '../_pipes/mynet-pipes.module';
import { OrderInvoiceComponent } from './invoice/invoice.component';
import { ContactEditorModule } from '../contacts/contact-editor/contact-editor.module';
import { CommodityService } from 'app/_shared/commodity.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { OrderContactPreviewComponent } from './new-order/order-details/contact-preview/contact-preview.component';
import { AnonymousNewOrderComponent } from './anonymous-new-order/anonymous-new-order.component';
import { OrdersAnonymousContactComponent } from './anonymous-new-order/anonymous-contact/anonymous-contact.component';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';
import { FuseSidebarModule } from '@fuse/components';
import { OrdersAnonymousShipmentPackagesComponent } from './anonymous-new-order/anonymous-shipment-packages/anonymous-shipment-packages.component';
import { OrdersAnonymousShipmentDetailsComponent } from './anonymous-new-order/anonymous-shipment-details/anonymous-shipment-details.component';
import { OrdersAnonymousShipmentPaymentComponent } from './anonymous-new-order/anonymous-shipment-payment/anonymous-shipment-payment.component';
import { OrdersAnonymousShipmentPickupComponent } from './anonymous-new-order/anonymous-shipment-pickup/anonymous-shipment-pickup.component';
import { OrdersAnonymousShipmentReviewComponent } from './anonymous-new-order/anonymous-shipment-review/anonymous-shipment-review.component';
import { AutoCompleteCountryModule } from 'app/_shared/auto-complete-country/auto-complete-country.module';
import { AutoCompleteCityModule } from 'app/_shared/auto-complete-city/auto-complete-city.module';
import { ConfirmOrderComponent } from './new-order/order-details/confirm-order/confirm-order.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { OrderReturnDialogComponent } from './list/order-return-dialog/order-return-dialog.component';
import { ReturnOrdersViewComponent } from './view/return-order-view/return-order-view.component';
import { ConfirmDimesionsComponent } from '../profile/confirm-dimensions/confirm-dimensions';
import { InvoicePrintComponent } from './invoice-print/invoice-print.component';
import { OrderProformaComponent } from './new-order/order-details/order-proforma/order-proforma.component';
import { OrderPieceComponent } from './new-order/order-details/order-piece/order-piece.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialogContent, MatDialogModule } from '@angular/material';

// import { OrderPieceComponent } from './new-order/order-details/order-piece/order-piece.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ImportOrderComponent } from './list/import-order/import-order.component';
import { BulkOrderDialogComponent } from './list/bulk-order-dialog/bulk-order-dialog.component';
import { OrderEventsDialogComponent } from './list/order-events-dialog/order-events-dialog.component';
import { BulkOrderResultComponent } from './list/bulk-order-result/bulk-order-result.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { OrderLabelComponent } from './order-label/order-label.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { AppModule } from 'app/app.module';
import { OrderCodComponent } from './new-order/order-details/order-cod/order-cod.component';
import { OrderCodSharedModule } from 'app/_shared/order-cod-shared/order-cod-shared.module';
import { OrderSkuSharedModule } from 'app/_shared/order-sku-shared/order-sku-shared.module';
import { CurrentConversionRateModule } from '../profile/current-conversion-rate/current-conversion-rate.module';
import { InternationalBulkOrderDialogComponent } from './list/international-bulk-order-dialog/international-bulk-order-dialog.component';
import { InternationalBulkOrderResultComponent } from './list/international-bulk-order-result/international-bulk-order-result.component';
import { InternationalImportOrderComponent } from './list/international-import-order/international-import-order.component';
// import {NgxPrinterModule, NgxPrinterService} from 'ngx-printer';

const routes = [
    {
        path: 'orders',
        canActivate: [AuthGuard],
        component: OrdersOrderListComponent,
        resolve: {
            data: OrdersOrderListService
        }
    },
    {
        path: 'orders/:id',
        canActivate: [AuthGuard],
        component: OrdersNewOrderComponent,
        resolve: {
            data: OrderService
        }
    },
    {
        path: 'guest/orders/new',
        canActivate: [AnonymousGuard],
        component: AnonymousNewOrderComponent
    },
    {
        path: 'orders/payments/redirect',
        // canActivate: [AuthGuard],
        component: OrdersPaymentDoneComponent,
        resolve: {
            data: PaymentDoneService
        }
    },
    {
        path: 'orders/view/:id',
        canActivate: [AuthGuard],
        component: OrderViewComponent,
        resolve: {
            data: OrderViewService
        }
    },
    {
        path: 'orders/print/:id',
        canActivate: [AuthGuard],
        component: OrderInvoiceComponent,
        resolve: {
            data: OrderViewService
        }
    }
    ,
    {
        path: 'orders/printall/:id',
        canActivate: [AuthGuard],
        component: InvoicePrintComponent,
        resolve: {
            data: OrderViewService
        }
    }
    ,
    {
        path: 'orders/label/:id',
        canActivate: [AuthGuard],
        component: OrderLabelComponent,
        resolve: {
            data: OrderViewService
        }
    }
    // ,
    // {
    //     path: 'order/cash',
    //     canActivate: [AuthGuard],
    //     component: OrdersNewOrderComponent,
    //     resolve: {
    //         data: OrderService
    //     }
    // }
];

@NgModule({
    declarations: [
        OrdersOrderListComponent,
        OrdersNewOrderComponent,
        OrdersOrderDetailsComponent,
        OrdersBillingDetailsComponent,
        PaymentComponent,
        OrdersPaymentDoneComponent,
        NetPointLocationsComponent,
        PhoneNumberDialogComponent,
        OrderEventsDialogComponent,
        OrderReturnDialogComponent,
        ConfirmCheckoutComponent,
        ConfirmOrderComponent,
        OrderViewComponent,
        OrderContactViewComponent,
        OrderPaymentViewComponent,
        OrderStatusViewComponent,
        OrderDetailsViewComponent,
        AddressViewComponent,
        OrderInvoiceComponent,
        OrderContactPreviewComponent,
        AnonymousNewOrderComponent,
        OrdersAnonymousContactComponent,
        OrdersAnonymousShipmentPackagesComponent,
        OrdersAnonymousShipmentDetailsComponent,
        OrdersAnonymousShipmentPaymentComponent,
        OrdersAnonymousShipmentPickupComponent,
        OrdersAnonymousShipmentReviewComponent,
        ReturnOrdersViewComponent,
        OrderProformaComponent,
        ConfirmDimesionsComponent,
        InvoicePrintComponent,
        OrderPieceComponent,
        ImportOrderComponent,
        BulkOrderDialogComponent,
        BulkOrderResultComponent,
        OrderLabelComponent,
        OrderCodComponent,
        InternationalBulkOrderDialogComponent,
        InternationalBulkOrderResultComponent,
        InternationalImportOrderComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        ContactEditorModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        AutoCompleteCountryModule,
        MatRadioModule,
        MatToolbarModule,
        MyNetPipesModule,
        MatTooltipModule,
        FuseSidebarModule,
        MatListModule,
        AutoCompleteCityModule,
        MatButtonToggleModule,
        MatCardModule,
        NgxBarcodeModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        MatNativeDateModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatSelectInfiniteScrollModule,
        PdfJsViewerModule,
        OrderCodSharedModule,
        OrderSkuSharedModule,
        CurrentConversionRateModule
        // OrderCodModule
    ],
    // exports: [OrderCodComponent],
    entryComponents: [
        NetPointLocationsComponent,
        PhoneNumberDialogComponent,
        ConfirmCheckoutComponent,
        ConfirmOrderComponent,
        OrderEventsDialogComponent,
        OrderReturnDialogComponent,
        ReturnOrdersViewComponent,
        OrderPieceComponent,
        ConfirmDimesionsComponent,
        InvoicePrintComponent,
        ImportOrderComponent,
        BulkOrderDialogComponent,
        BulkOrderResultComponent,
        InternationalBulkOrderDialogComponent,
        InternationalBulkOrderResultComponent,
        InternationalImportOrderComponent,
        OrderLabelComponent,
        OrderCodComponent
    ],
    providers: [
        OrderService,
        OrdersOrderListService,
        PaymentService,
        PaymentDoneService,
        NetPointService,
        OrderViewService,
        CommodityService,
        DecimalPipe,
        DatePipe,
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
    ]
})
export class OrdersModule { }
