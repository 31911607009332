import { DataSource } from '@angular/cdk/table';
import { ContactsContactListService } from './contact-list.service';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { ContactListItem } from './contact-list-item.model';

export class ContactsContactListDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');
    public pagination: any;
    // public _typeId: number; //Home


    constructor(
        private _contactsContactListService: ContactsContactListService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,

    ) {
        super();

        this.filteredData = this._contactsContactListService.contacts;
        this.pagination = this._contactsContactListService.paginationInfo;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._contactsContactListService.onContactsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges).pipe(
            map(() => {
                let data = this._contactsContactListService.contacts.slice();
                // let data = this._contactsContactListService.onContactsChanged.value.slice();
                data = this.filterData(data);

                this.filteredData = [...data];
                data = this.sortData(data);
                // Grab the page's slice of data
                // const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                // return data.splice(startIndex, this._matPaginator.pageSize);
                return data;
            })
        );
    }

    /**
     * Disconnect
     */
    disconnect(): void { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }
    setTypeFilter(typeId): any {
        // this._typeId = typeId;
    }
    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data: ContactListItem[]): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }
        return data.sort((a, b) => {
            let propertyA = '';
            let propertyB = '';

            switch (this._matSort.active) {
                case 'fullName':
                    [propertyA, propertyB] = [a.fullName, b.fullName];
                    break;
                case 'tags':
                    [propertyA, propertyB] = [
                        a.tags.length === 0 ? '' : a.tags[0],
                        b.tags.length === 0 ? '' : b.tags[0]
                    ];
                    break;
                case 'email':
                    [propertyA, propertyB] = [a.email, b.email];
                    break;
                case 'phoneNumber':
                    [propertyA, propertyB] = [a.phoneNumber, b.phoneNumber];
                    break;
            }

            const valueA = propertyA.toUpperCase();
            const valueB = propertyB.toUpperCase();

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }
}
