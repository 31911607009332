import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { OrderService } from '../order.service';
import { OrderViewService } from '../view/order-view.service';
import { OrderView } from '../view/order-view.model';
import { BillingDetails, CashOnDelivery } from '../new-order/order.model';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QuoteService } from 'app/main/quotes/quote.service';

@Component({
    selector: 'order-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderInvoiceComponent implements OnInit, OnDestroy {
    order: any;
    date: Date = new Date();
    billingDetails: BillingDetails;
    proformaSum: number;
    proformaDate: Date;
    Arr = Array;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _orderViewService: OrderViewService, private _orderService: OrderService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        console.log('this.proformaSum');

        this.proformaDate = new Date();
        console.log(this.order);
        this._orderViewService.onOrderSelected.subscribe(order => {
            this.order = order;
            console.log(this.order.orderInfo.numberOfPieces);
            console.log(this.pageNumber(this.order.orderInfo.numberOfPieces));
        });
        this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
            this.billingDetails = info;
        });
        this.proformaSum = this.order.proformaDetails
            .reduce((sum, current) => sum + current.totalValue, 0);
        console.log(this.proformaSum);
        console.log(this.order.proformaDetails);
    }
    pageNumber(numberOfPieces: number) {
        return Array.from(new Array(numberOfPieces), (x, i) => i + 1)
    }
    ngAfterInitView() {
        window.print();
    }
    onPrint() {
        window.print();
    }
    // printDiv(divName) {
    //     var printContents = document.getElementById(divName).innerHTML;
    //     var originalContents = document.body.innerHTML;
    //     document.body.innerHTML = printContents;
    //     window.print();
    //     document.body.innerHTML = originalContents;
    // }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
