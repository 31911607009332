import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatSelectModule, MatButtonModule } from '@angular/material';
import { OrderSkuSharedComponent } from './order-sku-shared.component';
@NgModule({
    declarations: [OrderSkuSharedComponent],
    imports: [FuseSharedModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatSelectModule,MatButtonModule],
    exports: [OrderSkuSharedComponent]
})
export class OrderSkuSharedModule { }
