<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Verify your phone number: {{ phoneNumber }}
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close(false)" aria-label="Close dialog" type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <div class="p-24 pb-0 m-0" fusePerfectScrollbar>
            <div fxFlex fxLayout="column">
                <div class="mr-sm-12" fxFlex [formGroup]="form">
                    <mat-form-field class="w-100-p">
                        <mat-label>Code</mat-label>
                        <span matPrefix>NET- &nbsp;</span>
                        <input matInput formControlName="token" name="mynet-phone-validation-token"
                            autocomplete="off" />
                    </mat-form-field>
                </div>
                <mat-error class="text-right" *ngIf="errorMessage">
                    {{errorMessage}}
                </mat-error>

                <div class="text-right primary--fg" *ngIf="successMessage">
                    {{ successMessage }}
                </div>

                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

            </div>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
        <button mat-raised-button color="primary" class="mr-8" (click)="confirm()" aria-label="Confirm"
            matTooltip="Confirm">
            CONFIRM
        </button>

        <button mat-button class="ml-8" color="warn" (click)="sendSMSCode()" aria-label="Resend" matTooltip="Resend">
            RESEND
        </button>
    </mat-dialog-actions>
</div>