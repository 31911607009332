<!-- <div class="page-layout carded fullwidth quick-quote">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="mt-16 mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        format_quote
                    </mat-icon>
                    <span class="h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <ng-container>Manifest Report</ng-container>
                    </span>
                </div>
            </div>
        </div> -->

<div id="invoice" class="compact page-layout blank" fxLayout="column">
    <form [formGroup]="group" class="compact page-layout blank">
        <mat-progress-bar class="app-progress-bar" *ngIf="showProgressBar" mode="indeterminate" color="accent">
        </mat-progress-bar>

        <div class="invoice-container">
            <div class="doNotPrint row">
                <mat-form-field appearance="fill">
                    <mat-label>From date</mat-label>
                    <input matInput [matDatepicker]="fromPicker" [max]="toDate" formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>To date</mat-label>
                    <input matInput [matDatepicker]="toPicker"  formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="toPicker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>
                <button mat-flat-button style="cursor: pointer;height: 59px; margin-left: 5px;" color="primary"
                    aria-label="print" [disabled]="orders.length  == 0" (click)="onPrint()">
                    <mat-icon>print</mat-icon>
                </button>
                <button mat-flat-button [disabled]="orders.length  == 0"
                    style="cursor: pointer;height: 59px; margin-left: 5px;" color="primary" aria-label="export"
                    (click)="exportAsExcel()">
                    <mat-icon>import_export</mat-icon>
                </button>


            </div>

            <div>
                <div class="header">
                    <td>
                        <h1> Manifest - {{billingDetails.companyName}}</h1>
                        <div class="row">
                            <h3> From : {{fromDateFormatted}} </h3>
                            <h3 style="margin-left: 10px;"> To : {{toDateFormatted}} </h3>
                        </div>
                        <h3> Total Orders : {{orders.length}} </h3>
                    </td>
                </div>

                <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="label">
                        <th mat-header-cell *matHeaderCellDef> Label</th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-barcode [bc-value]="element.label" [bc-height]="30" [bc-width]="2.5"
                                [bc-display-value]="true"></ngx-barcode>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="shipper">
                        <th mat-header-cell *matHeaderCellDef> Shipper </th>
                        <td mat-cell *matCellDef="let element"> {{element.shipper}} </td>
                    </ng-container>
                    <ng-container matColumnDef="origin">
                        <th mat-header-cell *matHeaderCellDef> Origin </th>
                        <td mat-cell *matCellDef="let element"> {{element.origin}} </td>
                    </ng-container>
                    <ng-container matColumnDef="receiver">
                        <th mat-header-cell *matHeaderCellDef> Receiver </th>
                        <td mat-cell *matCellDef="let element"> {{element.receiver}} </td>
                    </ng-container>
                    <ng-container matColumnDef="destination">
                        <th mat-header-cell *matHeaderCellDef> Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.destination}} </td>
                    </ng-container>
                    <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef> Weight </th>
                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                    </ng-container>
                    <ng-container matColumnDef="nop">
                        <th mat-header-cell *matHeaderCellDef> Nop </th>
                        <td mat-cell *matCellDef="let element"> {{element.nop}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>
    </form>
</div>
<!-- </div>
</div> -->