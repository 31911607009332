import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { LoginModel } from 'app/_models/LoginModel';
import { Routes } from 'app/Routes';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
// import { AuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { LoginStatus } from './LoginStatus';
import { LoginResult } from 'app/_models/LoginResult';
import { reject } from 'q';
import { VersionService } from 'app/_shared/version-service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loading = false;
    loginForm: FormGroup;
    errorMessage: string;
    myNetVersion: string;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        // private _socialAuthService: AuthService,
        private _router: Router,
        private versionService: VersionService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getVersionNumber();
        this.loginForm = this._formBuilder.group({
            // email: ['', [Validators.required, Validators.email]],
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            rememberMe: ['']
        });

    }

    submitForm(): void {
        this.loading = true;
        const user = new LoginModel(this.loginForm.value);
        this.errorMessage = '';
        this._authService.login(user).subscribe(
            result => {
                this.loading = false;
                if (result.status === LoginStatus.Success) {
                    this._authService.authenticate(result.user as LoginResult, result.token);
                    this.navigateToNextPage();
                    this._authService.onUserLoggedIn.next(result.user);
                } else if (result.status === LoginStatus.Lockout) {
                    this.errorMessage = 'Your account is locked!';
                } else if (result.status === LoginStatus.Failed) {
                    this.errorMessage = 'Invalid username or password!';
                } else if (result.status === LoginStatus.NeedsConfirmation) {
                    this.errorMessage = 'Your email address is not confirmed!';
                }
            },
            error => {
                this.loading = false;
                this.errorMessage = 'Unknown Server Error, please try again later';
            }
        );
    }

    private navigateToNextPage(): void {
        const nextRoute = localStorage.getItem('next');
        const role = localStorage.getItem('role');
        if (nextRoute) {
            this._router.navigateByUrl(nextRoute);
            localStorage.removeItem('next');
        } else {
            if (role == "Netpoint Station")
                this._router.navigateByUrl(Routes.netpoint);
            else
                this._router.navigateByUrl(Routes.homepage);
        }
    }

    signInWithGoogle(): void {
        // this.loading = true;
        // this.errorMessage = '';
        // this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
        //     x => {
        //         this._authService.externalLogin(x).subscribe(
        //             result => {
        //                 this.socialLogin(result, 'Google');
        //             },
        //             _ => (this.loading = false)
        //         );
        //     },
        //     _ => (this.loading = false)
        // );
    }

    // signInWithFB(): void {
    //     this.loading = true;
    //     this.errorMessage = '';
    //     this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
    //         x => {
    //             this._authService.externalLogin(x).subscribe(
    //                 (result: LoginResult) => {
    //                     this.socialLogin(result, 'Facebook');
    //                 },
    //                 _ => (this.loading = false)
    //             );
    //         },
    //         _ => (this.loading = false)
    //     );
    // }
    socialLogin(result: any, provider: string): void {
        this.loading = false;
        if (result.status === LoginStatus.Success) {
            this._authService.authenticate(result.user, result.token);
            this.navigateToNextPage();
            this._authService.onUserLoggedIn.next(result.user);
        } else if (result.status === LoginStatus.Success) {
            this.errorMessage = 'We could not login using ' + provider + '.';
        }
    }

    getVersionNumber() {
        this.myNetVersion = this.versionService.versionNumber;
    }
}
