import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, HostListener, KeyValueDiffer, KeyValueDiffers, KeyValueChanges, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, interval, Observable } from 'rxjs';
import { OrdersOrderListService } from './order-list.service';
import { OrdersOrderListDataSource } from './order-list.datasource';
import { takeUntil, debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { ServiceType } from 'app/_enums/ServiceType.enum';
import { OrderListItem } from './order-list-item.model';
import { Routes } from 'app/Routes';
import { Router } from '@angular/router';
import { OrderReturnDialogComponent } from './order-return-dialog/order-return-dialog.component';
import { OrderService } from '../order.service';
import { ReturnOrder, Order, BillingDetails } from '../new-order/order.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material';
import { OrderExcelListItem } from './import-order/import-order.model';
import { Country } from 'app/_models/country.model';
import { City } from 'app/_models/city.model';
import { LocationService } from 'app/_shared/location-service.service';
import { isNullOrUndefined } from 'util';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { isUndefined } from 'lodash';
import { OrderEventsDialogComponent } from './order-events-dialog/order-events-dialog.component';
import { BulkOrderDialogComponent } from './bulk-order-dialog/bulk-order-dialog.component';
import { BulkOrderResultComponent } from './bulk-order-result/bulk-order-result.component';
import { OrderLabelComponent } from '../order-label/order-label.component';
import { OrderViewService } from '../view/order-view.service';
import { InternationalBulkOrderDialogComponent } from './international-bulk-order-dialog/international-bulk-order-dialog.component';
import { InternationalBulkOrderResultComponent } from './international-bulk-order-result/international-bulk-order-result.component';
import { QuoteService } from 'app/main/quotes/quote.service';

@Component({
    selector: 'orders-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersOrderListComponent implements OnInit, OnDestroy {
    dataSource: OrdersOrderListDataSource | null;
    displayedColumns = ['direction', 'label', 'shipper', 'from', 'receiver', 'to', 'commodity', 'price', 'status', 'statusDate', 'updatedDate', 'updatedBy', 'select', 'actions'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;
    returnOrders: Order[];
    changedOrders: Order[];
    loadingOrders: boolean = false;
    fromDate: Date;
    toDate: Date;
    public color: ThemePalette = 'warn';
    checkedOrdersToPrint: number[] = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    orderEventsDialogRef: MatDialogRef<OrderEventsDialogComponent>;
    orderReturnDialogRef: MatDialogRef<OrderReturnDialogComponent>;
    resultDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    ServiceDirection = ServiceDirection;
    Routes = Routes;
    private _unsubscribeAll: Subject<any>;
    timer: any;
    tickCount: number;
    retryTickLimit: number = 2;
    selection = new SelectionModel<OrderListItem>(true, []);
    bulkOrderDialogRef: MatDialogRef<BulkOrderDialogComponent>;
    bulkOrderResultDialogRef: MatDialogRef<BulkOrderResultComponent>;
    internationalbulkOrderDialogRef: MatDialogRef<InternationalBulkOrderDialogComponent>;
    internationalbulkOrderResultDialogRef: MatDialogRef<InternationalBulkOrderResultComponent>;
    orderIdsToCheckStatus = [];
    selectedVal: string = "0";
    importingOrders: boolean = false;
    countries: Country[];
    cities: City[];
    allCities: City[];
    bulkOrderVisibility = false;
    internationalBulkOrderVisibility = true;

    domesticCountryId = 158;
    domesticCountryName = "Lebanon";


    orderLabelDialogRef: MatDialogRef<OrderLabelComponent>;



    disableModifyOrder = true;
    constructor(private _router: Router, private differs: KeyValueDiffers, private _ordersService: OrderService,
        private _ordersOrderListService: OrdersOrderListService, private _orderViewService: OrderViewService, public _matDialog: MatDialog, private _locationService: LocationService,
        private _quoteService: QuoteService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.getCountries();
        this._ordersService.getMyInfo().subscribe((info: BillingDetails) => {
            this.domesticCountryId = info.countryId;
            this.domesticCountryName = info.countryName;
            this.getCities(this.domesticCountryId.toString());
        });



        if (isUndefined(this._ordersOrderListService.fromDate)) {
            this.fromDate = new Date();
            this.fromDate.setHours(0, 0, 0);

            // this.fromDate.setDate(this.fromDate.getDate());
            // this.fromDate.setHours(1);
            // this.fromDate.setMinutes(0);
            // this.fromDate.setSeconds(0);
        }
        else
            this.fromDate = this._ordersOrderListService.fromDate;

        if (this._ordersOrderListService.toDate == undefined) {
            this.toDate = new Date();
            this.toDate.setHours(23, 59, 0);
            // this.toDate.setDate(this.toDate.getDate());
            // this.toDate.setHours(23);
            // this.toDate.setMinutes(59);
            // this.toDate.setSeconds(0);
        }
        else
            this.toDate = this._ordersOrderListService.toDate;

        this.dataSource = new OrdersOrderListDataSource(this._ordersOrderListService, this.paginator, this.sort);
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe((x: KeyboardEvent) => {
                if (x.code == "NumpadEnter" || x.code == "Enter")
                    this.changeDate(x.code);
                else {
                    if (!this.dataSource) {
                        return;
                    }
                    this.dataSource.filter = this.filter.nativeElement.value;
                }
            });

        this.onTypeChange("0");
        if (navigator.onLine) {
        } else {
        }
    }

    private getCountries(): Promise<any> {
        // this.loadingComplete = false;
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.countries = success;
            });
        });
    }
    private getCities(countryId: string): Promise<any> {
        // this.loadingComplete = false;
        return new Promise((resolve, reject) => {
            this._locationService.getCountryCitiesFromDB(countryId).subscribe(success => {
                resolve(success);
                this.cities = success;
                // this.loadingComplete = true;
                this._quoteService.getShippingTypes(ServiceDirection.Domestic, ServiceType.Parcels).pipe(takeUntil(this._unsubscribeAll)).subscribe(shippingTypes => {
                    this.bulkOrderVisibility = this.cities != undefined && this.countries != undefined && shippingTypes != undefined && shippingTypes.length > 0
                });
            });
            this._locationService.getCitiesFromDB().subscribe(success => {
                resolve(success);
                this.allCities = success;
                // this.loadingComplete = true;
                this._quoteService.getShippingTypes(ServiceDirection.Export, ServiceType.Docs).pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
                    this._quoteService.getShippingTypes(ServiceDirection.Export, ServiceType.Parcels).pipe(takeUntil(this._unsubscribeAll)).subscribe(result1 => {
                        var shippingTypes = result.concat(result1);
                        this.internationalBulkOrderVisibility = this.allCities != undefined && this.countries != undefined && shippingTypes != undefined && shippingTypes.length > 0
                    });
                });
            });
        });
    }


    ngAfterContentInit(): void {
        // this.refreshOrders();
        // TODO
        this.tickCount = 0;
        this.refreshOrders()
        this.timer = setInterval(() => this.refreshOrders(), 5000);


        // this.timer = interval(5000).subscribe(x => {
        //     this.refreshOrders();
        // });
    }

    public OpenAddFilesDialog(evt: any) {
        this.openBulkOrderDialog();
    }

    public OpenAddInternationalFilesDialog(evt: any) {
        this.openInternationalBulkOrderDialog();
    }

    openBulkOrderDialog(): void {
        this.bulkOrderDialogRef = this._matDialog.open(BulkOrderDialogComponent
            , {
                disableClose: true,
                panelClass: 'bulk-order',
                data: {
                    countries: this.countries,
                    cities: this.cities,
                    domesticCountryId: this.domesticCountryId,
                    // orders: this.ordersToImport,
                }
            }
        );
        this.bulkOrderDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {

                var pOrders = this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate);
                Promise.all([pOrders]);

                this.importingOrders = false;
                return;
            }


            // this.bulkOrderParams = response[1];
            // const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
            // e.click();
            this.bulkOrderDialogRef = null;
        });
    }
    openBulkOrderResultDialog(orders): void {
        this.bulkOrderResultDialogRef = this._matDialog.open(BulkOrderResultComponent
            , {
                panelClass: 'bulk-order-result',
                data: {
                    ordersImported: orders,
                }
            }
        );
        this.bulkOrderResultDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }

            const status = response[0] as string;

            if (status === 'cancel') {
                this.importingOrders = false;
                return;
            }
            // this.bulkOrderParams = response[1];
            // const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
            // e.click();
            this.bulkOrderResultDialogRef = null;
        });
    }
    orderImportCount = 0;
    openInternationalBulkOrderDialog(): void {
        this.internationalbulkOrderDialogRef = this._matDialog.open(InternationalBulkOrderDialogComponent
            , {
                disableClose: true,
                panelClass: 'bulk-order',
                data: {
                    countries: this.countries,
                    cities: this.allCities,
                    domesticCountryId: this.domesticCountryId,
                    // orders: this.ordersToImport,
                }
            }
        );
        this.internationalbulkOrderDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {

                var pOrders = this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate);
                Promise.all([pOrders]);

                this.importingOrders = false;
                return;
            }
            this.internationalbulkOrderDialogRef = null;
        });
    }
    openInternationalBulkOrderResultDialog(orders): void {
        this.internationalbulkOrderResultDialogRef = this._matDialog.open(InternationalBulkOrderResultComponent
            , {
                panelClass: 'bulk-order-result',
                data: {
                    ordersImported: orders,
                }
            }
        );
        this.internationalbulkOrderResultDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }

            const status = response[0] as string;

            if (status === 'cancel') {
                this.importingOrders = false;
                return;
            }
            // this.bulkOrderParams = response[1];
            // const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
            // e.click();
            this.internationalbulkOrderResultDialogRef = null;
        });
    }


    public validateExcelOrder(order: OrderExcelListItem): OrderExcelListItem {

        if (order.shipper == '')
            order.isValid = false;
        if (order.weight <= 0)
            order.weight = 0.5;

        const contactAddress = order.addresses[0];
        if (contactAddress.cityName == '' || contactAddress.countryName == '' || contactAddress.street == undefined || contactAddress.building == '')
            order.isValid = false;
        if (order.firstName == '' || order.lastName == '')
            order.isValid = false;
        var countryIdRetreived = this.countries.find(x => x.name == order.addresses[0].countryName);
        if (!isNullOrUndefined(countryIdRetreived)) {
            order.addresses[0].countryId = countryIdRetreived.countryId;
            //order.addresses[0].cities = this.cities.filter(x => x.countryId == countryIdRetreived.countryId);
        }
        else {
            countryIdRetreived = this.countries.find(x => x.countryId == 158);
            order.addresses[0].countryId = 158;
            //order.addresses[0].cities = this.cities.filter(x => x.countryId == 158);
        }
        var cityIdRetreived = this.cities.find(x => x.cityName == order.addresses[0].cityName);
        if (!isNullOrUndefined(cityIdRetreived)) {
            order.addresses[0].cityId = cityIdRetreived.cityId;
        }
        else
            order.isValid = false;
        const mobile = order.phoneNumber;


        var phoneCountryId = this.countries.find(x => x.countryPhoneCode == order.phoneCountryCode);
        if (phoneCountryId) {
            const mobile = order.phoneNumber;
            const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), phoneCountryId.countryReference);

            if (validatedPhone == '') {
                //     order.phoneNumber = validatedPhone
                // else {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }

        // const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
        // if (validatedPhone != '')
        //     order.phoneNumber = validatedPhone
        // else {
        //     order.phoneNumber = '';
        //     order.isValid = false;
        // }

        return order;
    }


    onTypeChange(typeSelected) {
        this.loadingOrders = true;
        this.selectedVal = typeSelected;
        let filter;
        if (this.filter.nativeElement.value.length > 0)
            filter = this.filter.nativeElement.value + " AND productTypeId = " + typeSelected;
        else
            filter = " productTypeId = " + typeSelected;
        this.dataSource.filter = filter;
        this.loadingOrders = false;
    }

    deleteOrder(orderId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadingOrders = true;
                this._ordersOrderListService.deleteOrder(orderId).then((response: any) => {
                    this.loadingOrders = false;
                });
            }
            this.confirmDialogRef = null;
        });
    }
    duplicateOrder(orderId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to duplicate?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadingOrders = true;
                this._ordersOrderListService.duplicateOrder(orderId).then((response: any) => {
                    this.loadingOrders = false;
                });
            }
            this.confirmDialogRef = null;
        });
    }

    cancelOrder(label: string): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to cancel order?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadingOrders = true;
                this._ordersOrderListService.cancelOrder(label).then((response: any) => {
                    this.openResultDialog(response[0].result);
                    this.loadingOrders = false;
                });
            }
            this.confirmDialogRef = null;
        });
    }
    private openResultDialog(message): void {
        this.resultDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.resultDialogRef.componentInstance.header = 'Cancel';
        this.resultDialogRef.componentInstance.confirm = 'Ok';
        this.resultDialogRef.componentInstance.cancel = '';
        this.resultDialogRef.componentInstance.confirmMessage = message;
    }
    generateReturnOrderDialog(order: any): void {
        this.orderReturnDialogRef = this._matDialog.open(OrderReturnDialogComponent, {
            panelClass: "contact-form-dialog",
            disableClose: false,
            data: {
                ReturnOrdersType: order.returnOrders
            }
        });
        this.orderReturnDialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result[0] == 'cancel')
                    return;
                const returnOrder = new ReturnOrder(
                    {
                        orderId: order.orderId,
                        returnOrderId: 0,
                        pickupDate: result[1].collectionDate,
                        timeRangeLookupId: 0,
                        specialInstructions: result[1].specialInstructions,
                        cods: result[1].orderCod,
                        serviceTypeId: result[1].retourTypeId
                    })
                this._ordersOrderListService.addReturnOrder(returnOrder);
            }
            this.orderReturnDialogRef = null;
        });
    }

    viewOrderEvents(order: OrderListItem): void {
        this._ordersOrderListService.getOrderEvents(order.orderId);
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this.orderEventsDialogRef = this._matDialog.open(OrderEventsDialogComponent, {
            panelClass: 'order-events-dialog',
            data: {
                label: order.label,
                maxHeight: '80vh',
                minHeight: '20vh'
            }
        });
    }

    viewOrderLabel(order: any): void {
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this.loadingOrders = true;
        this._orderViewService.generateLabel(order.toString()).then(x => {
            this.loadingOrders = false;
            this.orderLabelDialogRef = this._matDialog.open(OrderLabelComponent, {
                panelClass: 'order-label',
                data: {
                    orderIds: order.toString(),
                    url: x.data
                }
            });
        })

    }
    navigateToTracking(order: OrderListItem): void {
        window.location.href = [Routes.trackingURL + order.label].toString();
    }

    viewOrderDetails(order: OrderListItem): void {
        window.location.href = [Routes.ordersView + order.orderId].toString();
    }
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear()
            this.checkedOrdersToPrint = [];
        }
        else
            this.dataSource.filteredData.filter(x => x.label != null)
                .forEach(row => this.selection.select(row));
        this.selection.selected.forEach(row => this.checkedOrdersToPrint.push(row.orderId));
    }
    orderToggle(row) {
        if (isNullOrUndefined(row.label))
            return;
        this.checkedOrdersToPrint = [];
        this.selection.toggle(row);
        this.selection.selected.forEach(row => this.checkedOrdersToPrint.push(row.orderId));
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.filteredData.filter(x => x.label != null).length;

        return numSelected === numRows;
    }
    checkboxLabel(row?: OrderListItem): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId + 1}`;
    }
    clearFromDate(event) {
        event.stopPropagation();
        this.fromDate = null;
        this._ordersOrderListService.fromDate = this.fromDate;
        this._ordersOrderListService.toDate = this.toDate;
        this.loadingOrders = true;
        this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate).then((response: any) => {
            this.loadingOrders = false
            this.dataSource = new OrdersOrderListDataSource(this._ordersOrderListService, this.paginator, this.sort);
            this.dataSource.filter = this.filter.nativeElement.value;
            this.onTypeChange(this.selectedVal)
        });
    }
    clearToDate(event) {
        event.stopPropagation();
        this.toDate = null;
        this._ordersOrderListService.fromDate = this.fromDate;
        this._ordersOrderListService.toDate = this.toDate;
        this.loadingOrders = true;
        this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate).then((response: any) => {
            this.loadingOrders = false
            this.dataSource = new OrdersOrderListDataSource(this._ordersOrderListService, this.paginator, this.sort);
            this.dataSource.filter = this.filter.nativeElement.value;
            this.onTypeChange(this.selectedVal)
        });
    }
    changeDate(event) {
        this.loadingOrders = true;
        this._ordersOrderListService.fromDate = this.fromDate;
        this._ordersOrderListService.toDate = this.toDate;
        this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate).then((response: any) => {
            this.loadingOrders = false;
            this.dataSource = new OrdersOrderListDataSource(this._ordersOrderListService, this.paginator, this.sort);
            this.dataSource.filter = this.filter.nativeElement.value;
            this.onTypeChange(this.selectedVal)
            if (event == "NumpadEnter" || event == "Enter")
                this.dataSource.filter = this.filter.nativeElement.value;

        });
    }
    printCheckedOrders(orderid?: number) {
        if (orderid != undefined)
            this.checkedOrdersToPrint.push(orderid);
        this._router.navigateByUrl(Routes.PrintOrders + "/" + this.checkedOrdersToPrint.toString());
    }

    printCheckedLabels(orderid?: number) {
        if (orderid != undefined)
            this.checkedOrdersToPrint.push(orderid);
        this.viewOrderLabel(this.checkedOrdersToPrint);
    }



    orderClicked(event, order) {
        if (!event.target.className.includes('badge')) {
            if (order.status == 'Draft') {
                this._router.navigateByUrl(Routes.editOrder + order.orderId.toString());
            }
            else {
                this._router.navigateByUrl(Routes.ordersView + order.orderId.toString());
            }
        }
    }
    refreshOrders() {

        // var pOrders = this._ordersOrderListService.getOrders();
        // Promise.all([pOrders]);
        this.tickCount++;
        if (this.tickCount > this.retryTickLimit)
            return;
        this.orderIdsToCheckStatus = [];
        this.dataSource.filteredData.filter(x => x.status == "Processing").forEach(
            order => {

                if (!this.orderIdsToCheckStatus.find(a => a == order))
                    this.orderIdsToCheckStatus.push(order.orderId.toString());
            });
        if (this.orderIdsToCheckStatus.length > 0) {
            var pOrders = this._ordersOrderListService.getOrders(this.paginator, this.fromDate, this.toDate);
            Promise.all([pOrders]);
        }
        // this._ordersOrderListService.getOrdersForLabel(JSON.stringify(orderIds)).then((result: any) => {

        //     var orders = result.body as OrderListItem[];

        //     orders.forEach(element => {
        //         var index = this.dataSource.filteredData.findIndex((o => o.orderId == element.orderId));
        //         if (index >= 0 && element.label) {
        //             this.dataSource.filteredData[index].label = element.label;
        //             this.dataSource.filteredData[index].statusUpdatedDateString = element.statusUpdatedDateString;
        //             this.dataSource.filteredData[index].status = element.status;
        //         }
        //     });
        // })
    }
    menuOpened(orderId) {
        this.canUpdateOrder(orderId);
    }

    canUpdateOrder(orderId) {
        this.disableModifyOrder = true;
        this._ordersService.canUpdateOrder(orderId).then(canUpdate => {
            this.disableModifyOrder = !canUpdate;

        });

    }
    pagination(pageEvents): void {
        // pageEvents.pageIndex = pageEvents.pageIndex + 1;
        // this._ordersOrderListService.getOrders(pageEvents, this.fromDate, this.toDate);
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        clearInterval(this.timer);
        // this.timer._unsubscribeAll();
        // clearInterval(this.timer);
    }
    isGroup(index, item): boolean {
        return item.mainOrderId;
    }
}
