import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { InvoiceDoneService } from './invoice-done.service';

@Component({
    selector: 'invoice-payment-done',
    templateUrl: './invoice-done.component.html',
    styleUrls: ['./invoice-done.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoicePaymentDoneComponent implements OnInit {
    constructor(private _fuseConfigService: FuseConfigService, public paymentDoneService: InvoiceDoneService, public authService: AuthenticationService) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
    }
}
