<div id="order" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent no-print"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center no-padding-print">

        <!-- HEADER -->
        <div class="header accent no-print" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        Net Point
                    </div>
                    <!-- <div class="subtitle secondary-text" fxLayoutAlign="start center">
                        <mat-icon></mat-icon>
                        <span  class="ml-8">
                            Net Point
                        </span>
                    </div> -->
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card no-border-print">
            <!-- CONTENT -->
            <div class="content">
                <!-- <mat-tab-group dynamicHeight>
                    <mat-tab label="Short tab">
                      <div class="example-small-box mat-elevation-z4">
                        Small content
                      </div>
                    </mat-tab>
                    <mat-tab label="Long tab">
                      <div class="example-large-box mat-elevation-z4">
                        Large content
                      </div>
                    </mat-tab>
                  </mat-tab-group> -->
                <!-- <mat-progress-bar *ngIf="processing" mode="indeterminate">
                </mat-progress-bar> -->
                <mat-tab-group fxLayout="column" fxFlex class="order-tabs" [selectedIndex]="selectedIndex">

                    <!-- <mat-form-field class="col-xs-6 col-md-3">
                        <mat-label>Weight</mat-label>
                        <input matInput name="mynet-order-weight" formControlName="weight" required
                            (change)="weightChanged($event)" type="number" min="0"
                            (keypress)="numberOnly($event)">
                        <span matSuffix>kg</span>
                        <mat-error
                            *ngIf="group.get('quote').get('weight').hasError('validWeight') && currentDirection !== undefined && maxWeight">
                            Max weight for {{ ServiceDirection[currentDirection] }} is {{ maxWeight }} kg
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-xs-6 col-md-3">
                        <mat-label>Chargeable</mat-label>
                        <input matInput name="mynet-order-weight" formControlName="chargeableWeight"
                            type="number" min="0" [disabled]="true">
                        <span matSuffix>kg</span>
                    </mat-form-field> -->

                    <mat-tab label="Check In">
                        <div class="order-details tab-content p-12" fusePerfectScrollbar>
                            <form [formGroup]="netPointCheckIn">
                                <mat-progress-bar *ngIf="processing" mode="indeterminate">
                                </mat-progress-bar>
                                <div class="row ">
                                    <div class=" pt-6 pb-6 w-50-p">
                                        <mat-form-field class="col-xs-6 col-md-6">
                                            <mat-label>Label</mat-label>
                                            <input matInput formControlName="label" name="mynet-order-label">
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" aria-label="Submit"
                                            matTooltip="Submit Check In" (click)="checkInLabel()"
                                            [disabled]="netPointCheckIn.invalid ">
                                            Submit
                                        </button>
                                        <button mat-raised-button color="primary" aria-label="Clear"
                                            style="margin-left: 10px;" matTooltip="Clear"
                                            (click)="clearData(netPointCheckIn)">
                                            Clear
                                        </button>
                                        <div class="small-note">
                                            *SMS will be sent to consignee containing customer token.
                                        </div>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayoutAlign="end end">
                                        <mat-video src="/assets/videos/NetPoint-1.mp4" [autoplay]="true"
                                            [preload]="true" spinner="spin"></mat-video>
                                    </div> -->
                                </div>
                            </form>

                        </div>
                    </mat-tab>

                    <mat-tab label="Pre Deliver">
                        <div class="order-details tab-content p-12" fusePerfectScrollbar>
                            <form [formGroup]="netPointPreDeliver">
                                <mat-progress-bar *ngIf="processing" mode="indeterminate">
                                </mat-progress-bar>
                                <div class="row ">
                                    <div class=" pt-6 pb-6 w-50-p">
                                        <mat-form-field class="col-xs-6 col-md-6">
                                            <mat-label>Customer Token</mat-label>
                                            <input matInput formControlName="token" name="mynet-order-label">
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" aria-label="Back"
                                            matTooltip="Submit Pre Delivery" (click)="preDeliverLabel()"
                                            [disabled]="netPointPreDeliver.invalid ">
                                            Submit
                                        </button>
                                        <button mat-raised-button color="primary" aria-label="Clear"
                                            style="margin-left: 10px;" matTooltip="Clear"
                                            (click)="clearData(netPointPreDeliver)">
                                            Clear
                                        </button>
                                        <div class="small-note">
                                            *Insert token provided by customer to retrieve label info.
                                        </div>
                                        <div *ngIf="preDeliverResult.label != 'N/A'">
                                            <netpoint-result [loading]="loading" [data]="preDeliverResult">
                                            </netpoint-result>
                                        </div>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayoutAlign="end end">
                                        <mat-video src="/assets/videos/NetPoint-1.mp4" [autoplay]="true"
                                            [preload]="true" spinner="spin"></mat-video>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </mat-tab>

                    <mat-tab label="Deliver" [disabled]="preDeliverResult.label =='N/A'">
                        <div class="order-details tab-content p-12" fusePerfectScrollbar>
                            <form [formGroup]="netPointDeliver">
                                <mat-progress-bar *ngIf="processing" mode="indeterminate">
                                </mat-progress-bar>
                                <div class="row ">
                                    <div class=" pt-6 pb-6 w-50-p">
                                        <mat-form-field class="col-xs-6 col-md-6">
                                            <mat-label>Label</mat-label>
                                            <input matInput formControlName="label" name="mynet-order-label">
                                        </mat-form-field>
                                        <mat-form-field class="pl-4 no-padding-sm col-xs-6 col-md-6">
                                            <mat-label>Amount</mat-label>
                                            <input matInput formControlName="amount" name="mynet-order-amount"
                                                type="number" min="0" required>
                                        </mat-form-field>
                                        <mat-form-field class="pl-4 no-padding-sm col-xs-6 col-md-6">
                                            <mat-select formControlName="currency" name="mynet-order-currency" required>
                                                <mat-option *ngFor="let currency of currencyTypes"
                                                    [value]="currency.title">
                                                    {{currency.title}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" aria-label="Back"
                                            matTooltip="Submit Delivery" (click)="deliverLabel()"
                                            [disabled]="netPointDeliver.invalid ">
                                            Submit
                                        </button>
                                        <button mat-raised-button color="primary" aria-label="Clear"
                                            style="margin-left: 10px;" matTooltip="Clear"
                                            (click)="clearData(netPointDeliver)">
                                            Clear
                                        </button>
                                        <div class="small-note">
                                            *Make sure to collect the displayed amount of COD.
                                        </div>
                                        <div *ngIf="preDeliverResult.label != 'N/A'">
                                            <netpoint-result [loading]="loading" [data]="preDeliverResult">
                                            </netpoint-result>
                                        </div>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayoutAlign="end end">
                                        <mat-video src="/assets/videos/NetPoint-1.mp4" [autoplay]="true"
                                            [preload]="true" spinner="spin"></mat-video>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                    <mat-tab label="Return Merchandise">

                        <div class="order-details tab-content p-12" fusePerfectScrollbar>
                            <form [formGroup]="netPointReturnMerch">
                                <mat-progress-bar *ngIf="processing" mode="indeterminate">
                                </mat-progress-bar>
                                <div class="row ">
                                    <div class=" pt-6 pb-6 w-50-p">
                                        <mat-form-field class="col-xs-6 col-md-6">
                                            <mat-label>Label</mat-label>
                                            <input matInput formControlName="label" name="mynet-order-label">
                                        </mat-form-field>
                                        <mat-form-field class="pl-4 no-padding-sm col-xs-6 col-md-6">
                                            <mat-label>Amount</mat-label>
                                            <input matInput formControlName="amount" name="mynet-order-amount"
                                                type="number" min="0" required>
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" aria-label="Back"
                                            matTooltip="Submit Delivery" (click)="returnMerch()"
                                            [disabled]="netPointReturnMerch.invalid ">
                                            Submit
                                        </button>
                                        <button mat-raised-button color="primary" aria-label="Clear"
                                            style="margin-left: 10px;" matTooltip="Clear"
                                            (click)="clearData(netPointReturnMerch)">
                                            Clear
                                        </button>
                                        <div class="small-note">
                                            *Package will be returned to Skynet premises*
                                        </div>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayoutAlign="end end">
                                        <mat-video src="/assets/videos/NetPoint-1.mp4" [autoplay]="true"
                                            [preload]="true" spinner="spin"></mat-video>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </mat-tab>

                    <mat-tab label="Return To Net">
                        <div class="order-details tab-content p-12" fusePerfectScrollbar>
                            <form [formGroup]="netPointReturnMerchToNet">
                                <mat-progress-bar *ngIf="processing" mode="indeterminate">
                                </mat-progress-bar>
                                <div class="row ">
                                    <div class=" pt-6 pb-6 w-50-p">
                                        <mat-form-field class="col-xs-6 col-md-6">
                                            <mat-label>Label</mat-label>
                                            <input matInput formControlName="label" name="mynet-order-label">
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" aria-label="Back"
                                            matTooltip="Submit Check In" (click)="returnMerchToNet()"
                                            [disabled]="netPointReturnMerchToNet.invalid ">
                                            Submit
                                        </button>
                                        <button mat-raised-button color="primary" aria-label="Clear"
                                            style="margin-left: 10px;" matTooltip="Clear"
                                            (click)="clearData(netPointReturnMerchToNet)">
                                            Clear
                                        </button>
                                        <div class="small-note">

                                        </div>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayoutAlign="end end">
                                        <mat-video src="/assets/videos/NetPoint-1.mp4" [autoplay]="true"
                                            [preload]="true" spinner="spin"></mat-video>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>