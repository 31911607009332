import { NgModule } from '@angular/core';
import { CommodityPipe } from './commodity.pipe';
import { TimeRangePipe } from './time-range.pipe';

@NgModule({
    declarations: [CommodityPipe, TimeRangePipe],
    imports: [],
    exports: [CommodityPipe, TimeRangePipe]
})
export class MyNetPipesModule {}
