<form [formGroup]="group">
    <div class="mb-12 w-100-p row">
        <div class="ship-from mb-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Ship From
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <div class="col-xs-12 col-md-9 my-4">
                            <div class="row my-4">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>Shipper</mat-label>
                                    <mat-select name="mynet-order-shipper" formControlName="shipperId" required
                                        [disabled]="disableEdit != null && disableEdit == false" msInfiniteScroll
                                        (infiniteScroll)="getNextShipperBatch()" [complete]="offset > contacts.length">
                                        <input class="searchInput" matInput focused="'true'" type="text"
                                            (keydown)="handleInput($event)"
                                            (input)="getNextShipperBatch($event.target.value)" autocomplete="off"
                                            placeholder="search for shipper">
                                        <!-- <mat-divider></mat-divider> -->
                                        <!-- <mat-option>
                                            <ngx-mat-select-search [formControl]="shipperFilterCtrl"
                                                placeholderLabel="Find contact..."
                                                noEntriesFoundLabel="no matching contact found"></ngx-mat-select-search>
                                        </mat-option> -->
                                        <mat-option *ngFor="let contact of shipperOptions$ | async"
                                            [value]="contact.contactId">{{contact.fullName}}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-select name="mynet-order-shipper" formControlName="shipperId" required>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="contactFilterCtrl"
                                                placeholderLabel="Find contact..."
                                                noEntriesFoundLabel="no matching contact found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let contact of filteredContacts  | async "
                                            [value]="contact.contactId">
                                            <span class="text-truncate">{{contact.fullName}}</span>
                                        </mat-option>
                                    </mat-select> -->
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md"
                                    ngClass.xs="my-8">
                                    <button tabindex="-1" type="button" mat-button *ngIf="groupValue.shipperId"
                                        [disabled]="disableEdit != null && disableEdit == false"
                                        (click)="editContact(groupValue.shipperId)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button type="button" tabindex="-1" mat-button (click)="addNewShipper()"
                                        [disabled]="disableEdit != null && disableEdit == false">
                                        <mat-icon class="pr-8">person_add</mat-icon>
                                    </button>
                              
                                </div>
                            </div>
                            <div class="row my-4" [ngClass]="isNetPointShipper ? 'address-container' : ''">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>{{ shipperAddressLabel }}</mat-label>
                                    <mat-select name="mynet-order-shipper-address" formControlName="shipperAddressId"
                                        [disabled]="disableEdit != null && disableEdit == false" required>
                                        <mat-option *ngFor="let contact of shipperAddresses"
                                            [value]="contact.addressId">
                                            <span class="text-truncate">{{contact.display}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md">

                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3 my-4">
                            <order-contact-preview
                                *ngIf="contacts && shipperAddresses.length > 0 && groupValue.shipperId && groupValue.shipperAddressId "
                                [isNetPoint]="isNetPointShipper" [contact]="selectedShipper"
                                [address]="selectedShipperAddress"></order-contact-preview>
                        </div>
                    </div>
                    <mat-error class="mb-8 mt-8 text-italic row center-xs start-md"
                        *ngIf="formLoaded &&  groupValue.shipperId && shipperAddresses && shipperAddresses.length === 0">
                        This contact has not specified an address in {{domesticCountryName}}</mat-error>
                    <div class="row center-xs start-md mt-8">

                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="ship-to mb-4 mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Ship To
                    </mat-panel-title>

                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <div class="col-xs-12 col-md-9 my-4">
                            <div class="row my-4">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>Receiver</mat-label>
                                    <mat-select name="mynet-order-receiver" formControlName="receiverId" required
                                        [disabled]="disableEdit != null && disableEdit == false" msInfiniteScroll
                                        (infiniteScroll)="getNextReceiverBatch()" [complete]="offset > contacts.length">
                                        <input class="searchInput" matInput focused="'true'" type="text"
                                            (keydown)="handleInput($event)"
                                            (input)="getNextReceiverBatch($event.target.value)" autocomplete="off"
                                            placeholder="search for receiver">
                                        <mat-divider></mat-divider>
                                        <!-- <mat-option>
                                            <ngx-mat-select-search [formControl]="receiverFilterCtrl"
                                                placeholderLabel="Find contact..."
                                                noEntriesFoundLabel="no matching contact found"></ngx-mat-select-search>
                                        </mat-option> -->
                                        <mat-option *ngFor="let contact of receiverOptions$ | async"
                                            [value]="contact.contactId">{{contact.fullName}}
                                        </mat-option>
                                    </mat-select>

                                    <!-- <mat-select name="mynet-order-receiver" formControlName="receiverId" required>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="contactFilterCtrl"
                                                placeholderLabel="Find contact..."
                                                noEntriesFoundLabel="no matching contact found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let contact of filteredContacts  | async"
                                            [value]="contact.contactId">
                                            <span class="text-truncate">{{contact.fullName}}</span>
                                        </mat-option>
                                    </mat-select> -->
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md">
                                    <button type="button" mat-button tabindex="-1" *ngIf="groupValue.receiverId"
                                        [disabled]="disableEdit != null && disableEdit == false"
                                        (click)="editContact(groupValue.receiverId)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button type="button" tabindex="-1" mat-button (click)="addNewReceiver()"
                                        [disabled]="disableEdit != null && disableEdit == false">
                                        <mat-icon class="pr-8">person_add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row my-4" [ngClass]="isNetPointReceiver ? 'address-container' : ''">
                                <mat-form-field class="col-xs-12 col-md-6">
                                    <mat-label>{{ receiverAddressLabel }}</mat-label>
                                    <mat-select name="mynet-order-receiver-address" formControlName="receiverAddressId"
                                        [disabled]="disableEdit != null && disableEdit == false" required>
                                        <mat-option *ngFor="let contact of receiverAddresses"
                                            [value]="contact.addressId">
                                            <span class="text-truncate">{{contact.display}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="netpoint-button-container col-xs-12 col-md-6 center-xs start-md"
                                    *ngIf="!isExport">

                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3 my-4">
                            <order-contact-preview
                                *ngIf="receiverAddresses.length > 0 && groupValue.receiverId && groupValue.receiverAddressId"
                                [isNetPoint]="isNetPointReceiver" [contact]="selectedReceiver"
                                [address]="selectedReceiverAddress"></order-contact-preview>
                        </div>
                    </div>
                    <mat-error class="mb-8 mt-8 text-italic row center-xs start-md"
                        *ngIf="formLoaded && groupValue.receiverId && receiverAddresses && receiverAddresses.length === 0">
                        This contact has not specified an address in {{domesticCountryName}}</mat-error>

                    <div class="row center-xs start-md mt-8">

                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="package-information mb-4 mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Package Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="row">
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>What are you shipping?</mat-label>
                            <mat-select [disabled]="disableEdit != null && disableEdit == false"
                                name="mynet-order-commodity" formControlName="commodityId" required>
                                <mat-option *ngFor="let commodity of commodities" [value]="commodity.commodityId">
                                    <span class="text-truncate">{{ commodity.title }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>Full description of goods</mat-label>
                            <textarea matInput rows="3" formControlName="goodsDescription"
                                [readonly]="disableEdit != null && disableEdit == false"
                                name="mynet-order-goodsDescription"></textarea>
                        </mat-form-field>
                    </div>


                    <ng-container formGroupName="quote">
                        <div class="row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Shipper Reference</mat-label>
                                <input matInput name="mynet-order-shipperReference" formControlName="shipperReference"
                                    [readonly]="disableEdit != null && disableEdit == false">
                                <mat-error *ngIf="group.get('quote').get('shipperReference').hasError('exists')">
                                    Reference already exists
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="this.isMFERP" class="row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Multiframes Label</mat-label>
                                <input matInput name="mynet-order-multiframesLabel" formControlName="multiframesLabel"
                                    [readonly]="disableEdit != null && disableEdit == false">
                            </mat-form-field>
                        </div>
                        <div class="suffix row">
                            <mat-form-field class="col-xs-6 col-md-3">
                                <mat-label>Weight</mat-label>
                                <input matInput name="mynet-order-weight" formControlName="weight" required
                                    (change)="weightChanged($event)" type="number" min="0"
                                    (keypress)="numberOnly($event)">
                                <span matSuffix>kg</span>
                                <mat-error
                                    *ngIf="group.get('quote').get('weight').hasError('validWeight') && currentDirection !== undefined && maxWeight">
                                    Max weight for {{ ServiceDirection[currentDirection] }} is {{ maxWeight }} kg
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-xs-6 col-md-3">
                                <mat-label>Chargeable</mat-label>
                                <input matInput name="mynet-order-weight" formControlName="chargeableWeight"
                                    type="number" min="0" [disabled]="true">
                                <span matSuffix>kg</span>
                            </mat-form-field>
                            <button mat-icon-button color="primary" type="button"
                                (click)="showDimensionsHelp = !showDimensionsHelp">
                                <mat-icon>info</mat-icon>
                            </button>
                            <span class="h6 text-italic" *ngIf="showDimensionsHelp">
                                Shipments are priced either by weight or dimensional weight — a calculation based on
                                package
                                size.
                            </span>

                        </div>

                        <div class="row">
                            <mat-form-field class="col-xs-12 col-md-6">
                                <mat-label>Number Of Pieces</mat-label>
                                <!-- <input matInput type="number" name="mynet-order-numberOfPieces" formControlName="numberOfPieces"
                                    (keypress)="numberOnly($event)"  min="1" max="30" required > -->
                                    <input matInput formControlName="numberOfPieces" name="mynet-contact-height" type="number"
                                    (keypress)="numberOnly($event)"  min="0" />
                            </mat-form-field>
                        </div>

                        <div class="suffix-prime row" *ngIf="showShipmentValue">
                            <mat-form-field class="col-xs-6 col-md-3">
                                <mat-label>Shipment Value</mat-label>
                                <input matInput name="mynet-order-shipmentValue" formControlName="shipmentValue"
                                    (keypress)="numberOnly($event)" type="number" min="0" max="9999999999999999">
                                <mat-error *ngIf="group.get('quote').get('shipmentValue').hasError('validDecimal')">
                                    Shipment Value should be between 0.00 and 9999999999999999
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-xs-6 col-md-3">

                                <mat-select formControlName="shipmentValueCurrencyId"
                                    name="mynet-order-shipmentValueCurrencyId" required>
                                    <mat-option *ngFor="let currency of currencyTypes" [value]="currency.value">
                                        {{currency.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <button class="newDim" mat-raised-button (click)="addNewPiece()">
                            <mat-icon>add_circle_outline</mat-icon> Add new Dimensions
                        </button>

                        <div>
                            <div>
                                <!-- *ngIf="orderPieces.length > 1" -->
                                <div fxLayout="row wrap" fxLayoutGap="10px" class="piece-container"
                                    formArrayName="pieces">
                                    <div *ngFor="let piece of orderPieces; let i = index" [formGroupName]="i">
                                        <order-piece (pieceAdded)="pieceAdded($event)" [piece]="piece" [group]="group"
                                            [currentDirection]="currentDirection" [index]="(i + 1)"></order-piece>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="currentDirection === ServiceDirection.Domestic" class="column">
                            <div *ngFor="let check of orderSurcharges">
                                <label>
                                    <input #el (change)="onCheckBoxChanges(el, check.surchargeId)" type="checkbox"
                                        mat-checkbox [checked]="check.checked == true" [disabled]="check.disabled" />
                                    {{check.description}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="currentDirection !== ServiceDirection.Domestic" class="column">
                            <div *ngFor="let check of orderSurcharges">
                                <label>
                                    <input #el (change)="onCheckBoxChanges(el, check.surchargeId)" type="checkbox"
                                        mat-checkbox [checked]="check.checked" [disabled]="check.disabled" />
                                    {{check.description}}
                                </label>
                            </div>
                        </div>
                        <div class="ship-to mb-4 mt-4 col-xs-12">
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header style="pointer-events: none;">
                                    <mat-panel-title class="font-size-18">
                                        Cash On Delivery
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="pt-16 pb-16 w-100-p">
                                    <div class="col-xs-12">
                                        <button class="newDim" mat-raised-button (click)="addNewCod()"
                                            [disabled]="this.group.get('quote').get('cods').invalid || this.group.get('quote').get('cods')['length'] == 2 || disableCOD">
                                            <mat-icon>add_circle_outline</mat-icon> Add Cash on Delivery
                                        </button>
                                        <div class="piece-container" formArrayName="cods">
                                            <div *ngFor="let cod of quoteCods; let i = index" [formGroupName]="i">
                                                <order-cod-shared (codAdded)="codAdded($event)" [cod]="cod" [index]="(i + 1)"
                                                    [group]="this.group.get('quote')"></order-cod-shared>
                                            </div>
                                            <mat-error *ngIf="this.group.get('quote').hasError('validStatus')">Please save the Cash on Delivery</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                        <div class="ship-to mb-4 mt-4 col-xs-12" *ngIf ="userRole == 'E-Commerce Integration'">
                            <mat-expansion-panel [expanded]="true" [hideToggle]="true"  >
                                <mat-expansion-panel-header style="pointer-events: none;">
                                    <mat-panel-title class="font-size-18">
                                        Fulfilment
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="pt-16 pb-16 w-100-p">

                                    <button class="newDim" mat-raised-button (click)="addNewSku()"
                                        [disabled]="group.get('quote').get('skus').invalid || group.get('quote').get('skus').disabled">
                                        <mat-icon>add_circle_outline</mat-icon> Add Sku
                                    </button>
                                    <div class="row">
                                        <div class="piece-container" formArrayName="skus">
                                            <div *ngFor="let sku of quoteSkus; let i = index" [formGroupName]="i">
                                                <order-sku-shared (skuAdded)="skuAdded($event)" [sku]="sku"
                                                    [wmsSKUs]="wmsSKUs" [index]="(i + 1)"
                                                    [group]="this.group.get('quote')"></order-sku-shared>
                                            </div>
                                            <div *ngIf="group.get('quote').get('skus').errors">
                                                <mat-error *ngIf="group.get('quote').get('skus').errors.inValidSku">
                                                    Sku is duplicated , please select a different sku.</mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </div>

        <div *ngIf="currentDirection != 2" class="proforma-invoice mt-4 col-xs-12">
            <mat-expansion-panel (opened)="updateProformaPanelState()" [expanded]="proformaPanelState">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Proforma Invoice
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">

                    <order-proforma [group]="group" (submited)="saveProforma($event)"></order-proforma>


                </div>
            </mat-expansion-panel>
        </div>
        <div class="shipping-service mt-4 col-xs-12">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-size-18">
                        Shipping Service
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-16 pb-16 w-100-p">
                    <div class="date row">
                        <!-- [disabled]="!disableEdit" -->
                        <mat-form-field class="col-xs-12 col-md-6">
                            <mat-label>Pickup date</mat-label>
                            <input matInput name="mynet-order-pickupdate" placeholder="Date" [min]="minDate"
                                [matDatepicker]="picker" (focus)="picker.open()" readonly required
                                formControlName="pickupDate">
                            <mat-icon matSuffix>date_range</mat-icon>
                        </mat-form-field>
                        <mat-radio-group class="mb-16" name="mynet-order-timerange-type"
                            formControlName="timeRangeLookupId" required>
                            <div *ngFor="let range of timeRanges; let idx = index">
                                <mat-radio-button [value]="range.id">{{range.name}}
                                </mat-radio-button>
                            </div>
                            <mat-error *ngIf="group.get('timeRangeLookupId').hasError('required')">
                                Please provide a pickup time.
                            </mat-error>
                        </mat-radio-group>
                    </div>

                    <ng-container formGroupName="quote">

                        <div class="col-xs-12" *ngIf="isExport">
                            <mat-label class="mb-8 row">What are you shipping?</mat-label>
                            <mat-radio-group class="mb-16" formControlName="serviceTypeId"
                                name="mynet-order-shippingTypeId" [required]="isExport">
                                <mat-radio-button class="mr-8" [value]="ServiceType.Docs">Docs
                                </mat-radio-button>
                                <mat-radio-button class="ml-8" [value]="ServiceType.Parcels">Parcels
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="row">
                            <mat-form-field class="col-xs-12 col-md-6"
                                *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length > 2 && !isExport">
                                <mat-label>
                                    How soon do you want it received?
                                </mat-label>
                                <mat-select formControlName="shippingTypeId" name="mynet-order-shippingTypeId" required>
                                    <mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">
                                        {{shippingType.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length == 2 && !isExport"
                            class="col-xs-12 col-md-6">
                            <mat-label class="mb-8 row"
                                [ngClass]="group.get('quote').get('shippingTypeId').hasError('required') ? 'red-fg' : ''">
                                How soon do you want it received? *
                            </mat-label>
                            <mat-radio-group class="mb-16" name="mynet-order-shipping-type"
                                formControlName="shippingTypeId"
                                [ngClass]="group.get('quote').get('shippingTypeId').hasError('required') ? 'red-fg' : ''"
                                required>
                                <mat-radio-button class="mr-8" [value]="shippingTypes[0].id">
                                    {{ shippingTypes[0].description }}
                                </mat-radio-button>
                                <mat-radio-button class="ml-8" [value]="shippingTypes[1].id">
                                    {{ shippingTypes[1].description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>

                    <div class="row">
                        <mat-form-field class="pl-4 no-padding-sm col-xs-12 col-md-6">
                            <mat-label>Special Instructions</mat-label>
                            <textarea matInput rows="3" formControlName="specialInstructions"
                                name="mynet-order-specialInstructions"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>

    <mat-datepicker #picker></mat-datepicker>
</form>
<mat-action-row>

    <button type="button" mat-button color="warn" *ngIf="!hasSubsidiaryId" (click)="cancel()">Previous</button>
    <button type="button" mat-button color="warn" *ngIf="hasSubsidiaryId" (click)="backToOrders()">Cancel</button>

    <button type="button" *ngIf="hasSubsidiaryId && disableEdit == null"
        [disabled]="(groupValue.quote.quotePrice === 0 && this.specialAccount === false) || group.invalid || newQuote.value || generatingQuote " mat-button
        color="primary" (click)="confirmOrder()">Confirm</button>
    <button type="button" *ngIf="!hasSubsidiaryId "
        [disabled]="groupValue.quote.quotePrice === 0 || group.invalid || newQuote.value || generatingQuote " mat-button
        color="primary" (click)="submitForm()">Next</button>
    <button *ngIf="newQuote.value && disableEdit == null" type="button" mat-button color="primary"
        [disabled]="loadingShippingTypes || loadingSurcharges" (click)="generateNewQuote()">Update Price & Save</button>
    <button *ngIf="disableEdit != null" type="button" mat-button color="primary" [disabled]="loadingShippingTypes"
        (click)="updateQuote()">Update Price & Confirm</button>

</mat-action-row>
<mat-progress-bar *ngIf="generatingQuote || confirmingOrder" mode="indeterminate"></mat-progress-bar>