<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">

    </div>

    <div class="customer mb-32">
        <table class="simple">
            <thead>
                <tr>
                    <th>Tracking Number</th>
                    <th>Return Type</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let info of returnOrders">
                    <td>
                        <div fxLayout="row" fxLayoutAlign="start center">

                            <div *ngIf="info.label" fxLayout="row" fxLayoutAlign="start center">
                                <p class="name text-truncate " style="cursor: pointer;color:#FF0000;"
                                    (click)="navigateToTracking(info.label)">
                                    {{info.label}}
                                </p>
                            </div>
                            <div *ngIf="!info.label" fxLayout="row" fxLayoutAlign="start center">
                                <p class="name text-truncate ">
                                    N/A
                                </p>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="info.orderInfo.productTypeId == 1">
                        <span class="name text-truncate">
                            Parcels
                        </span>
                    </td>
                    <td *ngIf="info.orderInfo.productTypeId == 4">
                        <span class="name text-truncate">
                            Cash
                        </span>
                    </td>
                    <td>
                        <p class="text-truncate">
                            <span *ngIf="info.status" class="name text-truncate">
                                {{info.status}}
                            </span>
                        </p>
                        <p class="text-truncate">
                            <span *ngIf="!info.status" class="name text-truncate">
                                Created
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>