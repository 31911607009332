import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { CashOnDelivery, OrderSku, Piece } from './order.model';

export class QuoteData {
    quoteId: number;
    fromCountryId: number;
    fromCountry: string;
    toCountryId: number;
    toCountry: string;
    fromCityId: number;
    fromCity: string;
    toCityId: number;
    toCity: string;
    shippingTypeId: number;
    shippingType: string;
    weight: number;
    chargeableWeight: number;
    width: number;
    height: number;
    length: number;
    numberOfPieces: number;
    shipmentValue: number;
    shipmentValueCurrencyId: number;
    surchargesValue: string;
    type: ServiceDirection;
    quotePrice: number;
    quoteCurrencyId: number;
    quoteCurrency: string;
    direction: ServiceDirection;
    serviceTypeId: number;
    productTypeId: number;
    shipperReference: string;
    pieces: Piece[];
    cods : CashOnDelivery[];
    skus : OrderSku[];
    multiframesLabel: string;



    constructor(data?) {
        data = data || {};
        this.quoteId = data.quoteId || 0;
        this.fromCity = data.fromCity || '';
        this.toCity = data.toCity || '';
        this.fromCountry = data.fromCountry || '';
        this.toCountry = data.toCountry || '';
        this.shippingType = data.shippingType || '';
        this.fromCityId = data.fromCityId || 0;
        this.toCityId = data.toCityId || 0;
        this.fromCountryId = data.fromCountryId || 0;
        this.toCountryId = data.toCountryId || 0;
        this.shippingTypeId = data.shippingTypeId || 0;
        this.weight = data.weight || 0.5;
        this.chargeableWeight = data.chargeableWeight || 0;
        this.width = data.width || 0;
        this.height = data.height || 0;
        this.length = data.length || 0; 
        this.numberOfPieces = data.numberOfPieces || 1;
        this.shipmentValue = data.shipmentValue || 0 ;
        this.shipmentValueCurrencyId = data.shipmentValueCurrencyId || 1 ;
        this.surchargesValue = data.surchargesValue || '' ;
        this.quotePrice = data.quotePrice || 0;
        this.quoteCurrencyId = data.quoteCurrencyId || 1;
        this.quoteCurrency = data.quoteCurrency || 'LBP';
        this.type = data.type;
        this.direction = data.type;
        this.serviceTypeId = data.serviceTypeId || 0;
        this.productTypeId = data.productTypeId || 0;
        this.shipperReference = data.shipperReference || '';
        this.pieces = data.pieces;
        this.cods = data.cods;
        this.skus = data.skus;
        this.multiframesLabel = data.multiframesLabel || '';
    }
}
