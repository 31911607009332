import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, AfterContentInit, AfterContentChecked } from '@angular/core';

import { CashOnDelivery, ProformaDetails, Piece } from '../../order.model';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, NgForm, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Country } from 'app/_models/country.model';
import { LocationService } from 'app/_shared/location-service.service';
import { OrderService } from 'app/main/orders/order.service';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { WeightValidator } from 'app/_validators/weight.validator';
import { ConfirmDimesionsComponent } from 'app/main/profile/confirm-dimensions/confirm-dimensions';
import { MatDialogRef, MatDialog } from '@angular/material';
import { takeRightWhile } from 'lodash';

export interface User {
    id: number;
    country: string;
    name: string;
    age: number;
}
@Component({
    selector: 'order-piece',
    templateUrl: './order-piece.component.html',
    styleUrls: ['./order-piece.component.scss']
})

export class OrderPieceComponent implements OnInit {

    @Input() group: FormGroup;
    @Input() index: number;
    @Input() piece: FormGroup;
    maxWeight: number;
    // @Input() editable: boolean;
    @Input() currentDirection: ServiceDirection;;
    @Output() pieceAdded: EventEmitter<any> = new EventEmitter();

    confirmDimension: MatDialogRef<ConfirmDimesionsComponent>;

    private _unsubscribeAll: Subject<any>;
    constructor(public _matDialog: MatDialog,
        private _locationService: LocationService,
        private _orderService: OrderService,
        private _formBuilder: FormBuilder) {
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {

        if (this.currentDirection === ServiceDirection.Domestic) {
            // domestic
            this.maxWeight = WeightValidator.MaxDomesticWeight;
        } else if (this.currentDirection === ServiceDirection.Export) {
            // Export
            this.maxWeight = WeightValidator.MaxExportWeight;
        } else if (this.currentDirection === ServiceDirection.Import) {
            // Import
            this.currentDirection = ServiceDirection.Import;
        } else {
            // Not Supported
            this.maxWeight = undefined;
            this.currentDirection = undefined;
        }
        this.setDecimalValidation();
        if (this.piece.get('pieceId').value)
            this.piece.disable();
    }
    private setDecimalValidation(): void {
        const length = this.piece.get('length');
        const width = this.piece.get('width');
        const height = this.piece.get('height');
        if (this.currentDirection == ServiceDirection.Domestic) {
            length.setValidators([DecimalValidator.validDecimal(0, 200)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        else {
            length.setValidators([DecimalValidator.validDecimal(0, 150)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        const weight = this.piece.get('weight');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    }
    numberOnly(event) {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }
    cancel(): void {
        this.pieceAdded.emit({ mode: 'cancel', index: this.index });

    }
    removePiece(event): void {
        const pieces = this.group.get('quote').get('pieces') as FormArray;
        this.pieceAdded.emit({ mode: 'delete', index: this.index, value: pieces.value[this.index - 1] });
        // pieces.removeAt(this.index - 1);
        this.piece.markAsDirty();
    }
    dimensionsChanged(content) {
        const validHeight = this.piece.get('height').hasError('validDecimal');
        const validWidth = this.piece.get('width').hasError('validDecimal');
        const validLength = this.piece.get('length').hasError('validDecimal');
        if (validHeight || validWidth || validLength)
            this.confirmDimension = this._matDialog.open(ConfirmDimesionsComponent, {
                panelClass: 'confirm-Dimension'
            });
    }
    addPiece() {
        this.pieceAdded.emit({ mode: 'new', index: this.index, value: this.piece.value });
        this.piece.disable();
    }
    editPiece() {
        this.piece.enable();
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
