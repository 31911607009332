export const Routes = {
    homepage: '/quotes/quick',
    quickQuote: '/quotes/quick',
    domesticQuotes: '/quotes/domestic',
    importQuote: '/quotes/import',
    exportQuote: '/quotes/international',
    confirmEmail: '/auth/confirm-email',
    emailConfirmed: '/auth/email-confirmed',
    login: '/auth/login',
    logout: '/auth/logout',
    register: '/auth/register',
    forgotPasswordConfirmation: '/auth/forgot-password-confirmation',
    contacts: '/contacts',
    contactList: '/contactList',
    PrintInvoice: '/orders/print',
    PrintDistribution: '/distributions/print',
    PrintDistributionManifest: '/distributions/manifest',
    commingSoon: '/coming-soon',
    invoicePayment: '/invoice-payment',
    invoicePaymentRedirect: '/invoice-payment-redirect',

    settings: '/settings',

    profile: '/profile',
    orders: '/orders',
    editOrder: '/orders/',
    ordersView: '/orders/view/',
    distributions: '/distributions',
    newDistribution: '/distributions/new',
    newOrder: '/orders/new',
    newCashOrder: '/orders/cash',

    PrintOrders: '/orders/printall',
    newAnonymousOrder: '/guest/orders/new',
    paymentRedirect: '/orders/payments/redirect',
    trackingURL: 'https://www.thenet.group/track?Label=',

    codDetails: '/reports/cod',
    podDetails: '/reports/pod',
    fullFillmentInventory: '/reports/fulfillment',

    manifest: '/reports/manifest',
    clientDashboard: '/reports/dashboard',
    crossDocking: '/reports/crossdocking',


    netpoint: '/netpoint'

    
};
