<div class="page-layout simple fullwidth">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->
    <!-- CENTER -->
    
    <!-- <div class="center"> -->
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="column" fxLayoutAlign="center start">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="secondary-text s-18">home</mat-icon>
                    <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                    <span class="secondary-text">Distributions</span>
                </div>
                <div class="mt-16 mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        store_mall_directory
                    </mat-icon>
                    <span class="h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        New Distribution
                        {{ distributionDetailGroup?.value?.quote && distributionDetailGroup?.value?.quote?.type ? ' - ' + (distributionDetailGroup?.value?.quote.type === undefined ? 'Not Supported' : ServiceDirection[distributionDetailGroup?.value?.quote.type]) : '' }}
                    </span>
                </div>
            </div>
        </div>

        <!-- CONTENT CARD -->
        <div class="content-card">
            <!-- CONTENT -->
            <div class="content">
                <div class="w-100-p p-24">
                    <div>
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="step === PanelOption.MyInfo"
                                (opened)="setStep(PanelOption.MyInfo)" hideToggle class="mb-16 no-border"
                                *ngIf="!hasSubsidiaryId || firstTime" [disabled]="step !== PanelOption.MyInfo">
                                <mat-expansion-panel-header>
                                    <mat-panel-title
                                        [ngClass]="step !== PanelOption.MyInfo ? 'font-size-18' : 'font-size-28'">
                                        My Info
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                
                                <distributions-billing-details [group]="billingDetailsGroup" *ngIf="billingDetailsGroup"
                                    (submited)="saveBillingDetails()">
                                </distributions-billing-details>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === PanelOption.OrderDetails"
                                [disabled]="step !== PanelOption.OrderDetails"
                                (opened)="setStep(PanelOption.OrderDetails)" hideToggle class="mb-16 no-border">
                                <mat-expansion-panel-header>
                                    <mat-panel-title
                                        [ngClass]="step !== PanelOption.OrderDetails ? 'font-size-18' : 'font-size-28'">
                                        Distribution Details
                                    </mat-panel-title>
                                    <mat-panel-description *ngIf="newQuote && !newQuote.value"
                                        [ngClass]="step !== PanelOption.OrderDetails ? 'font-size-18' : 'font-size-28'">
                                        <!-- <strong>Price:</strong>
                                        <span class="pl-4"
                                            *ngIf="newQuote && !newQuote.value && distributionDetailGroup?.value?.quote.quotePrice !== 0">$
                                            {{ distributionDetailGroup?.value?.quote.quotePrice | number:'.2-2'  }}</span>
                                        <span class="pl-4"
                                            *ngIf="distributionDetailGroup?.value?.quote.quotePrice === 0">N/A</span> -->
                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <distribution-distribution-details #orderDetailComponent [group]="distributionDetailGroup"
                                    *ngIf="distributionDetailGroup" [hasSubsidiaryId]="hasSubsidiaryId" 
                                    (submited)="saveOrderDetails()" (confirmed)="confirmOrderDetails()" (canceled)="prevStep(PanelOption.MyInfo)"
                                    [newQuote]="newQuote" class="p-0">
                                </distribution-distribution-details>

                            </mat-expansion-panel>
                            <mat-expansion-panel [expanded]="step === PanelOption.UploadIDPhoto"
                                *ngIf="requiredUploadIdImage" (opened)="setStep(PanelOption.UploadIDPhoto)" hideToggle
                                [disabled]="step !== PanelOption.UploadIDPhoto" class="mb-16 no-border">
                                <mat-expansion-panel-header>

                                    <mat-panel-title
                                        [ngClass]="step !== PanelOption.UploadIDPhoto ? 'font-size-18' : 'font-size-28'">
                                        Upload ID
                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <div fxLayout="column" fxLayout.gt-xs="row">
                                    <div fxFlex>
                                        <div class="mb-12">
                                            <strong>You are required to upload your ID to complete the order</strong>
                                        </div>
                                        <div class="mb-12">
                                            <button mat-raised-button (click)="file.click()">Upload</button>
                                        </div>
                                        <div class="mb-12">
                                            <span style="color:red;" *ngIf="message">{{message}}</span>
                                        </div>
                                        <div *ngIf ="imgURL" class="mb-12">
                                            <img [src]="imgURL" width="300" *ngIf="imgURL">
                                        </div>
                                        <input #file hidden type="file" accept="image/x-png,image/gif,image/jpeg"
                                            (change)="uploadIDPhoto(file.files)" />
                                    </div>
                                </div>


                                <mat-action-row>
                                    <button type="button" mat-button color="warn" (click)="goBackFromUploadId()"
                                        [disabled]="imageUploadLoading">Previous</button>
                                    <button type="button" mat-button color="primary" [disabled]="imageUploadLoading"
                                        (click)="uploadIdPhotoAndContinue()">Upload And Continue</button>
                                </mat-action-row>
                                <mat-progress-bar *ngIf="imageUploadLoading" mode="indeterminate"></mat-progress-bar>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === PanelOption.Payment"
                                (opened)="setStep(PanelOption.Payment)" hideToggle
                                [disabled]="step !== PanelOption.Payment" class="mb-16 no-border">
                                <mat-expansion-panel-header>

                                    <mat-panel-title 
                                        [ngClass]="step !== PanelOption.Payment ? 'font-size-18' : 'font-size-28'">
                                        Confirm
                                    </mat-panel-title>
                                    <!-- <mat-panel-title *ngIf="isWalkIn"
                                        [ngClass]="step !== PanelOption.Payment ? 'font-size-18' : 'font-size-28'">
                                        Checkout & Pay
                                    </mat-panel-title> -->

                                </mat-expansion-panel-header>
                                <!-- <ng-container *ngIf="orderId && step === PanelOption.Payment">
                                    <orders-payment  [order]="orderDetail"></orders-payment>
                                </ng-container> -->

                                <mat-action-row>
                                    <button type="button" mat-button color="warn"
                                        (click)="goBackFromPayment()">Previous</button>
                                    <!-- <button type="button" mat-button color="primary" (click)="nextStep()">End</button> -->
                                </mat-action-row>
                            </mat-expansion-panel>

                        </mat-accordion>

                    </div>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->

    <!-- </div> -->
    <!-- / CENTER -->

</div>