<mat-accordion class="addresses">

    <mat-expansion-panel>

        <mat-expansion-panel-header>
            <mat-panel-title fxLayoutAlign="start center">
                <mat-icon class="mr-4" *ngIf="netpoint">domain</mat-icon>
                <span class="pr-4">{{ contactType}}</span>
                <span class="pr-4" *ngIf="netpoint">NetPoint</span>
                <span>Address</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxFlex fxLayout="column">
            <div class="address h4 py-24">
                <div fxFlex fxLayout="column">
                    <div class="mr-sm-12" fxFlex *ngIf="netpoint">
                        <strong>Name</strong>
                        <span class="mat-body-2">
                            {{ address.display }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex>
                        <strong>Country: </strong>
                        <span class="mat-body-2">
                            {{ address.countryName }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex>
                        <strong>City: </strong>
                        <span class="mat-body-2">
                            {{ address.cityName }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex>
                        <strong>Street Address: </strong>
                        <span class="mat-body-2">
                            {{ address.street }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex *ngIf="!netpoint">
                        <strong>Building: </strong>
                        <span class="mat-body-2">
                            {{ address.building }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex *ngIf="!netpoint">
                        <strong>Floor: </strong>
                        <span class="mat-body-2">
                            {{ address.floor }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex *ngIf="!netpoint">
                        <strong>Postal Code (ZIP): </strong>
                        <span class="mat-body-2">
                            {{ address.zipCode }}
                        </span>
                    </div>

                    <div class="mr-sm-12" fxFlex>
                        <strong>Landmark: </strong>
                        <span class="mat-body-2">
                            {{ address.landmark }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </mat-expansion-panel>

</mat-accordion>