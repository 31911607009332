export enum ServiceDirection {
    Import = 0,
    Export = 1,
    Domestic = 2,
    All = -99
}

export enum MyNetServiceType {
    Export = 1,
    Import = 2,
    Domestic = 3,
    All = -99

}
