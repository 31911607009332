import { NgModule } from '@angular/core';
import { AutoCompleteLocationComponent } from './auto-complete-location.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule } from '@angular/material';

@NgModule({
    declarations: [AutoCompleteLocationComponent],
    imports: [FuseSharedModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule],
    exports: [AutoCompleteLocationComponent]
})
export class AutoCompleteLocationModule {}
