import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'app/_shared/user.services';
import { FormGroup } from '@angular/forms';
import { PaperSize, UserDetails } from './profile.model';
import { BillingDetails } from '../orders/new-order/order.model';
import { environment } from 'environments/environment';

@Injectable()
export class ProfileService implements Resolve<any> {
    timeline: any;
    about: any;
    photosVideos: any;
    userDetail: UserDetails;
    paperSize: PaperSize;
    userDetailOnChanged: BehaviorSubject<UserDetails>;
    timelineOnChanged: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient , private _userService: UserService,) {
        // Set the defaults
        this.userDetailOnChanged = new BehaviorSubject(null);
        this.timelineOnChanged = new BehaviorSubject({});
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    //     return new Promise((resolve, reject) => {
    //         // Promise.all([this.getTimeline(), this.getAbout(), this.getPhotosVideos()]).then(() => {
    //             Promise.all([this.getMyInfo()]).then(() => {
    //             resolve();
    //         }, reject);
    //     });
    // }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
             {
                Promise.all([this.getMyInfo()]).then(() => {
                    resolve();
                }, reject);
            }
        });
    }

    // getMyInfo():Promise<UserDetails>{
    //     return new Promise((resolve, reject) => {
    //         this._userService.getMyInfo().subscribe((info: UserDetails) => {
    //             this.userDetail = info;
    //             this.userDetailOnChanged.next(this.userDetail);
    //         });
    //         resolve(this.userDetail);
    //     });
    // }
    
    getMyInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<UserDetails>(environment.apiUrl + 'v1/Users/MyInfo/').subscribe((response: UserDetails) => {
                this.userDetail  = response;
                this.userDetailOnChanged.next(this.userDetail);
                resolve(response);
            }, reject);
        });
    }

    getPaperSize(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<PaperSize>(environment.apiUrl + 'v1/Users/GetPaperSizes/').subscribe((response: PaperSize) => {
                this.paperSize  = response;
                resolve(response);
            }, reject);
        });
    }
    /**
     * Get timeline
     */
    getTimeline(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            resolve();
            // this._httpClient.get('api/profile-timeline').subscribe((timeline: any) => {
            //     this.timeline = timeline;
            //     this.timelineOnChanged.next(this.timeline);
            //     resolve(this.timeline);
            // }, reject);
        });
    }

    /**
     * Get about
     */
    getAbout(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            resolve();
            // this._httpClient.get('api/profile-about')
            //     .subscribe((about: any) => {
            //         this.about = about;
            //         this.aboutOnChanged.next(this.about);
            //         resolve(this.about);
            //     }, reject);
        });
    }

    /**
     * Get photos & videos
     */
    getPhotosVideos(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            resolve();
            // this._httpClient.get('api/profile-photos-videos').subscribe((photosVideos: any) => {
            //     this.photosVideos = photosVideos;
            //     this.photosVideosOnChanged.next(this.photosVideos);
            //     resolve(this.photosVideos);
            // }, reject);
        });
    }
}
