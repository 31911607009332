import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { InvoicePayment } from './Invoice-payment.model';

@Injectable()
export class InvoicePaymentService {
    constructor(private _httpClient: HttpClient, private router: Router) { }
    invoices: InvoicePayment;
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            var params = route.params.id;
            if (this.isGuid(params)) {
                Promise.all([this.getInvoices(params)]).then(() => {
                    localStorage.setItem('invoiceSubsidiaryId', params);
                    resolve(this);
                }, reject);
            }
        });
    }
    isGuid(value) {
        var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
        var match = regex.exec(value);
        return match != null;
    }
    createCheckoutSession(subsidiaryId: string, invoiceId: number, price: number): Observable<any> {
        const params = new HttpParams()
            .append('subsidiaryId', subsidiaryId.toString())
            .append('invoiceId', invoiceId.toString())
            .append('price', price.toString());

        return this._httpClient.post(environment.apiUrl + 'v1/Payments/CheckoutInvoice', null, { params });
    }
    createCheckoutSessionBulk(invoicePayments: InvoicePayment): Observable<any> {

        return this._httpClient.post(environment.apiUrl + 'v1/Payments/CheckoutInvoiceBulk', invoicePayments);
    }
    // deleteInvoices(invoicePayments: InvoicePayment): Observable<any> {

    //     return this._httpClient.post(environment.apiUrl + 'v1/Payments/DeleteInvoices', invoicePayments);
    // }

    getInvoices(subsidiaryId: string): Promise<InvoicePayment> {
        return new Promise((resolve, reject) => {
            this._httpClient.get<InvoicePayment>(environment.apiUrl + 'v1/Payments/Invoices/' + subsidiaryId).subscribe((response: InvoicePayment) => {
                this.invoices = response as InvoicePayment;
                localStorage.setItem('invoices', JSON.stringify(this.invoices));
                resolve(response);
            }, reject);
        });
    }

}
