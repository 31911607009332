import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { OrderService } from '../order.service';
import { OrderViewService } from '../view/order-view.service';
import { OrderView } from '../view/order-view.model';
import { BillingDetails, CashOnDelivery } from '../new-order/order.model';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QuoteService } from 'app/main/quotes/quote.service';
import { runInThisContext } from 'vm';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'order-label',
    templateUrl: './order-label.component.html',
    styleUrls: ['./order-label.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderLabelComponent implements OnInit, OnDestroy {
    src: any;
    orderIds: any;
    url: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _orderViewService: OrderViewService, @Inject(MAT_DIALOG_DATA) private _data: any) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.orderIds = _data.orderIds;
        this.url = _data.url;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // this._orderViewService.onOrderPrint.subscribe(PDFURL => {
        this.src = this.url;
        //     console.log(this.src);
        // });
        // console.log(this.orderIds);
        // this._orderViewService.generateLabel(this.orderIds).then(x => {
        //     console.log(x.data);
        //     this.src = x.data
        //     // setTimeout(() => { this.src = x.data; }, 2000);
        // })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
