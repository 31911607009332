import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Contact, Address } from './contact.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PhoneValidator } from 'app/_validators/phone.validator';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent implements OnInit, OnDestroy {
    selectedTab = 0;
    action: string;
    contact: Contact;
    contactForm: FormGroup;
    activeAddress: FormGroup;
    activeEditAddress: FormGroup;

    @Input() Importaddress: Address

    isNewAddressOpen = false;
    editIndex: boolean[] = [];
    protected _unsubscribeAll = new Subject<void>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];

    constructor(
        public matDialogRef: MatDialogRef<ContactFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.contact = _data.contact;
        } else {
            this.contact = new Contact();
        }

        this.createContactForm();
        this.createAddressForm();
    }

    get formValue(): any {
        return this.contactForm.value;
    }

    get addNewIndex(): number {
        const value = this.contactForm.value;
        return value && value.addresses ? value.addresses.length - 1 : -1;
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private createAddressForm(): void {
        this.activeAddress = this._formBuilder.group(new Address());
        this.activeAddress.get('cityId').disable();
    }

    private createContactForm(): void {
        this.tags = this.contact.tags.slice();
        this.contact.tags = [];
        this.contactForm = this._formBuilder.group(this.contact);
        
        this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));
        
        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        this.contact.addresses.forEach(address => {
            addressesFormArray.push(this._formBuilder.group(address));
            this.editIndex.push(false);
        });

        this.contactForm.get('email').setValidators(Validators.email);

        const country = new FormControl({ iso: 'LB' }, Validators.required);

        this.contactForm.get('phoneNumber').setValidators(PhoneValidator.validCountryMobile(country));
    }

    addNewAddress(): void {
        this.isNewAddressOpen = true;
        this.createAddressForm();
    }

    addressAdded(data: any): void {
        const addressForm: FormGroup = data.value;
        const mode: string = data.mode;
        const index: number = data.index;
        if (mode === 'cancel') {
            if (this.isNewAddressOpen && index === -1) {
                this.isNewAddressOpen = false;
            } else if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;
        }

        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        const address = this._formBuilder.group(addressForm);
        if (mode === 'new') {
            addressesFormArray.push(address);
            this.isNewAddressOpen = false;
        } else if (mode === 'edit') {
            addressesFormArray.setControl(index, address);
            this.editIndex[index] = false;
        }
    }

    removeAddress(index: number): void {
        const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        addressesFormArray.removeAt(index);
    }

    editAddress(index: number): void {
        // const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        // this.activeEditAddress = this._formBuilder.group(addressesFormArray.controls[index].value);
        this.editIndex[index] = true;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    saveNew(): void {
        this.matDialogRef.close(['new', this.contactForm.value, this.tags]);
    }

    saveExisting(): void {
        this.matDialogRef.close(['edit', this.contactForm.value, this.tags]);
    }
}
