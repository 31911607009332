<div [formGroup]="sku">
    <div>
        <div class="col-xs-12 col-md-3">
            <div fxFlex fxLayout="row">

                <mat-form-field fxFlex>
                    <mat-label>Sku</mat-label>
                    <mat-select panelClass="custom-panel" formControlName="sku" name="sku" (selectionChange)="skuSelected($event)">
                        <mat-option *ngFor="let sku of wmsSKUs" [value]="sku.name" required>
                            {{sku.name}} - {{sku.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <p>You selected: {{sku.name}} - {{sku.currentStock}} </p> -->
                <mat-form-field fxFlex>
                    <mat-label>Available Quantity</mat-label>
                    <input matInput [value]="availableQuantity" name="mynet-order-quantity" [readonly]="true"
                         />
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Requested Quantity</mat-label>
                    <input matInput formControlName="quantity" name="mynet-order-quantity"
                        (keypress)="numberOnly($event)" type="number" min="1" required />
                    <mat-error *ngIf="sku.get('quantity').hasError('max')">
                        Quantity requested cannot be greater than {{availableQuantity}}
                    </mat-error>
                    <mat-error *ngIf="sku.get('quantity').hasError('min')">
                        Quantity requested cannot be 0
                    </mat-error>
                </mat-form-field>
                <button [disabled]="sku.disabled" style="margin-top: 7px;"  mat-raised-button color="warn" class="mb-32" (click)="removeSku(sku)">
                    Remove
                </button>
            </div>
        </div>
        <div>
            <!-- <button *ngIf="!this.sku.disabled" mat-raised-button color="primary" class="mb-16" [disabled]="sku.invalid"
                (click)="addSku()">
                Save
            </button>
            <button *ngIf="this.sku.disabled" mat-raised-button color="primary" class="mb-16" (click)="editSku()">
                Edit
            </button> -->
          
        </div>
    </div>
</div>