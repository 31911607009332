import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Routes } from 'app/Routes';
import { Distribution, DistributionView } from '../new-distribution/distribution.model';
// import { OrderView } from './order-view.model';

@Injectable()
export class DistributionViewService implements Resolve<any> {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient, private router: Router) {
        // Set the defaults
        // this.onOrdersChanged = new BehaviorSubject([]);
        this.onDistributionAdded = new BehaviorSubject(0);
        this.onDistributionSelected = new BehaviorSubject(null);
        this.onDistributionViewSelected = new BehaviorSubject(null);
    }
    // Orders: OrderListItem[];
    // onOrdersChanged: BehaviorSubject<OrderListItem[]>;
    onDistributionAdded: BehaviorSubject<number>;
    onDistributionSelected: BehaviorSubject<Distribution>;
    onDistributionViewSelected: BehaviorSubject<DistributionView>;
    selectedDistribution: Distribution;
    selectedDistributionView: DistributionView;
    distributionId: number;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            if (isNaN(route.params.id)) {
                this.router.navigateByUrl(Routes.distributions);
            } else {
                Promise.all([this.getDistribution(route.params.id)]).then(() => {
                    resolve(this);
                }, reject);
            }
        });
    }

    /**
     * Get Orders
     *
     * @returns {Promise<any>}
     */
    getDistribution(id: number): Promise<Distribution> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<Distribution>(environment.apiUrl + 'v1/Distributions/View/' + id)
                .subscribe((response: any) => {
                    console.log('response')
                    console.log(response)
                    if (response == null)
                        this.router.navigateByUrl(Routes.distributions);
                    this.selectedDistribution = response as Distribution;
                    this.selectedDistributionView = response as DistributionView;
                    this.onDistributionSelected.next(this.selectedDistribution);
                    this.onDistributionViewSelected.next(this.selectedDistributionView);
                    this.distributionId = response.distributionId;
                    resolve(response);
                }, reject);
        });
    }

    printDistribution(distribution: Distribution): Promise<any> {
        console.log(distribution);
        console.log(this.distributionId);
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiUrl + 'v1/Distributions/Label/' + this.distributionId).subscribe((response: any) => {
                console.log(response.data);
                resolve(response);
            }, reject);
        });
    }

    getDistributionManifest(id: number) {
        return this._httpClient.get(environment.apiUrl + 'v1/Distributions/Manifest/' + id);
    }
}
