import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, AfterContentInit, AfterContentChecked } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { ContactListItem } from 'app/main/contacts/contact-list/contact-list-item.model';
import { Address } from 'app/main/contacts/contact-form/contact.model';
import { CashOnDelivery, ProformaDetails } from '../../order.model';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, NgForm, AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Country } from 'app/_models/country.model';
import { LocationService } from 'app/_shared/location-service.service';
import { OrderService } from 'app/main/orders/order.service';
import { takeUntil } from 'rxjs/operators';

export interface User {
    id: number;
    country: string;
    name: string;
    age: number;
}
@Component({
    selector: 'order-proforma',
    templateUrl: './order-proforma.component.html',
    styleUrls: ['./order-proforma.component.scss']
})

export class OrderProformaComponent implements OnInit, AfterViewInit, AfterContentInit, AfterContentChecked {
    proformaDetails: ProformaDetails[] = [];
    @ViewChild('proformaForm', { static: false }) proformaForm: NgForm

    countries: Country[];
    displayedColumns = ['description', 'countryId', 'quantity', 'customsCommodityCode', 'unitValue', 'totalValue', 'actions'];
    dataSource = new BehaviorSubject(this.proformaDetails);
    @Input() group: FormGroup;

    @Output() submited: EventEmitter<ProformaDetails[]> = new EventEmitter();

    public countryFilterCtrl: FormControl = new FormControl();
    public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

    private _unsubscribeAll: Subject<any>;
    constructor(private _locationService: LocationService, private _orderService: OrderService
    ) {
        this._unsubscribeAll = new Subject();
    }
    ngAfterContentChecked(): void {
    }
    ngAfterContentInit(): void {

    }
    ngAfterViewInit(): void {
        this.proformaForm
            .valueChanges.pipe()
            .subscribe(_ => this.testsave());
    }
    ngOnInit(): void {
        this.getCountries();
        var proformaValue = this.group.get('proforma').value;
        if (proformaValue)
            proformaValue.forEach(element => {

                this.proformaDetails.push(element);
                this.dataSource.next(this.proformaDetails);
            });
        this.countryFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.filterCountries();
            });

    }
    protected filterCountries() {

        if (!this.countries) {
            return;
        }
        // get the search keyword
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredCountries.next(
            this.countries.filter(country => country.displayName.toLowerCase().indexOf(search) > -1)
        );
    }
    private getCountries(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.countries = success;
                this.filteredCountries.next(this.countries.slice());
            });
        });
    }
    numberOnly(event) {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    }

    calculateUnitPrice(event, rowIdx) {
        this.proformaDetails[rowIdx].totalValue = event * this.proformaDetails[rowIdx].quantity;
    }
    calculateQuantity(event, rowIdx) {
        this.proformaDetails[rowIdx].totalValue = this.proformaDetails[rowIdx].unitValue * event;
    }
    add() {
        if (this.proformaForm.invalid)
            return;
        var a = new ProformaDetails();
        this.proformaDetails.push(a);
        this.dataSource.next(this.proformaDetails);
    }
    remove(entry) {
        this.proformaDetails.splice(this.proformaDetails.findIndex(e => e === entry), 1);
        this.dataSource.next(this.proformaDetails);
        this._orderService.deleteProformaDetails(entry.id).then(result => {
        });
    }
    save() {
        this.submited.emit(this.proformaDetails);
    }
    testsave() {
        // if (this.proformaForm.valid)
        //     this.submited.emit(this.proformaDetails);
        // console.log(this.proformaForm);
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
