import { ServiceType } from 'app/_enums/ServiceType.enum';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { CashOnDelivery } from 'app/main/orders/new-order/order.model';

export class InternationalQuote {
    fromCountryId: number;
    fromCityId: number;
    toCityId: number;
    toCountryId: number;
    shippingTypeId: number;
    weight: number;
    chargeableWeight:number;
    numberOfPieces: number;
    shipmentValue: number;
    surchargesValue: string;
    direction: ServiceDirection;
    serviceTypeId: ServiceType;
    height: number;
    width: number;
    length: number;
    shipperReference: string;
    cods: CashOnDelivery[];


    constructor(quote?: any) {
        quote = quote || {};
        this.fromCountryId = quote.fromCountryId || 0;
        this.fromCityId = quote.fromCityId || 0;
        this.toCountryId = quote.toCountryId || 0;
        this.toCityId = quote.toCityId || 0;
        this.shippingTypeId = quote.shippingTypeId || 0;
        this.weight = quote.weight;
        this.chargeableWeight = quote.chargeableWeight;
        this.height = quote.height;
        this.width = quote.width;
        this.length = quote.length;
        this.numberOfPieces = quote.numberOfPieces;
        this.shipmentValue = quote.shipmentValue;
        this.direction = quote.direction;
        this.serviceTypeId = quote.serviceTypeId;
        this.shipperReference = quote.shipperReference || '';
        this.cods = quote.cods || [];

    }
}

export const enum Countries {
    Lebanon = 158
}
