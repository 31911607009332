import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const subsidiaryid = localStorage.getItem('subsidiaryId');
        const role = localStorage.getItem('role');
        if (role == "Netpoint Station") {
            this._fuseNavigationService.removeNavigationItem('quotes');
            this._fuseNavigationService.removeNavigationItem('addressBook');
            this._fuseNavigationService.removeNavigationItem('bookings');
            this._fuseNavigationService.removeNavigationItem('distributions');
            this._fuseNavigationService.removeNavigationItem('reports');

        }   
        else if(role == "E-Commerce Integration")
        {
            // this._fuseNavigationService.removeNavigationItem('quotes');
            // this._fuseNavigationService.removeNavigationItem('addressBook');
            // this._fuseNavigationService.removeNavigationItem('bookings');
            // this._fuseNavigationService.removeNavigationItem('distributions');
            this._fuseNavigationService.removeNavigationItem('netpoints');
            // this._fuseNavigationService.removeNavigationItem('manifest');
        }
        else {
            this._fuseNavigationService.removeNavigationItem('netpoints');
            this._fuseNavigationService.removeNavigationItem('wms');

        }


        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
}
