import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'app/_guards/auth.guard';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ContactListFormModule } from './c-list-form/c-list-form.module';
import { ContactListEditorModule } from './c-list-editor/c-list-editor.module';
import { ContactsListComponent } from './c-list/c-list.component';
import { ContactsListService } from './c-list/c-list.service';

const routes = [
    {
        path: 'contactList',
        canActivate: [AuthGuard],
        component: ContactsListComponent,
        resolve: {
            data: ContactsListService
        }
    }
];

@NgModule({
    declarations: [ContactsListComponent],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatMenuModule,
        FuseConfirmDialogModule,
        ContactListFormModule,
        MatCheckboxModule,
        ContactListEditorModule
    ],
    providers: [ContactsListService]
})
export class ContactsListModule {}
