import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Entity } from 'app/_models/Entity.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HeardAboutUs } from './heard-about-us.model';
import { AuthenticationService } from 'app/authentication/authentication.service';

@Component({
    selector: 'heard-about-us-dialog',
    templateUrl: './heard-about-us-dialog.component.html',
    styleUrls: ['./heard-about-us-dialog.component.scss']
})
export class HeardAboutUsDialogComponent implements OnInit {
    lookups: Entity<number>[];
    form: FormGroup;
    otherLookupId = 4004;
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<HeardAboutUsDialogComponent>,
        private _authService: AuthenticationService
    ) {
        this.lookups = _data.lookups;
        this.form = _formBuilder.group(new HeardAboutUs());
    }

    ngOnInit(): void {
        // this.form.get('lookupId').valueChanges.subscribe(value => {
        //     if (value === this.otherLookupId) {
        //         this.form.get('details').setValue(undefined);
        //         this.form.get('details').setValidators(Validators.required);
        //     }
        // });
    }

    saveHeardAboutUs(): void {
        if (this.form.invalid) {
            return;
        }
        this._authService.saveHeardAboutUs(this.form.value as HeardAboutUs).subscribe(_ => {
            this.matDialogRef.close();
        });
    }
}
