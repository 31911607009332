import { FuseNavigation } from '@fuse/types';
import { Routes } from 'app/Routes';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'quotes',
                title: 'Quick Quote',
                type: 'collapsable',
                icon: 'format_quote',
                url: Routes.quickQuote,
                // url: Routes.domesticQuotes,
                children: [
                    {
                        id: 'quote-domestic',
                        title: 'Domestic',
                        type: 'item',
                        icon: 'local_shipping',
                        url: Routes.domesticQuotes
                    },
                    {
                        id: 'quote-export',
                        title: 'Export Express',
                        type: 'item',
                        icon: 'flight_takeoff',
                        url: Routes.exportQuote
                    }
                ]
            },
            {
                id: 'addressBook',
                title: 'Address Book',
                type: 'collapsable',
                icon: 'contact_phone',
                // url: Routes.contactList,
                children: [
                    {
                        id: 'Contact',
                        title: 'Contacts',
                        type: 'item',
                        icon: 'contact_phone',
                        url: Routes.contacts
                    },
                    {
                        id: 'ContactList',
                        title: 'Contact List',
                        type: 'item',
                        icon: 'contacts',
                        url: Routes.contactList
                    }
                    // ,
                    // {
                    //     id: 'Import',
                    //     title: 'Import',
                    //     type: 'item',
                    //     icon: 'contact_phone',
                    //     url: Routes.import
                    // }
                ]
            },
            {
                id: 'bookings',
                title: 'Bookings',
                type: 'collapsable',
                icon: 'shopping_cart',
                children: [
                    {
                        id: 'orders',
                        title: 'Orders',
                        type: 'item',
                        icon: 'shopping_cart',
                        url: Routes.orders
                    }
                    // ,
                    // {
                    //     id: 'distributions',
                    //     title: 'Distribution',
                    //     type: 'item',
                    //     icon: 'shopping_basket',
                    //     url: Routes.distributions
                    // }
                ]

            },
            {
                id: 'netpoints',
                title: 'Net Point',
                type: 'item',
                icon: 'money',
                url: Routes.netpoint
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'express',
                        title: 'Express',
                        type: 'collapsable',
                        icon: 'receipt',
                        children: [
                            {
                                id: 'codDetails',
                                title: 'Cod Details',
                                type: 'item',
                                icon: 'money',
                                url: Routes.codDetails
                            }
                            ,
                            {
                                id: 'podDetails',
                                title: 'POD Details',
                                type: 'item',
                                icon: 'payment',
                                url: Routes.podDetails
                            }
                            ,
                            {
                                id: 'manifest',
                                title: 'Manifest',
                                type: 'item',
                                icon: 'payment',
                                url: Routes.manifest
                            }
                            ,
                            {
                                id: 'crossDocking',
                                title: 'Cross-Docking',
                                type: 'item',
                                icon: 'payment',
                                url: Routes.crossDocking
                            }
                        ]
                    }
                    ,
                    {
                        id: 'wms',
                        title: 'WMS',
                        type: 'collapsable',
                        icon: 'receipt',
                        children: [
                            {
                                id: 'fullFillmentInventory',
                                title: 'FullFillment',
                                type: 'item',
                                icon: 'payment',
                                url: Routes.fullFillmentInventory
                            }
                        ]
                    }
                    ,
                    {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: 'payment',
                        url: Routes.clientDashboard
                    }

                ]

            }
        ]
    }
];

