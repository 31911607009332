import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { MyNetServiceType } from 'app/_enums/ServiceDirection.enum';

@Injectable({
    providedIn: 'root'
})
export class CommodityService {
    commodities: Commodity[];
    commoditiesChanged = new BehaviorSubject<Commodity[]>(null);

    constructor(private _httpClient: HttpClient) {}

    getCommodities(serviceTypeId: MyNetServiceType): Promise<Commodity[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<Commodity[]>(environment.apiUrl + 'v1/Commodities/ServiceType/' + serviceTypeId)
                .subscribe(result => {
                    this.commodities = result;
                    this.commoditiesChanged.next(this.commodities);
                    resolve(result);
                }, reject);
        });
    }
}

export interface Commodity {
    commodityId: number;
    title: string;
    erpProductTypeId: number;
    erpServiceTypeId: number;
    productTypeId: number;
    serviceTypeId: number;
}
