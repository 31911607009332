import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'confirm-dimensions-dialog',
    templateUrl: './confirm-dimensions.html',
    styleUrls: ['./confirm-dimensions.scss']
})
export class ConfirmDimesionsComponent implements OnInit {
    form: FormGroup;
    otherLookupId = 4004;
    constructor(
        private _formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<ConfirmDimesionsComponent>
    ) {
    }

    ngOnInit(): void {
        // this.form.get('lookupId').valueChanges.subscribe(value => {
        //     if (value === this.otherLookupId) {
        //         this.form.get('details').setValue(undefined);
        //         this.form.get('details').setValidators(Validators.required);
        //     }
        // });
    }
}
