<div fxLayout="column" fxLayoutAlign="start center" fxFlex>
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Confirm Dimensions
            </span>
            <button mat-icon-button tabindex="-1" (click)="matDialogRef.close(false)" aria-label="Close dialog"
                type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="w-100-p" fxFlex>
        <div fxFlex class="h2 mb-24">Please call customer service at <strong style="color: red;">1269</strong> to confirm dimensions.</div>
        <!-- <mat-icon (click)="matDialogRef.close()" style="cursor: pointer;" class="text-right">close</mat-icon> -->
    </div>

    <!-- <mat-form-field fxFlex="100" class="w-100-p">
        <mat-select placeholder="Select how did you hear about us" name="mynet-hear-about-us-lookup"
            formControlName="lookupId" required>
            <mat-option *ngFor="let lookup of lookups" [value]="lookup.id">{{ lookup.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="100" class="w-100-p" *ngIf="form.get('lookupId').value === otherLookupId">
        <mat-label>Please Specify</mat-label>
        <textarea matInput formControlName="details" name="mynet-hear-about-us-details"
            [required]="form.get('lookupId').value === otherLookupId"></textarea>
    </mat-form-field> -->

    <div class="w-100-p text-center">
        <!-- <button (click)="saveHeardAboutUs()" color="primary" mat-raised-button>Submit</button> -->
        <button (click)="matDialogRef.close()" class="ml-16" mat-raised-button>Ok</button>
    </div>

</div>