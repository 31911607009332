import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { UserDetails } from 'app/main/profile/profile.model';
import { LoginResult } from 'app/_models/LoginResult';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private _httpClient: HttpClient) {}

    generatePhoneNumberToken(phoneNumber: string): Observable<UserStatus> {
        if (phoneNumber && typeof phoneNumber === 'string') {
            return this._httpClient.post<UserStatus>(environment.apiUrl + 'v1/Users/GeneratePhoneNumberToken', {
                phoneNumber
            });
        } else {
            return EMPTY;
        }
    }

    confirmPhoneNumberToken(data: any): Observable<UserStatus> {
        return this._httpClient.post<UserStatus>(environment.apiUrl + 'v1/Users/ConfirmPhoneNumberToken', data);
    }

    isPhoneNumberExist(phoneNumber: string): Observable<boolean> {
        return this._httpClient.post<boolean>(environment.apiUrl + 'v1/Users/IsPhoneNumberExist', { phoneNumber });
    }

    getMyInfo(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/Users/MyInfo');
    }   

    addUserDetails(userDetails: UserDetails): Promise<any> {


        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Users/MyInfo/', userDetails)
                .subscribe((loginResult: LoginResult) => {
                    localStorage.setItem('loggedInUser', JSON.stringify(loginResult));
                    resolve(loginResult);
                }, reject);
        });
    }
}

export enum ChangePhoneNumberStatus {
    SamePhoneNumber = 1,
    SMSSent = 2,
    FailedToSendSMS = 3,
    Confirmed = 4,
    FailedToChangeNumber = 5
}

export interface UserStatus {
    status: ChangePhoneNumberStatus;
}
export interface LocationPinned {
    lat: number;
    lng: number;
    countryId: number;
    countryName: string;
    cityId : number;
    cityName : string;
    streetName: string;
}
