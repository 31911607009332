<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="m-0 mr-16 secondary-text">access_time</mat-icon>
        <div *ngIf="events && events.length > 0" class="h2 secondary-text">
            Current Status: {{ events[0].name }} at {{ events[0].dateFormatted }}
        </div>
    </div>

    <div class="customer mb-32">
        <table class="simple">
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Status Time</th>
                    <th>More Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let event of events">
                    <td>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <span class="name text-truncate">
                                {{event.name}}
                            </span>
                        </div>
                    </td>
                    <td>
                        <span class="email text-truncate">
                            {{event.dateFormatted}}
                        </span>
                    </td>

                    <td>
                        <span class="text-truncate">
                            {{event.information}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>