import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { UserService, ChangePhoneNumberStatus } from 'app/_shared/user.services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/internal/operators';
import { ServiceType, CurrencyType } from 'app/_enums/ServiceType.enum';
import { Routes } from 'app/Routes';
import { Router } from '@angular/router';

@Component({
    selector: 'distribution-print-dialog',
    templateUrl: './distribution-print-dialog.component.html',
    styleUrls: ['./distribution-print-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DistributionPrintDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    protected _unsubscribeAll = new Subject<void>();

    a: any
    // Workaround for angular component issue #13870
    disableAnimation = true;
    minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    form: FormGroup;
    cashEnabled: boolean = false;
    pagesTypes: any;
    // pageTypeId: number;

    constructor(private _router: Router, private _formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<DistributionPrintDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        // this.pageTypeId = _data.pageTypeId;
    }
    printDistributionForm = this._formBuilder.group({
        pagesPerSheet: [1, [Validators.required]]//,
        // codAmount: ['', null],
        // currencyId: [CurrencyType.LBP, null]
    })
    ngOnInit(): void {
            this.pagesTypes = [
                { name: "1 - One page per sheet", value:1  },
                { name: "2 - Two pages per sheet", value:2  },
                { name: "4 - Four pages per sheet", value:4  }];
        this.subscribeRetourTypeChanged();
    }
    get formValue(): any {
        return this.printDistributionForm.value;
    }
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    private subscribeRetourTypeChanged(): void {
        this.printDistributionForm
            .get('pagesPerSheet')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }
    confirm(): void {
        console.log(this.printDistributionForm.value);
        this.matDialogRef.close(['new', this.printDistributionForm.value,]);
    }
    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        const invalidChars = ['-', '+', 'e'];
        const valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }

        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }

        return true;
    }
}
