import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class StatusValidator {
    static validStatus = (status: string): ValidatorFn => {
        return (codControl: AbstractControl): { [key: string]: boolean } => {
            if (codControl.get('cods').disabled || codControl.get('cods').value.length == 0 )
                return undefined;
            else
                return { validStatus: true };

        }
    }
};
