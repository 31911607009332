// import { QuoteData } from './quote-data.model';

import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { DistributionViewContact, DistributionERP } from '../view/distribution-view.model';
import { Quote } from '@angular/compiler';

export class Distribution {
    detail: DistributionDetail;
    billingDetails: DistributionBillingDetails;

    constructor(distribution?) {
        distribution = distribution || {};
        this.detail = distribution.detail || new DistributionDetail();
        this.billingDetails = distribution.billingAddress || new DistributionBillingDetails();
    }
}

export class DistributionDetail {
    distributionId: number;
    quoteId: number;
    quote: QuoteData;
    shipperId: number;
    shipperName: string;
    shipperAddressId: number;
    shipperAddress: string;

    // receiverId: number;
    receiverId: number;
    receiverName: string;
    receiverCount: number;
    // receiverAddressId: number;
    // receiverAddress: string;
    goodsDescription: string;
    pickupDate: Date;
    timeRangeLookupId: number;
    commodityId: number;
    specialInstructions: string;
    label: string;
    isNetPointShipper: boolean;
    isNetPointReceiver: boolean;

    constructor(detail?) {
        detail = detail || {};
        this.distributionId = detail.orderId || 0;
        this.quoteId = detail.quoteId || 0;
        this.quote = detail.quote || new QuoteData();
        this.shipperId = detail.shipperId;
        this.shipperAddressId = detail.shipperAddressId;
        // this.receiverId = detail.receiverId;
        this.receiverId = detail.receiverId;
        // this.receiverAddressId = detail.receiverAddressId;
        this.goodsDescription = detail.goodsDescription || '';
        this.specialInstructions = detail.specialInstructions || '';
        this.pickupDate = detail.pickupDate || new Date(new Date().setDate(new Date().getDate() + 1));
        // this.isNetPointShipper = detail.isNetPointShipper || false;
        // this.isNetPointReceiver = detail.isNetPointReceiver || false;
        this.shipperName = detail.shipperName || '';
        this.shipperAddress = detail.shipperAddress || '';
        this.receiverName = detail.receiverName || '';
        // this.receiverAddress = detail.receiverAddress || '';
        // this.label = detail.label || '';
        this.timeRangeLookupId = detail.timeRangeLookupId;
        this.commodityId = detail.commodityId;
    }
}

export class DistributionBillingDetails {
    distributionId: number;
    quoteId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    countryName: string;
    cityName: string;
    phoneNumber: string;
    countryId: number;
    cityId: number;
    street: string;
    building: string;
    floor: string;
    zipCode: string;
    landmark: string;
    validPhoneNumber: boolean;
    confirmedPhoneNumber: boolean;

    constructor(billing?) {
        billing = billing || {};
        this.distributionId = billing.distributionId || 0;
        this.quoteId = billing.quoteId || 0;
        this.firstName = billing.firstName || '';
        this.lastName = billing.lastName || '';
        this.countryName = billing.countryName || '';
        this.cityName = billing.cityName || '';
        this.companyName = billing.companyName || '';
        this.phoneNumber = billing.phoneNumber || '';
        this.countryId = billing.countryId;
        this.cityId = billing.cityId;
        this.street = billing.street || '';
        this.building = billing.building || '';
        this.floor = billing.floor || '';
        this.zipCode = billing.zipCode || '';
        this.landmark = billing.landmark || '';
        this.validPhoneNumber = billing.phoneNumberValid || false;
        this.confirmedPhoneNumber = billing.confirmedPhoneNumber || false;
    }
}
export class DistributionView {
    shipper: DistributionViewContact;
    receiver: string;
    quote: Quote;
    distributionId: number
    distributionInfo: DistributionERP[]

    constructor(distribution?) {
        distribution = distribution || {};
        this.shipper = distribution.shipper ;
        this.receiver = distribution.detail || '';
        this.quote = distribution.quote;
        this.distributionId = distribution.distributionId;
        this.distributionInfo = distribution.distributionInfo;
    }
}


