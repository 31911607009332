import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatSelectModule, MatButtonModule } from '@angular/material';
import { OrderCodSharedComponent } from './order-cod-shared.component';

@NgModule({
    declarations: [OrderCodSharedComponent],
    imports: [FuseSharedModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatSelectModule,MatButtonModule],
    exports: [OrderCodSharedComponent]
})
export class OrderCodSharedModule { }
