<div id="contacts" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    contact_phone
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Address Book
                </span>
            </div>
            <mat-button-toggle-group appearance="legacy" name="fontStyle" >
                <mat-button-toggle class="fuse-white" [checked]="true" (change)="onValChange($event.value)"
                    value="5000">
                    Home
                </mat-button-toggle>
                <mat-button-toggle class="fuse-white" (change)="onValChange($event.value)" value="5002">
                    Imported
                </mat-button-toggle>
            </mat-button-toggle-group>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Search for a contact">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button (click)="openContactDialog('new')"
                class="add-contact-button fuse-white mt-24 mt-md-0 ">
                <span>Add New Contact</span>
            </button>

            <!-- <a download="MyNet Contact Import Template.xlsx" target="_blank" href="MyNet Contact Import Template.xlsx">
                TEMPLATE </a> -->
            <button mat-raised-button (click)="exportExcel('MyNet Contact Import Template.xlsx')"
                class="add-contact-button fuse-white mt-24 mt-md-0 ">Download Template</button>
            <button mat-raised-button (click)="OpenAddFilesDialog($event)"
                class="add-contact-button fuse-white mt-24 mt-md-0 " [disabled]="!loadingComplete">Import
            </button>


        </div>
        <input #FileSelectInputDialog type="file" style="visibility: hidden" multiple="false"
            (change)="onFileChange($event)">
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-progress-bar *ngIf="switchingType"  mode="indeterminate" color="accent"></mat-progress-bar>
            <mat-table class="contacts-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'30'}">
                <!-- Name Column -->
                <ng-container matColumnDef="fullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <p class="text-truncate">{{contact.fullName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- Company Name Column -->
                <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <p class="text-truncate">{{contact.companyName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Email</mat-header-cell>
                    <mat-cell *matCellDef="let contact" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{contact.email}}</p>
                    </mat-cell>
                </ng-container>


                <!-- Phone Number Column -->
                <ng-container matColumnDef="phoneNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{contact.phoneNumber}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Address
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact" fxHide fxShow.gt-sm>
                        <p class="text-truncate">{{contact.address}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Tags Column -->
                <ng-container matColumnDef="tags">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Tags</mat-header-cell>
                    <mat-cell *matCellDef="let contact" fxHide fxShow.gt-xs>
                        <p class="text-truncate">
                            <mat-chip-list class="h-40" style="display: block;">
                                <mat-chip *ngFor="let tag of contact.tags" style="font-size:12px;">
                                    {{ tag }}
                                </mat-chip>
                            </mat-chip-list>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <div fxFlex="row" fxLayoutAlign="end center">

                            <button mat-icon-button tabindex="-1" [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item aria-label="edit" (click)="editContact(contact.contactId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item aria-label="remove" [disabled]="!contact.canDelete"
                                    (click)="deleteContact(contact.contactId)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Remove</span>
                                </button>
                            </mat-menu>
                        </div>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let contact; columns: displayedColumns;" class="contact" matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}" (click)="editContact(contact.contactId)">
                </mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="dataSource.pagination.totalItems" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 50]" (page)="pagination($event)">
            </mat-paginator>



        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>