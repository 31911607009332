<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button tabindex="-1" class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/logo.svg">
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

            <div class="logo ml-24" *ngIf="!authService.loggedInUser">
                <img class="logo-icon mr-16" src="assets/images/logos/logo.svg">
                <span class="logo-text">MyNet</span>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="authService.loggedInUser">

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="imgURL" class="avatar mr-0 mr-sm-16" [src]="imgURL">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ name }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button fxHide fxShow.sm fxShow.xs mat-menu-item>
                    <span class="font-weight-900">{{ name }}</span>
                </button>

                <!-- <button mat-menu-item [routerLink]="[Routes.profile]">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
                <button mat-menu-item class="" (click)="edit()">
                    <mat-icon>accessibility_new</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item class="" (click)="settings()">
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>