import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CaveatsService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
    }
    getAll(serviceType) {
        return this._httpClient.get(environment.apiUrl + 'v1/Caveats/' + serviceType);
    }
}
CaveatsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaveatsService_Factory() { return new CaveatsService(i0.ɵɵinject(i1.HttpClient)); }, token: CaveatsService, providedIn: "root" });
