<!-- <ng-include src="filename" onload="expression" autoscroll="expression" ></ng-include> -->
<!-- *ngIf="printType == 1" -->


<div *ngIf="printType == 1" id="invoice" class="compact page-layout blank" fxLayout="column">
    <div class="invoice-container">
        <div class="doNotPrint">
            <button mat-flat-button color="primary" aria-label="back" [routerLink]="'/distributions'">
                <mat-icon>back</mat-icon>
                <span>Back</span>
            </button>
            <button mat-flat-button color="primary" aria-label="print" (click)="onPrint()">
                <mat-icon>print</mat-icon>
                <span>Print</span>
            </button>
        </div>
        <!-- INVOICE -->
        <div #printThis class="card">
            <ng-container *ngFor="let i of distribution.distributionInfo">
                <div class="header">
                    <!-- <div class="invoice-date">{{date | date:'MMM-dd-yyyy'}}</div> [bc-margin-left]="20" -->

                    <div fxLayout="column" fxLayoutAlign="space-between stretch">


                        <div class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="i.label" [bc-margin-left]="120" [bc-margin-top]="35"
                                    [bc-height]="50" [bc-width]="4" [bc-display-value]="true"></ngx-barcode>

                                <!-- <span class="title">INVOICE</span> -->
                                <!-- <span class="number">{{order.label}}</span> -->
                            </div>

                            <!-- <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                            <span class="title">DUE DATE</span>
                            <span class="date">{{invoice.dueDate}}</span>
                        </div> -->


                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="i.contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{i.contact.companyName}}</strong></div>

                                    <div class="shipper">{{i.contact.fullName}}</div>
                                    <div class="address">{{i.contact.address.display}}</div>
                                    <div class="address">{{i.contact.address.landmark}}</div>

                                    <!-- <div class="landmark">{{order.receiver.address.landmark}}</div>
                                    <div class="landmark">Floor: {{order.receiver.address.floor}}</div>
                                    <div class="landmark">Building: {{order.receiver.address.building}}</div> -->
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{i.contact.phoneNumber}}</div>
                                    <div class="landmark">{{i.contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                                <!-- <div class="shipper2" >
                                <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                    <span class="title"></span>
                                </div>
                                <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                    <span class="title"></span>
                                </div>
                                <div  class="address">{{order.receiver.phoneNumber}}</div>
                                <div  class="landmark">{{order.receiver.email}}</div>
                            </div> -->
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="issuer accent" fxLayout="row" fxLayoutAlign="start center">
                        <div class="logo">
                            <img src="assets/images/logos/logo.svg">
                        </div>

                        <div class="info">
                            <div class="title">{{invoice.from.title}}</div>
                            <div *ngIf="invoice?.from.address1" class="address" style="width: 220px;">
                                {{invoice.from.address1}}</div>
                            <div *ngIf="invoice?.from.address2" class="address">{{invoice.from.address2}}</div>
                            <div *ngIf="invoice?.from.phone" class="phone">{{invoice.from.phone}}</div>
                            <div *ngIf="invoice?.from.email" class="email">{{invoice.from.email}}</div>
                            <div *ngIf="invoice?.from.website" class="website">{{invoice.from.website}}</div>
                        </div>
                    </div> -->
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="!this.mescoDist" class="contact-note">
                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                        our website www.thenet.group
                    </div>
                    <div *ngIf="this.mescoDist" class="contact-note">
                        You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                    </div>
                </div>
                <div class="pagebreak"> </div>

            </ng-container>
            <!-- <div class="doNotPrint">
                <button mat-button aria-label="back" [routerLink]="'/distributions'">
                    <mat-icon>back</mat-icon>
                    <span>Back</span>
                </button>
                <button mat-button aria-label="print" (click)="onPrint()">
                    <mat-icon>print</mat-icon>
                    <span>Print</span>
                </button>
            </div> -->

        </div>

        <!-- / INVOICE -->

    </div>

</div>
<div *ngIf="printType == 2" id="invoice2" class="compact page-layout blank" fxLayout="column">
    <div class="invoice-container">
        <div class="doNotPrint">
            <button mat-flat-button color="primary" aria-label="back" [routerLink]="'/distributions'">
                <mat-icon>back</mat-icon>
                <span>Back</span>
            </button>
            <button mat-flat-button color="primary" aria-label="print" (click)="onPrint()">
                <mat-icon>print</mat-icon>
                <span>Print</span>
            </button>
        </div>
        <div #printThis class="card">
            <ng-container *ngFor="let i of distribution.distributionInfo ; let index = index , let even = even">

                <div *ngIf="even" class="header" style="margin-top: 10px;">

                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index].label" [bc-margin-left]="120" [bc-margin-top]="35"
                                    [bc-height]="50" [bc-width]="4" [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index].contact.fullName}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                    <div style="margin-top: 150px;"></div>
                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index + 1]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index + 1].label" [bc-margin-left]="120" [bc-margin-top]="35"
                                    [bc-height]="50" [bc-width]="4" [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>
                                    <div *ngIf="distribution.shipper.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;">{{distribution.shipper.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index + 1].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index + 1].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index + 1].contact.fullName}}
                                    </div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 1].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 1].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index + 1].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index + 1].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="distribution.distributionInfo[index + 1]" fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                </div>
               
                <div class="pagebreak"> </div>

            </ng-container>
            <!-- <div class="doNotPrint">
                <button mat-button aria-label="back" [routerLink]="'/distributions'">
                    <mat-icon>back</mat-icon>
                    <span>Back</span>
                </button>
                <button mat-button aria-label="print" (click)="onPrint()">
                    <mat-icon>print</mat-icon>
                    <span>Print</span>
                </button>
            </div> -->

        </div>


    </div>

</div>
<div *ngIf="printType == 4" id="invoice4" class="compact page-layout blank" fxLayout="column">
    <div class="invoice-container">
        <div class="doNotPrint">
            <button mat-flat-button color="primary" aria-label="back" [routerLink]="'/distributions'">
                <mat-icon>back</mat-icon>
                <span>Back</span>
            </button>
            <button mat-flat-button color="primary" aria-label="print" (click)="onPrint()">
                <mat-icon>print</mat-icon>
                <span>Print</span>
            </button>
        </div>
        <div #printThis class="card">
            <ng-container *ngFor="let i of distribution.distributionInfo ; let index = index , let even = even">
                <div *ngIf="pagesPerSheet(index)" class="header" style="margin-top: 50px;">
                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index].label"
                                    [bc-margin-left]="90" [bc-margin-top]="20" [bc-height]="50" [bc-width]="3"
                                    [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index].contact.fullName}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                               
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  fxLayout="row" fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                    <div style="margin-top: 30px;"></div>
                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index + 1]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index + 1].label"
                                    [bc-margin-left]="90" [bc-margin-top]="20" [bc-height]="50" [bc-width]="3"
                                    [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index + 1].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index + 1].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index + 1].contact.fullName}}
                                    </div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 1].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 1].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index + 1].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index + 1].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  fxLayout="row" fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                    <div style="margin-top: 30px;"></div>
                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index + 2]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index + 2].label"
                                    [bc-margin-left]="90" [bc-margin-top]="20" [bc-height]="50" [bc-width]="3"
                                    [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index + 2].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index + 2].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index + 2].contact.fullName}}
                                    </div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 2].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 2].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index + 2].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index + 2].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <!-- <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div> -->
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                    <div style="margin-top: 30px;"></div>
                    <div fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div *ngIf="distribution.distributionInfo[index + 3]" class="client">
                            <div class="invoice-number" fxLayout="row" fxLayoutAlign="start start">
                                <img width="137" height="137" src="assets/images/logos/logo.svg">
                                <ngx-barcode [bc-value]="distribution.distributionInfo[index + 3].label"
                                    [bc-margin-left]="90" [bc-margin-top]="20" [bc-height]="50" [bc-width]="3"
                                    [bc-display-value]="true"></ngx-barcode>
                            </div>
                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Shipper</span>
                                    </div>

                                    <div class="shipper">{{distribution.shipper.fullName}}</div>
                                    <div class="address">{{distribution.shipper.address.display}}</div>
                                    <div class="landmark">{{distribution.shipper.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.shipper.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.shipper.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title">Receiver</span>
                                    </div>
                                    <div *ngIf="distribution.distributionInfo[index + 3].contact.companyName.length > 0" class="shipper"><strong
                                        style="font-size:  larger;"> {{distribution.distributionInfo[index + 3].contact.companyName}}</strong></div>
                                    <div class="shipper">{{distribution.distributionInfo[index + 3].contact.fullName}}
                                    </div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 3].contact.address.display}}</div>
                                    <div class="address">
                                        {{distribution.distributionInfo[index + 3].contact.address.landmark}}</div>
                                </div>
                                <div class="shipper2">
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="due-date" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="title"></span>
                                    </div>
                                    <div class="address">{{distribution.distributionInfo[index + 3].contact.phoneNumber}}</div>
                                    <div class="landmark">{{distribution.distributionInfo[index + 3].contact.email}}</div>
                                </div>
                            </div>

                            <div class="info" fxLayout="row" fxLayoutAlign="start start">
                                <div class="shipper1">

                                    <div class="shipper">Weight: {{distribution.quote.weight}}</div>
                                    <div class="address">Pieces: {{distribution.quote.numberOfPieces}}</div>
                                    <div class="landmark">Service: {{distribution.quote.shippingType}}</div>
                                    <div class="landmark">Description: {{distribution.quote.goodsDescription}}</div>
                                    <div class="landmark">Special Instructions:
                                        {{distribution.quote.specialInstructions}}
                                    </div>
                                </div>
                                <div class="shipper2" >
                                    <div class="Cod" >Special Instructions: {{distribution.quote.specialInstructions}}</div>
                                </div>
                            </div>
                            
                            <div class="info">
                                <!-- <div class="note">Please pay within 15 days. Thank you for your business.</div> -->
                                <!-- <div fxLayout="row" fxLayoutAlign="start start">
                                    <div class="small-note">
                                        You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group
                                        or
                                        visit our website www.thenet.group
                                    </div>
                                </div> -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <div *ngIf="!this.mescoDist" class="small-note">
                                        *1$ and rounding fees will be applied to Total Price*
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="distribution.distributionInfo[index + 3]"fxLayoutAlign="start start">
                        <div *ngIf="!this.mescoDist" class="contact-note">
                            You may contact TheNet 24/7 hotline at 1269, by email to info@thenet.group or visit
                            our website www.thenet.group
                        </div>
                        <div *ngIf="this.mescoDist" class="contact-note">
                            You may contact our hotline on 15562, by email to infoeg@thenetglobal.group or visit our website www.thenetglobal.group
                        </div>
                    </div>
                </div>
              
                <div class="pagebreak"> </div>

            </ng-container>


        </div>


    </div>

</div>