import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { Package } from './Package.model';
import { PackageService } from './package.service';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { WeightValidator } from 'app/_validators/weight.validator';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { MatExpansionPanel } from '@angular/material';
import { QuoteService } from 'app/main/quotes/quote.service';
import { debounceTime } from 'rxjs/operators';
import { ServiceType } from 'app/_enums/ServiceType.enum';

@Component({
    selector: 'orders-anonymous-shipment-packages',
    templateUrl: './anonymous-shipment-packages.component.html',
    styleUrls: ['./anonymous-shipment-packages.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OrdersAnonymousShipmentPackagesComponent extends OrderAnonymousBaseComponent implements OnInit {
    @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
    packages: Package[];
    submited = false;
    onlyOnePackage = true;

    constructor(
        private _packageService: PackageService,
        private _formBuilder: FormBuilder,
        private _cd: ChangeDetectorRef,
        private _quoteService: QuoteService
    ) {
        super();
    }

    get orderPackages(): any {
        return (this.group.get('packages') as FormArray).controls;
    }

    ngOnInit(): void {
        this._packageService.getPackages().subscribe((packages: Package[]) => (this.packages = packages));

        this.group
            .get('packageType')
            .valueChanges.pipe(debounceTime(500))
            .subscribe((result: ServiceType) => {
                const currentDirection = this.form.get('currentDirection').value as ServiceDirection;
                this._quoteService.getShippingTypesPromise(currentDirection, result);
            });

        this.form
            .get('currentDirection')
            .valueChanges.pipe(debounceTime(500))
            .subscribe((result: ServiceDirection) => {
                const packageType = this.group.get('packageType').value as ServiceType;
                this._quoteService.getShippingTypesPromise(result, packageType);
            });
    }

    weightChanged(event: any, index: number): void {
        const weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            const packagesArray = this.group.get('packages') as FormArray;
            packagesArray.controls[index].get('weight').setValue('0.5');
        }
    }

    packgeChanged(event, index: number): void {
        const packageGroup = this.orderPackages[index] as FormGroup;
        const packageItem = this.packages.find(x => x.packageId === event);
        const dimension = packageGroup.get('dimension') as FormGroup;
        if (packageItem) {
            dimension.get('length').setValue(packageItem.length);
            dimension.get('width').setValue(packageItem.width);
            dimension.get('height').setValue(packageItem.height);
            dimension.setControl('description', new FormControl(packageItem.description));
            dimension.setControl('title', new FormControl(packageItem.title));
        } else {
            dimension.get('length').setValue(null);
            dimension.get('width').setValue(null);
            dimension.get('height').setValue(null);
            dimension.setControl('description', new FormControl(''));
            dimension.setControl('title', new FormControl(''));
        }
    }

    addPackage(): void {
        this.matExpansionPanelQueryList.changes.subscribe(change => {
            this._cd.detectChanges();
        });
        const packagesArray = this.group.get('packages') as FormArray;

        const packageControl = this.initPackage(this._formBuilder);
        if (this.form.get('currentDirection')) {
            const weight = packageControl.get('weight');
            weight.setValue(0.5);
            const currentDirection = this.form.get('currentDirection').value as ServiceDirection;
            weight.setValidators([WeightValidator.validWeight(currentDirection), Validators.required]);
            weight.updateValueAndValidity();
        }

        packageControl.get('expanded').setValue(true);

        packagesArray.controls.forEach((group: FormGroup) => {
            group.get('expanded').setValue(false);
        });
        packagesArray.push(packageControl);
    }

    removePackage(index: number): void {
        const packagesArray = this.group.get('packages') as FormArray;
        packagesArray.removeAt(index);
    }

    validateAndContinue(): void {
        if (this.group.valid) {
            this.continue();
        } else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    }

    packagePanelOpened(index: number): void {
        this.togglePanel(index, true);
    }

    packagePanelClosed(index: number): void {
        this.togglePanel(index, false);
    }

    getPanelDescription(index: number): string {
        const packagesArray = this.group.get('packages') as FormArray;
        const packageControl = packagesArray.controls[index] as FormGroup;

        const packageId = packageControl.get('packageId').value;
        const packageBox = this.packages.find(i => i.packageId === packageId);
        const packageBoxTitle = packageId === 0 && !packageBox ? 'Custom' : packageBox.title;

        let message = '<strong>Package:</strong> ' + packageBoxTitle;

        const dimension = packageControl.get('dimension') as FormGroup;
        if (
            dimension.get('width') &&
            dimension.get('width').value &&
            dimension.get('width').value >= 0 &&
            dimension.get('height') &&
            dimension.get('height').value &&
            dimension.get('height').value >= 0 &&
            dimension.get('length') &&
            dimension.get('length').value &&
            dimension.get('length').value >= 0
        ) {
            message += ', <strong>L:</strong> ' + dimension.get('width').value + ' cm';
            message += ' x <strong>W:</strong>: ' + dimension.get('length').value + ' cm';
            message += ' x <strong>H:</strong>: ' + dimension.get('height').value + ' cm';
        }
        if (packageControl.get('weight').value) {
            message += ', <strong>Weight:</strong> ' + packageControl.get('weight').value + ' kg';
        }

        return message;
    }

    private togglePanel(index: number, value: boolean): void {
        const packagesArray = this.group.get('packages') as FormArray;
        const packageControl = packagesArray.controls[index] as FormGroup;
        packageControl.get('expanded').setValue(value);
    }
}
