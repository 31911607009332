import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { RegisterModel } from 'app/_models/RegisterModel';
import { environment } from 'environments/environment';
import { LoginModel } from 'app/_models/LoginModel';
import { LoginResult } from 'app/_models/LoginResult';
import { ResetPassword } from './reset-password/ResetPassword.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';
import { HeardAboutUs } from 'app/main/profile/heard-about-us-dialog/heard-about-us.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    onUserInfoUpdated: BehaviorSubject<LoginResult>;
    onUserLoggedIn: Subject<LoginResult>;

    jwtHelper = new JwtHelperService();

    loggedInUser: LoginResult;
    constructor(private _httpClient: HttpClient) {
        this.onUserInfoUpdated = new BehaviorSubject(null);
        this.onUserLoggedIn = new Subject();
    }

    hasSubsidiaryId(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasSubsidiaryId');
    }

    getHeardAboutUsLookups(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HeardAboutUsLookups');
    }

    saveHeardAboutUs(data: HeardAboutUs): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/HeardAboutUs', data);
    }

    hasUserIDImage(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasUserIDImage');
    }

    register(user: RegisterModel): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/register', user);
    }

    login(user: LoginModel): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Login', user);
    }

    // externalLogin(user: SocialUser): Observable<any> {
    //     return this._httpClient.post(environment.apiUrl + 'v1/authentication/SocialLogin', user);
    // }

    resetPassword(resetPassword: ResetPassword): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ResetPassword', resetPassword);
    }

    logout(): Observable<any> {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Logout', null);
    }

    forgotPassword(email: string): Observable<any> {
        const params = new HttpParams().append('email', email.toString());
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ForgotPassword', null, { params });
    }

    isLoggedIn(): boolean {
        const storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            return false;
        }
        const loggedInUser = JSON.parse(storage) as LoginResult;
        const loggedInUserFound =
            loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0;

        return loggedInUserFound && !this.jwtHelper.isTokenExpired(tokenGetter());
    }

    getLoggedInUser(): void {
        const storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            this.loggedInUser = undefined;
        }
        const loggedInUser = JSON.parse(storage) as LoginResult;
        if (
            loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0
        ) {
            this.loggedInUser = loggedInUser;
        }
    }

    authenticate(user: LoginResult, token: string): void {
        localStorage.setItem('loggedInUser', JSON.stringify(user));
        localStorage.setItem('subsidiaryId', JSON.stringify(user.subsidiaryId));
        localStorage.setItem('countryId', JSON.stringify(user.countryId));
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role);
        localStorage.setItem('netPointKey', user.netPointKey);
        localStorage.setItem('specialAccount', user.specialAccount);
        localStorage.setItem('bulkSettings', user.bulkSettings);
        localStorage.setItem('isMFERP', JSON.stringify(user.isMFERP));
    }

    uploadUserIDPhoto(image): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });

        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserIDImage', image, { headers });
    }
    uploadUserPhoto(image): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });

        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserImage', image, { headers });
    }
    downloadUserPhoto(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserImage',{ responseType: 'blob' });
    }
    downloadUserIDPhoto(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserIDImage',{ responseType: 'blob' });
    }
}
