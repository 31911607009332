import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProfileService } from 'app/main/profile/profile.service';
import { ProfileComponent } from 'app/main/profile/profile.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { MatFormFieldModule, MatInputModule, MatProgressBarModule, MatSelectModule, MatExpansionModule, MatDatepickerModule, MatTableModule } from '@angular/material';
import { AutoCompleteLocationModule } from 'app/_shared/auto-complete-location/auto-complete-location.module';
import { AutoCompleteModule } from 'app/_shared/auto-complete/auto-complete.module';
import { SettingsComponent } from './settings.component';
import { SettingsService } from './settings.service';

const routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        resolve: {
            settings: SettingsService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    // declarations: [ProfileComponent, ProfileTimelineComponent, ProfileAboutComponent, ProfilePhotosVideosComponent],
    declarations: [SettingsComponent],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatTableModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        FuseSharedModule,
        MatProgressBarModule,
        MatSelectModule,
        MatExpansionModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        MatDatepickerModule,
        MatSlideToggleModule
        
    ],
    providers: [SettingsService]
})
export class SettingsModule {}
