export class ResetPassword {
    email: string;
    password: string;
    confirmPassword: string;
    code: string;

    constructor(formValue: any, code: string) {
        formValue = formValue || {};
        this.email = formValue.email || '';
        this.password = formValue.password || '';
        this.confirmPassword = formValue.confirmPassword || '';
        this.code = code;
    }
}
