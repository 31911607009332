<div id="invoice-payment" fxLayout="row">

    <div id="invoice-payment-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="invoice-payment-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="top">

                <div class="logo">
                    <img src="assets/images/logos/logo.svg">
                </div>
                <span *ngIf="invoice.invoices.length == 0" class="mt-16">No pending invoices!</span>

                
                <button *ngIf="invoice.invoices.length > 0" mat-raised-button color="blue" class="mt-16 mb-16"
                    type="button" (click)="pay()">
                    Proceed to payment
                </button>



            </div>

        </div>

    </div>

</div>