import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Routes } from 'app/Routes';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot): boolean {
        // const roles = next.firstChild.data['roles'] as Array<string>;
        // if (roles) {
        //     const match = this.authService.roleMatch(roles);
        //     if (match) {
        //         return true;
        //     } else {
        //         this.router.navigate(['members']);
        //         console.error('You are not authorized to access this area');
        //     }
        // }

        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.router.navigate([Routes.login]);
        return false;
    }
}
