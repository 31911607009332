import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { UserService, ChangePhoneNumberStatus } from 'app/_shared/user.services';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
    selector: 'phone-number-dialog',
    templateUrl: './phone-number-dialog.component.html',
    styleUrls: ['./phone-number-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PhoneNumberDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    phoneNumber: string;
    errorMessage: string;
    successMessage: string;
    infoMessage: string;
    loading = false;
    protected _unsubscribeAll = new Subject<void>();

    // Workaround for angular component issue #13870
    disableAnimation = true;

    form: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<PhoneNumberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _userService: UserService,
        private _formBuilder: FormBuilder
    ) {
        this.phoneNumber = _data.phoneNumber;
        this.form = _formBuilder.group({
            phoneNumber: new FormControl(this.phoneNumber),
            token: new FormControl()
        });
    }
    ngOnInit(): void {
        this.sendSMSCode();
    }

    ngAfterViewInit(): void {
        this.infoMessage = 'Please add the code received on your mobile for verification!';
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    sendSMSCode(): void {
        this.errorMessage = '';
        this.successMessage = '';
        this.loading = true;
        this._userService.generatePhoneNumberToken(this.phoneNumber).subscribe(result => {
            this.loading = false;
            if (result.status === ChangePhoneNumberStatus.SamePhoneNumber) {
                this.matDialogRef.close(true);
            } else if (result.status === ChangePhoneNumberStatus.FailedToSendSMS) {
                this.errorMessage = 'Failed to send SMS message, please try again later!';
            } else if (result.status === ChangePhoneNumberStatus.SMSSent) {
                this.successMessage = 'Verification Code sent!';
            }
        });
    }

    confirm(): void {
        this.loading = true;
        this.errorMessage = '';
        this.successMessage = '';
        this._userService.confirmPhoneNumberToken(this.form.value).subscribe(result => {
            this.loading = false;
            if (result.status === ChangePhoneNumberStatus.Confirmed) {
                this.matDialogRef.close(true);
            } else {
                this.errorMessage = 'Incorrect confirmation code.';
            }
        });
    }
}
