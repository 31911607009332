import { HttpClient } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
    }
    generatePhoneNumberToken(phoneNumber) {
        if (phoneNumber && typeof phoneNumber === 'string') {
            return this._httpClient.post(environment.apiUrl + 'v1/Users/GeneratePhoneNumberToken', {
                phoneNumber
            });
        }
        else {
            return EMPTY;
        }
    }
    confirmPhoneNumberToken(data) {
        return this._httpClient.post(environment.apiUrl + 'v1/Users/ConfirmPhoneNumberToken', data);
    }
    isPhoneNumberExist(phoneNumber) {
        return this._httpClient.post(environment.apiUrl + 'v1/Users/IsPhoneNumberExist', { phoneNumber });
    }
    getMyInfo() {
        return this._httpClient.get(environment.apiUrl + 'v1/Users/MyInfo');
    }
    addUserDetails(userDetails) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiUrl + 'v1/Users/MyInfo/', userDetails)
                .subscribe((loginResult) => {
                localStorage.setItem('loggedInUser', JSON.stringify(loginResult));
                resolve(loginResult);
            }, reject);
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
export var ChangePhoneNumberStatus;
(function (ChangePhoneNumberStatus) {
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["SamePhoneNumber"] = 1] = "SamePhoneNumber";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["SMSSent"] = 2] = "SMSSent";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["FailedToSendSMS"] = 3] = "FailedToSendSMS";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["Confirmed"] = 4] = "Confirmed";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["FailedToChangeNumber"] = 5] = "FailedToChangeNumber";
})(ChangePhoneNumberStatus || (ChangePhoneNumberStatus = {}));
