/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logout.component";
import * as i2 from "../authentication.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../@fuse/services/config.service";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoutComponent, [i2.AuthenticationService, i3.Router, i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("app-logout", i1.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
