import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactExcelListItem } from './contact-list-item.model';
import { FormGroup } from '@angular/forms';
import { Contact } from '../contact-editor/contact.model';
import { ContactEditorComponent } from '../contact-editor/contact-editor.component';
import { Country } from 'app/_models/country.model';
import { takeUntil, scan } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { City } from 'app/_models/city.model';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { ContactsContactListService } from '../contact-list/contact-list.service';
import { isNullOrUndefined } from 'util';
import { ContactListItem } from '../contact-list/contact-list-item.model';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const { read, write, utils } = XLSX;
type AOA = any[][];

@Component({
    selector: 'app-contact-sheet',
    templateUrl: './sheet-jscomponent.component.html',
    styleUrls: ['./sheet-jscomponent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SheetJSComponent implements OnInit {

    @ViewChild('importForm', { static: false }) importForm: NgForm = new NgForm(null, null);
    @ViewChild('btnValidate', { static: false }) btnValidate: MatButton
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName: string = 'SheetJS.xlsx';
    contacts: ContactExcelListItem[];
    invalidContacts: ContactExcelListItem[];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    protected _unsubscribeAll = new Subject<void>();

    countries: Country[];
    allCities: City[];
    countryCities: City[] = []
    dataSource;

    dataSourceInValid;
    selection = new SelectionModel<any>(true, []);
    contactDialogRef: MatDialogRef<ContactEditorComponent>;
    displayedColumns: string[] = ['edit', 'firstname', 'lastname', 'companyName', 'email', 'phoneCountryCode', 'mobile', 'country', 'city', 'street', 'building', 'floor', 'landmark', 'postCode'];//,'select'

    contactsValidated: boolean = false;
    filteredOptions: Observable<string[]>;
    limit = 250;
    offset = 0;
    options = new BehaviorSubject<City[]>([]);
    options$: Observable<City[]>;
    showProgressBar: boolean = true;
    isEditOn: boolean = false;
    editIndex: number;
    ConfirmButtonDisabled: boolean = true;
    countinValid: number = 0;
    public localData: any[];

    public countryFilterCtrl: FormControl = new FormControl();
    public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    public cityFilterCtrl: FormControl = new FormControl();
    public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
    public countryCodeFilterCtrl: FormControl = new FormControl();
    public filteredCountryCodes: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
    constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
        public _matDialog: MatDialog, public matDialogRef: MatDialogRef<ContactEditorComponent>
        , private cdr: ChangeDetectorRef
    ) {
        this.contacts = _data.contacts;
        this.allCities = _data.cities;
        this.countries = _data.countries;

    }

    ngOnInit(): void {
    }

    trackById(index, city) {
        return city.cityId;
    }

    onEdit(index) {
        this.editIndex = index;
        this.isEditOn = !this.isEditOn;

        var countryIdRetreived = this.contacts[index].addresses[0].countryId;
        if (countryIdRetreived != null)
            this.countryCities = this.allCities.filter(x => x.countryId == countryIdRetreived);

        this.importForm.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.importForm.form.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    }
    saveNewData(contact: ContactExcelListItem) {
        var contactExcelItem = this.contacts.find(x => x == contact);
        contactExcelItem.firstName = contact.firstName;
        contactExcelItem.lastName = contact.lastName;
        contactExcelItem.email = contact.email;
        contactExcelItem.phoneCountryCode = contact.phoneCountryCode;
        contactExcelItem.phoneNumber = contact.phoneNumber;

        if (contact.addresses[0].countryId != null)
            this.countryCities = this.allCities.filter(x => x.countryId == contact.addresses[0].countryId);

        if (!isNullOrUndefined(contact.addresses[0].cityId)) {
            contactExcelItem.addresses[0].cityName = this.allCities.find(x => x.cityId == contact.addresses[0].cityId).cityName
        }
        if (!isNullOrUndefined(contact.addresses[0].countryId)) {
            contactExcelItem.addresses[0].countryName = this.countries.find(x => x.countryId == contact.addresses[0].countryId).name
        }

        contactExcelItem.addresses[0].street = contact.addresses[0].street;
        contactExcelItem.addresses[0].building = contact.addresses[0].building;
        contactExcelItem.addresses[0].floor = contact.addresses[0].floor;
        contactExcelItem.addresses[0].landmark = contact.addresses[0].landmark;
        contactExcelItem.addresses[0].zipCode = contact.addresses[0].zipCode;

        // contact = this.validateExcel(contact);

        this.isEditOn = !this.isEditOn;
        contactExcelItem.isValid = this.importForm.form.valid;
        this.invalidContacts = this.contacts.filter(c => c.isValid == false);
    }

    validateExcel(contact: ContactExcelListItem): ContactExcelListItem {
        contact.isValid = true;
        const contactAddress = contact.addresses[0];
        if (contactAddress.cityName == '' || contactAddress.countryName == '')
            contact.isValid = false;
        if (contact.firstName == '' || contact.lastName == '')
            contact.isValid = false;
        var countryIdRetreived = this.countries.find(x => x.name == contact.addresses[0].countryName);
        if (!isNullOrUndefined(countryIdRetreived)) {
            contact.addresses[0].countryId = countryIdRetreived.countryId;
            // contact.addresses[0].cities = this.allCities.filter(x => x.countryId == countryIdRetreived.countryId);
        }
        else {
            countryIdRetreived = this.countries.find(x => x.countryId == 158);
            contact.addresses[0].countryId = 158;
            // contact.addresses[0].cities = this.allCities.filter(x => x.countryId == 158);
        }
        var cityIdRetreived = this.allCities.find(x => x.cityName == contact.addresses[0].cityName);
        if (!isNullOrUndefined(cityIdRetreived)) {
            contact.addresses[0].cityId = cityIdRetreived.cityId;
        }
        else
            contact.isValid = false;
        const mobile = contact.phoneNumber;
        const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
        if (validatedPhone != '')
            contact.phoneNumber = validatedPhone
        else {
            contact.phoneNumber = '';
            contact.isValid = false;
        }
        return contact;
    }
    ngAfterViewInit(): void {
        this.countryCodeFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.filterCountryCodes();
            });

    }
    ngAfterContentInit(): void {
        this.contacts.sort((b, a) => {
            if (a.isValid === b.isValid) {
                return 0;
            }
            if (a.isValid) {
                return -1;
            }
            if (b.isValid) {
                return 1;
            }
        });
        this.invalidContacts = this.contacts.filter(c => c.isValid == false);

        setTimeout(() => {
            this.dataSource = new MatTableDataSource<ContactExcelListItem>(this.contacts);
            this.filteredCountryCodes.next(this.countries.slice());
            let element: HTMLElement = document.getElementById('auto_trigger') as HTMLElement;
            element.click();



            if (this.invalidContacts.length > 0)
                this.ConfirmButtonDisabled = true;
            else
                this.ConfirmButtonDisabled = false;
        });
    }
    protected filterCities() {
        if (!this.options.value) {
            return;
        }
        let search = this.cityFilterCtrl.value;
        if (!search) {
            this.getNextBatch();
            this.filteredCities.next(this.options.value.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.getNextBatch(search);
        this.filteredCities.next(
            this.options.value.filter(city => city.displayName.toLowerCase().indexOf(search) > -1)
        );
    }
    protected filterCountries() {
        if (!this.countries) {
            return;
        }
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredCountries.next(
            this.countries.filter(country => country.displayName.toLowerCase().indexOf(search) > -1)
        );
    }
    protected filterCountryCodes() {
        if (!this.countries) {
            return;
        }
        let search = this.countryCodeFilterCtrl.value;
        if (!search) {
            this.filteredCountryCodes.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredCountryCodes.next(
            this.countries.filter(country => country.countryPhoneCode.toLowerCase().indexOf(search) > -1)
        );
    }

    ValidateImportedContacts(): ContactExcelListItem[] {
        if (!this.importForm)
            return;
        for (var _i = 0; _i < this.invalidContacts.length; _i++) {
            Object.keys(this.importForm.controls).filter(key => key.endsWith('-' + _i)).forEach(key => {
                const abstractControl = this.importForm.controls[key];
                if (abstractControl.invalid)
                    this.invalidContacts[_i].isValid = false;
                if (key.startsWith('txtMobile')) {
                    var phoneCountry = this.countries.find(country => country.countryPhoneCode == this.invalidContacts[_i].phoneCountryCode)
                    const countryCodeControl = this.importForm.controls['ddlCountryCode-' + _i];
                    const mobileControl = this.importForm.controls['txtMobile-' + _i];
                    if (phoneCountry) {
                        var country = new FormControl({ iso: phoneCountry }, Validators.required);
                        var phoneNumberValid = new FormControl(false);

                        countryCodeControl.setValue(phoneCountry.countryPhoneCode);
                        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
                    }
                    else {
                        countryCodeControl.setErrors({ 'invalid': true })
                        mobileControl.setErrors({ 'invalid': true })
                    }


                }
            });
        }

        return this.invalidContacts;
    }
    changeCountry(index, event) {
        if (event.value != 0) {
            this.invalidContacts[index].addresses[0].cityId = null;
            var countryIdRetreived = this.contacts[index].addresses[0].countryId;
            if (countryIdRetreived != null)
                this.countryCities = this.allCities.filter(x => x.countryId == countryIdRetreived);
        }
    }
    changeCountryCode(index, event) {
        var key = Object.keys(this.importForm.controls).filter(key => key.startsWith('txtMobile-' + index));
        const abstractControl = this.importForm.controls[key[0]];
        var phoneCountry = this.countries.find(country => country.countryPhoneCode == event.value)
        var country = new FormControl({ iso: phoneCountry }, Validators.required);
        var phoneNumberValid = new FormControl(false);
        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
    }
    save(form) {
        setTimeout(() => {
            this.ValidateImportedContacts();
        }, 1000);
        this.importForm.form.updateValueAndValidity();
        this.showProgressBar = false;
    }
    closeDialog() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.matDialogRef.close(['cancel', null]);
    }
    validate(): void {
        this.ValidateImportedContacts();
        this.importForm.form.updateValueAndValidity();
    }
    saveImported(): void {
        this.matDialogRef.close(['new', this.contacts.filter(x => x.isValid == true)]);
        //this.matDialogRef.close(['new', this.contacts.filter(x=> x.isValid == true).concat(this.invalidContacts)]);
    }

    getNextBatch(citySearch?: string) {
        if (this.countryCities.length == 0)
            this.countryCities = this.allCities;
        if (!isNullOrUndefined(citySearch))
            var result = this.countryCities.filter(city => city.displayName.toLowerCase().indexOf(citySearch) > -1).slice(0, 0 + this.limit);
        else {

            var result = this.countryCities.slice(0, 0 + this.limit);
        }
        this.filteredCities.next(result.slice());
        this.options.next(result);
        this.offset += this.limit;
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}