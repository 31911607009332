<div class="page-layout carded fullwidth quick-quote">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->
    <!-- CENTER -->
    <div class="center">
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="mt-16 mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                        format_quote
                    </mat-icon>
                    <span class="h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <ng-container [ngSwitch]="readyDirection">
                            <ng-container *ngSwitchCase="ServiceDirection.Export">International - Export</ng-container>
                            <ng-container *ngSwitchCase="ServiceDirection.Domestic">Domestic</ng-container>
                            <ng-container *ngSwitchDefault>Quick Quote ( Domestic, Export Express )</ng-container>
                        </ng-container>
                    </span>
                    <mat-icon class="s-32 ml-16">
                        <ng-container [ngSwitch]="readyDirection">
                            <ng-container *ngSwitchCase="ServiceDirection.Export">flight_takeoff</ng-container>
                            <ng-container *ngSwitchCase="ServiceDirection.Domestic">local_shipping</ng-container>
                            <ng-container *ngSwitchDefault>{{ quoteIcon }}</ng-container>
                        </ng-container>
                    </mat-icon>
                </div>
            </div>
        </div>

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="content-left-sidebar">
                <div class="options p-24">
                    <form [formGroup]="quoteForm">
                        <div class="row">
                            <auto-complete-location class="col-xs-12" [label]="'From Location'" [required]="true"
                                [control]="fromCityControl" [countryId]="domesticCountryId">
                            </auto-complete-location>
                            <auto-complete-location class="col-xs-12" [label]="'To Location'" [required]="true"
                                [control]="toCityControl"
                                [countryId]="readyDirection && readyDirection == ServiceDirection.Domestic ? domesticCountryId : null"
                                [excludeCountryId]="readyDirection && readyDirection == ServiceDirection.Export ? domesticCountryId : null">
                            </auto-complete-location>

                            <div class="col-xs-12" *ngIf="neededServiceType">
                                <mat-label class="mb-8 row">What are you shipping?</mat-label>
                                <mat-radio-group class="mb-16" formControlName="serviceTypeId"
                                    name="mynet-quick-quote-service-type" [required]="neededServiceType">
                                    <mat-radio-button class="mr-8" [value]="ServiceType.Docs">Docs
                                    </mat-radio-button>
                                    <mat-radio-button class="ml-8" [value]="ServiceType.Parcels">Parcels
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>


                            <mat-form-field class="col-xs-12"
                                *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length > 2 && currentDirection === ServiceDirection.Domestic">
                                <mat-label *ngIf="currentDirection === ServiceDirection.Domestic">
                                    How soon do you want it received?
                                </mat-label>
                                <mat-select formControlName="shippingTypeId" name="mynet-quick-quote-shippingTypeId"
                                    required>
                                    <mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">
                                        {{shippingType.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="col-xs-12"
                                *ngIf="currentDirection !== undefined && shippingTypes && shippingTypes.length == 2 && currentDirection === ServiceDirection.Domestic">
                                <mat-label class="mb-8 row" *ngIf="currentDirection === ServiceDirection.Domestic">
                                    How soon do you want it received?
                                </mat-label>
                                <mat-radio-group class="mb-16" formControlName="shippingTypeId" required
                                    name="mynet-quick-quote-shipping-type" (change)="dimensionsChanged($event)">
                                    <mat-radio-button class="mr-8" [value]="shippingTypes[0].id">
                                        {{ shippingTypes[0].name }}
                                    </mat-radio-button>
                                    <mat-radio-button class="ml-8" [value]="shippingTypes[1].id">
                                        {{ shippingTypes[1].name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <mat-form-field class="col-xs-12">
                                <mat-label>Weight</mat-label>
                                <input matInput formControlName="weight" name="mynet-quick-quote-weight" type="number"
                                    (change)="weightChanged($event)" min="0" required (keypress)="numberOnly($event)">
                                <span matSuffix>kg</span>
                                <mat-error
                                    *ngIf="!quoteForm.get('weight').hasError('requried') && quoteForm.get('weight').hasError('validWeight') && currentDirection !== undefined && maxWeight">
                                    Max weight for {{ ServiceDirection[currentDirection] }} is {{ maxWeight }} kg
                                </mat-error>
                                <mat-error *ngIf="quoteForm.get('weight').hasError('requried')">
                                    Weight is required
                                </mat-error>
                            </mat-form-field>

                         




                            <div class="col-xs-12"
                                *ngIf="!neededServiceType || (neededServiceType && quoteForm.get('serviceTypeId')?.value !== ServiceType.Docs)">
                                <div class="dimension-container row">
                                    <!-- <mat-form-field class="pr-8 col-xs-4">
                                        <mat-label>Length</mat-label>
                                        <input matInput formControlName="length" name="mynet-quick-quote-length"
                                            type="number" min="0" max="999" (keypress)="numberOnly($event)">
                                        <span matSuffix>cm</span>
                                    </mat-form-field> -->

                                    <mat-form-field class="pr-8 col-xs-4">
                                        <mat-label>Length</mat-label>
                                        <input matInput formControlName="length" name="mynet-contact-length"
                                            type="number" (keypress)="numberOnly($event)"
                                            (change)="dimensionsChanged($event)" min="0" />
                                        <span matSuffix>cm</span>

                                    </mat-form-field>

                                    <mat-form-field class="pl-8 pr-8 col-xs-4">
                                        <mat-label>Width</mat-label>
                                        <input matInput formControlName="width" name="mynet-quick-quote-width"
                                            type="number" min="0" max="999" (keypress)="numberOnly($event)"
                                            (change)="dimensionsChanged($event)">
                                        <span matSuffix>cm</span>
                                    </mat-form-field>

                                    <mat-form-field class="pl-8 col-xs-4">
                                        <mat-label>Height</mat-label>
                                        <input matInput formControlName="height" name="mynet-quick-quote-height"
                                            type="number" min="0" max="999" (keypress)="numberOnly($event)"
                                            (change)="dimensionsChanged($event)">
                                        <span matSuffix>cm</span>
                                    </mat-form-field>
                                </div>

                                <!-- <mat-error class="row my-8" *ngIf="quoteForm.get('length').hasError('validDecimal')">
                                    Length should be between 0 and 999
                                </mat-error> -->
                                <!-- <mat-error class="row my-8" *ngIf="quoteForm.get('width').hasError('validDecimal')">
                                    Width should be between 0 and 999
                                </mat-error>
                                <mat-error class="row my-8" *ngIf="quoteForm.get('height').hasError('validDecimal')">
                                    Height should be between 0 and 999
                                </mat-error> -->
                                <mat-error
                                    *ngIf="currentDirection == 2 && quoteForm.get('height').hasError('validDecimal')">
                                    Height should be between 0 and 100
                                </mat-error>
                                <mat-error
                                    *ngIf="currentDirection != 2 && quoteForm.get('height').hasError('validDecimal')">
                                    Height should be between 0 and 85
                                </mat-error>
                                <mat-error *ngIf="quoteForm.get('width').hasError('validDecimal')">
                                    Width should be between 0 and 100
                                </mat-error>
                                <mat-error
                                    *ngIf="currentDirection == 2 && quoteForm.get('length').hasError('validDecimal')">
                                    Length should be between 0 and 200
                                </mat-error>
                                <mat-error
                                    *ngIf="currentDirection != 2 && quoteForm.get('length').hasError('validDecimal')">
                                    Length should be between 0 and 100
                                </mat-error>

                                <span class="h6 text-italic row my-8">
                                    Shipments are priced either by weight or dimensional weight — a calculation
                                    based on package size.
                                </span>
                            </div>
                            
                            <div class="col-xs-12">
                                <button class="newDim" mat-raised-button (click)="addNewCod()"
                                    [disabled]="quoteForm.get('cods').invalid || quoteForm.get('cods')['length'] == 2">
                                    <mat-icon>add_circle_outline</mat-icon> Add Cash on Delivery
                                </button>
                                <div class="piece-container" #Cods formArrayName="cods">
                                    <div *ngFor="let cod of quoteCods; let i = index" [formGroupName]="i">
                                        <order-cod-shared (codAdded)="codAdded($event)" [cod]="cod" [index]="(i + 1)"
                                            [group]="quoteForm"></order-cod-shared>
                                    </div>
                                    <mat-error *ngIf="quoteForm.hasError('validStatus')">Please save the Cash on Delivery</mat-error>
                                </div>
                            </div>

                            <div class="col-xs-12">
                                <button mat-raised-button fxFlex color="primary"
                                    [disabled]="quoteForm.invalid || loadingShippingTypes"
                                    (click)="submitForm()">Quote</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <!-- CONTENT -->
            <div class="content" style="display: flex; flex-flow: column;">
                <quote-start [icon]="'format_quote'" [title]="'Quick Quote'" *ngIf="!quoteCalculated && !loading "
                    style="padding: 15px;">
                </quote-start>

                <quote-loader *ngIf="loading"></quote-loader>
                <!-- *ngIf="!formChanged" -->
                <quote-result style="padding: 15px;" [loading]="loading" [quoteCalculated]="quoteCalculated"
                    [formChanged]="formChanged" [neededServiceType]="neededServiceType"
                    [allowedToProceed]="allowedToProceed" (placeAnOrder)="goToPlaceAnOrder()" [domesticCountryId] = "this.domesticCountryId" [data]="quoteResult">
                </quote-result>

                <quote-caveats *ngIf="!loading && quoteCalculated" style="height: 100%;"
                    style="border-top: 2px solid #f20000;height: 100%; padding: 0px 15px;"
                    [serviceType]="currentServiceType"></quote-caveats>

            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>