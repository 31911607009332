<div [formGroup]="piece" >
    <div fxFlex fxLayout="column">
         <div class="col-xs-12 col-md-3">
            <div fxFlex fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Number Of Pieces</mat-label>
                    <input matInput formControlName="numberOfPieces" name="mynet-contact-numberOfPieces"
                        (keypress)="numberOnly($event)" type="number" min="1" required />
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Weight</mat-label>
                    <input matInput formControlName="weight" name="mynet-contact-weight" type="number" min="0.5"
                        (keypress)="numberOnly($event)" (change)="dimensionsChanged($event)" />
                    <span matSuffix>kg</span>
                    <mat-error
                        *ngIf="piece.get('weight').hasError('validWeight') && currentDirection !== undefined && maxWeight">
                        Max weight for {{ ServiceDirection }} is {{ maxWeight }} kg
                    </mat-error>
                </mat-form-field>
             </div>

        </div>
        <div class="col-sm-12 col-md-4 col-lg-2">
            <div fxFlex fxLayout="row">
                <div fxFlex fxLayout="column">
                    <mat-form-field fxFlex>
                        <mat-label>Length</mat-label>
                        <input matInput formControlName="length" name="mynet-contact-length" type="number"
                            (keypress)="numberOnly($event)" (change)="dimensionsChanged($event)" min="0" />
                        <span matSuffix>cm</span>
                        <mat-error *ngIf="currentDirection == 2 && piece.get('length').hasError('validDecimal')">
                            Length should be between 0 and 200
                        </mat-error>
                        <mat-error *ngIf="currentDirection != 2 && piece.get('length').hasError('validDecimal')">
                            Length should be between 0 and 100
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Width</mat-label>
                        <input matInput formControlName="width" name="mynet-contact-width" type="number"
                            (keypress)="numberOnly($event)" (change)="dimensionsChanged($event)" min="0" />
                        <span matSuffix>cm</span>
                        <mat-error *ngIf="piece.get('width').hasError('validDecimal')">
                            Width should be between 0 and 100
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Height</mat-label>
                        <input matInput formControlName="height" name="mynet-contact-height" type="number"
                            (keypress)="numberOnly($event)" (change)="dimensionsChanged($event)" min="0" />
                        <span matSuffix>cm</span>
                        <mat-error *ngIf="currentDirection == 2 && piece.get('height').hasError('validDecimal')">
                            Height should be between 0 and 100
                        </mat-error>
                        <mat-error *ngIf="currentDirection != 2 && piece.get('height').hasError('validDecimal')">
                            Height should be between 0 and 85
                        </mat-error>
                    </mat-form-field>

                </div>
                <img style="display: inline-flex;" width="200" src="/assets/images/package-dimensions.png"
                    alt="Package Dimensions" />

             </div>
        </div>
        <div style="text-align: left">
            <button *ngIf="!this.piece.disabled" mat-raised-button color="primary" class="mb-16"
                [disabled]="piece.invalid || 
                this.piece.get('length').value === 0 || 
                this.piece.get('width').value === 0 || 
                this.piece.get('height').value === 0" (click)="addPiece()">
                Save
            </button>
            <button *ngIf="this.piece.disabled" mat-raised-button color="primary" class="mb-16" (click)="editPiece()">
                Edit
            </button>
            <button style="margin:5px;" mat-raised-button color="warn" class="mb-16" (click)="removePiece($event)">
                Remove
            </button>
        </div>
    </div>
</div>