import { NgModule } from '@angular/core';
import { AutoCompleteCityComponent } from './auto-complete-city.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule } from '@angular/material';

@NgModule({
    declarations: [AutoCompleteCityComponent],
    imports: [FuseSharedModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatIconModule],
    exports: [AutoCompleteCityComponent]
})
export class AutoCompleteCityModule {}
