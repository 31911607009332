import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { PreDeliverResult } from '../netpoint.model';

@Component({
    selector: 'netpoint-result',
    templateUrl: './netpoint-result.component.html',
    styleUrls: ['./netpoint-result.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class NetPointResultComponent implements OnInit {
    @Input() loading: boolean;
    @Input() data: PreDeliverResult

    constructor() { }
    ngOnInit(): void {
    }
}
