import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { QuoteResult } from './quote-result.model';

@Component({
    selector: 'quote-result',
    templateUrl: './quote-result.component.html',
    styleUrls: ['./quote-result.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class QuoteResultComponent implements OnInit {
    @Input() data: QuoteResult;
    @Input() loading: boolean;
    @Input() quoteCalculated: boolean;
    @Input() formChanged: boolean;
    @Input() allowedToProceed: boolean;
    @Input() neededServiceType: boolean;
    @Input() domesticCountryId: number;

    @Output() placeAnOrder: EventEmitter<any> = new EventEmitter<any>();
    chargeableWeight: number;
    contactInformation: string;
    hotline: string;
    constructor() {}
    ngOnInit(): void {
        if (this.domesticCountryId == 280) {
            this.hotline = "";
            this.contactInformation = "600586439"
        }
        else if (this.domesticCountryId == 90) {
            this.hotline = "";
            this.contactInformation = "15562"
        }
        else {
            this.hotline = "1269"
            this.contactInformation = "+961 1 593 400"
        }
    }
}
