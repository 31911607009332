import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from './ResetPassword.model';
import { AuthenticationService } from '../authentication.service';
import { ResetPasswordStatus } from './ResetPasswordStatus.enum';
import { LoginResult } from 'app/_models/LoginResult';
import { Routes } from 'app/Routes';
import { CustomValidators } from '../register/register.component';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;
    code: string;
    errorMessage: string;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._route.queryParams.subscribe(param => {
            this.code = param.code;
        });

        this.resetPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    // check whether the entered password has a number
                    CustomValidators.patternValidator(/\d/, {
                        hasNumber: true
                    }),
                    // check whether the entered password has upper case letter
                    CustomValidators.patternValidator(/[A-Z]/, {
                        hasCapitalCase: true
                    }),
                    // check whether the entered password has a lower case letter
                    CustomValidators.patternValidator(/[a-z]/, {
                        hasSmallCase: true
                    }),
                    // check whether the entered password has a special character
                    CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
                        hasSpecialCharacters: true
                    }),
                    Validators.minLength(8)
                ])
            ],
            confirmPassword: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'confirmPassword' field
        // when the 'password' field changes
        this.resetPasswordForm
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('confirmPassword').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    submitForm(): void {
        const resetPasswordModel = new ResetPassword(this.resetPasswordForm.value, this.code);
        this._authService.resetPassword(resetPasswordModel).subscribe(
            result => {
                if (result.status === ResetPasswordStatus.Success) {
                    this._authService.authenticate(result.user as LoginResult, result.token);
                    this._router.navigateByUrl(Routes.homepage);
                } else if (result.status === ResetPasswordStatus.Failed) {
                    this.errorMessage = 'Could not reset password';
                }
            },
            error => {
                this.errorMessage = 'Unknown Server Error, please try again later';
            }
        );
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const confirmPassword = control.parent.get('confirmPassword');

    if (!password || !confirmPassword) {
        return null;
    }

    if (confirmPassword.value === '') {
        return null;
    }

    if (password.value === confirmPassword.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
