<div class="section pb-48">

    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
        <!-- <mat-icon class="m-0 mr-16 secondary-text">attach_money</mat-icon> -->
        <div class="h2 secondary-text">Payment</div>
    </div>

    <table class="simple">
        <thead>
            <tr>
                <th>Transaction Type</th>
                <th>Credit Card</th>
                <th>Credit Card Brand</th>
                <th>Amount</th>
                <th>Time</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payment of payments">
                <td>
                    <span class="text-truncate">
                        {{ payment.transactionType }}
                    </span>
                </td>
                <td>
                    <span class="text-truncate">
                        {{ payment.creditCardNumber }}
                    </span>
                </td>
                <td>
                    <span class="text-truncate">
                        {{ payment.creditCardBrand }}
                    </span>
                </td>
                <td>
                    <span class="text-truncate">
                         {{ payment.amount | number:'.2-2' }}
                    </span>
                </td>
                <td>
                    <span class="text-truncate">
                        {{ payment.transactionTime }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>